import { IconProps } from 'types/icons'
import React from 'react'
export const CheckmarkIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
    >
      <path
        d="M17.9367 39.663L0.261719 21.988L1.67572 20.574L17.9377 36.837L46.2927 8.54199L47.7067 9.95799L17.9367 39.663Z"
        fill={fill}
      />
    </svg>
  )
}
