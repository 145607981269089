import React from 'react'
interface IconProps {
  width?: string
  height?: string
  fill?: string
  stroke?: string
  className?: string
}
export const RotateDeviceToLandscapeIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
}: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill}>
      <path
        fill={fill}
        d="M3.333 59.798V6.868a3.538 3.538 0 0 1 3.535-3.535h36.265a3.538 3.538 0 0 1 3.534 3.535v16.465H50V6.868A6.877 6.877 0 0 0 43.132 0H6.868A6.877 6.877 0 0 0 0 6.868v52.93a6.877 6.877 0 0 0 6.868 6.869h1.465v-3.334H6.868a3.538 3.538 0 0 1-3.535-3.535Z"
      />
      <path
        fill={fill}
        d="M31.667 10.363H18.333v3.334h13.334v-3.334ZM73.132 30h-52.93a6.877 6.877 0 0 0-6.869 6.868v36.264A6.877 6.877 0 0 0 20.202 80h52.931C76.918 80 80 76.918 80 73.132V36.868A6.877 6.877 0 0 0 73.132 30Zm3.535 43.132a3.538 3.538 0 0 1-3.535 3.535h-52.93a3.538 3.538 0 0 1-3.535-3.535V36.868a3.538 3.538 0 0 1 3.535-3.535h52.931a3.538 3.538 0 0 1 3.534 3.535v36.264Z"
      />
      <path
        fill={fill}
        d="M23.333 58.333a3.333 3.333 0 1 0 0-6.666 3.333 3.333 0 0 0 0 6.666ZM69.638 48.333h-3.333v13.334h3.333V48.333ZM70 16.667v3.046l-4.227-4.225-2.356 2.357 8.25 8.248 8.25-8.248-2.357-2.357-4.227 4.225v-3.046C73.333 7.477 65.857 0 56.667 0v3.333C64.018 3.333 70 9.315 70 16.667Z"
      />
    </svg>
  )
}
