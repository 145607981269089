import React from 'react'
import { pxToRem } from 'libs/styled'
import styled from 'styled-components'

const ListItemIconContainer = styled.div`
  min-width: ${pxToRem(48)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const ListItemIcon: React.FC = ({ children }) => {
  return <ListItemIconContainer>{children}</ListItemIconContainer>
}
