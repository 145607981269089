import { useNavigationFlowHandler } from 'hooks/useNavigationFlowHandler'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const NotFoundMessageStyle = styled.p`
  padding: 3em;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.primaryText};
`

export const NotFoundMessage: React.FC = () => {
  const { t } = useTranslation()
  const { barcodeFlow: fromBarcode } = useNavigationFlowHandler()
  const notFoundMessage = useMemo(
    () => (fromBarcode ? t('ProductsListPage.noBarcodeResults') : t('ProductsListPage.noResults')),
    [fromBarcode, t]
  )

  return <NotFoundMessageStyle>{notFoundMessage}</NotFoundMessageStyle>
}
