import React from 'react'
import { IconProps } from 'types/icons'
export const CloseSelectIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#A6A6A6',
  stroke = 'white',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_46091_192)">
        <path
          d="M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0ZM11.8047 10.862L10.862 11.8047L8 8.94267L5.138 11.8047L4.19533 10.862L7.05733 8L4.19533 5.138L5.138 4.19533L8 7.05733L10.862 4.19533L11.8047 5.138L8.94267 8L11.8047 10.862Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_46091_192">
          <rect width="16" height="16" fill={stroke} />
        </clipPath>
      </defs>
    </svg>
  )
}
