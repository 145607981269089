import {
  setCalibrationCompleted,
  setCalibrationSizes,
  setShowCouvetteScale,
} from 'actions/couvette'
import ConditionalRender from 'components/ConditionalRender'
import { Text } from 'components/Typography'
import { RulerIcon } from 'components/core/Icons'
import PenWithLine from 'components/core/Icons/PenWithLine'
import { useActions } from 'hooks/useActions'
import { pxToRem } from 'libs/styled'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AccordionIconPosition } from 'types/accordion'
import CouvetteScale from '../CouvetteScale'
import { useDigitalCouvetteAnalytics } from '../analytics'
import { Bold, UnderlineText } from '../styles'
import {
  CalibrationMethods,
  DIGITAL_COUVETTE_SIZES,
  DigitalCouvetteSizeWidths,
  Frame,
  MeasureUnit,
  MeasureUnits,
} from '../types'
import { formatSelectedFramePlaceholder } from '../utils'
import BackButton from './components/BackButton'
import CalibrationCompleted from './components/CalibrationCompleted'
import CalibrationProcess from './components/CalibrationProcess'
import {
  CalibrationAccordion,
  CalibrationAccordionContent,
  CalibrationConfirm,
  CalibrationContainer,
  CalibrationFooter,
  CalibrationFrameAccordionTitle,
  CalibrationFrameChanger,
  CalibrationInfo,
  CalibrationRulerAccordionTitle,
} from './styles'

interface CouvetteCalibrationProps {
  calibrationMethod?: keyof typeof CalibrationMethods
  onChangeFrame: () => void
  selectedFrame?: Frame
  onBack: () => void
}

const DigitalCouvetteCalibration: FC<CouvetteCalibrationProps> = ({
  calibrationMethod = 'frame',
  selectedFrame,
  onChangeFrame,
  onBack,
}) => {
  const { t } = useTranslation()
  const { calibrationCompleted, showCouvetteScale } = useSelector(s => s.couvette)
  const [visibleInfo, setVisibleInfo] = useState(true)
  const [userMeasure, setUserMeasure] = useState<number>(0)
  const [measureUnit, setMeasureUnit] = useState<MeasureUnit>(MeasureUnits.Cm)

  const actions = useActions({
    setCalibrationCompleted,
    setShowCouvetteScale,
    setCalibrationSizes,
  })

  const {
    onCalibrationPageView,
    onConfirmFrameCalibrationEvent,
    onConfirmRulerCalibrationEvent,
  } = useDigitalCouvetteAnalytics()

  const onConfirmCalibrationEvent = () => {
    if (calibrationMethod === 'frame') {
      onConfirmFrameCalibrationEvent(selectedFrame.modelName)
    } else {
      onConfirmRulerCalibrationEvent(measureUnit)
    }
  }

  useEffect(() => {
    onCalibrationPageView(calibrationMethod)
  }, [calibrationMethod, onCalibrationPageView])

  const calibrationInfo = {
    frame: [
      t('DigitalCouvette.calibration.frameInfo1'),
      t('DigitalCouvette.calibration.frameInfo2'),
    ],
    ruler: [
      t('DigitalCouvette.calibration.rulerInfo1'),
      t('DigitalCouvette.calibration.rulerInfo2'),
    ],
  }

  const frameCalibrationTitle = selectedFrame && (
    <CalibrationFrameAccordionTitle>
      {formatSelectedFramePlaceholder(selectedFrame)}
      <CalibrationFrameChanger onClick={onChangeFrame}>
        <PenWithLine />{' '}
        <UnderlineText>{t('DigitalCouvette.calibration.changeFrame')}</UnderlineText>
      </CalibrationFrameChanger>
    </CalibrationFrameAccordionTitle>
  )

  const rulerCalibrationTitle = (
    <CalibrationRulerAccordionTitle>
      <RulerIcon width={pxToRem(48)} height={pxToRem(48)} />
      <div>
        <Bold>{t('DigitalCouvette.calibration.rulerTitle')}</Bold>
        <span>{t('DigitalCouvette.calibration.rulerSubtitle')}</span>
      </div>
    </CalibrationRulerAccordionTitle>
  )

  const accordionTitle = { frame: frameCalibrationTitle, ruler: rulerCalibrationTitle }

  const toggleAccordion = () => setVisibleInfo(!visibleInfo)

  const onConfirm = () => {
    onConfirmCalibrationEvent()
    actions.setCalibrationCompleted(true)
  }

  const onContinue = () => actions.setShowCouvetteScale(true)

  useMemo(() => {
    if (calibrationCompleted) return
    let sizes = {}

    Object.values(DIGITAL_COUVETTE_SIZES).forEach((sizeRange, i) => {
      const [min, max] = sizeRange.split('-')
      // When evaluating min and max, we do +1 to the max size
      // so the current max size is equal of the next size min
      if (calibrationMethod === CalibrationMethods.ruler) {
        const PXinMM = userMeasure / 100
        sizes = {
          ...sizes,
          [Object.keys(DIGITAL_COUVETTE_SIZES)[i]]: {
            min: Number(min) * PXinMM,
            max: (Number(max) + 1) * PXinMM,
          },
        }
      } else if (calibrationMethod === CalibrationMethods.frame && selectedFrame) {
        const PXinMM = userMeasure / selectedFrame.hingeToHinge
        sizes = {
          ...sizes,
          [Object.keys(DIGITAL_COUVETTE_SIZES)[i]]: {
            min: Number(min) * PXinMM,
            max: (Number(max) + 1) * PXinMM,
          },
        }
      }
    })
    actions.setCalibrationSizes(sizes as DigitalCouvetteSizeWidths)
  }, [actions, calibrationCompleted, calibrationMethod, selectedFrame, userMeasure])

  const showBackButton = !calibrationCompleted && !showCouvetteScale

  return (
    <CalibrationContainer openedAccordion={visibleInfo}>
      <ConditionalRender condition={showBackButton}>
        <BackButton onClick={onBack} />
      </ConditionalRender>
      <ConditionalRender condition={!calibrationCompleted}>
        <CalibrationAccordion
          isOpened={visibleInfo}
          title={accordionTitle[calibrationMethod]}
          onClickAccordionIcon={toggleAccordion}
          accordionIconPosition={AccordionIconPosition.bottom}
        >
          <CalibrationAccordionContent>
            <CalibrationInfo>
              {calibrationInfo[calibrationMethod].map((info, idx) => (
                <Text key={idx}>
                  <Bold>{idx + 1}.</Bold> {info}
                </Text>
              ))}
            </CalibrationInfo>
          </CalibrationAccordionContent>
        </CalibrationAccordion>
        <CalibrationProcess
          method={calibrationMethod}
          onUserDrag={setUserMeasure}
          selectedFrame={selectedFrame}
          measureUnit={measureUnit}
          setMeasureUnit={setMeasureUnit}
        />
        <CalibrationFooter>
          <CalibrationConfirm onClick={onConfirm}>
            {t('DigitalCouvette.calibration.confirm')}
          </CalibrationConfirm>
        </CalibrationFooter>
      </ConditionalRender>
      <ConditionalRender condition={calibrationCompleted}>
        {!showCouvetteScale ? <CalibrationCompleted onContinue={onContinue} /> : <CouvetteScale />}
      </ConditionalRender>
    </CalibrationContainer>
  )
}

export default DigitalCouvetteCalibration
