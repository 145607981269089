import { FontWeight, TypographyProps } from 'types/typography'
import { Device } from 'libs/media'
import { theme as defaultTheme } from 'theme'
import { css, DefaultTheme, FontObject, Sizes, SeparatedTheme } from 'styled-components'
import { landscape, tablet } from './media'
import { checkColor, clampValues, clampValuesLandscape, pxToRem } from './styled'

const fontSize = (defaultSize: number, customSize?: keyof Sizes) => {
  const customFontSize = customSize ? defaultTheme.fonts.sizesPx[customSize] : undefined
  return pxToRem(customFontSize ? customFontSize : defaultSize)
}

const getTypograpyStyles = (font: FontObject, props: TypographyProps) => `
    font-size: ${fontSize(font.towerSize, props.textSize)};
    line-height: ${pxToRem(font.towerLineHeight)};
    ${tablet(`
        font-size: ${clampValues(
          props.textSizeTabletMini
            ? defaultTheme.fonts.sizes[props.textSizeTabletMini]
            : font.tabletMiniSize,
          props.textSizeTablet ? defaultTheme.fonts.sizes[props.textSizeTablet] : font.tabletSize
        )};
        line-height: ${clampValues(font.tabletMiniLineHeight, font.tabletLineHeight)};
      `)};
    ${landscape(
      Device.tablet,
      `
        font-size: ${clampValuesLandscape(
          props.textSizeTabletMini
            ? defaultTheme.fonts.sizes[props.textSizeTabletMini]
            : font.tabletMiniSize,
          props.textSizeTablet ? defaultTheme.fonts.sizes[props.textSizeTablet] : font.tabletSize
        )};
        line-height: ${clampValuesLandscape(font.tabletMiniLineHeight, font.tabletLineHeight)};
      `
    )};
  }
`

const weightMap: Record<FontWeight, number> = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
}

// Generate defaul font object used on all typography components
export const generateDefaultFontProperties = (
  theme: DefaultTheme,
  font: FontObject,
  props: TypographyProps,
  separatedThemeName?: SeparatedTheme
) => {
  return css`
    ${getTypograpyStyles(font, props)}
    font-family: ${font.name || theme.fonts.primary}, serif;
    font-weight: ${props.weight ? weightMap[props.weight] : font.weight};
    text-align: ${props.textAlign};
    line-height: ${pxToRem(font.towerLineHeight)};
    color: ${checkColor(
      // todo: remove ts-ignore after styled-components types are fixed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props.color || 'primaryText',
      theme,
      separatedThemeName
    )};
    margin: 0;
  `
}
