import ConditionalRender from 'components/ConditionalRender'
import { Logo } from 'components/Logo'
import { OakleyCustomIcon } from 'components/core/Icons'
import { Image } from 'components/core/Image'
import { isOOBrand } from 'libs/custom'
import { CatalogImageAngles, getThumbnail } from 'libs/image'
import React from 'react'
import { ImageInfo } from 'types/image'
import {
  BarCodeScanModalDescription,
  BarCodeScanModalSubTitle,
  BarCodeScanModalTitle,
  BarCodeScanModalWrapper,
  CustomLogoContainer,
  ImageModalProductImageContainer,
  LogoContainer,
  RayBanCustomLogo,
} from '../styles'
import { BarcodeScanModalBaseProps } from './types'
import { BarcodeDebugFlag } from '../BarcodeDebugFlag'

export const BarcodeScanModalBase: React.FC<BarcodeScanModalBaseProps> = ({
  visible,
  formattedBrand,
  customizable,
  thumbnail,
  onCancel,
  title,
  subtitle,
  description,
  isCapitalizedTitle = false,
  footerContent,
}) => {
  const isOakley = isOOBrand(formattedBrand)

  const renderCustomLogo = () => (
    <CustomLogoContainer>
      {isOakley ? <OakleyCustomIcon fill={'#d0021b'} /> : <RayBanCustomLogo />}
    </CustomLogoContainer>
  )

  const renderLogo = () => (
    <LogoContainer>{customizable ? renderCustomLogo() : <Logo />}</LogoContainer>
  )

  const renderThumbnail = (thumbnail: Pick<ImageInfo, 'brand' | 'materialCode' | 'moco'>) => {
    const newThumbnail: ImageInfo = { ...thumbnail, isFirstLevel: false, format: 'png' }
    const imageUrl = getThumbnail({
      info: newThumbnail,
      angle: CatalogImageAngles.QUARTER,
    })
    return imageUrl
  }

  return (
    <BarCodeScanModalWrapper
      visible={visible}
      onCancel={onCancel}
      renderHeader={() => renderLogo()}
      aria={{ labelledby: title }}
      footerContent={footerContent}
    >
      <div>
        <BarcodeDebugFlag />
        <ConditionalRender condition={!!subtitle}>
          <BarCodeScanModalSubTitle>{subtitle}</BarCodeScanModalSubTitle>
        </ConditionalRender>
        <BarCodeScanModalTitle
          aria-label={title}
          aria-description={title}
          isCapitalized={isCapitalizedTitle}
          isReducedMargin={!!subtitle}
        >
          {title}
        </BarCodeScanModalTitle>
        <ImageModalProductImageContainer>
          <Image
            src={typeof thumbnail !== 'string' ? renderThumbnail(thumbnail) : thumbnail}
            alt="product image"
          />
        </ImageModalProductImageContainer>
        {description && (
          <BarCodeScanModalDescription aria-label={description} aria-description={description}>
            {description}
          </BarCodeScanModalDescription>
        )}
      </div>
    </BarCodeScanModalWrapper>
  )
}
