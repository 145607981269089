import { CardProductContainerStyled } from 'components/CardProductBase/ProductCard.style'
import { NavigationBtn } from 'components/Cart/CartMerchRecommender/styles'
import { MultiSlidesWrapper, SingleSlideWrapper } from 'components/Slider/styles'
import { Heading4 } from 'components/Typography'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, pxToRem } from 'libs/styled'
import styled from 'styled-components'
import { PDPSimilarTitle } from '../styles'

export const PDPSuggestionsContainer = styled.section`
  margin-block-start: ${pxToRem(32)};
  ${tablet(`
    margin-block-start: ${clampValues(12, 16)};
  `)}
`

export const SuggestionsSelector = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  gap: ${pxToRem(80)};
  margin-block-end: ${pxToRem(32)};
  margin-inline-start: ${pxToRem(24)};

  ${tablet(`
    gap: ${pxToRem(45)};
    margin-block-end: ${pxToRem(8)};
  `)}

  ${PDPSimilarTitle} {
    margin-block-end: 0;
    margin-inline-start: 0;
  }
`

export const SuggestionsTabLabel = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: ${pxToRem(4)};

  ${Heading4} {
    margin-block-end: 0;
    margin-inline-start: 0;
  }
`

export const PDPSuggestionsSlides = styled.div`
  ${MultiSlidesWrapper}, ${SingleSlideWrapper} {
    padding: 0 ${pxToRem(24)};
    ${tablet(`
      padding: 0 ${clampValues(72, 96)};
    `)}
  }

  ${CardProductContainerStyled} {
    margin-bottom: 0;
    ${tablet(`
      margin-bottom: ${clampValues(4, 32)};
    `)}
  }
`

const NavBtn = styled(NavigationBtn)`
  opacity: 1;
  background: ${({ theme }) => theme.colors.bodyBackground};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  block-size: ${pxToRem(68)};
  inline-size: ${pxToRem(68)};
  border: none;
  padding: 0;
  position: fixed;
  inset-block-start: ${pxToRem(176)};
  transform: translate(
    0,
    calc(-50% + ${({ theme }) => pxToRem(theme.fonts.heading4.towerSize / 2)})
  );
  ${({ theme }) => `
    ${tablet(
      `
        padding: 0;
        block-size: ${pxToRem(48)};
        inline-size: ${pxToRem(48)};
        inset-block-start: ${pxToRem(100)};
        transform: translate(0, calc(-50% + ${pxToRem(theme.fonts.heading4.tabletSize / 2)}));
      `
    )}
    ${tablet(
      Device.ipadMini,
      `
        transform: translate(0, calc(-50% + ${pxToRem(theme.fonts.heading4.tabletMiniSize / 2)}));
      `
    )}
  `};

  ${landscape(`
    padding: 0;
  `)}

  &:active {
    border: none;
  }
`

export const PrevBtn = styled(NavBtn)`
  inset-inline-start: ${pxToRem(18)};

  ${tablet(
    `
    inset-inline-start: ${pxToRem(12)};
  `
  )}
`

export const NextBtn = styled(NavBtn)`
  inset-inline-end: ${pxToRem(18)};

  ${tablet(
    `
    inset-inline-end: ${pxToRem(12)};
  `
  )}
`
