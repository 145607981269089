import { BarcodeScanModalWithRedirectProps } from 'components/BarCodeScanModal/types'
import { PrimaryButton, SecondaryButton } from 'components/core/Button'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { isOOBrand } from 'libs/custom'
import { getCustomizableBrand } from 'libs/customProducts'
import { getCustomProductRoute } from 'libs/routing'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BarcodeScanModalBase } from '../BarcodeScanModalBase'
import { useSendCloseModalEvent, useSendImpressionEvent } from './analytics'

export const BarcodeScanModalExactUpc = ({
  visible,
  onCancel,
  onRedirect,
  productInfo,
}: BarcodeScanModalWithRedirectProps) => {
  const { t } = useTranslation()
  const { basePath } = useStoreIndentity()
  const { originalBrand, moco, model, UPC } = productInfo
  const formattedBrand = getCustomizableBrand(originalBrand)
  const thumbnail = {
    brand: originalBrand,
    materialCode: model,
    moco,
  }
  const isOakley = isOOBrand(formattedBrand || originalBrand)

  const sendCloseModalEvent = useSendCloseModalEvent(isOakley)

  const title = t(isOakley ? 'BarCodeScanModal.ooTitle' : 'BarCodeScanModal.rayBanTitle')
  const description = t(
    isOakley ? 'BarCodeScanModal.oakleyDescription' : 'BarCodeScanModal.rayBanDescriptionUpc'
  )
  const url = getCustomProductRoute({ basePath, brand: formattedBrand, model, UPC })

  const handleOnCancel = useCallback(() => {
    sendCloseModalEvent()
    onCancel()
  }, [onCancel, sendCloseModalEvent])

  const shouldSendEvent = Boolean(visible)

  useSendImpressionEvent(shouldSendEvent, isOakley)

  return (
    <BarcodeScanModalBase
      visible={visible}
      title={title}
      description={description}
      customizable={true}
      formattedBrand={formattedBrand}
      thumbnail={thumbnail}
      isCapitalizedTitle={true}
      onCancel={handleOnCancel}
      footerContent={[
        <SecondaryButton key="cancel" onClick={handleOnCancel}>
          {t('BarCodeScanModal.cancelBtn')}
        </SecondaryButton>,
        <PrimaryButton key="customize" onClick={() => onRedirect(url)}>
          {t('BarCodeScanModal.confirmBtn')}
        </PrimaryButton>,
      ]}
    />
  )
}
