import ErrorWidget from 'components/ErrorWidget'
import { TFunction } from 'i18next'
import Log from 'libs/Log'
import React from 'react'

interface ErrorBoundaryComponentProps {
  actionUrl: string
  t?: TFunction
}

interface ErrorBoundaryComponentState {
  error: Error | null
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryComponentProps,
  ErrorBoundaryComponentState
> {
  constructor(props: ErrorBoundaryComponentProps) {
    super(props)
    this.state = {
      error: null,
    }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ error })

    Log.error(error, 'ReactError')
    Log.error(info.toString(), 'ReactError')
  }

  render() {
    if (this.state.error) {
      let extraProps = {}
      if (this.props.actionUrl) {
        extraProps = {
          actionLabel: this.props.t ? this.props.t('Core.goToHomePage') : '',
          action: () => {
            window.location.href = this.props.actionUrl
          },
        }
      }
      return <ErrorWidget type="500" errors={[this.state.error]} {...extraProps} withWrapper />
    }
    return this.props.children
  }
}
