import ConditionalRender from 'components/ConditionalRender'
import ErrorWidget from 'components/ErrorWidget'
import { Loading } from 'components/Loading'
import { QRCode } from 'components/QRCode'
import { PrimaryButton } from 'components/core/Button'
import {
  useSessionHandoffStateSaver,
  useSessionHandoffStatusChecker,
  useSessionHandoffToken,
} from 'data'
import { usePageChecker } from 'hooks/useNavigationUtils'
import { useResetSS } from 'hooks/useResetSS'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import Log from 'libs/Log'
import { loadState } from 'localStorage'
import { Options } from 'qr-code-styling'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DefaultTheme, ThemeContext } from 'styled-components'

interface SessionHandoffContentProps {
  onScanCompleted?: () => void
}

export const SessionHandoffContent: React.FC<SessionHandoffContentProps> = ({
  onScanCompleted,
}) => {
  const resetSS = useResetSS()
  const [qrcodeUrl, setQrcodeUrl] = useState<string | undefined>()
  const {
    data: TokenData,
    loading: TokenLoading,
    error: tokenError,
    refetch,
  } = useSessionHandoffToken()
  const [
    saveSession,
    { loading: createUserLoading, error: createUserError },
  ] = useSessionHandoffStateSaver()
  const [
    checkHandoffStatus,
    { startPolling, stopPolling, data: scanningStatus },
  ] = useSessionHandoffStatusChecker()
  const theme: DefaultTheme = useContext(ThemeContext)
  const roundQRCodeStyles: Partial<Options> = {
    width: 250,
    height: 250,
    qrOptions: {
      mode: 'Byte',
      errorCorrectionLevel: 'M',
    },
    dotsOptions: {
      type: 'dots',
      color: theme.colors.primaryText,
    },
    cornersDotOptions: {
      type: 'dot',
      color: theme.colors.primaryText,
    },
    cornersSquareOptions: {
      type: 'dot',
      color: theme.colors.primaryText,
    },
  }

  const currentLocation = useLocation()
  const { isHomePage } = usePageChecker()
  const { basePath, langCode, storeId } = useStoreIndentity()
  const location = {
    path:
      currentLocation.pathname.replace(basePath, isHomePage ? '/' : '') + currentLocation.search,
    langCode,
    storeId,
  }

  const getStateToSave = useCallback(() => {
    const state = loadState()
    const stateToSave = { ...state, location }
    return JSON.stringify(stateToSave)
  }, [location])

  const saveSessionState = useCallback(
    (token: string) =>
      saveSession({
        variables: {
          url: `${window.location.origin}`,
          token: token,
          isScanned: false,
          state: getStateToSave(),
        },
      })
        .then(res => {
          setQrcodeUrl(`${res.data?.sessionHandoffStateSaver.qrcodeUrl}`)
        })
        .catch(err => Log.error(err)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (!TokenData?.sessionHandoffToken.token) return
    if (!qrcodeUrl) {
      saveSessionState(TokenData?.sessionHandoffToken.token)
      checkHandoffStatus({
        variables: {
          token: TokenData?.sessionHandoffToken.token,
        },
      })
      startPolling(5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TokenData])

  useEffect(() => {
    if (scanningStatus?.sessionHandoffState.isScanned === 'true') {
      stopPolling()
      onScanCompleted()
      resetSS()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanningStatus, stopPolling])

  if (TokenLoading || createUserLoading) return <Loading />

  if (tokenError || createUserError)
    return (
      <ErrorWidget
        type="Generic Error"
        errors={[tokenError as Error] || [createUserError as Error]}
        withWrapper
      />
    )

  return (
    <ConditionalRender condition={!!qrcodeUrl}>
      <QRCode
        url={qrcodeUrl || ''}
        styles={roundQRCodeStyles}
        sizes={{ tower: 250 }}
        data-token={TokenData?.sessionHandoffToken.token}
        data-post-url={qrcodeUrl}
      />
      {tokenError && (
        <div>
          <PrimaryButton onClick={() => refetch()}>Token is expired</PrimaryButton>
        </div>
      )}
    </ConditionalRender>
  )
}
