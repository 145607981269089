import { getConfigFromSessionStorage, getConfigOverrideSessionKey } from 'libs/configOverride'

const {
  REACT_APP_ASSETS_URL: assetsUrl,
  REACT_APP_REMIX_PRICE_SERVICE_URL: remixPriceServiceUrl,
  REACT_APP_PRODUCT_IMAGES_URL: productImagesBaseUrl,
  REACT_APP_REMIX_URL: remixURL,
  REACT_APP_REMIX_ENV: remixEnv,
  REACT_APP_GRAPHQL_API_URL,
  REACT_APP_ANALYTICS_URL: analyticsUrl,
  REACT_APP_ANALYTICS_ENABLED: analyticsEnabled,
  REACT_APP_FLUID_WF: fluidWf,
  REACT_APP_FLUID_JS: fluidOakJs,
  REACT_APP_RELIC_ENABLED: relicEnabled,
  REACT_APP_RELIC_APP_ID: relicAppId,
  REACT_APP_RELIC_LICENSE_KEY: relicLicenseKey,
  REACT_APP_TRANSLATION_SERVICE_URL: labelsUrl,
  REACT_APP_SIGNAL_TIMEOUT: deviceSignalTimeout = 60 * 1000, // in milliseconds
  REACT_APP_RBCUSTOM_ENDPOINT: rbCustomEndPoint,
  REACT_APP_RBCUSTOM_JS: rbCustomJsUrl,
  REACT_APP_RBCUSTOM_OCID: rbCustomOcId,
  REACT_APP_OOCUSTOM_ENDPOINT: ooCustomEndPoint,
  REACT_APP_OOCUSTOM_JS: ooCustomJsUrl,
  REACT_APP_OOCUSTOM_OCID: ooCustomOcId,
  REACT_APP_LOG_SERVER_URL: logServerUrl,
  REACT_APP_IFRAME_URL: iFrameUrl,
  REACT_APP_SERVICE_WORKER_ENABLE: serviceWorkerEnabled,
  REACT_APP_DEFAULT_PRODUCT_TYPE: defaultProductType,
  REACT_APP_CONFIGURATOR_VTO_ENV: vtoEnv,
  REACT_APP_LOGIN_REDIRECT_URI: loginRedirectURL,
  REACT_APP_LOGIN_PAGE_URI: loginPageURL,
  REACT_APP_SAVE_ADB2C_TOKEN: saveADB2CTokenToLocalStorage,
  REACT_APP_LOGIN_WITH_ROCP: loginWithROCP,
  REACT_APP_DRESSED_ON_IMAGE_SERVICE: dressedOnImageService,
  REACT_APP_COURTESY_URL: courtesyUrl,
  REACT_APP_COURTESY_INTERVAL: courtesyInterval,
  REACT_APP_TOGGLE_FEATURE_COURTESY: featureCourtesyEnabled,
  REACT_APP_TOGGLE_FEATURE_RTR: realTimeRendererEnabled,
  REACT_APP_FA_ERROR_PAGE_URL: faErrorPageUrl,
  REACT_APP_FRAME_ADVISOR_BEST_MATCH_COUNT: bestMatchCount,
  REACT_APP_VM_ENABLE: vmEnabled,
  REACT_APP_VM_URL: vmURL,
  REACT_APP_VM_KEY: vmKey,
  REACT_APP_VM_SOURCE: vmSource,
  REACT_APP_VM_JS: vmJS,
  REACT_APP_CONFIGURATOR_VM_ENV: vmEnv,
  REACT_APP_RTR_JS: rtrJS,
  REACT_APP_TOGGLE_FEATURE_HANDOFF: sessionHandoffEnabled,
  REACT_APP_NEW_MERCH_ACCESSORIES_BAG: newMerchAccessoriesBag,
} = process.env

export const localStorageKeyPrefix = 'm4c'

export const defaultConfig = {
  adb2ctokenKey: `${localStorageKeyPrefix}-ADB2C-token`,
  addressGeocode: true, // use geocode resolver in checkout
  migrationStatusKey: `${localStorageKeyPrefix}-is-user-migrated`,
  appReadOnlyKey: `${localStorageKeyPrefix}-app-read-only`,
  offlineLocalstorageKey: `${localStorageKeyPrefix}-offline`,
  env: 'production',
  vtoEnv,
  remixStoreId: 10001,
  remixPriceServiceUrl,
  loginPageURL,
  remixURL,
  remixEnv,
  fluidWf,
  fluidOakJs,
  analyticsUrl,
  analyticsEnabled: analyticsEnabled === 'true',
  analyticsLocation: 'M4C',
  assets: { URL: assetsUrl },
  authKey: `${localStorageKeyPrefix}-auth`,
  bestMatchCount: Number(bestMatchCount),
  cartLocalstorageKey: `${localStorageKeyPrefix}-cart`, // used to save redux cart state in localStorage
  checkoutCountDown: 30000,
  courtesyInterval: parseInt(courtesyInterval),
  courtesyUrl,
  customOnlyDevice: 'custom-only',
  defaultProductType,
  deviceSignalTimeout: Number(deviceSignalTimeout),
  dressedOnImageService,
  enablePaginationLimit: 15,
  faErrorPageUrl,
  fallbackLanguage: 'en-US',
  iFrameUrl,
  resetAppOnClickLogo: false,
  googleAnalytics: {
    id: 'UA-129072208-2',
    digitalCatalogId: 'UA-129072208-9',
    shopperSlug: 'M4C',
    gtagId: 'dw-gtag-google',
  },
  itemsPerPage: 39,
  labelsUrl,
  log2api: true, // used to trace logs to api server.
  loginRedirectURL,
  loginWithROCP: loginWithROCP === 'true',
  logServerUrl,
  middleware: { URL: REACT_APP_GRAPHQL_API_URL || '' },
  minicartDelay: 0, // 0 to disable the auto close closing minicart event
  name: process.env.NAME || '',
  ooCustomEndPoint,
  ooCustomJsUrl,
  ooCustomOcId,
  opticalCategory: '1',
  productImagesBaseUrl,
  publicUrl: process.env.PUBLIC_URL || '',
  rbCustomEndPoint,
  rbCustomJsUrl,
  rbCustomOcId,
  relicAppId,
  relicEnabled: relicEnabled === 'true',
  relicLicenseKey,
  remixVirtualMirrorProducts: ['0RB2140CP'], // Only original wayfarer is enabled for virtual mirror
  resetAppTimeOut: 420000, // reset every 7 minutes - use 0 or false to disable.
  saveADB2CTokenToLocalStorage,
  screensaverLocalStorageKey: `${localStorageKeyPrefix}-screensaver`, // used to save redux screensaver state in localStorage
  serviceWorkerEnabled: serviceWorkerEnabled === 'true',
  showScreensaver: true,
  storeType: 'multi',
  sunCategory: '2',
  tealiumAnalytics: {
    pagePlatform: 'SSW',
    profile: 'smartshopper2',
    shopperSlug: 'M4C',
    gtagId: 'dw-gtag-tealium',
    isEnabled: analyticsEnabled === 'true',
  },
  toggleFeature: {
    courtesy: featureCourtesyEnabled && featureCourtesyEnabled.toLowerCase() === 'true',
    realTimeRenderer: realTimeRendererEnabled && realTimeRendererEnabled.toLowerCase() === 'true',
    handoff: sessionHandoffEnabled && sessionHandoffEnabled.toLowerCase() === 'true',
  },
  urlKey: `${localStorageKeyPrefix}-url`,
  useVKeyboard: true, // use the virtual keyboard or not
  version: process.env.VERSION,
  vmEnabled: vmEnabled === 'true',
  vmEnv,
  vmKey,
  vmSource,
  vmURL,
  vmJS,
  wishlistLocalstorageKey: `${localStorageKeyPrefix}-wishlist`, // used to save redux wishlist state in localStorage
  rtrJS,
  newMerchAccessoriesBag,
}

export type AppConfig = typeof defaultConfig

const configOverrideSessionKey = getConfigOverrideSessionKey(localStorageKeyPrefix)
const configFromSessionStorage = getConfigFromSessionStorage<AppConfig>(configOverrideSessionKey)
const config: AppConfig = configFromSessionStorage || defaultConfig

export default config
