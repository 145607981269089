import React from 'react'
import { IconProps } from 'types/icons'

export const ShopBagIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#333',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28.333 26.901"
      aria-label="shoppingBagIcon"
      role="button"
    >
      <g fillRule="evenodd" transform="translate(-11.667 -10.833)">
        <path
          fill={fill}
          fillRule="nonzero"
          d="M24.38 10.833a5.215 5.215 0 015.01 6.667h5.827l3.038 20.234H11.667L14.704 17.5h4.668a5.214 5.214 0 015.009-6.667zm9.402 8.334H16.139l-2.537 16.9H36.32l-2.537-16.9zM24.38 12.5a3.547 3.547 0 00-3.238 5h6.475a3.547 3.547 0 00-3.237-5z"
        ></path>
        {/* <circle
          cx="5"
          cy="5"
          r="5"
          fill="#D0021B"
          transform="translate(30 13.333)"
        ></circle> */}
      </g>
    </svg>
  )
}
