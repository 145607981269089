import { pxToRem } from 'libs/styled'
import { generateDefaultFontProperties } from 'libs/typography'
import styled from 'styled-components'
import { ValidateStatus } from 'types/checkoutV3'

export const InputStyled = styled.input<{ validateStatus: ValidateStatus }>`
  ${({ theme, ...props }) => generateDefaultFontProperties(theme, theme.fonts.inputs, { ...props })}
  width: 100%;
  padding-left: 1em;
  border: 1px solid
    ${({ theme, validateStatus }) =>
      validateStatus === 'error'
        ? theme.checkout.input_error_color
        : theme.checkout.input_border_color};
  border-radius: unset;
  letter-spacing: 0.3px;
  line-height: ${pxToRem(48)};
  &::placeholder {
    color: 'rgb(117, 117, 117)' !important;
  }
  &:focus {
    box-shadow: none;
    outline: 0;
  }
`
