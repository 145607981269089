import React from 'react'
import { IconProps } from 'types/icons'
export const FaFaceShapeRectangleUngenderedIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 46"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M43.3129 15.9867C43.1209 7.99465 36.1949 2.54965 29.8409 0.872652C23.8829 -0.701348 18.5629 0.754652 15.6849 4.66965C12.5179 3.02565 9.13595 3.09865 6.27795 4.90565C1.88595 7.68265 -0.442052 13.8787 0.341948 20.6287C0.357948 20.9367 0.785949 28.1926 4.70295 31.5767C5.63095 32.3777 6.67895 32.8837 7.82395 33.1217L8.97095 39.4317C9.66295 43.2367 12.9729 45.9997 16.8419 45.9997H27.2609C31.0609 45.9997 34.3609 43.2947 35.1059 39.5687L36.4399 32.8967C36.9819 32.6427 43.6329 29.2307 43.3129 15.9867ZM10.9389 39.0737L7.45695 19.9197C12.9799 19.3147 16.9859 15.3677 18.4209 11.7427C20.4719 14.3387 25.4119 18.2217 37.0259 19.7707L33.1449 39.1757C32.5859 41.9717 30.1119 43.9997 27.2609 43.9997H16.8419C13.9399 43.9997 11.4579 41.9277 10.9389 39.0737ZM37.0109 30.0437L39.1979 19.1087L38.3339 17.9197C21.1449 15.9127 18.9869 8.81665 18.9699 8.75665L16.9999 8.99965C16.9999 12.5257 12.4859 17.8577 6.24995 17.9977L5.28895 19.1787L7.42095 30.9057C6.92395 30.7037 6.44995 30.4407 6.01295 30.0647C2.74095 27.2417 2.34295 20.5877 2.33295 20.4597C1.63295 14.4057 3.60095 8.96365 7.34695 6.59565C9.82295 5.03065 12.7059 5.12065 15.4699 6.84765L16.8569 6.51465C19.5309 2.05765 25.0529 1.67665 29.3299 2.80665C34.9839 4.29965 41.1459 9.07665 41.3139 16.0357C41.5169 24.4587 38.7739 28.3917 37.0109 30.0437Z"
        fill={fill}
      />
      <path d="M24.0129 35.9997H19.9859V37.9997H24.0129V35.9997Z" fill={fill} />
      <path d="M22.4339 30.9997H19.9859V32.9996H24.4339V23.0567H22.4339V30.9997Z" fill={fill} />
    </svg>
  )
}
