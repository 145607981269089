import React from 'react'
import { useLocation } from 'react-router-dom'
import { isCustomizableEnabledForBrand } from '../../libs/customProducts'
import { getGenderValue } from '../../libs/url'
import { DataAttrs, Product } from '../../types/product'
import { Store } from '../../types/store'
import { ProductCard } from '../CardProductBase'

export interface StandardProductCardProps {
  className?: string
  product: Product
  store: Store
  dataAttrs: DataAttrs
  showWishlistButton?: boolean
  showRTRIcon?: boolean
}

const StandardProductCard: React.FC<StandardProductCardProps> = ({
  className,
  product,
  store,
  dataAttrs,
  showWishlistButton = false,
  showRTRIcon = true,
}) => {
  const location = useLocation()
  const genderValue = getGenderValue(location)

  return (
    <ProductCard
      className={className}
      customizableEnabled={isCustomizableEnabledForBrand(
        product.originalBrand,
        store.remixEnabled,
        store.ocpEnabled
      )}
      showPrice={store.srpStandard}
      product={product}
      showWishlistButton={showWishlistButton}
      showColorsAvailable
      showProductModel
      dataAttrs={dataAttrs}
      gender={genderValue}
      showRTRIcon={showRTRIcon}
      {...(showWishlistButton && { displayWishlistIconAtBottom: true })}
      isOppositeIconArrangement
    />
  )
}

export default StandardProductCard
