import React from 'react'
import { IconProps } from 'types/icons'

export const FaTipsEyeglassesOffIcon = ({
  width = '1rem',
  height = '1rem',
  stroke = '#333',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 99 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="49.152" cy="50.0641" r="48.128" stroke={stroke} strokeWidth="2.048" />
      <g clipPath="url(#clip0_48_52722)">
        <path
          d="M54.8905 62.3522C54.4072 62.3522 53.9956 62.0225 53.8901 61.5504L52.2947 54.365L53.4918 52.5679C53.6822 52.2822 54.0007 52.1122 54.3437 52.1122H69.6321V58.0084L71.6801 55.9604V52.1122H73.7281V50.0642H71.6073L63.271 31.1929C62.5972 29.6702 61.4073 28.4219 59.9204 27.6785L55.7548 25.5957L54.8383 27.4276L59.004 29.5105C60.0669 30.0419 60.9168 30.9328 61.397 32.0203L69.3679 50.0642H54.3437C53.3146 50.0642 52.3592 50.5752 51.7878 51.4322L51.111 52.4481C49.8463 52.0344 48.4578 52.0344 47.1921 52.4481L46.5152 51.4322C45.9449 50.5752 44.9895 50.0642 43.9604 50.0642H28.9362L36.9071 32.0213C37.3883 30.9338 38.2383 30.0419 39.3012 29.5115L43.4668 27.4287L42.5503 25.5967L38.3847 27.6795C36.8968 28.423 35.7069 29.6702 35.0331 31.1939L26.6968 50.0642H24.576V52.1122H26.624V61.3282C26.624 63.0219 28.0024 64.4002 29.6961 64.4002H43.4136C44.8646 64.4002 46.0975 63.411 46.4129 61.9938L48.1045 54.3814C48.1096 54.3599 48.1025 54.3394 48.1055 54.3179C48.7916 54.1418 49.5145 54.1418 50.1996 54.3179C50.2027 54.3394 50.1965 54.3599 50.2006 54.3814L51.8923 61.9938C52.2077 63.411 53.4406 64.4002 54.8916 64.4002H58.4612L56.4132 62.3522H54.8905ZM44.413 61.5504C44.3085 62.0225 43.8969 62.3522 43.4136 62.3522H29.6961C29.1318 62.3522 28.672 61.8934 28.672 61.3282V52.1122H43.9604C44.3034 52.1122 44.6219 52.2822 44.8123 52.5679L46.0094 54.365L44.413 61.5504Z"
          fill={fill}
        />
        <path
          d="M66.643 66.7892L72.746 60.6872L71.297 59.2393L65.195 65.3413L59.092 59.2393L57.644 60.6872L63.7471 66.7892L57.644 72.8923L59.092 74.3402L65.195 68.2382L71.297 74.3402L72.746 72.8923L66.643 66.7892Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_48_52722">
          <rect width="49.152" height="49.152" fill="white" transform="translate(24.576 25.4883)" />
        </clipPath>
      </defs>
    </svg>
  )
}
