import {
  GridSlideCardFAWrapper,
  StyledSliderFA,
} from 'components/FrameAdvisor/GridSliderSuggestionsFA/styles'
import Loading from 'components/Loading'
import { PrimaryButton } from 'components/core/Button'
import { ArrowUpIcon } from 'components/core/Icons/ArrowUp'
import { StickyTitle } from 'components/plp/PLPTitle'
import { Device, landscape, tablet, tabletPortrait } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const FrameAdvisorSuggestionContainer = styled.div`
  touch-action: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${landscape(`
    height: auto;
    display; block;
  `)}
  ${tablet(`
    height: auto;
    display; block;
`)}
`

export const FrameAdvisorSuggestionContentWrapper = styled.div`
  display: flex;
  margin: 0 ${pxToRem(100)};
  background: ${({ theme }) => theme.colors.bodyBackground};
  flex: 1;
  overflow: scroll;

  ${landscape(`
   margin: 0 ${pxToRem(56 - 6)} 0 ${pxToRem(112 - 12)};
   overflow: unset;
  `)};

  ${tablet(`
    margin: 0 ${clampValues(72 - 12, 96 - 12)} 0;
    overflow: unset;
  `)};

  ${landscape(
    Device.tablet,
    `margin: 0 ${clampValues(18 - 12, 40 - 12)} 0 ${clampValuesLandscape(72 - 12, 96 - 12)};`
  )};
`
export const FrameAdvisorSuggestionHeader = styled(StickyTitle)`
  padding-bottom: ${pxToRem(76)};
  ${landscape(`
    padding-bottom: 0;
  `)}
  ${tablet(`
    padding-bottom: ${clampValues(40, 56)};
  `)}
  ${StyledSliderFA} {
    padding: 0 ${pxToRem(112)};
    ${landscape(`
      padding: 0 ${pxToRem(112)} ${pxToRem(32)} 0;
    `)}
    ${tablet(`
      padding: 0 ${clampValues(72, 96)};
    `)}
    ${landscape(
      Device.tablet,
      `padding: 0 ${clampValuesLandscape(42, 62)} ${clampValuesLandscape(32, 32)} 0;
      `
    )}
  }
  ${GridSlideCardFAWrapper} {
    &:first-child {
      ${landscape(`
        margin-top: 0;
    `)}
    }
  }
`

export const FrameAdvisorSuggestions = styled.div`
  width: 100%;
  overscroll-behavior: none;
  ${landscape(`
    padding: 0 ${pxToRem(50)} 0 0;
  `)}
  ${landscape(
    Device.tablet,
    `
  padding: 0 ${clampValues(24 - 12, 48 - 12)} 0 0;
`
  )};
`

export const MessageContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
`

export const MessageTitle = styled.div`
  display: flex;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 85%;
`

export const MessageCTA = styled.div`
  width: auto;
  display: flex;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid #fff;
  margin: 0 auto;
  cursor: pointer;
`
export const IconContainer = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  margin-left: 3px;
`

export const ButtonsContainer = styled.div`
  height: ${pxToRem(190)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${landscape(`
    width: 100%;
    display: flex;
    justify-content: center;
  `)}
`

export const OverPageLoading = styled(Loading)`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`

export const FrameAdvisorToggle = styled.div`
  width: 100%;
  margin-top: ${pxToRem(24)};
  background-color: ${({ theme }) => `${theme.colors.bodyBackground}CC`};
  display: flex;
  justify-content: center;
  ${landscape(`
      position: fixed;
      bottom: 0;
      width: auto;
      margin-top: 0;
    `)}
  ${landscape(
    Device.tower,
    `
      left: ${pxToRem(100)};
      right: ${pxToRem(525)};
      padding-block: ${pxToRem(26)};
    `
  )}
  ${tablet(`
    position: fixed;
    bottom: 0;
    padding-block: ${pxToRem(20)};
    margin-top: 0;
  `)}
  ${landscape(
    Device.tablet,
    `
      left: ${clampValuesLandscape(60, 84)};
      right: ${clampValuesLandscape(328, 436)};
      padding-block: ${pxToRem(20)};
    `
  )}
`

export const ScrollTopButton = styled(PrimaryButton)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-weight: 400;
  padding: ${pxToRem(18)};
  line-height: 1;
  min-width: ${clampValues(42, 65)};
  bottom: ${pxToRem(60)};
  right: 0;
  ${tablet(`
    padding: ${clampValues(18, 22)};
    bottom: ${clampValues(30, 55)};
  `)};
`

export const RoundScrollTopButton = styled(ScrollTopButton)`
  z-index: 2;
  position: fixed;
  left: unset;
  margin: 0;
  right: ${pxToRem(25)};
  bottom: ${pxToRem(142)};
  width: ${pxToRem(60)};
  height: ${pxToRem(60)};
  padding: ${pxToRem(15)};
  text-transform: uppercase;
  line-height: 0;
  border-radius: ${pxToRem(64)};
  ${landscape(`
    position: sticky;
    margin-right: calc(-${pxToRem(50)} - ${pxToRem(30)});
    bottom: ${pxToRem(95)};
    left: 100%;
  `)}
  ${tablet(`
    position: sticky;
    bottom: ${pxToRem(105)};
    margin-right: calc(-${pxToRem(70)});
    left: 100%;
  `)}
  ${landscape(
    Device.tablet,
    `

    font-size: 14px;
    bottom: ${pxToRem(20)};
    padding: unset;
  `
  )}

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const ArrowUpIconStyled = styled(ArrowUpIcon)`
  stroke: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: ${pxToRem(15)};
  width: ${pxToRem(20)};
  height: ${pxToRem(12)};
  ${tablet(`
  top: ${clampValues(15, 20)};
  font-size: ${clampValues(12, 14)};
  `)}
`

export const ScrollText = styled.span`
  position: absolute;
  bottom: ${pxToRem(12)};
  font-size: ${pxToRem(12)};
  ${tablet(`
  bottom: ${clampValues(12, 16)};
  font-size: ${clampValues(12, 14)};
  `)};
  ${tabletPortrait(
    Device.tower,
    `
      bottom: ${pxToRem(18)};
      font-size: ${pxToRem(16)};
    `
  )};
`
