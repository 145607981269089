import React from 'react'
import { IconProps } from 'types/icons'
export const FaBridgePositionIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 48"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <g clipPath="url(#clip0_2756_1099)">
        <path
          d="M23.5 4.41394V13.9999H25.5V4.41394L29.793 8.70694L31.207 7.29294L24.5 0.585938L17.793 7.29294L19.207 8.70694L23.5 4.41394Z"
          fill="black"
        />
        <path
          d="M25.5 43.586V34H23.5V43.586L19.207 39.293L17.793 40.707L24.5 47.414L31.207 40.707L29.793 39.293L25.5 43.586Z"
          fill="black"
        />
        <path
          d="M28.2807 28.8583C28.4007 28.8583 28.5307 28.8383 28.6507 28.7893C29.1607 28.5893 29.4107 27.9993 29.2107 27.4893C29.0007 26.9783 28.4207 26.7283 27.9107 26.9283C27.3907 27.1383 27.1407 27.7183 27.3507 28.2283C27.5007 28.6293 27.8807 28.8583 28.2807 28.8583Z"
          fill="black"
        />
        <path
          d="M46.3798 21.1882C46.5698 21.1882 46.7698 21.1282 46.9398 21.0182C47.3998 20.7082 47.5198 20.0882 47.2198 19.6282C46.9098 19.1682 46.2898 19.0482 45.8298 19.3482C45.3698 19.6592 45.2398 20.2792 45.5498 20.7392C45.7398 21.0292 46.0598 21.1882 46.3798 21.1882Z"
          fill="black"
        />
        <path
          d="M28.5007 23.9707C28.4807 23.4107 28.0207 22.9807 27.4707 23.0007C26.9107 23.0207 26.4807 23.4797 26.5007 24.0407C26.5207 24.5807 26.9607 25.0007 27.5007 25.0007C27.5107 25.0007 27.5207 25.0007 27.5407 25.0007C28.0907 24.9797 28.5207 24.5207 28.5007 23.9707Z"
          fill="black"
        />
        <path
          d="M47.1906 26.9505C46.6706 26.7695 46.1006 27.0595 45.9306 27.5805C45.7506 28.1005 46.0306 28.6705 46.5506 28.8505C46.6606 28.8805 46.7706 28.9005 46.8706 28.9005C47.2906 28.9005 47.6806 28.6405 47.8206 28.2205C48.0006 27.7005 47.7206 27.1305 47.1906 26.9505Z"
          fill="black"
        />
        <path
          d="M37.64 33.0099C37.09 32.9999 36.63 33.4399 36.63 33.9999C36.62 34.5499 37.06 34.9999 37.61 35.0099C37.62 35.0099 37.6201 35.0099 37.6301 35.0099C38.1701 35.0099 38.6201 34.5699 38.6301 34.0199C38.6301 33.4699 38.19 33.0199 37.64 33.0099Z"
          fill="black"
        />
        <path
          d="M34.1206 32.3306C33.6106 32.1306 33.0306 32.3806 32.8306 32.8906C32.6306 33.4116 32.8806 33.9916 33.3906 34.1906C33.5106 34.2416 33.6406 34.2606 33.7606 34.2606C34.1606 34.2606 34.5406 34.0206 34.6906 33.6206C34.8906 33.1096 34.6406 32.5306 34.1206 32.3306Z"
          fill="black"
        />
        <path
          d="M29.73 30.4384C29.35 30.8294 29.36 31.4594 29.75 31.8484C29.94 32.0384 30.2 32.1384 30.45 32.1384C30.71 32.1384 30.97 32.0294 31.16 31.8384C31.55 31.4384 31.54 30.8084 31.15 30.4184C30.75 30.0294 30.12 30.0394 29.73 30.4384Z"
          fill="black"
        />
        <path
          d="M31.2198 16.9393C30.6998 17.1393 30.4498 17.7193 30.6498 18.2293C30.7998 18.6293 31.1798 18.8703 31.5798 18.8703C31.6998 18.8703 31.8198 18.8493 31.9398 18.8093V18.8003C32.4498 18.6003 32.7098 18.0203 32.5098 17.5103C32.3098 16.9903 31.7298 16.7403 31.2198 16.9393Z"
          fill="black"
        />
        <path
          d="M35.3704 16.0803C34.8104 16.1203 34.4004 16.6003 34.4504 17.1493C34.4904 17.6793 34.9304 18.0693 35.4404 18.0693C35.4704 18.0693 35.5004 18.0693 35.5204 18.0693C36.0704 18.0303 36.4804 17.5493 36.4404 16.9903C36.4004 16.4393 35.9204 16.0303 35.3704 16.0803Z"
          fill="black"
        />
        <path
          d="M39.4802 16.069C38.9302 16.03 38.4502 16.439 38.4002 16.99C38.3602 17.54 38.7702 18.019 39.3202 18.069C39.3502 18.069 39.3802 18.069 39.4002 18.069C39.9202 18.069 40.3602 17.67 40.4002 17.149C40.4402 16.6 40.0302 16.109 39.4802 16.069Z"
          fill="black"
        />
        <path
          d="M47.4004 22.9492C46.8404 22.9492 46.4004 23.3982 46.4004 23.9492C46.4004 23.9692 46.4004 23.9892 46.4004 24.0092C46.4004 24.0192 46.4004 24.0192 46.4004 24.0302C46.4204 24.5692 46.8604 25.0002 47.4004 25.0002C47.4104 25.0002 47.4204 25.0002 47.4304 25.0002C47.9904 24.9792 48.4204 24.5202 48.4004 23.9702C48.4004 23.9602 48.4004 23.9602 48.4004 23.9602C48.4004 23.9602 48.4004 23.9502 48.4004 23.9392C48.4004 23.3892 47.9504 22.9492 47.4004 22.9492Z"
          fill="black"
        />
        <path
          d="M44.1999 18.209C44.3999 17.688 44.1399 17.108 43.6199 16.919C43.1099 16.719 42.5299 16.979 42.3399 17.499C42.1399 18.009 42.3999 18.589 42.9199 18.789C43.0299 18.829 43.1499 18.849 43.2699 18.849C43.6699 18.849 44.0499 18.608 44.1999 18.209Z"
          fill="black"
        />
        <path
          d="M41.1599 32.4191C40.6399 32.6081 40.3799 33.1791 40.5699 33.6991C40.7199 34.0981 41.0999 34.3481 41.5099 34.3481C41.6199 34.3481 41.7399 34.3281 41.8599 34.2881C42.3699 34.0981 42.6399 33.5171 42.4399 32.9981C42.2499 32.4781 41.6699 32.2191 41.1599 32.4191Z"
          fill="black"
        />
        <path
          d="M44.1099 30.5401C43.7199 30.9301 43.7199 31.5601 44.1099 31.9501C44.3099 32.1391 44.5599 32.2401 44.8199 32.2401C45.0799 32.2401 45.3299 32.1391 45.5299 31.9501C45.9199 31.5591 45.9199 30.9201 45.5199 30.5301C45.1299 30.1401 44.4999 30.1491 44.1099 30.5401Z"
          fill="black"
        />
        <path
          d="M18.5503 32.13C18.8003 32.13 19.0603 32.04 19.2503 31.85C19.6503 31.46 19.6503 30.83 19.2703 30.43C18.8803 30.04 18.2503 30.031 17.8503 30.42C17.4603 30.81 17.4503 31.44 17.8403 31.83C18.0303 32.03 18.2903 32.13 18.5503 32.13Z"
          fill="black"
        />
        <path
          d="M3.47997 30.5393C3.07997 30.9293 3.07997 31.5593 3.46997 31.9493C3.66997 32.1483 3.91997 32.2393 4.17997 32.2393C4.43997 32.2393 4.68997 32.1483 4.88997 31.9593C5.27997 31.5593 5.27997 30.9293 4.88997 30.5393C4.49997 30.1483 3.86997 30.1483 3.47997 30.5393Z"
          fill="black"
        />
        <path
          d="M1.80058 26.9609C1.28058 27.1309 1.00058 27.7009 1.18058 28.2309C1.32058 28.6409 1.71058 28.9009 2.13058 28.9009C2.23058 28.9009 2.34058 28.8909 2.45058 28.8509C2.97058 28.6709 3.25058 28.1109 3.07058 27.5809C2.90058 27.0609 2.33058 26.7809 1.80058 26.9609Z"
          fill="black"
        />
        <path
          d="M2.04037 21.0683C2.21037 21.1783 2.40037 21.2283 2.59037 21.2283C2.91037 21.2283 3.23037 21.0683 3.43037 20.7793C3.73037 20.3183 3.59037 19.6993 3.13037 19.3983C2.67037 19.0883 2.05037 19.2283 1.75037 19.6883C1.45037 20.1483 1.58037 20.7693 2.04037 21.0683Z"
          fill="black"
        />
        <path
          d="M21.0906 26.9302C20.5806 26.7302 20.0006 26.9802 19.7906 27.4912C19.5906 28.0012 19.8406 28.5812 20.3506 28.7912C20.4706 28.8412 20.6006 28.8602 20.7206 28.8602C21.1206 28.8602 21.5006 28.6212 21.6506 28.2302C21.8606 27.7202 21.6106 27.1302 21.0906 26.9302Z"
          fill="black"
        />
        <path
          d="M1.60029 25.0093C2.13029 25.0093 2.57029 24.5893 2.60029 24.0493C2.62029 23.4993 2.20029 23.0293 1.65029 23.0093C1.09029 22.9793 0.630295 23.4093 0.600295 23.9593C0.580295 24.5093 1.00029 24.9793 1.55029 24.9993C1.57029 25.0093 1.58029 25.0093 1.60029 25.0093Z"
          fill="black"
        />
        <path
          d="M22.5007 24.0405C22.5007 24.0305 22.5007 24.0305 22.5007 24.0305C22.5007 24.0095 22.5007 23.9795 22.5007 23.9605C22.5007 23.9505 22.5007 23.9505 22.5007 23.9395C22.5007 23.3895 22.0507 22.9395 21.4907 22.9395C20.9407 22.9505 20.5007 23.3995 20.5007 23.9505C20.5007 23.9605 20.5007 23.9605 20.5007 23.9605C20.4807 24.5205 20.9107 24.9805 21.4707 25.0005C21.4807 25.0005 21.4907 25.0005 21.5007 25.0005C22.0407 25.0005 22.4807 24.5695 22.5007 24.0405Z"
          fill="black"
        />
        <path
          d="M9.46078 16.0803C8.91078 16.1203 8.50078 16.6003 8.54078 17.1493C8.59078 17.6793 9.02078 18.0693 9.54078 18.0693C9.57078 18.0693 9.59078 18.0693 9.62078 18.0693C10.1708 18.0303 10.5808 17.5403 10.5408 17.0003C10.4908 16.4393 10.0108 16.0303 9.46078 16.0803Z"
          fill="black"
        />
        <path
          d="M7.84013 32.4198C7.33013 32.2198 6.75013 32.4898 6.56013 32.9998C6.37013 33.5198 6.63013 34.0998 7.15013 34.2898C7.26013 34.3298 7.38013 34.3498 7.49013 34.3498C7.90013 34.3498 8.28013 34.0998 8.43013 33.7008C8.62013 33.1888 8.36013 32.6088 7.84013 32.4198Z"
          fill="black"
        />
        <path
          d="M6.61028 17.5106C6.41028 17.0006 5.83029 16.7406 5.32029 16.9406C4.80029 17.1406 4.54028 17.7206 4.74028 18.2306C4.90028 18.6306 5.28028 18.8716 5.68028 18.8716C5.80028 18.8716 5.92028 18.8506 6.04028 18.8106V18.8006C6.55028 18.6006 6.81028 18.0306 6.61028 17.5106Z"
          fill="black"
        />
        <path
          d="M18.3004 18.1999C18.5004 17.6889 18.2404 17.1089 17.7204 16.9199C17.7204 16.9099 17.7204 16.9099 17.7204 16.9099C17.2104 16.7199 16.6304 16.9789 16.4304 17.4999C16.2404 18.0099 16.5004 18.5899 17.0104 18.7899C17.1304 18.8299 17.2504 18.8499 17.3704 18.8499C17.7704 18.8499 18.1504 18.5999 18.3004 18.1999Z"
          fill="black"
        />
        <path
          d="M11.36 33.0099V33.0199C10.81 33.0199 10.37 33.4799 10.38 34.0309C10.39 34.5809 10.8401 35.0199 11.3901 35.0099C11.9401 34.9999 12.3901 34.5499 12.3801 33.9999C12.3701 33.4499 11.92 32.9999 11.36 33.0099Z"
          fill="black"
        />
        <path
          d="M13.5807 16.069C13.0207 16.03 12.5407 16.439 12.5007 16.99C12.4607 17.54 12.8707 18.019 13.4207 18.059C13.4507 18.069 13.4807 18.069 13.5007 18.069C14.0207 18.069 14.4607 17.67 14.5007 17.149C14.5407 16.59 14.1207 16.109 13.5807 16.069Z"
          fill="black"
        />
        <path
          d="M14.88 32.3306C14.36 32.5306 14.11 33.1096 14.31 33.6306C14.47 34.0206 14.84 34.2606 15.24 34.2606C15.36 34.2606 15.49 34.2406 15.61 34.1906C16.12 33.9916 16.38 33.4116 16.17 32.8906C15.97 32.3806 15.39 32.1306 14.88 32.3306Z"
          fill="black"
        />
        <path
          d="M24.4999 18C22.6949 18 20.9339 18.631 19.5419 19.775C19.1149 20.126 19.0539 20.755 19.4039 21.182C19.7549 21.61 20.3849 21.671 20.8109 21.32C22.8829 19.617 26.1169 19.617 28.1889 21.32C28.3749 21.473 28.5999 21.548 28.8229 21.548C29.1119 21.548 29.3979 21.424 29.5959 21.183C29.9469 20.756 29.8849 20.126 29.4579 19.776C28.0659 18.631 26.3049 18 24.4999 18Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2756_1099">
          <rect width={48} height={48} fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
