// TODO: We should use device from styled.ts instead. This will need adjustment as well as change all the places with clampValues
import { ContentImage } from 'components/Image/Index'
import { Text } from 'components/Typography'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const HeaderContainer = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 2.636rem;
  display: flex;
  justify-content: center;
  width: 100vw;

  a {
    width: auto;
    max-height: 4rem;
    height: 100%;
  }
  ${tablet('    padding-top: 1.364rem;    ')}
`

export const ProfileAvatarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: ${pxToRem(41)};
  display: flex;
  align-items: center;
  gap: ${pxToRem(16)};
  text-align: end;
`

export const ProfileAvatarLabel = styled(Text)`
  width: ${pxToRem(74)};
`

export const ProfileAvatarContainer = styled.button`
  width: ${pxToRem(98)};
  height: ${pxToRem(98)};
  border-radius: 50%;
  ${landscape(`
    right: ${pxToRem(114)};
  `)}
  ${tablet(`
    width: ${clampValues(62, 78)};
    height: ${clampValues(62, 78)};
    right: ${clampValues(41, 41)};
  `)}
  ${landscape(
    Device.tablet,
    `
    width: ${clampValuesLandscape(58, 78)};
    height: ${clampValuesLandscape(58, 78)};
    right: ${clampValuesLandscape(30, 41)};
  `
  )}
`
export const ProfileAvatar = styled(ContentImage)`
  border-radius: 50%;
  aspect-ratio: 1 /1;
`
