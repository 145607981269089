import React from 'react'
import { IconProps } from 'types/icons'

export const RetakeScanIcon = ({ width = '24', height = '24', fill = 'white' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="026" clipPath="url(#clip0_22115_104860)">
        <g id="Vector">
          <path
            d="M16 18.5H20.8705C18.801 21.332 15.55 23 12 23C5.9345 23 1 18.0655 1 12H0C0 18.6165 5.3835 24 12 24C15.7735 24 19.2365 22.272 21.5 19.326V24H22.5V17.5H16V18.5Z"
            fill={fill}
          />
          <path
            d="M12 0C8.2265 0 4.764 1.728 2.5 4.674V0H1.5V6.5H8V5.5H3.129C5.199 2.668 8.45 1 12 1C18.0655 1 23 5.9345 23 12H24C24 5.3835 18.6165 0 12 0Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_22115_104860">
          <rect width="24" height="24" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  )
}
