import { Label } from 'components/Typography'
import { CloseIcon } from 'components/core/Icons'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import { isIPadView } from 'libs/utils'
import { NavigationContext } from 'providers/navigationProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { DefaultTheme, ThemeContext } from 'styled-components'

const ContainerPopUp = styled.div`
  display: flex;
  width: ${pxToRem(248)};
  height: ${pxToRem(80)};
  padding: ${pxToRem(20)};
  align-items: center;
  justify-content: space-between;
  gap: ${pxToRem(8)};
  border-radius: ${pxToRem(12)};
  opacity: 0.9;
  background: #555;
  position: absolute;
  right: ${pxToRem(112)};
  z-index: 9999999;
  ${tablet(`
        right: ${clampValues(78, 92)};
        width: ${clampValues(186, 248)};
        height: ${clampValues(66, 80)};
        gap: ${clampValues(6, 8)};
        padding: ${clampValues(15, 20)}};
    `)}
  ${landscape(
    Device.tablet,
    `
        right: ${clampValuesLandscape(74, 92)};
        height: ${clampValuesLandscape(60, 80)};
        width: ${clampValuesLandscape(186, 248)};
        gap: ${clampValuesLandscape(6, 8)};
        padding: ${clampValuesLandscape(15, 20)}};
    `
  )}
`

const TextWrapper = styled.div`
  text-align: left;
  max-width: ${pxToRem(150)};
  ${tablet(`
        max-width: ${clampValues(129, 150)};
    `)}
`

const ClosePopUp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProfilePopUp = () => {
  const { t } = useTranslation()
  const theme: DefaultTheme = useContext(ThemeContext)
  const { landscapeNavigation } = useContext(NavigationContext)

  const isIPad = isIPadView()

  const [showPopUp, setShowPopUp] = useState(false)

  const iconSize = useMemo(() => {
    if (isIPad && landscapeNavigation) {
      return clampValuesLandscape(12, 18)
    }
    if (isIPad) {
      return clampValues(12, 18)
    }
    return pxToRem(18)
  }, [isIPad, landscapeNavigation])

  useEffect(() => {
    setTimeout(() => setShowPopUp(true), 1000)
    setTimeout(() => setShowPopUp(false), 6000)
  }, [])

  return (
    <>
      {showPopUp && (
        <ContainerPopUp data-analytics_available_call="0">
          <TextWrapper>
            <Label color={theme.colors.bodyBackground} weight="bold">
              {t('FrameAdvisor.popUpProfile')}
            </Label>
          </TextWrapper>

          <ClosePopUp onClick={() => setShowPopUp(false)} data-analytics_available_call="0">
            <CloseIcon
              stroke={theme.colors.bodyBackground}
              fill={theme.colors.bodyBackground}
              height={iconSize}
              width={iconSize}
            />
          </ClosePopUp>
        </ContainerPopUp>
      )}
    </>
  )
}

export default ProfilePopUp
