import { setVisibilityWarningModal } from 'actions/ui'
import { Modal } from 'components/core/Modal'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const WarningMessageStyled = styled.p`
  font-size: 2rem;
  text-align: center;
  margin: 0;
`

const ModalStyled = styled(Modal)`
  &.ant-modal {
    .invisible-btn {
      z-index: 1;
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: ${({ theme }) => theme.colors.secondaryText};
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3rem;
    }
  }
`

interface WarningModalProps {
  warningText?: string
}

export const WarningModal: React.FC<WarningModalProps> = ({ warningText }) => {
  const isWarningModalvisible = useSelector(s => s.ui.isWarningModalvisible)
  const dispatch = useDispatch()

  return (
    <ModalStyled
      visible={isWarningModalvisible}
      centered={true}
      width="80%"
      onCancel={() => dispatch(setVisibilityWarningModal(false))}
    >
      <WarningMessageStyled>{warningText}</WarningMessageStyled>
    </ModalStyled>
  )
}
