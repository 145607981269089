import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { DrawerOverlay, DrawerContainer, DrawerTab } from './styles'
import ReactDOM from 'react-dom'

interface DrawerProps {
  position: 'right' | 'left' | 'bottom'
  variant?: 'fullheight' | 'fullscreen' | 'center' | 'bottom' | 'landscape'
  visible?: boolean
  renderTab?: () => React.ReactElement | null
  onOverlayClick: () => void
  className?: string
  unmountOnExit?: boolean
}

export const DrawerBaseContainer: React.FC<DrawerProps> = ({
  children,
  position,
  visible = false,
  variant = 'fullheight',
  renderTab,
  onOverlayClick,
  className,
  unmountOnExit = true,
}) => {
  return (
    <>
      <CSSTransition
        unmountOnExit={unmountOnExit}
        classNames="visible"
        in={visible}
        appear={visible}
        timeout={300}
      >
        <DrawerOverlay
          onClick={() => {
            if (onOverlayClick) {
              onOverlayClick()
            }
          }}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit={unmountOnExit}
        classNames="visible"
        in={visible}
        appear={visible}
        timeout={300}
      >
        <DrawerContainer position={position} variant={variant} className={className}>
          {renderTab && (
            <DrawerTab position={position} variant={variant}>
              {renderTab()}
            </DrawerTab>
          )}
          {children}
        </DrawerContainer>
      </CSSTransition>
    </>
  )
}

const drawerClassName = 'drawer__container'

export const usePortalDiv = () => {
  const [divEl, setDivEl] = useState<Element>()

  useEffect(() => {
    const drawerElement =
      document.querySelector(`.${drawerClassName}`) ||
      document.body.appendChild(document.createElement('div', {}))
    drawerElement.classList.add(drawerClassName)

    setDivEl(drawerElement)
  }, [])

  return divEl
}

export const Drawer: React.FC<DrawerProps> = ({ children, ...props }) => {
  const divEl = usePortalDiv()

  if (!divEl) return null

  return ReactDOM.createPortal(
    <DrawerBaseContainer {...props}>{children}</DrawerBaseContainer>,
    divEl
  )
}

export const DrawerPortal: React.FC = ({ children }) => {
  const divEl = usePortalDiv()

  if (!divEl) return null

  return ReactDOM.createPortal(children, divEl)
}
