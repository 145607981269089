import React from 'react'
import { IconProps } from 'types/icons'
export const FaFrameShapeSquareIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 68 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M65.1667 0.416992H39.6667C38.1012 0.416992 36.8333 1.68491 36.8333 3.25033V8.01458C35.0072 7.38558 32.9913 7.38558 31.1667 8.01458V3.25033C31.1667 1.68491 29.8987 0.416992 28.3333 0.416992H2.83333C1.26792 0.416992 0 1.68491 0 3.25033V28.7503C0 30.3157 1.26792 31.5837 2.83333 31.5837H28.3333C29.8987 31.5837 31.1667 30.3157 31.1667 28.7503V11.1114C32.8993 10.1212 35.1007 10.1212 36.8333 11.1128V28.7503C36.8333 30.3157 38.1012 31.5837 39.6667 31.5837H65.1667C66.7321 31.5837 68 30.3157 68 28.7503V3.25033C68 1.68491 66.7321 0.416992 65.1667 0.416992ZM28.3333 28.7503H2.83333V3.25033H28.3333V28.7503ZM65.1667 28.7503H39.6667V3.25033H65.1667V28.7503Z"
        fill={fill}
      />
    </svg>
  )
}
