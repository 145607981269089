import React from 'react'
import { IconProps } from 'types/icons'
export const FaFrameShapePilotIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 68 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M67.4049 10.0739C66.1087 5.13401 62.8178 1.91534 58.3482 1.24101C56.498 0.997341 54.4665 0.851424 52.4166 0.842924V0.833008H15.5833V0.842924C13.5248 0.852841 11.4863 0.998758 9.62476 1.24526C5.18209 1.91676 1.89117 5.13542 0.594923 10.0753C-1.07533 16.4433 0.933506 23.7972 5.26567 27.1788C6.95009 28.4934 8.98584 29.1593 11.21 29.1593C12.536 29.1593 13.9272 28.9227 15.3523 28.4438C21.8619 26.2593 28.0669 19.465 30.5659 11.9368C32.5422 10.4408 35.452 10.4394 37.4297 11.9354C39.9117 19.4438 46.1266 26.2565 52.6461 28.4438C54.0713 28.9213 55.4624 29.1593 56.7884 29.1593C59.0126 29.1593 61.0483 28.492 62.7328 27.1788C67.0663 23.7958 69.0738 16.4433 67.4049 10.0739ZM28.2412 9.85292C26.3244 17.0298 20.526 23.7207 14.4528 25.7593C12.4113 26.4435 9.46609 26.8572 7.01101 24.9461C3.57417 22.2615 1.96059 16.0466 3.33759 10.7936C3.92267 8.56376 5.57167 4.72317 10.0243 4.05026C11.941 3.79809 13.8124 3.67342 15.5847 3.66351V3.66634H16.174C21.461 3.71451 25.7748 4.81384 27.5102 6.73343C28.3021 7.61034 28.5316 8.61476 28.2412 9.85292ZM31.1609 8.41784C31.0618 7.08476 30.5461 5.86784 29.6097 4.83367C29.2187 4.40159 28.754 4.02051 28.2468 3.66776H39.7502C39.2444 4.02051 38.7783 4.40159 38.3888 4.83367C37.4566 5.86501 36.9409 7.07909 36.8418 8.41926C35.9365 8.09626 34.9774 7.91634 33.9999 7.91634C33.0224 7.91634 32.0662 8.09626 31.1609 8.41784ZM60.9903 24.9461C58.5352 26.8586 55.5885 26.4449 53.5485 25.7593C47.4753 23.7207 41.6754 17.0312 39.7686 9.89401C39.4683 8.61476 39.6978 7.61034 40.4911 6.73343C42.2293 4.81243 46.5516 3.71309 51.8358 3.66634H52.4166V3.66492C54.1832 3.67484 56.0447 3.79384 57.9515 4.04601C62.4296 4.72317 64.0786 8.56376 64.6637 10.7936C66.0407 16.0452 64.4271 22.2615 60.9903 24.9461Z"
        fill={fill}
      />
    </svg>
  )
}
