import { connectRouter, RouterActionType } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'
import app from './app'
import barcode from './barcode'
import cart from './cart'
import checkout from './checkout'
import filters from './filters'
import flows from './flows'
import frameAdvisorReducer from './frameAdvisor'
import order from './order'
import { sessionReducer } from './store'
import ui from './ui'
import wishlist from './wishlist'
import couvette from './couvette'

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    barcode,
    cart,
    order,
    flows,
    wishlist,
    session: sessionReducer,
    app,
    checkout,
    ui,
    frameAdvisor: frameAdvisorReducer,
    filters,
    couvette,
  })

export default createRootReducer

export type StoreActionsTypes = RouterActionType
