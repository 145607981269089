import React from 'react'
import { IconProps } from 'types/icons'

export const ThumbUpPlain: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#333',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34.29 29.95"
      aria-label="likeIcon"
      role="button"
    >
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2" fill={fill}>
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M34.1,14.5a3.33,3.33,0,0,1-.21.63c-.19.37-.42.73-.61,1.09a.46.46,0,0,0-.08.34A3.29,3.29,0,0,1,32.64,20a.37.37,0,0,0-.08.41,3.22,3.22,0,0,1-.44,3.19.8.8,0,0,0-.16.73,3.53,3.53,0,0,1-.85,3.5,3.11,3.11,0,0,1-1.7.9,10.28,10.28,0,0,1-1.75.18H14.72a4,4,0,0,1-2.18-.7.84.84,0,0,0-.65-.16,4.07,4.07,0,0,1-.05.61,1.38,1.38,0,0,1-1.25,1.22H1.68a1.48,1.48,0,0,1-1.1-.37A1.46,1.46,0,0,1,.1,28.43V15.09a1.52,1.52,0,0,1,.44-1.15A1.5,1.5,0,0,1,1.7,13.5h9.72a5.09,5.09,0,0,1,.68.12.44.44,0,0,0,.34,0,9.28,9.28,0,0,0,1-1.09A45.28,45.28,0,0,0,17,6.74a2,2,0,0,0,.25-1.08c0-1.11,0-2.22-.08-3.33a1.82,1.82,0,0,1,1-1.75,3.65,3.65,0,0,1,3.94.21,2.29,2.29,0,0,1,.61.9c.42,1,.82,2.05,1.17,3.09A6.6,6.6,0,0,1,24,8.28c-.17.89-.3,1.78-.46,2.67-.05.31,0,.34.28.34h7.36a3.17,3.17,0,0,1,1.7.4A2.56,2.56,0,0,1,34,13.3l.09.3v.92ZM21.77,12.68c.26-1.56.53-3.09.78-4.62a6.47,6.47,0,0,0,.15-1.64,13.06,13.06,0,0,0-1.33-4.16,1,1,0,0,0-.76-.67,2.28,2.28,0,0,0-1.7.15.61.61,0,0,0-.37.61c.05,1.23.07,2.46.07,3.68a2.47,2.47,0,0,1-.15.86,5.89,5.89,0,0,1-.49,1c-1,1.66-2,3.32-3.1,5a6.21,6.21,0,0,1-1.83,2c-.48.31-1,.63-1.44,1a.42.42,0,0,0-.18.31v10c0,.2.08.22.26.24a4.56,4.56,0,0,1,1,.2,2.51,2.51,0,0,1,.61.36,3.11,3.11,0,0,0,1.73.52H27.8a6.47,6.47,0,0,0,.76,0,2.2,2.2,0,0,0,1.57-.9,1.51,1.51,0,0,0,.09-1.89,3.78,3.78,0,0,0-.79-.59.64.64,0,0,1,.22-.24,2.17,2.17,0,0,0,1.59-1.51,1.36,1.36,0,0,0-.44-1.57,4.28,4.28,0,0,0-.76-.41.92.92,0,0,1,.3-.29,2.15,2.15,0,0,0,1.3-.92A1.48,1.48,0,0,0,31,16.69a.27.27,0,0,1-.18-.18.24.24,0,0,1,0-.18.28.28,0,0,1,.17-.1,2.11,2.11,0,0,0,1.63-1.81,1.34,1.34,0,0,0-.74-1.55,2.59,2.59,0,0,0-.95-.18H21.77ZM10.15,14.93l-.27,0H1.81a.24.24,0,0,0-.29.29v13a.26.26,0,0,0,.07.25.29.29,0,0,0,.25.07h8c.34,0,.34,0,.34-.36V14.94Z" />
        </g>
      </g>
    </svg>
  )
}
