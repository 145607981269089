import React from 'react'
import { IconProps } from 'types/icons'

export const GeolocatorIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M24 11.5H21.4745C21.221 6.6615 17.3385 2.779 12.5 2.5255V0H11.5V2.5255C6.6615 2.779 2.779 6.6615 2.5255 11.5H0V12.5H2.5255C2.779 17.3385 6.6615 21.221 11.5 21.4745V24H12.5V21.4745C17.3385 21.221 21.221 17.3385 21.4745 12.5H24V11.5ZM12.5 20.4745V18H11.5V20.4745C7.2125 20.2235 3.7765 16.7875 3.5255 12.5H6V11.5H3.5255C3.7765 7.2125 7.2125 3.7765 11.5 3.5255V6H12.5V3.5255C16.7875 3.7765 20.2235 7.2125 20.4745 11.5H18V12.5H20.4745C20.2235 16.7875 16.7875 20.2235 12.5 20.4745Z"
        fill={fill}
      />
    </svg>
  )
}
