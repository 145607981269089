import { isAuthenticated } from 'libs/auth'
import { redirectToLoginPage } from 'libs/routing'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

export const AuthChecker = () => {
  const app = useSelector(s => s.app)

  if (isAuthenticated() && app.url) {
    return <Redirect to={`${app.url}`} />
  } else {
    redirectToLoginPage()
  }
}

export default AuthChecker
