import React from 'react'
import { IconProps } from 'types/icons'

export const FaFaceShapeDiamondUngenderedIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 46"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M43.3129 15.9875C43.1209 7.99547 36.1949 2.55047 29.8409 0.873472C23.8829 -0.700528 18.5629 0.756472 15.6849 4.67047C12.5179 3.02647 9.13595 3.09947 6.27795 4.90647C1.88595 7.68347 -0.442052 13.8795 0.341948 20.6295C0.357948 20.9375 0.785949 28.1935 4.70295 31.5775C5.30895 32.1005 5.96895 32.4905 6.66895 32.7735C6.89895 33.1575 7.14795 33.5315 7.41895 33.8925L13.1999 41.6015C15.2669 44.3555 18.5569 46.0005 21.9999 46.0005C25.4429 46.0005 28.7329 44.3555 30.7999 41.6005L36.5809 33.8925C37.1099 33.1875 37.5549 32.4345 37.9259 31.6505C39.4949 30.7205 43.5839 27.2135 43.3129 15.9875ZM21.9999 44.0005C19.1829 44.0005 16.4909 42.6545 14.7999 40.4005L9.01895 32.6925C7.14395 30.1925 6.41995 27.0005 7.03295 23.9355L7.84595 19.8695C12.8809 19.1475 16.9539 15.5125 18.4179 11.8255C20.3819 14.2385 25.0549 17.6955 36.1199 19.6965L36.9679 23.9355C37.5809 27.0005 36.8569 30.1925 34.9819 32.6925L29.1999 40.3995C27.5089 42.6545 24.8169 44.0005 21.9999 44.0005ZM38.9279 23.5425L37.9459 18.6325L37.1339 17.8435C20.3199 14.9625 18.9979 8.89947 18.9899 8.85847L16.9999 9.00047C16.9999 12.2345 12.9819 17.4585 6.92495 17.9615L6.01995 18.8045L5.07195 23.5435C4.69195 25.4415 4.75695 27.3775 5.21395 29.2195C2.68095 26.1005 2.34195 20.5775 2.33395 20.4605C1.63295 14.4065 3.60095 8.96447 7.34695 6.59647C9.82295 5.03147 12.7059 5.12047 15.4699 6.84847L16.8569 6.51547C19.5309 2.05847 25.0529 1.67747 29.3299 2.80747C34.9839 4.30047 41.1459 9.07747 41.3139 16.0365C41.4589 22.0435 40.2939 25.5495 39.0929 27.5545C39.2439 26.2285 39.1949 24.8755 38.9279 23.5425Z"
        fill={fill}
      />
      <path d="M24.0129 36.0005H19.9859V38.0005H24.0129V36.0005Z" fill={fill} />
      <path d="M22.4339 31.0005H19.9859V33.0005H24.4339V23.0575H22.4339V31.0005Z" fill={fill} />
    </svg>
  )
}
