export const SET_AVAILABLE_IN_STORE = 'ui/SET_AVAILABLE_IN_STORE'

export interface SetAvailableInStore {
  type: typeof SET_AVAILABLE_IN_STORE
  payload: {
    productType: string
    status: boolean
  }
}

export const setAvailableInStore = (productType: string, status: boolean): SetAvailableInStore => ({
  type: SET_AVAILABLE_IN_STORE,
  payload: { productType, status },
})

export type FiltersActionTypes = SetAvailableInStore
