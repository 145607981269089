import { BadgeBestMatch } from 'components/BadgeBestMatch'
import ConditionalRender from 'components/ConditionalRender'
import React from 'react'
import { DataAttrs, Product } from 'types/product'
import { BestMatchText } from './BestMatchText'
import { BestMatchDetails } from './styles'

interface BestMatchInfoProps {
  product: Product
  dynamicSentence?: string
  dataAttrs?: DataAttrs
  isDressedOnView?: boolean
}

export const BestMatchInfo: React.FC<BestMatchInfoProps> = ({
  dynamicSentence,
  product,
  isDressedOnView,
}) => {
  return (
    <BestMatchDetails isDressedOnView={isDressedOnView}>
      <BadgeBestMatch />
      <ConditionalRender condition={!!dynamicSentence}>
        <BestMatchText
          dynamicSentence={dynamicSentence}
          product={product}
          isDressedOnView={isDressedOnView}
        />
      </ConditionalRender>
    </BestMatchDetails>
  )
}
