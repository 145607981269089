import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const LoveHateBtnContainer = styled.div`
  position: absolute;
  right: ${pxToRem(20)};
  bottom: ${pxToRem(16)};
  display: flex;
  z-index: 3;
  ${tablet(
    `
    right: ${clampValues(14, 20)};
    bottom: ${clampValues(16, 16)};
  `
  )}
  ${landscape(
    Device.tablet,
    `
    right: ${clampValuesLandscape(14, 20)};
    bottom: ${clampValuesLandscape(16, 16)};
  `
  )}
`
