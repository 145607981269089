import { useNavigationFlowHandler } from 'hooks/useNavigationFlowHandler'
import { default as React, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BarcodeScanModalExactUpc } from './Modals/BarcodeScanModalExactUpc'
import { BarcodeScanModalOnlyCustom } from './Modals/BarcodeScanModalOnlyCustom'
import { BarcodeScanModalOtherPRIZMMoco } from './Modals/BarcodeScanModalOtherPRIZMMoco'
import { BarcodeScanModalOtherPolarizedMoco } from './Modals/BarcodeScanModalOtherPolarizedMoco'
import { BarcodeScanModalSameModel } from './Modals/BarcodeScanModalSameModel'
import { BarcodeScanModalUnavailableNotPolarizedOtherMocos } from './Modals/BarcodeScanModalUnavailableNotPolarizedOtherMocos'
import { BarcodeScanModalUnavailablePRIZMMoco } from './Modals/BarcodeScanModalUnavailablePRIZMMoco'
import { BarcodeScanModalUnavailablePolarized } from './Modals/BarcodeScanModalUnavailablePolarized'

const BarCodeScanModal: React.FC = () => {
  const { productInfo, scenario } = useSelector(s => s.barcode)
  const { barcodeFlow } = useNavigationFlowHandler()

  const history = useHistory()

  const [visible, setVisible] = useState(barcodeFlow)

  const handleCloseModal = () => {
    setVisible(false)
  }

  const onRedirect = (url: string) => {
    history.replace(history.location.pathname)
    history.push(url)
    handleCloseModal()
  }

  const renderModal = () => {
    const modalProps = {
      visible,
      productInfo,
      onCancel: handleCloseModal,
      onRedirect,
    }

    switch (scenario) {
      case 'scenario1':
        return <BarcodeScanModalExactUpc {...modalProps} />
      case 'scenario2B':
      case 'scenario2C':
        return <BarcodeScanModalOtherPRIZMMoco {...modalProps} />
      case 'scenario3B':
        return <BarcodeScanModalOtherPolarizedMoco {...modalProps} />
      case 'scenario4A':
        return <BarcodeScanModalSameModel {...modalProps} />
      case 'scenario4B':
        return <BarcodeScanModalOnlyCustom {...modalProps} />
      case 'scenario5A':
      case 'scenario5B':
        return <BarcodeScanModalUnavailablePRIZMMoco {...modalProps} />
      case 'scenario6':
        return <BarcodeScanModalUnavailablePolarized {...modalProps} />
      case 'scenario7':
        return <BarcodeScanModalUnavailableNotPolarizedOtherMocos {...modalProps} />
      default:
        return null
    }
  }

  return <>{renderModal()}</>
}

export default BarCodeScanModal
