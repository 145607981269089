import React from 'react'
import { IconProps } from 'types/icons'
export const UpcIcon = ({ width = '1rem', height = '1rem' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.7901 10.2615C23.3326 8.518 22.1711 7.382 20.5936 7.144C19.9406 7.058 19.2236 7.0065 18.5001 7.0035V7H5.50012V7.0035C4.77362 7.007 4.05412 7.0585 3.39712 7.1455C1.82912 7.3825 0.667616 8.5185 0.210116 10.262C-0.379384 12.5095 0.329616 15.105 1.85862 16.2985C2.45312 16.7625 3.17162 16.9975 3.95662 16.9975C4.42462 16.9975 4.91562 16.914 5.41862 16.745C7.71612 15.974 9.90612 13.576 10.7881 10.919C11.4856 10.391 12.5126 10.3905 13.2106 10.9185C14.0866 13.5685 16.2801 15.973 18.5811 16.745C19.0841 16.9135 19.5751 16.9975 20.0431 16.9975C20.8281 16.9975 21.5466 16.762 22.1411 16.2985C23.6706 15.1045 24.3791 12.5095 23.7901 10.2615ZM9.96762 10.1835C9.29112 12.7165 7.24462 15.078 5.10112 15.7975C4.38062 16.039 3.34112 16.185 2.47462 15.5105C1.26162 14.563 0.692116 12.3695 1.17812 10.5155C1.38462 9.7285 1.96662 8.373 3.53812 8.1355C4.21462 8.0465 4.87512 8.0025 5.50062 7.999V8H5.70862C7.57462 8.017 9.09712 8.405 9.70962 9.0825C9.98912 9.392 10.0701 9.7465 9.96762 10.1835ZM10.9981 9.677C10.9631 9.2065 10.7811 8.777 10.4506 8.412C10.3126 8.2595 10.1486 8.125 9.96962 8.0005H14.0296C13.8511 8.125 13.6866 8.2595 13.5491 8.412C13.2201 8.776 13.0381 9.2045 13.0031 9.6775C12.6836 9.5635 12.3451 9.5 12.0001 9.5C11.6551 9.5 11.3176 9.5635 10.9981 9.677ZM21.5261 15.5105C20.6596 16.1855 19.6196 16.0395 18.8996 15.7975C16.7561 15.078 14.7091 12.717 14.0361 10.198C13.9301 9.7465 14.0111 9.392 14.2911 9.0825C14.9046 8.4045 16.4301 8.0165 18.2951 8H18.5001V7.9995C19.1236 8.003 19.7806 8.045 20.4536 8.134C22.0341 8.373 22.6161 9.7285 22.8226 10.5155C23.3086 12.369 22.7391 14.563 21.5261 15.5105Z"
        fill="black"
      />
    </svg>
  )
}
