import { IconProps } from 'types/icons'
import React from 'react'
export const OuterglarereductionIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 43"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M8.433 8.82 16.62 17l.871-.87L9.304 7.95h6.78V6.719H7.2v8.875h1.232V8.82zM27.882 7.95h6.78l-8.188 8.18.871.871 8.187-8.181v6.774h1.232V6.72h-8.882v1.23zM35.532 34.159l-8.187-8.181-.87.87 8.186 8.181h-6.779v1.23h8.882v-8.874h-1.232v6.774zM16.083 35.03H9.304l8.187-8.182-.87-.87-8.188 8.18v-6.773H7.201v8.875h8.882v-1.23z"
        fill={fill}
      />
    </svg>
  )
}
