import React from 'react'
import styled from 'styled-components'
import { Store } from 'types/store'

const ScreenSaverStyled = styled.div<{ active: boolean; poster: boolean }>`
  ${({ active }) =>
    active &&
    `
    z-index: 200;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `}

  ${({ poster }) =>
    poster &&
    `
    background-repeat: no-repeat;
    background-size: cover;
  `}
`
const VideoStyled = styled.video`
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
`

interface ScreenSaver {
  store: Store
  isIdleStatus: boolean
  autostart: boolean
  poster?: string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const ScreenSaver: React.FC<ScreenSaver> = ({
  store,
  poster,
  autostart,
  isIdleStatus,
  onClick,
}) => {
  const forceRunScreenSaver = window.location.href.includes('?screensaver=1')
  if ((!store || !isIdleStatus) && !forceRunScreenSaver) return null
  const { url, screensaverUrls, screensaverEnabled } = store

  const style = {
    backgroundImage: 'url(' + poster + ')',
  }

  const showScreensaver = screensaverEnabled && autostart

  return (
    <ScreenSaverStyled
      data-adobe="screensaver_exit"
      style={poster ? style : {}}
      active={showScreensaver}
      poster={poster && !url}
      onClick={onClick}
    >
      {showScreensaver && screensaverUrls && screensaverUrls.length && (
        <VideoStyled id="loopMovie" autoPlay loop muted>
          {screensaverUrls.map(screensaver => (
            <source key={screensaver.id} src={screensaver.url} type={screensaver.type} />
          ))}
        </VideoStyled>
      )}
    </ScreenSaverStyled>
  )
}
