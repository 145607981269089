import { FloatingButton } from 'components/FloatingActions/styles'
import styled, { css, keyframes } from 'styled-components'
import { device } from '../../styles/variables'
import { Drawer } from 'components/Drawer'
import { HeaderContainer } from 'components/Header/styles'

export const StyledDrawer = styled(Drawer)`
  z-index: 5;
  ${HeaderContainer} {
    padding-top: 1rem;

    @media ${device.ipadMiniPortraitReal} {
      padding-top: 1rem;
    }
  }
`

export const CloseButton = styled(FloatingButton)`
  position: absolute;
  top: 1.55rem;
  right: 0;
  width: 3rem;
  height: 3rem;
  padding: 0.9rem;
  border: ${({ theme }) => `1px solid ${theme.colors.alto}`};
  z-index: 4;
`
export const ScanContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8rem;
  align-items: center;
`

export const ScanReader = styled.div`
  position: relative;
  width: 81.25vw;
  height: 71.89vh;
  @media ${device.ipadLandscape} {
    width: 85.94vw;
    height: 61.72vh;
  }
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: ${({ theme }) => theme.colors.black};
`

export const Canvas = styled.canvas`
  display: none;
`

export const ScannerArea = styled.div`
  position: relative;
`

const scannerBorder = css`
  &:before,
  &:after {
    position: absolute;
    width: 1.82rem;
    height: 1.82rem;
    border-color: ${({ theme }) => theme.colors.monza};
    border-style: solid;
    content: ' ';
  }
`

export const ScannerReaderElement = styled.div`
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24.82rem;
  height: 12rem;
  ${scannerBorder};
  &:before {
    top: 0;
    left: 0;
    border-width: 1px 0 0 1px;
  }
  &:after {
    top: 0;
    right: 0;
    border-width: 1px 1px 0 0;
  }
`
export const BottomCornerBorder = styled.div`
  ${scannerBorder};
  & :before {
    bottom: 0;
    right: 0;
    border-width: 0 1px 1px 0;
  }
  &:after {
    bottom: 0;
    left: 0;
    border-width: 0 0 1px 1px;
  }
`

const scan = keyframes`
  0% { top: 50%; }
  25% { top: 1%; }
  75% { top: 98%; }
`

export const ScannerBar = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.monza};
  opacity: 0.7;
  position: relative;
  top: 50%;
  animation-name: ${scan};
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`

export const TopTitle = styled.p`
  color: ${({ theme }) => theme.colors.secondaryText};
  font-family: ${({ theme }) => theme.fontHeaderTitle};
  font-size: ${({ theme }) => theme.fonts.sizes.small};
  margin: 0 0 0.73rem;
`
export const BottomTitle = styled.p`
  color: ${({ theme }) => theme.colors.secondaryText};
  font-size: ${({ theme }) => theme.fonts.sizes.small};
  font-family: ${({ theme }) => theme.fontHeaderTitle};
  margin: 1.09rem 0 0;
`
