import React from 'react'
import { IconProps } from 'types/icons'

export const CustomizeIcon: React.FC<IconProps> = ({ width = '100%', height = '100%' }) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="2 2 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8082 29.6162C23.434 29.6162 29.6162 23.434 29.6162 15.808C29.6162 14.4541 29.4211 13.1452 29.0579 11.9088L24.5069 13.2377C24.7474 14.0525 24.8764 14.9153 24.8764 15.808C24.8764 20.8164 20.8163 24.8765 15.8082 24.8765C10.7998 24.8765 6.73996 20.8164 6.73996 15.808C6.73996 10.7997 10.7998 6.73976 15.8082 6.73976C16.1892 6.73976 16.5648 6.76328 16.9335 6.8089L17.5756 2.11195C16.9969 2.03815 16.4071 2 15.8082 2C8.18209 2 2 8.18211 2 15.808C2 23.434 8.18209 29.6162 15.8082 29.6162Z"
        fill="url(#paint0_angular_9555_278588)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8287 7.52068L22.6982 5.68137L14.0695 16.1225L14.2825 18.1241L16.2534 17.9618L24.8287 7.52068ZM23.3902 4.8698L25.5207 6.70912L26.7991 5.14026L24.6685 3.30094L23.3902 4.8698ZM24.9344 2.11074L27.9172 4.65335C28.1302 4.86983 28.1834 5.19437 27.9704 5.41074L16.8918 18.8273C16.8383 18.9355 16.6787 18.9896 16.5722 18.9896L13.8022 19.2601C13.5361 19.2601 13.2698 19.0437 13.2698 18.7732L13.0034 15.96C13.0034 15.8518 13.0567 15.6896 13.11 15.5814L24.1888 2.21893C24.4017 1.94847 24.7214 1.94847 24.9344 2.11074Z"
        fill="#A6A6A6"
      />
      <defs>
        <radialGradient
          id="paint0_angular_9555_278588"
          fx="0%"
          fy="52%"
          cx="0%"
          cy="100%"
          r="100%"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5 -6) scale(2)"
        >
          <stop offset="0.0679789" stopColor="#F29C1A" />
          <stop offset="0.184621" stopColor="#E32021" />
          <stop offset="0.277105" stopColor="#E61983" />
          <stop offset="0.371526" stopColor="#D51F84" />
          <stop offset="0.469699" stopColor="#155BA7" />
          <stop offset="0.56866" stopColor="#00ABE8" />
          <stop offset="0.67996" stopColor="#88BF52" />
          <stop offset="0.765651" stopColor="#DADB00" />
          <stop offset="0.966801" stopColor="#FFE810" />
        </radialGradient>
      </defs>
    </svg>
  )
}
