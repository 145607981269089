import styled, { css } from 'styled-components'
import { Col } from '../Grid'
import { pxToRem } from 'libs/styled'
import { tabletPortrait } from 'libs/media'
import { Text } from 'components/Typography'

export const FormItemWrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: ${pxToRem(16)};
  vertical-align: top;
  ${tabletPortrait(`
     line-height: normal;
  `)}
`
export const LabelWrapper = styled(Col)`
  padding-bottom: 0;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

export const LabelStyled = styled.label<{ required?: boolean }>`
  margin-bottom: ${pxToRem(10)};
  text-transform: capitalize;
  display: flex;
  ${({ required }) =>
    required &&
    css`
      &::before,
      &::after {
        content: '*';
        margin-right: ${pxToRem(8)};
        font-size: ${pxToRem(20)};
        color: ${({ theme }) => theme.colors.monza};
        line-height: 1;
      }

      &::before {
        display: inline-block;
      }

      &::after {
        display: none;
      }
    `}
`

export const HelpStyled = styled(Text)`
  text-align: right;
  color: ${({ theme }) => theme.colors.monza};
  margin-bottom: ${pxToRem(-5)};
  margin-top: ${pxToRem(2)};
  min-height: ${pxToRem(22)};
`

export const ValidateWrapper = styled.div`
  &.has-error {
    color: ${({ theme }) => theme.colors.monza};
  }
`
