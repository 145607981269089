import React from 'react'
import { IconProps } from 'types/icons'
export const FaceLengthLongIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      strokeWidth={0.2}
      className={className}
    >
      <path
        d="M15.9997 1.33325C10.118 1.33325 5.33301 7.91259 5.33301 15.9999C5.33301 24.0872 10.118 30.6666 15.9997 30.6666C21.8813 30.6666 26.6663 24.0872 26.6663 15.9999C26.6663 7.91259 21.8813 1.33325 15.9997 1.33325ZM15.9997 29.9999C10.4857 29.9999 5.99967 23.7196 5.99967 15.9999C5.99967 8.28025 10.4857 1.99992 15.9997 1.99992C21.5137 1.99992 25.9997 8.28025 25.9997 15.9999C25.9997 23.7196 21.5137 29.9999 15.9997 29.9999Z"
        fill={fill}
      />
      <path
        d="M1.33301 1.99992H1.99967V29.9999H1.33301V30.6666H3.33301V29.9999H2.66634V1.99992H3.33301V1.33325H1.33301V1.99992Z"
        fill={fill}
      />
      <path d="M17.3327 22.6665H14.666V23.3332H17.3327V22.6665Z" fill={fill} />
      <path
        d="M16.6663 19.3333H14.666V20H17.3323L17.3383 14.3337L16.6717 14.333L16.6663 19.3333Z"
        fill={fill}
      />
    </svg>
  )
}
