import { History } from 'history'
import qs from 'qs'
import { useMemo } from 'react'
import { useNavigationFlowHandler } from './useNavigationFlowHandler'

interface UsePDPRouteParamsProps {
  comesFromBarcode: boolean
  comesFromFrameAdvisor: boolean
  exactUpc: boolean
}

export const usePDPRouteParams = (history: History): UsePDPRouteParamsProps => {
  const { search } = history.location
  const { barcodeFlow } = useNavigationFlowHandler()
  return useMemo(() => {
    const parsedUrl = qs.parse(search, { ignoreQueryPrefix: true })
    const comesFromFrameAdvisor = parsedUrl.fromFrameAdvisor === 'true'
    const exactUpc = parsedUrl.exactUpc === 'true'
    return {
      comesFromBarcode: barcodeFlow,
      comesFromFrameAdvisor,
      exactUpc,
    }
  }, [barcodeFlow, search])
}
