import { ApolloError } from '@apollo/client'
import ErrorWidget from 'components/ErrorWidget'
import Loading from 'components/Loading'
import { usePrevious } from 'hooks/usePreviousNonNullish'
import { i18n } from 'i18next'
import { getAuthToken } from 'libs/auth'
import { parseGrapqhQLError } from 'libs/errors'
import { getLangFromUrl } from 'libs/url'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { I18nContext } from 'react-i18next'
import { history } from 'store'
import { initI18n } from '../i18n'

interface TranslationFetcherProps {
  children: React.ReactNode
}

const TranslationProvider: React.FC<TranslationFetcherProps> = ({ children }) => {
  const checkIsAuthenticated = () => !!getAuthToken()
  const currentLang = getLangFromUrl()

  const [error, setError] = useState<ApolloError>()

  const [i18nInstance, setI18nInstance] = useState<i18n | undefined>()

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(checkIsAuthenticated())
  const prevIsAuthenticated = usePrevious(isAuthenticated)

  const [lang, setLang] = useState<string>(currentLang)
  const prevLang = usePrevious(lang)

  const getAndStoreI18nInstance = useCallback(() => {
    const i18nInstance = initI18n(lang, setError)
    setI18nInstance(i18nInstance)
  }, [lang])

  // every time the lang changes or the authentication status changes we want to refresh the component
  history.listen(() => {
    const langFromUrl = getLangFromUrl()

    setIsAuthenticated(checkIsAuthenticated())
    setLang(langFromUrl)
  })

  // we need this to fetch translations on the first render
  useEffect(() => {
    getAndStoreI18nInstance()
  }, [getAndStoreI18nInstance])

  // we need this to refetch transaltions every time the lang or the authentication status change
  useEffect(() => {
    if (lang !== prevLang || isAuthenticated !== prevIsAuthenticated) {
      getAndStoreI18nInstance()
    }
  }, [getAndStoreI18nInstance, i18nInstance, isAuthenticated, lang, prevIsAuthenticated, prevLang])

  if (error) {
    return <ErrorWidget {...parseGrapqhQLError(error)} withWrapper />
  }

  return i18nInstance ? (
    <I18nContext.Provider value={{ i18n: i18nInstance }}>{children}</I18nContext.Provider>
  ) : (
    <Loading />
  )
}

export const useGetI18n = () => {
  const context = useContext(I18nContext)
  return context.i18n
}

export default TranslationProvider
