import { SessionHandoffContent } from 'components/Contents/SessionHandoffContent'
import { Heading4, Text } from 'components/Typography'
import { Modal } from 'components/core/Modal'
import { pxToRem } from 'libs/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: ${pxToRem(149)} ${pxToRem(104)} ${pxToRem(170)} ${pxToRem(104)};
    max-width: ${pxToRem(810)};
    max-height: ${pxToRem(750)};
    text-align: center;
  }

  .ant-modal-content {
    border-radius: ${pxToRem(16)};
  }
`

const TransferSessionModalContent = styled.div``

const Info = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${pxToRem(32)};
  margin-bottom: ${pxToRem(64)};
`

const TextStyled = styled(Text)`
  margin-left: ${pxToRem(16)};
`

interface TransferSessionModalProps {
  visible: boolean
  onClose: () => void
}

export const TransferSessionModal: React.FC<TransferSessionModalProps> = ({ visible, onClose }) => {
  const { t } = useTranslation()

  return (
    <ModalStyled visible={visible} onCancel={onClose}>
      <TransferSessionModalContent>
        <Heading4 weight="bold">{t('FrameAdvisor.onboarding.qrcodeTitle')}</Heading4>
        <Info>
          <TextStyled weight="bold">{t('FrameAdvisor.onboarding.assistance')}</TextStyled>
        </Info>
        <SessionHandoffContent onScanCompleted={onClose} />
      </TransferSessionModalContent>
    </ModalStyled>
  )
}
