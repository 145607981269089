import { CheckoutFormDataField } from '../../types/checkoutV3'

export const useCheckoutFields = () => {
  const fields = [
    'firstName',
    'lastName',
    'email',
    'emailVerification',
    'phone',
    'address',
    'address1',
    'zip',
    'city',
    'state',
    'shipping',
    'shippingType',
  ]

  return (fields || []) as CheckoutFormDataField[]
}
