import { isIPadView } from 'libs/utils'
import styled from 'styled-components'

interface DrawerContainerProps {
  position: 'right' | 'left' | 'bottom'
  variant: 'fullheight' | 'fullscreen' | 'center' | 'bottom' | 'landscape'
}

interface DrawerTabProps {
  position: 'right' | 'left' | 'bottom'
  variant: 'fullheight' | 'fullscreen' | 'center' | 'bottom' | 'landscape'
}

export const variantMap = (
  position: 'right' | 'left' | 'bottom' = 'right',
  variant: 'fullheight' | 'fullscreen' | 'center' | 'landscape' | 'bottom' = 'fullheight'
): {
  'min-height': string
  height: string
  width: string
  top: string
  bottom: string
} =>
  ({
    right: {
      fullheight: {
        'min-height': '100%',
        height: 'auto',
        width: '29.136rem',
        top: '0',
        bottom: '0',
      },
      fullscreen: {
        'min-height': '100%',
        height: 'auto',
        width: '100vw',
        top: '0',
        bottom: '0',
      },
      center: {
        'min-height': '0',
        height: 'auto',
        width: '37.7273rem',
        top: isIPadView() ? '5%' : '19%',
        bottom: 'auto',
      },
      bottom: {
        'min-height': '0',
        height: 'auto',
        width: '37.7273rem',
        top: isIPadView() ? '5%' : '28%',
        bottom: 'auto',
      },
      landscape: {
        'min-height': '100%',
        height: 'auto',
        width: '23.091rem',
        top: '0',
        bottom: '0',
      },
    },

    left: {
      fullheight: {
        'min-height': '100%',
        height: 'auto',
        width: '29.136rem',
        top: '0',
        bottom: '0',
      },
      fullscreen: {
        'min-height': '100%',
        height: 'auto',
        width: '100vw',
        top: '0',
        bottom: '0',
      },
      center: {
        'min-height': '0',
        height: 'auto',
        width: '37.7273rem',
        top: isIPadView() ? '5%' : '19%',
        bottom: 'auto',
      },
      bottom: {
        'min-height': '0',
        height: 'auto',
        width: '37.7273rem',
        top: isIPadView() ? '5%' : '28%',
        bottom: 'auto',
      },
      landscape: {
        'min-height': '100%',
        height: 'auto',
        width: '23.091rem',
        top: '0',
        bottom: '0',
      },
    },

    bottom: {
      fullheight: {
        'min-height': '0',
        height: 'auto',
        width: '92.5%',
        top: 'auto',
        bottom: '0',
      },
      fullscreen: {
        'min-height': '0',
        height: 'auto',
        width: '92.5%',
        top: 'auto',
        bottom: '0',
      },
      center: {
        'min-height': '0',
        height: 'auto',
        width: '92.5%',
        top: 'auto',
        bottom: '0',
      },
      bottom: {
        'min-height': '0',
        height: 'auto',
        width: '92.5%',
        top: 'auto',
        bottom: '0',
      },
      landscape: {
        'min-height': '0',
        height: 'auto',
        width: '92.5%',
        top: 'auto',
        bottom: '0',
      },
    },
  }[position][variant])

export const positionMap = (position: 'right' | 'left' | 'bottom' = 'right') =>
  ({
    left: {
      enterTransform: 'translateX(-100%)',
      doneTransform: 'translateX(0)',
      left: '0',
    },
    right: {
      enterTransform: 'translateX(0)',
      doneTransform: 'translateX(-100%)',
      left: '100vw',
    },
    bottom: {
      enterTransform: 'translate(-50%, calc(100% - 4rem))',
      doneTransform: 'translate(-50%, -4rem)',
      left: '50%',
    },
  }[position])

export const tabVariantMap = (
  position: 'right' | 'left' | 'bottom' = 'right',
  variant: 'fullheight' | 'fullscreen' | 'center' | 'landscape' | 'bottom' = 'fullheight'
): {
  bottom: string
  left: string
  transform: string
  'transform-origin': string
} => {
  return {
    right: {
      fullheight: {
        bottom: '50%',
        left: '0',
        transform: 'translateX(-50%) rotate(-90deg)',
        'transform-origin': '50% 100%',
      },
      fullscreen: {
        bottom: '50%',
        left: '0',
        transform: 'translateX(-50%) rotate(-90deg)',
        'transform-origin': '50% 100%',
      },
      center: {
        bottom: isIPadView() ? '30%' : '50%',
        left: '0',
        transform: 'translateX(-50%) rotate(-90deg)',
        'transform-origin': '50% 100%',
      },
      bottom: {
        bottom: '0',
        left: '0',
        transform: 'rotate(-90deg)',
        'transform-origin': '0 100%',
      },
      landscape: {
        bottom: '50%',
        left: '0',
        transform: 'translateX(-50%) rotate(-90deg)',
        'transform-origin': '50% 100%',
      },
    },

    left: {
      fullheight: {
        bottom: '50%',
        left: '0',
        transform: 'translateX(-50%) rotate(-90deg)',
        'transform-origin': '50% 100%',
      },
      fullscreen: {
        bottom: '50%',
        left: '0',
        transform: 'translateX(-50%) rotate(-90deg)',
        'transform-origin': '50% 100%',
      },
      center: {
        bottom: '50%',
        left: '0',
        transform: 'translateX(-50%) rotate(-90deg)',
        'transform-origin': '50% 100%',
      },
      bottom: {
        bottom: '0',
        left: '0',
        transform: 'rotate(-90deg)',
        'transform-origin': '0 100%',
      },
      landscape: {
        bottom: '50%',
        left: '0',
        transform: 'translateX(-50%) rotate(-90deg)',
        'transform-origin': '50% 100%',
      },
    },

    bottom: {
      fullheight: {
        bottom: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        'transform-origin': '0 0',
      },
      fullscreen: {
        bottom: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        'transform-origin': '0 0',
      },
      center: {
        bottom: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        'transform-origin': '0 0',
      },
      bottom: {
        bottom: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        'transform-origin': '0 0',
      },
      landscape: {
        bottom: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        'transform-origin': '0 0',
      },
    },
  }[position][variant]
}

export const DrawerContainer = styled.div<DrawerContainerProps>`
  z-index: 4;
  position: fixed;
  top: ${({ position, variant }) => variantMap(position, variant).top};
  bottom: ${({ position, variant }) => variantMap(position, variant).bottom};
  left: ${({ position }) => positionMap(position).left};
  display: flex;
  align-items: flex-start;
  width: ${({ position, variant }) => variantMap(position, variant).width};
  height: ${({ position, variant }) => variantMap(position, variant).height};
  transition: transform 300ms ease-in-out;
  min-height: ${({ position, variant }) => variantMap(position, variant)['min-height']};
  background: ${({ theme }) => theme.colors.bodyBackground};
  color: ${({ theme }) => theme.colors.primaryText};
  overflow: auto;

  &,
  &.visible-enter {
    transform: ${({ position }) => positionMap(position).enterTransform};
  }

  &.visible-enter-active,
  &.visible-enter-done {
    transform: ${({ position }) => positionMap(position).doneTransform};
  }
`
export const DrawerOverlay = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.25);
  transition: 0.3s opacity ease-in-out;

  &,
  &.visible-enter {
    opacity: 0;
    width: 0;
    height: 0;
    min-height: none;
  }

  &.visible-enter-active,
  &.visible-enter-done {
    opacity: 1;
    width: 100vw;
  }
`

export const DrawerTab = styled.div<DrawerTabProps>`
  position: absolute;
  bottom: ${({ position, variant }) => tabVariantMap(position, variant).bottom};
  left: ${({ position, variant }) => tabVariantMap(position, variant).left};
  flex-shrink: 0;
  transform: ${({ position, variant }) => tabVariantMap(position, variant).transform};
  transform-origin: ${({ position, variant }) =>
    tabVariantMap(position, variant)['transform-origin']};
`
