import React from 'react'
import { IconProps } from 'types/icons'
export const FaThicknessMediumIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 96 28"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M95.113 20.825L91.39 16.73C82.862 7.348 70.772 2 58.093 2H31V4H58.093C70.182 4 81.779 9.13 89.91 18.075L93.633 22.17C94.029 22.606 94.02 23.089 93.979 23.339C93.938 23.589 93.792 24.05 93.277 24.336L92.194 24.937C91.675 25.225 91.088 25.378 90.497 25.378C89.392 25.378 88.375 24.874 87.707 23.995L81.916 16.376C77.405 10.44 70.259 6.896 62.801 6.896C59.593 6.896 56.462 7.529 53.495 8.778L50.632 9.983L31 9.214V11.216L51 12L54.273 10.622C57.04 9.457 59.937 8.896 62.803 8.896C69.523 8.896 76.066 11.98 80.326 17.586L86.117 25.205C87.189 26.616 88.828 27.377 90.499 27.377C91.407 27.377 92.325 27.152 93.167 26.684L94.25 26.082C96.165 25.019 96.588 22.447 95.113 20.825Z"
        fill={fill}
      />
      <path d="M2 8.077V4H17V2H0V10L17 10.667V8.665L2 8.077Z" fill={fill} />
      <path d="M25 11V2H27.999V0H20V2H23V11H20V13H27.999V11H25Z" fill={fill} />
    </svg>
  )
}
