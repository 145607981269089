import React, { PureComponent } from 'react'
import Draggable, { DraggableEventHandler } from 'react-draggable'
import reactTriggerChange from 'react-trigger-change'
import styled from 'styled-components'

import { KeyboardButton } from './KeyboardButton'

import CyrillicLayout from './layouts/CyrillicLayout'
import FrenchLayout from './layouts/FrenchLayout'
import GermanLayout from './layouts/GermanLayout'
import LatinLayout from './layouts/LatinLayout'
import PortugueseLayout from './layouts/PortugueseLayout'
import SpanishLayout from './layouts/SpanishLayout'
import SymbolsLayout from './layouts/SymbolsLayout'

import BackspaceIcon from './icons/BackspaceIcon'
import DraggableIcon from './icons/DraggableIcon'
import LanguageIcon from './icons/LanguageIcon'
import ShiftIcon from './icons/ShiftIcon'
import CapsLockIcon from './icons/CapsLockIcon'
import {
  ClickHandler,
  KeyboardProps,
  KeyboardState,
  LangLayoutMap,
} from '../../types/touch-keyboard'

const KeyboardRow = styled.div`
  display: flex;

  .secondary-keyboard.secondary-keyboard--active {
    background-color: #005192;
    border-color: #005192;
    color: #ffffff;
  }
`
const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

const langLayoutMap: LangLayoutMap = {
  de: {
    layout: GermanLayout,
    layoutName: 'GermanLayout',
  },
  fr: {
    layout: FrenchLayout,
    layoutName: 'FrenchLayout',
  },
  ru: {
    layout: CyrillicLayout,
    layoutName: 'CyrillicLayout',
  },
  es: {
    layout: SpanishLayout,
    layoutName: 'SpanishLayout',
  },
  pt: {
    layout: PortugueseLayout,
    layoutName: 'PortugueseLayout',
  },
  default: {
    layout: LatinLayout,
    layoutName: 'LatinLayout',
  },
  symbols: {
    layout: SymbolsLayout,
    layoutName: 'SymbolsLayout',
  },
}

class KeyboardComponent extends PureComponent<KeyboardProps, KeyboardState> {
  constructor(props: KeyboardProps) {
    super(props)

    this.state = {
      currentLanguage: props.defaultKeyboard,
      showSymbols: false,
      uppercase: this.isUppercase(),
      showSecondaryKeyboard: false,
    }
  }

  static defaultProps = {
    dataset: {
      type: 'input',
    },
    defaultKeyboard: 'us',
    isFirstLetterUppercase: true,
    rightButtons: [],
    showNumericRow: true,
    showSymbols: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    storePosition: () => {},
    uppercaseAfterSpace: false,
    secondaryKeyboard: 'LatinLayout',
  }

  componentDidUpdate(prevProps: KeyboardProps) {
    if (
      this.props.secondaryKeyboard !== prevProps.secondaryKeyboard ||
      this.props.defaultKeyboard !== prevProps.defaultKeyboard
    ) {
      this.setState(state => ({
        currentLanguage:
          state.currentLanguage !== prevProps.defaultKeyboard && this.props.secondaryKeyboard
            ? this.props.secondaryKeyboard
            : this.props.defaultKeyboard,
      }))
    }
  }

  getKeys = (): string[][] => {
    const { currentLanguage, showSymbols, uppercase, showSecondaryKeyboard } = this.state

    let selectedLayout = langLayoutMap['default']

    if (
      !showSecondaryKeyboard &&
      typeof currentLanguage === 'string' &&
      currentLanguage in langLayoutMap
    ) {
      selectedLayout = langLayoutMap[currentLanguage]
    }
    if (showSymbols) {
      selectedLayout = langLayoutMap['symbols']
    }

    const keysSet = selectedLayout.layout

    return uppercase
      ? keysSet.map((keyRow: string[]) => keyRow.map(key => key.toUpperCase()))
      : keysSet
  }

  getSymbolsKeyValue = () => {
    const { showSymbols, currentLanguage } = this.state
    let symbolsKeyValue

    switch (currentLanguage) {
      case 'de':
        symbolsKeyValue = 'Abc'
        break
      case 'ru':
        symbolsKeyValue = 'Абв'
        break
      default:
        symbolsKeyValue = 'Abc'
        break
    }

    if (!showSymbols) symbolsKeyValue = '.?!&'

    return symbolsKeyValue
  }

  static focusWithSelectionRange(inputNode: HTMLInputElement, selectionPosition: number) {
    inputNode.focus()
    inputNode.setSelectionRange(selectionPosition, selectionPosition)
  }

  handleLanguageClick: ClickHandler = () => {
    if (this.props.secondaryKeyboard) {
      this.setState(state => ({
        showSymbols: false,
        showSecondaryKeyboard: !state.showSecondaryKeyboard,
      }))
    }
  }

  handleShiftClick: ClickHandler = () => {
    const { inputNode } = this.props
    inputNode && inputNode.focus()
    // Uppercase has 3 states, 0 - the state is lowercase, 1 - the state is in uppercase with Shift option, 2 - the state is in Caps Lock
    this.setState(prevState => ({
      uppercase:
        prevState.uppercase % 2 === 0 && prevState.uppercase !== 0 ? 0 : prevState.uppercase + 1,
    }))
  }

  handleSymbolsClick: ClickHandler = () => {
    const { inputNode } = this.props
    inputNode && inputNode.focus()
    this.setState(prevState => ({ showSymbols: !prevState.showSymbols }))
  }

  handleLetterButtonClick: ClickHandler = key => {
    const { inputNode } = this.props
    if ((typeof key !== 'string' && typeof key !== 'number') || !inputNode) return
    const stringKey = key.toString()
    const { value } = inputNode
    const selectionStart =
      inputNode.selectionStart !== null ? inputNode.selectionStart : value.length
    const selectionEnd = inputNode.selectionEnd !== null ? inputNode.selectionEnd : value.length
    const nextValue = value.substring(0, selectionStart) + stringKey + value.substring(selectionEnd)
    const nextSelectionPosition = selectionStart + stringKey.length
    inputNode.value =
      inputNode.maxLength > 0 ? nextValue.substring(inputNode.maxLength, -1) : nextValue
    reactTriggerChange(inputNode)

    Keyboard.focusWithSelectionRange(inputNode, nextSelectionPosition)
    this.setState({ uppercase: this.isUppercase() })
    inputNode.dispatchEvent(new CustomEvent('input'))
  }

  handleDragKeyClick: ClickHandler = () => {
    const { inputNode } = this.props
    inputNode && inputNode.focus()
  }

  isUppercase = (): number => {
    const { inputNode, isFirstLetterUppercase = false, uppercaseAfterSpace = false } = this.props

    if (this.state && this.state.uppercase && this.state.uppercase === 2) return 2

    return !!inputNode &&
      inputNode.type !== 'password' &&
      ((!inputNode.value.length && isFirstLetterUppercase) ||
        (inputNode.value.length > 0 &&
          inputNode.value[inputNode.value.length - 1] === ' ' &&
          uppercaseAfterSpace))
      ? 1
      : 0
  }

  handleBackspaceClick: ClickHandler = () => {
    const { inputNode } = this.props
    if (!inputNode) return

    const { value } = inputNode
    const selectionStart =
      inputNode.selectionStart !== null ? inputNode.selectionStart : value.length
    const selectionEnd = inputNode.selectionEnd !== null ? inputNode.selectionEnd : value.length
    let nextValue
    let nextSelectionPosition: number
    if (selectionStart === selectionEnd) {
      nextValue = value.substring(0, selectionStart - 1) + value.substring(selectionEnd)
      nextSelectionPosition = selectionStart - 1
    } else {
      nextValue = value.substring(0, selectionStart) + value.substring(selectionEnd)
      nextSelectionPosition = selectionStart
    }
    nextSelectionPosition = nextSelectionPosition > 0 ? nextSelectionPosition : 0

    inputNode.value = nextValue
    reactTriggerChange(inputNode)

    Keyboard.focusWithSelectionRange(inputNode, nextSelectionPosition)
    this.setState({ uppercase: this.isUppercase() })
    inputNode.dispatchEvent(new CustomEvent('input'))
  }

  storePosition: DraggableEventHandler = (_, data) => {
    this.props.storePosition(data.x, data.y)
  }

  render() {
    const { coords, secondaryKeyboard, hideKeyboardLabel, className } = this.props
    const { showSecondaryKeyboard, currentLanguage } = this.state
    const keys = this.getKeys()
    const symbolsKeyValue = this.getSymbolsKeyValue()

    const isPrimaryKeyboardEqualToSecondary =
      currentLanguage &&
      typeof currentLanguage === 'string' &&
      (!(currentLanguage in langLayoutMap) ||
        langLayoutMap[currentLanguage].layoutName === secondaryKeyboard)

    const vKeyboardBounds = this.props.bounds || 'body'

    return (
      <Draggable
        defaultPosition={coords}
        position={coords}
        handle=".keyboard-drag-icon"
        bounds={vKeyboardBounds}
        onStop={this.storePosition}
      >
        <div
          className={`${className} keyboard-wrapper`}
          data-analytics_available_call="0"
          style={{
            opacity: typeof this.props.opacity !== 'undefined' ? this.props.opacity : 1,
          }}
        >
          {this.props.showNumericRow && (
            <KeyboardRow>
              {numbers.map(button => (
                <KeyboardButton
                  value={button}
                  onClick={this.handleLetterButtonClick}
                  classes={'keyboard-numberButton'}
                  key={button}
                />
              ))}
              <KeyboardButton value={<BackspaceIcon />} onClick={this.handleBackspaceClick} />
            </KeyboardRow>
          )}

          {keys.map((row, i) => (
            <KeyboardRow key={`r${i}`}>
              {keys.length === i + 1 && (
                <KeyboardButton
                  classes="shift-symbols"
                  value={this.state.uppercase === 2 ? <CapsLockIcon /> : <ShiftIcon />}
                  onClick={this.handleShiftClick}
                />
              )}
              {row.map((button, ii) => {
                switch (button.toLowerCase()) {
                  case '*bs':
                    return (
                      <KeyboardButton
                        value={<BackspaceIcon />}
                        onClick={this.handleBackspaceClick}
                        key={`b${ii}`}
                      />
                    )

                  case '*sh':
                    return (
                      <KeyboardButton
                        classes="shift-symbols"
                        value={this.state.uppercase === 2 ? <CapsLockIcon /> : <ShiftIcon />}
                        onClick={this.handleShiftClick}
                        key={`b${ii}`}
                      />
                    )

                  default:
                    return (
                      <KeyboardButton
                        value={button}
                        onClick={this.handleLetterButtonClick}
                        key={`b${ii}`}
                      />
                    )
                }
              })}

              {keys.length === i + 1 && this.props.showSymbols && (
                <KeyboardButton
                  classes="shift-symbols"
                  value={symbolsKeyValue}
                  onClick={this.handleSymbolsClick}
                />
              )}
            </KeyboardRow>
          ))}

          <KeyboardRow>
            <KeyboardButton
              value={<DraggableIcon />}
              classes="keyboard-drag-icon keyboard-small-btn"
              onClick={this.handleDragKeyClick}
            />
            {secondaryKeyboard && !isPrimaryKeyboardEqualToSecondary && (
              <KeyboardButton
                classes={`secondary-keyboard ${
                  showSecondaryKeyboard ? 'secondary-keyboard--active' : ''
                } keyboard-small-btn`}
                value={<LanguageIcon />}
                onClick={this.handleLanguageClick}
              />
            )}
            <KeyboardButton
              value={' '}
              classes="keyboard-space"
              onClick={this.handleLetterButtonClick}
            />
            <KeyboardButton
              value={hideKeyboardLabel}
              classes="keyboard-submit-button"
              onClick={this.props.submitClicked}
            />
          </KeyboardRow>
        </div>
      </Draggable>
    )
  }
}

export const Keyboard = styled(KeyboardComponent)`
  margin: 0 auto;
  width: 100%;
  max-width: 1030px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  background: ${({ theme }) => theme.touchkeyboard.backgroundColor};
  z-index: 10000;
  position: fixed;
  bottom: 0;
  left: calc(50% - 350px);
  width: 700px;
  opacity: ${({ opacity }) => opacity};
  &-keysSet {
    display: flex;
    flex-grow: 1;
  }
`
