import React from 'react'

interface ServiceApiProps {
  apiEndpoint: string
  children: (props: {
    loading: boolean
    error: Error | undefined
    json: Record<string, unknown> | undefined
  }) => React.ReactNode
}

interface ServiceApiState {
  config: Record<string, unknown> | undefined
  error: Error | undefined
}

export class ServiceApi extends React.PureComponent<ServiceApiProps, ServiceApiState> {
  constructor(props: ServiceApiProps) {
    super(props)
    this.state = {
      config: undefined,
      error: undefined,
    }
  }

  componentDidMount() {
    const { apiEndpoint } = this.props

    fetch(apiEndpoint)
      .then(r => r.json())
      .then(json => this.setState({ config: json }))
      .catch(e => this.setState({ config: undefined, error: e }))
  }
  render() {
    const { config, error } = this.state

    return this.props.children({
      loading: error === undefined && config === undefined,
      error,
      json: config,
    })
  }
}
