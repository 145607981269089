import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'

export const useSendCloseModalEvent = () => {
  const sendCloseModalEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: 'BarcodeResult_Close',
  })

  return sendCloseModalEvent
}

export const useSendImpressionEvent = (isOakley: boolean, customizable = false) => {
  const customPageSection = isOakley ? 'Barcode:CustomOO:NOUpc' : 'Barcode:CustomRB:NOUpc'

  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    // https://abstractsrl.atlassian.net/browse/M4C-6452
    // Page_Section2: customizable ? customPageSection : 'Barcode:Standard:NOUpc:ViewColors',
    Page_Section2: customizable ? customPageSection : 'Barcode:Standard:NOUpc:PolarizedOnly',
  })

  return { sendImpressionEvent }
}
