import React from 'react'
import { IconProps } from 'types/icons'
export const FaFittingFeaturesIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 15"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path d="M1.33333 5.99985V3.33318H4V1.99985H0V7.33318H4V5.99985H1.33333Z" fill={fill} />
      <path
        d="M31.39 9.70385L22.1207 3.00985C21.2113 2.35318 20.1187 1.99985 18.998 1.99985H12V3.33318H18.998C19.844 3.33318 20.654 3.59518 21.34 4.09051L30.6093 10.7852C30.6173 10.7912 30.634 10.8032 30.6367 10.8338C30.6393 10.8645 30.6247 10.8792 30.6173 10.8865L29.256 12.2478C28.878 12.6258 28.3767 12.8338 27.8447 12.8338C27.1813 12.8338 26.5833 12.5232 26.2033 11.9805L26.1907 11.9625L26.178 11.9452L23.244 8.02985C21.9913 6.35851 19.9973 5.36118 17.9093 5.36118C17.3687 5.36118 16.8287 5.42718 16.3027 5.55785L14.5227 5.99985H12V7.33318H14.6853L16.6233 6.85185C17.05 6.74585 17.4813 6.69451 17.9087 6.69451C19.5633 6.69451 21.1547 7.46651 22.1767 8.82985L25.1107 12.7452C25.7667 13.6825 26.802 14.1672 27.844 14.1672C28.6907 14.1672 29.5413 13.8478 30.198 13.1905L31.5593 11.8292C32.1693 11.2192 32.0887 10.2085 31.39 9.70385Z"
        fill={fill}
      />
      <path
        d="M7.33333 1.99984V7.33317H6V8.6665H10V7.33317H8.66667V1.99984H10V0.666504H6V1.99984H7.33333Z"
        fill={fill}
      />
    </svg>
  )
}
