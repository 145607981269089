import { toggleSearchDrawer } from 'actions/ui'
import qs from 'qs'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useNavigationFlowHandler } from './useNavigationFlowHandler'
import { usePageChecker } from './useNavigationUtils'

export const useUrlParams = () => {
  const { location } = useHistory()
  const { search } = location
  const parsedUrl = qs.parse(search, { ignoreQueryPrefix: true })
  const fromBarcode = parsedUrl.comesFromBarcode === 'true'
  const isOnlyCustom = parsedUrl.isOnlyCustom === 'true'
  const isOakley = parsedUrl.isOakley === 'true'
  return {
    fromBarcode,
    isOnlyCustom,
    isOakley,
  }
}

export const useToggleSearchDrawer = () => {
  const dispatch = useDispatch()
  const { barcodeFlow } = useNavigationFlowHandler()
  const { isHomePage } = usePageChecker()

  useEffect(() => {
    if (barcodeFlow && isHomePage) {
      dispatch(toggleSearchDrawer)
    }
  }, [dispatch, barcodeFlow, isHomePage])
}
