
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ContentV2Image"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ContentV2Image"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"src"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"altText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"callToActionCustomText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mimeType"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":111}};
    doc.loc.source = {"body":"fragment ContentV2Image on ContentV2Image {\n  __typename\n  src\n  altText\n  callToActionCustomText\n  mimeType\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
