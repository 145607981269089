import { MapDevicePx, pxByDevice, pxToRem } from 'libs/styled'
import { merge } from 'lodash'
import QRCodeStyling, { Options } from 'qr-code-styling'
import React, { useContext, useMemo, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { NavigationContext } from '../../providers/navigationProvider'

export const StyledQRCode = styled.div<{ size: number; url: string }>`
  margin: 0 auto;
  height: ${({ size }) => pxToRem(size)};
  width: ${({ size }) => pxToRem(size)};
  background-color: ${({ theme }) => theme.colors.white};
  animation-name: ${({ url }) => (url ? 'none' : 'color')};
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  border-radius: ${pxToRem(20)};

  canvas {
    width: ${({ size }) => pxToRem(size)};
    height: ${({ size }) => pxToRem(size)};
  }

  @keyframes color {
    0% {
      background-color: ${({ theme }) => theme.colors.mercury};
    }
    100% {
      background-color: ${({ theme }) => theme.colors.seashell};
    }
  }
`
const defaultStyles: Partial<Options> = {
  // get qood quality qrcode resolution
  width: 200,
  height: 200,
  qrOptions: {
    mode: 'Byte',
    errorCorrectionLevel: 'L',
  },
  dotsOptions: {
    type: 'square',
    color: '#000000',
  },
  cornersDotOptions: {
    type: 'square',
    color: '#000000',
  },
  cornersSquareOptions: {
    type: 'square',
    color: '#000000',
  },
}

export interface QRCodeProps {
  className?: string
  url?: string
  styles?: Partial<Options>
  sizes?: MapDevicePx
}

export const QRCode: React.FC<QRCodeProps> = ({ url, className, styles = {}, sizes }) => {
  const { landscapeNavigation } = useContext(NavigationContext)
  const defaultSizes = {
    ipadMini: landscapeNavigation ? 82 : 120,
    tablet: landscapeNavigation ? 110 : 120,
    tower: 120,
  }

  const qrCodeSize = pxByDevice(sizes || defaultSizes)

  const ref = useRef<HTMLDivElement | null>(null)
  const qrCode = useMemo(() => new QRCodeStyling(merge(defaultStyles, styles)), [styles])

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current)
    }
  }, [qrCode])

  useEffect(() => {
    qrCode.update({
      data: url,
    })
  }, [url, qrCode])

  return (
    <StyledQRCode
      data-url={url}
      data-test="img-qr"
      className={className}
      size={qrCodeSize}
      url={url}
    >
      <div ref={ref} />
    </StyledQRCode>
  )
}
