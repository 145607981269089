import React, { useCallback, useContext } from 'react'
import styled, { DefaultTheme, ThemeContext } from 'styled-components'

export const TogglerContainer = styled.div<{ backgroundColor?: string }>`
  display: flex;
  height: 1.818rem;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.cta.backgroundColor};
  border-radius: 1.818rem;
  justify-content: space-between;
  ${({ backgroundColor }) => (backgroundColor ? `background: ${backgroundColor}` : '')}
`

interface TogglerButtonProps {
  backgroundColor?: string
  activeBackgroundColor?: string
}
export const TogglerButton = styled.button<TogglerButtonProps & { active: boolean }>`
  z-index: ${({ active }) => (active ? 1 : 0)};
  margin: 0;
  display: flex;
  flex-direction: column; /* row direction doesn't center text vertically in KIOSK for some reason */
  justify-content: center;
  align-items: center;
  border-radius: 1.818rem;
  outline: 0;
  font-size: 0.909rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: ${({ theme, active }) => (active ? theme.cta.textColor : theme.cta.secondaryTextColor)};
  background-color: ${({ theme, active }) =>
    active ? theme.cta.backgroundColor : theme.cta.secondaryBackgroundColor};
  padding: 0.643rem;
`

export interface ToggleOption {
  value: string
  label: string
  dataElementId?: string
  dataDescription?: string
}

interface TogglerProps {
  value?: string
  valueIndex?: number
  dataElementId?: string
  onChange: (value: string, index: number) => void
  options: ToggleOption[]
  analitycsCallNumber?: number
  focus?: boolean
  style?: TogglerButtonProps
  className?: string
  ToggleButtonComponent?: typeof TogglerButton
}

export const Toggler: React.FC<TogglerProps> = ({
  onChange,
  value,
  valueIndex,
  options,
  analitycsCallNumber = 0,
  focus = false,
  style = {},
  className,
  ToggleButtonComponent = TogglerButton,
}) => {
  const theme: DefaultTheme = useContext(ThemeContext)
  const isSelected = useCallback(
    (optionValue, optionIndex) => {
      return value === optionValue || valueIndex === optionIndex
    },
    [value, valueIndex]
  )

  return (
    <TogglerContainer
      data-analytics_available_call={analitycsCallNumber}
      className={className}
      backgroundColor={style.backgroundColor || theme.colors.white}
    >
      {options?.map((option, index) => (
        <ToggleButtonComponent
          key={option.value}
          data-element-id={option?.dataElementId}
          data-description={option?.dataDescription}
          onClick={() => onChange(option?.value, index)}
          active={isSelected(option?.value, index)}
          autoFocus={focus && isSelected(option?.value, index)}
          aria-label={option?.label}
          aria-description={option?.label}
          backgroundColor={style.backgroundColor || theme.colors.white}
          activeBackgroundColor={style.activeBackgroundColor || theme.colors.codGray}
        >
          {option?.label}
        </ToggleButtonComponent>
      ))}
    </TogglerContainer>
  )
}
