import styled from 'styled-components'
import React from 'react'

interface ListItemActionProps {
  className?: string
}

const ListItemActionContainer = styled.div`
  display: flex;
  margin-left: auto;
`

export const ListItemAction: React.FC<ListItemActionProps> = ({ children }) => {
  return <ListItemActionContainer>{children}</ListItemActionContainer>
}
