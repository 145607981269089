import { useMemo } from 'react'

export const useRTRVisibility = () => {
  return useMemo(() => {
    return {
      // isRTREnabled: !!store.rtrEnabled, No flag on M4C
      isRTREnabled: true,
    }
  }, [])
}
