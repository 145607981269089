import React from 'react'
import { IconProps } from 'types/icons'
export const FaceLengthIcon = ({ width = '1rem', height = '1rem', fill = '#333' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.435 22.989C32.92 22.197 31.856 21.273 29.672 21.05L29.732 20.691C29.861 19.92 29.927 19.136 29.963 18.352L29.969 18.351C29.968 18.342 29.965 18.335 29.964 18.327C29.977 18.035 30 17.744 30 17.452C30 13.083 28.434 9.097 25.854 6.032C27.174 4.758 28 2.975 28 1V0H13V2H25.899C25.864 2.17 25.804 2.33 25.753 2.494C25.593 3.003 25.356 3.473 25.052 3.897C24.144 5.163 22.673 6 21 6V8C22.165 8 23.262 7.71 24.229 7.204C26.002 9.27 27.247 11.823 27.747 14.65C25.313 13.007 21.574 13 20 13C16.999 13 14.728 12.609 13 12.066V14.158C14.835 14.663 17.128 15 20 15C26.638 15 27.762 16.911 27.96 18.418C27.924 19.07 27.866 19.721 27.759 20.363L25.536 33.699C25.205 35.681 24.582 37.599 23.682 39.397C21.647 43.47 17.553 46 13 46V48C18.316 48 23.095 45.047 25.472 40.292C26.46 38.315 27.146 36.208 27.509 34.027L27.859 31.925C28.116 31.97 28.377 32 28.645 32C31.332 32 32.39 29.577 33.324 27.439L33.559 26.906C34.184 25.499 34.139 24.072 33.435 22.989ZM31.731 26.094L31.491 26.639C30.581 28.723 29.956 30 28.645 30C28.489 30 28.339 29.973 28.19 29.946L29.342 23.031C30.528 23.132 31.377 23.493 31.758 24.078C32.207 24.77 31.934 25.638 31.731 26.094Z"
        fill={fill}
      />
      <path d="M4 46V2H6V0H0V2H2V46H0V48H6V46H4Z" fill={fill} />
      <path d="M15.013 36H10.986V38H15.013V36Z" fill={fill} />
      <path d="M13.434 31H10.986V33H15.434V23.057H13.434V31Z" fill={fill} />
    </svg>
  )
}
