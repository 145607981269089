import React from 'react'
import { IconProps } from 'types/icons'

export const RoundIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 0C32.12 0 27.977 3.197 26.542 7.605C24.947 6.885 23.054 6.885 21.458 7.606C20.023 3.197 15.88 0 11 0C4.935 0 0 4.935 0 11C0 17.065 4.935 22 11 22C17.065 22 22 17.065 22 11C22 10.529 21.96 10.067 21.902 9.611C23.165 8.849 24.836 8.848 26.097 9.61C26.04 10.066 26 10.528 26 11C26 17.065 30.935 22 37 22C43.065 22 48 17.065 48 11C48 4.935 43.065 0 37 0ZM11 20C6.038 20 2 15.963 2 11C2 6.037 6.038 2 11 2C15.962 2 20 6.037 20 11C20 15.963 15.962 20 11 20ZM28 11C28 15.963 32.038 20 37 20C41.962 20 46 15.963 46 11C46 6.037 41.962 2 37 2C32.038 2 28 6.037 28 11Z"
        fill={fill}
      />
    </svg>
  )
}
