import { lineClamp } from 'libs/lineClamp'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Product } from 'types/product'
import { BestMatchTextWrapperStyled, ProductDescriptionStyled } from './styles'

interface BestMatchTextProps {
  product: Product
  dynamicSentence: string
  isDressedOnView?: boolean
}

const className = 'show-more'

export const BestMatchText: React.FC<BestMatchTextProps> = ({
  dynamicSentence,
  product,
  isDressedOnView,
}) => {
  const { t } = useTranslation()
  const [displayFullText, setDiplayFullText] = useState(false)
  const ref = useRef<HTMLParagraphElement>(null)
  const [originalText, setOriginaText] = useState<string>()
  const id = useMemo(() => `best-match-${product.id}`, [product.id])

  const toggleText = useCallback(() => {
    setDiplayFullText(!displayFullText)
  }, [displayFullText])

  useEffect(() => {
    if (isDressedOnView && !displayFullText) {
      const truncationHTML = document.createElement('span')
      truncationHTML.innerHTML = `...${t('FrameAdvisor.bestMatchShowMore')}`
      truncationHTML.className = className
      truncationHTML.addEventListener('click', toggleText)
      if (ref.current) {
        const response = lineClamp(ref.current, {
          clamp: 2,
          truncationHTML,
        })
        !originalText && setOriginaText(response.original)
      }
    } else if (isDressedOnView && displayFullText) {
      const descriptionElement = document.getElementById(id)
      const moreElement = descriptionElement?.getElementsByClassName(className)[0]
      if (moreElement?.parentNode) {
        moreElement.parentNode.removeChild(moreElement)
      }
    }
  }, [isDressedOnView, displayFullText, id, toggleText, t, originalText])

  return (
    <BestMatchTextWrapperStyled>
      <ProductDescriptionStyled id={id} ref={ref} weight={isDressedOnView ? 'regular' : 'bold'}>
        {isDressedOnView && displayFullText ? originalText : dynamicSentence}{' '}
        {isDressedOnView && displayFullText && (
          <span onClick={toggleText}>{t('FrameAdvisor.bestMatchShowLess')}</span>
        )}
      </ProductDescriptionStyled>
    </BestMatchTextWrapperStyled>
  )
}
