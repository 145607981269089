import React from 'react'
import { IconProps } from 'types/icons'

export const RoseMetalsIcon = ({ width = '1rem', height = '1rem', className = '' }: IconProps) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" className={className}>
      <circle cx="50%" cy="50%" r="50%" fill="url(#roseMetal)" />
      <defs>
        <linearGradient
          id="roseMetal"
          x1={47.85}
          y1={52.563}
          x2={7.612}
          y2={5.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.047} stopColor="#DEA88B" />
          <stop offset={0.435} stopColor="#FCC8AF" stopOpacity={0.692} />
          <stop offset={0.646} stopColor="#FFE6DA" stopOpacity={0.51} />
          <stop offset={0.795} stopColor="#FFCBB2" stopOpacity={0.7} />
          <stop offset={1} stopColor="#E0AF99" />
        </linearGradient>
      </defs>
    </svg>
  )
}
