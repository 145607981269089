import ErrorWidget from 'components/ErrorWidget'
import Loading from 'components/Loading'
import RotateDevice from 'components/RotateDeviceMessage'
import BaseContainer from 'containers/BaseContainer'
import {
  TermsAndConditionsDataQueryResult,
  useFrameAdvisorRegulations,
  useTermsAndConditionsPageData,
} from 'data'
import { parseGrapqhQLError } from 'libs/errors'
import { pxToRem } from 'libs/styled'
import { NavigationContext } from 'providers/navigationProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { scrollbar } from 'styles'
import { FrameAdvisorFsaRegulationsType } from 'types/graphqlTypes'

const RegulationsContainer = styled(BaseContainer)`
  ${scrollbar};
  overflow-x: hidden;
`

const RegulationsContent = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  padding-inline: ${pxToRem(96)};
  padding-top: ${pxToRem(64)};
`

const BipaAndPrivacyPolicy: React.FC<{ regulationsType: FrameAdvisorFsaRegulationsType }> = ({
  regulationsType,
}) => {
  const { data, loading, error } = useFrameAdvisorRegulations(regulationsType)

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorWidget {...parseGrapqhQLError(error)} withWrapper />
  }

  return (
    <RegulationsContent dangerouslySetInnerHTML={{ __html: data.frameAdvisorRegulations || '' }} />
  )
}
const TermsAndConditions: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [loadData, { data }] = useTermsAndConditionsPageData<TermsAndConditionsDataQueryResult>(
    'PageGeneric'
  )
  const content = data?.page?.content[0]

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    if (data?.page?.content) {
      setLoading(false)
    }
  }, [data])

  if (loading) {
    return <Loading />
  }

  return <RegulationsContent dangerouslySetInnerHTML={{ __html: content?.html || '' }} />
}

const Content: React.FC = () => {
  const { pathname } = useLocation()

  if (pathname.includes('bipa')) {
    return <BipaAndPrivacyPolicy regulationsType="bipaPolicy" />
  } else if (pathname.includes('privacy')) {
    return <BipaAndPrivacyPolicy regulationsType="privacyPolicy" />
  } else if (pathname.includes('terms')) {
    return <TermsAndConditions />
  }

  return null
}

export const FrameAdvisorRegulations: React.FC = () => {
  const { landscapeNavigation } = useContext(NavigationContext)
  const history = useHistory()

  if (landscapeNavigation) {
    return <RotateDevice showCloseBtn to="portrait" onCancel={() => history.go(-2)} />
  }

  return (
    <RegulationsContainer
      className="regulations-page"
      showPrivacyPolicyTooltip={false}
      showHeader={false}
      showMenu={landscapeNavigation ? false : true}
      withPadding={false}
      floatingHeader={false}
      content={<Content />}
    />
  )
}
