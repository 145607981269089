import { IconProps } from 'types/icons'
import { getARIAAttribues } from 'libs/accessibility'
import React from 'react'

export const ThumbUpIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  className = '',
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 32 30"
      fill="none"
      strokeWidth={0}
      {...getARIAAttribues('likeIcon', 'button')}
    >
      <path
        d="M32 18.4787C32 17.6347 31.6113 16.8867 31.0133 16.3787C31.6113 15.8707 32 15.1227 32 14.2787C32 12.7533 30.7587 11.512 29.2333 11.512H19.022C21.4413 5.46468 22.2527 1.98468 21.4313 1.16401C20.3527 0.0853451 18.5973 0.0853451 17.4873 1.19735L8.77267 11.1967C8.554 11.0407 8.28867 10.946 8 10.946H1.33333C0.596667 10.946 0 11.5427 0 12.2793V28.3333C0 29.07 0.596667 29.6667 1.33333 29.6667H8C8.73667 29.6667 9.33333 29.07 9.33333 28.3333V27.7887L14.642 29.5767C14.7793 29.6227 14.9227 29.6467 15.0673 29.6467H26.4327C27.9587 29.6467 29.1993 28.4053 29.1993 26.88C29.1993 26.3533 29.0433 25.866 28.7873 25.4467H29.2327C30.7587 25.4467 31.9993 24.2053 31.9993 22.68C31.9993 21.8353 31.6113 21.088 31.0127 20.58C31.612 20.0713 32 19.3233 32 18.4787ZM8 28.3333H1.33333V12.2793H8V12.3333V20.2447V27V27.3393V28.3333ZM29.2333 21.246C30.024 21.246 30.6667 21.8893 30.6667 22.6793C30.6667 23.4693 30.0233 24.1127 29.2333 24.1127H25.0333V25.446H26.4333C27.224 25.446 27.8667 26.0893 27.8667 26.8793C27.8667 27.6693 27.2233 28.3127 26.4333 28.3127H15.3013H15.068L9.33333 26.3813V23.3093V21.578V20.2447V12.5833L18.4613 2.10668C19.0047 1.56401 19.8753 1.55335 20.4367 2.06468C20.6887 2.98268 19.08 7.86001 17.4153 11.926C17.3313 12.132 17.3547 12.366 17.4787 12.55C17.6027 12.7347 17.81 12.8453 18.032 12.8453H29.2333C30.024 12.8453 30.6667 13.488 30.6667 14.2787C30.6667 15.0693 30.0233 15.712 29.2333 15.712H25.0333V17.0453H29.2333C30.024 17.0453 30.6667 17.688 30.6667 18.4787C30.6667 19.2693 30.0233 19.912 29.2333 19.912H25.0333V21.2453H29.2333V21.246Z"
        fill={fill}
      />
    </svg>
  )
}
