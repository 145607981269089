import { PrizmColors, PrizmFonts } from 'styled-components'

const colors: PrizmColors = {
  primary: '#ffffff',
}

const font = 'Avenir, Helvetica, Arial'

const fonts: PrizmFonts = {
  font: font,
  heading1: {
    name: font,
    weight: 900,
    towerSize: 100,
    tabletSize: 68,
    tabletMiniSize: 50,
    towerLineHeight: 125,
    tabletLineHeight: 85,
    tabletMiniLineHeight: 63,
  },
  body: {
    name: font,
    weight: 700,
    towerSize: 36,
    tabletSize: 36,
    tabletMiniSize: 26,
    towerLineHeight: 45,
    tabletLineHeight: 45,
    tabletMiniLineHeight: 33,
  },
  cta: {
    name: font,
    weight: 700,
    towerSize: 30,
    tabletSize: 30,
    tabletMiniSize: 22,
    towerLineHeight: 45,
    tabletLineHeight: 45,
    tabletMiniLineHeight: 33,
  },
}

export default {
  colors,
  fonts,
}
