import { useSelector } from 'react-redux'
import { useMemo } from 'react'

export const useAnalyticsShippingMode = () => {
  const { shipping, shippingType } = useSelector(s => s.checkout.data)
  //Todo - We should get shippingMethods from
  // import { useShippingMethods } from '../../data'
  // const {data} = useShippingMethods()
  // And format as required (first char uppercase only)
  // const shippingMethods = data?.shippingMethods?.map(({identifier}) => identifier.slice(1, identifier.length).toLowerCase())
  // https://abstractsrl.atlassian.net/browse/M4C-5597
  const shippingMethods = {
    ['G']: 'Green',
    ['E']: 'Express',
    ['M']: 'Military',
    ['P']: 'PO box',
    ['S']: 'Standard',
  }

  const shippingMethod = shippingMethods[shippingType]

  const delivery = !!shipping && shipping === 'home' ? 'Delivery-at-home' : 'Ship-to-store'

  return useMemo(() => `${delivery}:${shippingMethod}`, [delivery, shippingMethod])
}
