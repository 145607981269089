import Log from './Log'
import config from 'config'

const saveOfflineStatus = (): void => {
  localStorage.setItem(config.offlineLocalstorageKey, new Date().toISOString())
}

const saveOnlineStatus = (): void => {
  localStorage.setItem(config.offlineLocalstorageKey, '')
}

const getOfflineTimestamp = (): string => localStorage.getItem(config.offlineLocalstorageKey)

export const handleOffline = saveOfflineStatus
export const handleOnline = (): void => {
  const offlineTimestamp = getOfflineTimestamp()
  if (offlineTimestamp) {
    const currentTimeStamp = new Date().toISOString()
    const offlineError = `Device Offline from ${offlineTimestamp} to ${currentTimeStamp}`
    Log.error(offlineError, 'Network Error')
    saveOnlineStatus()
  }
}

export const initOfflineErrorNotifier = (): void => {
  window.addEventListener('offline', handleOffline)
  window.addEventListener('online', handleOnline)
}
