import { CaretLeftIcon, CaretRightIcon } from 'components/core/Icons'
import { NukaCarousel } from 'components/NukaCarousel'
import { useDeviceType } from 'hooks/useDeviceType'
import { usePageChecker } from 'hooks/useNavigationUtils'
import { pxToRem } from 'libs/styled'
import { ScrollMode } from 'nuka-carousel'
import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { CartMerchRecommenderAccordionProps } from '../CartMerchRecommenderAccordion/types'
import MerchCardComponent from '../MerchCardComponent'
import { CartMerchProgressBar, MerchNextBtn, MerchPrevBtn } from '../styles'

export const CartMerchStyledCarousel = styled(NukaCarousel)<{
  isCartPage: boolean
  showLastSlideGradient: boolean
  showFirstSlideGradient: boolean
}>`
  overflow: visible;
  ${({ isCartPage, showLastSlideGradient }) =>
    isCartPage &&
    `mask-image:
    ${showLastSlideGradient && 'linear-gradient(to left, transparent 0%, black 20% 80%)'}}`};
  ${({ isCartPage, showFirstSlideGradient }) =>
    isCartPage &&
    `mask-image:
    ${showFirstSlideGradient && 'linear-gradient(to right, transparent 0%, black 20% 80%)'}}`};
`

export const CartMerchCarousel: FC<CartMerchRecommenderAccordionProps> = ({ products }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const { isCartPage } = usePageChecker()
  const { isTowerLandscape, isIpadlandscape } = useDeviceType()

  const handleSlidesToShowAmount = () => {
    switch (true) {
      case isTowerLandscape:
        return 3.1
      case isIpadlandscape:
        return 2.19
      default:
        return 1.6
    }
  }

  const showNextBtn = isCartPage
    ? slideIndex < products.length - handleSlidesToShowAmount()
    : slideIndex !== products.length - 1

  const showProgressBar = isCartPage
    ? products.length > handleSlidesToShowAmount()
    : products.length > 1

  return (
    <CartMerchStyledCarousel
      isCartPage={isCartPage}
      showLastSlideGradient={slideIndex < products.length - handleSlidesToShowAmount()}
      showFirstSlideGradient={
        slideIndex !== 0 && slideIndex >= products.length - handleSlidesToShowAmount()
      }
      beforeSlide={(_, targetIndex) => setSlideIndex(targetIndex)}
      slideIndex={slideIndex}
      withoutBullets={false}
      cellSpacing={24}
      slidesToShow={isCartPage ? handleSlidesToShowAmount() : 1}
      slidesToScroll={slideIndex - Math.floor(slideIndex) || 1}
      scrollMode={isCartPage ? ScrollMode.remainder : ScrollMode.page}
      renderCenterCenterControls={({ currentSlide, previousSlide, nextSlide }) => {
        return (
          <>
            {currentSlide ? (
              <MerchPrevBtn onClick={previousSlide}>
                <CaretLeftIcon width={pxToRem(9)} height={pxToRem(16)} />
              </MerchPrevBtn>
            ) : null}

            {showNextBtn ? (
              <MerchNextBtn onClick={nextSlide}>
                <CaretRightIcon width={pxToRem(9)} height={pxToRem(16)} />
              </MerchNextBtn>
            ) : null}
          </>
        )
      }}
      renderBottomCenterControls={({ currentSlide, nextSlide, goToSlide }) => {
        if (showProgressBar) {
          return (
            <CartMerchProgressBar
              currentSlide={Math.ceil(currentSlide)}
              disableAnimation
              isProgressPaused
              nextPlay={nextSlide}
              onProgressClick={goToSlide}
              playlist={products}
            />
          )
        }
        return null
      }}
    >
      {products.map((item, index) => (
        <MerchCardComponent key={index} product={item} />
      ))}
    </CartMerchStyledCarousel>
  )
}
