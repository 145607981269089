import { Modal } from 'components/core/Modal'
import styled from 'styled-components'
import { pxToRem } from 'libs/styled'

export const Popup = styled(Modal)`
  .ant-modal-content {
    padding: ${pxToRem(80)} ${pxToRem(24)};
    text-align: center;

    h3 {
      margin-bottom: ${pxToRem(16)};
    }
    h5 {
      margin-bottom: ${pxToRem(64)};
    }
  }
`

export const PopupFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: ${pxToRem(24)};

  button {
    width: ${pxToRem(269)};
    height: ${pxToRem(56)};
  }
`
