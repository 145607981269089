import React from 'react'
import { IconProps } from 'types/icons'

export const FaTipsHoldDeviceIcon = ({
  width = '1rem',
  height = '1rem',
  stroke = '#333',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 98 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="49" cy="49.5195" r="48" stroke={stroke} strokeWidth="2" />
      <g clipPath="url(#clip0_1611_160729)">
        <path
          d="M69.1564 47.1604L63.2917 41.8296V30.1237C63.2917 27.3093 61.0019 25.0195 58.1875 25.0195H39.8125C36.9981 25.0195 34.7083 27.3093 34.7083 30.1237V41.8296L28.8436 47.1614C26.0833 49.6706 24.5 53.2497 24.5 56.9808V74.0195H26.5417V56.9808C26.5417 53.8244 27.881 50.7956 30.2177 48.6712L34.7083 44.5889V58.3518L32.7106 60.1587L34.1561 61.6042L42.1431 54.3777C42.8791 53.7111 43.6692 53.9254 44.1643 54.3491C44.6594 54.7727 44.9943 55.52 44.4512 56.3509L38.7478 65.074C38.2425 65.8467 37.8913 66.7594 37.6739 67.8639C37.2063 70.2486 35.1003 71.9779 32.6667 71.9779V72.9987V74.0195C35.4923 74.0195 37.9811 72.3403 39.1296 69.8668C39.3552 69.8974 39.5787 69.9362 39.8125 69.9362H58.1875C58.4213 69.9362 58.6448 69.8974 58.8704 69.8668C60.0189 72.3403 62.5077 74.0195 65.3333 74.0195V72.9987V71.9779C62.8997 71.9779 60.7937 70.2486 60.3251 67.8649C60.1077 66.7594 59.7565 65.8467 59.2512 65.075L53.5478 56.352C53.0047 55.521 53.3396 54.7727 53.8347 54.3501C54.3298 53.9275 55.1189 53.7121 55.8559 54.3787L63.8429 61.6052L65.2884 60.1597L63.2917 58.3508V44.5889L67.7823 48.6712C70.119 50.7956 71.4583 53.8234 71.4583 56.9808V74.0195H73.5V56.9808C73.5 53.2497 71.9167 49.6706 69.1564 47.1604ZM57.2269 52.8638C55.8559 51.6224 53.9143 51.5949 52.5086 52.7974C51.1019 54 50.8273 55.9212 51.8389 57.4698L57.5423 66.1928C57.8455 66.6573 58.0711 67.2198 58.2345 67.8904C58.2191 67.8904 58.2038 67.8945 58.1875 67.8945H39.8125C39.7962 67.8945 39.7809 67.8904 39.7645 67.8894C39.9279 67.2187 40.1535 66.6563 40.4566 66.1918L46.16 57.4688C47.1727 55.9202 46.8971 53.9989 45.4904 52.7964C44.0837 51.5949 42.1441 51.6224 40.7721 52.8628L36.75 56.5041V30.1237C36.75 28.4352 38.124 27.0612 39.8125 27.0612H58.1875C59.876 27.0612 61.25 28.4352 61.25 30.1237V56.5041L57.2269 52.8638Z"
          fill={fill}
        />
        <path d="M53.0833 31.1445H44.9166V33.1862H53.0833V31.1445Z" fill={fill} />
        <path
          d="M49 63.8109C50.1276 63.8109 51.0417 62.8968 51.0417 61.7692C51.0417 60.6416 50.1276 59.7275 49 59.7275C47.8725 59.7275 46.9584 60.6416 46.9584 61.7692C46.9584 62.8968 47.8725 63.8109 49 63.8109Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1611_160729">
          <rect width="49" height="49" fill="white" transform="translate(24.5 25.0195)" />
        </clipPath>
      </defs>
    </svg>
  )
}
