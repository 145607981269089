import config from 'config'
import { Location } from 'history'
import { resolveQueryParams } from 'hooks/useQueryParams'
import { flatten } from 'lodash'
import qs from 'qs'
import LocaleCode from './localeCode'

/** It returns the current language form the URL */
export const getLangFromUrl = (): string => {
  const urlparts = window.location.pathname.match(/\/([a-zA-Z-]*)/g)
  const langAttr = urlparts[2] && urlparts[2].replace('/', '')
  if (langAttr && LocaleCode.validateLanguageCode(langAttr)) {
    return langAttr
  }
  return 'en-US'
}

export const getCollectionFromUrl = (url: string): string => {
  const [, search] = url.split('?')
  return search && qs.parse(search).collection
}

/** It returns the site absolute path */
export const getSiteAbsolutePath = (basepath?: string): string => {
  const lang = getLangFromUrl()

  let path = basepath
  if (!path) {
    const [_, storeType, storeId] = window.location.pathname.split('/')
    path = [_, storeType, storeId].join('/')
  }

  if (lang) {
    return [path, lang].join('/')
  }
  return path
}

export const getPLPFiltersFromLocation = (
  location: Location
): { name: string; value: string }[] => {
  const { search } = location
  if (search) {
    const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true, arrayLimit: 1000 })
    if (parsedSearch) {
      return Object.keys(parsedSearch)
        .filter(name => ['q', 'sort'].indexOf(name) === -1)
        .map(name => ({
          name,
          value:
            typeof parsedSearch[name] === 'object' && name === 'frameColor'
              ? flatten(Object.values(parsedSearch[name]))
              : parsedSearch[name],
        }))
    }
  }
}
const excludePLPFilterFromResults = ['q', 'sort']

export const getPLPFiltersKindAndValueFromLocation = (
  location: Location,
  exclude = excludePLPFilterFromResults
) => {
  const { search } = location
  if (search) {
    const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true })
    if (parsedSearch) {
      return Object.keys(parsedSearch)
        .filter(name => exclude.indexOf(name) === -1)
        .map(kind => ({
          kind,
          value: parsedSearch[kind],
        }))
    }
  }
  return undefined
}

/** Adds and removes query paramaters and returns a new query string
 * If toggle is true the parameter will be removed when present in query string.
 */
export const manageQueryStringFilters = (location, name, value, toggle = true): string => {
  const { search } = location
  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true })
  const previousFilterValue = Object.keys(parsedSearch).indexOf(name) !== -1 && parsedSearch[name]
  // Remove filter
  if (toggle) {
    if (previousFilterValue) {
      delete parsedSearch[name]
    } else {
      parsedSearch[name] = value
    }
  } else {
    // toggle if passing the same value
    if (previousFilterValue === '' + value) {
      delete parsedSearch[name]
    } else {
      parsedSearch[name] = value
    }
  }

  // M4C-705: cannot query optical products and polarized togheter
  if (parsedSearch.collection === config.opticalCategory && parsedSearch.polarized === 'true') {
    delete parsedSearch.polarized
  }

  const newSearch = qs.stringify(parsedSearch)
  if (newSearch) {
    return `?${newSearch}`
  }
}

/** Manages multi values filters in query string
 *
 * It is possibile to add more than a value in query string for the same key.
 */
export const manageMultiValueQueryStringFilters = (
  location: Location,
  name: string,
  value: string
): string => {
  const { search } = location
  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true, arrayLimit: 50 })

  if (Object.keys(parsedSearch).indexOf(name) !== -1) {
    const values = parsedSearch[name]

    if (Array.isArray(values)) {
      const newValues = values.filter(el => el !== '' + value)

      // if values and new values are different
      // we have removed an item
      if (newValues.length !== values.length) {
        if (newValues.length === 1) {
          // array with only one value..
          parsedSearch[name] = newValues[0]
        } else {
          parsedSearch[name] = newValues
        }
      } else {
        // nothing changed, add the new value
        parsedSearch[name] = [...newValues, value]
      }
    } else {
      // previous values in querystring are not an array
      if (value == parsedSearch[name]) {
        delete parsedSearch[name]
      } else {
        parsedSearch[name] = [parsedSearch[name], value]
      }
    }
  } else {
    parsedSearch[name] = value
  }

  const newSearch = qs.stringify(parsedSearch)
  if (newSearch) {
    return `?${newSearch}`
  }
}

export const getVMUrl = (hashVM: string | number | boolean, fromStore = false): string => {
  return `${config.vmURL}?hash=${encodeURIComponent(hashVM)}&fromStore=${fromStore}`
}

export const appendQueryParamToUrl = (url: string, param: string): string =>
  `${url}${url.indexOf('?') > -1 ? '&' : '?'}${param}`

const homepageBypassedQS = 'homepageBypassed=1'

export const appendHomepageBypassedToUrl = (url: string): string =>
  appendQueryParamToUrl(url, homepageBypassedQS)

export const isHomepageBypassed = (url: string): boolean => url.indexOf(homepageBypassedQS) > -1

export const getGenderValue = (location: Location): string => {
  const activeFilters = getPLPFiltersFromLocation(location)
  const genderFilter = activeFilters && activeFilters.find(el => el.name === 'gender')
  return genderFilter && genderFilter.value
}

export const getPLPSortFromLocation = (location: Location): { kind: string; value: string }[] => {
  const { sort } = resolveQueryParams(
    {
      sort: (value, kind) => [{ kind, value: String(value) }],
    },
    location.search
  )
  return sort?.length ? sort : undefined
}

export const getQueryParamsOnlyDefined = (paramsObj: {
  [key: string]: string | undefined
}): string => {
  return paramsObj
    ? Object.keys(paramsObj)
        .filter(key => !!paramsObj[key])
        .map(key => key + '=' + paramsObj[key])
        .join('&')
    : ''
}
