import { updateFaPreselectedFilters, updatePLPPreselectedFilters } from 'actions/ui'
import { executeOnce, uniqueId } from 'libs/utils'
import { differenceWith, isEqual } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { UIState } from 'reducers/ui'
import { ActiveFilters } from 'types/analytics'
import { FiltersState, PreselectedFilters, TogglableProductTypeValues } from 'types/filter'
import { useActions } from './useActions'
import { getFiltersFromUrl } from './useFilters'
import { usePredefinedFiltersProductCount } from './usePredifinedFilterProductCount'

export const usePreselectedFilters = (filters: FiltersState): PreselectedFilters | undefined => {
  // Preselected filters are preselected by the cms, from hero banner or menu option (ticket SS-8442)
  const {
    shouldUpdatePlpPreselectedFilters,
    plpPreselectedFilters,
    faPreselectedFilters,
    shouldUpdateFaPreselectedFilters,
  }: UIState = useSelector(s => s.ui)
  const history = useHistory()
  const actions = useActions({
    updateFaPreselectedFilters,
    updatePLPPreselectedFilters,
  })
  const hash = useRef<string | undefined>(history.action !== 'POP' ? uniqueId() : undefined)
  const userFilters = useUserFilters()

  const predefinedFiltersCount = usePredefinedFiltersProductCount(filters)

  const isFAPLP = useMemo(() => history.location.pathname.includes('frame-advisor'), [history])
  const preselectedFilters = useMemo(
    () => (isFAPLP ? faPreselectedFilters : plpPreselectedFilters),
    [faPreselectedFilters, isFAPLP, plpPreselectedFilters]
  )
  const shouldUpdatePreselectedFilters = useMemo(
    () => (isFAPLP ? shouldUpdateFaPreselectedFilters : shouldUpdatePlpPreselectedFilters),
    [isFAPLP, shouldUpdateFaPreselectedFilters, shouldUpdatePlpPreselectedFilters]
  )

  useEffect(() => {
    if (shouldUpdatePreselectedFilters) {
      hash.current = uniqueId()
    }
  }, [shouldUpdatePreselectedFilters])

  useEffect(() => {
    if (hash.current && userFilters) {
      if (userFilters.areSamePreselectedFilters) {
        executeOnce(
          (
            filters: ActiveFilters[],
            productTypeCounts: Record<TogglableProductTypeValues, number>,
            isFAPLP: boolean
          ) => {
            actions[isFAPLP ? 'updateFaPreselectedFilters' : 'updatePLPPreselectedFilters']({
              filters,
              productTypeCounts,
            })
          },
          hash.current
        )(preselectedFilters?.filters ?? [], predefinedFiltersCount, isFAPLP)
      } else {
        executeOnce(
          (
            filters: ActiveFilters[],
            productTypeCounts: Record<TogglableProductTypeValues, number>,
            isFAPLP: boolean
          ) => {
            actions[isFAPLP ? 'updateFaPreselectedFilters' : 'updatePLPPreselectedFilters']({
              filters,
              productTypeCounts,
            })
          },
          hash.current
        )(userFilters.filters ?? [], predefinedFiltersCount, isFAPLP)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilters, actions, predefinedFiltersCount, preselectedFilters?.filters, isFAPLP])

  return preselectedFilters
}

export const useUserFilters = () => {
  const { plpPreselectedFilters: preselectedFilters }: UIState = useSelector(s => s.ui)
  const activeFilters = useActiveLocationFilters() as ActiveFilters[] | undefined
  const filteredActiveFilters = useMemo(
    () => activeFilters?.filter(f => !['storeAvailable', 'roxable', 'collection'].includes(f.kind)),
    [activeFilters]
  )

  return useMemo(() => {
    if (!filteredActiveFilters) {
      return
    }

    const userFilters = differenceWith(
      filteredActiveFilters,
      preselectedFilters?.filters ?? [],
      isEqual
    )

    return {
      areSamePreselectedFilters: !!preselectedFilters?.filters && userFilters.length === 0,
      filters: userFilters,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredActiveFilters, preselectedFilters?.filters])
}

export const useActiveLocationFilters = () => {
  const { location } = useHistory()

  return useMemo(() => getFiltersFromUrl(location) as ActiveFilters[] | undefined, [location])
}
