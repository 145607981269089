export const VMMVClassName = 'vmmv-container-enabled'

export const VMMV_POLICY_COOKIE_KEY = 'BasicImageConsent'
export const VMMV_POLICY_COOKIE_KEY2 = 'MlProcessImageConsent'
export const VMMV_PICTURE_VIDEO_LOCALSTORAGE_KEY = 'vmmv-picture-video-ids'

export const VMMV_SAVED_STATE_KEY = 'virtual-mirror-expirience'

export const VM_STYLE = 'm4c'
export const VM_STORE = 'multi'
