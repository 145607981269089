import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'

export const useSendUnavailablePolarizedAnalyticsEvent = () => {
  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    Page_Section2: 'Barcode:Standard:NOUpc:PolarizedOnly',
  })

  const sendDiscoverEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: 'BarcodeNoResultStd_Polarized',
  })

  return {
    sendDiscoverEvent,
    sendImpressionEvent,
  }
}
