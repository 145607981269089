declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultRootState extends AppState {}
}

import { createBrowserHistory } from 'history'
import { loadState, persistReducers } from 'localStorage'
import { routerMiddleware } from 'react-router-redux'
import {
  AnyAction,
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  StoreEnhancer,
  StoreEnhancerStoreCreator,
} from 'redux'
import thunk, { ThunkAction as ThunkActionLib } from 'redux-thunk'
import { StoreActionTypes } from './actions/store'
import createRootReducer from './reducers'
import storeReducer from './reducers/store'

export const history = createBrowserHistory()

// Listen for changes to the current location.
history.listen(() => {
  // location is an object like window.location
  document.querySelector('body').scrollTop = 0
})

const enhancers: StoreEnhancer[] = []
const middleware: Middleware[] = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const appReducer = createRootReducer(history)
const initialState = loadState()

const rootReducer = (state: AppReducerState, action: StoreActionTypes) => {
  state = storeReducer(state, action)
  return appReducer(state, action)
}

const composedEnhancers = compose<StoreEnhancerStoreCreator>(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(rootReducer, initialState, composedEnhancers)

persistReducers(
  store,
  'cart',
  'checkout',
  'wishlist',
  'session',
  'frameAdvisor',
  'filters',
  'couvette'
)

export default store

export type AppStore = typeof store
export type AppState = ReturnType<typeof appReducer>
export type AppReducerState = Parameters<typeof appReducer>[0]
export type ThunkAction<ReturnType = void> = ThunkActionLib<
  ReturnType,
  AppState,
  unknown,
  AnyAction
>
