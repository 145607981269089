import { pxToRem } from 'libs/styled'
import styled from 'styled-components'
import { scrollbar } from 'styles'
import { landscape } from '../../libs/media'

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.455rem;
`

export const ScrollableContainer = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
`

export const ScrollableContentContainer = styled(ScrollableContainer)`
  overflow-x: hidden;
  flex: 1;
`

export const ScrollablePaneContainer = styled(ScrollableContainer)`
  display: flex;
  -webkit-overflow-scrolling: touch;
  min-height: ${pxToRem(110)};
  max-width: 90%;
  width: unset;
  ${landscape(`
    max-width: 60%;
  `)}

  > * {
    margin-right: ${pxToRem(32)};

    :last-child {
      margin-right: 0;
    }
  }
`

export const ScrollableContent = styled.div<{ currentTabIndex }>`
  width: 100%;
  margin-left: ${({ currentTabIndex }) => (currentTabIndex ? `-${currentTabIndex}00%` : 0)};
  transition: margin-left 0.5s;
  height: 100%;
`

export const TabPane = styled.button<{
  isActive?: boolean
}>`
  height: 100%;
  position: relative;

  ::after {
    content: '';
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    background-color: ${({ theme }) => theme.colors.black};
    height: 3px;
    width: 100%;
    position: absolute;
    left: 0;
    margin-top: 0.22rem;
  }
`

export const TabContent = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
  white-space: initial;
  vertical-align: top;
  height: 100%;
  padding: 0 5vw;
  position: relative;
  margin-top: 4.11vh;
`

export const StyledScrollableTabContent = styled.div<{
  topContent?: number
  bottomContent?: number
}>`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollbar};

  ::before,
  ::after {
    content: '';
    left: 0;
    width: calc(100% - 5vw - 5px);
    transition: height 0.3s;
    position: absolute;
  }

  ::before {
    top: 0;
    height: ${({ topContent }) => `${topContent || 0}px`};
    background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
  }

  ::after {
    bottom: 0;
    height: ${({ bottomContent }) => `${bottomContent || 0}px`};
    background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  }
`
