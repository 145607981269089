import React from 'react'
import styled from 'styled-components'
import { theme } from 'theme'
import { pxToRem } from 'libs/styled'
import { ArrowLeftIcon } from 'components/core/Icons'

const BackButtonStyled = styled.button`
  width: ${pxToRem(64)};
  height: ${pxToRem(64)};
  padding: ${pxToRem(18)};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: ${pxToRem(32)};
  border: ${({ theme }) => `1px solid ${theme.colors.frenchGrey}`};
`

const BackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <BackButtonStyled type="button" onClick={onClick} data-analytics_available_call="0">
      <ArrowLeftIcon stroke={theme.menu.textColor} />
    </BackButtonStyled>
  )
}

export default BackButton
