import React from 'react'
import { IconProps } from 'types/icons'

export const SelectedIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#555',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1329 21.2754L0.349609 9.49208L1.29228 8.54941L12.1336 19.3914L31.0369 0.528076L31.9796 1.47208L12.1329 21.2754Z"
        fill={fill}
      />
    </svg>
  )
}
