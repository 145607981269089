import { Label } from 'components/Typography'
import { pxToRem } from 'libs/styled'
import React from 'react'
import styled from 'styled-components'
import { ListItem, ListItemAction } from '../List'

export const SwitcherWithLableStyled = styled(ListItem)``
export const LabelWrapper = styled.div`
  position: relative;
  margin-left: ${pxToRem(8)};

  label {
    width: ${pxToRem(47)};
    padding: 0 ${pxToRem(6.5)} 0 ${pxToRem(12)};
  }
`

export const Switcher = styled.div<{ checked: boolean }>`
  position: absolute;
  top: 3px;
  right: ${({ checked }) => (checked ? '2px' : pxToRem(28))};
  width: ${pxToRem(15)};
  height: ${pxToRem(15)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.bodyBackground};
`

export const LabelStyled = styled(Label)<{ checked: boolean }>`
  display: flex;
  flex-direction: ${({ checked }) => (checked ? 'row' : 'row-reverse')};
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  min-width: ${pxToRem(47)};
  height: ${pxToRem(24)};
  border-radius: ${pxToRem(16)};
  cursor: pointer;
  background-color: ${({ theme, checked }) =>
    checked ? theme.cta.backgroundColor : theme.colors.emperor};

  & input {
    opacity: 0;
  }
`
export const ToggleText = styled.span`
  display: flex;
  align-items: center;
`

type SwitcherWithLabelProps = React.ComponentProps<typeof Switcher> & {
  className?: string
  disabled?: boolean
  checked: boolean
  label: React.ReactNode
  showOnOff?: boolean
  dataAttr?: {
    test: string
  }
}

export const SwitcherWithLabel: React.FC<SwitcherWithLabelProps> = ({
  disabled,
  checked,
  label,
  showOnOff = true,
  className,
  dataAttr,
  ...props
}) => {
  return (
    <SwitcherWithLableStyled className={className}>
      <span>{label}</span>
      <ListItemAction>
        <LabelWrapper>
          <LabelStyled checked={checked} data-test={dataAttr?.test}>
            {showOnOff && <ToggleText>{checked ? 'ON' : 'OFF'}</ToggleText>}
            <input type="checkbox" {...props} disabled={disabled} checked={checked} />
            <Switcher checked={checked} />
          </LabelStyled>
        </LabelWrapper>
      </ListItemAction>
    </SwitcherWithLableStyled>
  )
}
