import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const BestMatchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${pxToRem(24)} 0;

  ${tablet(`margin: ${clampValues(18, 24)} 0;`)}
  ${landscape(Device.tablet, `margin: ${clampValuesLandscape(18, 24)} 0;`)}
`

export const BestMatchBadge = styled.div<{ isCapitalized?: boolean }>`
  // TODO: background color hardcoded temporarily
  background-color: ${({ theme }) => theme.colors.royalBlue};
  padding: ${pxToRem(5)} ${pxToRem(11)};
  min-width: 5.46rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(20)};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${pxToRem(48)};
  ${({ isCapitalized }) => isCapitalized && 'text-transform: capitalize;'}

  & svg {
    margin-right: 0.36rem;
  }

  ${tablet(`
    padding: ${clampValues(1, 3)} ${clampValues(8, 9)};
    font-size: ${clampValues(12, 14)};
    line-height: ${clampValues(18, 20)};
  `)}
  ${landscape(
    Device.tablet,
    `
    padding: ${clampValuesLandscape(1, 3)} ${clampValuesLandscape(8, 9)};
    font-size: ${clampValues(12, 14)};
    line-height: ${clampValues(18, 20)};
  `
  )}
`
