import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import config from 'config'
import introspectionQueryResultData from './fragmentTypes.json'
import RENEW_TOKEN_QUERY from './graphql/RenewToken.graphql'
import { cleanLocalStorageData, getAuthToken, setAuthToken } from './libs/auth'

let client: ApolloClient<NormalizedCacheObject>

const getNewToken = (token: string): Promise<string> => {
  return client
    .query({ query: RENEW_TOKEN_QUERY, context: { headers: { authorization: token } } })
    .then(res => res.data.renewToken.token)
}

const httpLink = new HttpLink({
  uri: operation => `${config.middleware.URL}/graphql?name=${operation.operationName}`,
})

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (!graphQLErrors) return
  for (let err of graphQLErrors) {
    switch (err.extensions.wcsCode) {
      case 403:
        const prevHeaders = operation.getContext().headers
        return fromPromise(
          getNewToken(prevHeaders.authorization).catch(error => {
            cleanLocalStorageData()
            throw new Error(error)
          })
        )
          .filter(value => Boolean(value))
          .flatMap(accessToken => {
            setAuthToken(accessToken)

            operation.setContext({
              headers: {
                ...prevHeaders,
                authorization: `Bearer ${accessToken}`,
              },
            })

            // retry the request once
            return forward(operation)
          })
    }
  }
})

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const cache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData,
})

client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  connectToDevTools: true,
  cache,
})

export default client
