import React from 'react'
import { IconProps } from 'types/icons'
export const FaThicknessThinIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 96 28"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path d="M2 7V4H17V2H0V9H17V7H2Z" fill={fill} />
      <path
        d="M94.964 20.964L87.716 13.716C80.214 6.214 70.04 2 59.431 2H31V4H59.431C69.581 4 79.124 7.953 86.301 15.13L93.549 22.378C93.992 22.821 94.013 23.335 93.987 23.603C93.961 23.871 93.838 24.371 93.318 24.718L92.578 25.212C91.918 25.652 91.153 25.884 90.363 25.884C89.102 25.884 87.94 25.309 87.177 24.308L82.095 17.638C77.02 10.976 68.992 7 60.618 7H31V9H60.618C68.42 9 75.775 12.643 80.504 18.849L85.586 25.519C86.762 27.063 88.549 27.883 90.363 27.883C91.509 27.883 92.667 27.555 93.687 26.875L94.427 26.381C96.276 25.15 96.535 22.535 94.964 20.964Z"
        fill={fill}
      />
      <path d="M25 2H27.999V0H20V2H23V9H20V11H27.999V9H25V2Z" fill={fill} />
    </svg>
  )
}
