import { CustomNuanceTab, customNuanceTabNames, NuanceTab } from 'types/nuance'
import { Product } from 'types/product'
import { arrayIncludes } from './caseInsensitive'

export const isNuanceProduct = (product: Product) =>
  product.isNuanceFrame || product.isNuanceAccessories

export const isNuanceCustomTab = (tab: NuanceTab): tab is CustomNuanceTab =>
  arrayIncludes(customNuanceTabNames, tab.name)

export const isNuanceBrand = (nuanceBrandCode: string) => nuanceBrandCode.toLowerCase() === 'aw'
