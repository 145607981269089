import { resetStore } from 'actions/store'
import { useActions } from 'hooks/useActions'
import { cleanupOneConfiguratorRecipes } from 'libs/custom'
import { cleanupVMMVData } from 'libs/virtualMirror'
import { resetLocalStorageState } from 'localStorage'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import * as serviceWorkerRegistration from '../serviceWorkerRegistration'
import { useStoreContext } from './useStoreContext'
import { useStoreIndentity } from './useStoreIdentity'
import { useSelector } from 'react-redux'

export const useResetSS = () => {
  const actions = useActions({
    resetStore,
  })
  const store = useStoreContext()
  const history = useHistory()
  const couvette = useSelector(s => s.couvette)

  const stateToKeepAfterReset = { couvette }
  const { storeType, storeId, langCode } = useStoreIndentity()
  const defaultLang = store.defaultLangCode || langCode
  const basePath = `/${storeType}/${storeId}/${defaultLang}`

  const resetSS = useCallback(() => {
    actions.resetStore()
    cleanupVMMVData()
    cleanupOneConfiguratorRecipes()
    serviceWorkerRegistration.unregister()
    resetLocalStorageState(stateToKeepAfterReset)
    history.push(basePath)
    location.reload() // force reload without cache to load new assets
  }, [actions, basePath, history, stateToKeepAfterReset])

  return resetSS
}
