import { lightTheme } from 'components/Keyboard'
import {
  DefaultColors,
  DefaultFonts,
  DefaultTheme,
  ElementsNewUiTheme,
  Sizes,
} from 'styled-components'
import electronics from './theme/electronics'
import greenShipment from './theme/greenShipment'
import nuance from './theme/nuance'
import prizm from './theme/prizm'

const UiElementssizes = {
  tiny: '0.6364rem', //14px
  smallest: '0.727rem', //16px
  small: '0.818rem', //18px
  medium: '1rem', //22px
  large: '1.091rem', //24px
  largest: '1.455rem', //32px
  huge: '1.8182rem', //40px
}

const elementsNewUiTheme: ElementsNewUiTheme = {
  typography: {
    color: '#333',
    primaryFont: 'Roboto,Helvetica,Arial,sans-serif',
    sizes: UiElementssizes,
  },
  button: {
    height: '2.27rem',
  },
  roundbutton: {
    outLinedDisabledColor: '#888888',
    outLinedColor: 'black',
    outLinedBorderColor: 'currentcolor',
    outLinedBorderSize: '1px',
    containedColor: 'white',
    containedBorderColor: 'transparent',
    containedBackGround: '#333',
    containedDisabledBackGround: '#888888',
    smallHeight: '1.59rem',
    smallFontSize: '0.73rem',
    mediumHeight: '2.27rem',
    mediumFontSize: '0.91rem',
    mediumLargeHeight: '3.1818rem',
    mediumLargeFontSize: '1.36rem',
    largeHeight: '3.64rem',
    largeFontSize: '1.36rem',
  },
  accordion: {
    color: '#555',
    iconWidth: '1.5em',
    iconHeight: '1.5em',
  },
}

// To name colors and avoid similars use this tool
// http://chir.ag/projects/name-that-color/
// Convert new name in camelCase

export const colors: DefaultColors = {
  primaryText: '#333333', // BodyPrimaryTextColor
  secondaryText: '#888888', // BodySecondaryTextColor
  bodyBackground: '#FFFFFF', // BodyBackgroundColor
  alto: '#D8D8D8',
  wildSand: '#f6f6f6',
  white: 'white',
  monza: '#d0021b',
  red: '#902727',
  green: '#37B00F',
  orange: '#FB882C',
  black: '#000',
  blue: '#031434',
  lightBlue: '#011F5D',
  brandeisBlue: '#0977ff',
  transparentBlue: '#e9f2ff',
  skyBlue: '#005192',
  lightPink: '#fdd7dc',
  codGray: '#111',
  emperor: '#555555',
  transparentBlack: 'rgba(0, 0, 0, 0.5)',
  mercury: '#e8e8e8',
  seashell: '#f1f1f1',
  pastelGreen: '#6EE484',
  brightGreen: '#ebfbee',
  asparagus: '#729755',
  stateBlue: '#C4C3EC',
  royalBlue: '#504BD6',
  titanWhite: '#E9E8FF',
  dustyGray: '#999',
  frenchGrey: '#D6D6D6',
  placeholder: '#757575',
}

const productCardTheme = {
  border: 'none',
  priceColor: 'inherit',
  colorsColor: 'inherit',
  iconAlignment: 'left',
  iconsHeight: '1.6rem',
  iconsMarginBottom: '0',
  cardPadding: '0.6rem',
  primaryFont: 'Roboto,Helvetica,Arial,sans-serif',
  secondaryFont: 'Amiri,Helvetica,Arial,sans-serif',
  altPrimaryFont: 'ScoutCond,Helvetica,Arial,sans-serif',
  altSecondaryFont: '"MorePro-CondBook",Helvetica,Arial,sans-serif',
  color: colors.emperor,
  imageMarginBottom: '0',
  imageMaxWidth: '100%',
  rowGutter: '0rem',
  priceOrder: '2',
  brandNameOrder: '1',
  modelCodeOrder: '5',
  modelNameOrder: '3',
  colorsOrder: '4',
  prescriptionOrder: '6',
}

export const fontSizesPx: Sizes = {
  heading1: 64,
  heading1Tablet: 64,
  heading1TabletMini: 64,
  heading2: 48,
  heading2Tablet: 48,
  heading2TabletMini: 48,
  heading3: 40,
  heading3Tablet: 40,
  heading3TabletMini: 40,
  heading4: 32,
  heading4Tablet: 32,
  heading4TabletMini: 32,
  heading5: 24,
  heading5Tablet: 24,
  heading5TabletMini: 24,
  body: 18,
  bodyTablet: 18,
  bodyTabletMini: 18,
  label: 14,
  labelTablet: 14,
  labelTabletMini: 14,
  caption: 12,
  captionTablet: 12,
  captionTabletMini: 12,
  cta: 18,
  ctaTablet: 18,
  ctaTabletMini: 18,
  link: 32,
  linkTablet: 32,
  linkTabletMini: 32,
}

export const fonts: DefaultFonts = {
  primary: 'sans-serif', // BodyFontFamily
  gilmerHeavy: 'Gilmer, sans-serif',
  secondary: 'Amiri,Helvetica,Arial,sans-serif',
  sizes: {
    shallow: '0.5rem', //11px  shallow
    tiny: '0.591rem', //13px
    smallest: '0.818rem', //18px
    small: '1.091rem', //24px
    medium: '1.3636rem', //30px
    large: '1.455rem', //32px
    largest: '1.6364rem', //36px
    huge: '2.2727rem', //50px
    great: '2.727rem', //60px
    giant: '3.182rem', //70px
  },
  heading1: {
    weight: 400,
    towerSize: 60,
    tabletSize: 56,
    tabletMiniSize: 48,
    towerLineHeight: 75,
    tabletLineHeight: 62,
    tabletMiniLineHeight: 60,
  },
  heading2: {
    weight: 300,
    towerSize: 40,
    tabletSize: 40,
    tabletMiniSize: 34,
    towerLineHeight: 48,
    tabletLineHeight: 48,
    tabletMiniLineHeight: 46,
  },
  heading3: {
    weight: 500,
    towerSize: 34,
    tabletSize: 34,
    tabletMiniSize: 26,
    towerLineHeight: 34,
    tabletLineHeight: 42,
    tabletMiniLineHeight: 34,
  },
  heading4: {
    weight: 300,
    towerSize: 24,
    tabletSize: 24,
    tabletMiniSize: 20,
    towerLineHeight: 30,
    tabletLineHeight: 36,
    tabletMiniLineHeight: 31,
  },
  heading5: {
    weight: 400,
    towerSize: 20,
    tabletSize: 20,
    tabletMiniSize: 18,
    towerLineHeight: 28,
    tabletLineHeight: 30,
    tabletMiniLineHeight: 28,
  },
  body: {
    weight: 400,
    towerSize: 18,
    tabletSize: 16,
    tabletMiniSize: 16,
    towerLineHeight: 26,
    tabletLineHeight: 24,
    tabletMiniLineHeight: 24,
  },
  inputs: {
    weight: 400,
    towerSize: 18,
    tabletSize: 16,
    tabletMiniSize: 16,
    towerLineHeight: 26,
    tabletLineHeight: 55,
    tabletMiniLineHeight: 24,
  },
  label: {
    weight: 400,
    towerSize: 14,
    tabletSize: 14,
    tabletMiniSize: 14,
    towerLineHeight: 24,
    tabletLineHeight: 20,
    tabletMiniLineHeight: 20,
  },
  cta: {
    weight: 400,
    towerSize: 18,
    tabletSize: 16,
    tabletMiniSize: 16,
    towerLineHeight: 26,
    tabletLineHeight: 26,
    tabletMiniLineHeight: 24,
  },
  link: {
    weight: 400,
    towerSize: 24,
    tabletSize: 18,
    tabletMiniSize: 20,
    towerLineHeight: 48,
    tabletLineHeight: 36,
    tabletMiniLineHeight: 31,
  },
  caption: {
    weight: 500,
    towerSize: 14,
    tabletSize: 14,
    tabletMiniSize: 12,
    towerLineHeight: 16,
    tabletLineHeight: 16,
    tabletMiniLineHeight: 14,
  },
  sizesPx: fontSizesPx,
}

const theme: DefaultTheme = {
  cta: {
    textColor: '#ffffff', // CtaTextColor
    backgroundColor: '#031434', // CtaBackgroundColor
    textAlignment: 'center', // CtaTextAlignment
    borderRadius: '0px', // CtaBorderRadius
    secondaryTextColor: colors.primaryText, // CtaSecondaryTextColor
    secondaryBackgroundColor: 'transparent', // CtaSecondaryBackgroundColor
    secondaryTextAlignment: 'center', // CtaSecondaryTextAlignment
    secondaryBorderRadius: '0px', // CtaSecondaryBorderRadius
  },
  menu: {
    textColor: '#000000', // MenuTextColor
    backgroundColor: '#ffffff', // MenuBackgroundColor
  },
  checkout: {
    checkbox_checked_border_color: colors.frenchGrey, // @grey
    input_border_color: colors.frenchGrey,
    input_error_color: colors.monza,
    collpase_border_color: colors.frenchGrey,
  },
  elementsNewUi: elementsNewUiTheme,
  fonts,
  radioColors: {
    gold: '#d8af58',
    silver: '#c0c0c0',
    rose: '#ffe4e1',
  },
  colors,
  inputFontColor: '#333',
  fontInput: 'Roboto,Helvetica,Arial,sans-serif',
  fontHeaderTitle: 'Roboto,Helvetica,Arial,sans-serif',
  fontActionButton: 'Roboto,Helvetica,Arial,sans-serif',
  productCard: productCardTheme,
  touchkeyboard: lightTheme,
  electronics,
  nuance,
  prizm,
  greenShipment,
}

export { theme }
