import { toggleFrameAdvisorMySelectionAction } from 'actions/ui'
import { addItemToWishlist, removeItemFromWishlist } from 'actions/wishlist'
import { formatProductForUndo } from 'libs/formatters'
import {
  AdvisorSuggestion,
  BiometricData,
  EyeColor,
  FaProductType,
  FrameAdvisorUserProfile,
  FrameAdvisorViewMode,
  HairColor,
  LoveHatesData,
  Mood,
  SkinUndertone,
  UndoData,
} from 'types/frameAdvisor'
import { ContentV2PlaylistItem } from 'types/graphqlTypes'
import { Product } from 'types/product'
import { ThunkAction } from '../store'

export const UPDATE_SURVEY = 'frameAdvisor/UPDATE_SURVEY'
export interface UpdateSurvey {
  type: typeof UPDATE_SURVEY
  payload: {
    key: string
    tag: string
  }
}

export const UPDATE_SURVEY_STEPS = 'frameAdvisor/UPDATE_SURVEY_STEPS'
export interface UpdateSurveySteps {
  type: typeof UPDATE_SURVEY_STEPS
  payload: Record<number, string>
}

export const RESET_SURVEY = 'frameAdvisor/RESET_SURVEY'
export interface ResetSurvey {
  type: typeof RESET_SURVEY
}

export const SET_COMPLETE_SURVEY = 'frameAdvisor/COMPLETE_SURVEY'
export interface SetCompleteSurvey {
  type: typeof SET_COMPLETE_SURVEY
  payload: boolean
}
export const REMOVE_SURVEY_CHOICE = 'frameAdvisor/REMOVE_SURVEY_CHOICE'
export interface RemoveSurveyChoice {
  type: typeof REMOVE_SURVEY_CHOICE
  payload: string
}
export const SAVE_LAST_STEP_FOR_BACK = 'frameAdvisor/SAVE_LAST_STEP_FOR_BACK'
export interface SaveLastStepForBack {
  type: typeof SAVE_LAST_STEP_FOR_BACK
  payload: string
}

export const SET_ON_CAPTURE_PROCESS = 'frameAdvisor/SET_ON_CAPTURE_PROCESS'

export interface SetOnCaptureProcess {
  type: typeof SET_ON_CAPTURE_PROCESS
  payload: boolean
}

export const ADD_LOVE_HATE_MOCO = 'frameAdvisor/ADD_LOVE_HATE_MOCO'

export const SAVE_MOCO_FOR_UNDO = 'frameAdvisor/SAVE_MOCO_FOR_UNDO'
export const REMOVE_MOCO_FROM_UNDO = 'frameAdvisor/REMOVE_MOCO_FROM_UNDO'
export const UNDO_HATE_ITEM = 'frameAdvisor/UNDO_HATE_ITEM'

export interface AddLoveHateMoco {
  type: typeof ADD_LOVE_HATE_MOCO
  payload: LoveHatesData
}

export interface SaveMocoForUndo {
  type: typeof SAVE_MOCO_FOR_UNDO
  payload: UndoData
}

export interface RemoveMocoFromUndo {
  type: typeof REMOVE_MOCO_FROM_UNDO
  payload: UndoData
}

export interface UndoHateItem {
  type: typeof UNDO_HATE_ITEM
  payload: UndoData
}

export const REMOVE_LOVE_HATE_MOCO = 'frameAdvisor/REMOVE_LOVE_HATE_MOCO'
export interface RemoveLoveHateMoco {
  type: typeof REMOVE_LOVE_HATE_MOCO
  payload: {
    moco: string
    playlistId?: string
  }
}

export const RESET_LOVE_HATE = 'frameAdvisor/RESET_LOVE_HATE'
export interface ResetLoveHate {
  type: typeof RESET_LOVE_HATE
}

export const UPDATE_VISITED = 'frameAdvisor/UPDATE_VISITED'
export interface UpdateVisited {
  type: typeof UPDATE_VISITED
  payload: {
    visited: boolean
  }
}

export const ADD_TO_MYSELECTION = 'frameAdvisor/ADD_TO_MYSELECTION'
export interface AddToMySelection {
  type: typeof ADD_TO_MYSELECTION
  payload: {
    product: Product
  }
}

export const REMOVE_FROM_MYSELECTION = 'frameAdvisor/REMOVE_FROM_MYSELECTION'
export interface RemoveFromMySelection {
  type: typeof REMOVE_FROM_MYSELECTION
  payload: {
    moco: string
  }
}

export const SAVE_FSA_VIDEOID = 'frameAdvisor/SAVE_FSA_VIDEOID'
export const SAVE_FSA_INFO = 'frameAdvisor/SAVE_FSA_INFO'

export interface SaveFsaVideoId {
  type: typeof SAVE_FSA_VIDEOID
  payload: {
    videoId: string
  }
}
export interface SaveFsaInfo {
  type: typeof SAVE_FSA_INFO
  payload: {
    uuid: string
    profilePictureUrl: string
    pictureUuid: string
    videoUuid: string
    playlistId: string
    userInformation: {
      faceBreadth: number
      glassesType: string
    }
    userSearchInformation: {
      gender: string
      age: string
      skinUndertones: SkinUndertone
      faceShape: string
      faceLength: string
      hairColor: HairColor
      eyeColor: EyeColor
      eyebrowsThickness: string
      eyeMood: string
      sunMood: string
      darkCircles: string
    }
    advisorInformation: Record<FaProductType, Record<Mood, AdvisorSuggestion>> | null
  }
}

export const SAVE_FSA_USERID = 'frameAdvisor/SAVE_FSA_USER'
export interface SaveFsaUserId {
  type: typeof SAVE_FSA_USERID
  payload: {
    userId: string
  }
}

export const RESET_FRAME_ADVISOR = 'frameAdvisor/RESET_FRAME_ADVISOR'
export const CLEAR_FSA = 'frameAdvisor/CLEAR_FSA'
export interface ResetFrameAdvisor {
  type: typeof RESET_FRAME_ADVISOR
}
export interface ClearFsa {
  type: typeof CLEAR_FSA
}

export const TOGGLE_COUVETTE_DRESSED_ON = 'frameAdvisor/TOGGLE_COUVETTE_DRESSED_ON'
export interface ToggleCouvetteDressedOn {
  type: typeof TOGGLE_COUVETTE_DRESSED_ON
}

export const TOGGLE_WISHLIST_DRESSED_ON = 'frameAdvisor/TOGGLE_WISHLIST_DRESSED_ON'

export interface ToggleWishlistDressedOn {
  type: typeof TOGGLE_WISHLIST_DRESSED_ON
}

export const SELECT_FRAME_ADVISOR_PLAYLIST = 'frameAdvisor/SELECT_FRAME_ADVISOR_PLAYLIST'
export interface SelectFrameAdvisorPlaylist {
  type: typeof SELECT_FRAME_ADVISOR_PLAYLIST
  payload: {
    playlist: ContentV2PlaylistItem
  }
}

export const SELECT_FRAME_ADVISOR_SHADES = 'frameAdvisor/SELECT_FRAME_ADVISOR_SHADES'

export const SET_SELECTED_OPTION_ID = 'frameAdvisor/SET_SELECTED_OPTION_ID'
export const SET_SELECTED_OPTION_IDS = 'frameAdvisor/SET_SELECTED_OPTION_IDS'
export interface SetSelectedOptionId {
  type: typeof SET_SELECTED_OPTION_ID
  payload: {
    id: string
    key: string
  }
}
export interface SetSelectedOptionIds {
  type: typeof SET_SELECTED_OPTION_IDS
  payload: Record<string, string>
}
export interface SelectFrameAdvisorShades {
  type: typeof SELECT_FRAME_ADVISOR_SHADES
  payload: {
    shadesFor: string
  }
}

export const SET_FRAME_ADVISOR_MYSELECTION_VISITED =
  'frameAdvisor/SET_FRAME_ADVISOR_MYSELECTION_VISITED'
export interface SetFrameAdvisorMySelectionVisited {
  type: typeof SET_FRAME_ADVISOR_MYSELECTION_VISITED
}

export const SAVE_FACE_SCAN_TOKENS = 'frameAdvisor/SAVE_FACE_SCAN_TOKENS'
export const SAVE_SURVEY_TOKENS = 'frameAdvisor/SAVE_SURVEY_TOKENS'
export interface SaveFsaAccessToken {
  type: typeof SAVE_FACE_SCAN_TOKENS | typeof SAVE_SURVEY_TOKENS
  payload: {
    accessToken: string
    refreshToken: string
  }
}

export const SET_SENTENCE_MAP = 'frameAdvisor/SET_SENTENCE_MAP'
export interface SetSentenceMap {
  type: typeof SET_SENTENCE_MAP
  payload: {
    sentenceMap: Record<string, number>
  }
}

export const UPDATE_STYLES_PREFERENCES = 'frameAdvisor/UPDATE_STYLES_PREFERENCES'
export interface UpdateStylesPreferences {
  type: typeof UPDATE_STYLES_PREFERENCES
  payload: boolean
}

export const UPDATE_BIOMETRIC_DATA = 'frameAdvisor/UPDATE_BIOMETRIC_DATA'
export interface UpdateBiometricData {
  type: typeof UPDATE_BIOMETRIC_DATA
  payload: Partial<BiometricData>
}

export const UPDATE_FLAG_AGE_DECLARATION = 'frameAdvisor/UPDATE_FLAG_AGE_DECLARATION'
export interface UpdateFlagAgeDeclaration {
  type: typeof UPDATE_FLAG_AGE_DECLARATION
  payload: boolean
}

export const UPDATE_VIEW_MODE = 'frameAdvisor/UPDATE_VIEW_MODE'
export interface UpdateViewMode {
  type: typeof UPDATE_VIEW_MODE
  payload: FrameAdvisorViewMode
}

export const setFrameAdvisorMyselectionVisited = (): SetFrameAdvisorMySelectionVisited => ({
  type: SET_FRAME_ADVISOR_MYSELECTION_VISITED,
})

export const updateSurvey = (key: string, tag: string): UpdateSurvey => ({
  type: UPDATE_SURVEY,
  payload: {
    key,
    tag,
  },
})

export const updateSurveySteps = (steps: Record<number, string>): UpdateSurveySteps => ({
  type: UPDATE_SURVEY_STEPS,
  payload: steps,
})

export const resetSurvey = (): ResetSurvey => ({
  type: RESET_SURVEY,
})

export const saveLastStepForBack = (stepId: string): SaveLastStepForBack => ({
  type: SAVE_LAST_STEP_FOR_BACK,
  payload: stepId,
})

export const setCompleteSurvey = (isComplete: boolean): SetCompleteSurvey => ({
  type: SET_COMPLETE_SURVEY,
  payload: isComplete,
})

export const setOnCaptureProcess = (isScanning: boolean): SetOnCaptureProcess => ({
  type: SET_ON_CAPTURE_PROCESS,
  payload: isScanning,
})

export const loveMoco = (product: Product, playListId?: string): AddLoveHateMoco => ({
  type: ADD_LOVE_HATE_MOCO,
  payload: {
    status: '0',
    product,
    moco: product.moco,
    timestamp: new Date().toISOString(),
    playListId,
  },
})

export const hateMoco = (product: Product, playListId?: string): AddLoveHateMoco => ({
  type: ADD_LOVE_HATE_MOCO,
  payload: {
    status: '1',
    product,
    moco: product.moco,
    timestamp: new Date().toISOString(),
    playListId,
  },
})

export const saveMocoForUndo = (product: UndoData): SaveMocoForUndo => ({
  type: SAVE_MOCO_FOR_UNDO,
  payload: formatProductForUndo(product),
})

export const removeMocoFromUndo = (product: UndoData): RemoveMocoFromUndo => ({
  type: REMOVE_MOCO_FROM_UNDO,
  payload: formatProductForUndo(product),
})

export const undoHate = (product: UndoData): UndoHateItem => ({
  type: UNDO_HATE_ITEM,
  payload: formatProductForUndo(product),
})

export const removeMoco = (product: Product, playlistId?: string): RemoveLoveHateMoco => ({
  type: REMOVE_LOVE_HATE_MOCO,
  payload: {
    moco: product.moco,
    playlistId,
  },
})

export const resetLoveHate = (): ResetLoveHate => ({
  type: RESET_LOVE_HATE,
})

export const updateVisited = (visited: boolean): UpdateVisited => ({
  type: UPDATE_VISITED,
  payload: {
    visited,
  },
})

export const addToMySelection = (product: Product): AddToMySelection => {
  return {
    type: ADD_TO_MYSELECTION,
    payload: {
      product: product,
    },
  }
}

export const removeFromMySelection = (moco: string): RemoveFromMySelection => {
  return {
    type: REMOVE_FROM_MYSELECTION,
    payload: {
      moco,
    },
  }
}

export const saveFsaVideoId = (videoId: string): SaveFsaVideoId => {
  return {
    type: SAVE_FSA_VIDEOID,
    payload: {
      videoId,
    },
  }
}

export const saveFsaInfo = (fsaInfo: FrameAdvisorUserProfile): SaveFsaInfo => {
  return {
    type: SAVE_FSA_INFO,
    payload: {
      uuid: fsaInfo.uuid || '',
      profilePictureUrl: fsaInfo.profilePictureUrl || null,
      pictureUuid: fsaInfo.pictureUuid || null,
      videoUuid: fsaInfo.videoUuid || null,
      playlistId: fsaInfo.playlistId || null,
      userInformation: {
        faceBreadth: fsaInfo.userInformation.faceBreadth || null,
        glassesType: fsaInfo.userInformation.glassesType || null,
      },
      userSearchInformation: {
        gender: fsaInfo.userSearchInformation.gender || null,
        age: fsaInfo.userSearchInformation.age || null,
        skinUndertones: fsaInfo.userSearchInformation.skinUndertones || null,
        faceShape: fsaInfo.userSearchInformation.faceShape || null,
        faceLength: fsaInfo.userSearchInformation.faceLength || null,
        hairColor: fsaInfo.userSearchInformation.hairColor || null,
        eyeColor: fsaInfo.userSearchInformation.eyeColor || null,
        eyebrowsThickness: fsaInfo.userSearchInformation.eyebrowsThickness || null,
        eyeMood: fsaInfo.userSearchInformation.eyeMood || null,
        sunMood: fsaInfo.userSearchInformation.sunMood || null,
        darkCircles: fsaInfo.userSearchInformation.darkCircles || null,
      },
      advisorInformation: fsaInfo.advisorInformation || null,
    },
  }
}

export const resetFrameAdvisor = (): ResetFrameAdvisor => {
  return {
    type: RESET_FRAME_ADVISOR,
  }
}

export const clearFsa = (): ClearFsa => {
  return {
    type: CLEAR_FSA,
  }
}

export const toggleCouvetteDressedOn = (): ToggleCouvetteDressedOn => ({
  type: TOGGLE_COUVETTE_DRESSED_ON,
})

export const toggleWishlistDressedOn = (): ToggleWishlistDressedOn => ({
  type: TOGGLE_WISHLIST_DRESSED_ON,
})

export const loveItem = (product: Product, playListId?: string): ThunkAction => dispatch => {
  dispatch(loveMoco(product, playListId))
  dispatch(addItemToWishlist(product))
  return dispatch(addToMySelection(product))
}

export const hateItem = (product: Product, playListId?: string): ThunkAction => dispatch => {
  dispatch(hateMoco(product, playListId))
  dispatch(removeItemFromWishlist(product))
  return dispatch(removeFromMySelection(product.moco))
}

export const saveToUndoableList = (product: UndoData): ThunkAction => dispatch => {
  return dispatch(saveMocoForUndo(product))
}

export const removeFromUndoableList = (product: UndoData): ThunkAction => dispatch => {
  return dispatch(removeMocoFromUndo(product))
}

export const undoHatedItem = (product: UndoData): ThunkAction => dispatch => {
  return dispatch(undoHate(product))
}

export const removeItemFromMySelection = (
  product: Product,
  playlistId?: string
): ThunkAction => dispatch => {
  dispatch(removeMoco(product, playlistId))
  dispatch(removeItemFromWishlist(product))
  return dispatch(removeFromMySelection(product.moco))
}

export const selectFrameAdvisorPlaylist = (
  playlist: ContentV2PlaylistItem
): SelectFrameAdvisorPlaylist => {
  return {
    type: SELECT_FRAME_ADVISOR_PLAYLIST,
    payload: {
      playlist,
    },
  }
}

export const selectFrameAdvisorShades = (shadesFor: string): SelectFrameAdvisorShades => {
  return {
    type: SELECT_FRAME_ADVISOR_SHADES,
    payload: {
      shadesFor,
    },
  }
}

export const setSelectedOptionId = (id: string, key: string): SetSelectedOptionId => {
  return {
    type: SET_SELECTED_OPTION_ID,
    payload: {
      id,
      key,
    },
  }
}

export const setSelectedOptionIds = (keys: Record<string, string>): SetSelectedOptionIds => {
  return {
    type: SET_SELECTED_OPTION_IDS,
    payload: keys,
  }
}

export const toggleFrameAdvisorMySelectionSetVisited = (): ThunkAction => dispatch => {
  dispatch(toggleFrameAdvisorMySelectionAction)
  dispatch(setFrameAdvisorMyselectionVisited())
}

export const saveFsaUserId = (userId: string): SaveFsaUserId => {
  return {
    type: SAVE_FSA_USERID,
    payload: {
      userId,
    },
  }
}

export const saveFaceScanTokens = (
  accessToken: string,
  refreshToken: string
): SaveFsaAccessToken => {
  return {
    type: SAVE_FACE_SCAN_TOKENS,
    payload: {
      accessToken,
      refreshToken,
    },
  }
}

export const saveSurveyTokens = (accessToken: string, refreshToken: string): SaveFsaAccessToken => {
  return {
    type: SAVE_SURVEY_TOKENS,
    payload: {
      accessToken,
      refreshToken,
    },
  }
}

export const setSentenceMap = (sentenceMap: Record<string, number>): SetSentenceMap => ({
  type: SET_SENTENCE_MAP,
  payload: {
    sentenceMap,
  },
})

export const removeSurveyChoice = (choiceKey: string): RemoveSurveyChoice => ({
  type: REMOVE_SURVEY_CHOICE,
  payload: choiceKey,
})

export const updateStylesPreferences = (completed: boolean): UpdateStylesPreferences => ({
  type: UPDATE_STYLES_PREFERENCES,
  payload: completed,
})

export const updateBiometricData = (data: Partial<BiometricData>): UpdateBiometricData => ({
  type: UPDATE_BIOMETRIC_DATA,
  payload: data,
})

export const updateFlagAgeDeclaration = (checked: boolean): UpdateFlagAgeDeclaration => ({
  type: UPDATE_FLAG_AGE_DECLARATION,
  payload: checked,
})

export const removeItemFromFASelection = (product: Product): ThunkAction => dispatch => {
  dispatch(removeMoco(product))
  return dispatch(removeFromMySelection(product.moco))
}

export const updateFrameAdvisorViewMode = (mode: FrameAdvisorViewMode): UpdateViewMode => ({
  type: UPDATE_VIEW_MODE,
  payload: mode,
})

export type FrameAdvisorActionTypes =
  | UpdateSurvey
  | ResetSurvey
  | RemoveSurveyChoice
  | SetCompleteSurvey
  | AddLoveHateMoco
  | RemoveLoveHateMoco
  | ResetLoveHate
  | UpdateVisited
  | AddToMySelection
  | RemoveFromMySelection
  | SaveFsaVideoId
  | SaveFsaInfo
  | ResetFrameAdvisor
  | ClearFsa
  | ToggleCouvetteDressedOn
  | ToggleWishlistDressedOn
  | SelectFrameAdvisorPlaylist
  | SelectFrameAdvisorShades
  | SetFrameAdvisorMySelectionVisited
  | SaveMocoForUndo
  | RemoveMocoFromUndo
  | UndoHateItem
  | SetSelectedOptionId
  | SetSelectedOptionIds
  | SaveFsaUserId
  | SaveFsaAccessToken
  | SetSentenceMap
  | UpdateSurveySteps
  | SaveLastStepForBack
  | SetOnCaptureProcess
  | UpdateStylesPreferences
  | UpdateBiometricData
  | UpdateFlagAgeDeclaration
  | UpdateViewMode
