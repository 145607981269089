import { removeItemFromWishlist } from 'actions/wishlist'
import { createCartItemId, stock } from 'libs/cart'
import { Action, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AppState } from 'store'
import { CustomProduct, Product } from 'types/product'
import { toggleMiniCartDrawer } from './ui'

export const ADD_ITEM = 'cart/ADD_ITEM'
export const REMOVE_ITEM = 'cart/REMOVE_ITEM'
export const SET_ITEM_QUANTITY = 'cart/SET_ITEM_QUANTITY'
export const CLEAR_CART_ITEM_OVERLAY = 'cart/CLEAR_CART_ITEM_OVERLAY'

/** Before add an item to cart we have to check
 *  whether it is already in cart
 **/

interface AddToCart {
  type: typeof ADD_ITEM
  payload: {
    id: string
    product: CustomProduct
    quantity: number
    storeId: string
  }
}

interface RemoveItemAction {
  type: typeof REMOVE_ITEM
  payload: {
    id: string
  }
}

interface SetItemQuantityAction {
  type: typeof SET_ITEM_QUANTITY
  payload: {
    id: string
    quantity: number
  }
}

interface ClearCartItemOverlayAction {
  type: typeof CLEAR_CART_ITEM_OVERLAY
}

type CartThunkAction = ThunkAction<void, AppState, unknown, Action<string>>

export const addToCart = (
  id: string,
  product: CustomProduct,
  quantity: number,
  storeId: string
) => (dispatch: Dispatch<AddToCart>) => {
  dispatch({
    type: ADD_ITEM,
    payload: {
      id,
      product,
      quantity,
      storeId,
    },
  })
}

const addItemToCartAction = {
  ZPFN: (product, storeId, quantity) => {
    const id = createCartItemId(product.UPC)
    return () => addToCart(id, product, quantity, storeId)
  },
  OCP: (product, storeId) => {
    const { recipeId } = product
    const id = createCartItemId(recipeId)
    return () => addToCart(id, product, 1, storeId)
  },
  // TODO: the REMIX product type doesn't exist, we use CUSTOMRB
  // REMIX: (dispatch, product, storeId) => {
  //   const { token } = product
  //   const id = createCartItemId(token)
  //   addToCart(id, product, 1, storeId)
  // },
  CUSTOMRB: (product, storeId) => {
    const { recipeId } = product
    const id = createCartItemId(recipeId)
    return () => addToCart(id, product, 1, storeId)
  },
}
export const addItemToCart = ({
  product,
  storeId,
  quantity = 1,
  type = 'ZPFN',
  toggleMinicart = true,
}: {
  product: CustomProduct
  storeId: string
  quantity?: number
  type?: string
  toggleMinicart?: boolean
}): CartThunkAction => {
  return function (dispatch) {
    const action = addItemToCartAction[type]
    const cb = action(product, storeId, quantity)
    dispatch(cb())

    if (toggleMinicart) {
      dispatch(toggleMiniCartDrawer)
    }
  }
}

export const addWishlistItemsToCart = (products: Product[], storeId: string): CartThunkAction => {
  return function (dispatch, getState) {
    const { cart } = getState()
    products.forEach((product, i) => {
      if (stock(cart, product) !== 0) {
        dispatch(
          addItemToCart({
            product,
            storeId,
            quantity: 1,
            toggleMinicart: i === products.length - 1, // to toggle only for last item, as for even numbers it`s not working
          })
        )
        dispatch(removeItemFromWishlist(product))
      }
    })
  }
}

// removeItemFromCart
export const removeItemFromCart = (id: string) => {
  return function (dispatch: Dispatch<RemoveItemAction>) {
    dispatch({
      type: REMOVE_ITEM,
      payload: {
        id,
      },
    })
  }
}

export const setCartItemQuantity = (id: string, quantity: number) => (
  dispatch: Dispatch<SetItemQuantityAction>
) => {
  dispatch({
    type: SET_ITEM_QUANTITY,
    payload: {
      id,
      quantity,
    },
  })
}

export const clearCartItemOverlay = (): ClearCartItemOverlayAction => {
  return {
    type: CLEAR_CART_ITEM_OVERLAY,
  }
}

export type CartActions =
  | AddToCart
  | RemoveItemAction
  | SetItemQuantityAction
  | ClearCartItemOverlayAction
