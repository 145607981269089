import { addItemToCart } from 'actions/cart'
import { PrimaryButton } from 'components/core/Button'
import { ColorSwatch } from 'components/FrameAdvisor/ColorElements'
import { Price } from 'components/pdp/Price'
import { Heading5 } from 'components/Typography'
import { useSendAnalyticsAddToCartEvent } from 'containers/AnalyticsProvider'
import { useActions } from 'hooks/useActions'
import { useCart } from 'hooks/useCart'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { notNull } from 'libs/formatters'
import { getProductImages } from 'libs/image'
import { dataDescriptionForProduct } from 'libs/utils'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Product } from 'types/product'
import {
  MerchCardColorSwitchTitle,
  MerchCardContentWrapper,
  MerchCardCTAWrapper,
  MerchCardDescription,
  MerchCardImageWrapper,
  MerchCardPrice,
  MerchCardTextContainer,
  MerchCardWrapper,
  MerchColorSwatch,
  MerchSwatchesWrapper,
} from './styles'

interface MerchCardProps {
  product: Product
}

const MerchCardComponent: React.FC<MerchCardProps> = ({ product }) => {
  const { t } = useTranslation()
  const { storeId } = useStoreIndentity()
  const { sendAddToCartEvent } = useSendAnalyticsAddToCartEvent({})
  const { items } = useCart()

  const variants = useMemo(() => [product, ...(product.variants || [])].filter(notNull), [product])

  const [selectedVariant, setSelectedVariant] = useState<Product>(variants[0])

  const imageSource = getProductImages(selectedVariant)?.[0]?.file

  const actions = useActions({
    addToCart: addItemToCart,
  })

  const handleColorClick = useCallback(
    color => {
      setSelectedVariant(color)
    },
    [setSelectedVariant]
  )

  const isItemInCartAlready = items.find(item => item.product.UPC === selectedVariant.UPC)

  const handleAddToCart = useCallback(() => {
    actions.addToCart({ product: selectedVariant, storeId: storeId || '', toggleMinicart: false })
    sendAddToCartEvent(selectedVariant)
  }, [selectedVariant, actions, storeId, sendAddToCartEvent])

  return (
    <MerchCardWrapper>
      <MerchCardImageWrapper>
        <div>
          <img src={imageSource} />
        </div>
      </MerchCardImageWrapper>
      <MerchCardContentWrapper>
        <MerchCardPrice>
          <Heading5 weight="bold">
            <Price
              price={selectedVariant.price.current.value}
              currency={selectedVariant.price.currency}
            />
          </Heading5>
        </MerchCardPrice>
        <MerchCardTextContainer>
          <Heading5 weight="bold">{selectedVariant.name}</Heading5>
          <MerchCardDescription>
            {selectedVariant.description ||
              'Nome che va su due rghe molto molto lungo si vdsòlfjòdsalfj fjlj jsdl sdfl'}
          </MerchCardDescription>
          <MerchCardColorSwitchTitle>
            <span>{t('Core.color')} </span>
            {selectedVariant.lensColor}
          </MerchCardColorSwitchTitle>
          <MerchSwatchesWrapper>
            {variants.map((item: Product, i) => (
              <MerchColorSwatch
                key={i}
                onClick={() => handleColorClick(item)}
                active={selectedVariant.UPC === item.UPC}
              >
                <ColorSwatch background={item.lensColor} />
              </MerchColorSwatch>
            ))}
          </MerchSwatchesWrapper>
        </MerchCardTextContainer>
        <MerchCardCTAWrapper>
          <PrimaryButton
            data-element-id="Prod_AddCart"
            data-description={dataDescriptionForProduct(selectedVariant)}
            onClick={handleAddToCart}
            disabled={!!isItemInCartAlready}
          >
            {isItemInCartAlready ? t('ProductView.added') : t('ProductView.add')}
          </PrimaryButton>
        </MerchCardCTAWrapper>
      </MerchCardContentWrapper>
    </MerchCardWrapper>
  )
}

export default MerchCardComponent
