import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'

export const useSendUnavailablePrizmAnalyticsEvent = () => {
  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    Page_Section2: 'Barcode:Standard:NOUpc:PrizmOnly',
  })

  const sendDiscoverEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: 'BarcodeNoResultStd_Prizm',
  })

  return {
    sendDiscoverEvent,
    sendImpressionEvent,
  }
}
