import {
  CalibrationMethods,
  DigitalCouvetteSizeWidths,
  Frame,
  Size,
} from 'components/DigitalCouvette/types'
import {
  CouvetteActionTypes,
  RESET_COUVETTE,
  SET_CALIBRATION_COMPLETED,
  SET_CALIBRATION_METHOD,
  SET_CALIBRATION_SIZES,
  SET_SELECTED_FRAME,
  SET_SELECTED_SIZE,
  SET_SHOW_CALIBRATION,
  SET_SHOW_COUVETTE_SCALE,
} from '../actions/couvette'

export interface CouvetteState {
  selectedFrame?: Frame
  showCalibration: false
  calibrationMethod: keyof typeof CalibrationMethods
  calibrationCompleted: boolean
  showCouvetteScale: boolean
  calibrationSizes?: DigitalCouvetteSizeWidths
  selectedSize?: Size
}

const initialState: CouvetteState = {
  selectedFrame: null,
  showCalibration: false,
  calibrationMethod: 'frame',
  calibrationCompleted: false,
  showCouvetteScale: false,
  calibrationSizes: null,
  selectedSize: null,
}

export default (state = initialState, action: CouvetteActionTypes) => {
  switch (action.type) {
    case SET_SELECTED_FRAME:
      return { ...state, selectedFrame: action.payload }
    case SET_SHOW_CALIBRATION:
      return { ...state, showCalibration: action.payload }
    case SET_CALIBRATION_METHOD:
      return { ...state, calibrationMethod: action.payload }
    case SET_CALIBRATION_COMPLETED:
      return { ...state, calibrationCompleted: action.payload }
    case SET_SHOW_COUVETTE_SCALE:
      return { ...state, showCouvetteScale: action.payload }
    case SET_CALIBRATION_SIZES:
      return { ...state, calibrationSizes: action.payload }
    case SET_SELECTED_SIZE:
      return { ...state, selectedSize: action.payload }
    case RESET_COUVETTE:
      return initialState
    default:
      return state
  }
}
