import { pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const ICON_SIZE = pxToRem(27)

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    object-fit: contain;
  }
`

export const CheckedIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 2.273rem;
  z-index: 2;
`
