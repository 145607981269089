import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useAction } from './useActions'
import { hideAllDrawer as hideAllDrawerAction } from 'actions/ui'

export const useCloseDrawerOnPageChange = () => {
  const hideAllDrawer = useAction(hideAllDrawerAction)

  const {
    location: { pathname },
  } = useHistory()

  useEffect(() => {
    hideAllDrawer()
  }, [pathname, hideAllDrawer])
}
