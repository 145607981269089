import React from 'react'
import { IconProps } from 'types/icons'
export const MSizeIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 35"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M0 0H8.448L17.904 26.832H18L27.216 0H35.568V34.272H29.856V7.824H29.76L20.256 34.272H15.312L5.808 7.824H5.712V34.272H0V0Z"
        fill={fill}
      />
    </svg>
  )
}
