import { ApolloProvider } from '@apollo/client'
import config from 'config'
import { CMSDrivenThemeProvider } from 'context/themeContext'
import { isIPadView } from 'libs/utils'
import TranslationProvider from 'providers/translationProvider'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import client from './apolloClient'
import Log from './libs/Log'
import { initOfflineErrorNotifier } from './libs/offline'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

window.addEventListener('error', errorEvent => {
  const { message } = errorEvent
  Log.error(message, 'UI generic error')
})

initOfflineErrorNotifier()

ReactDOM.render(
  <CMSDrivenThemeProvider>
    <ApolloProvider client={client}>
      <Suspense fallback={null}>
        <TranslationProvider>
          <App />
        </TranslationProvider>
      </Suspense>
    </ApolloProvider>
  </CMSDrivenThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// TODO: check that the register/unregister flow are corretly working based on the env variable status
config.serviceWorkerEnabled && !isIPadView()
  ? serviceWorkerRegistration.register()
  : serviceWorkerRegistration.unregister()
