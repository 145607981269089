import { InitializeParameters } from '@luxottica/capture-component'
import type { Config } from 'vmmv'
import { ConfigureParams } from './oneConfigurator'

export enum Orientation {
  Portrait = 'portrait',
  Landscape = 'landscape',
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>
}

export type Comparator<T> = (a: T, b: T) => number

declare global {
  interface Window {
    configureParams?: ConfigureParams
    vmmvQRCodeParams?: Config
    vmmvParams?: Config
    faParams?: InitializeParameters
    smartshopperLoadedFluidScript?: string[]
  }
}
