import { BarcodeScanModalProps } from 'components/BarCodeScanModal/types'
import { PrimaryButton } from 'components/core/Button'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BarcodeScanModalBase } from '../BarcodeScanModalBase'
import { useSendUnavailablePolarizedAnalyticsEvent } from './analytics'

export const BarcodeScanModalUnavailablePolarized = ({
  visible,
  onCancel,
  productInfo,
}: BarcodeScanModalProps) => {
  const { t } = useTranslation()
  const { originalBrand, moco, model } = productInfo
  const thumbnail = {
    brand: originalBrand,
    materialCode: model,
    moco,
  }
  const subtitle = t('BarCodeScanModal.standardSameModelSubtitle')
  const title = t('BarCodeScanModal.findOutPolarizedVersion')

  const { sendDiscoverEvent, sendImpressionEvent } = useSendUnavailablePolarizedAnalyticsEvent()

  useEffect(() => {
    if (visible) {
      sendImpressionEvent()
    }
  }, [sendImpressionEvent, visible])

  const handleOnCancel = useCallback(() => {
    sendDiscoverEvent()
    onCancel()
  }, [onCancel, sendDiscoverEvent])

  return (
    <BarcodeScanModalBase
      visible={visible}
      title={title}
      subtitle={subtitle}
      description={''}
      formattedBrand={originalBrand}
      thumbnail={thumbnail}
      onCancel={handleOnCancel}
      footerContent={[
        <PrimaryButton key="cancel" onClick={handleOnCancel}>
          {t('BarCodeScanModal.discoverPolarized')}
        </PrimaryButton>,
      ]}
    />
  )
}
