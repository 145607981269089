import { CustomizeIcon, FaCustomizeIcon, SelectedIcon, VmIcon } from 'components/core/Icons'
import { RtrIcon } from 'components/core/Icons/RTRIcon'
import { pxToRem } from 'libs/styled'
import React from 'react'
import { CheckedIconContainer, IconContainer } from './icons.styles'

export const StyledRtrIcon = () => (
  <IconContainer>
    <RtrIcon height={pxToRem(32)} width={pxToRem(32)} />
  </IconContainer>
)

export const StyledVmIcon = () => (
  <IconContainer>
    <VmIcon height={pxToRem(32)} width={pxToRem(32)} />
  </IconContainer>
)

export const StyledCustomizeIcon = () => (
  <IconContainer>
    <CustomizeIcon />
  </IconContainer>
)

export const StyledFaCustomizeIcon = () => (
  <IconContainer>
    <FaCustomizeIcon height={pxToRem(32)} width={pxToRem(32)} />
  </IconContainer>
)

export const StyledCheckedIcon = () => (
  <CheckedIconContainer>
    <SelectedIcon />
  </CheckedIconContainer>
)
