import React from 'react'
import { IconProps } from 'types/icons'

export const RestartIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <g>
        <path
          d="M16 0.062C11.7953 0.062 7.82667 1.68267 4.82467 4.62467C4.372 5.068 3.948 5.54 3.55067 6.034V0H2.21734V8.682H10.8993V7.34867H4.21934C4.68467 6.71867 5.198 6.124 5.75734 5.576C8.50934 2.88 12.1467 1.39533 16 1.39533C24.07 1.39533 30.636 7.96133 30.636 16.0313C30.636 24.1013 24.07 30.6667 16 30.6667C7.93 30.6667 1.364 24.1013 1.364 16.0313H0.0306702C0.0306702 24.8367 7.19467 32 16 32C24.8053 32 31.9693 24.8367 31.9693 16.0313C31.9693 7.226 24.8053 0.062 16 0.062Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_42257_11579">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
