import React from 'react'

import { ValidateStatus } from 'types/checkoutV3'
import { FormItem } from '../FormItem'
import { InputStyled } from './styles'

interface FormInputProps {
  name: string
  type: HTMLInputElement['type']
  label: string

  value?: string
  required?: boolean
  placeholder?: string
  className?: string
  feedback?: string
  onChange?: (val: string) => void
  onBlur?: (val: string) => void
  fieldDescription?: JSX.Element
  hasFeedback?: boolean
  validateStatus: ValidateStatus
  help?: string
}

export const FormInput: React.FC<FormInputProps> = ({
  name,
  value,
  placeholder,
  type,
  label,
  fieldDescription,
  required,
  hasFeedback,
  validateStatus,
  help,
  className,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onBlur = () => {},
}) => (
  <FormItem
    label={label}
    required={required}
    hasFeedback={hasFeedback}
    validateStatus={validateStatus}
    extra={fieldDescription}
    help={help}
    className={className}
  >
    <InputStyled
      data-test={`input-${name}`}
      name={name}
      onChange={e => onChange(e.target.value)}
      onBlur={e => onBlur(e.target.value)}
      placeholder={placeholder}
      type={type}
      value={value}
      aria-label={label}
      validateStatus={validateStatus}
    />
  </FormItem>
)
