import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'
import { useEffect } from 'react'

export const useSendCancelModalEvent = (isOakley: boolean, customizable = false) => {
  const getCustomCancelDataId = (isOakley: boolean, isViewOther: boolean) => {
    const customDataCloseId = isOakley
      ? 'BarcodeNoResultOOCustom_Close'
      : 'BarcodeNoResultRBCustom_Close'
    const customViewOthersId = isOakley
      ? 'BarcodeNoResultOOCustom_ViewOtherColors'
      : 'BarcodeNoResultRBCustom_ViewOtherColors'

    return isViewOther ? customViewOthersId : customDataCloseId
  }

  const getStandardCancelDataId = (isViewOthers = false) =>
    isViewOthers ? 'BarcodeNoResultStd_ViewOtherColors' : 'BarcodeNoResultStd_Close'

  const useSendAnalyticsClick = useSendAnalyticsEvent((isViewOthers: boolean) => ({
    id: 'Click',
    data_element_id: customizable
      ? getCustomCancelDataId(isOakley, isViewOthers)
      : getStandardCancelDataId(isViewOthers),
  }))

  return useSendAnalyticsClick
}

export const useSendImpressionEvent = (
  shouldSendEvent: boolean,
  isOakley: boolean,
  customizable = false
) => {
  const customPageSection = isOakley ? 'Barcode:CustomOO:NOUpc' : 'Barcode:CustomRB:NOUpc'
  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    Page_Section2: customizable ? customPageSection : 'Barcode:Standard:NOUpc:ViewColors',
  })

  useEffect(() => {
    if (shouldSendEvent) {
      sendImpressionEvent()
    }
  }, [shouldSendEvent, sendImpressionEvent])
}
