import React from 'react'
import { IconProps } from 'types/icons'
export const FaFaceShapeRoundManIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 48"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M42.6051 22.165C41.9241 21.37 40.7781 21 39.0001 21V17C39.0001 12.645 37.3401 8.68 34.6381 5.669C36.0961 4.219 37.0001 2.214 37.0001 0H19.0001C16.5701 0 14.5441 1.744 14.0961 4.046C9.00506 4.505 5.00006 8.791 5.00006 14V21C3.22206 21 2.07606 21.37 1.39506 22.165C0.488055 23.222 0.710056 24.674 0.926056 26.078L1.01006 26.64C1.31306 28.775 1.77206 32 5.00006 32C5.01706 32 5.03406 31.997 5.05106 31.997C5.57106 40.906 12.9631 48 22.0001 48C31.0371 48 38.4291 40.906 38.9491 31.997C38.9661 31.997 38.9831 32 39.0001 32C42.2281 32 42.6871 28.775 42.9901 26.641L43.0741 26.079C43.2901 24.674 43.5131 23.222 42.6051 22.165ZM22.0001 46C13.7291 46 7.00006 39.271 7.00006 31V17.772C12.2531 17.557 15.4771 15.673 16.5721 12.113C18.0071 13.49 20.7531 14.834 26.0001 14.985C26.3241 14.994 26.6561 15 27.0001 15C31.0471 15 34.1251 15.65 35.7861 16.792C36.3791 17.2 36.7911 17.671 37.0001 18.198V31C37.0001 39.271 30.2711 46 22.0001 46ZM34.6501 2C33.8241 4.327 31.6071 6 29.0001 6V8C30.2091 8 31.3511 7.723 32.3801 7.241C32.6091 7.134 32.8311 7.017 33.0481 6.889C35.0921 9.121 36.4711 11.963 36.8691 15.115C34.2061 13.333 29.8451 13 27.0001 13C26.6461 13 26.3301 12.984 26.0001 12.974C17.3071 12.704 17.0011 9.017 17.0001 9H15.0001C15.0001 11.798 14.1301 15.467 7.00006 15.783V14C7.00006 9.589 10.5891 6 15.0001 6H16.0001V5C16.0001 3.346 17.3461 2 19.0001 2H34.6501ZM2.99006 26.359L2.90206 25.774C2.74806 24.772 2.60306 23.827 2.91206 23.466C3.06206 23.293 3.53206 23 5.00006 23V30C3.84306 30 3.39206 29.184 2.99006 26.359ZM41.0981 25.774L41.0101 26.359C40.6081 29.184 40.1571 30 39.0001 30V23C40.4681 23 40.9381 23.293 41.0881 23.467C41.3961 23.827 41.2511 24.772 41.0981 25.774Z"
        fill={fill}
      />
      <path d="M24.0131 36H19.9861V38H24.0131V36Z" fill={fill} />
      <path d="M22.4341 31H19.9861V33H24.4341V23.057H22.4341V31Z" fill={fill} />
    </svg>
  )
}
