import React from 'react'
import { IconProps } from 'types/icons'

export const NuanceAudioLogo: React.FC<IconProps> = ({
  width = '270',
  height = '69',
  fill = 'white',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 270 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3055_1846)">
        <path
          d="M99.0742 26.5664H101.736L102.031 28.0464H102.178C102.503 27.7216 102.858 27.4236 103.273 27.1591C103.983 26.7171 105.048 26.2717 106.467 26.2717C110.017 26.2717 112.384 28.6089 112.384 32.483V41.3562H109.427V32.483C109.427 30.561 107.921 28.9337 105.878 28.9337C103.662 28.9337 102.034 30.561 102.034 32.483V41.3562H99.0776L99.0742 26.5664Z"
          fill={fill}
        />
        <path
          d="M115.344 26.5664H118.301V35.4396C118.301 37.3616 119.808 38.9889 121.85 38.9889C124.067 38.9889 125.694 37.3616 125.694 35.4396V26.5664H128.651V41.3562H125.989L125.694 39.8763H125.547C125.222 40.201 124.867 40.4957 124.452 40.7636C123.742 41.2056 122.647 41.6509 121.257 41.6509C117.708 41.6509 115.341 39.3137 115.341 35.4396L115.344 26.5664Z"
          fill={fill}
        />
        <path
          d="M141.077 39.7291H140.93C140.635 40.1141 140.28 40.4389 139.865 40.7035C139.155 41.1756 138.03 41.6511 136.493 41.6511C133.238 41.6511 131.169 39.7592 131.169 37.0671C131.169 34.1105 133.091 32.1852 137.233 32.1852H140.93V31.8905C140.93 29.9685 139.748 28.7832 137.826 28.7832C135.756 28.7832 134.869 29.9652 134.722 30.8525H131.765C131.912 28.368 134.132 26.2686 137.829 26.2686C141.526 26.2686 143.893 28.4584 143.893 31.8872V41.3531H141.378L141.084 39.7257L141.077 39.7291ZM140.93 34.5525H137.38C135.133 34.5525 134.126 35.5871 134.126 36.9198C134.126 38.2524 135.013 39.1398 136.788 39.1398C139.48 39.1398 140.93 37.6598 140.93 35.1451V34.5525Z"
          fill={fill}
        />
        <path
          d="M146.843 26.5664H149.505L149.799 28.0464H149.947C150.272 27.7216 150.626 27.4236 151.042 27.1591C151.752 26.7171 152.816 26.2717 154.236 26.2717C157.785 26.2717 160.153 28.6089 160.153 32.483V41.3562H157.196V32.483C157.196 30.561 155.689 28.9337 153.647 28.9337C151.43 28.9337 149.803 30.561 149.803 32.483V41.3562H146.846L146.843 26.5664Z"
          fill={fill}
        />
        <path
          d="M176.569 36.1798C176.097 38.3998 174.202 41.6511 169.765 41.6511C165.329 41.6511 162.372 38.5438 162.372 33.9598C162.372 29.3759 165.476 26.2686 169.765 26.2686C174.055 26.2686 176.094 29.5533 176.569 31.5925H173.613C173.14 30.2598 172.015 28.9305 169.769 28.9305C167.254 28.9305 165.332 30.8826 165.332 33.9598C165.332 37.037 167.254 38.9891 169.769 38.9891C172.136 38.9891 173.288 37.3618 173.613 36.1798H176.569Z"
          fill={fill}
        />
        <path
          d="M192.096 37.0671C191.386 38.9891 189.286 41.6511 185.439 41.6511C181.15 41.6511 178.046 38.5438 178.046 33.9598C178.046 29.3759 181.15 26.2686 185.439 26.2686C189.43 26.2686 192.538 29.3759 192.538 33.5145C192.538 33.9565 192.477 34.3147 192.447 34.5793L192.387 34.9945H180.999C181.324 37.5091 183.068 39.1364 185.436 39.1364C187.21 39.1364 188.513 38.1018 188.985 37.0671H192.092H192.096ZM189.728 32.7778C189.404 30.4105 187.806 28.7832 185.439 28.7832C182.925 28.7832 181.445 30.4105 181.003 32.7778H189.728Z"
          fill={fill}
        />
        <path
          d="M210.435 39.7291H210.288C209.993 40.1141 209.638 40.4389 209.223 40.7035C208.513 41.1756 207.388 41.6511 205.851 41.6511C202.597 41.6511 200.527 39.7592 200.527 37.0671C200.527 34.1105 202.449 32.1852 206.591 32.1852H210.288V31.8905C210.288 29.9685 209.106 28.7832 207.184 28.7832C205.115 28.7832 204.227 29.9652 204.08 30.8525H201.123C201.271 28.368 203.491 26.2686 207.187 26.2686C210.884 26.2686 213.251 28.4584 213.251 31.8872V41.3531H210.737L210.442 39.7257L210.435 39.7291ZM210.288 34.5525H206.739C204.492 34.5525 203.484 35.5871 203.484 36.9198C203.484 38.2524 204.371 39.1398 206.146 39.1398C208.838 39.1398 210.288 37.6598 210.288 35.1451V34.5525Z"
          fill={fill}
        />
        <path
          d="M216.201 26.5664H219.157V35.4396C219.157 37.3616 220.664 38.9889 222.707 38.9889C224.923 38.9889 226.55 37.3616 226.55 35.4396V26.5664H229.507V41.3562H226.845L226.55 39.8763H226.403C226.078 40.201 225.723 40.4957 225.308 40.7636C224.598 41.2056 223.503 41.6509 222.114 41.6509C218.565 41.6509 216.197 39.3137 216.197 35.4396L216.201 26.5664Z"
          fill={fill}
        />
        <path
          d="M243.413 39.5817H243.266C242.824 40.1744 241.521 41.651 238.682 41.651C234.808 41.651 231.878 38.6944 231.878 33.9598C231.878 29.2251 234.808 26.2685 238.682 26.2685C241.551 26.2685 242.764 27.7753 243.266 28.3378H243.413V20.6499H246.37V41.353H243.708L243.413 39.5784V39.5817ZM243.413 33.9631C243.413 30.8859 241.491 28.9338 239.124 28.9338C236.757 28.9338 234.835 30.8859 234.835 33.9631C234.835 37.0403 236.757 38.9924 239.124 38.9924C241.491 38.9924 243.413 37.0403 243.413 33.9631Z"
          fill={fill}
        />
        <path
          d="M249.474 20.6499H252.728V23.7572H249.474V20.6499ZM249.621 26.5665H252.578V41.3563H249.621V26.5665Z"
          fill={fill}
        />
        <path
          d="M254.948 33.9598C254.948 29.3759 258.052 26.2686 262.341 26.2686C266.631 26.2686 269.735 29.3759 269.735 33.9598C269.735 38.5438 266.627 41.6511 262.341 41.6511C258.056 41.6511 254.948 38.5438 254.948 33.9598ZM266.778 33.9598C266.778 30.8826 264.856 28.9305 262.341 28.9305C259.827 28.9305 257.905 30.8826 257.905 33.9598C257.905 37.037 259.827 38.9891 262.341 38.9891C264.856 38.9891 266.778 37.037 266.778 33.9598Z"
          fill={fill}
        />
        <path
          d="M7.48881 38.5805C3.40043 38.5805 0.0888672 36.5112 0.0888672 33.9564C0.0888672 31.4016 3.40377 29.3323 7.48881 29.3323C11.5738 29.3323 14.8888 31.4016 14.8888 33.9564C14.8888 36.5112 11.5738 38.5805 7.48881 38.5805Z"
          fill={fill}
        />
        <path
          d="M38.9438 48.7595C46.0967 48.7595 51.8953 42.1319 51.8953 33.9563C51.8953 25.7807 46.0967 19.1531 38.9438 19.1531C31.7908 19.1531 25.9922 25.7807 25.9922 33.9563C25.9922 42.1319 31.7908 48.7595 38.9438 48.7595Z"
          fill={fill}
        />
        <path
          d="M59.2949 33.9563C59.2949 27.8254 62.4022 22.853 66.2328 22.853C70.0633 22.853 73.1707 27.822 73.1707 33.9563C73.1707 40.0872 70.0633 45.0562 66.2328 45.0562C62.4022 45.0562 59.2949 40.0872 59.2949 33.9563Z"
          fill={fill}
        />
        <path
          d="M78.7227 33.9563C78.7227 31.502 79.9649 29.5164 81.4985 29.5164C83.032 29.5164 84.2743 31.5053 84.2743 33.9563C84.2743 36.4073 83.032 38.3963 81.4985 38.3963C79.9649 38.3963 78.7227 36.4073 78.7227 33.9563Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3055_1846">
          <rect
            width="269.646"
            height="29.6031"
            fill={fill}
            transform="translate(0.0888672 19.1531)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
