import { CloseIcon } from 'components/core/Icons'
import { ErrorMessage } from 'components/ErrorMessage'
import Header from 'components/Header'
import Loading from 'components/Loading'
import { useBarcodeScanner } from 'hooks/useBarcodeScanner'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Store } from 'types/graphqlTypes'
import { BarcodeResult, CodeScanner } from './CodeScanner'
import {
  BottomCornerBorder,
  BottomTitle,
  CloseButton,
  ScanContent,
  ScannerArea,
  ScannerBar,
  ScannerReaderElement,
  StyledDrawer,
  TopTitle,
} from './styles'
interface CodeScannerModalProps {
  store: Store
}

export const CodeScannerModal: React.FC<CodeScannerModalProps> = ({ store }) => {
  const { t } = useTranslation()
  const visible = useSelector(({ ui }) => ui.barcodeScannerDrawerIsVisible)
  const history = useHistory()
  const { toggleBarcodeScanner } = useBarcodeScanner()

  const [barcodeError, setBarcodeError] = useState('')
  const [scannerLoaded, setScannerLoaded] = useState(false)
  const [isScanned, setIsScanned] = useState(false)

  const clearState = () => {
    setBarcodeError('')
    setIsScanned(false)
    setScannerLoaded(false)
  }

  const onModalClose = () => {
    clearState()
    toggleBarcodeScanner()
  }

  const onBarcodeSearch = useCallback(
    (result: BarcodeResult) => {
      setIsScanned(true)
      history.push(`${store.url}/scan-barcode?q=${result.value}`)
    },
    [history, store.url]
  )

  const onScannerLoad = useCallback(() => {
    setScannerLoaded(true)
  }, [])

  const content = useMemo(() => {
    if (barcodeError) return <ErrorMessage>{barcodeError}</ErrorMessage>
    return (
      <>
        <TopTitle>{t('Barcode.topLabel')}</TopTitle>
        <ScannerArea>
          {scannerLoaded && (
            <ScannerReaderElement>
              <BottomCornerBorder />
              <ScannerBar />
            </ScannerReaderElement>
          )}
          <CodeScanner
            onResult={onBarcodeSearch}
            onError={setBarcodeError}
            isScanned={isScanned}
            onLoad={onScannerLoad}
          />
        </ScannerArea>
        <BottomTitle>{t('Barcode.bottomLabel')}</BottomTitle>
      </>
    )
  }, [barcodeError, isScanned, onBarcodeSearch, onScannerLoad, scannerLoaded, t])

  return (
    <StyledDrawer
      position="left"
      variant="fullscreen"
      visible={visible}
      onOverlayClick={onModalClose}
    >
      <Header />
      <CloseButton onClick={onModalClose} data-analytics_available_call="0">
        <CloseIcon />
      </CloseButton>
      <ScanContent>{isScanned ? <Loading /> : content}</ScanContent>
    </StyledDrawer>
  )
}
