import { GreenShipmentColors, GreenShipmentFonts } from 'styled-components'

const colors: GreenShipmentColors = {
  primary: '#000000',
  primaryBlue: '#8EC5E2',
  secondaryBlue: '#DDEEF6',
  primaryLight: '#ffffff',
}

const font = 'AvenirLight, Helvetica, Arial'

const fonts: GreenShipmentFonts = {
  font: font,
  heading1: {
    name: font,
    weight: 350,
    towerSize: 60,
    tabletSize: 34,
    tabletMiniSize: 34,
    towerLineHeight: 62,
    tabletLineHeight: 62,
    tabletMiniLineHeight: 46,
  },
  heading2: {
    name: font,
    weight: 400,
    towerSize: 32,
    tabletSize: 20,
    tabletMiniSize: 20,
    towerLineHeight: 48,
    tabletLineHeight: 26,
    tabletMiniLineHeight: 26,
  },
  heading3: {
    name: font,
    weight: 350,
    towerSize: 30,
    tabletSize: 20,
    tabletMiniSize: 20,
    towerLineHeight: 36,
    tabletLineHeight: 26,
    tabletMiniLineHeight: 26,
  },
  body: {
    name: font,
    weight: 400,
    towerSize: 18,
    tabletSize: 15,
    tabletMiniSize: 15,
    towerLineHeight: 26,
    tabletLineHeight: 22,
    tabletMiniLineHeight: 22,
  },
}

export default {
  colors,
  fonts,
}
