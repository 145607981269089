export const device = {
  ipadMiniPortrait: 'screen and (max-width: 810px)',
  ipadMiniPortraitReal: 'only screen and (min-device-width: 768px) and (max-device-width: 1024px)',
  ipadMiniPortraitRealframeAdvisor:
    'only screen and (min-device-width: 768px) and (max-device-width: 1023px)',
  ipadPortrait: 'screen and (max-width: 1024px)',
  ipadLandscape: 'screen and (max-width: 1366px) and (orientation:landscape)',
  tabletPortrait: 'screen and (min-width: 600px) and (max-width: 850px)',
  tablet: 'screen and (min-width: 600px) and (max-width: 1023px)',
  tabletLandscape:
    'screen and (min-width: 600px) and (max-width: 1024px) and (orientation:landscape)',
  ipadProPortrait:
    'only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5)',
  ipadProLandscape:
    'only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)',
}
