export const SET_BARCODE_FLOW = 'ui/SET_BARCODE_FLOW'

export interface SetBarcodeFlowAction {
  type: typeof SET_BARCODE_FLOW
  payload: {
    show: boolean
  }
}

export const setBarcodeFlow = (show: boolean): SetBarcodeFlowAction => ({
  type: SET_BARCODE_FLOW,
  payload: {
    show,
  },
})

export type FlowsActionTypes = SetBarcodeFlowAction
