import { DisclaimerWrapper } from 'components/MasterUI/Disclaimer'
import { LinkTypography } from 'components/Typography'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const PrivacyPolicyTooltipContainer = styled.div<{ isFixed?: boolean }>`
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'absolute')};
  top: ${pxToRem(25)};
  left: ${pxToRem(25)};
  z-index: 4;
  display: flex;
  align-self: start;
  align-items: center;
  width: calc(100% - ${pxToRem(72)} - ${pxToRem(50)});
  min-height: ${pxToRem(100)};
  pointer-events: none;
  svg,
  div {
    pointer-events: auto;
  }
  ${tablet(`
    top: ${clampValues(0, 18)};
    left: ${clampValues(10, 16)};
  `)}
  ${landscape(
    Device.tablet,
    `
    top: ${clampValuesLandscape(8, 15)};
    left: ${clampValuesLandscape(8, 15)};
  `
  )}
`

export const PrivacyPolicyTooltipWrapper = styled('div')<{ visible: boolean }>`
  width: calc(100% - ${pxToRem(72)} - ${pxToRem(50)});
  margin-left: 14px;
  display: ${({ visible }) => (visible ? 'initial' : 'none')};
  border-radius: 0.45rem;

  ${landscape(`
    width: ${pxToRem(1050)};
  `)}
  ${landscape(
    Device.tablet,
    `
      width: ${clampValuesLandscape(850, 1050)};
    `
  )}

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent ${({ theme }) => theme.colors.white};
    border-width: ${pxToRem(10)} ${pxToRem(10)} ${pxToRem(10)} 0;
    top: 50%;
    left: ${pxToRem(36)};
    margin-top: ${pxToRem(-10)};
    ${tablet(`
      left: ${clampValues(28, 36)};
    `)}
  }

  ${DisclaimerWrapper} {
    padding-bottom: ${pxToRem(20)};
    width: fit-content;
    max-width: ${pxToRem(1050)};
    position: static;
    border-radius: 0.45rem;
    text-align: left;
    ${tablet(Device.ipadMini, `padding: ${pxToRem(10)};`)}
    ${landscape(`
      padding-left: ${pxToRem(50)};
      padding-right: ${pxToRem(50)}
    `)}

    ${LinkTypography} {
      line-height: 0;
    }
  }
`
