import { Modal } from 'components/core/Modal'
import { PrivacyPolicyDataQueryResult, usePrivacyPolicyPageData } from 'data'
import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

const ModalHeader = styled.div`
  margin-bottom: 3em;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primaryText};
    font-family: ${({ theme }) => theme.fonts.primary};
  }
`

const PrivacyPolicyContent = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  & > * {
    margin-bottom: 0;
    display: inline-block;
  }
`

interface PrivacyPolicyModalProps {
  className?: string
  visible: boolean
  onCancel: (event: React.MouseEvent<Element, MouseEvent>) => void
}

export const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({
  className,
  visible,
  onCancel,
}) => {
  const [loadData, { data }] = usePrivacyPolicyPageData<PrivacyPolicyDataQueryResult>('PageGeneric')
  const content = data?.page?.content[0]

  useEffect(() => {
    if (visible) {
      loadData()
    }
  }, [loadData, visible])

  return (
    <Modal
      className="privacy-policy"
      width="75%"
      visible={visible}
      onCancel={onCancel}
      renderHeader={() => (
        <ModalHeader>
          <h1 className="link-to-modal__modal-header">
            {content && <Trans i18nKey={content.title} />}
          </h1>
        </ModalHeader>
      )}
    >
      <PrivacyPolicyContent
        className={className}
        dangerouslySetInnerHTML={{ __html: content?.html || '' }}
      />
    </Modal>
  )
}
