import { OrderActionTypes, SET_ORDER } from 'actions/order'

interface OrderState {
  orderNumber: string | null
  token: string | null
}

const initialState: OrderState = {
  orderNumber: null,
  token: null,
}

export default (state = initialState, action: OrderActionTypes) => {
  const { payload } = action

  switch (action.type) {
    case SET_ORDER: {
      return {
        orderNumber: payload.orderNumber,
        token: payload.token,
      }
    }

    default:
      return state
  }
}
