import React from 'react'
import { IconProps } from 'types/icons'

export const FaTipsRelaxedFaceIcon = ({
  width = '1rem',
  height = '1rem',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 99 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="49.152" cy="49.7604" r="48.128" stroke={stroke} strokeWidth="2.048" />
      <g clipPath="url(#clip0_48_52728)">
        <path
          d="M70.9764 41.5556C70.7798 33.3718 63.6876 27.7961 57.1811 26.0789C51.0801 24.4671 45.6324 25.9591 42.6853 29.967C39.4423 28.2835 35.9792 28.3583 33.0526 30.2087C28.5552 33.0523 26.1713 39.397 26.9741 46.309C26.9905 46.6244 27.4288 54.0545 31.4398 57.5198C31.6292 57.6826 31.833 57.8085 32.0316 57.9488C33.4867 66.0865 40.6005 72.2889 49.1519 72.2889C58.073 72.2889 65.4386 65.5408 66.436 56.8818C68.3294 55.2373 71.2088 51.1484 70.9764 41.5556ZM29.0139 46.136C28.2961 39.9367 30.3113 34.364 34.1472 31.9392C36.6826 30.3377 39.6348 30.4288 42.4652 32.1973L43.8855 31.8563C46.6236 27.2923 52.2782 26.9022 56.6578 28.0593C62.4475 29.5881 68.7574 34.4798 68.9294 41.6058C69.081 47.9167 67.8225 51.5283 66.5599 53.5538V44.6409L65.6956 43.6292C47.4899 40.7548 46.0789 34.2995 46.0696 34.2555L44.0319 34.4009C44.0319 38.0259 39.1945 43.6169 32.7679 43.6169L31.7439 44.6409V54.8307C29.3467 51.599 29.0221 46.2527 29.0139 46.136ZM49.1519 70.2409C40.6824 70.2409 33.7919 63.3504 33.7919 54.8809V45.627C39.6758 45.1959 43.9479 41.0958 45.4706 37.2773C47.5258 39.8117 52.5065 43.4828 64.5119 45.5113V54.8809C64.5119 63.3504 57.6214 70.2409 49.1519 70.2409Z"
          fill="black"
        />
        <path d="M51.2131 62.0488H47.0895V64.0968H51.2131V62.0488Z" fill="black" />
        <path d="M49.5962 56.9286H47.0895V58.9766H51.6442V48.7949H49.5962V56.9286Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_48_52728">
          <rect width="49.152" height="49.152" fill="white" transform="translate(24.576 25.1846)" />
        </clipPath>
      </defs>
    </svg>
  )
}
