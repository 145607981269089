import { Text } from 'components/Typography'
import { FormInput } from 'components/core/FormInput'
import { fieldsValidator, translateErrorMessage } from 'libs/checkoutV3'
import { omit, pick } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultTheme, ThemeContext } from 'styled-components'
import { BiometricData } from 'types/frameAdvisor'
import { useCheckoutFields } from '../../../hooks/checkoutV3/useCheckoutFields'
import { BiometricFormStyled } from './styles'

export type FormInputType = 'text' | 'email'

export type InputFieldsMap<T> = Partial<Record<keyof BiometricFormData, T>>

export type FormInputs = {
  name: keyof BiometricFormData
  type: FormInputType
  required: boolean
  autocomplete?: boolean
}

const inputs: InputFieldsMap<FormInputs> = {
  firstName: { name: 'firstName', type: 'text', required: true },
  lastName: { name: 'lastName', type: 'text', required: true },
  email: { name: 'email', type: 'email', required: true },
}

export type BiometricFormData = Omit<BiometricData, 'isIllinoisResident'>

export type FormErrors = {
  [key: string]: string
}
export type FormTouched = {
  [key: string]: boolean
}

export interface BiometricFormProps {
  data: Partial<BiometricFormData>
  onChange: (data: Partial<BiometricFormData>) => void
  onComplete: (isValid: boolean) => void
}

const validateField = (key: keyof BiometricFormData, value: string): string | undefined => {
  const validators = fieldsValidator[key]
  let error: string
  validators.forEach(check => {
    const isValid = check.validate(value, undefined, undefined)
    if (!isValid) {
      error = check.message
    }
  })

  return error
}

const validateForm = (data: Partial<BiometricFormData>) => {
  return !Object.values(inputs).some(item => !!validateField(item.name, data?.[item.name]))
}

export const BiometricForm = ({ data, onChange, onComplete }: BiometricFormProps) => {
  const { t } = useTranslation()
  const theme: DefaultTheme = useContext(ThemeContext)
  const inputFields = Object.values(pick(inputs, useCheckoutFields()))
  const [errors, setErrors] = useState<FormErrors | undefined>()

  const setData = useCallback(
    (key: keyof BiometricFormData, value: string) => {
      const cloneData = { ...data }
      cloneData[key] = value
      let cloneErrors = { ...errors }
      const error = validateField(key, value)
      if (error) {
        cloneErrors[key] = error
      } else {
        cloneErrors = omit(errors, [key])
      }
      setErrors(cloneErrors)
      onChange(cloneData)
    },
    [data, errors, onChange]
  )

  useEffect(() => {
    onComplete(validateForm(data))
  }, [data, onComplete])

  return (
    <BiometricFormStyled role="group" aria-labelledby="biometricDataConsent">
      <Text color={theme.colors.monza}>{t('FrameAdvisor.biometricDataConsent.formTitle')}</Text>
      {inputFields.map((input, key) => (
        <FormInput
          key={key}
          name={input.name}
          value={data && data[input.name]}
          onChange={v => setData(input.name, v)}
          label={t(`FrameAdvisor.biometricDataConsent.${input.name}`)}
          type={input.type}
          required={input.required}
          hasFeedback={errors && !!errors[input.name]}
          validateStatus={errors && !!errors[input.name] ? 'error' : undefined}
          help={translateErrorMessage(t, input.name, errors)}
        />
      ))}
    </BiometricFormStyled>
  )
}
