import { useMediaQuery, useZoomLevel } from 'hooks/useNavigationUtils'
import React, { useEffect } from 'react'

interface NavigationContextData {
  landscapeNavigation: boolean
  zoomLevel: number
  isAlternativeNavigation: boolean
}

export const NavigationContext = React.createContext<NavigationContextData>({
  landscapeNavigation: false,
  zoomLevel: 100,
  isAlternativeNavigation: false,
})

interface NavigationProviderProps {
  isAlternativeNavigation?: boolean
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({
  isAlternativeNavigation = false,
  children,
}) => {
  const zoomLevel = useZoomLevel()
  const isLandscape = useMediaQuery('only screen and (orientation:landscape)')
  const landscapeNavigation = isAlternativeNavigation || isLandscape || false

  // Toggle class with landscape navigation
  useEffect(() => {
    document.body.classList.toggle('landscape-navigation', landscapeNavigation)
  }, [landscapeNavigation])

  return (
    <NavigationContext.Provider value={{ landscapeNavigation, zoomLevel, isAlternativeNavigation }}>
      {children}
    </NavigationContext.Provider>
  )
}
