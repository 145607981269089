import config from 'config'
import qs from 'qs'
import { cleanLocalStorageData, setAuthToken, setMigrationStatus } from './auth'
export interface GetCustomProductRouteOptions {
  basePath: string
  brand: string
  model: string
  recipeId?: string
  UPC?: string
}

export const getCustomProductRoute = ({
  basePath,
  brand,
  model,
  recipeId,
  UPC,
}: GetCustomProductRouteOptions): string => {
  const recipeSegment = recipeId ? `/${recipeId}` : ''
  const upcParams = UPC
    ? qs.stringify({ UPC, skipOnMissingUpc: true }, { addQueryPrefix: true })
    : ''

  return `${basePath}/custom/${brand}/pdp/${model}CP${recipeSegment}${upcParams}`
}

export const getPLPSimilarProducts = (storeUrl: string) => {
  // TODO: Add parameters to show all
  return `${storeUrl}/products`
}

export const getCustomProductViewAllRoute = (storeUrl: string, brand: string) => {
  return `${storeUrl}/custom/${brand}/viewall`
}

export const redirectToLoginPage = () => {
  cleanLocalStorageData()
  const redirectURL = encodeURI(config.loginRedirectURL)
  const ROCPFlow = config.loginWithROCP
  window.location.href = ROCPFlow ? `${config.loginPageURL}&redirect_uri=${redirectURL}` : '/login'
}

export const authenticate = (token: string, isMigrated: string, readOnly?: false): void => {
  // TODO: could it be better putting the cleanLocalStorageData() here?
  setAuthToken(token)
  setMigrationStatus(isMigrated)
  localStorage.setItem(config.urlKey, `store/device/${config.fallbackLanguage}`)
  if (readOnly) {
    localStorage.setItem(config.appReadOnlyKey, readOnly)
  }
  window.location.href = window.location.origin
}

export const getTokenFromUrl = location => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const token = params['id_token']
  return { token }
}

export const redirectToFAAuthErrorPage = () => {
  return window.location.replace(config.faErrorPageUrl)
}
