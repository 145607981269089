import { ListTypography } from 'components/Typography'
import { tablet } from 'libs/media'
import { pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const SelectStyled = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  outline: 0;
  width: 100%;
`

export const SelectInnerStyled = styled.div`
  cursor: pointer;
  height: ${pxToRem(40)};
  border: ${({ theme }) => `1px solid ${theme.colors.frenchGrey}`};
  min-height: ${pxToRem(52)};
`

export const SelectWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  padding: ${pxToRem(1)} 0 ${pxToRem(1)} ${pxToRem(18)};
  justify-content: space-between;
  align-items: center;
  margin-right: ${pxToRem(12)};
  user-select: none;
  line-height: ${pxToRem(48)};
  ${({ theme, placeholder }) =>
    placeholder &&
    `
      p {
        color: ${theme.colors.placeholder}
      }
    `}
`

export const SelectDropdown = styled.ul<{ visible?: boolean }>`
  width: 100%;
  border-top: ${({ theme }) => `1px solid ${theme.colors.frenchGrey}`};
  position: absolute;
  z-index: 10;
  visibility: ${({ visible }) => !visible && 'hidden'};
  height: ${({ visible }) => (visible ? 'auto' : 0)};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  max-height: ${pxToRem(250)};
  overflow-y: auto;
  transition: opacity 0.3s ease;
  margin-bottom: 0;
  padding: ${pxToRem(1)} 0 ${pxToRem(1)} ${pxToRem(18)};
  padding-left: 0;
  list-style: none;
  outline: none;
  background-color: ${({ theme }) => theme.colors.white};
  li {
    line-height: ${pxToRem(48)};
  }
  ${tablet(
    `
    max-height: ${pxToRem(120)};
    `
  )}
`

export const OptionSlyled = styled(ListTypography)`
  display: block;
  padding: ${pxToRem(5)} ${pxToRem(12)};
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: normal;
`
