import {
  CaretDownIcon,
  CloseIcon,
  FrameMeasureIcon,
  FrameWidthIcon,
  GeolocatorIcon,
  RulerIcon,
} from 'components/core/Icons'
import { SwitcherWithLabel } from 'components/core/Switcher'
import { pxToRem } from 'libs/styled'
import { NavigationContext } from 'providers/navigationProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CouvetteDetailCard,
  CouvetteGuideLine,
  CouvetteModal,
  CouvetteModalText,
  CouvetteModalTitle,
  // CouvetteModalText,
  // CouvetteModalTitle,
  CouvetteScaleColumn,
  CouvetteScaleContainer,
  CouvetteScaleDetailsContent,
  CouvetteScaleDetailsReferenceContainer,
  CouvetteScaleDetailsTitle,
  CouvetteScaleDetailsTitleContainer,
  ReferenceGlassContainer,
  SizeCta,
  SizeList,
  SizeListDescriptionText,
} from './styles'
import RotateDevice from 'components/RotateDeviceMessage'

interface DigitalCouvetteProps {
  isVisible: boolean
  onCancel: () => void
}

export const DIGITAL_COUVETTE_SIZES = {
  XXS: '109-120',
  XS: '121-124',
  S: '125-128',
  M: '129-132',
  L: '133-136',
  XL: '137-140',
  XXL: '141-156',
}

export const getSizeMeasure = size => DIGITAL_COUVETTE_SIZES[size]

export const getWidthBasedOnSize = size => {
  if (!size) return 0
  const sizeRange = DIGITAL_COUVETTE_SIZES[size.toUpperCase()]
  const rangeMax = sizeRange.split('-')[1]
  return size ? rangeMax : 0
}

export const CouvetteScale = ({ t }) => {
  const [selectedSize, setSelectedSize] = useState<string | null>()
  const [digitalCouvetteDescriptionToggle, setDigitalCouvetteDescriptionToggle] = useState(false)
  const [referenceImage, setReferenceImage] = useState(false)

  return (
    <div>
      <SizeListDescriptionText>{t('FrameAdvisor.couvette.selectSize')}</SizeListDescriptionText>
      <SizeList>
        {Object.keys(DIGITAL_COUVETTE_SIZES).map((size, index) => (
          <SizeCta
            selected={selectedSize === size}
            key={index}
            onClick={() => setSelectedSize(size)}
            data-measure={getSizeMeasure(size)}
          >
            {size}
          </SizeCta>
        ))}
      </SizeList>
      <div>
        <CouvetteScaleDetailsTitleContainer>
          <CouvetteScaleDetailsTitle
            onClick={() => setDigitalCouvetteDescriptionToggle(!digitalCouvetteDescriptionToggle)}
            isOpen={digitalCouvetteDescriptionToggle}
          >
            <RulerIcon height={pxToRem(20)} width={pxToRem(24)} />
            {t('FrameAdvisor.couvette.howToMeasure')}
            <CaretDownIcon
              height={pxToRem(36)}
              width={pxToRem(36)}
              className={digitalCouvetteDescriptionToggle ? 'open' : ''}
            />
          </CouvetteScaleDetailsTitle>
          <CouvetteScaleDetailsReferenceContainer>
            <SwitcherWithLabel
              showOnOff={false}
              onChange={() => setReferenceImage(!referenceImage)}
              checked={referenceImage}
              label={t('FrameAdvisor.couvette.referenceGlasses')}
            />
          </CouvetteScaleDetailsReferenceContainer>
        </CouvetteScaleDetailsTitleContainer>
        {digitalCouvetteDescriptionToggle && (
          <CouvetteScaleDetailsContent>
            <CouvetteDetailCard>
              <GeolocatorIcon height={pxToRem(24)} width={pxToRem(24)} />
              {t('FrameAdvisor.couvette.howToMeasureStep1')}
            </CouvetteDetailCard>
            <CouvetteDetailCard>
              <FrameWidthIcon height={pxToRem(24)} width={pxToRem(24)} />
              {t('FrameAdvisor.couvette.howToMeasureStep2')}
            </CouvetteDetailCard>
            <CouvetteDetailCard>
              <FrameMeasureIcon height={pxToRem(24)} width={pxToRem(24)} />
              {t('FrameAdvisor.couvette.howToMeasureStep3')}
            </CouvetteDetailCard>
          </CouvetteScaleDetailsContent>
        )}
      </div>
      <CouvetteScaleContainer>
        {referenceImage}
        <CouvetteScaleColumn className="columns" />
        {Object.keys(DIGITAL_COUVETTE_SIZES).map((size, index) => (
          <CouvetteScaleColumn className="columns" selected={selectedSize === size} key={index}>
            <span>{size}</span>
          </CouvetteScaleColumn>
        ))}
        <CouvetteGuideLine size={selectedSize} data-measure={`${getSizeMeasure(selectedSize)} mm`}>
          {selectedSize && <GeolocatorIcon height={pxToRem(24)} width={pxToRem(24)} />}
        </CouvetteGuideLine>
        {referenceImage && selectedSize && <ReferenceGlassContainer size={selectedSize} />}
      </CouvetteScaleContainer>
    </div>
  )
}

export const DigitalCouvette: React.FC<DigitalCouvetteProps> = ({ isVisible, onCancel }) => {
  const { t } = useTranslation()
  const renderCloseIcon = () => <CloseIcon height={pxToRem(32)} width={pxToRem(32)} />
  const { landscapeNavigation } = useContext(NavigationContext)
  return (
    <CouvetteModal
      visible={isVisible}
      onCancel={() => onCancel()}
      renderCloseIcon={renderCloseIcon}
      className="couvette-modal"
    >
      {!landscapeNavigation ? (
        <RotateDevice />
      ) : (
        <>
          <CouvetteModalTitle>{t('FrameAdvisor.couvette.title')}</CouvetteModalTitle>
          <CouvetteModalText>{t('FrameAdvisor.couvette.description')}</CouvetteModalText>
          <CouvetteScale t={t} />
        </>
      )}
    </CouvetteModal>
  )
}
