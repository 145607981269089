import {
  REFRESH_TOKEN,
  RESET_STORE,
  SAVE_ANALYTICS_GOOGLE_SENDED,
  SAVE_ANALYTICS_TEALIUM_SENDED,
  SessionActionTypes,
  StoreActionTypes,
  UPDATE_BIPA_STATE_SHOULD_RESET,
} from 'actions/store'
import { AppReducerState } from 'store'
import { v4 as uuid } from 'uuid'

export default (state: AppReducerState, action: StoreActionTypes) => {
  switch (action.type) {
    case RESET_STORE:
      return
    default:
      return state
  }
}

export interface SessionState {
  token: string
  hasSentGoogleAnalyticsNewSession: boolean
  hasSentTealiumNewSession: boolean
  shouldResetVMBipaState: boolean
}

export const sessionReducer = (
  state: SessionState = {
    token: uuid(),
    hasSentGoogleAnalyticsNewSession: false,
    hasSentTealiumNewSession: false,
    shouldResetVMBipaState: true,
  },
  action: SessionActionTypes
): SessionState => {
  switch (action.type) {
    case REFRESH_TOKEN:
      return {
        token: uuid(),
        hasSentGoogleAnalyticsNewSession: false,
        hasSentTealiumNewSession: false,
        shouldResetVMBipaState: true,
      }

    case SAVE_ANALYTICS_GOOGLE_SENDED:
      return {
        ...state,
        hasSentGoogleAnalyticsNewSession: true,
      }

    case SAVE_ANALYTICS_TEALIUM_SENDED:
      return {
        ...state,
        hasSentTealiumNewSession: true,
      }
    case UPDATE_BIPA_STATE_SHOULD_RESET:
      return {
        ...state,
        shouldResetVMBipaState: action.payload.shouldResetState,
      }

    default:
      return state
  }
}
