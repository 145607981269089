import { Maybe, NuanceTabContent, Scalars } from './graphqlTypes'

export const customTabDetailsName = 'Product details'
export const customNuanceTabNames = [customTabDetailsName] as const
export type CustomNuanceTabNames = typeof customNuanceTabNames[number]

export interface NuancePage {
  tabs: NuanceTab[]
}

export interface NuanceTab extends NuanceTabContent {
  id: string
  name?: Maybe<Scalars['String']>
  label: string
  hasShortMedia?: boolean
}

export interface CustomNuanceTab extends NuanceTab {
  name: CustomNuanceTabNames
}
