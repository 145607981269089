import React from 'react'
import { MultiSlidesWrapper, SingleSlideWrapper } from './styles'

export interface SliderProps {
  slidesNumber: number
  gutter?: number
  isCentered?: boolean
  isSingleCentered?: boolean
  className?: string
}

export const Slider: React.FC<SliderProps> = ({
  slidesNumber,
  gutter,
  children,
  isCentered,
  isSingleCentered,
  className,
}) => {
  return slidesNumber > 1 ? (
    <MultiSlidesWrapper gutter={gutter} isCentered={isCentered} className={className}>
      {children}
    </MultiSlidesWrapper>
  ) : (
    <SingleSlideWrapper className={className} isCentered={isSingleCentered}>
      {children}
    </SingleSlideWrapper>
  )
}
