import { useContext } from 'react'
import { AnalyticsContext } from 'containers/AnalyticsProvider'
import { EVENTS_ID } from 'libs/analytics'
import { CalibrationMethods, Size } from './types'

export const useDigitalCouvetteAnalytics = () => {
  const { sendAnalyticsEvent } = useContext(AnalyticsContext)

  const onLandingPageView = () =>
    sendAnalyticsEvent({
      id: EVENTS_ID.vpw,
      Page_Section1: 'DigitalCouvette',
      Page_Section2: 'Onboarding',
      Page_Type: 'Static',
    })

  const onStartFrameCalibrationEvent = () =>
    sendAnalyticsEvent({
      id: EVENTS_ID.click,
      Tracking_Type: 'hiddenaction',
      data_element_id: 'DigitalCouvette_Onboarding_StartCalibration',
      data_description: 'FramesCalibration',
    })

  const onStartRulerCalibrationEvent = () =>
    sendAnalyticsEvent({
      id: EVENTS_ID.click,
      Tracking_Type: 'hiddenaction',
      data_element_id: 'DigitalCouvette_Onboarding_StartCalibration',
      data_description: 'RulerCalibration',
    })

  const onCalibrationPageView = (calibrationMethod: keyof typeof CalibrationMethods) => {
    const section2 = {
      [CalibrationMethods.frame]: 'FrameCalibration',
      [CalibrationMethods.ruler]: 'RulerCalibration',
    }
    sendAnalyticsEvent({
      id: EVENTS_ID.vpw,
      Page_Section1: 'DigitalCouvette',
      Page_Section2: section2[calibrationMethod],
      Page_Type: 'Static',
    })
  }

  const onConfirmFrameCalibrationEvent = (modelName: string) =>
    sendAnalyticsEvent({
      id: EVENTS_ID.click,
      Tracking_Type: 'hiddenaction',
      data_element_id: 'DigitalCouvette_Frames_Confirm',
      data_description: modelName,
    })

  const onConfirmRulerCalibrationEvent = (measureUnit: 'Inch' | 'Cm' = 'Cm') =>
    sendAnalyticsEvent({
      id: EVENTS_ID.click,
      Tracking_Type: 'hiddenaction',
      data_element_id: 'DigitalCouvette_Ruler_Confirm',
      data_description: measureUnit,
    })

  const onCalibrationCompletePageView = () =>
    sendAnalyticsEvent({
      id: EVENTS_ID.vpw,
      Page_Section1: 'DigitalCouvette_Calibration',
      Page_Section2: 'CompletedCalibration',
      Page_Type: 'Static',
    })

  const onCouvetteExperiencePageView = () =>
    sendAnalyticsEvent({
      Page_Section1: 'DigitalCouvette',
      Page_Section2: 'Measurement',
      Page_Type: 'Static',
    })

  const onRestartCalibrationEvent = () =>
    sendAnalyticsEvent({
      id: EVENTS_ID.click,
      data_element_id: 'DigitalCouvette_RestartCalibration',
    })

  const onClickSizeEvent = (size: Size) =>
    sendAnalyticsEvent({
      id: EVENTS_ID.click,
      data_element_id: 'DigitalCouvette_Size',
      data_description: size,
    })

  const onClickHowToMeasureEvent = () =>
    sendAnalyticsEvent({
      id: EVENTS_ID.click,
      data_element_id: 'DigitalCouvette_HowToMeasure',
    })

  const onClickReferenceGlassesEvent = () =>
    sendAnalyticsEvent({
      id: EVENTS_ID.click,
      data_element_id: 'DigitalCouvette_Reference',
    })

  const resetCalibrationEvent = () =>
    sendAnalyticsEvent({
      id: EVENTS_ID.impression,
      Page_Section2: 'RestartCalibration',
    })

  const onCancelResetCalibrationEvent = () =>
    sendAnalyticsEvent({
      id: EVENTS_ID.click,
      data_element_id: 'DigitalCouvette_RestartCalibration_Back',
    })

  const onConfirmResetCalibrationEvent = () =>
    sendAnalyticsEvent({
      id: EVENTS_ID.click,
      data_element_id: 'DigitalCouvette_RestartCalibration_Restart',
    })

  return {
    onLandingPageView,
    onStartFrameCalibrationEvent,
    onStartRulerCalibrationEvent,
    onCalibrationPageView,
    onConfirmFrameCalibrationEvent,
    onConfirmRulerCalibrationEvent,
    onCalibrationCompletePageView,
    onCouvetteExperiencePageView,
    onRestartCalibrationEvent,
    onClickSizeEvent,
    onClickHowToMeasureEvent,
    onClickReferenceGlassesEvent,
    resetCalibrationEvent,
    onCancelResetCalibrationEvent,
    onConfirmResetCalibrationEvent,
  }
}
