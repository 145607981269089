import formatPrice from 'libs/formatPrice'
import { useStoreLang } from './useStoreLang'
import { useCallback } from 'react'

export const usePriceFormatter = () => {
  const defaultStoreLanguage = useStoreLang()
  return useCallback(
    (price: number, currency: string) =>
      formatPrice({ price, currency, defaultLangCode: defaultStoreLanguage }),
    [defaultStoreLanguage]
  )
}
