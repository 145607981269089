import { GraphQLError } from 'graphql'
import Maybe from 'graphql/tsutils/Maybe'
import { useAnalyticsShippingMode } from 'hooks/checkoutV3/useAnalyticsShippingMethod'
import {
  convertToCamelCaseAndStripSpecials,
  getWithFallback,
  isIPadView,
  useGetProductBrandName,
} from 'libs/utils'
import { useCallback } from 'react'
import { FrameAdvisorState } from 'reducers/frameAdvisor'
import {
  ActiveFilters,
  AmInstance,
  AnalyticProduct,
  AnalyticProductResponse,
  AnalyticsCartProduct,
  AnalyticsCategory,
  AnalyticsConfig,
  AnalyticsFAProduct,
  CommonDataLayer,
  CPComponentsTypes,
  ErrorData,
  ErrorForAnalytics,
  FormatProductProps,
  IsVmSupportedLayer,
  StoreCustom,
  StoreInitialCustomData,
  UtagData,
  VmProductForAnalytics,
} from 'types/analytics'
import { CartItem } from 'types/cart'
import { OrderItem } from 'types/checkoutV3'
import { BOMComponent } from 'types/customRecipe'
import { Store } from 'types/graphqlTypes'
import { Collection, CustomProduct, Product } from 'types/product'
import { isBestMatch } from './frameAdvisor'

export const useGetDataDescriptionForProduct = () => {
  const getProductBrandName = useGetProductBrandName()
  return useCallback(
    (product: {
      brand: {
        code: string
      }
      name: string
      UPC: string
    }) => {
      return `${getProductBrandName(product.brand.code)}_${product.name}_${product.UPC}`
    },
    [getProductBrandName]
  )
}

const getCollectionCategory = (collection: string): AnalyticsCategory | string => {
  switch (collection) {
    case Collection.Optical:
      return AnalyticsCategory.OPTICS
    case Collection.Sun:
      return AnalyticsCategory.SUN
    case Collection.Stella:
    case Collection.ElectronicsOptical:
      return AnalyticsCategory.ELECTRONICS
    case Collection.ClipOn:
    case Collection.Accessories:
    case Collection.MXGogglesAccessories:
    case Collection.SnowGogglesAccessories:
    case Collection.DisposableSurgicalMask:
      return AnalyticsCategory.ACCESSORIES
    case Collection.ReadersGlasses:
    case Collection.MXGoggles:
    case Collection.SnowGoggles:
    case Collection.ElectronicSnowGoggles:
      return AnalyticsCategory.EYEWEAR
    case Collection.AFAApparel:
    case Collection.AFAAccessories:
    case Collection.AFAFootwear:
    case Collection.Miscellaneous:
    case Collection.Helmet:
      return AnalyticsCategory.AFA
    default:
      return collection
  }
}

export const formatVmProduct = (item: CustomProduct): VmProductForAnalytics => {
  return {
    [item.UPC]: {
      Category: getCollectionCategory(item.collection),
      Type: item.customizable ? 'CP' : 'STD',
      Vm_IsUpcSupported: item.vto ? '1' : '0',
    },
  }
}

export const useFormatOrderData = (
  countryId: string,
  subtot?: number,
  currency?: string,
  posReceiptId?: string
): Record<string, string> => {
  const Order_ShippingMode = useAnalyticsShippingMode()
  return {
    Order_CartId: '',
    Order_Id: posReceiptId ? `${posReceiptId}` : '',
    Order_Currency: currency ? currency : '',
    Order_GiftCardCode: '',
    Order_DiscountCode: '',
    Order_InsuranceCode: '',
    Order_Insured: '',
    Order_ProductsAmount: subtot ? (Math.round(subtot * 100) / 100).toFixed(2) : '',
    Order_DiscountAmount: '',
    Order_ShippingAmount: '0.00',
    Order_ShippingDiscount: '0.00',
    Order_ShippingTaxRate: '',
    Order_InsuranceAmount: '',
    Order_TaxAmount: '0.00',
    Order_ShippingMode,
    Order_State: countryId,
    Order_ZipCode: '',
    Order_PaymentType: 'POS',
    Order_PaymentInstallments: '',
    Order_ShippingAddress: '',
    Order_Type: '',
    Order_SubscriptionFrequency: '',
    Order_PrescriptionMethod: '',
    Order_CancellationId: '',
  }
}

export const stripSpecialCharacters = (
  stringToFormat: string,
  spacialCharactersRegex = /[\s\$]/g
): string => {
  const formattedString = stringToFormat.replace(spacialCharactersRegex, '')
  return formattedString
}

export const formatFilters = (activeFilters: ActiveFilters[]): string => {
  const formattedFilters = activeFilters
    .map(elem => {
      if (Array.isArray(elem.value)) {
        return elem.value.map((val: string) => `${elem.kind}=${val}`).join('|')
      } else {
        return `${elem.kind}=${elem.value}`
      }
    })
    .join('|')
  return stripSpecialCharacters(formattedFilters)
}

export const formatActiveFilters = (activeFilters: ActiveFilters[] | undefined): string => {
  let formattedFilters = ''
  if (activeFilters) {
    formattedFilters = formatFilters(activeFilters)
  }
  return stripSpecialCharacters(formattedFilters)
}

export const formatActiveSort = (activeSort: ActiveFilters[] | undefined): string => {
  const defaultValue = 'sort=price:relevance'
  if (activeSort) {
    if (activeSort[0] && activeSort[0].value) {
      return formatFilters(activeSort)
    }
    return defaultValue
  }
  return defaultValue
}

export const formatSearch = (searchValue: string): Record<string, string> => ({
  Page_Section1: 'Search',
  Search_Keyword: searchValue,
  Search_View: 'SERP',
  Search_Type: 'text',
  Events_SearchRun: '1',
})

export const mapLoginNation: Record<string, string> = {
  bt: 'EU', // Baltics
  me: 'EU', // LID - MENA
  ld: 'EU', // LID - UCIS
  ae: 'MEA', // Middle - East
  nc: 'EU', // Nordics
  bq: 'INT', // Boutique
  atl: 'FR', // Atelier
  lwg: 'MEA', // Lower GULF
  bal: 'EU', // or LDB (LID - Balkans)
  trr: 'INT', // Travel Retail
  lhb: 'CA', // Luxury House Brand CANADA
  opg: 'US', // Luxury House Brands USA
  osa: 'US', // USA regions
  osc: 'CA', // CA resgions
  ind: 'IN', // India regions
}

export const getStoreBrand = (store: Store): string => {
  switch (store.storeType) {
    case 'custom-only':
      return 'null'
    case 'multi':
      return 'ALL'
    default:
      return store.storeType?.toUpperCase()
  }
}

export const getStoreCustom = (store: Store): StoreCustom => {
  const storeCustom: StoreCustom[] = []
  if (store.ocpEnabled) storeCustom.push('OCP')
  if (store.remixEnabled) storeCustom.push('Remix')
  if (storeCustom.length === 0) storeCustom.push('null')
  return storeCustom.join(',') as StoreCustom
}

export const getStoreInitialCustomData = (store: Store): StoreInitialCustomData => {
  const customInitialData = {}
  if (store) {
    const storeId = getWithFallback(store.deviceId, '')
    const [deviceType, userId, lang] = storeId.split('.')
    customInitialData['User_Email_MD5'] = storeId
    customInitialData['User_Segments'] = deviceType
    customInitialData['User_Id'] = userId
    customInitialData['User_Nation'] = lang
  }
  return customInitialData
}

declare global {
  interface Window {
    gtag(eventKey: string, eventValue: string, utagData: UtagData): void
  }
}

export const getNewSessionEventSender = (
  sendAnalyticEvent: (store: Store, eventName: string) => void,
  analyticsTealiumManager: (store: Store, sessionToken: string) => AmInstance,
  sessionToken: string,
  storeData: Store
): (() => void) => {
  const amInstance = storeData && analyticsTealiumManager(storeData, sessionToken)

  return () => {
    amInstance && amInstance.sendNewSessionEvent()
    storeData && sendAnalyticEvent(storeData, 'new_session')
  }
}

export const getBeforeResetEventSender = (
  sendAnalyticEvent: (store: Store, eventName: string) => void,
  storeData: Maybe<Store>
) => {
  return (itemsInCart?: number): void => {
    if (storeData && itemsInCart) {
      sendAnalyticEvent(storeData, 'abandoned_cart')
    }
  }
}

export const EVENTS_ID = {
  vpw: 'VirtualPage-View',
  event: 'Event',
  click: 'Click',
  impression: 'Impression',
}

export const PAGES: { [key: string]: string } = {
  home: 'home',
  products: 'products',
  cart: 'cart',
  wishlist: 'wishlist',
  pdp: 'pdp',
  'thank-you': 'thank-you',
  checkout: 'checkout',
  rb: 'rb',
  oo: 'oo',
  rbpcp: 'rbpcp',
  oopcp: 'oopcp',
  playlist: 'playlist',
  fsa: 'fsa',
  stella: 'stella',
  supernova: 'supernova',
  brands: 'brands',
  onboarding: 'onboarding',
  survey: 'survey',
  profile: 'profile',
  scanning: 'scanning',
  handoff: 'retrieve-session',
  'order-check': 'order-check',
  nuance: 'nuance',
}

const PAGES_CUSTOM: { [key: string]: string } = {
  rb: 'rb',
  oo: 'oo',
}

const formatPageType = (
  pageType: string | undefined,
  subPageType: string | undefined,
  model: string | undefined
): string => {
  if (subPageType && PAGES[subPageType] && PAGES_CUSTOM[subPageType] && model) {
    return `${PAGES[subPageType]}pcp`
  }

  if (subPageType) {
    return PAGES[subPageType] ?? PAGES.pdp
  }

  return pageType ? PAGES[pageType] : PAGES.home
}

const getCustomModelFromPathname = (): string => {
  const [pathname] = window.location.pathname.match(/\/custom\/(.+)/) || []
  const pathArray = pathname ? pathname.split('/') : []
  const model = pathArray.find(pathElem => pathElem.endsWith('CP'))
  return model || ''
}

const getPageType = (): string => {
  const [, , , , pageType, subPageType] = window.location.pathname.split('/')
  const model = getCustomModelFromPathname()
  return formatPageType(pageType, subPageType, model)
}

const OO_CP_COMPONENTS_TYPES: CPComponentsTypes = {
  case: 'ocp_microbag',
  engraving: 'ocp_etching',
}

const RB_CP_COMPONENTS_TYPES: CPComponentsTypes = {
  case: 'C',
  engraving: 'S',
}

const getCPComponentValue = (item: CustomProduct, componentType: keyof CPComponentsTypes) => {
  const brandTypes = item.originalBrand === 'OO' ? OO_CP_COMPONENTS_TYPES : RB_CP_COMPONENTS_TYPES
  const components = item.customItem?.custom.bom.components.filter(
    (c: BOMComponent) => c.componentType === brandTypes[componentType]
  )
  return components?.map((el: BOMComponent) => el.componentCode).join(' | ') || ''
}

const getCPSize = (item: CustomProduct) => {
  return item.customItem?.custom.bom.modelGrid?.slice(-2) || ''
}

const isPageWithCatalogProducts = (pageType: string): boolean => {
  return [PAGES.products, PAGES.pdp].includes(pageType)
}

const isConfiguratorPage = (pageType: string): boolean => {
  return [PAGES.oo, PAGES.rb, PAGES.oopcp, PAGES.rbpcp].includes(pageType)
}

export const getProductNameFromPathname = (): string => {
  const [, product] = window.location.pathname.match(/\/products\/(.+?)\//) || []
  return product ? product.replace(/-/g, '') : ''
}

// Based on doc https://docs.google.com/document/d/1AFpHgZKjf2YMP1UmqlVqM1bfPaUD5go8MAqf9dTblnE/edit#
export const PAGE_TYPES: { [key: string]: string } = {
  [PAGES.home]: 'Home',
  [PAGES.products]: 'Plp',
  [PAGES.cart]: 'CartPage',
  [PAGES.wishlist]: 'Wishlist',
  [PAGES.pdp]: 'Pdp',
  [PAGES['thank-you']]: 'Thankyou',
  [PAGES.checkout]: 'Delivery',
  [PAGES.rb]: 'Custompage',
  [PAGES.oo]: 'Custompage',
  [PAGES.rbpcp]: 'Pcp',
  [PAGES.oopcp]: 'Pcp',
  [PAGES.playlist]: 'Playlist',
  [PAGES.fsa]: 'FrameAdvisor',
  [PAGES.brands]: 'Landing',
  [PAGES.onboarding]: 'Connection',
  [PAGES.survey]: 'Survey',
  [PAGES.profile]: 'Profile',
  [PAGES.scanning]: 'Facescan',
  [PAGES.electronics]: 'Landing',
  [PAGES.handoff]: 'Static',
  [PAGES['order-check']]: 'OrderSummary',
  [PAGES.nuance]: 'Landing',
}

export const PAGE_SECTION_1: { [key: string]: string } = {
  [PAGES.home]: 'Landings',
  [PAGES.products]: 'Catalog',
  [PAGES.pdp]: 'Catalog',
  [PAGES.wishlist]: 'FittingRoom',
  [PAGES.cart]: 'CartPage',
  [PAGES['thank-you']]: 'Checkout',
  [PAGES.checkout]: 'Checkout',
  [PAGES.rb]: 'Landings',
  [PAGES.oo]: 'Landings',
  [PAGES.rbpcp]: 'Custom',
  [PAGES.oopcp]: 'Custom',
  [PAGES.electronics]: 'Landings',
  [PAGES.brands]: 'Landings',
  [PAGES.playlist]: 'FrameAdv',
  [PAGES.onboarding]: 'FrameAdv',
  [PAGES.survey]: 'FrameAdv',
  [PAGES.profile]: 'FrameAdv',
  [PAGES.scanning]: 'FrameAdv',
  [PAGES.handoff]: 'Other',
  [PAGES.nuance]: 'Landings',
}

export const PAGE_SECTION_2: { [key: string]: string } = {
  [PAGES['thank-you']]: 'Standard',
  [PAGES.checkout]: 'Standard',
  [PAGES.pdp]: getProductNameFromPathname(),
  [PAGES.rb]: 'Custom',
  [PAGES.oo]: 'Custom',
  [PAGES.rbpcp]: getCustomModelFromPathname(),
  [PAGES.oopcp]: getCustomModelFromPathname(),
  [PAGES.stella]: 'stella',
  [PAGES.supernova]: 'supernova',
  [PAGES.brands]: 'brands',
  [PAGES.handoff]: 'HandOff',
  [PAGES.nuance]: 'Nuance',
}

export const ERROR_SOURCE: { [key: string]: string } = {
  'Solr Error': 'Product',
  'Network error': 'Server',
  '404': '404',
  '500': 'Client',
  'CMS Error': 'Server',
  'WCS Error': 'Server',
  'Magento Error': 'Server',
  'Catalog API Error': 'Server',
}

export const isGraphQLError = (e: GraphQLError | Error): e is GraphQLError => 'extensions' in e

export const formatError = (error: ErrorData): ErrorForAnalytics => {
  const errorData = error.errors[0]
  return {
    id: 'Error',
    Error_Source: ERROR_SOURCE[error.type] || 'Anomaly',
    Error_Code: isGraphQLError(errorData) ? errorData.extensions?.code : '500',
    Error_Details: errorData.stack || errorData.message,
    Error_Message: errorData.message,
  }
}

const pageCountryMap: Record<string, string> = {
  GB: 'UK',
}

const getPageCountry = (countryId: string) => {
  countryId = countryId.toUpperCase()
  return pageCountryMap[countryId] || countryId
}

export const formatCommonData = (
  analyticsConfig: AnalyticsConfig,
  store: Maybe<Store> | undefined,
  version = '',
  isEventWithCatalogProducts = false
): CommonDataLayer | IsVmSupportedLayer => {
  const deviceType = isIPadView() ? 'iPAD' : 'WALL43'
  const pageType = getPageType()
  const lang = store?.langCode.split('-')[0].toUpperCase()

  // used the fallbacks on empty string because the analytics provider wraps service and config-override routes too
  const urlSlugs = window.location.pathname.split('/')
  const storeId = urlSlugs[2] || ''
  const langCode = urlSlugs[3] || ''
  const langCodeChuncks = langCode && langCode.split('-')
  const langCodeFromUrl = langCodeChuncks[0] || ''
  const countryIdFromUrl = langCodeChuncks[1] || ''

  const defaultLang = langCodeFromUrl.toUpperCase()
  const defaultCountyId = countryIdFromUrl

  let commonDataContext: CommonDataLayer = {
    Page_Brand: analyticsConfig.shopperSlug || '',
    Store_Id: store?.globalStoreId || storeId,
    Store_Chain: store?.gviCode,
    Page_Design: '',
    Page_Country: getPageCountry(store?.countryId || defaultCountyId || ''),
    Page_Language: lang || defaultLang,
    Page_DeviceType: deviceType,
    Page_Platform: analyticsConfig.pagePlatform || 'SS20',
    Page_PlatformRelease: version || '',
    Page_Type: PAGE_TYPES[pageType],
    Page_Section1: PAGE_SECTION_1[pageType] || '',
    Page_Section2: PAGE_SECTION_2[pageType] || '',
    Order_Currency: store?.currencyFormat || '',
  }

  if (isPageWithCatalogProducts(pageType) || isEventWithCatalogProducts) {
    commonDataContext.Vm_IsBrowserSupported = store?.vmEnabled ? '1' : '0'
  }

  return commonDataContext
}

export const analyticsFormatProduct = ({
  item,
  quantity = 0,
  isEventWithCatalogProducts = false,
  withFAData = false,
}: FormatProductProps): AnalyticProductResponse => {
  const pageType = getPageType()
  const isCustomized = !!item.customItem

  let productContext: AnalyticProductResponse = {
    Status: item.availability?.available ? 'Available' : 'Out-of-stock',
    OosOptions: '',
    Category: getCollectionCategory(item.collection),
    Type: isCustomized ? 'CP' : 'STD',
    Engraving: isCustomized ? getCPComponentValue(item, 'engraving') : '',
    Price: '0.00',
    PriceFull: '0.00',
    Brand: item.originalBrand, // TODO: fix this getProductBrandName(item.originalBrand),
    Size: isCustomized ? getCPSize(item) : `${item.size}`,
    Sku: item.sku ? item.sku : '',
    ModelName: item.name,
    MoCo: item.moco,
    LensColor: item.lensColor ? item.lensColor : '',
    LensTechnology: '',
    FrameColor: item.frameColor ? item.frameColor : '',
    FrameTechnology: '',
    Shape: item.frameShape ? item.frameShape[0] : '',
    LensUPC: '',
    ModelCode: item.customizable ? item.model : '',
    Lens: item.customizable && item.lensColorLabel ? item.lensColorLabel : '',
    Frame: item.customizable && item.frameColorLabel ? item.frameColorLabel : '',
    Case: item.customizable ? getCPComponentValue(item, 'case') : '',
    Cloth: '',
    Units: item.customizable ? '1' : quantity ? quantity.toString() : '',
    PerkCode: '',
    InsuranceAmount: '',
    InsuranceCode: '',
    Warranty: '',
    TaxRate: '0.00',
    CancelledUnits: '',
    CancelledAmount: '',
    Badges: '',
  }

  if (item.customizable) {
    productContext.Stock = `${item.availability?.ecommStock}`
    productContext.Image = `/${item.originalBrand}/${item.model}/${item.moco}_000A.png`
    productContext.Url = `${item.url}`
  }

  if (
    isPageWithCatalogProducts(pageType) ||
    isEventWithCatalogProducts ||
    isConfiguratorPage(pageType)
  ) {
    productContext.Vm_IsUpcSupported = item.vto ? '1' : '0'
    productContext.Conf_IsUpcSupported = item.customizable ? '1' : '0'
  }

  if (withFAData) {
    productContext.Fg_BestMatch = isBestMatch(item) ? '1' : '0'
    productContext.Badges = 'FrameAdv'
  }

  return productContext
}

export const analyticsFormatProducts = (
  items: AnalyticProduct[]
): Record<string, AnalyticProductResponse> => {
  return items.reduce((acc: Record<string, AnalyticProductResponse>, product: AnalyticProduct) => {
    acc[product.UPC] = analyticsFormatProduct({ item: product })
    return acc
  }, {})
}

export const analyticsFormatCheckoutProducts = (
  items: OrderItem[],
  cartItems: CartItem[]
): Record<string, AnalyticProductResponse> => {
  return items.reduce((acc: Record<string, AnalyticProductResponse>, orderItem: OrderItem) => {
    const cartItem = cartItems.find(cItem => {
      if (!!cItem.product.customItem) {
        return orderItem.customDetails?.recipeId === String(cItem.product.customItem.id)
      }
      return cItem.product.UPC === orderItem.details.upc
    })
    if (!cartItem?.product) return acc

    const cartProduct = (cartItem.product as unknown) as AnalyticProduct
    const UPC = cartProduct.customizable ? cartProduct.model : cartProduct.UPC

    acc[UPC] = analyticsFormatProduct({
      item: cartProduct,
      quantity: orderItem.itemQuantity,
    })
    return acc
  }, {})
}

export const analyticsFormatCartItems = (
  items: AnalyticsCartProduct[],
  isEventWithCatalogProducts = false
): Record<string, AnalyticProductResponse> => {
  return items.reduce(
    (acc: Record<string, AnalyticProductResponse>, item: AnalyticsCartProduct) => {
      const UPC = item.product.customizable ? item.product.model : item.product.UPC
      acc[UPC] = analyticsFormatProduct({
        item: item.product,
        quantity: item.quantity,
        isEventWithCatalogProducts,
      })
      return acc
    },
    {}
  )
}

export const analyticsFormatFASuggestions = (
  items: Product[]
): Record<string, AnalyticsFAProduct> => {
  return items.reduce((acc: Record<string, AnalyticsFAProduct>, item: Product) => {
    const UPC = item.customizable ? item.model : item.UPC
    acc[UPC] = {
      Category: getCollectionCategory(item.collection),
      Type: item.customizable ? 'CP' : 'STD',
      FrameType: item.frameAdvisorEvidences.frameShape || '',
      Fg_BestMatch: isBestMatch(item) ? '1' : '0',
      LensType: item.frameAdvisorEvidences.lensColorTreatment || '',
      Price: '0.00',
      PriceFull: '0.00',
      // TODO: will be correct when availability is added for FA product
      Status: 'Available',
    }
    return acc
  }, {})
}

export const getPageUserFace = (frameAdvisorState: FrameAdvisorState) => {
  const { fsaInfo } = frameAdvisorState

  const faceShape =
    fsaInfo?.userSearchInformation.faceShape || frameAdvisorState.survey.face_shape || ''
  const faceLength =
    fsaInfo?.userSearchInformation.faceLength || frameAdvisorState.survey.face_length || ''
  const faceSkin = fsaInfo?.userSearchInformation.skinUndertones || ''

  const hairColor = fsaInfo?.userSearchInformation.hairColor || ''
  const eyeColor = fsaInfo?.userSearchInformation.eyeColor || ''

  const userFace = {
    JawShape: faceShape ?? faceLength,
    FaceLength: faceLength,
    FaceSkin: faceSkin,
    HairColor: hairColor,
    EyeColor: eyeColor,
  }

  const userFacePairs = Object.keys(userFace).reduce((acc, key) => {
    const value = userFace[key]
    if (value) return [...acc, `${key}=${value}`]

    return acc
  }, [])

  return userFacePairs.join(',')
}

export const getPageUserStyle = (frameAdvisorState: FrameAdvisorState) => {
  const { fsaInfo } = frameAdvisorState
  const SUNGLASSES_LABELS = ['SUN', 'Sunglasses']
  const gender = fsaInfo?.userSearchInformation.gender || frameAdvisorState.survey.gender || ''

  const playList = frameAdvisorState.selectedPlaylist?.analyticsData?.playlistTitleID || ''
  const shadesFor = frameAdvisorState.shadesFor || 'MySelf'
  const frameCat = frameAdvisorState.survey.productType
    ? SUNGLASSES_LABELS.includes(frameAdvisorState.survey.productType)
      ? 'Sunglasses'
      : 'Eyeglasses'
    : ''

  const userStyles = {
    For: shadesFor,
    Gender: gender,
    FrameCat: frameCat,
    Style: convertToCamelCaseAndStripSpecials(playList),
  }
  const userStylePairs = Object.keys(userStyles).reduce((acc, key) => {
    const value = userStyles[key]
    if (value) return [...acc, `${key}=${value}`]

    return acc
  }, [])

  return userStylePairs.join(',')
}

export const formatFrameAdvisorPageData = (frameAdvisorState: FrameAdvisorState) => {
  const isFaceScanAppConnected = !!frameAdvisorState.fsaInfo?.pictureUuid

  return {
    Fs_IsConnected: isFaceScanAppConnected ? '1' : '0',
    Page_UserFace: getPageUserFace(frameAdvisorState),
    Page_UserStyle: getPageUserStyle(frameAdvisorState),
  }
}
