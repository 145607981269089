import { FaCustomizeIcon } from 'components/core/Icons/FaCustomizeIcon'
import { VmIcon } from 'components/core/Icons/VmIcon'
import { AntistaticIcon } from './Antistatic'
import { ArrowLeftIcon } from './ArrowLeft'
import { BackIcon } from './Back'
import { BarcodeIcon } from './BarcodeIcon'
import { BestMatchTipIcon } from './BestMatchTip'
import { BestMatchesIcon } from './BestMatches'
import { ButterflyIcon } from './Butterfly'
import { CaretDownIcon } from './CaretDown'
import { CaretDownThinIcon } from './CaretDownThin'
import { CaretLeftIcon } from './CaretLeft'
import { CaretLeftThinIcon } from './CaretLeftThin'
import { CaretMenuIcon } from './CaretMenu'
import { CaretRightIcon } from './CaretRight'
import { CaretRightThinIcon } from './CaretRightThin'
import { CaretUpThinIcon } from './CaretUpThin'
import { CatIcon } from './Cat'
import { CheckmarkIcon } from './Checkmark'
import { CheckmarkRoundIcon } from './CheckmarkRound'
import { CloseIcon } from './Close'
import { CloseSelectIcon } from './CloseSelect'
import { CustomizeIcon } from './Customize'
import { DigitalCouvetteIcon } from './DigitalCouvette'
import { DressedOnIcon } from './DressedOn'
import { EasyOnThePlanetBlackIcon } from './EasyOnThePlanet'
import { EsillorLogoBlackIcon } from './EssilorLogo'
import { FaBridgePositionIcon } from './FaBridgePosition'
import { FaBridgePositionHighIcon } from './FaBridgePositionHigh'
import { FaBridgePositionLowIcon } from './FaBridgePositionLow'
import { FaFaceShapeDiamondManIcon } from './FaFaceShapeDiamondMan'
import { FaFaceShapeDiamondUngenderedIcon } from './FaFaceShapeDiamondUngendered'
import { FaFaceShapeDiamondWomanIcon } from './FaFaceShapeDiamondWoman'
import { FaFaceShapeHeartManIcon } from './FaFaceShapeHeartMan'
import { FaFaceShapeHeartUngenderedIcon } from './FaFaceShapeHeartUngendered'
import { FaFaceShapeHeartWomanIcon } from './FaFaceShapeHeartWoman'
import { FaFaceShapeOvalManIcon } from './FaFaceShapeOvalMan'
import { FaFaceShapeOvalUngenderedIcon } from './FaFaceShapeOvalUngendered'
import { FaFaceShapeOvalWomanIcon } from './FaFaceShapeOvalWoman'
import { FaFaceShapeRectangleManIcon } from './FaFaceShapeRectangleMan'
import { FaFaceShapeRectangleUngenderedIcon } from './FaFaceShapeRectangleUngendered'
import { FaFaceShapeRectangleWomanIcon } from './FaFaceShapeRectangleWoman'
import { FaFaceShapeRoundManIcon } from './FaFaceShapeRoundMan'
import { FaFaceShapeRoundUngenderedIcon } from './FaFaceShapeRoundUngendered'
import { FaFaceShapeRoundWomanIcon } from './FaFaceShapeRoundWoman'
import { FaFaceShapeSquareManIcon } from './FaFaceShapeSquareMan'
import { FaFaceShapeSquareUngenderedIcon } from './FaFaceShapeSquareUngendered'
import { FaFaceShapeSquareWomanIcon } from './FaFaceShapeSquareWoman'
import { FaFittingFeaturesIcon } from './FaFittingFeatures'
import { FaFrameShapeCatIcon } from './FaFrameShapeCat'
import { FaFrameShapePilotIcon } from './FaFrameShapePilot'
import { FaFrameShapeSquareIcon } from './FaFrameShapeSquare'
import { FaStyleIcon } from './FaStyle'
import { FaTempleFittingIcon } from './FaTempleFitting'
import { FaTempleFittingHighIcon } from './FaTempleFittingHigh'
import { FaTempleFittingMiddleIcon } from './FaTempleFittingMiddle'
import { FaThicknessMediumIcon } from './FaThicknessMedium'
import { FaThicknessThickIcon } from './FaThicknessThick'
import { FaThicknessThinIcon } from './FaThicknessThin'
import { FaTipsEyeglassesOffIcon } from './FaTipsEyeglassesOff'
import { FaTipsHoldDeviceIcon } from './FaTipsHoldDevice'
import { FaTipsLitSpotIcon } from './FaTipsLitSpot'
import { FaTipsLookStraightIcon } from './FaTipsLookStraight'
import { FaTipsMoveHairIcon } from './FaTipsMoveHair'
import { FaTipsRelaxedFaceIcon } from './FaTipsRelaxedFace'
import { FaceLengthIcon } from './FaceLength'
import { FaceLengthLongIcon } from './FaceLengthLong'
import { FaceLengthShortIcon } from './FaceLengthShort'
import { FaceScanAppSwitchFace } from './FaceScanAppSwitchFace'
import { FaceScanAppSwitchGlasses } from './FaceScanAppSwitchGlasses'
import { FaceShapesIcon } from './FaceShapes'
import { FilterIcon } from './Filter'
import { FiltersIcon } from './Filters'
import { FitTipIcon } from './FitTip'
import { FrameAdvisorIcon } from './FrameAdvisor'
import { BlackMetalsIcon } from './FrameAdvisor/BlackMetals'
import { BronzeMetalsIcon } from './FrameAdvisor/BronzeMetals'
import { FaceShapeCircularIcon } from './FrameAdvisor/FaceShapeCircular'
import { FaceShapeOvalIcon } from './FrameAdvisor/FaceShapeOval'
import { FaceShapeRectangularIcon } from './FrameAdvisor/FaceShapeRectangular'
import { FaceShapeSquaredIcon } from './FrameAdvisor/FaceShapeSquared'
import { FaceShapeTriangleIcon } from './FrameAdvisor/FaceShapeTriangle'
import { GoldMetalsIcon } from './FrameAdvisor/GoldMetals'
import { GunMetalsIcon } from './FrameAdvisor/GunMetals'
import { RoseMetalsIcon } from './FrameAdvisor/RoseMetals'
import { SilverMetalsIcon } from './FrameAdvisor/SilverMetals'
import { FrameColorIcon } from './FrameColor'
import { FrameMeasureIcon } from './FrameMeasure'
import { FrameShapeIcon } from './FrameShape'
import { FrameSizeIcon } from './FrameSize'
import { FrameWidthIcon } from './FrameWidth'
import { GeolocatorIcon } from './Geolocator'
import { GeometricalIcon } from './Geometrical'
import { HamburgerMenuIcon } from './HamburgerMenu'
import { HingeToHingeIcon } from './HingeToHinge'
import { HomeIcon } from './HomeIcon'
import { HydrophobicIcon } from './Hydrophobic'
import { IconIsNew } from './IconIsNew'
import { InfoIcon } from './Info'
import { InnerglarereductionIcon } from './Innerglarereduction'
import { IrregularIcon } from './Irregular'
import { LSizeIcon } from './LSize'
import { LensColorTreatmentIcon } from './LensColorTreatment'
import { MSizeIcon } from './MSize'
import { MenuIcon } from './Menu'
import { MinusIcon } from './Minus'
import { NuanceAudioLogo } from './NuanceAudio'
import { OakleyCustomIcon } from './OakleyCustom'
import { OleophobicIcon } from './Oleophobic'
import { OuterglarereductionIcon } from './Outerglarereduction'
import { OvalIcon } from './Oval'
import { PhantosIcon } from './Phantos'
import { PictureModeIcon } from './PictureMode'
import { PillowIcon } from './Pillow'
import { PilotIcon } from './Pilot'
import { PlaylistTipIcon } from './PlaylistTip'
import { PlusIcon } from './Plus'
import { PriceIcon } from './Price'
import { ProTipIcon } from './ProTip'
import { ProductViewIcon } from './ProductView'
import { RayBanMetaLogo } from './RayBanMetaLogo'
import { RayBanStories } from './RayBanStories'
import { RaybanFacebookLogoIcon } from './RaybanFacebookLogo'
import { RectangleIcon } from './Rectangle'
import { ResetIcon } from './Reset'
import { RestartIcon } from './Restart'
import { RetakeScanIcon } from './RetakeScan'
import { RotateDeviceToLandscapeIcon } from './RotateDeviceToLandscape'
import { RotateDeviceToPortraitIcon } from './RotateDeviceToPortrait'
import { RoundIcon } from './Round'
import { RulerIcon } from './Ruler'
import { SSizeIcon } from './SSize'
import { ScanIcon } from './ScanIcon'
import { SearchIcon } from './Search'
import { SelectedIcon } from './Selected'
import { ShopBagIcon } from './ShopBag'
import { ShoppingBagIcon } from './ShoppingBag'
import { SizeFitTipIcon } from './SizeFitTip'
import { SkinUndertone1Icon } from './SkinUndertone1'
import { SkinUndertone2Icon } from './SkinUndertone2'
import { SkinUndertone3Icon } from './SkinUndertone3'
import { SkinUndertonesIcon } from './SkinUndertones'
import { SquareIcon } from './Square'
import { ThumbDownIcon } from './ThumbDown'
import { ThumbUpIcon } from './ThumbUp'
import { ThumbUpLiked } from './ThumbUpLiked'
import { ThumbUpPlain } from './ThumbUpPlain'
import { TrashIcon } from './Trash'
import { UndertoneBlueIcon } from './UndertoneBlue'
import { UndertoneCoolIcon } from './UndertoneCool'
import { UndertoneGreenIcon } from './UndertoneGreen'
import { UndertoneWarmIcon } from './UndertoneWarm'
import { UndoIcon } from './UndoIcon'
import { UpcIcon } from './Upc'
import { VTOIcon } from './VTO'
import { WarningIcon } from './WarningIcon'
import { XlSizeIcon } from './XlSize'
import { XsSizeIcon } from './XsSize'
import { XxlSizeIcon } from './XxlSize'
import { XxsSizeIcon } from './XxsSize'
import { RightIcon } from './Right'
import { LeftIcon } from './Left'
import { StopButton } from './StopButton'
import { PlayButton } from './PlayButton'

export {
  AntistaticIcon,
  ArrowLeftIcon,
  BackIcon,
  BarcodeIcon,
  BestMatchesIcon,
  BestMatchTipIcon,
  BlackMetalsIcon,
  BronzeMetalsIcon,
  ButterflyIcon,
  CaretDownIcon,
  CaretDownThinIcon,
  CaretLeftIcon,
  CaretLeftThinIcon,
  CaretMenuIcon,
  CaretRightIcon,
  CaretRightThinIcon,
  CaretUpThinIcon,
  CatIcon,
  CheckmarkIcon,
  CheckmarkRoundIcon,
  CloseIcon,
  CloseSelectIcon,
  CustomizeIcon,
  DigitalCouvetteIcon,
  DressedOnIcon,
  EasyOnThePlanetBlackIcon,
  EsillorLogoBlackIcon,
  FaBridgePositionHighIcon,
  FaBridgePositionIcon,
  FaBridgePositionLowIcon,
  FaceLengthIcon,
  FaceLengthLongIcon,
  FaceLengthShortIcon,
  FaceScanAppSwitchFace,
  FaceScanAppSwitchGlasses,
  FaceShapeCircularIcon,
  FaceShapeOvalIcon,
  FaceShapeRectangularIcon,
  FaceShapesIcon,
  FaceShapeSquaredIcon,
  FaceShapeTriangleIcon,
  FaCustomizeIcon,
  FaFaceShapeDiamondManIcon,
  FaFaceShapeDiamondUngenderedIcon,
  FaFaceShapeDiamondWomanIcon,
  FaFaceShapeHeartManIcon,
  FaFaceShapeHeartUngenderedIcon,
  FaFaceShapeHeartWomanIcon,
  FaFaceShapeOvalManIcon,
  FaFaceShapeOvalUngenderedIcon,
  FaFaceShapeOvalWomanIcon,
  FaFaceShapeRectangleManIcon,
  FaFaceShapeRectangleUngenderedIcon,
  FaFaceShapeRectangleWomanIcon,
  FaFaceShapeRoundManIcon,
  FaFaceShapeRoundUngenderedIcon,
  FaFaceShapeRoundWomanIcon,
  FaFaceShapeSquareManIcon,
  FaFaceShapeSquareUngenderedIcon,
  FaFaceShapeSquareWomanIcon,
  FaFittingFeaturesIcon,
  FaFrameShapeCatIcon,
  FaFrameShapePilotIcon,
  FaFrameShapeSquareIcon,
  FaStyleIcon,
  FaTempleFittingHighIcon,
  FaTempleFittingIcon,
  FaTempleFittingMiddleIcon,
  FaThicknessMediumIcon,
  FaThicknessThickIcon,
  FaThicknessThinIcon,
  FaTipsEyeglassesOffIcon,
  FaTipsHoldDeviceIcon,
  FaTipsLitSpotIcon,
  FaTipsLookStraightIcon,
  FaTipsMoveHairIcon,
  FaTipsRelaxedFaceIcon,
  FilterIcon,
  FiltersIcon,
  FitTipIcon,
  FrameAdvisorIcon,
  FrameColorIcon,
  FrameMeasureIcon,
  FrameShapeIcon,
  FrameSizeIcon,
  FrameWidthIcon,
  GeolocatorIcon,
  GeometricalIcon,
  GoldMetalsIcon,
  GunMetalsIcon,
  HamburgerMenuIcon,
  HingeToHingeIcon,
  HomeIcon,
  HydrophobicIcon,
  IconIsNew,
  InfoIcon,
  InnerglarereductionIcon,
  IrregularIcon,
  LensColorTreatmentIcon,
  LSizeIcon,
  MenuIcon,
  MinusIcon,
  MSizeIcon,
  NuanceAudioLogo,
  OakleyCustomIcon,
  OleophobicIcon,
  OuterglarereductionIcon,
  OvalIcon,
  PhantosIcon,
  PictureModeIcon,
  PillowIcon,
  PilotIcon,
  PlaylistTipIcon,
  PlusIcon,
  PriceIcon,
  ProductViewIcon,
  ProTipIcon,
  RaybanFacebookLogoIcon,
  RayBanMetaLogo,
  RayBanStories,
  RectangleIcon,
  ResetIcon,
  RestartIcon,
  RetakeScanIcon,
  RoseMetalsIcon,
  RotateDeviceToLandscapeIcon,
  RotateDeviceToPortraitIcon,
  RoundIcon,
  RulerIcon,
  ScanIcon,
  SearchIcon,
  SelectedIcon,
  ShopBagIcon,
  ShoppingBagIcon,
  SilverMetalsIcon,
  SizeFitTipIcon,
  SkinUndertone1Icon,
  SkinUndertone2Icon,
  SkinUndertone3Icon,
  SkinUndertonesIcon,
  SquareIcon,
  SSizeIcon,
  ThumbDownIcon,
  ThumbUpIcon,
  ThumbUpLiked,
  ThumbUpPlain,
  TrashIcon,
  UndertoneBlueIcon,
  UndertoneCoolIcon,
  UndertoneGreenIcon,
  UndertoneWarmIcon,
  UndoIcon,
  UpcIcon,
  VmIcon,
  VTOIcon,
  WarningIcon,
  XlSizeIcon,
  XsSizeIcon,
  XxlSizeIcon,
  XxsSizeIcon,
  RightIcon,
  LeftIcon,
  StopButton,
  PlayButton,
}
