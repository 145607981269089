import styled from 'styled-components'

export const PanelHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 4.545rem;

  & > *:first-child {
    flex-grow: 1;
  }
`
export const PanelHeaderCloseButton = styled.button`
  padding: 0.682rem;
  width: 2.273rem;
`
export const PanelHeaderTitle = styled.h3`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.455rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryText};
`
export const PanelHeaderTitleCount = styled.span`
  color: ${({ theme }) => theme.colors.secondaryText};
  font-weight: bold;
`
