import React from 'react'
import { IconProps } from 'types/icons'

export const ArrowLeftIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = 'none',
  stroke = '#333',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34 24"
      role="button"
      aria-label="backIcon"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M2.634 12.424h30.549M12.629 1.653L1.96 12.424l10.67 10.771"
      ></path>
    </svg>
  )
}
