import config from 'config'
import { ReplyParsedToken } from 'containers/LoginRedirect'
import jwt from 'jsonwebtoken'
import { forIn, startsWith } from 'lodash'
import Log from './Log'
export const setAuthToken = (authToken): void => localStorage.setItem(config.authKey, authToken)

export const setMigrationStatus = (isMigrated): void => {
  const isAllowedValueType = isMigrated !== null && isMigrated !== undefined
  isAllowedValueType && localStorage.setItem(config.migrationStatusKey, isMigrated)
}

export const setADB2CToken = (ADB2CToken): void =>
  localStorage.setItem(config.adb2ctokenKey, ADB2CToken)

export const getAuthToken = (): string => localStorage.getItem(config.authKey)

const getMigrationStatus = (): string => localStorage.getItem(config.migrationStatusKey)
export const checkUserMigrated = (): boolean => getMigrationStatus() == 'true'

export const cleanLocalStorageData = (): void => {
  forIn(window.localStorage, (_, objKey: string) => {
    // I am using this key instead of the one in the variable localStorageKeyPrefix because some users might still have the old key name. Since m4c- is also present on the localStorageKeyPrefix key 'm4c-ui' this should handle both new and old keys
    if (startsWith(objKey, 'm4c-')) {
      window.localStorage.removeItem(objKey)
    }
  })
}

export const checkToken = (token): boolean => {
  let valid = false

  try {
    const data = jwt.decode(token)
    valid = !!(data && Date.now() / 1000 < data.exp)
  } catch (e) {
    Log.error(e)
  }
  return valid
}

export const isAppReadOnly = (): boolean => {
  const appReadOnlyLocalStorage = localStorage.getItem(config.appReadOnlyKey)
  if (appReadOnlyLocalStorage === '1') {
    return true
  }

  const token = getAuthToken()
  let readOnly = false

  try {
    const data = jwt.decode(token)
    readOnly = !!(data && data.readOnly)
    if (!readOnly) {
      readOnly = false
    }
  } catch (e) {
    Log.error(e)
  }
  return readOnly
}

export const isAuthenticated = (): boolean => {
  const token = getAuthToken()
  const isUserMigratedKeyPresentInLocalStorage = window.localStorage.hasOwnProperty(
    config.migrationStatusKey
  )

  if (token) {
    if (!isUserMigratedKeyPresentInLocalStorage) {
      const parsedToken: ReplyParsedToken = JSON.parse(atob(token.trim().split('.')[1]))
      setMigrationStatus(parsedToken.isMigrated || 'false')
      return true
    }
    if (isUserMigratedKeyPresentInLocalStorage && checkToken(token)) {
      return true
    }
  }

  cleanLocalStorageData()
  return false
}
