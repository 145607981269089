import { BarcodeScanModalProps } from 'components/BarCodeScanModal/types'
import { PrimaryButton } from 'components/core/Button'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BarcodeScanModalBase } from '../BarcodeScanModalBase'
import { useSendCloseModalEvent, useSendImpressionEvent } from './analytics'

export const BarcodeScanModalUnavailableNotPolarizedOtherMocos = ({
  visible,
  onCancel,
  productInfo,
}: BarcodeScanModalProps) => {
  const { t } = useTranslation()
  const { originalBrand, moco, model } = productInfo

  const thumbnail = {
    brand: originalBrand,
    materialCode: model,
    moco,
  }

  const subtitle = t('BarCodeScanModal.standardSameModelSubtitle')
  const title = t('BarCodeScanModal.standardSameModelTitle')
  const description = t('BarCodeScanModal.standardDescriptionSameModel')
  const sendCloseModalEvent = useSendCloseModalEvent()

  const handleOnCancel = useCallback(() => {
    sendCloseModalEvent()
    onCancel()
  }, [onCancel, sendCloseModalEvent])

  const { sendImpressionEvent } = useSendImpressionEvent(visible, false)

  useEffect(() => {
    if (visible) {
      sendImpressionEvent()
    }
  }, [visible, sendImpressionEvent])

  return (
    <BarcodeScanModalBase
      visible={visible}
      title={title}
      subtitle={subtitle}
      description={description}
      formattedBrand={originalBrand}
      thumbnail={thumbnail}
      onCancel={handleOnCancel}
      footerContent={[
        <PrimaryButton
          key="cancel"
          onClick={handleOnCancel}
          data-element-id="BarcodeNoResultStd_Polarized"
        >
          {t('BarCodeScanModal.showBtnStandard')}
        </PrimaryButton>,
      ]}
    />
  )
}
