import { PanelHeaderContainer } from 'components/core/PanelHeader/styles'
import { Device, landscape, tablet } from 'libs/media'
import { clampValuesLandscape } from 'libs/styled'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const MiniCartItemsContainer = styled.div``

export const MiniCartContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${PanelHeaderContainer} {
    margin-bottom: 1.727rem;
  }
`

export const DrawerMiniCartItemsContainer = styled.div`
  overflow-y: auto;

  ${landscape(`
    flex: 1;
    padding-bottom: 3rem;
  `)}
  ${landscape(
    Device.tablet,
    `
    flex: 1;
    padding-bottom: ${clampValuesLandscape(50, 220)}
  `
  )}
  ${landscape(Device.ipadMini, 'padding-bottom: 3rem;')}

  ${tablet(`
    flex: 1;
    padding-bottom: 3rem;
  `)}

  ${tablet(Device.ipadMini, 'height: 70%;')}
`
export const MiniCartPanelHeader = styled.div`
  margin-bottom: 1.591rem;
  width: 100%;
`
export const MiniCartEmpty = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.136rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primaryText};
  text-align: center;
`

export const StyledLink = styled(Link)`
  display: grid;
`

export const MiniCartSummuryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.591rem;
  font-size: 1.136rem;
  color: ${({ theme }) => theme.colors.primaryText};
`

export const TotalValue = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryText};
`

export const TotalText = styled.span`
  color: ${({ theme }) => theme.colors.primaryText};
  text-transform: uppercase;
`
