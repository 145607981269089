import React from 'react'
import { IconProps } from 'types/icons'
export const RulerIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M46 0.0100098H2C0.895 0.0100098 0 0.90501 0 2.01001V19.99C0 21.095 0.895 21.99 2 21.99H46C47.105 21.99 48 21.095 48 19.99V2.01001C48 0.90601 47.105 0.0100098 46 0.0100098ZM46 19.99H2V2.01001H7.667V10.01H9.667V2.01001H15.334V10.01H17.334V2.01001H23V10.01H25V2.01001H30.667V10.01H32.667V2.01001H38.334V10.01H40.334V2.01001H46V19.99Z"
        fill={fill}
      />
    </svg>
  )
}
