import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const useFsaProfilePicture = (pictureUrl: string) => {
  const accessToken = useSelector(state => state.frameAdvisor.faceScanAccessToken)
  const [fileUrl, setFileUrl] = useState<string>()
  useEffect(() => {
    const fetchImage = async () => {
      const url = await fetch(pictureUrl, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(r => r.blob())
        .then(r => URL.createObjectURL(r))
      setFileUrl(url)
    }
    if (pictureUrl) {
      fetchImage()
    }
  }, [pictureUrl, accessToken])

  return fileUrl
}
