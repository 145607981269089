import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'
import { useEffect } from 'react'

export const useSendCloseModalEvent = () =>
  useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: 'BarcodeNoResultStd_Close',
  })

export const useSendImpressionEvent = (shouldSendEvent: boolean) => {
  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    Page_Section2: 'Barcode:Standard:NOUpc:CustomOnly',
  })

  useEffect(() => {
    if (shouldSendEvent) {
      sendImpressionEvent()
    }
  }, [shouldSendEvent, sendImpressionEvent])
}
