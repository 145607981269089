import { useGetI18n } from 'providers/translationProvider'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useStoreIndentity } from './useStoreIdentity'

export const useReloadLangOnHistory = () => {
  const { langCode } = useStoreIndentity()
  const history = useHistory()
  const i18n = useGetI18n()

  useEffect(() => {
    if (history.action === 'POP' && i18n.language !== langCode) {
      i18n.changeLanguage(langCode)
    }
  }, [langCode, history, i18n])
}
