import styled from 'styled-components'

export interface Row {
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  justify?:
    | 'center'
    | 'end'
    | 'start'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'baseline'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  wrap?: 'wrap' | 'nowrap'
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse'
}

export const Row = styled.div<Row>`
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  zoom: 1;
  display: flex;
  align-items: ${({ align }) => (align ? align : 'initial')};
  justify-content: ${({ justify }) => (justify ? justify : 'initial')};
  flex-wrap: ${({ wrap }) => (wrap ? wrap : 'initial')};
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  box-sizing: border-box;
`

export const Col = styled.div<{ span?: number }>`
  flex: 0 0 auto;
  display: block;
  box-sizing: border-box;
  width: ${({ span }) => (span ? `calc(${span} * 4.1667%)` : '100%')};
`
