import { hideAllDrawer as hideAllDrawerAction } from 'actions/ui'
import { Drawer } from 'components/Drawer'
import { MainMenu } from 'components/MainMenu'
import { useAction } from 'hooks/useActions'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const DrawerStyled = styled(Drawer)`
  background: ${({ theme }) => theme.colors.bodyBackground};
  color: ${({ theme }) => theme.menu.textColor};
  z-index: 5;
`

export const MenuDrawer: React.FC = () => {
  const visible = useSelector(({ ui }) => ui.menuDrawerIsVisible)
  const hideAllDrawer = useAction(hideAllDrawerAction)

  return (
    <DrawerStyled position="left" visible={visible} onOverlayClick={hideAllDrawer}>
      <MainMenu />
    </DrawerStyled>
  )
}
