import React from 'react'
import { IconProps } from 'types/icons'
export const FaTempleFittingMiddleIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M65.0006 72.6433V50H61.6673V72.6433L51.1789 62.155L48.8223 64.5117L63.3339 79.0233L77.8456 64.5117L75.4889 62.155L65.0006 72.6433Z"
        fill="black"
      />
      <path
        d="M74.1315 49.9866C74.2015 49.995 74.2699 50 74.3399 50C75.1682 50 75.8865 49.3833 75.9915 48.54L76.2249 46.6666L76.5532 44.0416L77.8665 33.54L79.5315 20.22L76.2249 19.8066L74.5599 33.1266L74.3249 35H25.5465C18.9732 35 12.8482 37.945 8.74153 43.0766L2.4582 50.9316C1.3082 52.37 1.42153 54.44 2.72486 55.7466C3.41153 56.4333 4.3332 56.81 5.28486 56.81C5.50986 56.81 5.73653 56.7883 5.9632 56.745C7.13653 56.5216 8.11986 55.735 8.59486 54.6383C9.6532 52.1916 15.4632 40 26.0049 40C26.9249 40 27.6715 39.255 27.6715 38.3333H73.9082L73.2465 43.6283L72.8665 46.6666L72.6849 48.1266C72.5699 49.04 73.2182 49.8733 74.1315 49.9866Z"
        fill="black"
      />
    </svg>
  )
}
