import qs, { IParseOptions, ParsedQs } from 'qs'
import { useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export type QueryParamsResolver<TParamValue> = (
  value: NonNullable<ParsedQs[string]>,
  key: string
) => TParamValue | undefined

export type QueryParamsResolversMap<TParams extends Record<string, unknown>> = {
  [key in keyof Required<TParams>]: QueryParamsResolver<TParams[key]>
}

export const resolveQueryParams = <TParams extends Record<string, unknown>>(
  resolvers: QueryParamsResolversMap<TParams>,
  queryParams: string,
  parseOptions?: IParseOptions & { decoder?: never }
): Partial<TParams> => {
  const params = qs.parse(queryParams.replace(/^\?/, ''), parseOptions)

  return Object.keys(params).reduce<Partial<TParams>>((accumulator, key) => {
    const resolverKey = key as keyof TParams
    const resolver = resolvers[resolverKey]
    const paramValue = params[key]

    if (typeof resolver === 'function' && paramValue) {
      const value = resolver.call(null, paramValue, resolverKey.toString())
      accumulator[resolverKey] = value
    }

    return accumulator
  }, {} as TParams)
}

/**
 * Parse the query params of the current url or the provided `queryParams` by resolving their values
 * from a transformer.
 *
 * @param resolvers The resolvers map to transform params from
 * @param queryParams The query params from the url search. It can include the `?` or omit it
 * @param parseOptions The parse options passed directly to the `qs` library
 * @returns The parsed query params in a JSON object
 *
 * @example
 * // Params will only contain the UPC as the `test` param is not resolved. The `skipOnMissingUpc` will be undefined as there is no value
 * // assigned to it. This will output:
 * // { UPC: '1895' }
 * const params = useQueryParams({ UPC: String, skipOnMissingUpc: (value) => value === 'true' }, '?UPC=1895&test=not-stored&skipOnMissingUpc')
 */
export const useQueryParams = <TParams extends Record<string, unknown>>(
  resolvers: QueryParamsResolversMap<TParams>,
  queryParams?: string,
  parseOptions?: IParseOptions & { decoder?: never }
): Partial<TParams> => {
  const resolversRef = useRef(resolvers)
  const { search } = useLocation()
  const queryParamsToResolve = queryParams ?? search

  return useMemo(() => {
    return resolveQueryParams(resolversRef.current, queryParamsToResolve, parseOptions)
  }, [parseOptions, queryParamsToResolve])
}

export const BooleanResolver = (value?: ParsedQs[string]) => String(value).toLowerCase() === 'true'
