import { DisclaimerProps } from 'types/components'
import { Label } from 'components/Typography'
import { landscape, tablet, Device } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const DisclaimerWrapper = styled.div<DisclaimerProps>`
  flex-shrink: 0;
  z-index: 0;
  position: ${({ floatingDisclaimer }) => (floatingDisclaimer ? 'absolute' : 'relative')};
  right: 0;
  bottom: 0;
  left: 0;
  padding: ${pxToRem(20)} ${pxToRem(24)} ${pxToRem(44)};
  text-align: center;
  color: ${({ theme, disclaimerColor }) => theme.colors[disclaimerColor || 'primaryText']};
  background: ${({ theme, floatingDisclaimer }) =>
    floatingDisclaimer ? 'transparent' : theme.colors.white};
  ${tablet(`
    padding: ${pxToRem(20)} ${clampValues(18, 22)} ${pxToRem(30)};
    position: fixed;
  `)};
  ${({ withShadow, floatingDisclaimer }) =>
    withShadow &&
    !floatingDisclaimer &&
    `
      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: ${pxToRem(-32)};
        left: 0;
        display: block;
        height: ${pxToRem(35)};
        background-image: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
      }`}
`

export const DisclaimerNPSWrapper = styled(DisclaimerWrapper)`
  ${landscape(
    Device.tablet,
    `
    padding-bottom: ${clampValuesLandscape(18, 28)};
  `
  )}
`

export const DisclaimerText = styled(Label)`
  display: block;
  color: inherit;
`
