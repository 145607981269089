import ConditionalRender from 'components/ConditionalRender'
import { ListTypography } from 'components/Typography'
import { landscape } from 'libs/media'
import { pxToRem } from 'libs/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export interface BadgeChipsProps {
  badges: string[]
  badgesToShow?: string[]
  className?: string
}

const Chips = styled.ul`
  list-style: none;
  margin: ${pxToRem(-4)};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${landscape('justify-content: initial;')}
`

export const BadgeChip = styled(ListTypography)`
  border: 1px solid;
  border-radius: ${pxToRem(100)};
  padding: ${pxToRem(4)} ${pxToRem(16)};
  margin: ${pxToRem(4)};
`

// badges filtering and sorting is on the bff side
export const BadgeChips: React.FC<BadgeChipsProps> = ({
  badges = [],
  badgesToShow = [],
  className,
}) => {
  const { t } = useTranslation()
  const showBadge = (badge: string) => {
    return <BadgeChip key={badge}>{t(`ProductView.badges.${badge}`)}</BadgeChip>
  }
  return (
    <ConditionalRender condition={!!badges.length}>
      <Chips className={className}>
        {badgesToShow.length > 0
          ? badges
              .filter(badge => badgesToShow.includes(badge))
              ?.map(badge => !!badge && showBadge(badge))
          : badges.map(badge => !!badge && showBadge(badge))}
      </Chips>
    </ConditionalRender>
  )
}
