import { checkIsElectronicsProduct, getProductColorTitle } from 'libs/product'
import { useGetProductBrandName } from 'libs/utils'
import React from 'react'
import { ProductPriceType } from 'types/price'
import { CustomProduct } from 'types/product'
import { CardBodyColumn, ColorTitle, ProductBrand, ProductModel } from './ProductCard.style'
import { ProductColors } from './ProductColors'
import { ProductPrice } from './ProductPrice'

interface CardProductInfoProps {
  product: CustomProduct
  showColor?: boolean
  showProductBrandName?: boolean
  showProductModel?: boolean
  showColorsAvailable?: boolean
  shouldRenderPrice?: boolean
  customPrice?: React.ReactElement
  priceStyle?: ProductPriceType
  highlightModelCode?: boolean
  className?: string
}

const CardProductInfo: React.FC<CardProductInfoProps> = ({
  product,
  showColor,
  showProductBrandName,
  showProductModel,
  shouldRenderPrice,
  showColorsAvailable,
  customPrice,
  priceStyle = 'default',
  highlightModelCode = false,
  className,
}) => {
  const { colorsAvailable, name, model } = product

  const getProductBrandName = useGetProductBrandName()
  const brandName = getProductBrandName(product.originalBrand)
  const isStellaProduct = checkIsElectronicsProduct(product)
  const modelName = isStellaProduct ? name : model.startsWith('0') ? model.substring(1) : model

  const productModel = isStellaProduct
    ? [modelName].filter(Boolean)
    : [modelName, name].filter(Boolean).join(' ')

  const availableColors = (product.variants && product.variants.length + 1) || 0

  return (
    <>
      <CardBodyColumn>
        {showColor && <ColorTitle>{getProductColorTitle(product)}</ColorTitle>}
        {showProductBrandName && (
          <ProductBrand data-test="text-brand" aria-label={brandName} aria-description={brandName}>
            {brandName}
          </ProductBrand>
        )}
        {showProductModel && (
          <ProductModel
            data-test="context-modelName"
            highlight={highlightModelCode}
            aria-label={modelName}
            aria-description={modelName}
          >
            {productModel}
          </ProductModel>
        )}
      </CardBodyColumn>
      <CardBodyColumn>
        {shouldRenderPrice &&
          (customPrice || (
            <ProductPrice product={product} priceStyle={priceStyle} className={className} />
          ))}
        {showColorsAvailable && (
          <ProductColors availableColors={colorsAvailable ? colorsAvailable : availableColors} />
        )}
      </CardBodyColumn>
    </>
  )
}

export default CardProductInfo
