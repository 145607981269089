export type FormatPriceArguments = {
  price: number
  currency: string
  defaultLangCode: string
  fractionDigits?: number
}

// This option is not included in the API TS definition but it's needed and it work
type FullNumberFormatOptions = Intl.NumberFormatOptions & { numberingSystem: string }

const getFormatPriceOptions = ({ currency, fractionDigits }): FullNumberFormatOptions => ({
  // Passing these params as optionals, means that the format style will be decimal instead of currency.
  // This avoid breaking the Intl API when a currency is not valid.
  ...(currency && { currency, style: 'currency', currencyDisplay: 'symbol' }),
  minimumFractionDigits: currenciesWithoutPrecision.includes(currency) ? 0 : fractionDigits,
  numberingSystem: 'latn', // This ensures Latin numerals are used
})

/**
 * Format price function.
 */
const formatPrice = ({
  price,
  currency,
  defaultLangCode,
  fractionDigits = 2,
}: FormatPriceArguments): string => {
  const options: FullNumberFormatOptions = getFormatPriceOptions({ currency, fractionDigits })

  //Using defaultLangCode as locale since it reflect the countryId
  const formattedPrice = Intl.NumberFormat(defaultLangCode, options)
    .formatToParts(price)
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
          // We overwrite the currency symbol
          return currencySymbols[currency] ?? value
        default:
          return value
      }
    })
    .join('')

  return formattedPrice
}

export default formatPrice

const currenciesWithoutPrecision = ['JPY', 'HUF']
const currenciesCommaThousandSeparator = ['JPY']
const currenciesCommaDecimalSeparator = ['AED']
const currencySymbols: Record<string, string> = {
  SGD: 'S$',
  AED: 'DH',
  JPY: '¥',
}

const adjustSeparators = (formattedParts: Intl.NumberFormatPart[], currency: string): string => {
  return formattedParts
    .map(({ type, value }) => {
      if (type === 'group' && currenciesCommaThousandSeparator.includes(currency)) {
        // Replace group (thousand) separator with a comma if needed
        return ','
      }
      if (type === 'decimal' && currenciesCommaDecimalSeparator.includes(currency)) {
        // Replace decimal separator with a period if needed
        return '.'
      }
      return value
    })
    .join('')
}

export const formatProductPrice = (
  price: number,
  currency: string,
  defaultLangCode: string,
  fractionDigits = 2
): string => {
  const options: Intl.NumberFormatOptions = getFormatPriceOptions({ currency, fractionDigits })

  const formattedParts = new Intl.NumberFormat(defaultLangCode, options).formatToParts(price)

  // Adjust separators for thousand and decimal if needed
  let adjustedPrice = adjustSeparators(formattedParts, currency)

  adjustedPrice = formattedParts
    .map(({ type, value }) => {
      if (type === 'currency') {
        return currencySymbols[currency] ?? value
      }
      return value
    })
    .join('')

  return adjustedPrice
}
