import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ActionCreatorsMapObject, AnyAction, bindActionCreators } from 'redux'

export function useActions<A, M extends ActionCreatorsMapObject<A>>(actions: M) {
  const dispatch = useDispatch()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const actionsObject = useMemo(() => actions, [])

  return useMemo(() => bindActionCreators(actionsObject, dispatch), [dispatch, actionsObject])
}

export function useAction<A extends AnyAction>(action: A) {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(action), [dispatch, action])
}
