import ConditionalRender from 'components/ConditionalRender'
import { BackIcon, CloseIcon } from 'components/core/Icons'
import { ListItem, ListItemAction } from 'components/core/List'
import { clampValues, pxToRem } from 'libs/styled'
import { isIPadView } from 'libs/utils'
import React, { ReactElement } from 'react'

interface PanelHeaderProps {
  onClose: React.EventHandler<React.MouseEvent>
  dataCalls?: string
  closeIcon?: ReactElement<unknown>
  showBackButton?: boolean
  showCloseButton?: boolean
  onBack?: React.EventHandler<React.MouseEvent>
  backIcon?: ReactElement<unknown>
  dataAttr?: {
    test?: string
  }
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({
  children,
  onClose,
  dataCalls = '0',
  closeIcon,
  showBackButton = false,
  showCloseButton = true,
  onBack,
  backIcon,
  dataAttr,
}) => {
  const iconSize = isIPadView() ? clampValues(20, 27) : pxToRem(34)

  return (
    <ListItem>
      {children}{' '}
      <ListItemAction>
        <ConditionalRender condition={showBackButton && !!onBack}>
          <button
            className="panelHeaderBackButton"
            onClick={onBack}
            data-analytics_available_call={dataCalls}
          >
            {backIcon || <BackIcon height={iconSize} width={iconSize} />}
          </button>
        </ConditionalRender>
        <ConditionalRender condition={showCloseButton}>
          <button
            className="panelHeaderCloseButton"
            onClick={onClose}
            data-analytics_available_call={dataCalls}
            data-test={dataAttr?.test}
          >
            {closeIcon || <CloseIcon height={iconSize} width={iconSize} />}
          </button>
        </ConditionalRender>
      </ListItemAction>
    </ListItem>
  )
}
