import React from 'react'
import { IconProps } from 'types/icons'

export const DigitalCouvetteIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#333',
  stroke = '#333',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1592 10.663L13.509 10.8285L13.6634 10.4736C14.2418 9.14382 15.7668 8.375 18.5 8.375C20.2894 8.375 21.5715 8.70676 22.398 9.30785C23.2029 9.89321 23.625 10.7703 23.625 12C23.625 13.6177 23.1241 14.89 22.257 15.757C21.39 16.6241 20.1177 17.125 18.5 17.125C15.6746 17.125 13.375 14.8254 13.375 12C13.375 11.7788 13.3941 11.5683 13.4234 11.3596L13.4577 11.1149L13.2464 10.9868C12.4944 10.5309 11.5056 10.5309 10.7536 10.9868L10.5423 11.1149L10.5766 11.3596C10.6059 11.5683 10.625 11.7788 10.625 12C10.625 14.8254 8.32539 17.125 5.5 17.125C3.88226 17.125 2.61004 16.6241 1.74298 15.757C0.875911 14.89 0.375 13.6177 0.375 12C0.375 10.7703 0.797065 9.89321 1.60199 9.30785C2.42854 8.70676 3.71058 8.375 5.5 8.375C8.23318 8.375 9.75817 9.14382 10.3366 10.4736L10.491 10.8285L10.8409 10.663C11.5623 10.3215 12.4371 10.3215 13.1592 10.663ZM23 3.375H23.375V3V2.375H23.625V4.625H23.375V4V3.625H23H1H0.625V4V4.625H0.375V2.375H0.625V3V3.375H1H23ZM0.625 12C0.625 12.705 0.737068 13.9158 1.41192 14.9608C2.10496 16.034 3.35471 16.875 5.5 16.875C8.18811 16.875 10.375 14.6881 10.375 12C10.375 11.2615 10.2405 10.381 9.49937 9.70014C8.77055 9.03066 7.53047 8.625 5.5 8.625C3.46953 8.625 2.22945 9.03066 1.50063 9.70014C0.759476 10.381 0.625 11.2615 0.625 12ZM13.625 12C13.625 14.6881 15.8119 16.875 18.5 16.875C20.6453 16.875 21.895 16.034 22.5881 14.9608C23.2629 13.9158 23.375 12.705 23.375 12C23.375 11.2615 23.2405 10.381 22.4994 9.70014C21.7705 9.03066 20.5305 8.625 18.5 8.625C16.4695 8.625 15.2294 9.03066 14.5006 9.70014C13.7595 10.381 13.625 11.2615 13.625 12Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.75"
      />
    </svg>
  )
}
