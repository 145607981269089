import React from 'react'
import { IconProps } from 'types/icons'
export const FrameShapeIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 23"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M21.2007 9.20367C20.0593 7.88167 19.1607 6.26567 18.7147 4.59701C18.5733 3.99501 18.6813 3.52234 19.0547 3.10967C19.8727 2.20568 21.9067 1.68834 24.3933 1.66634H24.6667V1.66567C25.498 1.67034 26.374 1.72634 27.2713 1.84501C29.3787 2.16367 30.1547 3.97101 30.43 5.02034C30.9113 6.85501 30.6153 8.93767 29.7707 10.407C30.104 10.731 30.406 11.0857 30.6733 11.467C31.8827 9.67101 32.3367 7.03567 31.72 4.68234C31.11 2.35767 29.5613 0.843008 27.458 0.525674C26.5873 0.411008 25.6313 0.342341 24.6667 0.338341V0.333008H7.33333V0.337674C6.36466 0.342341 5.40533 0.411008 4.52933 0.527008C2.43866 0.843008 0.889992 2.35767 0.279992 4.68234C-0.506007 7.67901 0.439326 11.1397 2.47799 12.731C3.27066 13.3497 4.22866 13.663 5.27533 13.663C5.89933 13.663 6.55399 13.5517 7.22466 13.3263C10.288 12.2983 13.208 9.10101 14.384 5.55834C15.314 4.85434 16.6833 4.85367 17.614 5.55767C18.1367 7.13901 19.0133 8.65301 20.0953 9.93767C20.4407 9.66234 20.8087 9.41434 21.2007 9.20367ZM13.29 4.57767C12.388 7.95501 9.65932 11.1037 6.80132 12.063C5.84066 12.385 4.45466 12.5797 3.29933 11.6803C1.68199 10.417 0.922659 7.49234 1.57066 5.02034C1.84599 3.97101 2.62199 2.16367 4.71733 1.84701C5.61933 1.72834 6.49999 1.66967 7.33399 1.66501V1.66634H7.63399C10.1113 1.69167 12.1313 2.20967 12.946 3.10967C13.3187 3.52234 13.4267 3.99501 13.29 4.57767ZM17.3373 3.90234C16.9113 3.75101 16.46 3.66634 16 3.66634C15.54 3.66634 15.09 3.75101 14.664 3.90234C14.6173 3.27501 14.3747 2.70234 13.934 2.21567C13.75 2.01234 13.5313 1.83301 13.2927 1.66701H18.706C18.468 1.83301 18.2487 2.01234 18.0647 2.21567C17.6267 2.70034 17.384 3.27167 17.3373 3.90234Z"
        fill={fill}
      />
      <path
        d="M30.6667 15.6667H29.4633C29.4273 13.0833 27.3253 11 24.7333 11C22.1193 11 20 13.1193 20 15.7333C20 18.3253 22.0833 20.4273 24.6667 20.4633V21.6667C24.6667 22.4033 25.2633 23 26 23H30.6667C31.4033 23 32 22.4033 32 21.6667V17C32 16.2633 31.4033 15.6667 30.6667 15.6667ZM24.6667 17V19.1267C22.8233 19.09 21.3333 17.5853 21.3333 15.7333C21.3333 13.8587 22.8587 12.3333 24.7333 12.3333C26.5853 12.3333 28.09 13.8233 28.1267 15.6667H26C25.2633 15.6667 24.6667 16.2633 24.6667 17ZM30.6667 21.6667H26V17H30.6667V21.6667Z"
        fill={fill}
      />
    </svg>
  )
}
