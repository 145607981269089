import { getWithFallback } from 'libs/utils'
import { shallowEqual, useSelector } from 'react-redux'

export const DEFAULT_LANG = 'en-US'

export interface StoreIdentity {
  storeType: string
  storeId: string
  langCode: string
  basePath: string
}

export const useStoreIndentity = () => {
  return useSelector(state => {
    const [, urlStoreType, urlStoreId, urlLangCode] = state.router.location.pathname.split('/')

    const storeType = getWithFallback(urlStoreType, '')
    const storeId = getWithFallback(urlStoreId, '')
    const langCode = getWithFallback(urlLangCode, '')

    return {
      storeType,
      storeId,
      langCode,
      basePath: `/${storeType}/${storeId}/${langCode}`,
    }
  }, shallowEqual)
}
