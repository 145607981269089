import config from 'config'
import debug from 'debug'

import { localStorageKeyPrefix } from '../config/config.base'
import { ResponseError } from '../data'
import store from '../store'

const BASE = `${localStorageKeyPrefix}-app`
const COLOURS = {
  trace: 'lightblue',
  info: 'blue',
  warn: 'orange',
  error: 'red',
}

type ErrorMessages = Error | ResponseError | string

/** Generate a debug message */
const generateMessage = (level: string, message: ErrorMessages, source: string): void => {
  // Set the prefix which will cause debug to enable the message
  const namespace = `${BASE}:${level}`
  const createDebug = debug(namespace)

  // send log to api server for level > of trace...
  if (
    ['trace', 'info'].indexOf(level) === -1 &&
    config.log2api &&
    config.logServerUrl &&
    message instanceof Error
  ) {
    const state = store.getState()
    const msg = message && message.message ? message.message : message

    const body = JSON.stringify({
      level,
      // Message sometime is an error object with message attribute
      msg: `React app - ${source} - ${msg} - ${window.location.href}`,
    })
    const headers = {
      'api-session-id': state.session.token,
      'Content-Type': 'application/json',
    }

    fetch(config.logServerUrl, {
      method: 'POST',
      body,
      headers,
    })
  }

  if (createDebug.enabled) {
    // Set the colour of the message based on the level
    createDebug.color = COLOURS[level]

    if (source) {
      createDebug(source, message)
    } else {
      createDebug(message)
    }
  }
}

/**
 * This class is used to log messages to browser console
 * and to backend service... (TODO).
 *
 * How to use:
 *  import Log from './libs/Log'
 *  Log.info('AAA', 'app.js');
 *
 * To enable logging is necessary to add an entry 'debug'
 * on browser's localStorage with this value:
 *
 *  digital-wall-app:<level|*>
 *
 * Level should be a value such as: trace | info | warn | error | *
 * in order to enable a specific logging level.
 *
 * further info: https://levelup.gitconnected.com/step-up-your-console-messaging-game-in-your-react-app-42eee17659ec
 */
class Log {
  static trace(message: ErrorMessages, source?: string) {
    return generateMessage('trace', message, source)
  }

  static info(message: ErrorMessages, source?: string) {
    return generateMessage('info', message, source)
  }

  static warn(message: ErrorMessages, source?: string) {
    return generateMessage('warn', message, source)
  }

  static error(message: ErrorMessages, source?: string) {
    return generateMessage('error', message, source)
  }
}

export default Log
