import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'
import { useEffect } from 'react'

export const useSendBarcodePrizmAnalyticsEvent = (shouldSendEvent: boolean) => {
  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    Page_Section2: 'Barcode:Prizm',
  })

  const sendCloseModalEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: 'BarcodeResultPrizm_Close',
  })

  const sendDiscoverEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: 'BarcodeResultPrizm_Discover',
  })

  useEffect(() => {
    if (shouldSendEvent) {
      sendImpressionEvent()
    }
  }, [shouldSendEvent, sendImpressionEvent])

  return {
    sendCloseModalEvent,
    sendDiscoverEvent,
  }
}
