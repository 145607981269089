import { BarcodeScanModalWithRedirectProps } from 'components/BarCodeScanModal/types'
import { PrimaryButton } from 'components/core/Button'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { isOOBrand } from 'libs/custom'
import { getCustomizableBrand } from 'libs/customProducts'
import { getCustomProductRoute } from 'libs/routing'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BarcodeScanModalBase } from '../BarcodeScanModalBase'
import { useSendCloseModalEvent, useSendImpressionEvent } from './analytics'

export const BarcodeScanModalOnlyCustom = ({
  visible,
  onCancel,
  onRedirect,
  productInfo,
}: BarcodeScanModalWithRedirectProps) => {
  const { t } = useTranslation()
  const { basePath } = useStoreIndentity()
  const { originalBrand, model, UPC, thumbnailUrl } = productInfo
  const formattedBrand = getCustomizableBrand(originalBrand)

  const isOakley = isOOBrand(formattedBrand || originalBrand)

  const subtitle = t(
    isOakley
      ? 'BarCodeScanModal.oakleyOnlyCustomSubtitle'
      : 'BarCodeScanModal.raybanOnlyCustomSubtitle'
  )
  const title = t('BarCodeScanModal.onlyCustomTitle')
  const description = t(
    isOakley
      ? 'BarCodeScanModal.oakleyDescriptionOnlyCustom'
      : 'BarCodeScanModal.raybanDescriptionOnlyCustom'
  )
  const sendCloseModalEvent = useSendCloseModalEvent()

  const handleOnCancel = useCallback(() => {
    sendCloseModalEvent()
    onCancel()
  }, [onCancel, sendCloseModalEvent])

  const url = getCustomProductRoute({ basePath, brand: formattedBrand, model, UPC })
  const shouldSendEvent = Boolean(visible)

  useSendImpressionEvent(shouldSendEvent)

  return (
    <BarcodeScanModalBase
      visible={visible}
      subtitle={subtitle}
      title={title}
      description={description}
      customizable={true}
      formattedBrand={formattedBrand}
      thumbnail={thumbnailUrl}
      onCancel={handleOnCancel}
      footerContent={[
        <PrimaryButton key="customize" onClick={() => onRedirect(url)}>
          {t('BarCodeScanModal.confirmBtn')}
        </PrimaryButton>,
      ]}
    />
  )
}
