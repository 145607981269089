import React from 'react'
import { IconProps } from 'types/icons'

export const SearchIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#333',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      role="button"
      aria-label="searchIcon"
    >
      <path
        d="M23.8535 23.1465L17.4075 16.7005C19.0135 14.9265 20 12.5815 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C12.5815 20 14.9265 19.0135 16.7005 17.4075L23.1465 23.8535L23.8535 23.1465ZM10 19C5.0375 19 1 14.9625 1 10C1 5.0375 5.0375 1 10 1C14.9625 1 19 5.0375 19 10C19 14.9625 14.9625 19 10 19Z"
        fill={fill}
      />
    </svg>
  )
}
