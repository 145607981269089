import classnames from 'classnames'
import React from 'react'
import { ProgressBarPlaylistItemProps, ProgressBarProps } from 'types/progressBar'
import { Progress, ProgressContainer } from './styles'

const DEFAULT_DURATION = 8 // time in seconds

export const ProgressBar: React.FC<ProgressBarProps> = ({
  currentSlide,
  playlist,
  animationTime = DEFAULT_DURATION,
  isProgressPaused,
  nextPlay,
  disableAnimation = false,
  className,
  activeColor,
  backgroundColor,
  renderPrevButton,
  renderNextButton,
  onProgressClick,
  dataCalls = {},
}) => {
  const isPassedSlide = (index: number) => {
    if (disableAnimation) return currentSlide === index
    return currentSlide > index
  }

  const getAnimationDuration = (slide: string | ProgressBarPlaylistItemProps) => {
    if (disableAnimation || typeof slide === 'string') return
    return slide?.duration ? `${slide.duration}s` : `${animationTime}s`
  }

  return (
    <ProgressContainer className={className} {...dataCalls}>
      {renderPrevButton && renderPrevButton()}
      {playlist.map((slide, index: number) => (
        <Progress
          key={index}
          active={disableAnimation ? false : currentSlide === index}
          passed={isPassedSlide(index)}
          animationTime={getAnimationDuration(slide)}
          isProgressPaused={isProgressPaused || disableAnimation}
          onAnimationEnd={nextPlay}
          {...(onProgressClick ? { onClick: () => onProgressClick(index) } : {})}
          className={classnames({
            active: isPassedSlide(index),
          })}
          activeColor={activeColor}
          backgroundColor={backgroundColor}
        />
      ))}
      {renderNextButton && renderNextButton()}
    </ProgressContainer>
  )
}
