import React from 'react'
import { IconProps } from 'types/icons'

export const CloseIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#555',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22.431 22.431"
      aria-label="closeIcon"
      role="button"
    >
      <path
        fill={fill}
        d="M22.069 20.319l-9.104-9.104 9.104-9.104a1.237 1.237 0 10-1.75-1.749l-9.104 9.104L2.111.362a1.237 1.237 0 10-1.749 1.75l9.104 9.103-9.104 9.104a1.238 1.238 0 001.75 1.75l9.103-9.104 9.104 9.104a1.238 1.238 0 001.75-1.75z"
      ></path>
    </svg>
  )
}
