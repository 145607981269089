import { useCartRecommendedAccessories } from 'data'
import React, { FC } from 'react'
import { CartMerchRecommenderAccordion } from './CartMerchRecommenderAccordion'
import { CartMerchRecommenderProps } from './types'

const CartMerchRecommender: FC<CartMerchRecommenderProps> = ({ cartItems }) => {
  const accessoriesData = useCartRecommendedAccessories(cartItems.map(c => c.product))

  const products = accessoriesData.data?.cartRecommendedAccessories.products
  const showRecommender = products && products.length > 0

  return showRecommender ? <CartMerchRecommenderAccordion products={products} /> : null
}

export default CartMerchRecommender
