import styled, { keyframes } from 'styled-components'

const Loader = keyframes`
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
`

export const Progress = styled.div<{
  active?: boolean
  passed: boolean
  animationTime?: string
  isProgressPaused?: boolean
  activeColor?: string
  backgroundColor?: string
}>`
  margin: 0 0.25rem;
  display: flex;
  flex-grow: 1;
  height: 5px;
  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#666'};
  background-image: ${({
    activeColor = 'rgba(255, 255, 255, 1)',
    backgroundColor = 'rgba(88, 89, 104, 1)',
  }) =>
    `linear-gradient(to right,${activeColor} 0%, ${activeColor} 50%, ${backgroundColor} 50.001%, ${backgroundColor} 100%)`};
  background-repeat: no-repeat;
  background-position: ${({ passed }) => (passed ? '0 0' : '100% 50%')};
  background-size: 200%;
  animation-play-state: ${({ isProgressPaused }) => isProgressPaused && 'paused'};
  animation-name: ${({ active }) => (active ? Loader : '')};
  animation-duration: ${({ animationTime }) => animationTime && animationTime};
  animation-timing-function: linear;
  animation-fill-mode: forwards;
`

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 1;
  width: 50%;
  max-width: 100%;
  position: relative;
`
