import { FilterOption, FilterInput, FilterFacet } from 'types/filter'

/**
 * Check is input value is UPC
 * @param upc
 * @returns {boolean}
 */
export const isUPC = (upc: string): boolean => {
  const match = upc.match(/^[0-9]{8,13}$/)
  return match ? true : false
}

/**
 * Returns a string query with the product type value
 * @returns string value
 */

export const addProductTypeToQuery = (query: string, filter: FilterInput): string => {
  return `${query}&${filter.name}=${filter.value}`
}

const NUMBER_OF_PREVIEW_ITEMS = 4
const NUMBER_NO_PRODUCT_TYPE_ITEMS = 1 // TODO: could we do it better?

export const getSearchDataItemsLimit = (
  query: string,
  productTypeFilter?: FilterInput
): number | undefined => {
  if (!productTypeFilter) return NUMBER_NO_PRODUCT_TYPE_ITEMS
  if (isUPC(query)) return undefined
  return NUMBER_OF_PREVIEW_ITEMS
}

export const getFilterFacet = (filterOption: FilterOption): FilterFacet => ({
  name: filterOption.name,
  value: filterOption.value,
  label: filterOption.label,
  analyticsTag: filterOption.analyticsTag,
  count: filterOption.count,
})
