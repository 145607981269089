import React from 'react'
import { IconProps } from 'types/icons'

export const FrameMeasureIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <g clipPath="url(#clip0_1668_157591)">
        <path
          d="M12.334 0C7.92298 0 4.33398 3.589 4.33398 8C4.33398 12.411 7.92298 16 12.334 16C16.745 16 20.334 12.411 20.334 8C20.334 3.589 16.745 0 12.334 0ZM12.334 15.3333C8.29032 15.3333 5.00065 12.0437 5.00065 8C5.00065 3.95633 8.29032 0.666667 12.334 0.666667C16.3777 0.666667 19.6673 3.95633 19.6673 8C19.6673 12.0437 16.3777 15.3333 12.334 15.3333Z"
          fill={fill}
        />
        <path
          d="M11.1473 10.1953L8.06732 7.11533L7.59598 7.58667L11.1467 11.138L17.0617 5.236L16.5907 4.764L11.1473 10.1953Z"
          fill={fill}
        />
      </g>
      <path
        d="M4.68848 23.8074L2.95366 22.0719H21.7143L19.9795 23.8074L20.9472 24.775L24.334 21.3875L20.9472 18L19.9795 18.9677L21.7143 20.7032H2.95366L4.68848 18.9677L3.72081 18L0.333984 21.3875L3.72081 24.775L4.68848 23.8074Z"
        fill={fill}
      />
      <defs>
        <clipPath id="clip0_1668_157591">
          <rect width={width} height={height} fill={fill} transform="translate(4.33398)" />
        </clipPath>
      </defs>
    </svg>
  )
}
