import config from 'config'
import { useEffect, useRef } from 'react'
import Log from './Log'

import { getAuthToken } from './auth'

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null && delay > 0) {
      let id = setInterval(tick, delay)
      return () => {
        clearInterval(id)
      }
    }
  }, [delay])
}

export const emitSignal = (): void => {
  const fetchConfig = {
    method: 'PUT',
    headers: {
      Authorization: `bearer: ${getAuthToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userAgent: navigator.userAgent,
    }),
  }

  fetch(`${config.middleware.URL}/signal`, fetchConfig).catch(err => {
    Log.info(err, 'Signal')
  })
}
