import { hideAllDrawer, toggleLanguageSelectorList } from 'actions/ui'
import { CaretDownIcon } from 'components/core/Icons'
import { useAction } from 'hooks/useActions'
import { useClickOutside } from 'hooks/useClickOutside'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { useGetI18n } from 'providers/translationProvider'
import React, { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DefaultTheme, ThemeContext } from 'styled-components'
import { Language } from 'types/graphqlTypes'
import {
  CurrentLanguage,
  LanguageSelectorContainer,
  LanguageSelectorList,
  LanguageSelectorListItem,
  LanguageSelectorTitle,
  LanguageSelectorToggleButton,
} from './styles'

interface LanguageSelectorProps {
  languages: Language[]
  hideTitle?: boolean
  useLangFromUrl?: boolean
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  languages,
  hideTitle = false,
  useLangFromUrl = true,
}) => {
  const { t } = useTranslation()
  const wrapper = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const i18n = useGetI18n()
  const theme: DefaultTheme = useContext(ThemeContext)
  const isLanguageListVisible = useSelector(({ ui }) => {
    return ui.languageSelectorIsVisible
  })
  const { langCode, storeType, storeId } = useStoreIndentity()
  const hideAll = useAction(hideAllDrawer)
  const toggleLanguageSelector = useAction(toggleLanguageSelectorList)
  const currentLang = useLangFromUrl ? langCode.split('-')[0] : i18n.language.split('-')[0]
  const lng = useLangFromUrl ? langCode : i18n.language

  useClickOutside(wrapper, toggleLanguageSelector, isLanguageListVisible)

  const changeLanguage = (lang: string) => {
    hideAll()
    i18n.changeLanguage(lang)
    if (useLangFromUrl) {
      history.push(`/${storeType}/${storeId}/${lang}`)
    }
  }

  return (
    <div ref={wrapper}>
      <LanguageSelectorContainer>
        <LanguageSelectorTitle onClick={toggleLanguageSelector}>
          {!hideTitle && (
            <>
              {t('Core.chooseLanguage')}
              {':'}
            </>
          )}
          <CurrentLanguage>{currentLang.toUpperCase()}</CurrentLanguage>
          <LanguageSelectorToggleButton className={isLanguageListVisible ? 'rotate' : ''}>
            <CaretDownIcon stroke={theme.menu.textColor} />
          </LanguageSelectorToggleButton>
        </LanguageSelectorTitle>
        {isLanguageListVisible && (
          <LanguageSelectorList>
            {languages.map(({ isoCode, label }, i) => {
              return (
                <li key={i}>
                  <LanguageSelectorListItem
                    data-element-id="MainNav_MenuLang"
                    onClick={() => changeLanguage(isoCode)}
                    className={lng == isoCode ? 'active' : ''}
                  >
                    {t(`LanguageSel.${label}`)}
                  </LanguageSelectorListItem>
                </li>
              )
            })}
          </LanguageSelectorList>
        )}
      </LanguageSelectorContainer>
    </div>
  )
}
