import React, { FC, useEffect } from 'react'
import CheckmarkRoundLight from 'components/core/Icons/CheckmarkRoundLight'
import { pxToRem } from 'libs/styled'
import { Heading1, Heading3 } from 'components/Typography'
import { CalibrationCompletedStyled, CalibrationContinueBtn } from '../styles'
import { useTranslation } from 'react-i18next'
import { useDigitalCouvetteAnalytics } from '../../analytics'

interface Props {
  onContinue: () => void
}

const CalibrationCompleted: FC<Props> = ({ onContinue }) => {
  const { t } = useTranslation()
  const { onCalibrationCompletePageView } = useDigitalCouvetteAnalytics()
  useEffect(() => {
    onCalibrationCompletePageView()
  }, [onCalibrationCompletePageView])
  return (
    <CalibrationCompletedStyled>
      <CheckmarkRoundLight width={pxToRem(80)} height={pxToRem(80)} />
      <Heading1>{t('DigitalCouvette.calibrationComplete.title1')}</Heading1>
      <Heading3>{t('DigitalCouvette.calibrationComplete.title2')}</Heading3>
      <CalibrationContinueBtn onClick={onContinue}>
        {t('DigitalCouvette.calibrationComplete.continue')}
      </CalibrationContinueBtn>
    </CalibrationCompletedStyled>
  )
}

export default CalibrationCompleted
