import ErrorWidget from 'components/ErrorWidget'
import Loading from 'components/Loading'
import config from 'config'
import { setADB2CToken } from 'libs/auth'
import { authenticate, getTokenFromUrl, redirectToLoginPage } from 'libs/routing'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

export interface ParsedToken {
  SSWSToken: string
  acr: string
  aud: string
  auth_time: number
  device: string
  deviceType: string
  LDAP_migrated: boolean
  exp: number
  iat: number
  iss: string
  nbf: number
  nonce: string
  signInName: string
  sub: string
  tid: string
  ver: string
}

export interface ReplyParsedToken {
  user_name: string
  exp: number
  deviceId: string
  doorId: string
  countryId: string
  client_id: string
  isMigrated?: boolean
}

const LoginRedirect: React.FC<RouteComponentProps> = props => {
  const { location } = props
  const { token } = getTokenFromUrl(location)

  if (token) {
    try {
      const adb2cParsedToken: ParsedToken = JSON.parse(atob(token.split('.')[1]))
      const replyParsedToken: ReplyParsedToken = JSON.parse(
        atob(adb2cParsedToken.SSWSToken.split('.')[1])
      )
      if (config.saveADB2CTokenToLocalStorage) {
        setADB2CToken(token)
      }
      authenticate(adb2cParsedToken.SSWSToken, `${replyParsedToken.isMigrated || false}`)
    } catch (e) {
      return (
        <ErrorWidget
          errors={[Error('The token is not valid. Please try with another one')]}
          type="400 invalid request"
          actionLabel="Try to login again"
          action={() => redirectToLoginPage()}
        />
      )
    }
  } else {
    return (
      <ErrorWidget
        errors={[Error('No token provided. You need a token to login')]}
        type="400 invalid request"
        actionLabel="Try to login again"
        action={() => redirectToLoginPage()}
      />
    )
  }

  return <Loading />
}

export default LoginRedirect
