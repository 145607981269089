import { Heading3, Heading4 } from 'components/Typography'
import { List } from 'components/core/List'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, pxToRem } from 'libs/styled'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${tablet(`
      padding-bottom: 3.532rem;
  `)}
  ${tablet(
    Device.ipadMini,
    `
    padding-bottom: 3.532rem;
  `
  )}

${landscape(
    Device.tablet,
    `
    padding-bottom: 0;
  `
  )}
`

export const HeadMenuList = styled.ul`
  margin-bottom: 3.773rem;
  background: ${({ theme }) => theme.menu.backgroundColor};
`
export const HeadMenuItem = styled.li`
  padding: 1.045rem 0;
  display: flex;
  align-items: center;
`

export const MenuTitle = styled(Heading3)<{ isTopLevel: boolean }>`
  margin-top: ${pxToRem(64)};
  margin-bottom: 0;
  padding: ${({ isTopLevel }) =>
    `0 ${pxToRem(isTopLevel ? 50 : 96)} ${pxToRem(isTopLevel ? 0 : 16)}`};
  ${({ isTopLevel }) =>
    tablet(`
    margin-top: ${pxToRem(32)};
    padding: 0 ${isTopLevel ? pxToRem(58) : clampValues(54, 72)} 0;
  `)};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.menu.textColor};
`

export const MenuItemTitle = styled(Heading4)`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.menu.textColor};
`

export const MenuList = styled(List)<{ isTopLevel: boolean }>`
  padding-left: ${({ isTopLevel }) => pxToRem(isTopLevel ? 96 : 144)};
  ${tablet(`
    padding-left: ${clampValues(72, 96)};
  `)}
`

export const MenuItem = styled.li`
  padding-top: ${pxToRem(72)};

  &:first-of-type {
    padding-top: ${pxToRem(37)};
  }

  &:last-of-type {
    margin-bottom: 25rem;
  }
  ${landscape(`&:last-of-type {
    margin-bottom: ${pxToRem(80)};
  }`)}
  ${tablet(`
    padding-top: ${pxToRem(48)};

    &:first-of-type {
      padding-top: ${pxToRem(32)};
    }

    &:last-of-type {
      margin-bottom: ${pxToRem(80)}
    }
  `)}
`

export const SecondaryMenuItemLink = styled(Link)`
  margin-left: auto;
  padding-right: 2.137rem;
  font-size: 0.9545rem;
  color: ${({ theme }) => theme.colors.secondaryText};
  text-decoration: underline;
`

export const MenuItemAction = styled.button`
  margin: 0;
  padding: 0 4.545rem;
  border: 0;
  outline: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.364rem;
  font-weight: 300;
  color: ${({ theme }) => theme.menu.textColor};
  background: inherit;

  &:active {
    color: black;
  }
`

export const MenuPanelHeader = styled.div<{ isLangSelectorVisible: boolean; isTopLevel: boolean }>`
  ${({ theme, isLangSelectorVisible, isTopLevel }) =>
    isTopLevel && !isLangSelectorVisible && `background: ${theme.menu.backgroundColor}`};
  position: relative;
  padding: 0 1.455rem;
  padding-bottom: 2.136rem;
  padding-top: 17.7vh;
  ${tablet(`
    padding-top: 3.532rem;
  `)}
  ${tablet(
    Device.ipadMini,
    `
    padding-top: 3.532rem;
  `
  )}
`

export const ScrollingContent = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  // disable ios elastic scroll on IOS
  overscroll-behavior-y: none;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.636rem;
  width: 2.273rem;
  height: 2.273rem;
`

export const BackButton = styled.button`
  padding: 0.455rem;
  width: 2.273rem;
  height: 2.273rem;
`

export const ModalContent = styled.div`
  padding-top: 7.182rem;
  display: block;
  width: 80vw;
  max-width: 36.364rem;
`

export const ModalContentResetContainer = styled.div`
  margin-bottom: 2.682rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.364rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.primaryText};
  text-align: center;
  > span {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: normal;
    color: ${({ theme }) => theme.colors.primaryText};
  }
`

export const ModalContentResetCTAContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.636rem;
  text-align: center;

  button {
    font-size: 0.909rem;
    min-width: 9.091rem;
  }
`
export const ThumbUpIconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;

  svg {
    width: 1.6rem;
  }
`

export const MenuIconBadge = styled.span`
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  display: block;
  width: 0.545rem;
  height: 0.545rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.monza};
`

export const HeadMenuBorderItem = styled(HeadMenuItem)`
  margin: 0 28px 18px;

  > button {
    border: 1px solid;
    padding: ${pxToRem(16)} ${pxToRem(24)};
    border-radius: ${pxToRem(16)};
    margin: ${pxToRem(-10)} 0 ${pxToRem(20)};
    width: 100%;
  }
`
