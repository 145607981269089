import React from 'react'
import { IconProps } from 'types/icons'
export const UndertoneGreenIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0_7243_62427"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="62"
        height="62"
      >
        <circle cx="31.0002" cy="31.0002" r="30.383" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_7243_62427)">
        <rect
          x="-0.830078"
          y="-16.7446"
          width="62.9362"
          height="95.4894"
          fill="url(#paint0_linear_7243_62427)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7243_62427"
          x1="59.495"
          y1="58.8518"
          x2="-11.7275"
          y2="-11.2032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0E6863" />
          <stop offset="1" stopColor="#07C8BD" />
        </linearGradient>
      </defs>
    </svg>
  )
}
