import { useMemo } from 'react'
import { FiltersState, ProductTypeValues } from 'types/filter'

const getProductTypeCount = (filters: FiltersState, productType: ProductTypeValues) =>
  filters['collection'].options?.find(o => o.value === productType)?.count || 0

export const usePredefinedFiltersProductCount = (filters: FiltersState) => {
  const productsCount = useMemo(
    () => ({
      [ProductTypeValues.OPTICAL]: getProductTypeCount(filters, ProductTypeValues.OPTICAL),
      [ProductTypeValues.SUN]: getProductTypeCount(filters, ProductTypeValues.SUN),
    }),
    [filters]
  )

  return productsCount
}
