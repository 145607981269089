import React from 'react'
import { IconProps } from 'types/icons'
export const FrameColorIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 34"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M35.1492 16.2735C31.9152 14.1505 29.1352 10.3755 28.0722 6.39551C27.8602 5.49251 28.0222 4.78351 28.5822 4.16451C29.8092 2.80851 32.8602 2.03251 36.5902 1.99951H37.0002V1.99851C38.2472 2.00551 39.5612 2.08951 40.9072 2.26751C44.0682 2.74551 45.2322 5.45651 45.6452 7.03051C46.4022 9.91651 45.8752 13.2085 44.4632 15.4215C44.4672 15.4285 44.4732 15.4345 44.4772 15.4405C44.8832 16.0885 45.2472 16.6905 45.5922 17.2725C47.7172 14.5905 48.5742 10.3125 47.5802 6.52251C46.6652 3.03551 44.3422 0.763512 41.1872 0.287512C39.8812 0.114512 38.4472 0.0125117 37.0002 0.00651169V-0.000488281H11.0002V0.00651169C9.54723 0.0125117 8.10823 0.116512 6.79423 0.290512C3.65823 0.764512 1.33523 3.03651 0.420233 6.52351C-0.758767 11.0185 0.659233 16.2095 3.71723 18.5965C4.90623 19.5245 6.34323 19.9945 7.91323 19.9945C8.84923 19.9945 9.83123 19.8275 10.8372 19.4895C15.4322 17.9475 19.8122 13.1515 21.5762 7.83751C22.9712 6.78151 25.0252 6.78051 26.4212 7.83651C27.7802 11.9475 30.7262 15.7555 34.1262 17.9815C34.4382 17.4455 34.7842 16.8695 35.1492 16.2735ZM19.9352 6.36651C18.5822 11.4325 14.4892 16.1555 10.2022 17.5945C8.76123 18.0785 6.68223 18.3695 4.94923 17.0205C2.52323 15.1255 1.38423 10.7385 2.35623 7.03051C2.76923 5.45651 3.93323 2.74551 7.07623 2.27051C8.42923 2.09251 9.75023 2.00451 11.0012 1.99751V1.99951H11.4282C15.1552 2.03451 18.1952 2.81051 19.4192 4.16451C19.9782 4.78351 20.1402 5.49251 19.9352 6.36651ZM26.0062 5.35351C25.3672 5.12651 24.6902 4.99951 24.0002 4.99951C23.3102 4.99951 22.6352 5.12651 21.9962 5.35351C21.9262 4.41251 21.5622 3.55351 20.9012 2.82351C20.6252 2.51851 20.2972 2.24951 19.9392 2.00051H28.0602C27.7032 2.24951 27.3742 2.51851 27.0982 2.82351C26.4402 3.55051 26.0762 4.40751 26.0062 5.35351Z"
        fill={fill}
      />
      <path
        d="M40.0694 16C39.3524 16 38.6364 16.345 38.2044 17.034C36.2504 20.154 33.4624 25.021 33.4624 27.392C33.4624 31.041 36.4204 34 40.0704 34C43.7194 34 46.6784 31.042 46.6784 27.392C46.6784 25.021 43.8904 20.154 41.9364 17.034C41.5024 16.345 40.7854 16 40.0694 16ZM40.0694 32V18C40.1794 18 40.2214 18.067 40.2394 18.096C43.5114 23.32 44.6764 26.323 44.6764 27.392C44.6764 29.933 42.6094 32 40.0694 32Z"
        fill={fill}
      />
    </svg>
  )
}
