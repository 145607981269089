import React from 'react'
import { IconProps } from 'types/icons'

export const CaretDownIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#555',
  className = '',
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 51 51"
    >
      <path
        fill={fill}
        d="M17 19.743c-.38-.363-.954-.314-1.281.11-.29.376-.288.922-.016 1.294l.114.13L25.5 30.5l9.683-9.223c.38-.363.425-1 .098-1.424-.29-.377-.776-.457-1.148-.216l-.134.106-8.497 8.096L17 19.743z"
      ></path>
    </svg>
  )
}
