import { hideAllDrawer as hideAllDrawerAction } from 'actions/ui'
import { Drawer } from 'components/Drawer'
import { FloatingButton } from 'components/FloatingActions/styles'
import Header from 'components/Header'
import PrivacyPolicyTooltip from 'components/MasterUI/PrivacyPolicyTooltip'
import { InputTypography } from 'components/Typography'
import { PrimaryButton } from 'components/core/Button'
import { BarcodeIcon, CloseIcon, SearchIcon } from 'components/core/Icons'
import { useAction } from 'hooks/useActions'
import { useBarcodeScanner } from 'hooks/useBarcodeScanner'
import { useDebounce } from 'hooks/useDebounce'
import { tablet } from 'libs/media'
import { pxToRem } from 'libs/styled'
import { getSearchValueFromQs, isBarcodeAvailiable, isIPadView } from 'libs/utils'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled, { DefaultTheme, ThemeContext } from 'styled-components'
import { SearchProducts } from './SearchProducts'

const SEARCH_DEBOUNCE_INTERVAL = 1000

const SearchWrapper = styled.div`
  position: absolute;
  top: ${pxToRem(170)};
  padding: 0 4.409rem 4rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 11.727rem);

  ${tablet(
    `
    top: ${pxToRem(194)};
    padding: 0 4.409rem 4rem;
    height: calc(100vh - 10.818rem);`
  )}
`

const SearchFloatingButtonInner = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pxToRem(32)};
  height: ${pxToRem(32)};
`

const SearchFloatingButton = styled(FloatingButton)`
  z-index: 3;
  position: absolute;
  top: 12.409rem;
  right: 0;
  border: 1px solid ${({ theme }) => theme.colors.secondaryText};

  ${tablet(`
    top: 1.5rem;
    width: 2.818rem;
    height: 2.818rem;
    padding: 0.987rem;
  `)}
`

const SearchInputWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  flex: 1;
`

const SearchInput = styled(InputTypography)`
  margin: 0;
  padding: 0.2em;
  width: 100%;
  max-width: 28.72rem;
  border: 0;
  outline: 0;
  font-size: 1.36rem;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryText};
  ${tablet(`
    max-width: unset;
  `)}
`

const SearchIconWrapper = styled.div`
  padding: 0.67rem;
  width: 2.73rem;
  height: 2.73rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryText};
`

const BarcodeScannerButton = styled(PrimaryButton)`
  margin-left: 0.727rem;
  min-width: 9.409rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  text-transform: none;

  svg {
    margin-top: 0.091rem;
    min-width: 40px;
    width: 32px;
    height: 32px;
  }
`

const SearchContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`

export const SearchDrawer: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const initialQuery = getSearchValueFromQs(history.location)
  const [query, setQuery] = useState(initialQuery)
  const hideAllDrawer = useAction(hideAllDrawerAction)
  const debouncedQuery = useDebounce(query, SEARCH_DEBOUNCE_INTERVAL)

  const theme: DefaultTheme = useContext(ThemeContext)
  const visible = useSelector(({ ui }) => ui.searchDrawerIsVisible)
  const isIpad = isIPadView()
  const { toggleBarcodeScanner } = useBarcodeScanner()

  return (
    <Drawer
      position="left"
      variant="fullscreen"
      visible={visible}
      onOverlayClick={hideAllDrawer}
      className="search-drawer"
    >
      <PrivacyPolicyTooltip isFixed={true} stroke="black" />
      <SearchFloatingButton type="button" onClick={hideAllDrawer}>
        <SearchFloatingButtonInner>
          <CloseIcon fill={theme.colors.primaryText} />
        </SearchFloatingButtonInner>
      </SearchFloatingButton>
      <Header />
      <SearchWrapper>
        <SearchContainer>
          <SearchInputWrapper>
            <SearchInput
              type="search"
              value={query}
              onChange={e => setQuery(e.target.value)}
              autoFocus
            />
            <SearchIconWrapper>
              <SearchIcon fill={theme.colors.primaryText} />
            </SearchIconWrapper>
          </SearchInputWrapper>
          {isIpad && isBarcodeAvailiable() && (
            <BarcodeScannerButton
              data-element-id="MainNav_SearchBarcode"
              onClick={() => {
                toggleBarcodeScanner()
              }}
            >
              <BarcodeIcon fill="#fff" height="50%" />
              {t('ProductsListPage.barcodeSearch')}
            </BarcodeScannerButton>
          )}
        </SearchContainer>

        {debouncedQuery && <SearchProducts query={debouncedQuery} />}
      </SearchWrapper>
    </Drawer>
  )
}
