import { Heading4 } from 'components/Typography'
import { NavigationContext } from 'providers/navigationProvider'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { FontWeight } from 'types/typography'
import {
  ScrollableContent,
  ScrollableContentContainer,
  ScrollablePaneContainer,
  TabPane,
  TabsContainer,
} from './styles'

interface DataTabAttr {
  id?: string
  heading?: string
}

export interface SingleTabProps {
  id: string
  tabName?: string
  tabContent?: React.ReactNode | ((isActive: boolean) => React.ReactNode)
  dataAttr?: DataTabAttr
}

interface TabsProps {
  tabs: SingleTabProps[]
  defaultActiveKey?: number
  activeKey?: number
  onChange?: (v: number) => void
  onTabClick?: (i: number) => void
  isTabDisabled?: (v: string, i: number) => boolean
  className?: string
  dataCalls?: string
  scrollLogicalPosition?: ScrollLogicalPosition
  withFocus?: boolean
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  defaultActiveKey = 0,
  activeKey,
  onChange,
  onTabClick,
  isTabDisabled,
  children,
  className,
  dataCalls,
  scrollLogicalPosition,
  withFocus = true,
}) => {
  const tabsListRef = useRef<HTMLDivElement | null>(null)
  const [activeTab, setActiveTab] = useState(activeKey || defaultActiveKey)
  const { landscapeNavigation } = useContext(NavigationContext)

  useEffect(() => {
    activeKey !== null && activeKey !== undefined && setActiveTab(activeKey)
  }, [activeKey])

  useEffect(() => {
    onChange && onChange(activeTab)
  }, [activeTab, onChange])

  useEffect(() => {
    if (tabsListRef?.current && withFocus) {
      const activetabElement = tabsListRef.current.children[activeTab]
      activetabElement &&
        activetabElement.scrollIntoView({
          inline: 'center',
          ...(scrollLogicalPosition && { block: scrollLogicalPosition }),
        })
    }
  }, [activeTab, landscapeNavigation, scrollLogicalPosition, withFocus])

  const onTabChange = (tabIndex: number) => {
    if (tabIndex !== activeTab) {
      setActiveTab(tabIndex)
      onTabClick && onTabClick(tabIndex)
    }
  }

  const handleTabClick = (id: string, i: number) => {
    if (isTabDisabled && isTabDisabled(id, i)) {
      return
    }

    onTabChange(i)
  }

  return (
    <TabsContainer className={className}>
      <ScrollablePaneContainer ref={tabsListRef}>
        {tabs.map((tab, i) => {
          const isActive = activeTab === i
          const fontWeight: FontWeight | false = isActive ? 'bold' : false
          return (
            <TabPane
              onClick={() => handleTabClick(tab.id, i)}
              key={tab.id}
              isActive={isActive}
              aria-label={tab.tabName}
              aria-describedby={`tab-content-${tab?.id}`}
              data-element-id={tab.dataAttr?.id}
              data-test={tab.dataAttr?.id}
              {...(dataCalls && { 'data-analytics_available_call': dataCalls })}
              className={isActive ? 'active' : ''}
            >
              {typeof tab.tabContent === 'function' ? (
                tab.tabContent(isActive)
              ) : (
                <Heading4 weight={fontWeight} data-test={tab.dataAttr?.heading}>
                  {tab.tabContent || tab.tabName}
                </Heading4>
              )}
            </TabPane>
          )
        })}
      </ScrollablePaneContainer>
      <ScrollableContentContainer>
        <ScrollableContent currentTabIndex={activeTab}>{children}</ScrollableContent>
      </ScrollableContentContainer>
    </TabsContainer>
  )
}

export default Tabs
