import React from 'react'
import { IconProps } from 'types/icons'

export const TrashIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#555',
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M21.67 4.539h-5.716V4.23c0-1.506-1.2-2.731-2.674-2.731h-2.56c-1.474 0-2.674 1.225-2.674 2.73v.309H2.33a.839.839 0 00-.83.847.84.84 0 00.83.848h1.342l1.55 13.845c.154 1.38 1.296 2.421 2.656 2.421h8.572c1.387 0 2.531-1.063 2.663-2.472l1.286-13.794h1.271a.84.84 0 00.83-.848.839.839 0 00-.83-.847zM9.706 4.23c0-.571.455-1.036 1.014-1.036h2.56c.56 0 1.014.465 1.014 1.036v.308H9.706V4.23zm7.754 15.636c-.05.535-.484.938-1.01.938H7.878c-.516 0-.95-.395-1.008-.918L5.342 6.234h13.39L17.46 19.867z"
      ></path>
    </svg>
  )
}
