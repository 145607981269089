import { Dispatch } from 'redux'
export const REFRESH_TOKEN = 'session/REFRESH_TOKEN'
export const SAVE_ANALYTICS_GOOGLE_SENDED = 'session/SAVE_ANALYTICS_GOOGLE_SENDED'
export const SAVE_ANALYTICS_TEALIUM_SENDED = 'session/SAVE_ANALYTICS_TEALIUM_SENDED'
export const UPDATE_BIPA_STATE_SHOULD_RESET = 'ui/UPDATE_BIPA_STATE_SHOULD_RESET'
export const RESET_STORE = 'store/RESET_STORE'

export interface ResetStoreAction {
  type: typeof RESET_STORE
}

export const resetStore = () => (dispatch: Dispatch<ResetStoreAction>) => {
  dispatch({
    type: RESET_STORE,
  })
}

export interface RefreshSessionAction {
  type: typeof REFRESH_TOKEN
}

export interface SaveAnalyticsGoogleSendedAction {
  type: typeof SAVE_ANALYTICS_GOOGLE_SENDED
}

export interface SaveAnalyticsTealiumSendedAction {
  type: typeof SAVE_ANALYTICS_TEALIUM_SENDED
}

export interface UpdateBipaVMStateShouldReset {
  type: typeof UPDATE_BIPA_STATE_SHOULD_RESET
  payload: {
    shouldResetState: boolean
  }
}

export const refreshToken = (): RefreshSessionAction => ({
  type: REFRESH_TOKEN,
})

export const saveAnalyticsGoogleSended = (): SaveAnalyticsGoogleSendedAction => ({
  type: SAVE_ANALYTICS_GOOGLE_SENDED,
})

export const saveAnalyticsTealiumSended = (): SaveAnalyticsTealiumSendedAction => ({
  type: SAVE_ANALYTICS_TEALIUM_SENDED,
})

export const updateBipaVMStateShouldReset = (
  shouldResetState: boolean
): UpdateBipaVMStateShouldReset => ({
  type: UPDATE_BIPA_STATE_SHOULD_RESET,
  payload: { shouldResetState },
})

export type SessionActionTypes =
  | RefreshSessionAction
  | SaveAnalyticsGoogleSendedAction
  | SaveAnalyticsTealiumSendedAction
  | UpdateBipaVMStateShouldReset

export type StoreActionTypes = ResetStoreAction
