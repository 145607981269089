import React from 'react'
import { Bold } from './WelcomePageDigitalCouvette/styles'
import { DIGITAL_COUVETTE_SIZES, Frame } from './types'

export const getSizeMeasure = (size: string) => DIGITAL_COUVETTE_SIZES[size]

export const formatSelectedFramePlaceholder = (frame: Frame) => (
  <>
    <Bold>{frame.model}</Bold> {frame.brand} <Bold>{frame.modelName}</Bold> {`(${frame.caliber})`}
  </>
)
