const loadedScriptsMap = new Map<string, Promise<void>>()

const loadScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.src = src
    script.onload = () => resolve()
    script.onerror = reject
    document.head.appendChild(script)
  })
}

export const importScript = (id: string, src: string): Promise<void> => {
  if (loadedScriptsMap.has(id)) {
    return loadedScriptsMap.get(id) as Promise<void>
  }

  const loadScriptPromise = loadScript(src)
  loadedScriptsMap.set(id, loadScriptPromise)
  return loadScriptPromise
}
