import { BestMatchWrapper } from 'components/BadgeBestMatch/styles'
import { Text } from 'components/Typography'
import { Device, landscape, tabletPortrait } from 'libs/media'
import { pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const BestMatchTextWrapperStyled = styled.div`
  margin-bottom: ${pxToRem(16)};
  .show-more {
    font-weight: bold;
  }
`

export const ProductDescriptionStyled = styled(Text)`
  text-align: left;
  margin-bottom: 0;

  span {
    font-weight: bold;
  }
`

export const BestMatchDetails = styled.div<{ isDressedOnView?: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: center;
  grid-column: 1 / 3;
  position: absolute;
  bottom: 0;
  border-radius: ${pxToRem(16)} ${pxToRem(16)} 0px 0px;
  background-color: #ffffffcc;
  width: 100%;
  padding: 0 ${pxToRem(164)} 0 ${pxToRem(16)};
  z-index: 2;
  border-bottom: 4px solid #504bd6;
  ${tabletPortrait(
    `
      padding: 0 ${pxToRem(140)} 0 ${pxToRem(16)};
    `
  )}
  ${landscape(
    Device.tablet,
    `
    padding: 0 ${pxToRem(140)} 0 ${pxToRem(16)};
  `
  )}

  ${BestMatchWrapper} {
    margin-block: ${pxToRem(16)};
  }
`
