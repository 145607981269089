import styled from 'styled-components'

export const ColorImage = styled.div<{
  src?: string
}>`
  width: 100%;
  height: 100%;
  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 50%;
`
