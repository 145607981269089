import { pushToDataTrack } from 'containers/AnalyticsProvider'
import { Device, landscape, tablet } from 'libs/media'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FilterFacet, FirstLevelMenuValues, ProductTypeValues } from 'types/filter'

const SearchProductTypeTabsWrapper = styled.ul`
  max-width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 1rem;
`

const SearchProductTypeFilterTab = styled.li<{ isActive?: boolean }>`
  margin-bottom: 1.27rem;
  margin-right: 1.45rem;
  text-align: center;
  text-transform: capitalize;
  ${({ isActive }) => (isActive ? 'border-bottom: 2px solid' : null)};
  min-height: 1.64rem;
  ${tablet(
    `
    margin-right: 1.45rem;
    margin-bottom: 1.27;
  `
  )}
  ${landscape(Device.tablet, 'margin-bottom: 1.27rem')}
`

const TabHeading = styled.h5<{ isSelected?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 0.909rem;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
  line-height: 1.5;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primaryText : theme.colors.secondaryText};
`

interface ProductTypeTabsProps {
  filters: FilterFacet[]
  selectedFilter?: FilterFacet
  onChange: (v: FilterFacet) => void
}

const translationKeys: Record<string, string> = {
  [FirstLevelMenuValues.EYEWEAR_ACCESSORIES]: 'accessories',
  [FirstLevelMenuValues.GOGGLES_ACCESSORIES]: 'gogglesAccessories',
}

export const ProductTypeTabs: React.FC<ProductTypeTabsProps> = ({
  filters,
  selectedFilter,
  onChange,
}) => {
  const { t } = useTranslation()

  const filterWhitelist: string[] = [
    ProductTypeValues.SUN,
    ProductTypeValues.OPTICAL,
    ProductTypeValues.NUANCE_FRAME,
    ProductTypeValues.NUANCE_ACCESSORIES,
  ]
  const onProductTypeClick = (filter: FilterFacet, i: number) => {
    onChange(filter)
    pushToDataTrack({
      id: 'Click',
      data_element_id: `MainNav_Results_Tab${i}`,
      data_description: filter.value,
    })
  }

  const whitelistedFilters = useMemo(
    () =>
      filters
        .filter(f => filterWhitelist.includes(f.value))
        .sort((a, b) => filterWhitelist.indexOf(a.value) - filterWhitelist.indexOf(b.value)),
    [filterWhitelist, filters]
  )

  const getTabTitle = (filter: FilterFacet) => {
    const key = translationKeys[filter.value] || filter.label
    return t(`PageTitle.${key}`, {
      defaultValue: filter.label,
    })
  }

  return (
    <SearchProductTypeTabsWrapper>
      {whitelistedFilters.length > 1 &&
        whitelistedFilters.map((filter, index) => (
          <SearchProductTypeFilterTab
            isActive={filter.value === selectedFilter.value}
            key={filter.value}
            onClick={() => onProductTypeClick(filter, index)}
          >
            <TabHeading isSelected={filter.value === selectedFilter.value}>
              {getTabTitle(filter)}
            </TabHeading>
          </SearchProductTypeFilterTab>
        ))}
    </SearchProductTypeTabsWrapper>
  )
}
