type Options = {
  truncationChar: string
  clamp: number | string
  truncationHTML?: HTMLElement
}

const defaultSplitOnChars = ['.', ' ']

let chunks: string[] | undefined,
  lastChunk: string | undefined,
  splitOnChars = [...defaultSplitOnChars],
  splitChar = splitOnChars[0]

export const lineClamp = (
  element: HTMLElement,
  { truncationChar = '...', clamp = 'auto', truncationHTML }: Partial<Options>
) => {
  const originalText = element.innerHTML
  try {
    reset()
    const isCSSValue =
      typeof clamp === 'string' && (clamp.indexOf('px') > -1 || clamp.indexOf('em') > -1)
    let maxLines: number
    let clampedText: string

    if (clamp === 'auto') {
      maxLines = getMaxLines(element)
    } else if (isCSSValue) {
      maxLines = getMaxLines(element, parseInt(clamp as string))
    } else {
      maxLines = clamp as number
    }

    const height = getMaxHeight(element, maxLines)
    if (height <= element.clientHeight) {
      clampedText = truncate(element, element, height, { truncationChar, clamp, truncationHTML })
    }

    return {
      original: originalText,
      clamped: clampedText,
    }
  } catch (err) {
    return {
      original: originalText,
      clamped: undefined,
    }
  }
}

const computeStyle = (element: HTMLElement, property: string) =>
  window.getComputedStyle(element, null).getPropertyValue(property)

const getMaxLines = (element: HTMLElement, height?: number) => {
  const availHeight = height ?? element.clientHeight
  const lineHeight = getLineHeight(element)

  return Math.max(Math.ceil(availHeight / lineHeight), 0)
}

const getMaxHeight = (element: HTMLElement, clmp: number) => {
  const lineHeight = getLineHeight(element)
  return Math.ceil(lineHeight * clmp)
}

const getLineHeight = (element: HTMLElement) => {
  const lineHeight: number = parseFloat(computeStyle(element, 'line-height'))
  if (isNaN(lineHeight)) {
    // Normal line heights vary from browser to browser. The spec recommends
    // a value between 1.0 and 1.2 of the font size. Using 1.1 to split the diff.
    return parseFloat(computeStyle(element, 'font-size')) * 1.2
  }

  return lineHeight
}

const truncate = (
  element: HTMLElement,
  target: HTMLElement | ChildNode,
  maxHeight: number,
  options: Options
): string | undefined => {
  if (!maxHeight) {
    return
  }

  const nodeValue = target.nodeValue?.replace(options.truncationChar, '')

  if (!chunks) {
    if (splitOnChars.length > 0) {
      splitChar = splitOnChars.shift() ?? ''
    } else {
      splitChar = ''
    }

    chunks = nodeValue?.split(splitChar)
  }

  if (chunks && chunks.length > 1) {
    lastChunk = chunks.pop()
    applyEllipsis(target, chunks.join(splitChar), options)
  } else {
    chunks = undefined
  }

  if (options.truncationHTML) {
    if (target.nodeValue) target.nodeValue = target.nodeValue.replace(options.truncationChar, '')
    element.appendChild(options.truncationHTML)
  }

  if (chunks) {
    if (element.clientHeight <= maxHeight) {
      if (splitOnChars.length >= 0 && splitChar != '') {
        applyEllipsis(target, chunks.join(splitChar) + splitChar + lastChunk, options)
        chunks = undefined
      } else {
        return element.innerHTML
      }
    }
  } else {
    if (splitChar === '') {
      applyEllipsis(target, splitChar, options)
      target = getLastChild(element, options)
      reset()
    }
  }

  return truncate(element, target, maxHeight, options)
}

const applyEllipsis = (element: HTMLElement | ChildNode, str: string, options: Options) => {
  element.nodeValue = str + options.truncationChar
}

// TODO: to be improved ... with very nested components the library does not work
const getLastChild = (element: HTMLElement, options: Options): ChildNode => {
  if (
    element.lastElementChild &&
    element.lastElementChild.children &&
    element.lastElementChild.children.length > 0
  ) {
    return getLastChild(Array.prototype.slice.call(element.children).pop(), options)
  } else if (
    !element.lastChild ||
    !element.lastChild.nodeValue ||
    element.lastChild.nodeValue == '' ||
    element.lastChild.nodeValue == options.truncationChar
  ) {
    element.lastChild?.parentNode?.removeChild(element.lastChild)
    return getLastChild(element, options)
  } else {
    return element.lastChild
  }
}

const reset = () => {
  chunks = undefined
  lastChunk = undefined
  splitOnChars = [...defaultSplitOnChars]
  splitChar = splitOnChars[0]
}
