import { FormItem } from 'components/core/FormItem'
import { TextareaField } from 'components/core/TextArea/styled'
import jwt from 'jsonwebtoken'
import { cleanLocalStorageData } from 'libs/auth'
import { authenticate } from 'libs/routing'
import qs from 'qs'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { PrimaryButton } from '../components/core/Button'
import { ReplyParsedToken } from './LoginRedirect'

const LoginBypassWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 9.09rem;
`

const FormWrapper = styled.div`
  width: 70%;
`

type LoginBypass = RouteComponentProps

const LoginBypass: React.FC<LoginBypass> = () => {
  const [inputToken, setInputToken] = useState('')
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const token = params['token']
  const readonly = params['read-only']

  if (token) {
    cleanLocalStorageData()
    const parsedToken: ReplyParsedToken = jwt.decode(token)
    authenticate(token.trim(), `${parsedToken.isMigrated || false}`, readonly)
  }

  const handleSubmit = e => {
    e.preventDefault()
    cleanLocalStorageData()
    const trimmedToken = inputToken.trim()
    const parsedToken: ReplyParsedToken = JSON.parse(atob(trimmedToken.split('.')[1]))
    authenticate(trimmedToken, `${parsedToken.isMigrated || false}`, false)
  }

  return (
    <LoginBypassWrapper>
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <>
            <FormItem label="Token" help={''}>
              <TextareaField
                rows={4}
                onChange={e => setInputToken(e.target.value)}
                value={inputToken}
                name="token"
              />
            </FormItem>
            <PrimaryButton type="submit" disabled={!inputToken}>
              Send
            </PrimaryButton>
          </>
        </form>
      </FormWrapper>
    </LoginBypassWrapper>
  )
}

export default LoginBypass
