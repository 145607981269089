import { AccessibilityRoles } from 'types/accessibility'
import i18next from 'i18next'

/**
 * The returned object includes the ARIA attributes required for accessibility
 * * @param {string} key
 * * @param {AccessibilityRoles} role
 */

export const getARIAAttribues = (key: string, role?: AccessibilityRoles) => {
  return {
    role,
    'aria-label': i18next.t(`ARIA.${key}.label`),
  }
}
