import { Device, landscape, tablet } from 'libs/media'
import { checkColor, clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const ThumbBtn = styled.button<{ isActive?: boolean; backgroundColor?: string }>`
  position: relative;
  padding: 0;
  margin-top: -0.5em !important;
  width: ${pxToRem(60)};
  height: ${pxToRem(60)};
  margin-left: ${pxToRem(16)};
  flex: 0 0 auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background-color: ${({ theme, isActive, backgroundColor }) =>
    isActive
      ? backgroundColor
        ? checkColor(backgroundColor, theme)
        : theme.cta.backgroundColor
      : theme.cta.secondaryBackgroundColor};
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;

  ${tablet(
    `
    margin-left: ${clampValues(12, 16)};
    width: ${clampValues(42, 48)};
    height: ${clampValues(42, 48)};
  `
  )}
  ${landscape(
    Device.tablet,
    `
    margin-left: ${clampValuesLandscape(12, 16)};
    width: ${clampValuesLandscape(42, 48)};
    height: ${clampValuesLandscape(42, 48)};

  `
  )}
`

export const ThumbBtnStyled = styled(ThumbBtn)`
  width: 2.7275rem;
  height: 2.7275rem;

  ${tablet(
    `
    width: 2.7275rem;
    height: 2.7275rem;
    `
  )}
  ${landscape(
    Device.tablet,
    `
    width: 2.7275rem;
    height: 2.7275rem;

  `
  )}
`
