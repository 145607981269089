import { CaretUpThinIcon } from 'components/core/Icons'
import { Heading5 } from 'components/Typography'
import { useDeviceType } from 'hooks/useDeviceType'
import { usePageChecker } from 'hooks/useNavigationUtils'
import { pxToRem } from 'libs/styled'
import { NavigationContext } from 'providers/navigationProvider'
import React, {
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { CartMerchCarousel } from '../CartMerchCarousel'
import MerchCardComponent from '../MerchCardComponent'
import { MerchAccordionStyled, MerchCardContainer } from '../styles'
import { MerchAccordionProps } from '../types'
import { CartMerchRecommenderAccordionProps } from './types'

const MerchAccordion: React.FC<MerchAccordionProps> = ({ children, label }) => {
  const { landscapeNavigation } = useContext(NavigationContext)
  const [isOpened, setIsOpened] = useState(!landscapeNavigation)
  const targetRef = useRef<HTMLElement | null>(null)

  const handleAccordionClick = useCallback(
    (ev: MouseEvent<HTMLElement>) => {
      setIsOpened(!isOpened)
      targetRef.current = ev.target as HTMLElement
    },
    [isOpened]
  )

  useEffect(() => {
    if (isOpened) {
      setTimeout(() => {
        targetRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 300)
    }
  }, [isOpened])

  return (
    <MerchAccordionStyled
      isOpened={isOpened}
      renderAccordionIcon={() => <CaretUpThinIcon height={pxToRem(32)} width={pxToRem(32)} />}
      title={<Heading5>{label}</Heading5>}
      onTitleClick={handleAccordionClick}
    >
      {children}
    </MerchAccordionStyled>
  )
}

export const CartMerchRecommenderAccordion: FC<CartMerchRecommenderAccordionProps> = ({
  products,
}) => {
  const { t } = useTranslation()
  const { isTowerLandscape } = useDeviceType()
  const { isCartPage } = usePageChecker()
  const showInline = useMemo(() => isCartPage && isTowerLandscape && products.length <= 3, [
    isCartPage,
    isTowerLandscape,
    products.length,
  ])

  return (
    <MerchAccordion label={t('NewMerch.title')}>
      {showInline ? (
        <MerchCardContainer>
          {products.map((item, i) => (
            <MerchCardComponent key={i} product={item} />
          ))}
        </MerchCardContainer>
      ) : (
        <CartMerchCarousel products={products} />
      )}
    </MerchAccordion>
  )
}
