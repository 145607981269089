const CONFIG_OVERRIDE_STORAGE_SLUG = 'config-override'
const SESSION_EXPIRATION_INTERVAL = 3600000 // 1 hour

const getSessionStorageItem = (sessionStorageKey: string): string | null => {
  return sessionStorage.getItem(sessionStorageKey)
}

export const setConfigSession = <T>(sessionStorageKey: string, configState: T): void => {
  sessionStorage.setItem(
    sessionStorageKey,
    JSON.stringify({
      config: configState,
      setDate: new Date().getTime(),
    })
  )
}

export const getConfigFromSessionStorage = <T>(sessionStorageKey: string): T | null => {
  const configFromSessionStorage: string | null = getSessionStorageItem(sessionStorageKey)
  return configFromSessionStorage ? JSON.parse(configFromSessionStorage).config : null
}

export const getConfigOverrideSessionKey = (shopperSlug: string): string =>
  [shopperSlug, CONFIG_OVERRIDE_STORAGE_SLUG].join('-')

export const getDateFromSessionStorage = <T>(sessionStorageKey: string): T | null => {
  const configFromSessionStorage: string | null = getSessionStorageItem(sessionStorageKey)
  return configFromSessionStorage ? JSON.parse(configFromSessionStorage).setDate : null
}

const removeOverrideFromSessionStorage = (configOverrideSessionKey: string): void => {
  sessionStorage.removeItem(configOverrideSessionKey)
  window.location.reload()
}

export const checkOverrideConfigInSession = (SHOPPER_SLUG: string): void => {
  const configOverrideSessionKey = getConfigOverrideSessionKey(SHOPPER_SLUG)
  const expirationDateFromStorage: string | null = getDateFromSessionStorage(
    configOverrideSessionKey
  )
  const expirationDate =
    expirationDateFromStorage &&
    new Date(expirationDateFromStorage).getTime() + SESSION_EXPIRATION_INTERVAL

  if (expirationDate && expirationDate < Date.now()) {
    removeOverrideFromSessionStorage(configOverrideSessionKey)
  }
}
