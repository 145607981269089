import Tabs from 'components/Tabs'
import {
  ScrollableContentContainer,
  ScrollablePaneContainer,
  TabPane,
} from 'components/Tabs/styles'
import { Heading4, LinkTypography, ListTypography } from 'components/Typography'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'
// TODO: We should use device from styled.ts instead. This will need adjustment as well as change all the places with clampValues
import { BadgeBestMatch } from 'components/BadgeBestMatch'
import { CardProductContainerStyled } from 'components/CardProductBase/ProductCard.style'
import { ListItemContainer } from 'components/core/List/ListItem'
import { Slider } from 'components/Slider'
import { device } from 'styles/variables'
import { ProductWishListActionButton } from '../CardProductBase/ProductWishListActionButton'
import { RTRContainer } from '../core/RealTimeRenderer/styles'
import { PDPPanel } from './PDPPanel'
import { PanelContent, PDPPanelContainer } from './PDPPanel/styles'
import { SuggestionsSelector } from './SuggestionsTab/styles'

export const VirtualMirrorButtonContainer = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  min-height: 3.636rem;

  .ant-btn,
  button {
    &,
    &:focus,
    &:hover,
    &:active {
      padding: 1.136rem;
      height: auto;
      border: 0;
      font-family: ${({ theme }) => theme.fonts.primary};
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.primaryText};
      line-height: 1.364rem;
      text-align: center;
      text-decoration: underline;
      text-transform: uppercase;

      span {
        text-decoration: underline;
      }
    }
  }
`

export const WishListIconButton = styled(ProductWishListActionButton)`
  margin: 0 0 0 0.7273rem;
  width: 2.7273rem;
  height: 2.7273rem;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50%;
`

export const PDPHeader = styled.div`
  position: relative;
  padding-top: ${pxToRem(198)};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.wildSand};
  ${tablet(`
    padding-top: ${clampValues(102, 122)};
  `)}

  ${landscape(`
    flex: 1;
    padding-top: ${pxToRem(214)};
    height: 100%;
  `)}
  ${landscape(
    Device.tablet,
    `
    padding-top: ${clampValuesLandscape(94, 110)};
  `
  )}
`

export const PDPInfoActionsPrice = styled.div`
  margin-top: ${pxToRem(15)};
  text-align: center;
  ${landscape(`
    text-align: left;
    margin-top: ${pxToRem(24)};
  `)}
`

export const PDPInfoActionsButtons = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;

  ${landscape(
    `
    justify-content: flex-start;
    margin-top: ${pxToRem(24)};
    > button {
      width: ${pxToRem(262)};
    }
  `
  )}
  ${tablet(
    `
    margin-top: ${clampValues(16, 16)};
  `
  )}

  ${landscape(
    Device.tablet,
    `
    flex-wrap: wrap-reverse;
    row-gap: ${clampValuesLandscape(16, 16)};
    gap: ${clampValuesLandscape(16, 16)};
      `
  )}

  > button {
    max-width: ${pxToRem(290)};
    ${tablet(`
      max-width: ${clampValues(260, 290)};
      max-height: ${clampValues(42, 56)};
    `)}

    ${landscape(
      Device.tablet,
      `
        max-width: ${clampValuesLandscape(260, 290)};
        max-height: ${clampValuesLandscape(42, 58)};
      `
    )}
  }
`

export const PDPActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
`

export const PDPSimilarTitle = styled(Heading4)<{ isSelected?: boolean }>`
  margin-block-end: ${pxToRem(32)};
  margin-inline-start: ${pxToRem(24)};
  font-weight:   ${({ isSelected }) => (isSelected ? '700' : '')}}
  text-decoration:   ${({ isSelected }) => (isSelected ? 'underline' : '')}}

  ${tablet(`
    margin-left: ${clampValues(72, 96)};
    margin-bottom: ${pxToRem(8)};
  `)}
`

export const PDPSimilarContainer = styled.div`
  margin-top: ${pxToRem(48)};
  ${tablet(`
    margin-top: ${clampValues(12, 16)};
  `)}
`

export const DrawerPanelContainer = styled.div`
  padding: 18.33vmax 1.818rem 0;
  width: 100%;
  height: 100%;

  @media ${device.ipadMiniPortraitReal} {
    padding-top: ${pxToRem(77)};
    padding-bottom: ${pxToRem(70)};
  }
`

export const PDPContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.colors.bodyBackground};
  min-height: 100%;
  ${landscape(`
    height: 100vh;
  `)}
  ${tablet(`
    padding-bottom: ${pxToRem(100)};
  `)}
  ${landscape(Device.tablet, 'padding-bottom: 0; height: 100%;overflow: unset;')}
`

export const TabsStyled = styled(Tabs)`
  height: fit-content;

  ${landscape(`
    height: 100%;
  `)}

  ${ScrollableContentContainer} {
    ${landscape(`
      position: relative;
      width: ${pxToRem(1123)};
      left: ${pxToRem(-300)};
    `)}
    ${landscape(
      Device.tablet,
      `
      width: ${clampValuesLandscape(620, 830)};
      left: -15vw;
    `
    )}
  }

  ${ScrollablePaneContainer} {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    min-height: ${pxToRem(33)};
    overflow: visible;
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.wildSand};
    z-index: 2;
    width: 100%;
    padding-top: 0;
    ${tablet(`
      padding: ${clampValues(10, 38)} 0 ${clampValues(10, 20)};
      min-height: ${clampValues(72, 97)};
    `)}
    ${landscape(`
      max-width: 100%;
      background: transparent;
      padding-bottom: 0;
    `)}
      ${landscape(
      Device.tablet,
      `
        padding-top: ${pxToRem(76)};
        min-height: ${clampValuesLandscape(88, 115)};
    `
    )}
  }

  ${TabPane} {
    :last-child {
      padding-right: 0;
    }

    &:after {
      margin-top: 0;
    }
  }
`

export const TabContentStyled = styled.div`
  display: inline-block;
  white-space: normal;
  width: 100%;
  vertical-align: top;
  padding-bottom: ${pxToRem(78)};
  height: 100%;
  position: relative;

  ${landscape(`
    padding-bottom: ${pxToRem(94)};
  `)}
  ${tablet(`
    padding-bottom: ${clampValues(42, 78)};
  `)}
  ${landscape(
    Device.tablet,
    `
    padding-bottom: ${clampValuesLandscape(89, 89)};
  `
  )}
`

export const TabContentWrapper = styled.div`
  position: relative;
  margin: auto;
  width: ${pxToRem(856)};
  height: ${pxToRem(482)};
  ${tablet(`
    width: ${clampValues(624, 832)};
    height: ${clampValues(348, 468)};
  `)}
  ${landscape(
    `
    height: 100%;
    width: 100%;
    `
  )}
  ${landscape(
    Device.tablet,
    `
    height: ${clampValuesLandscape(348, 468)};
  `
  )}
  ${RTRContainer} {
    margin-top: ${pxToRem(30)}
      ${tablet(
        Device.tablet,
        `
      margin-top: ${clampValues(14, 22)}
    `
      )};
  }
`

export const TabContentWrapperQRCode = styled(TabContentWrapper)<{ withFAMatch: boolean }>`
  top: ${pxToRem(74)};

  ${landscape(
    `
      width: ${pxToRem(836)};
      height: ${pxToRem(470)};
      top: ${pxToRem(139)};
      left: ${pxToRem(169)};
      margin: unset;
    `
  )}

  ${({ withFAMatch }) =>
    tablet(`
      top: ${clampValues(50, 70)};
      height: ${withFAMatch ? clampValues(363, 485) : clampValues(312, 416)};
  `)}

${({ withFAMatch }) =>
    landscape(
      Device.tablet,
      `
        height: ${withFAMatch ? clampValuesLandscape(363, 538) : clampValuesLandscape(312, 416)};
        width: ${clampValuesLandscape(547, 774)};
        top: ${clampValuesLandscape(70, 90)};
        left: ${pxToRem(34)};
      `
    )}
`

export const TryLensesTabPane = styled.div`
  display: flex;
  align-items: center;
`

interface TabHeaderProps {
  isActive: boolean
}
export const TabHeader = styled(Heading4).attrs<TabHeaderProps>(({ isActive }) => ({
  weight: isActive ? 'bold' : false,
}))<TabHeaderProps>``

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 ${pxToRem(112)};
  height: ${pxToRem(412)};
  row-gap: ${pxToRem(16)};
  ${landscape(`
    position: absolute;
    top: 0;
    left: ${pxToRem(1259)};
    text-align: left;
    padding: 0 ${pxToRem(112)} 0 0;
    align-items: flex-start;
    width: auto;
    height: 100%;
    padding-top: ${pxToRem(73 + 164)};
  `)}
  ${tablet(`
    height: ${clampValues(297, 416)};
    row-gap: 0;
  `)}
  ${landscape(
    Device.tablet,
    `
      padding-top: ${clampValuesLandscape(77, 129)};
      left: ${clampValuesLandscape(700, 950)};
      padding-right: ${pxToRem(20)};
    `
  )}
`

export const ProductInfoWrapper = styled.div<{ withMarginBottom: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
  text-align: center;
  margin-bottom: ${({ withMarginBottom }) => (withMarginBottom ? `${pxToRem(16)}` : '0')};
  ${landscape(`
    text-align: left;
  `)}
`

export const BrandImagePDP = styled.img`
  width: 100%;
  height: auto;
  max-width: ${pxToRem(220)};
  align-self: center;
  margin-bottom: ${pxToRem(16)};
  ${tablet(`
    max-width: ${clampValues(170, 220)};
    margin-bottom: ${clampValues(16, 16)};
  `)}
  ${landscape(`
      align-self: flex-start;
  `)}
  ${landscape(
    Device.tablet,
    `
    max-width: ${clampValuesLandscape(150, 220)};
    margin-bottom: ${clampValuesLandscape(16, 16)};
  `
  )}
`

export const Chips = styled.ul`
  list-style: none;
  margin: ${pxToRem(-4)};
  margin-top: ${pxToRem(16 - 4)};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${landscape(`
    margin-top: ${pxToRem(24)};
    justify-content: initial;
  `)}
`

export const BadeChipsWrapper = styled.div`
  margin: ${pxToRem(-4)};
  margin-top: ${pxToRem(16 - 4)};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${landscape(`
    margin-top: ${pxToRem(24)};
    justify-content: initial;
  `)}
`

export const BadgeChip = styled(ListTypography)`
  border: 1px solid;
  border-radius: ${pxToRem(100)};
  padding: ${pxToRem(4)} ${pxToRem(16)};
  margin: ${pxToRem(4)};
  text-transform: capitalize;
`

export const CustomizeiconContainer = styled.div`
  margin-right: ${pxToRem(7)};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledBadgeBestMatch = styled(BadgeBestMatch)`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

export const RelationsLink = styled(LinkTypography)`
  margin-top: ${pxToRem(8)};
  ${landscape(`margin-top: ${pxToRem(24)};  `)}
`

export const PDPSuggestionsContainer = styled.section`
  margin-block-start: ${pxToRem(32)};
  ${tablet(`
    margin-block-start: ${clampValues(12, 16)};
  `)}

  ${SuggestionsSelector} {
    padding: 0 ${pxToRem(24)};
    margin-block-end: ${pxToRem(24)};
    ${tablet(`
      padding: 0 ${clampValues(72, 96)};
      margin-block-end: ${clampValues(12, 24)};
    `)}
  }
`

export const SliderStyled = styled(Slider)`
  padding-inline: ${pxToRem(16)};
`

export const SuggestionsPDPPanel = styled(PDPPanel)`
  padding-block-start: ${pxToRem(176)};
  padding-inline: 0;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(32)};
  ${landscape(
    Device.tablet,
    `
      padding-block-start: ${pxToRem(100)};
      padding-inline: 0;
  `
  )}
  ${PDPPanelContainer} {
    padding-block-start: 0;
    ${landscape(`

      padding-block-start: ${pxToRem(0)};
    `)}
    ${landscape(
      Device.tablet,
      `
      padding-block-start: ${pxToRem(0)};
    `
    )}
  }
  ${PanelContent} {
    margin-block: 0;
    margin-inline: auto;
    ${landscape(`

    margin-inline:  ${pxToRem(116)};
  `)}
    ${landscape(
      Device.tablet,
      `
    margin-inline:  ${clampValuesLandscape(103, 122)};
  `
    )}
  }
  .panelHeaderCloseButton {
    ${landscape(
      Device.tower,
      `
      inset-inline-end: ${pxToRem(30)};
      inset-block-start: ${pxToRem(100)};
      position: fixed;
    `
    )}
  }
  ${ListItemContainer} {
    ${landscape(`
      padding-inline: ${pxToRem(32)};
    `)}
  }

  ${CardProductContainerStyled}:not(:last-child) {
    margin-bottom: ${pxToRem(48)};
    ${tablet(`
      margin-bottom: ${clampValuesLandscape(24, 48)};
    `)}
  }
`
