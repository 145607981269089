import { IconSelectWrapper } from 'components/DigitalCouvette/WelcomePageDigitalCouvette/styles'
import { Text } from 'components/Typography'
import { useClickOutside } from 'hooks/useClickOutside'
import { pxToRem } from 'libs/styled'
import React, { ReactChild, useEffect, useRef, useState } from 'react'
import { CaretDownThinIcon, CaretUpThinIcon, CloseSelectIcon } from '../Icons'
import {
  OptionSlyled,
  SelectDropdown,
  SelectInnerStyled,
  SelectStyled,
  SelectWrapperStyled,
} from './styles'

interface SelectProps {
  className?: string
  placeholder?: string
  defaultValue?: string
  onSelect?: (value: string | number) => void
  forceVisible?: boolean
  value?: string
  required?: boolean
}

export const Select: React.FC<SelectProps> = ({
  className,
  placeholder = 'Select',
  defaultValue,
  onSelect,
  forceVisible,
  children,
  value: valueProp,
}) => {
  const [value, setValue] = useState<string | number>(valueProp || defaultValue || '')
  const [displayValue, setDisplayValue] = useState<string>(placeholder || '')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (typeof forceVisible !== 'undefined') {
      setIsOpen(forceVisible)
    }
  }, [forceVisible])

  useEffect(() => {
    setValue(valueProp || '')
  }, [valueProp])

  const innerRef = useRef<HTMLDivElement | null>(null)
  useClickOutside(innerRef, () => setIsOpen(false))

  const onSetValue = (val: string | number) => {
    setValue(val)
    onSelect && onSelect(val)
  }

  const childrenWithProps = React.Children.map(children, (child: ReactChild) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onSetValue,
        setDisplayValue,
        defaultValue: value,
      } as unknown)
    }
    return child
  })

  return (
    <SelectStyled className={className} onClick={() => setIsOpen(o => !o)}>
      <SelectInnerStyled
        role="combobox"
        aria-autocomplete="list"
        aria-expanded={isOpen}
        tabIndex={0}
        ref={innerRef}
      >
        <SelectWrapperStyled placeholder={displayValue}>
          <Text>{displayValue}</Text>
          {isOpen ? (
            <IconSelectWrapper>
              <CloseSelectIcon width={pxToRem(16)} height={pxToRem(16)} />
              <CaretUpThinIcon width={pxToRem(12)} height={pxToRem(14)} />
            </IconSelectWrapper>
          ) : (
            <CaretDownThinIcon width={pxToRem(12)} height={pxToRem(14)} />
          )}
        </SelectWrapperStyled>
        <SelectDropdown style={{ width: innerRef.current?.offsetWidth }} visible={isOpen}>
          {childrenWithProps}
        </SelectDropdown>
      </SelectInnerStyled>
    </SelectStyled>
  )
}

interface SelectOptionProps {
  onSetValue?: (val: string | number) => void
  setDisplayValue?: (val: string | number) => void
  value: string | number
  defaultValue?: string | number
}

export const SelectOption: React.FC<SelectOptionProps> = ({
  onSetValue,
  setDisplayValue,
  defaultValue,
  value,
  children,
  ...rest
}) => {
  function findLastChildTextNode(children: React.ReactNode): string {
    if ((children as React.ReactElement)?.props) {
      return findLastChildTextNode((children as React.ReactElement)?.props.children)
    }
    return children as string
  }

  const displayValue = findLastChildTextNode(children)

  useEffect(() => {
    if (!defaultValue) return
    if (defaultValue === value) {
      setDisplayValue?.(displayValue)
    }
  }, [setDisplayValue, defaultValue, value, displayValue])

  return (
    <OptionSlyled onClick={() => onSetValue?.(value)} {...rest}>
      {children}
    </OptionSlyled>
  )
}
