import ConditionalRender from 'components/ConditionalRender'
import { CloseIcon } from 'components/core/Icons'
import { pxToRem } from 'libs/styled'
import { NavigationContext } from 'providers/navigationProvider'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { resetCouvette, setCalibrationMethod, setShowCalibration } from '../../actions/couvette'
import { useActions } from '../../hooks/useActions'
import RotateDevice from '../RotateDeviceMessage'
import DigitalCouvetteCalibration from './DigitalCouvetteCalibration'
import WelcomePageDigitalCouvette from './WelcomePageDigitalCouvette'
import { useDigitalCouvetteAnalytics } from './analytics'
import { DigitalCouvetteModal } from './styles'
import { CalibrationMethods } from './types'

interface DigitalCouvetteProps {
  isVisible: boolean
  onCancel: () => void
}

export const DigitalCouvette: React.FC<DigitalCouvetteProps> = ({ isVisible, onCancel }) => {
  const { selectedFrame, showCalibration, calibrationMethod } = useSelector(s => s.couvette)
  const actions = useActions({
    setShowCalibration,
    setCalibrationMethod,
    resetCouvette,
  })
  const {
    onStartFrameCalibrationEvent,
    onStartRulerCalibrationEvent,
  } = useDigitalCouvetteAnalytics()

  const renderCloseIcon = () => <CloseIcon height={pxToRem(24)} width={pxToRem(24)} />
  const { landscapeNavigation } = useContext(NavigationContext)

  const onStartCalibration = () => {
    actions.setCalibrationMethod('frame')
    actions.setShowCalibration(true)
    onStartFrameCalibrationEvent()
  }

  const startRulerCalibration = () => {
    actions.setCalibrationMethod('ruler')
    actions.setShowCalibration(true)
    onStartRulerCalibrationEvent()
  }

  const resetCalibration = () => {
    actions.resetCouvette()
  }

  const handleCancel = () => {
    onCancel()
  }

  return (
    <DigitalCouvetteModal
      visible={isVisible}
      onCancel={handleCancel}
      renderCloseIcon={renderCloseIcon}
      className="couvette-modal"
      wrapClassName="no-overscroll"
      dataCalls="0"
    >
      {!landscapeNavigation ? (
        <RotateDevice onCancel={onCancel} />
      ) : (
        <>
          <ConditionalRender condition={!showCalibration}>
            <WelcomePageDigitalCouvette
              selectedFrame={selectedFrame}
              startCalibration={onStartCalibration}
              onClickRulerCalibration={startRulerCalibration}
            />
          </ConditionalRender>
          <ConditionalRender condition={showCalibration}>
            <DigitalCouvetteCalibration
              calibrationMethod={calibrationMethod as keyof typeof CalibrationMethods}
              selectedFrame={selectedFrame}
              onChangeFrame={resetCalibration}
              onBack={resetCalibration}
            />
          </ConditionalRender>
        </>
      )}
    </DigitalCouvetteModal>
  )
}
