import digitalCouvetteReferenceGlasses from 'assets/digitalCouvetteReferenceGlasses.svg'
import { Heading2, Heading5 } from 'components/Typography'
import { Modal } from 'components/core/Modal'
import { ModalContent } from 'components/core/Modal/styles'
import { clampValues, flexGapPolyfill, pxToRem } from 'libs/styled'
import styled from 'styled-components'
import { getWidthBasedOnSize } from '.'

const calculateAccurateMillimetersValue = (val: number) => {
  // Based on calculation found here: https://www.w3.org/TR/css3-values/#absolute-lengths
  // Not sure how accurate it is
  const cm = val / 10
  const px = cm * (96 / 2)
  return `${px}px`
}

export const CouvetteModal = styled(Modal)`
  width: 100vw !important; // necessary untill we move from ant to a different modal
  height: 100vh;
  padding-bottom: 0 !important;

  .ant-modal-content {
    width: 100%;
    height: 100vh;
    text-align: center;
    overflow-y: scroll;
  }

  ${ModalContent} {
    max-height: unset;
  }
`

export const CouvetteModalTitle = styled(Heading2)`
  margin: ${clampValues(20, 37)} 0 ${pxToRem(24)};
`

export const CouvetteModalText = styled(Heading5)`
  padding: 0 ${pxToRem(295)};
`

export const SizeList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${pxToRem(443)};
  margin: ${pxToRem(10)} 0 ${pxToRem(60)};
`

export const SizeCta = styled.div<{ selected: boolean }>`
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};
  text-transform: uppercase;
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.colors.royalBlue : theme.colors.mercury)};
  background-color: ${({ theme, selected }) => (selected ? theme.colors.royalBlue : 'transparent')};
  color: ${({ theme, selected }) => (selected ? theme.colors.white : theme.colors.black)};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'unset')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: ${pxToRem(4)};
  &:after {
    content: ${({ selected }) => (selected ? 'attr(data-measure)' : '')};
    color: ${({ theme }) => theme.colors.royalBlue};
    position: absolute;
    bottom: ${pxToRem(-36)};
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  }
`

export const CouvetteScaleContainer = styled.div`
  display: grid;
  position: relative;
  height: ${pxToRem(295)};
  grid-template-columns: ${calculateAccurateMillimetersValue(100)} ${calculateAccurateMillimetersValue(
      20
    )} ${calculateAccurateMillimetersValue(4)} ${calculateAccurateMillimetersValue(4)} ${calculateAccurateMillimetersValue(
      4
    )} ${calculateAccurateMillimetersValue(4)} ${calculateAccurateMillimetersValue(4)} ${calculateAccurateMillimetersValue(
      16
    )};
  width: ${calculateAccurateMillimetersValue(156)};
  margin: 0 auto;
  border-top: ${({ theme }) => `1px solid ${theme.colors.mercury}`};
  padding-top: 16px;
`

export const CouvetteGuideLine = styled.div<{ size: string }>`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right, #00000060 40%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 10px 3px;
  background-repeat: repeat-x;
  &:before {
    height: 100%;
    position: absolute;
    inset: 0;
    width: ${({ size }) => `${calculateAccurateMillimetersValue(getWidthBasedOnSize(size))}`};
    background: ${({ theme }) => theme.colors.royalBlue};
    content: '';
  }
  &:after {
    content: ${({ size }) => (size ? 'attr(data-measure)' : '')};
    position: absolute;
    width: ${({ size }) => `${calculateAccurateMillimetersValue(getWidthBasedOnSize(size))}`};
    height: 100%;
    inset: 0;
    color: ${({ theme }) => theme.colors.royalBlue};
    margin-top: ${pxToRem(8)};
  }
  svg {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 0;
  }
`

export const CouvetteScaleColumn = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: flex-start;
  padding-top: ${pxToRem(18)};
  justify-content: center;
  &:nth-of-type(1) {
    border-radius: ${pxToRem(16)} 0 0 ${pxToRem(16)};
  }
  &:nth-of-type(8) {
    border-radius: 0 ${pxToRem(16)} ${pxToRem(16)} 0;
  }
  &:nth-child(odd) {
    background-color: ${({ theme, selected }) =>
      selected ? theme.colors.titanWhite : theme.colors.mercury};
  }
  &:nth-child(even) {
    background-color: ${({ theme, selected }) =>
      selected ? theme.colors.titanWhite : theme.colors.wildSand};
  }
  > span {
    transform: rotate(90deg);
    text-transform: uppercase;
  }
`

export const CouvetteScaleDetailsTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${pxToRem(760)};
  margin: 0 auto ${pxToRem(16)};
`
export const CouvetteScaleDetailsTitle = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  row-gap: ${pxToRem(16)};
  gap: ${pxToRem(16)};
  ${flexGapPolyfill(pxToRem(8))}
  justify-content: space-evenly;
  svg.open {
    rotate: ${({ isOpen }) => (isOpen ? '180deg' : 'unset')};
  }
`

export const CouvetteScaleDetailsContent = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  row-gap: ${pxToRem(24)};
  gap: ${pxToRem(24)};
  ${flexGapPolyfill(pxToRem(12))}
  margin: ${pxToRem(16)} 0;
`

export const SizeListDescriptionText = styled.div`
  display: block;
  margin: ${clampValues(32, 40)} auto 0;
`

export const ReferenceGlassContainer = styled.div<{ size: string }>`
  position: absolute;
  width: ${({ size }) =>
    size ? `${calculateAccurateMillimetersValue(getWidthBasedOnSize(size))}` : '100%'};
  height: ${pxToRem(295)};
  background-image: ${`url(${digitalCouvetteReferenceGlasses})`};
  background-size: 100%;
  background-position: 0 36px;
  background-repeat: no-repeat;
  top: ${pxToRem(16)};
`

export const CouvetteScaleDetailsReferenceContainer = styled.div`
  display: flex;
  align-items: center;
  row-gap: ${pxToRem(16)};
  gap: ${pxToRem(16)};
  ${flexGapPolyfill(pxToRem(8))}
`

export const CouvetteDetailCard = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.mercury}`};
  border-radius: ${pxToRem(8)};
  display: flex;
  flex-direction: column;
  max-width: ${pxToRem(248)};
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(18)};
  min-width: ${pxToRem(248)};
  min-height: ${pxToRem(100)};
`
