import { FILTER_TYPE_BRAND, FILTER_TYPE_FLAG } from 'components/FiltersDrawer/filters.const'
import { Location } from 'history'
import { TFunction } from 'i18next'
import { capitalizeString, getProductBrandName, getSearchValueFromQs } from 'libs/utils'
import { FilterState } from 'types/filter'
import { Store } from 'types/store'

const disallowedCustomTitles = [
  'frameMaterial',
  'frameColor',
  'frameType',
  'frameShape',
  'lensColor',
  'lensTreatment',
  'faceShape',
  'price',
]

const removeDisallowCustomTitleFilters = (filters: FilterState[]) => {
  return filters?.filter(filter => {
    return !disallowedCustomTitles.includes(filter.key)
  })
}

export const getBrandNameFromStore = (store: Store | undefined, brandCode: string): string => {
  const brand = store && store.brands.find(b => b.id === brandCode)
  return brand ? brand.code : brandCode
}

export const getFilterOptionTitle = (
  filters: FilterState[],
  store: Store | undefined,
  t: TFunction
): string => {
  filters = removeDisallowCustomTitleFilters(filters)
  if (filters.length !== 1) return
  const filter = filters[0]

  switch (filter.type) {
    case FILTER_TYPE_BRAND: {
      const selectedOptions = filter.options?.filter(option => option.selected === true) || []
      if (selectedOptions.length !== 1) return
      const code = getBrandNameFromStore(store, selectedOptions[0].value)
      return getProductBrandName(code, t)
    }
    case FILTER_TYPE_FLAG: {
      const selectedOptions = filter.options?.filter(option => option.selected === true) || []
      if (selectedOptions.length !== 1) return
      return t(`PlpFilters.${filter.key}`)
    }
    default: {
      const selectedOptions = filter.options?.filter(option => option.selected === true) || []
      if (selectedOptions.length !== 1) return
      const option = selectedOptions[0]
      const fallbackTitle = t(option.label)
      const title = t(`PageTitle.${option.label.toLowerCase()}`, {
        defaultValue: fallbackTitle,
      })
      return title
    }
  }
}

export const getTitle = (
  filters: FilterState[],
  store: Store | undefined,
  location: Location,
  t: TFunction
): string => {
  const search = getSearchValueFromQs(location)
  if (search) return `${t('ProductsListPage.titleSearch')} ${search}`
  const filter = getFilterOptionTitle(filters, store, t)
  if (filter) {
    return capitalizeString(filter)
  }
  // Shop all as default
  return t('ProductsListPage.titleSearchall')
}
