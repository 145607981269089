import React from 'react'
import { IconProps } from 'types/icons'

export const ProductViewIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#555',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_23754_3068)">
        <path
          d="M10 0H1C0.4475 0 0 0.4475 0 1V10C0 10.5525 0.4475 11 1 11H10C10.5525 11 11 10.5525 11 10V1C11 0.4475 10.5525 0 10 0ZM10 10H1V1H10V10Z"
          fill={fill}
        />
        <path
          d="M10 13H1C0.4475 13 0 13.4475 0 14V23C0 23.5525 0.4475 24 1 24H10C10.5525 24 11 23.5525 11 23V14C11 13.4475 10.5525 13 10 13ZM10 23H1V14H10V23Z"
          fill={fill}
        />
        <path
          d="M23 0H14C13.4475 0 13 0.4475 13 1V10C13 10.5525 13.4475 11 14 11H23C23.5525 11 24 10.5525 24 10V1C24 0.4475 23.5525 0 23 0ZM23 10H14V1H23V10Z"
          fill={fill}
        />
        <path
          d="M23 12.996H14C13.4475 12.996 13 13.4435 13 13.996V22.996C13 23.5485 13.4475 23.996 14 23.996H23C23.5525 23.996 24 23.5485 24 22.996V13.996C24 13.4435 23.5525 12.996 23 12.996ZM23 22.996H14V13.996H23V22.996Z"
          fill={fill}
        />
      </g>
      <defs>
        <rect width={width} height={height} fill="white" />
      </defs>
    </svg>
  )
}
