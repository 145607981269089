import React from 'react'
import { IconProps } from 'types/icons'
export const FaThicknessThickIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 96 31"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M95.384 24.958L93.315 22.344C83.641 10.125 68.912 3 53.328 3H31V5H53.328C68.393 5 82.396 11.774 91.746 23.586L93.815 26.2C93.924 26.338 93.932 26.482 93.918 26.578C93.905 26.674 93.859 26.811 93.717 26.914L93.297 27.22C92.091 28.097 90.672 28.561 89.193 28.561C86.557 28.561 84.17 27.11 82.962 24.775L81.386 21.728C77.763 14.723 70.61 10.372 62.719 10.372C59.444 10.372 56.289 11.119 53.342 12.593L50.602 13.963L31 12.425V14.431L51 16L54.237 14.381C56.964 13.017 59.862 12.371 62.72 12.371C69.601 12.371 76.237 16.124 79.611 22.646L81.187 25.693C82.806 28.822 85.963 30.56 89.194 30.56C91.018 30.56 92.866 30.006 94.474 28.837L94.894 28.531C96.043 27.696 96.265 26.072 95.384 24.958Z"
        fill={fill}
      />
      <path d="M2 10.151V5H17V3H0V12L17 13.333V11.327L2 10.151Z" fill={fill} />
      <path d="M25 2H27.999V0H20V2H23V15H20V17H27.999V15H25V2Z" fill={fill} />
    </svg>
  )
}
