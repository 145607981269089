import React from 'react'
import { IconProps } from 'types/icons'
export const PriceIcon = ({ width = '1rem', height = '1rem' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_41758_10625)">
        <path
          d="M22.9586 0H14.0161C13.7401 0 13.4751 0.11 13.2796 0.3055L0.305119 13.3065C-0.101381 13.714 -0.101381 14.375 0.305119 14.7825L9.19812 23.694C9.40162 23.898 9.66812 24 9.93462 24C10.2001 24 10.4651 23.899 10.6681 23.697L23.6916 10.743C23.8891 10.5475 24.0001 10.2805 24.0001 10.0025V1.0435C24.0001 0.4675 23.5336 0 22.9586 0ZM23.0001 10.0025C23.0001 10.0145 22.9956 10.026 22.9871 10.0345L9.96362 22.988C9.95962 22.992 9.95112 23 9.93462 23C9.91812 23 9.91012 22.992 9.90612 22.988L1.01312 14.0765C0.996119 14.0595 0.996119 14.0305 1.01312 14.013L13.9871 1.012C13.9951 1.0045 14.0051 1 14.0161 1H22.9586C22.9816 1 23.0001 1.0195 23.0001 1.0435V10.0025Z"
          fill="black"
        />
        <path
          d="M19.0001 3C17.8971 3 17.0001 3.897 17.0001 5C17.0001 6.103 17.8971 7 19.0001 7C20.1031 7 21.0001 6.103 21.0001 5C21.0001 3.897 20.1031 3 19.0001 3ZM19.0001 6C18.4486 6 18.0001 5.5515 18.0001 5C18.0001 4.4485 18.4486 4 19.0001 4C19.5516 4 20.0001 4.4485 20.0001 5C20.0001 5.5515 19.5516 6 19.0001 6Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_41758_10625">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
