import config from 'config'
import { FrameAdvisorTip, LikeStatus } from 'types/frameAdvisor'
import { Product } from 'types/product'

export const BEST_MATCH_COUNT = config.bestMatchCount
const availableTips = Object.values(FrameAdvisorTip)

interface GetSuggestionsOrderProps {
  products: Product[]
  tipGrids?: FrameAdvisorTip[]
  columns: number
}

export interface FALoveHateProduct extends Product {
  status?: LikeStatus
}

export interface SortedProduct extends FALoveHateProduct {
  isBestMatch: boolean
}

type SuggestionTile = SortedProduct | FrameAdvisorTip

export const checkIfTipTile = (
  tile: SuggestionTile,
  tipGrids = availableTips
): tile is FrameAdvisorTip => {
  return tipGrids.includes(tile as FrameAdvisorTip)
}

export const getSuggestionsOrder = ({
  products,
  columns,
  tipGrids = availableTips,
}: GetSuggestionsOrderProps) => {
  const modifiedProducts = products.map(p => {
    return {
      ...p,
      isBestMatch: isBestMatch(p),
    }
  })

  const sortedProducts: SuggestionTile[] = []

  let weightCounter = 0
  let i = 0
  let tipI = 0

  const putTip = () => {
    // put tips in loop
    if (tipI < tipGrids.length) {
      const tipTile = tipGrids[tipI]
      sortedProducts.push(tipTile)
      tipI++
    } else {
      const tipTile = tipGrids[0]
      sortedProducts.push(tipTile)
      tipI = 1
    }
  }

  while (i < modifiedProducts.length) {
    const product = modifiedProducts[i]
    const isExtended = isBestMatch(product)
    const weight = isExtended ? 2 : 1
    const totWeight = weightCounter + weight

    if (totWeight === columns) {
      sortedProducts.push(product)
      i++
      weightCounter = 0
    }

    if (totWeight < columns) {
      sortedProducts.push(product)
      weightCounter = weightCounter + weight
      i++
    }

    if (totWeight > columns) {
      putTip()
      weightCounter = 0
    }
  }

  // case when last row is not filled
  if (weightCounter > 0) {
    const tipsToAddNumber = columns - weightCounter
    for (let i = tipsToAddNumber; i > 0; i--) {
      putTip()
    }
  }

  return sortedProducts
}
export const isBestMatch = (product: Product) =>
  product.enforcementBullets?.length >= BEST_MATCH_COUNT
