import { pxToRem } from 'libs/styled'
import styled from 'styled-components'
import {
  Caption,
  Heading1,
  Heading4,
  Heading5,
  LinkTypography,
  ListTypography,
} from 'components/Typography'
import { SelectDropdown, SelectInnerStyled, SelectStyled } from 'components/core/Select/styles'
import { Device, landscape } from 'libs/media'

export const ContainerStartPageDigitalCouvette = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(90)} ${pxToRem(40)} ${pxToRem(142)};
  gap: ${pxToRem(42)};
`

export const StyledCaption = styled(Caption)`
  text-transform: uppercase;
  text-align: left;
  padding-left: ${pxToRem(16)};
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(4)};
  margin-bottom: ${pxToRem(16)};

  ${SelectStyled} {
    width: ${pxToRem(410)};
  }

  ${SelectInnerStyled} {
    border-radius: 4px;
  }

  ${SelectDropdown} {
    text-align: left;
    left: 0;
    border: ${({ theme }) => `1px solid ${theme.colors.frenchGrey}`};
    border-radius: 0 0 4px 4px;
    box-shadow: 0 5.891px 11.782px 0 rgba(96, 97, 112, 0.16), 0 0 2.945px 0 rgba(40, 41, 61, 0.04);

    ${landscape(
      Device.tablet,
      `
    max-height: ${pxToRem(196)};
  `
    )}
  }

  ${ListTypography} {
    padding: ${pxToRem(12)} ${pxToRem(16)};
  }
`

export const DigitalCouvetteHeading1 = styled(Heading1)`
  font-size: ${pxToRem(66)}!important;
  line-height: ${pxToRem(76)}!important;
  width: ${pxToRem(662)}!important;
`

export const DigitalCouvetteHeading4 = styled(Heading4)`
  font-size: ${pxToRem(34)}!important;
  line-height: ${pxToRem(44)}!important;
`

export const DigitalCouvetteHeading5Styled = styled(Heading5)`
  font-size: ${pxToRem(28)}!important;
  line-height: ${pxToRem(30)}!important;
  height: ${pxToRem(76)}!important;
`
export const DigitalCouvetteSelectWrapper = styled(SelectWrapper)`
  margin-bottom: ${pxToRem(46)}!important;
`
export const DigitalCouvetteStyledCaption = styled(StyledCaption)`
  font-size: ${pxToRem(22)}!important;
  line-height: ${pxToRem(26)}!important;
`

export const DigitalCouvetteLinkTypography = styled(LinkTypography)`
  font-size: ${pxToRem(26)}!important;
  line-height: ${pxToRem(46)}!important;
`

export const IconSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${pxToRem(8)};
`

export const Bold = styled.span`
  font-weight: bold;
`
