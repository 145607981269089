import { getThumbnailUrlForCustom } from 'libs/customProducts'
import { getProductImages } from 'libs/image'
import * as React from 'react'
import { CustomProduct } from 'types/product'
import { StyledImg } from './ProductImage.style'

interface ProductImageProps {
  dressedOnImageUrl?: string
  product: CustomProduct
}

export const ProductImage: React.FC<ProductImageProps> = ({ dressedOnImageUrl, product }) => {
  const defaultRenderImage = () => {
    const { customItem, recipeId, originalBrand } = product

    const image = getProductImages(product, [300, 600, 900])
    const customImage = getThumbnailUrlForCustom(recipeId, originalBrand)
    const isCustomImage = customItem && recipeId

    return (
      <>
        {isCustomImage ? (
          <StyledImg alt={product.name || ''} src={customImage} />
        ) : (
          <StyledImg
            alt={product.name || ''}
            src={`${image[0][1].file}`}
            srcSet={`${image[0][0].file} 1x, ${image[0][1].file} 2x, ${image[0][2].file} 3x`}
          />
        )}
      </>
    )
  }
  const renderDressedOnImage = (url: string) => (
    <StyledImg alt={'vm-image'} aria-label={'vm-image'} aria-description={'vm-image'} src={url} />
  )
  return dressedOnImageUrl ? renderDressedOnImage(dressedOnImageUrl) : defaultRenderImage()
}
