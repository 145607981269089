import { pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const TextareaField = styled.textarea`
  color: ${({ theme }) => theme.colors.secondaryText};
  padding: 0.23rem;
  border: 1px solid ${({ theme }) => theme.checkout.input_border_color};
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: ${pxToRem(144)};
  resize: none;
  font-size: ${({ theme }) => theme.fonts.sizes.smallest};
  line-height: 1.56rem;
`
