import React from 'react'
import {
  CheckboxContainer,
  CheckboxDescription,
  CheckboxWrapper,
  HiddenCheckbox,
  Icon,
  StyledCheckbox,
} from './styles'

export type CheckboxWithLabelProps = React.ComponentProps<typeof HiddenCheckbox> & {
  className?: string
  description?: React.ReactElement | string
}

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  className,
  children,
  disabled,
  checked,
  description,
  ...props
}) => (
  <>
    <CheckboxWrapper disabled={disabled} className={className}>
      <CheckboxContainer>
        <HiddenCheckbox checked={checked} disabled={disabled} {...props} />
        <StyledCheckbox checked={checked}>
          <Icon width="1em" height="1em" viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
      {children}
    </CheckboxWrapper>
    {description && (
      <>
        {typeof description === 'string' ? (
          <CheckboxDescription
            textSize="body"
            textSizeTablet="bodyTablet"
            textSizeTabletMini="bodyTabletMini"
            disabled={disabled}
          >
            {description}
          </CheckboxDescription>
        ) : (
          description
        )}
      </>
    )}
  </>
)
