const colors = {
  dark: '#222',
  grey: '#e2e2e5',
  mediumGrey: '#474747',
  lightGrey: '#f5f5f5',
  white: '#fff',
  magenta: '#9a142d',
  blue: '#005192',
}

const fonts = {
  family: '"Roboto", sans-serif',
  size: '18px',
  weight: '300',
}

export const darkTheme = {
  fontFamily: fonts.family,
  fontWeight: fonts.weight,
  fontSize: fonts.size,
  backgroundColor: colors.grey,

  button: {
    color: colors.white,
    height: '60px',
    borderColor: colors.mediumGrey,
    borderRadius: '4px',
    width: '100px',
    backgroundColor: colors.dark,
    activeBackgroundColor: colors.mediumGrey,
    submitBackgroundColor: colors.magenta,
    activeColor: colors.white,
    submitColor: colors.white,
  },
}

export const lightTheme = {
  fontFamily: fonts.family,
  fontWeight: fonts.weight,
  fontSize: fonts.size,
  backgroundColor: colors.grey,

  button: {
    color: colors.dark,
    height: '60px',
    borderColor: colors.lightGrey,
    borderRadius: '0',
    width: '100px',
    backgroundColor: colors.lightGrey,
    activeBackgroundColor: colors.blue,
    submitBackgroundColor: colors.blue,
    activeColor: colors.white,
    submitColor: colors.white,
  },
}
