import { css, DefaultTheme, SeparatedTheme, ThemeColors } from 'styled-components'
import { DeepPartial } from 'types/utils'
import { Device, DeviceMap } from './media'
import { isIPadView } from './utils'
import { getChromiumVersion } from './virtualMirror'

// todo: change to 16 when html font-size is changed
const PXTOREM_RATIO = 22

export const clampBuilder = (
  minWidthPx: number,
  maxWidthPx: number,
  minPxSize: number,
  maxPxSize: number
) => {
  // TODO: put this kiosk logic into a separate function
  const chromiumVersion = getChromiumVersion()
  const isNotUsingKiosk = chromiumVersion === 0 // we added this case for testing purpose,
  // sometimes tests get run in the browser ouside a kiosk, in this case we want
  // the clamp to be enabled
  const isClampEnabled = (chromiumVersion > 79 && isIPadView()) || isNotUsingKiosk

  const minWidth = minWidthPx / PXTOREM_RATIO
  const maxWidth = maxWidthPx / PXTOREM_RATIO

  const minRemWidth = minPxSize / PXTOREM_RATIO
  const maxRemWidth = maxPxSize / PXTOREM_RATIO

  const slope = (maxRemWidth - minRemWidth) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minRemWidth

  const calculatedPreferredValue = `${yAxisIntersection.toFixed(4)}rem + ${(slope * 100).toFixed(
    4
  )}vw`

  return isClampEnabled
    ? `clamp(${Math.min(minRemWidth, maxRemWidth)}rem, ${calculatedPreferredValue}, ${Math.max(
        minRemWidth,
        maxRemWidth
      )}rem)`
    : `${maxRemWidth}rem`
}

export const clampValues = (min: number, max: number) => {
  return clampBuilder(768, 1024, min, max)
}

export const clampValuesLandscape = (min: number, max: number) => {
  return clampBuilder(1024, 1366, min, max)
}

export const pxToRem = (pxValue: number) => {
  //This ratio is the one used as a base font for the body to calculate all the rem sizes to use
  return `${(pxValue / PXTOREM_RATIO).toFixed(5)}rem`
}

export type MapDevicePx = { [key in Device]?: number }

// eslint-disable-next-line @typescript-eslint/ban-types
const enumKeys = <I extends object, O extends keyof I>(obj: I): O[] => {
  return Object.keys(obj) as O[]
}

export const pxByDevice = (mapDevicePx: MapDevicePx) => {
  for (const device of enumKeys(mapDevicePx)) {
    if (window.matchMedia(DeviceMap[device]).matches) {
      return mapDevicePx[device]
    }
  }
  return mapDevicePx.tower
}

export const checkColor = (
  color: string,
  theme: DefaultTheme,
  separatedThemeName?: SeparatedTheme
) => {
  const colorsObject = separatedThemeName ? theme[separatedThemeName].colors : theme.colors
  const isColorFromTheme = colorsObject.hasOwnProperty(color) || false
  const textColor = isColorFromTheme ? colorsObject[color as keyof DeepPartial<ThemeColors>] : color
  return textColor
}

/**
 * Calculate the percentage of a value out of the max value.
 *
 * @example
 * calculatePercentage(10, 100) // 10%
 * calculatePercentage(28, 482) // 5.80%
 *
 * @param value The value to calculate in percentage
 * @param maxValue The max value that can be set
 * @returns The percentage of the value from the max value
 */
export const calculatePercentage = (value: number, maxValue: number): string => {
  const percentage = (value / maxValue) * 100

  return `${percentage.toFixed(2)}%`
}
export const withLineClamp = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`

export const lineClamp = (rows: number) => css`
  line-clamp: ${rows};
  -webkit-line-clamp: ${rows};
`
export const flexGapPolyfill = (gap: string, direction?: 'column' | 'row') => {
  let margin = 'margin: 0'
  const isGapSupported = getChromiumVersion() > 84 // 84 is the minumum version where gap was introduced for chrome
  if (!isGapSupported) {
    switch (direction) {
      case 'column':
        margin = `margin: 0 0 ${gap} 0`
        break
      case 'row':
        margin = `margin: 0 ${gap} 0 0`
        break
      default:
        margin = `margin: ${gap}`
        break
    }
    return `
      & > * {
        ${margin}
        &:last-child  {
          margin: unset;
        }
      }
    `
  }
  switch (direction) {
    case 'row':
      return `column-gap: ${gap};`
    case 'column':
      return `row-gap: ${gap};`
    default:
      return `gap: ${gap};`
  }
}

export const flexGapPolyfillCleanup = () => {
  return `
    column-gap: unset;
    row-gap: unset;
    gap: unset;
    & > * {
        margin: unset;
      }
    }
  `
}
