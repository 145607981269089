import React from 'react'
import useImage from 'use-image'

interface ImageProps {
  src: string
}

export const Image: React.FC<
  ImageProps & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = ({ src, ...props }) => {
  const [image, status] = useImage(src)

  if (status === 'loaded' && image) return <img src={image.src} {...props} />
  return null
}
