import { useHomePageChecker } from 'hooks/useHomePageChecker'
import { createMouseEvents, executeOnce, simulateEvents } from 'libs/utils'
import { useReset } from 'providers/resetProvider'
import { useEffect } from 'react'

const mouseEvents = ['mousedown', 'mousemove', 'click']

export const useSimulateFirstInteraction = (clientX?: number, clientY?: number): void => {
  const { userInteractedAfterReset } = useReset()
  const { isHomePage } = useHomePageChecker()

  useEffect(() => {
    if (clientX && clientY && userInteractedAfterReset && isHomePage) {
      executeOnce(() => {
        // get  the topmost element
        const element = document.elementFromPoint(clientX, clientY)
        if (element) {
          setTimeout(() => {
            simulateEvents(element, createMouseEvents(mouseEvents))
          }, 0)
        }
      }, 'homepage-first-interaction')()
    }
  }, [clientX, clientY, userInteractedAfterReset, isHomePage])
}
