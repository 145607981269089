import { FilterKind } from 'components/FiltersDrawer/filters.const'
import { Location as LocationHistory } from 'history'
import { ConformedBrandsList } from 'types/brand'
import { BrandFilterOption, FilterOption, FilterOptionsComparator } from 'types/filter'
import { Brand, Maybe } from 'types/graphqlTypes'
import { getPLPFiltersKindAndValueFromLocation } from './url'

export const isTrueOption = (filterValue: string[] | string | undefined): boolean => {
  let isTrueOption = false
  if (!filterValue) return false

  if (Array.isArray(filterValue)) {
    filterValue.forEach(val => {
      isTrueOption = ['true', 'TRUE', '1'].includes(val)
    })
  } else isTrueOption = ['true', 'TRUE', '1'].includes(filterValue)

  return isTrueOption
}

export const isFilterRange = (filterkind: FilterKind): boolean =>
  ['maxPrice', 'minPrice'].includes(filterkind)

const SIZE_ORDER_MAP = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']
const DESIRED_ORDER_GEO_FIT = [
  'High Bridge Fit',
  'Low Bridge Fit',
  'Universal Fit',
  'Adjustable Nosepads',
]

const sizeOptionsComparator: FilterOptionsComparator = (a, b) => {
  if (a.translatedLabel && b.translatedLabel) {
    const aNum = parseInt(a.translatedLabel)
    const bNum = parseInt(b.translatedLabel)
    const isNumA = !isNaN(aNum)
    const isNumB = !isNaN(bNum)
    if (isNumA && isNumB) return aNum - bNum
    if (isNumA) return -1
    if (isNumB) return 1
    return (
      SIZE_ORDER_MAP.indexOf(a.translatedLabel.toUpperCase()) -
      SIZE_ORDER_MAP.indexOf(b.translatedLabel.toUpperCase())
    )
  }
}

const genderOptionsComparator: FilterOptionsComparator = (a, b) => {
  if (a.value > b.value) {
    return 1
  } else if (a.value < b.value) {
    return -1
  } else return 0
}

const geoFitOptionsComparator: FilterOptionsComparator = (a, b) => {
  const aIndex = DESIRED_ORDER_GEO_FIT.indexOf(a.value)
  const bIndex = DESIRED_ORDER_GEO_FIT.indexOf(b.value)
  return aIndex - bIndex
}

const childAgeRangeFitOptionsComparator: FilterOptionsComparator = (a, b) => {
  return a.label > b.label ? 1 : -1
}

export const defaultOptionsComparator: FilterOptionsComparator = (a, b) => {
  if (a.translatedLabel && b.translatedLabel) {
    return a.translatedLabel.localeCompare(b.translatedLabel)
  }
  return 0
}

const optionFilterMap: { [kind in FilterKind]?: FilterOptionsComparator } = {
  geoFit: geoFitOptionsComparator,
  gender: genderOptionsComparator,
  frameSizeFamily: sizeOptionsComparator,
  childAgeRange: childAgeRangeFitOptionsComparator,
}

export const getFilterOptionsComparator = (filterKind: FilterKind): FilterOptionsComparator => {
  return optionFilterMap[filterKind] || defaultOptionsComparator
}

const optionFilterModificator: { [kind in FilterKind]?: (label: string) => string } = {
  frameSizeFamily: (v: string) => v.toUpperCase(),
}

export const getFilterLabel = (
  filterKind: string,
  option: FilterOption | BrandFilterOption
): string => {
  const modificator = optionFilterModificator[filterKind]
  const defaultLabel = option.label || option.value
  return modificator ? modificator(defaultLabel) : defaultLabel
}

export const getKindFilterValue = (
  location: LocationHistory,
  filterKind: FilterKind
): string | undefined => {
  const activeFilters = getPLPFiltersKindAndValueFromLocation(location)
  const genderFilter = activeFilters && activeFilters.find(el => el.kind === filterKind)
  return genderFilter && (genderFilter.value as string)
}

export const getConformedBrandsList = (brands: Brand[]): ConformedBrandsList => {
  return brands.reduce((acc, mainBrand) => {
    const mappedSubBrands = {}
    mainBrand.brands?.forEach(
      (subBrand: Brand) =>
        (mappedSubBrands[subBrand.code.toUpperCase()] = mainBrand.code.toUpperCase())
    )
    return { ...acc, ...mappedSubBrands }
  }, {})
}

export const isInArrayOrEqual = <T>(arrayOrValue: T | T[], value: T) => {
  if (Array.isArray(arrayOrValue)) {
    return arrayOrValue.includes(value)
  }
  return arrayOrValue === value
}

export const getMainBrandFromSubBrands = (
  subFilters: Maybe<string>,
  brandsList: ConformedBrandsList
): Maybe<string> => {
  if (!subFilters) {
    return
  }
  const firstSubBrandFromFilters = subFilters.split(',')[0]
  return brandsList[firstSubBrandFromFilters]
}

export const isInBrandArray = (
  arrayOrValue: string | string[],
  value: string,
  brands: ConformedBrandsList
): boolean => {
  if (Array.isArray(arrayOrValue)) {
    const brandsFilters = arrayOrValue.map(subBrandsFilters =>
      getMainBrandFromSubBrands(subBrandsFilters, brands)
    )
    return brandsFilters.includes(value)
  }
  return getMainBrandFromSubBrands(arrayOrValue, brands) === value
}

export const toArray = <T>(arrayOrValue: T | T[] | undefined) => {
  return arrayOrValue ? ([] as T[]).concat(arrayOrValue) : []
}
