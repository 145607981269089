import { useCallback, useEffect, useState } from 'react'

function getLocalStorageValue<T>(key: string) {
  const item = window.localStorage.getItem(key)
  return item ? (JSON.parse(item) as T) : null
}

function setLocalStorageValue<T>(key: string, valueToStore: T) {
  window.localStorage.setItem(key, JSON.stringify(valueToStore))
}

const listeners: Record<string, Array<(value: unknown) => void>> = {}

function setValue<T>(key: string, value: T) {
  setLocalStorageValue<T>(key, value)
  listeners[key].forEach(listener => {
    listener(value)
  })
}

export function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
  let storedValue = getLocalStorageValue<T>(key)
  if (storedValue === null) {
    storedValue = initialValue
    setLocalStorageValue(key, storedValue)
  }

  const newListener = useState<unknown>(initialValue)[1]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setStoredValue = useCallback((value: T) => setValue<T>(key, value), [key])

  useEffect(() => {
    listeners[key] = listeners[key] || []
    listeners[key].push(newListener)

    return () => {
      listeners[key] = listeners[key].filter(l => l !== newListener)
    }
  }, [newListener, key])

  return [storedValue, setStoredValue]
}
