import { SLIDE_CHANGE_ANIMATION_TIME } from 'libs/carousel'
import Carousel, { CarouselProps } from 'nuka-carousel'
import React from 'react'
import { createGlobalStyle } from 'styled-components'

const GlobalNukaCarouselCss = createGlobalStyle`
  .slider-container {
    height: 100%;
    width: 100%;

    & .slider-frame {
      height: 100% !important;
    }
  }

  .slider-list {
    height: 100%;
  }

  .hidden {
    display: none;
  }
`

interface NukaCarouselProps {
  autoplay?: boolean
  animationTime?: number
  loop?: boolean
  withoutBullets?: boolean
  withoutButtons?: boolean
  innerRef?: React.MutableRefObject<HTMLDivElement | null>
}

const defaultCarouselProps = {
  speed: 500,
  dragThreshold: 0.2,
}

export const NukaCarousel: React.FC<Omit<CarouselProps, 'innerRef'> & NukaCarouselProps> = ({
  children,
  autoplay = false,
  animationTime = SLIDE_CHANGE_ANIMATION_TIME,
  loop,
  withoutBullets = true,
  withoutButtons = true,
  ...props
}) => {
  const { defaultControlsConfig, innerRef, ...carouselProps } = props

  return (
    <>
      <GlobalNukaCarouselCss />
      <Carousel
        innerRef={innerRef as React.MutableRefObject<HTMLDivElement>}
        autoplay={autoplay}
        autoplayInterval={animationTime}
        wrapAround={loop}
        withoutControls={withoutBullets && withoutButtons}
        defaultControlsConfig={{
          prevButtonClassName: withoutButtons ? 'hidden' : '',
          nextButtonClassName: withoutButtons ? 'hidden' : '',
          pagingDotsContainerClassName: withoutBullets ? 'hidden' : '',
          ...(defaultControlsConfig ? { ...defaultControlsConfig } : {}),
        }}
        {...defaultCarouselProps}
        {...carouselProps}
      >
        {children}
      </Carousel>
    </>
  )
}
