import { ColorSwatchWrapper } from 'components/FrameAdvisor/ColorElements/ColorSwatch'
import { Heading5, Text } from 'components/Typography'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const MerchCardWrapper = styled.div`
  padding-block: ${pxToRem(24)};
  padding-inline: ${pxToRem(24)};
  margin-inline: 0;
  border-radius: ${pxToRem(15)};
  border: ${pxToRem(1)} solid ${({ theme }) => theme.colors.primaryText};
  background: #f2f2f2;
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  gap: ${pxToRem(24)};
  ${tablet(`
     padding-block-start: 0;
     padding: ${clampValues(18, 24)}};
  `)}
  ${landscape(
    Device.tablet,
    `
     padding: ${clampValuesLandscape(18, 24)}};
  `
  )}
`

export const MerchCardImageWrapper = styled.div`
  flex: 1;
  inline-size: 100%;
  block-size: 100%;

  img {
    inline-size: 100%;
    block-size: 100%;
  }
`

export const MerchCardPrice = styled.div`
  align-self: flex-end;
`

export const MerchCardContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${pxToRem(16)};
  align-items: flex-start;
`

export const MerchColorSwatch = styled.button<{ active: boolean }>`
  ${({ active, theme }) =>
    active &&
    `
    padding: ${pxToRem(2)};
    border: ${pxToRem(1)} solid ${theme.colors.primaryText};
    border-radius: 50%;
  `}

  ${ColorSwatchWrapper} {
    inline-size: ${pxToRem(20)};
    block-size: ${pxToRem(20)};
    outline: ${pxToRem(1)} solid ${({ theme }) => theme.colors.primaryText};
    outline-offset: ${pxToRem(1)};
  }
`

export const MerchCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
`

export const MerchSwatchesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${pxToRem(32)};
`

export const MerchCardDescription = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

export const MerchCardColorSwitchTitle = styled(Text)`
  span {
    font-weight: bold;
  }
`

export const MerchCardCTAWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  inline-size: fit-content;
  align-self: flex-end;
`

export const MerchNoAccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-inline: ${pxToRem(32)};
  padding-block: ${pxToRem(60)} ${pxToRem(48)};
  gap: ${pxToRem(48)};
  background-color: #f2f2f2;
  ${Heading5} {
    margin-block: 0 ${pxToRem(60)};
  }
`
