import styled from 'styled-components'
import { PrimaryButton } from '../core/Button'

export const ProductItemContainer = styled.div`
  padding: 0.68rem 1rem;
  width: 50%;
`

export const WishlistAddToCart = styled(PrimaryButton)`
  margin-left: auto;
`
