import { Heading3, Heading5 } from 'components/Typography'
import { PrimaryButton, SecondaryButton } from 'components/core/Button'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDigitalCouvetteAnalytics } from '../analytics'
import { Popup, PopupFooter } from './styles'

interface Props {
  visible: boolean
  onClose: () => void
  onConfirm: () => void
}
const RestartCalibrationPopup: FC<Props> = ({ visible, onClose, onConfirm }) => {
  const { t } = useTranslation()

  const {
    resetCalibrationEvent,
    onCancelResetCalibrationEvent,
    onConfirmResetCalibrationEvent,
  } = useDigitalCouvetteAnalytics()

  useEffect(() => {
    if (visible) {
      resetCalibrationEvent()
    }
  }, [resetCalibrationEvent, visible])

  const handleClose = () => {
    onCancelResetCalibrationEvent()
    onClose()
  }

  const handleConfirm = () => {
    onConfirmResetCalibrationEvent()
    onConfirm()
  }

  return (
    <Popup visible={visible} onCancel={onClose} removeCloseBtn>
      <Heading3>{t('DigitalCouvette.calibrationComplete.restartTitle')}</Heading3>
      <Heading5>{t('DigitalCouvette.calibrationComplete.restartDesc')}</Heading5>
      <PopupFooter>
        <SecondaryButton onClick={handleClose}>{t('NavBar.backButton')}</SecondaryButton>
        <PrimaryButton onClick={handleConfirm}>
          {t('DigitalCouvette.calibrationComplete.restart')}
        </PrimaryButton>
      </PopupFooter>
    </Popup>
  )
}

export default RestartCalibrationPopup
