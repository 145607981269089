import { clearCartItemOverlay } from 'actions/cart'
import config from 'config'
import { useActions } from 'hooks/useActions'
import { useGetProductBrandName } from 'libs/utils'
import React, { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { CartState } from 'reducers/cart'
import styled from 'styled-components'
import { pxToRem } from '../../libs/styled'
import { CartItem } from '../../types/cart'
import { ProductCard } from '../CardProductBase'
import { MiniCartPrice } from '../MiniCartPrice'

interface MinicartProductCardProps {
  cartItem: CartItem
  handleRemoveButtonClick: () => void
  showPrice: boolean

  showRTRIcon: boolean
}

const MiniCartPriceStyled = styled(MiniCartPrice)`
  max-width: ${pxToRem(150)};
`

export const MinicartProductCard: React.FC<MinicartProductCardProps> = ({
  cartItem,
  handleRemoveButtonClick,
  showPrice,
  showRTRIcon,
}) => {
  const { product } = cartItem
  const { UPC, brand, name } = product
  const getProductBrandName = useGetProductBrandName()
  const { cartItemOverlayId }: CartState = useSelector(s => s.cart)
  const showAddedToBagOverlay = useMemo(
    () =>
      config.newMerchAccessoriesBag &&
      config.newMerchAccessoriesBag.toLowerCase() === 'true' &&
      cartItem.id === cartItemOverlayId,
    [cartItemOverlayId, cartItem]
  )
  const actions = useActions({ clearCartItemOverlay })
  const t = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (showAddedToBagOverlay) {
      t.current = setTimeout(() => {
        actions.clearCartItemOverlay()
      }, 300)
    }

    return () => {
      clearTimeout(t.current)
    }
  }, [actions, showAddedToBagOverlay])

  const dataAttrs = {
    link: 'MainNav_BagProd-Img',
    removeBtn: 'MainNav_BagProd-Remove',
    description: `${getProductBrandName(brand.code)}_${name}_${UPC}`,
  }
  return (
    <ProductCard
      product={product}
      dataAttrs={dataAttrs}
      handleRemoveButtonClick={handleRemoveButtonClick}
      showRemoveButton
      showProductModel
      showIcons={true}
      priceStyle="default"
      showRTRIcon={showRTRIcon}
      isOppositeIconArrangement={true}
      showAddedToBagOverlay={showAddedToBagOverlay}
      customPrice={
        <MiniCartPriceStyled
          showPrice={showPrice}
          quantity={cartItem.quantity}
          cartItem={cartItem}
          showQuantity
          showVAT
          showSuggested
        />
      }
    />
  )
}
