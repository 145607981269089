import React from 'react'
import { IconProps } from 'types/icons'

export const GoldMetalsIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <circle cx="50%" cy="50%" r="50%" fill="url(#goldMetal)" />
      <defs>
        <linearGradient
          id="goldMetal"
          x1={47.85}
          y1={52.563}
          x2={5.8}
          y2={3.263}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.047} stopColor="#C69516" />
          <stop offset={0.484} stopColor="#ECC665" stopOpacity={0.692} />
          <stop offset={0.646} stopColor="#F8D885" stopOpacity={0.4} />
          <stop offset={0.766} stopColor="#F2D17A" stopOpacity={0.7} />
          <stop offset={1} stopColor="#D0B140" />
        </linearGradient>
      </defs>
    </svg>
  )
}
