import React from 'react'
import { IconProps } from 'types/icons'

export const MenuIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = 'none',
  stroke = '#333',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 33 29"
      aria-label="menuIcon"
      role="button"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M1 .762h30.549M1 9.762h30.549M1 18.762h30.549M1 27.762h30.549"
      ></path>
    </svg>
  )
}
