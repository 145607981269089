import React from 'react'
import { IconProps } from 'types/icons'
export const FaFaceShapeRectangleWomanIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M47.707 36.293C45.736 34.322 45.001 25.867 45 21C45 9.42 35.58 0 24 0C12.42 0 3 9.42 3 21C2.999 25.867 2.264 34.322 0.293 36.293L0 36.586V37C0 43.065 4.935 48 11 48C13.418 48 15.648 47.207 17.465 45.88C17.913 45.958 18.373 46 18.841 46H29.26C29.698 46 30.127 45.96 30.548 45.891C32.364 47.211 34.589 48 37 48C43.065 48 48 43.065 48 37V36.586L47.707 36.293ZM18.842 44C15.941 44 13.458 41.928 12.939 39.074L9.457 19.92C14.98 19.315 18.986 15.368 20.421 11.743C22.472 14.339 27.412 18.222 39.026 19.771L35.145 39.176C34.586 41.972 32.112 44 29.261 44H18.842ZM37 46C35.566 46 34.214 45.654 33.01 45.055C35.072 43.955 36.625 41.978 37.106 39.569L41.198 19.109L40.334 17.92C23.145 15.913 20.987 8.817 20.97 8.757L19 9C19 12.526 14.486 17.858 8.25 17.998L7.289 19.179L10.971 39.431C11.418 41.89 12.961 43.907 15.038 45.032C13.822 45.646 12.453 46 11 46C6.161 46 2.202 42.162 2.007 37.37C4.914 33.734 5 22.329 5 21C5 10.523 13.523 2 24 2C34.477 2 43 10.523 43 21C43 22.329 43.086 33.734 45.993 37.37C45.798 42.162 41.839 46 37 46Z"
        fill={fill}
      />
      <path d="M26.434 23.057H24.434V31H21.986V33H26.434V23.057Z" fill={fill} />
      <path
        d="M27.312 36.928L24 35.958L20.688 36.928L21.879 38.12C22.445 38.688 23.198 39 24 39C24.802 39 25.555 38.688 26.122 38.121L27.312 36.928Z"
        fill={fill}
      />
    </svg>
  )
}
