import { toggleProfileRecapDrawer as toggleProfileRecapDrawerAction } from 'actions/ui'
import { QuizProfileIcon } from 'components/core/Icons/QuizProfile'
import { Logo, LogoColors } from 'components/Logo'
import { useAction } from 'hooks/useActions'
import { useFsaProfilePicture } from 'hooks/useFsaProfilePicture'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { pxToRem } from 'libs/styled'
import { isIPadView } from 'libs/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  HeaderContainer,
  ProfileAvatar,
  ProfileAvatarContainer,
  ProfileAvatarLabel,
  ProfileAvatarWrapper,
} from './styles'
import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'
import ProfilePopUp from 'components/FrameAdvisor/FrameAdvisorProfileHeader/ProfilePopUp'

interface Header {
  logoColor?: LogoColors
  onClick?: (e: React.MouseEvent) => void
  className?: string
  showFrameAdvisorProfile?: boolean
  logoUrl?: string
}

const Header: React.FC<Header> = ({
  onClick,
  logoColor,
  className,
  showFrameAdvisorProfile,
  logoUrl,
}) => {
  const { basePath } = useStoreIndentity()
  const { t } = useTranslation()
  const isIPad = isIPadView()
  const isSurveyComplete = useSelector(s => s.frameAdvisor.isSurveyComplete)
  const userProfile = useSelector(s => s.frameAdvisor.fsaInfo)
  const profileImage = useFsaProfilePicture(userProfile?.profilePictureUrl || '')
  const toggleProfileRecapDrawer = useAction(toggleProfileRecapDrawerAction)

  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    //The QA team interpreted that it should be left blank as per documentation, but the console says the value is incorrect
    Page_Section2: '',
  })

  const onClickProfileAvatar = () => {
    sendImpressionEvent()
    toggleProfileRecapDrawer()
  }

  const renderAvatar = () => {
    const MissingAvatarImage = (
      <QuizProfileIcon width={pxToRem(isIPad ? 30 : 43)} height={pxToRem(isIPad ? 32 : 45)} />
    )

    if (isSurveyComplete || profileImage) {
      return (
        <>
          <ProfileAvatar
            imageSource={profileImage}
            fallbackImage={MissingAvatarImage}
            alt="Profile image"
          />
        </>
      )
    }
    return MissingAvatarImage
  }

  return (
    <HeaderContainer className={className}>
      <Link to={basePath} onClick={onClick} data-element-id="MainNav_Logo">
        <Logo logoColor={logoColor} logoUrl={logoUrl} />
      </Link>
      {showFrameAdvisorProfile && (
        <ProfileAvatarWrapper>
          <ProfilePopUp />
          <ProfileAvatarLabel>{t('FrameAdvisor.profile.headerRecapTitle')}</ProfileAvatarLabel>
          <ProfileAvatarContainer data-element-id="FAFAB_View" onClick={onClickProfileAvatar}>
            {renderAvatar()}
          </ProfileAvatarContainer>
        </ProfileAvatarWrapper>
      )}
    </HeaderContainer>
  )
}

export default Header
