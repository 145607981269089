import styled from 'styled-components'
import { DRESSED_ON_IMAGE_RATIO, IMAGE_RATIO } from 'components/CardProductBase/ProductCard.style'

export const PreviewContainer = styled.div<{ isDressedOnView?: boolean }>`
  position: relative;
  width: 100%;
  padding-top: ${({ isDressedOnView }) => (isDressedOnView ? DRESSED_ON_IMAGE_RATIO : IMAGE_RATIO)};
  overflow: hidden;
`

export const PreviewStyled = styled.div<{ backgroundColor?: string }>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.wildSand};
`
