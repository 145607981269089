import React from 'react'
import { IconProps } from 'types/icons'
export const FaceShapesIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 37"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <circle
        cx={13.8895}
        cy={14.4031}
        r={12.716}
        fill="white"
        stroke="#333333"
        strokeWidth={1.49927}
      />
      <rect
        x={14.7145}
        y={15.152}
        width={20.9435}
        height={20.9435}
        rx={1.49927}
        fill="white"
        stroke="#333333"
        strokeWidth={1.49927}
      />
    </svg>
  )
}
