import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CartItem } from 'types/cart'

interface UseCart {
  items: CartItem[]
  currency: string
  numberItems: number
  subtot: number
}

export const useCart = (): UseCart => {
  const cartItems = useSelector(s => s.cart.items)

  return useMemo(
    () => ({
      items: cartItems,
      currency: cartItems[0]?.product.price?.currency || '',
      numberItems: cartItems.reduce((tot, el) => (tot += el.quantity), 0),
      subtot: cartItems.reduce((tot, el) => {
        const priceValue = (el.product.price && el.product.price.current.value) || 0
        return (tot += el.quantity * priceValue)
      }, 0.0),
    }),
    [cartItems]
  )
}
