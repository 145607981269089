import { useEffect, useState } from 'react'

export const useApplicationOnlineStatus = () => {
  const [isOffline, setIsOffline] = useState<boolean>(!navigator.onLine || false)

  useEffect(() => {
    window.addEventListener('offline', () => setIsOffline(true))
    window.addEventListener('online', () => setIsOffline(false))

    return () => {
      window.removeEventListener('offline', () => setIsOffline(false))
      window.removeEventListener('online', () => setIsOffline(false))
    }
  }, [])
  return {
    isOffline,
  }
}
