import React from 'react'

export const UndoIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.32 21.92" width="100%" height="100%">
      <path
        d="M28.8,14a8.39,8.39,0,0,1-8.3,8.5H3a.86.86,0,0,1-.8-.8.79.79,0,0,1,.8-.8H20.5a6.72,6.72,0,0,0,6.7-6.8,6.72,6.72,0,0,0-6.7-6.8H3.3L6.9,11a.91.91,0,0,1,0,1.2.91.91,0,0,1-1.2,0L.7,7.1a.91.91,0,0,1,0-1.2L5.7.8A.91.91,0,0,1,6.9.8.91.91,0,0,1,6.9,2L3.3,5.6H20.5A8.3,8.3,0,0,1,28.8,14Z"
        transform="translate(-0.48 -0.57)"
        fill="#fff"
      />
    </svg>
  )
}
