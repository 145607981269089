import React from 'react'
import { IconProps } from 'types/icons'
export const FaFaceShapeRectangleManIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 48"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M40.605 22.165C39.987 21.444 38.981 21.076 37.473 21.013L37.706 19.269C38.181 15.707 37.661 12.289 36.201 9.383C35.51 8.007 34.649 6.759 33.659 5.649C35.105 4.2 36 2.203 36 0H18C15.571 0 13.541 1.741 13.092 4.041C8.00196 4.501 3.99996 8.792 3.99996 14L4.84696 21.004C3.15896 21.026 2.05596 21.394 1.39396 22.164C0.486957 23.221 0.708957 24.673 0.924957 26.078L1.00896 26.64C1.31296 28.775 1.77196 32 4.99996 32C5.39896 32 5.78596 31.943 6.15796 31.845L7.36696 41.843C7.79296 45.353 10.781 48 14.316 48H27.747C31.241 48 34.224 45.388 34.685 41.925L36.023 31.893C36.339 31.962 36.666 32 37 32C40.228 32 40.687 28.775 40.99 26.641L41.074 26.079C41.29 24.674 41.513 23.222 40.605 22.165ZM27.747 46H14.316C11.791 46 9.65696 44.109 9.35296 41.602L6.46196 17.752C11.444 17.447 14.509 15.569 15.572 12.113C17.096 13.575 20.096 15 26 15C31.148 15 34.784 16.068 35.837 17.864C35.812 18.241 35.775 18.621 35.724 19.004L32.703 41.661C32.373 44.135 30.243 46 27.747 46ZM33.65 2C32.824 4.327 30.607 6 28 6V8C29.083 8 30.116 7.781 31.059 7.389C31.412 7.242 31.749 7.066 32.075 6.872C32.991 7.878 33.782 9.019 34.415 10.28C35.146 11.737 35.597 13.351 35.779 15.055C33.092 13.316 28.765 13 26 13C16.303 13 16.001 9.018 16 9H14C14 11.769 13.154 15.396 6.22196 15.777L5.99996 13.941C6.03196 9.557 9.60796 6 14 6H15V5C15 3.346 16.346 2 18 2H33.65ZM4.99996 30C3.84296 30 3.39196 29.184 2.98996 26.359L2.90196 25.774C2.74796 24.773 2.60296 23.826 2.91196 23.467C3.06196 23.292 3.53196 23 4.99996 23H5.08896L5.91396 29.817C5.62296 29.926 5.31996 30 4.99996 30ZM39.098 25.774L39.01 26.359C38.608 29.184 38.157 30 37 30C36.754 30 36.519 29.956 36.29 29.89L37.208 23.005C38.504 23.033 38.945 23.301 39.088 23.467C39.396 23.827 39.251 24.773 39.098 25.774Z"
        fill={fill}
      />
      <path d="M23.013 36H18.986V38H23.013V36Z" fill={fill} />
      <path d="M21.434 31H18.986V33H23.434V23.057H21.434V31Z" fill={fill} />
    </svg>
  )
}
