import { AccordionStateProps } from 'types/accordion'
import { pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const AccordionLayoutWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const AccordionContent = styled.div<AccordionStateProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

export const AccordionTitle = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const AccordionSubtitle = styled.span`
  margin-left: auto;
  margin-right: ${pxToRem(24)};
`

export const AccordionFooter = styled.div`
  width: 100%;
  height: ${pxToRem(18)};
  span {
    position: absolute;
    right: 50%;
    bottom: ${pxToRem(16)};
  }
`

export const AccordionIcon = styled.span<AccordionStateProps>`
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
  transition: transform 0.5s;
  display: flex;
`

export const InlineWrapper = styled.div`
  display: flex;
  align-items: center;
`
