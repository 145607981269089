import { ElectronicsColors, ElectronicsFonts } from 'styled-components'

const colors: ElectronicsColors = {
  primary: '#000000',
  secondary: '#111111',
  primaryDark: '#333',
  primaryLight: '#ffffff',
  inactive: '#888888',
}

const primaryFont = 'Oswald,Gilmer,Roboto,Helvetica,Arial'
const secondaryFont = 'Lato,Gilmer,Roboto,Helvetica,Arial'

const fonts: ElectronicsFonts = {
  primary: primaryFont,
  secondary: secondaryFont,
  heading1: {
    name: primaryFont,
    weight: 500,
    towerSize: 48,
    tabletSize: 48,
    tabletMiniSize: 35,
    towerLineHeight: 72,
    tabletLineHeight: 72,
    tabletMiniLineHeight: 52,
  },
  heading2: {
    name: primaryFont,
    weight: 400,
    towerSize: 48,
    tabletSize: 48,
    tabletMiniSize: 34,
    towerLineHeight: 62,
    tabletLineHeight: 62,
    tabletMiniLineHeight: 46,
  },
  heading4: {
    name: primaryFont,
    weight: 400,
    towerSize: 32,
    tabletSize: 33,
    tabletMiniSize: 23,
    towerLineHeight: 48,
    tabletLineHeight: 50,
    tabletMiniLineHeight: 34,
  },
  heading5: {
    name: primaryFont,
    weight: 400,
    towerSize: 24,
    tabletSize: 24,
    tabletMiniSize: 18,
    towerLineHeight: 36,
    tabletLineHeight: 36,
    tabletMiniLineHeight: 28,
  },
  body: {
    weight: 400,
    towerSize: 18,
    tabletSize: 18,
    tabletMiniSize: 15,
    towerLineHeight: 26,
    tabletLineHeight: 26,
    tabletMiniLineHeight: 22,
  },
  label: {
    name: secondaryFont,
    weight: 400,
    towerSize: 14,
    tabletSize: 14,
    tabletMiniSize: 14,
    towerLineHeight: 20,
    tabletLineHeight: 20,
    tabletMiniLineHeight: 18,
  },
  cta: {
    name: primaryFont,
    weight: 400,
    towerSize: 32,
    tabletSize: 32,
    tabletMiniSize: 23,
    towerLineHeight: 48,
    tabletLineHeight: 48,
    tabletMiniLineHeight: 34,
  },
}

export default {
  colors,
  fonts,
}
