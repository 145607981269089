import { GraphQLError } from 'graphql'
import Maybe from 'graphql/tsutils/Maybe'
import { CartItem } from 'types/cart'
import { CustomProduct } from './product'

export interface ActiveFilters {
  kind: string
  value: string[] | string
}

export enum AnalyticsCategory {
  OPTICS = 'OPTICS',
  SUN = 'SUN',
  ELECTRONICS = 'ELECTRONICS',
  ACCESSORIES = 'ACCESSORIES',
  EYEWEAR = 'EYEWEAR',
  AFA = 'AFA',
}

export type AnalyticProductResponse = {
  Status: string
  OosOptions: string
  Vm_IsUpcSupported?: string
  Conf_IsUpcSupported?: string
  // TODO: remove string when analytics new categories are defined totally
  Category: AnalyticsCategory | string
  Type: string
  Engraving: string
  Price: string
  PriceFull: string
  Brand: string
  Size: string
  Sku: string
  ModelName: string
  MoCo: string
  LensColor: string
  LensTechnology: string
  FrameColor: string
  FrameTechnology: string
  Shape: Maybe<string>
  LensUPC: string
  ModelCode: string
  Lens: string
  Frame: string
  Case: string
  Cloth: string
  PerkCode: string
  InsuranceAmount: string
  InsuranceCode: string
  Warranty: string
  TaxRate: string
  CancelledUnits: string
  CancelledAmount: string
  Units: string
  Badges: string
  Stock?: string
  Image?: string
  Url?: string
  Fg_BestMatch?: '1' | '0'
}

export interface AnalyticsFAProduct {
  Category: AnalyticsCategory | string
  Type: string
  FrameType: string
  Fg_BestMatch: '1' | '0' // 1 if it's a best match, otherwise 0
  LensType: string
  Price: string
  PriceFull: string
  Status: string
}

export interface AnalyticProduct extends CustomProduct {
  collection: string
  originalBrand: string
}

export type AnalyticsCartProduct = CartItem & {
  product: AnalyticProduct
}

export interface VmProductForAnalyticsFields {
  Category: string
  Type: string
  Vm_IsUpcSupported: string
}

export type VmProductForAnalytics = Record<string, VmProductForAnalyticsFields>

export interface FormatProductProps {
  item: CustomProduct
  quantity?: number
  isEventWithCatalogProducts?: boolean
  withFAData?: boolean
}

export type StoreCustom = 'OCP' | 'Remix' | 'null' | 'OCP,Remix'

export interface AnalyticsConfig {
  profile: string
  shopperSlug: string
  gtagId: string
  isEnabled: boolean
  pagePlatform?: string
}

export interface PageRegionAndEnv {
  env: string
  region: string
}

export interface UtagData {
  Page_Environment: string
  Page_Platform: string
  Page_Brand: string
  Page_Region: string
  Page_Country: string
  Store_Name: string
  Tags_GoogleAnalytics_Id: string
  page_path: string
}

export type AmInstance = {
  sendNewSessionEvent: () => void
}

export interface CPComponentsTypes {
  case: string
  engraving: string
}

export interface CommonDataLayer {
  Page_Brand: string
  Store_Id: string
  Page_Design: string
  Page_Country: string
  Page_Language: string
  Page_DeviceType: string
  Page_Platform: string
  Page_PlatformRelease: string
  Page_Type: string
  Page_Section1: string
  Page_Section2: string
  Store_Chain: string
  Order_Currency: string
  Vm_IsBrowserSupported?: string
  Page_Environment?: string
  Adobe_SessionId?: string
  Tags_AdobeAnalytics_TrafficSourceCid_ThisHit?: string
}

export interface IsVmSupportedLayer {
  Vm_IsBrowserSupported?: string
}

export type AnalyticsEventPayload = Record<string, unknown>
export type AnalyticsDataLayer = Partial<CommonDataLayer>
export interface AnalyticsEventDataLayer extends AnalyticsDataLayer, AnalyticsEventPayload {}
export type AnalitycsEventFunction<T extends unknown[]> = (...args: T) => Record<string, unknown>

export interface ErrorGraphQLData {
  type: string
  errors: readonly GraphQLError[]
}

export interface ErrorNetworkData {
  type: string
  errors: Error[]
}

export type ErrorData = ErrorGraphQLData | ErrorNetworkData

export interface ErrorForAnalytics {
  id: string
  Error_Source: string
  Error_Code: string
  Error_Details: string
  Error_Message: string
}

export interface StoreInitialCustomData {
  User_Email_MD5?: string
  User_Segments?: string
  User_Id?: string
  User_Nation?: string
}
