import { resetBarcodeModalInfo } from 'actions/barcode'
import BarCodeScanModal from 'components/BarCodeScanModal'
import ConditionalRender from 'components/ConditionalRender'
import ErrorWidget from 'components/ErrorWidget'
import Loading from 'components/Loading'
import { SearchProductContent } from 'components/SearchDrawer/SearchProductContent'
import config from 'config'
import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'
import { useSearchData } from 'data'
import { useFiltersState } from 'hooks/useFilters'
import { useNavigationFlowHandler } from 'hooks/useNavigationFlowHandler'
import { usePageChecker } from 'hooks/useNavigationUtils'
import { usePrevious, usePreviousNonNullish } from 'hooks/usePreviousNonNullish'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { EVENTS_ID } from 'libs/analytics'
import { parseGrapqhQLError } from 'libs/errors'
import { getFilterFacet, getSearchDataItemsLimit } from 'libs/search'
import { getSearchValueFromQs } from 'libs/utils'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FilterFacet, FilterInput, FiltersState } from 'types/filter'

interface SearchProductsProps {
  query: string
}

const filterOptionsWithPositiveCount = (filters: FilterFacet[]) => {
  return filters.filter(option => !!option.count)
}

const getProductTypeFacetsFromOptions = (filters: FiltersState) => {
  // Sort default filter as the first option
  return (filters.collection?.options || [])
    .concat(filters.firstLevel?.options || [])
    .sort((a, b) => {
      if (a.value === config.defaultProductType) return -1
      if (b.value === config.defaultProductType) return 1
      return 0
    })
    .map(getFilterFacet)
}

const getInitialProductTypeFilter = (filters: FilterFacet[]): FilterFacet => {
  const defaultProductTypeFilter = filters.find(
    filter => filter.value === config.defaultProductType && !!filter.count
  )
  if (defaultProductTypeFilter) return defaultProductTypeFilter

  const initialProductTypeFilter = filters.find(filter => !!filter.count)
  if (initialProductTypeFilter) return initialProductTypeFilter

  return filters[0]
}

export const SearchProducts: React.FC<SearchProductsProps> = ({ query }) => {
  const [selectedProductTypeFilter, setSelectedProductTypeFilter] = useState<
    FilterFacet | undefined
  >(undefined)
  const [searchDataFilters, setSearchDataFilters] = useState<FilterFacet[]>([])
  const { basePath } = useStoreIndentity()
  const [sentAnalyticsEvent, setSentAnalyticsEvent] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const { isHomePage } = usePageChecker()
  const { barcodeFlow: fromBarcode } = useNavigationFlowHandler()
  const queryFromUrl = getSearchValueFromQs(history.location)
  const prevQuery = usePrevious(query)

  const productPageUrl = `${basePath}/products`
  const productTypeFilter: FilterInput[] = useMemo(() => {
    return selectedProductTypeFilter
      ? [
          {
            name: selectedProductTypeFilter.name,
            value: selectedProductTypeFilter.value,
          },
        ]
      : []
  }, [selectedProductTypeFilter])

  const { data: searchData, loading: searchLoading, error: searchError } = useSearchData(
    query,
    productTypeFilter,
    getSearchDataItemsLimit(query, selectedProductTypeFilter)
  )
  const prevData = usePreviousNonNullish(searchData)
  const existingSearchData = !isEmpty(searchData) ? searchData : prevData

  const filtersFromProducts = existingSearchData?.products.filters ?? []
  const productCount = existingSearchData?.products.numRows
  const filters = useFiltersState(filtersFromProducts)

  const analyticsData: { [key: string]: string } = {
    id: EVENTS_ID.event,
    Search_Keyword: query,
    Search_Type: 'text',
    Search_View: 'NO-SERP',
    Search_ResultItemsQnt: (existingSearchData?.products.numRows ?? 0).toString(),
    Events_SearchRun: productCount > 0 ? '1' : '0',
  }

  const sendAnalyticsEvent = useSendAnalyticsEvent(analyticsData)

  useEffect(() => {
    if (searchData && !sentAnalyticsEvent && !searchLoading && !searchError && !fromBarcode) {
      sendAnalyticsEvent()
      setSentAnalyticsEvent(true)
    }
  }, [searchData, searchLoading, searchError, sendAnalyticsEvent, sentAnalyticsEvent, fromBarcode])

  useEffect(() => {
    if (fromBarcode && query !== queryFromUrl) {
      // in case changing search query manually remove &barcodeFlow=true from the url
      fromBarcode && history.replace(history.location.pathname)
      dispatch(resetBarcodeModalInfo())
    }
  }, [queryFromUrl, fromBarcode, query, history, dispatch])

  useEffect(() => {
    if (prevQuery !== query) {
      setSelectedProductTypeFilter(undefined)
      setSearchDataFilters([])
    }
  }, [query, prevQuery, history, fromBarcode])

  useEffect(() => {
    if (!selectedProductTypeFilter && !searchLoading) {
      const initialSearchFilters = getProductTypeFacetsFromOptions(filters)
      const initialProductTypeFilter = getInitialProductTypeFilter(initialSearchFilters)
      setSelectedProductTypeFilter(initialProductTypeFilter)
      setSearchDataFilters(initialSearchFilters)
    }
  }, [selectedProductTypeFilter, filters, searchLoading])

  if (!existingSearchData || searchLoading) {
    return <Loading />
  }

  if (searchError) {
    return <ErrorWidget {...parseGrapqhQLError(searchError)} withWrapper />
  }

  return (
    <>
      <SearchProductContent
        query={query}
        items={existingSearchData.products.items}
        productCount={productCount}
        searchDataFilters={filterOptionsWithPositiveCount(searchDataFilters)}
        noDataFilters={searchDataFilters}
        productPageUrl={productPageUrl}
        selectedProductTypeFilter={selectedProductTypeFilter}
        setSelectedProductTypeFilter={setSelectedProductTypeFilter}
      />
      <ConditionalRender condition={isHomePage && fromBarcode}>
        <BarCodeScanModal />
      </ConditionalRender>
    </>
  )
}
