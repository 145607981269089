import React from 'react'
import { IconProps } from 'types/icons'

export const CaretMenuIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#555',
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32">
      <path
        fill={fill}
        d="M11.412 24.693a.866.866 0 00.096 1.23.89.89 0 001.13.016l.113-.11 8.049-9.296-8.049-9.295a.888.888 0 00-1.243-.095.866.866 0 00-.188 1.103l.092.128 7.065 8.158-7.065 8.16z"
      ></path>
    </svg>
  )
}
