import { clearPlpPreselectedFilters, hideAllDrawer as hideAllDrawerAction } from 'actions/ui'
import { CatalogCustomModels } from 'components/CatalogCustomModels'
import ConditionalRender from 'components/ConditionalRender'
import { NoDataContent } from 'components/Contents/NoDataContent'
import { NotFoundMessage } from 'components/Contents/NotFoundMessage'
import { SimilarStylesContent } from 'components/Contents/SimilarStylesContent'
import { PrimaryButton } from 'components/core/Button'
import { ProductList } from 'components/ProductsList'
import { ProductTypeTabs } from 'components/SearchDrawer/ProductTypeTabs'
import config from 'config'
import { useAction } from 'hooks/useActions'
import { useNavigationFlowHandler } from 'hooks/useNavigationFlowHandler'
import { useStoreContext } from 'hooks/useStoreContext'
import { useUrlParams } from 'hooks/useUrlParams'
import { isCustomizableEnabledForBrand } from 'libs/customProducts'
import { Device, landscape, tablet } from 'libs/media'
import { addProductTypeToQuery } from 'libs/search'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BarcodeModalInfoReducer } from 'reducers/barcode'
import styled from 'styled-components'
import { FilterFacet, ProductTypeValues } from 'types/filter'
import { Product } from 'types/product'

export const ViewAllWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const PrimaryButtonStyled = styled(PrimaryButton)`
  margin: 2.45rem;
  min-width: 8.2rem;
`

export const ProductCounter = styled.p`
  margin: 1rem 0 0;
  padding: 1rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primaryText};
`

const SearchContent = styled.div`
  margin-top: 5.09rem;
  ${landscape('margin-top: 3.27rem;')}
  ${tablet('margin-top: 2.09rem;')}
  ${landscape(Device.tablet, 'margin-top: 2.86rem;')}
`

const getInitialNoDataFilter = (noDataFilters: FilterFacet[], selectedFilter?: FilterFacet) => {
  if (selectedFilter && noDataFilters.find(filter => filter.value === selectedFilter.value)) {
    return selectedFilter
  }
  const defaultFilter = noDataFilters.find(filter => filter.value === config.defaultProductType)
  if (defaultFilter) {
    return defaultFilter
  }
  return noDataFilters[0]
}

interface SearchProductsContentProps {
  items: Product[]
  query: string
  productCount: number
  productPageUrl: string
  searchDataFilters: FilterFacet[]
  noDataFilters: FilterFacet[]
  selectedProductTypeFilter?: FilterFacet
  setSelectedProductTypeFilter: (filter: FilterFacet) => void
}

export const SearchProductContent: React.FC<SearchProductsContentProps> = ({
  items,
  productCount,
  searchDataFilters,
  noDataFilters,
  productPageUrl,
  selectedProductTypeFilter,
  setSelectedProductTypeFilter,
  query,
}) => {
  const { t } = useTranslation()

  const initialNoResultsProductTypeFilter = getInitialNoDataFilter(
    noDataFilters,
    selectedProductTypeFilter
  )
  const store = useStoreContext()
  const [selectedNoResultProductType] = useState<FilterFacet | undefined>(
    initialNoResultsProductTypeFilter
  )
  const dispatch = useDispatch()
  const { isOnlyCustom, isOakley } = useUrlParams()
  const { barcodeFlow: fromBarcode } = useNavigationFlowHandler()
  const { scenario, similarStylesProducts }: BarcodeModalInfoReducer = useSelector(s => s.barcode)
  const history = useHistory()
  const enableCustom = isCustomizableEnabledForBrand(
    isOakley ? 'oo' : 'rb',
    store.remixEnabled,
    store.ocpEnabled
  )

  const hideAllDrawer = useAction(hideAllDrawerAction)

  const showSimilarProductsFromBarcode =
    (scenario === 'scenario8' || scenario === 'scenario4B') && fromBarcode
  const showResults = productCount > 0 && !showSimilarProductsFromBarcode
  const isNoDataContentShown = productCount === 0 && !showSimilarProductsFromBarcode

  const handleChangeProductType = (filterFacet: FilterFacet) => {
    setSelectedProductTypeFilter(filterFacet)
  }

  const renderProductTypeTabs = () => {
    if (isOnlyCustom && enableCustom) return null
    if (!selectedProductTypeFilter && !selectedNoResultProductType) return null

    if (isNoDataContentShown && selectedNoResultProductType && noDataFilters.length) {
      return (
        <ProductTypeTabs
          filters={noDataFilters}
          selectedFilter={selectedNoResultProductType}
          onChange={handleChangeProductType}
        />
      )
    }

    if (!isNoDataContentShown && selectedProductTypeFilter && searchDataFilters.length) {
      return (
        <ProductTypeTabs
          filters={searchDataFilters}
          selectedFilter={selectedProductTypeFilter}
          onChange={handleChangeProductType}
        />
      )
    }
  }

  const renderNoDataContent = () => {
    if (isOnlyCustom && enableCustom) {
      return <CatalogCustomModels query={query} />
    }
    if (
      selectedNoResultProductType &&
      [ProductTypeValues.OPTICAL, ProductTypeValues.SUN].includes(
        selectedNoResultProductType.value as ProductTypeValues
      )
    ) {
      // TODO: the issue with aborted requests when switching between Sun and Optical no data tabs
      return (
        <NoDataContent
          productTypeFilterValue={selectedNoResultProductType.value}
          renderProductTypeTabs={renderProductTypeTabs()}
        />
      )
    }
    if (selectedNoResultProductType) {
      return <NotFoundMessage />
    }
    return null
  }

  return (
    <SearchContent>
      <ConditionalRender condition={!showSimilarProductsFromBarcode && !fromBarcode}>
        {renderProductTypeTabs()}
      </ConditionalRender>
      <ConditionalRender condition={showSimilarProductsFromBarcode}>
        <SimilarStylesContent similarStylesProducts={similarStylesProducts} />
      </ConditionalRender>
      <ConditionalRender condition={isNoDataContentShown}>
        {renderNoDataContent()}
      </ConditionalRender>
      <ConditionalRender condition={showResults}>
        <ProductCounter>
          {t(`ProductsListPage.${productCount > 1 ? 'results' : 'result'}`, {
            count: productCount,
          })}
        </ProductCounter>
        <ProductList items={items} isSearchList={true} />
        <ConditionalRender condition={productCount >= 4}>
          <ViewAllWrapper>
            <PrimaryButtonStyled
              data-element-id="Tiles_ViewAll"
              onClick={() => {
                history.push(
                  `${productPageUrl}?q=${encodeURI(
                    addProductTypeToQuery(query, selectedProductTypeFilter)
                  )}`
                )
                dispatch(clearPlpPreselectedFilters())
                hideAllDrawer()
              }}
            >
              {t('Pages.viewAll')}
            </PrimaryButtonStyled>
          </ViewAllWrapper>
        </ConditionalRender>
      </ConditionalRender>
    </SearchContent>
  )
}
