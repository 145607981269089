import React from 'react'
import {
  PreviewContainer,
  PreviewStyled,
} from 'components/CardProductBase/ProductPreview/ProductPreview.style'

export const ProductPreview: React.FC<{ backgroundColor?: string; isDressedOnView?: boolean }> = ({
  backgroundColor,
  children,
  isDressedOnView,
}) => {
  return (
    <PreviewContainer isDressedOnView={isDressedOnView}>
      <PreviewStyled backgroundColor={backgroundColor}>{children}</PreviewStyled>
    </PreviewContainer>
  )
}
