import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled, { css, keyframes } from 'styled-components'

const growIconBadge = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
`

export const MenuIconBadge = styled.span<{ isAnimation?: boolean; transparentBg?: boolean }>`
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  display: block;
  width: 0.545rem;
  height: 0.545rem;
  border-radius: 100%;
  background-color: ${({ theme, transparentBg }) =>
    transparentBg ? 'transparent' : theme.colors.monza};
  animation: ${({ isAnimation }) =>
    isAnimation &&
    css`
      ${growIconBadge} 1.5s linear
    `};
`

export const FloatingButton = styled.button`
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pxToRem(88)};
  height: ${pxToRem(88)};
  border-right: 1px solid ${({ theme }) => theme.colors.frenchGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.frenchGrey};
  background-color: white;
  ${tablet(`
    width: ${clampValues(56, 64)};
    height: ${clampValues(56, 64)};
  `)}
  ${landscape(
    Device.tablet,
    `
    width: ${clampValuesLandscape(48, 64)};
    height: ${clampValuesLandscape(48, 64)};
  `
  )}
`

export const FloatingButtonInner = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const FloatingContainer = styled.div`
  z-index: 1;
  position: fixed;
  top: ${pxToRem(260)};
  left: 0;

  ${tablet(`
    top: ${pxToRem(253)};
  `)}

  > ${FloatingButton}:first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.frenchGrey};
  }
`
