import { IconProps } from 'types/icons'
import React from 'react'
export const CheckmarkRoundIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM6.4 12L2.4 8L3.528 6.872L6.4 9.736L12.472 3.664L13.6 4.8L6.4 12Z"
        fill={fill}
      />
    </svg>
  )
}
