import { setBarcodeModalInfo, resetBarcodeModalInfo } from 'actions/barcode'
import { BarcodeScanScenario, WHSSkuBase } from 'components/BarCodeScanModal/types'
import { convertCustomModelIntoProductInfo, convertProductForBarcodeModal } from 'libs/barcode'
import React, { useCallback } from 'react'
import qs from 'qs'
import { Redirect } from 'react-router-dom'
import { CustomModel } from 'types/custom'
import { Product } from 'types/product'
import { useActions } from './useActions'
import { BooleanResolver, useQueryParams } from './useQueryParams'

const useBarcodeHandleScenario = () => {
  const actions = useActions({
    setBarcodeModalInfo,
    resetBarcodeModalInfo,
  })
  const queryParams = useQueryParams({
    barcodeDebug: BooleanResolver,
  })
  const debugEnabled = queryParams.barcodeDebug
  return useCallback(
    (
      scenario: BarcodeScanScenario,
      product?: WHSSkuBase | CustomModel,
      redirectInfo?: {
        pathname?: string
        query?: string
      },
      similarStylesProducts?: Product[]
    ) => {
      const productInfo =
        scenario === 'scenario4B'
          ? convertCustomModelIntoProductInfo(product as CustomModel)
          : convertProductForBarcodeModal(product as WHSSkuBase)

      // TODO: this condition got in introduced because after saving the barcode info on redux only the modals have
      // the possibility to do the clean up of the saved data (by using the close button)
      // in case of the other scenarios we don't want data to be saved, otherwise the dirty data cause
      // unintended behaviours.

      // Improvement: we have to split this callback into multiple callbacks, and this hook returns 2,3,4 callbacks that
      // BarcodeActions knows how to use (since we have the switch case)
      // we have to split this into multiple callbacks also because for the scenario10 we're calling it this way
      // return redirectToScenario('scenario10', undefined, {...})
      // Beeing forced to use undefined as an argument means this function is doing too many things, and it's a bad
      // design pattern.
      if (scenario !== 'scenario9') {
        actions.setBarcodeModalInfo({ productInfo, scenario, similarStylesProducts })
      } else {
        actions.resetBarcodeModalInfo()
      }

      const comesFromBarcode = 'comesFromBarcode=true'

      const defaultSearch = redirectInfo?.query
        ? `?q=${redirectInfo.query}&${comesFromBarcode}`
        : `?${comesFromBarcode}`

      const search = debugEnabled ? `${defaultSearch}&${qs.stringify(queryParams)}` : defaultSearch
      const pathname: string = redirectInfo?.pathname || productInfo?.url

      return (
        <Redirect
          to={{
            pathname,
            search,
          }}
        />
      )
    },
    [actions]
  )
}

export default useBarcodeHandleScenario
