import { Slider } from 'components/Slider'
import { Label, Text } from 'components/Typography'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled, { css } from 'styled-components'

export const SliderContainerFA = styled.div<{ paddingTop?: number }>`
  width: 100%;
  height: ${pxToRem(235)};
  display: flex;
  align-items: center;
  ${landscape(`
    height: 100vh;
    width: fit-content;
    position: sticky;
    right: 0;
    top: 0
  `)};
  ${({ paddingTop }) =>
    landscape(`
    transition: all 0.5s ease;
    padding-top: ${paddingTop ? pxToRem(paddingTop) : 0};
    height: ${paddingTop ? '100vh' : `calc(100vh - 10rem - ${pxToRem(90)})`};
  `)};
  ${({ paddingTop }) =>
    landscape(
      Device.tablet,
      `
    height: ${paddingTop ? '100vh' : `calc(100vh - 7rem - ${pxToRem(90)})`};
  `
    )};
`

export const StyledSliderFA = styled(Slider)<{ gutter?: number }>`
  height: ${pxToRem(267)};

  ${landscape(`
    flex-direction: column;
    padding: 0 ${pxToRem(50)} ${pxToRem(50)} ${pxToRem(50)};
    overflow-y: auto;
    overflow-x: unset;

    & > * {
      margin-left: 0;
      margin-top: ${pxToRem(32)};
    }
  `)}

  ${landscape(
    Device.tablet,
    `
    padding: 0 ${clampValuesLandscape(20, 40)} ${pxToRem(100)};
      & > * {
        margin-top: ${clampValuesLandscape(24, 32)};
      }
    `
  )}
`

export const GridSlideCardFAWrapper = styled.button<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ isSelected }) => (isSelected ? pxToRem(10) : '0')};
  ${landscape(`
    justify-content: center;
  `)}

  img {
    border-radius: ${clampValues(7, 10)};
    box-shadow: ${({ isSelected, theme }) =>
      isSelected ? `0 0 0 ${clampValues(6, 8)} ${theme.cta.backgroundColor}` : 'none'};
  }
`

const getDefaultGridImageSizes = (isSelected?: boolean) => css`
  width: ${isSelected ? `${pxToRem(304)}` : `${pxToRem(270)}`};
  /* height figma not correct */
  height: ${isSelected ? `${pxToRem(228)}` : `${pxToRem(200)}`};
  ${tablet(`
    width: ${isSelected ? `${clampValues(200, 266)}` : `${clampValues(179, 224)}`};
    height: ${isSelected ? `${clampValues(150, 200)}` : `${clampValues(134, 167)}`};
  `)}
  ${landscape(
    Device.tablet,
    `
    width: ${
      isSelected ? `${clampValuesLandscape(200, 266)}` : `${clampValuesLandscape(168, 224)}`
    };
    height: ${
      isSelected ? `${clampValuesLandscape(150, 200)}` : `${clampValuesLandscape(125, 167)}`
    };
  `
  )}
`

const getLargeGridImageSizes = (isSelected?: boolean) => css`
  width: ${isSelected ? `${pxToRem(320)}` : `${pxToRem(270)}`};
  height: ${isSelected ? `${pxToRem(240)}` : `${pxToRem(202)}`};
  ${tablet(`
    width: ${isSelected ? `${clampValues(200, 266)}` : `${clampValues(178, 224)}`};
    height: ${isSelected ? `${clampValues(150, 200)}` : `${clampValues(134, 167)}`};
  `)}
  ${landscape(
    Device.tablet,
    `
    width: ${
      isSelected ? `${clampValuesLandscape(200, 266)}` : `${clampValuesLandscape(178, 224)}`
    };
    height: ${
      isSelected ? `${clampValuesLandscape(150, 200)}` : `${clampValuesLandscape(134, 167)}`
    };
  `
  )}
`

export const GridImageFA = styled.div<{ isSelected?: boolean; isLarge?: boolean }>`
  position: relative;
  border-radius: ${clampValues(7, 10)};
  ${({ isSelected }) => !isSelected && 'overflow: hidden'};
  ${({ isSelected, isLarge }) =>
    isLarge ? getLargeGridImageSizes(isSelected) : getDefaultGridImageSizes(isSelected)}
`

export const PlayListTitleFA = styled(Text)<{ isSelected?: boolean }>`
  position: absolute;
  bottom: ${({ isSelected }) => (isSelected ? `${pxToRem(32)}` : `${pxToRem(26)}`)};
  left: ${({ isSelected }) => (isSelected ? `${pxToRem(16)}` : `${pxToRem(12)}`)};
  z-index: 1;
  ${({ isSelected }) =>
    tablet(`
    bottom: ${isSelected ? `${clampValues(10, 16)}` : `${pxToRem(10)}`};
  `)}
`

export const PlayListDescriptionFA = styled(Label)<{ isSelected?: boolean }>`
  position: absolute;
  bottom: ${({ isSelected }) => (isSelected ? `${pxToRem(14)}` : `${pxToRem(8)}`)};
  left: ${({ isSelected }) => (isSelected ? `${pxToRem(16)}` : `${pxToRem(12)}`)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
  z-index: 1;
`

export const OverlayGridSlide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 41.5%, rgba(0, 0, 0, 0.6) 99.6%);
  mix-blend-mode: normal;
`
