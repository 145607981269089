import React from 'react'
import { IconProps } from 'types/icons'

export const SilverMetalsIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <circle cx="50%" cy="50%" r="50%" fill="url(#silverMetal)" />
      <defs>
        <linearGradient
          id="silverMetal"
          x1={47.85}
          y1={52.563}
          x2={7.612}
          y2={5.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.047} stopColor="#AEACAC" />
          <stop offset={0.435} stopColor="#B9B9B9" stopOpacity={0.692} />
          <stop offset={0.641} stopColor="#D0D0D0" stopOpacity={0.4} />
          <stop offset={0.795} stopColor="#B9B9B9" stopOpacity={0.7} />
          <stop offset={1} stopColor="#B3B0B0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
