import React from 'react'
import { IconProps } from 'types/icons'
export const FaFaceShapeDiamondWomanIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M47.707 36.293C45.736 34.322 45.001 25.867 45 21C45 9.42 35.58 0 24 0C12.42 0 3 9.42 3 21C2.999 25.867 2.264 34.322 0.293 36.293L0 36.586V37C0 43.065 4.935 48 11 48C14.071 48 16.848 46.732 18.845 44.697C20.418 45.532 22.185 46 24 46C25.815 46 27.582 45.532 29.155 44.697C31.152 46.732 33.929 48 37 48C43.065 48 48 43.065 48 37V36.586L47.707 36.293ZM24 44C21.182 44 18.491 42.654 16.8 40.4L11.019 32.692C9.144 30.192 8.42 27 9.033 23.935L9.846 19.869C14.881 19.147 18.954 15.512 20.418 11.825C22.382 14.238 27.055 17.695 38.12 19.696L38.968 23.935C39.581 27 38.857 30.192 36.982 32.692L31.2 40.4C29.509 42.654 26.818 44 24 44ZM37 46C34.631 46 32.48 45.073 30.871 43.572C31.588 43 32.241 42.345 32.8 41.6L38.581 33.892C40.797 30.936 41.653 27.164 40.929 23.542L39.947 18.632L39.135 17.843C22.32 14.962 20.998 8.899 20.99 8.858L19 9C19 12.234 14.982 17.458 8.925 17.961L8.02 18.804L7.072 23.543C6.347 27.165 7.203 30.938 9.42 33.893L15.2 41.6C15.759 42.345 16.412 43 17.128 43.572C15.52 45.073 13.369 46 11 46C6.161 46 2.202 42.162 2.007 37.37C4.914 33.734 5 22.329 5 21C5 10.523 13.523 2 24 2C34.477 2 43 10.523 43 21C43 22.329 43.086 33.734 45.993 37.37C45.798 42.162 41.839 46 37 46Z"
        fill={fill}
      />
      <path d="M26.434 23.057H24.434V31H21.986V33H26.434V23.057Z" fill={fill} />
      <path
        d="M27.312 36.928L24 35.958L20.688 36.928L21.879 38.12C22.445 38.688 23.198 39 24 39C24.802 39 25.555 38.688 26.122 38.121L27.312 36.928Z"
        fill={fill}
      />
    </svg>
  )
}
