import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { useRouteMatch } from 'react-router-dom'

export const useHomePageChecker = () => {
  const { basePath } = useStoreIndentity()
  return {
    isHomePage: Boolean(
      useRouteMatch({
        path: basePath,
        exact: true,
      })
    ),
  }
}
