export const SET_ORDER = 'order/SET_ORDER'

export interface SetOrder {
  type: typeof SET_ORDER
  payload: {
    orderNumber: string | null
    token: string | null
  }
}

export const setOrder = (payload: {
  orderNumber: string | null
  token: string | null
}): SetOrder => ({
  type: SET_ORDER,
  payload,
})

export type OrderActionTypes = SetOrder
