import { ApolloError } from '@apollo/client'
import config from 'config'
import { getTranslations } from 'data'
import i18next, { InitOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { getLangFromUrl } from 'libs/url'
import { initReactI18next } from 'react-i18next'

const customLangDetector = {
  name: 'dw-lang-detector',
  lookup() {
    const lang = getLangFromUrl()
    return lang
  },
}

const lngDetector = new LanguageDetector()
lngDetector.addDetector(customLangDetector)

type ErrorHandler = (e: ApolloError) => void

const getInitConfig = (lang: string, errorHandler: ErrorHandler): InitOptions => {
  return {
    interpolation: {
      escapeValue: false,
    },
    load: 'currentOnly',
    fallbackLng: config.fallbackLanguage,
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    detection: {
      order: ['dw-lang-detector'],
      caches: [],
    },
    react: {
      useSuspense: true,
    },
    backend: {
      async request(options, url, payload, callback) {
        try {
          const { data } = await getTranslations(lang)
          callback(undefined, {
            status: 200,
            data: JSON.stringify(data.translations.data),
          })
        } catch (e) {
          errorHandler(e)
        }
      },
    },
  }
}

export const initI18n = (lang: string, errorHandler: ErrorHandler) => {
  const i18n = i18next.createInstance()
  const initConfig = getInitConfig(lang, errorHandler)

  i18n.use(HttpBackend).use(lngDetector).use(initReactI18next).init(initConfig)

  return i18n
}
