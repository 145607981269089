import {
  CartInformation,
  CheckoutFormDataField,
  CheckoutV3Type,
  FormErrors,
  FormFieldValidator,
  FormPanel,
  OrderItem,
  StoreData,
} from 'types/checkoutV3'

export const SET_MODAL = 'checkout/SET_MODAL'
export const SET_CART_INFO = 'checkout/SET_CART_INFO'
export const SET_CUSTOMER_ADDRESS = 'checkout/SET_CUSTOMER_ADDRESS'
export const SET_PURCHASE_NUMBER = 'checkout/SET_PURCHASE_NUMBER'
export const SET_CS_ORDER_NOTE = 'checkout/SET_CS_ORDER_NOTE'
export const SET_WEB_ORDER_IDENTIFIER = 'checkout/SET_WEB_ORDER_IDENTIFIER'
export const UPDATE_ORDER_ITEM = 'checkout/UPDATE_ORDER_ITEM'
export const DELETE_ORDER_ITEM = 'checkout/DELETE_ORDER_ITEM'
export const CHANGE_FORM_PANEL = 'checkout/CHANGE_FORM_PANEL'
export const SET_DATA = 'checkout/SET_DATA'
export const SET_CHECKOUT_TYPE = 'checkout/SET_TYPE'
export const VALIDATE_FIELD = 'checkout/VALIDATE_FIELD'
export const SET_FORM_ERRORS = 'checkout/SET_FORM_ERRORS'
export const RESET_CHECKOUT = 'checkout/RESET_CHECKOUT'

export interface SetWebOrderIdentifier {
  type: typeof SET_WEB_ORDER_IDENTIFIER
  payload: string
}
export interface SetModal {
  type: typeof SET_MODAL
  payload: {
    visible: boolean
    type: string
  }
}

export interface SetCartInfo {
  type: typeof SET_CART_INFO
  payload: CartInformation
}

export interface SetCustomerAddress {
  type: typeof SET_CUSTOMER_ADDRESS
  payload: string
}

export interface SetPurchaseNumber {
  type: typeof SET_PURCHASE_NUMBER
  payload: string
}

export interface SetCSOrderNote {
  type: typeof SET_CS_ORDER_NOTE
  payload: string
}

export interface UpdateOrderItem {
  type: typeof UPDATE_ORDER_ITEM
  payload: OrderItem
}

export interface DeleteOrderItem {
  type: typeof DELETE_ORDER_ITEM
  payload: OrderItem
}

export interface ChangePanelAction {
  type: typeof CHANGE_FORM_PANEL
  payload: {
    activePanels: FormPanel[]
  }
}
export interface SetDataAction {
  type: typeof SET_DATA
  payload: {
    field: CheckoutFormDataField
    data: string
  }
}

export interface SetCheckoutTypeAction {
  type: typeof SET_CHECKOUT_TYPE
  payload: keyof CheckoutV3Type
}

export interface ValidateFieldAction {
  type: typeof VALIDATE_FIELD
  payload: {
    field: CheckoutFormDataField
    value: string | undefined
    validators: FormFieldValidator[]
    storeData: StoreData
  }
}

export interface FormErrorsAction {
  type: typeof SET_FORM_ERRORS
  payload: {
    errors: FormErrors
  }
}

export interface ResetCheckoutAction {
  type: typeof RESET_CHECKOUT
}

export const setModal = (payload: { visible: boolean; type: string }): SetModal => ({
  type: SET_MODAL,
  payload,
})

export const setCartInfo = (payload: CartInformation): SetCartInfo => ({
  type: SET_CART_INFO,
  payload,
})

export const setCustomerAddress = (payload: string): SetCustomerAddress => ({
  type: SET_CUSTOMER_ADDRESS,
  payload,
})

export const setPurchaseNumber = (payload: string): SetPurchaseNumber => ({
  type: SET_PURCHASE_NUMBER,
  payload,
})

export const setCSOrderNote = (payload: string): SetCSOrderNote => ({
  type: SET_CS_ORDER_NOTE,
  payload,
})

export const setWebOrderIdentifier = (payload: string): SetWebOrderIdentifier => ({
  type: SET_WEB_ORDER_IDENTIFIER,
  payload,
})

export const updateOrderItem = (payload: OrderItem): UpdateOrderItem => ({
  type: UPDATE_ORDER_ITEM,
  payload,
})

export const deleteOrderItem = (payload: OrderItem): DeleteOrderItem => ({
  type: DELETE_ORDER_ITEM,
  payload,
})

export const setFormErrors = (errors: FormErrors): FormErrorsAction => {
  return {
    type: SET_FORM_ERRORS,
    payload: {
      errors,
    },
  }
}

export const changePanel = (activePanels: FormPanel[]): ChangePanelAction => {
  return {
    type: CHANGE_FORM_PANEL,
    payload: {
      activePanels: activePanels,
    },
  }
}

export const setCheckoutData = (field: CheckoutFormDataField, data?: string): SetDataAction => {
  return {
    type: SET_DATA,
    payload: {
      field,
      data: data || '',
    },
  }
}

export const setCheckoutType = (payload: keyof CheckoutV3Type): SetCheckoutTypeAction => ({
  type: SET_CHECKOUT_TYPE,
  payload,
})

export const validateField = (
  field: CheckoutFormDataField,
  value: string | undefined,
  validators: FormFieldValidator[],
  storeData: StoreData
): ValidateFieldAction => {
  return {
    type: VALIDATE_FIELD,
    payload: {
      field,
      value,
      storeData,
      validators,
    },
  }
}

export const resetCheckout = (): ResetCheckoutAction => {
  return {
    type: RESET_CHECKOUT,
  }
}

export type CheckoutActionTypes =
  | SetModal
  | SetCartInfo
  | SetCustomerAddress
  | SetCSOrderNote
  | SetPurchaseNumber
  | SetWebOrderIdentifier
  | UpdateOrderItem
  | DeleteOrderItem
  | ChangePanelAction
  | SetDataAction
  | SetCheckoutTypeAction
  | ValidateFieldAction
  | FormErrorsAction
  | ResetCheckoutAction
