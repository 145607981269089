import config from 'config'
import { PageRegionAndEnv, UtagData } from 'types/analytics'
import { Store } from 'types/store'
import Log from './Log'
import { executeOnce } from './utils'

interface AnalyticsManagerConfig {
  id: string
  digitalCatalogId: string
  shopperSlug: string
  gtagId: string
}
class AnalyticsManager {
  static amInstance: AnalyticsManager
  analyticsConfig: AnalyticsManagerConfig
  store: Store

  constructor(analyticsConfig: AnalyticsManagerConfig, store: Store) {
    this.analyticsConfig = {
      ...analyticsConfig,
      gtagId: analyticsConfig.gtagId || 'dw-gtag',
    }
    this.store = store

    if (AnalyticsManager.amInstance) {
      return AnalyticsManager.amInstance
    }

    AnalyticsManager.amInstance = this
    return this
  }

  static getPageRegionAndEnv(): PageRegionAndEnv {
    const hostname = window.location.hostname
    const getEnvSlug = () => {
      const envSlugs = ['dev', 'test', 'uat', 'stage']

      const currentSlug = envSlugs.find((slug: string) => hostname.includes(`-${slug}`))
      return currentSlug ? currentSlug.toUpperCase() : 'PROD'
    }

    if (hostname.includes('localhost')) {
      return {
        env: 'DEV',
        region: '',
      }
    }

    return {
      env: getEnvSlug(),
      region: hostname.split('.')[1].toLowerCase().includes('emea') ? 'EMEA' : 'NA',
    }
  }

  loadAnalyticScript(): UtagData {
    return executeOnce((): UtagData => {
      const headFragment = document.createDocumentFragment()
      const gtag = document.createElement('script')

      const utagData = this.getAnalyticsData()

      gtag.id = this.analyticsConfig.gtagId
      gtag.text = `(function(a,b,c,d){
      a='https://www.googletagmanager.com/gtag/js?id=${utagData.Tags_GoogleAnalytics_Id}';
      b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
      a=b.getElementsByTagName(c)[0];
      a.parentNode.insertBefore(d,a);
      })();

      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments)};
      gtag('js', new Date());
      gtag('config', '${utagData.Tags_GoogleAnalytics_Id}', {
        'custom_map': {
        'dimension1': 'Page_Brand',
        'dimension2': 'Page_Country',
        'dimension3': 'Page_Platform',
        'dimension4': 'Store_Name',
        'dimension5': 'Page_Region',
        'dimension6': 'Page_Environment'
      }});`

      headFragment.appendChild(gtag)
      document.head.appendChild(headFragment)

      return utagData
    }, this.analyticsConfig.gtagId)()
  }

  getAnalyticsData(): UtagData {
    const pageRegionEnv = AnalyticsManager.getPageRegionAndEnv()
    const { digitalCatalog, countryId, storeId, name } = this.store
    const { id, digitalCatalogId } = this.analyticsConfig

    const data = {
      Page_Environment: pageRegionEnv.env,

      Page_Platform: digitalCatalog ? 'Digital Catalog' : 'Smart Shopper',

      Page_Brand: this.analyticsConfig.shopperSlug,

      Page_Region: pageRegionEnv.region,

      Page_Country: countryId,

      Store_Name: `${storeId} - ${name}`,

      Tags_GoogleAnalytics_Id: digitalCatalog ? digitalCatalogId : id,

      page_path: `${window.location.pathname}${window.location.search}`,
    }

    return data
  }

  sendAnalyticEvent(event: string): UtagData {
    let utagData: UtagData
    if (!document.getElementById(this.analyticsConfig.gtagId)) {
      utagData = this.loadAnalyticScript()
    } else {
      utagData = this.getAnalyticsData()
    }

    window.gtag && window.gtag('event', event, utagData)
    return utagData
  }
}

export const sendGoogleAnalyticEvent = (store: Store, event: string): void => {
  const amInstance = new AnalyticsManager(config.googleAnalytics, store)
  const data = amInstance.sendAnalyticEvent(event)
  Log.info(`Analytic: sent event "${event}" with payload ${JSON.stringify(data)}`)
}

export const sendGoogleAnalyticNewSession = (store: Store): void =>
  sendGoogleAnalyticEvent(store, 'new_session')
