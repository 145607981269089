import classnames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'
import { KeyboardButtonProps, ReactClickHandler } from '../../types/touch-keyboard'

export const Button = styled.button`
  display: flex;
  flex-basis: ${({ theme }) => theme.touchkeyboard.button.width};
  justify-content: space-around;
  align-items: center;
  height: ${({ theme }) => theme.touchkeyboard.button.height};
  border: 1px solid ${({ theme }) => theme.touchkeyboard.button.borderColor};
  border-radius: ${({ theme }) => theme.touchkeyboard.button.borderRadius};
  font-family: ${({ theme }) => theme.touchkeyboard.fontFamily};
  font-size: ${({ theme }) => theme.touchkeyboard.fontSize};
  font-weight: ${({ theme }) => theme.touchkeyboard.fontWeight};
  color: ${({ theme }) => theme.touchkeyboard.button.color};
  background-color: ${({ theme }) => theme.touchkeyboard.button.backgroundColor};

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:active {
    background-color: ${({ theme }) => theme.touchkeyboard.button.activeBackgroundColor};
    border-color: ${({ theme }) => theme.touchkeyboard.button.activeBackgroundColor};
    color: ${({ theme }) => theme.touchkeyboard.button.activeColor};
  }

  &.keyboard {
    &-numberButton {
      flex-grow: 1;
    }

    &-halfButton {
      flex-basis: 56px;
    }

    &-space {
      flex-grow: 1;
    }

    &-utilButton {
      flex-grow: 2;
      max-width: 55px;
    }

    &-additionalButton {
      flex-basis: 128px;
    }

    &-submitButton,
    &-submit-button {
      flex-basis: 95px;
      font-size: 1em;
      font-weight: bold;
      background-color: ${({ theme }) => theme.touchkeyboard.button.submitBackgroundColor};
      border-color: ${({ theme }) => theme.touchkeyboard.button.submitBackgroundColor};
      color: ${({ theme }) => theme.touchkeyboard.button.submitColor};
    }
    &-small-btn {
      flex-basis: 70px;
    }
  }
`

const KeyboardButtonWithoutMemo: React.FC<KeyboardButtonProps> = props => {
  const { classes, isDisabled, autofocus, value, onClick } = props

  const handleClick: ReactClickHandler = React.useCallback(() => {
    if (onClick) onClick(value)
  }, [onClick, value])

  return (
    <Button
      type="button"
      className={classnames('keyboard-button', classes)}
      onClick={handleClick}
      autoFocus={autofocus}
      disabled={isDisabled}
    >
      {value}
    </Button>
  )
}

export const KeyboardButton = React.memo(
  KeyboardButtonWithoutMemo,
  (prev, next) => prev.value === next.value
)
