import { AccordionLayout } from 'components/core/Accordion/AccordionLayout'
import { AccordionTitle } from 'components/core/Accordion/styles'
import { PrimaryButton } from 'components/core/Button'
import { ProgressBar } from 'components/ProgressBar'
import { MultiSlidesWrapper, SingleSlideWrapper } from 'components/Slider/styles'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const MerchAccordionStyled = styled(AccordionLayout)<{ isOpened: boolean }>`
  padding-block: 0 ${({ isOpened }) => (isOpened ? pxToRem(52) : 0)};
  padding-inline: ${pxToRem(32)};
  position: fixed;
  inset-inline: 0;
  inset-block-end: 0;
  background-color: #f2f2f2;
  ${({ isOpened }) =>
    tablet(`
     padding-block: 0 ${isOpened ? clampValues(36, 52) : 0};
     padding-inline: ${clampValues(24, 32)};
  `)}
  ${({ isOpened }) =>
    landscape(
      Device.tablet,
      `
     padding-block: 0 ${isOpened ? clampValuesLandscape(36, 52) : 0};
     padding-inline: ${clampValuesLandscape(24, 32)};
  `
    )}
  ${AccordionTitle} {
    padding-block: ${pxToRem(30)};
    ${tablet(`
     padding-inline: ${clampValues(24, 30)}};
    `)}
    ${landscape(
      Device.tablet,
      `
     padding-inline: ${clampValuesLandscape(24, 30)}};
     padding-block-start: 0;
  `
    )}
  }
  ${MultiSlidesWrapper}, ${SingleSlideWrapper} {
    padding-block: 0;
    padding-inline: 0;
    padding-inline-start: 0;
    ${tablet(`
      padding-inline: ${clampValues(72, 96)};
    `)}
  }
`

export const NavigationBtn = styled(PrimaryButton)`
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};
  border: ${pxToRem(1)} solid ${({ theme }) => theme.colors.primaryText};
  background: white;
  position: absolute;
  border-radius: 50%;
  padding: 0;
  ${landscape(
    `
      padding: 0;
  `
  )}
`

export const MerchPrevBtn = styled(NavigationBtn)`
  inset-inline-start: 0;
  inset-block-start: 50%;
  transform: translate(${pxToRem(-12)}, -50%);
`

export const MerchNextBtn = styled(NavigationBtn)`
  inset-inline-end: 0;
  inset-block-start: 50%;
  transform: translate(${pxToRem(12)}, -50%);
`

export const CartMerchProgressBar = styled(ProgressBar)`
  inset-block-end: -${pxToRem(30)};
  inline-size: 25vw;
  ${tablet(`
     inset-block-end: ${clampValues(-24, -30)}};
  `)}
  ${landscape(
    Device.tablet,
    `
     inset-block-end: ${clampValuesLandscape(-24, -30)}};
  `
  )}
`

export const MerchCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pxToRem(24)};
  align-items: center;
`
