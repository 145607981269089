import React from 'react'
import { IconProps } from 'types/icons'
export const LensColorTreatmentIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 46"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M7.26367 15.7233L9.18567 16.2763C9.97167 13.5443 12.1277 8.83231 17.7337 7.97431L17.4307 5.99731C12.5317 6.74631 8.82667 10.2913 7.26367 15.7233Z"
        fill={fill}
      />
      <path
        d="M24.8226 0.000244141C21.4776 0.000244141 18.0566 0.238244 14.7526 0.682244C-2.72236 3.37624 -3.29136 31.6312 8.49164 41.0872C11.0116 43.0902 13.9406 44.0002 17.0496 44.0002C21.2126 44.0002 25.6986 42.3692 29.9696 39.5522C29.9686 39.4982 29.9616 39.4462 29.9616 39.3932C29.9616 38.9462 29.9676 38.1832 30.3496 36.8442C25.8606 40.1542 21.1776 42.0002 17.0496 42.0002C14.2656 42.0002 11.8056 41.1662 9.74264 39.5282C3.70364 34.6812 0.910643 23.6452 3.38564 14.4042C4.42364 10.5292 7.31964 3.85224 15.0196 2.66424C18.2976 2.22424 21.5956 2.00024 24.8226 2.00024C34.7326 2.00024 42.3496 4.13824 45.1976 7.72024C46.4256 9.26424 46.7806 11.0812 46.2806 13.2722C45.2956 17.0212 43.7366 20.6892 41.7056 24.1162C42.3786 24.2402 43.0226 24.4642 43.6096 24.7952C45.6066 21.3652 47.2046 17.6402 48.2236 13.7502C50.5036 3.87624 38.3086 0.000244141 24.8226 0.000244141Z"
        fill={fill}
      />
      <path
        d="M40.5689 28.0002C39.8519 28.0002 39.1359 28.3452 38.7039 29.0342C36.7499 32.1542 33.9619 37.0212 33.9619 39.3922C33.9619 43.0412 36.9199 46.0002 40.5699 46.0002C44.2189 46.0002 47.1779 43.0422 47.1779 39.3922C47.1779 37.0212 44.3899 32.1542 42.4359 29.0342C42.0019 28.3452 41.2849 28.0002 40.5689 28.0002ZM40.5689 44.0002V30.0002C40.6789 30.0002 40.7209 30.0672 40.7389 30.0962C44.0109 35.3202 45.1759 38.3232 45.1759 39.3922C45.1759 41.9332 43.1089 44.0002 40.5689 44.0002Z"
        fill={fill}
      />
    </svg>
  )
}
