import React from 'react'
import { IconProps } from 'types/icons'
export const FaStyleIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 23"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M24.0133 11.5342C21.6147 10.2155 19.4867 7.48683 18.7147 4.5975C18.5733 3.99616 18.6813 3.5235 19.0547 3.11083C19.8713 2.20816 21.9 1.6915 24.38 1.6675H24.6667V1.66683C25.498 1.6715 26.374 1.72816 27.2713 1.84616C29.3787 2.1655 30.1547 3.97216 30.43 5.02216C31.078 7.4935 30.3187 10.4188 28.702 11.6808C28.4853 11.8502 28.2593 11.9695 28.0307 12.0682C28.0733 12.5502 28.1833 12.9902 28.3447 13.3762C28.7653 13.2222 29.1627 13.0128 29.522 12.7328V12.7322C31.5607 11.1402 32.506 7.68016 31.72 4.6835C31.11 2.35883 29.5613 0.844163 27.458 0.526829C26.5873 0.411496 25.632 0.343496 24.6667 0.33883V0.333496H7.33333V0.338163C6.36466 0.342163 5.40533 0.410829 4.52933 0.526829C2.43866 0.843496 0.889992 2.3575 0.279992 4.68216C-0.506007 7.67883 0.439326 11.1395 2.47799 12.7308V12.7315C3.27066 13.3495 4.22866 13.6628 5.27533 13.6628C5.89933 13.6628 6.55399 13.5515 7.22466 13.3262C10.288 12.2975 13.2087 9.10016 14.384 5.55816C15.314 4.8535 16.6833 4.8535 17.6133 5.5575C18.6593 8.72016 21.1127 11.6148 23.8173 12.9268C23.9293 12.5428 24 12.1215 24 11.6668C24 11.6215 24.0107 11.5788 24.0133 11.5342ZM13.29 4.57816C12.388 7.9555 9.65932 11.1035 6.80132 12.0635C5.84133 12.3855 4.45399 12.5815 3.29866 11.6802C1.68133 10.4175 0.922659 7.49283 1.57066 5.0215C1.84599 3.97216 2.62199 2.16483 4.71733 1.8475C5.61933 1.72816 6.49999 1.6695 7.33399 1.66483V1.66683H7.67999C10.136 1.69816 12.1367 2.21483 12.946 3.11016C13.3187 3.52283 13.4267 3.9955 13.29 4.57816ZM14.664 3.90216C14.6173 3.27483 14.374 2.70216 13.934 2.2155C13.75 2.01216 13.5313 1.83283 13.2933 1.66683H18.7073C18.4693 1.83283 18.25 2.01216 18.066 2.2155C17.6267 2.70083 17.384 3.2715 17.3373 3.90283C16.9113 3.7515 16.46 3.66683 16 3.66683C15.54 3.66683 15.09 3.7515 14.664 3.90216Z"
        fill={fill}
      />
      <path
        d="M30 16.3335C28.4267 16.3335 26.6667 14.3382 26.6667 11.6668C26.6667 11.2982 26.368 11.0002 26 11.0002C25.632 11.0002 25.3334 11.2982 25.3334 11.6668C25.3334 14.3382 23.5734 16.3335 22 16.3335C21.632 16.3335 21.3334 16.6315 21.3334 17.0002C21.3334 17.3688 21.632 17.6668 22 17.6668C23.5734 17.6668 25.3334 19.6622 25.3334 22.3335C25.3334 22.7022 25.632 23.0002 26 23.0002C26.368 23.0002 26.6667 22.7022 26.6667 22.3335C26.6667 19.6622 28.4267 17.6668 30 17.6668C30.368 17.6668 30.6667 17.3688 30.6667 17.0002C30.6667 16.6315 30.368 16.3335 30 16.3335ZM26 19.3375C25.5367 18.3528 24.8607 17.5335 24.0674 17.0002C24.8607 16.4668 25.5367 15.6475 26 14.6628C26.4634 15.6475 27.1394 16.4668 27.9327 17.0002C27.1394 17.5335 26.4634 18.3528 26 19.3375Z"
        fill={fill}
      />
    </svg>
  )
}
