import React, { createContext, useState } from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { GlobalStyle } from 'styles'
import { theme } from 'theme'

export const ThemeUpdateContext = createContext((theme: DefaultTheme) =>
  // eslint-disable-next-line no-console
  console.error('attempted to set theme outside of a ThemeUpdateContext.Provider', theme)
)

export const CMSDrivenThemeProvider: React.FC = ({ children }) => {
  const [dynamicTheme, setDynamicTheme] = useState(theme)

  return (
    <ThemeProvider theme={dynamicTheme}>
      <>
        <GlobalStyle />
        <ThemeUpdateContext.Provider value={setDynamicTheme}>
          {children}
        </ThemeUpdateContext.Provider>
      </>
    </ThemeProvider>
  )
}
