import ConditionalRender from 'components/ConditionalRender'
import { CaretLeftIcon, CaretRightIcon } from 'components/core/Icons'
import { ToggleOption } from 'components/core/Toggler'
import { pxToRem } from 'libs/styled'
import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Range } from 'react-range'
import { IRenderThumbParams, IRenderTrackParams } from 'react-range/lib/types'
import { DefaultTheme, ThemeContext } from 'styled-components'
import { DottedLine } from '../../styles'
import { CalibrationMethods, Frame, MeasureUnit, MeasureUnits } from '../../types'
import {
  CalibrationStartIcon,
  CalibrationStartPoint,
  CalibrationThumb,
  CalibrationTrack,
  MeasureIndicator,
  MeasureUnitToggle,
  MeasureUnitToggleButton,
  RangeAndGlassContainer,
  RangeStyled,
  ReferenceGlassContainer,
  ReferenceObjectPlaceholder,
  ReferenceRulerContainer,
  Thumb,
  ThumbIconContainer,
} from '../styles'

const measureUnitMap = {
  [MeasureUnits.Cm]: '10 cm',
  [MeasureUnits.Inch]: '4’’',
}

interface CalibrationMethodProps {
  method: keyof typeof CalibrationMethods
  onUserDrag: (pxInMMRatio: number) => void
  selectedFrame?: Frame
  measureUnit: MeasureUnit
  setMeasureUnit: (measureUnit: MeasureUnit) => void
}

const CalibrationProcess: FC<CalibrationMethodProps> = ({
  method,
  onUserDrag,
  measureUnit,
  setMeasureUnit,
}) => {
  const MIN_VALUE = 0
  const MAX_VALUE = 100
  const STEP_VALUE = 0.1

  const theme: DefaultTheme = useContext(ThemeContext)

  const { t } = useTranslation()

  const rulerCalibration = method === CalibrationMethods.ruler

  const [rangeValue, setRangeValue] = useState(80)

  const measureRef = useRef(null)

  const onDrag = (value: number[]) => {
    setRangeValue(value[0])
  }

  const measureUnitOptions: ToggleOption[] = [
    { label: 'Cm', value: MeasureUnits.Cm },
    { label: 'Inches', value: MeasureUnits.Inch },
  ]

  const onChangeMeasureUnit = (value: string) => {
    setMeasureUnit(value as MeasureUnit)
  }

  useEffect(() => {
    if (measureRef.current) {
      // This represents the 10cm measured from the user in PXs
      const userMeasureInPX = measureRef.current.getBoundingClientRect().width
      onUserDrag(userMeasureInPX)
    }
  }, [onUserDrag, rangeValue])

  const renderReferenceElement = useCallback(() => {
    const elements: { [key in CalibrationMethods]: ReactNode } = {
      frame: (
        <ReferenceGlassContainer>
          <ReferenceObjectPlaceholder>
            {t('DigitalCouvette.calibration.framePlaceholder')}
          </ReferenceObjectPlaceholder>
        </ReferenceGlassContainer>
      ),
      ruler: (
        <ReferenceRulerContainer>
          <ReferenceObjectPlaceholder>
            {t('DigitalCouvette.calibration.rulerPlaceholder')}
          </ReferenceObjectPlaceholder>
        </ReferenceRulerContainer>
      ),
    }
    return elements[method]
  }, [method, t])

  const renderTrack = useCallback(
    ({ props, children }: IRenderTrackParams) => {
      return (
        <CalibrationTrack {...props}>
          <MeasureIndicator
            // TODO da rimettere orientation?
            // orientation="horizontal"
            style={{ width: `${rangeValue}%` }}
            visible={rulerCalibration}
            ref={measureRef}
          >
            <CaretLeftIcon
              height={pxToRem(12)}
              width={pxToRem(12)}
              stroke={theme.cta.backgroundColor}
              fill={theme.cta.backgroundColor}
            />
            <span>{measureUnitMap[measureUnit]}</span>
            <CaretRightIcon
              height={pxToRem(12)}
              width={pxToRem(12)}
              stroke={theme.cta.backgroundColor}
              fill={theme.cta.backgroundColor}
            />
          </MeasureIndicator>
          {renderReferenceElement()}
          <CalibrationStartPoint>
            <CalibrationStartIcon width={'100%'} height={'100%'} fill={theme.cta.backgroundColor} />
            <DottedLine orientation="vertical" />
          </CalibrationStartPoint>
          {children}
        </CalibrationTrack>
      )
    },
    [rangeValue, rulerCalibration, theme.cta.backgroundColor, measureUnit, renderReferenceElement]
  )

  const renderThumb = useCallback(
    ({ props }: IRenderThumbParams) => {
      return (
        <CalibrationThumb {...props}>
          <DottedLine orientation="vertical" />
          <ThumbIconContainer>
            <CaretLeftIcon stroke="none" fill={theme.cta.backgroundColor} />
            <Thumb />
            <CaretRightIcon stroke="none" fill={theme.cta.backgroundColor} />
          </ThumbIconContainer>
        </CalibrationThumb>
      )
    },
    [theme]
  )

  return (
    <RangeAndGlassContainer className="range-and-glasses-container">
      <RangeStyled>
        <ConditionalRender condition={rulerCalibration}>
          <MeasureUnitToggle
            ToggleButtonComponent={MeasureUnitToggleButton}
            options={measureUnitOptions}
            onChange={onChangeMeasureUnit}
            value={measureUnit}
            focus
          />
        </ConditionalRender>
        <Range
          min={MIN_VALUE}
          max={MAX_VALUE}
          values={[rangeValue]}
          step={STEP_VALUE}
          onChange={onDrag}
          renderTrack={renderTrack}
          renderThumb={renderThumb}
        />
      </RangeStyled>
    </RangeAndGlassContainer>
  )
}

export default CalibrationProcess
