import styled from 'styled-components'

export const ErrorWidget = styled.div`
  margin-top: 2em;
  padding: 3em 1em;
  width: 90%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.secondaryText};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
`

export const ErrorWidgetTitle = styled.h1`
  font-size: 2em;
  word-break: break-word;
`

export const ErrorWidgetLabels = styled.div`
  margin-bottom: 1em;
  text-align: center;
`

export const ErrorWidgetDetails = styled.div`
  text-align: right;

  a {
    display: inline-block;

    &::after {
      display: inline-block;
      width: 0.9091rem;
    }
  }
`

export const ErrorWidgetMessages = styled.div`
  padding: 1em;
  height: auto;
  visibility: visible;
  opacity: 1;
  text-align: left;
  min-height: 4.5455rem;
`
export const ErrorWidgetList = styled.ul`
  margin: 0;
  padding: 0;
`

export const ErrorWidgetMessage = styled.li`
  margin: 0;
  list-style: none;

  ul {
    padding-left: 1em;
    border-left: 1px solid ${({ theme }) => theme.colors.secondaryText};
  }
`
