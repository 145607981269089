import React from 'react'
import { IconProps } from 'types/icons'

export const ScanIcon = ({ width = '1rem', height = '1rem', fill = 'none' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 47 46"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path id="Vector" d="M17.5 1H1.5V17" stroke="black" strokeWidth="2" strokeLinecap="round" />
        <path
          id="Vector_2"
          d="M29.5 1H45.5V17"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          id="Vector_3"
          d="M29.5 45L45.5 45L45.5 29"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          id="Vector_4"
          d="M17.5 45L1.5 45L1.5 29"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path id="Line" d="M7 23H40" stroke="black" strokeWidth="2" strokeLinecap="round" />
      </g>
    </svg>
  )
}
