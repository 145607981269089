import { CloseIcon } from 'components/core/Icons'
import React from 'react'
import { PanelHeaderCloseButton, PanelHeaderContainer } from './styles'

interface PanelHeaderProps {
  onClose: React.EventHandler<React.MouseEvent>
  dataCalls?: string
  closeIcon?: React.ReactNode
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({
  children,
  onClose,
  dataCalls = '0',
  closeIcon = <CloseIcon />,
}) => {
  return (
    <PanelHeaderContainer>
      {children}
      <PanelHeaderCloseButton onClick={onClose} data-analytics_available_call={dataCalls}>
        {closeIcon}
      </PanelHeaderCloseButton>
    </PanelHeaderContainer>
  )
}
