import { BestMatchesIcon } from 'components/core/Icons'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultTheme, ThemeContext } from 'styled-components'
import { BestMatchBadge, BestMatchWrapper } from './styles'

interface BadgeBestMatchProps {
  isCapitalized?: boolean
  className?: string
}

export const BadgeBestMatch: React.FC<BadgeBestMatchProps> = ({
  className,
  isCapitalized = false,
}) => {
  const { t } = useTranslation()
  const theme: DefaultTheme = useContext(ThemeContext)
  return (
    <BestMatchWrapper className={className}>
      <BestMatchBadge isCapitalized={isCapitalized}>
        <BestMatchesIcon width="16" height="16" fill={theme.colors.white} stroke="none" />
        {t('FrameAdvisor.bestMatch')}
      </BestMatchBadge>
    </BestMatchWrapper>
  )
}
