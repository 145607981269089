import { generateDefaultFontProperties } from 'libs/typography'
import styled from 'styled-components'
import { InputProps, LinkTypographyProps, TypographyProps } from 'types/typography'
import { Paragraph } from './Paragraph'
export { Paragraph }

export const Heading1 = styled.h1<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.fonts.heading1, { ...props })}
`

export const Heading2 = styled.h2<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.fonts.heading2, { ...props })}
`

export const Heading3 = styled.h3<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.fonts.heading3, { ...props })}
`

export const Heading4 = styled.h4<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.fonts.heading4, { ...props })}
`

export const Heading5 = styled.h5<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.fonts.heading5, { ...props })}
`

export const ListTypography = styled.li<TypographyProps>`
  ${({ theme, ...props }) => generateDefaultFontProperties(theme, theme.fonts.label, { ...props })}
`

export const Label = styled.label<TypographyProps>`
  ${({ theme, ...props }) => generateDefaultFontProperties(theme, theme.fonts.label, { ...props })}
`

export const Text = styled.p<TypographyProps>`
  ${({ theme, ...props }) => generateDefaultFontProperties(theme, theme.fonts.body, { ...props })}
`

export const Caption = styled.p<TypographyProps>`
  ${({ theme, ...props }) =>
    generateDefaultFontProperties(theme, theme.fonts.caption, { ...props })}
`

export const LinkTypography = styled.a<LinkTypographyProps>`
  ${({ theme, ...props }) => generateDefaultFontProperties(theme, theme.fonts.link, { ...props })}
  text-decoration: ${({ removeUnderline }) => (removeUnderline ? 'none' : 'underline')};
`

export const ButtonTypography = styled.button<TypographyProps>`
  ${({ theme, ...props }) => generateDefaultFontProperties(theme, theme.fonts.cta, { ...props })}
`

export const InputTypography = styled.input<InputProps>`
  ${({ theme, ...props }) => generateDefaultFontProperties(theme, theme.fonts.body, { ...props })}
  padding: ${({ removePadding }) => (removePadding ? 0 : '0.45em 0.525em 0.4em')};
  border: ${({ bordered, theme }) => (bordered ? `solid 1px ${theme.colors.emperor}` : 'none')};
`
