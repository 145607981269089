import { get } from 'lodash'
import { OOThumbnailSide, RBThumbnailSide } from 'types/custom'
import { BOM, Custom, OOCustomRecipe, RBCustomRecipe } from 'types/customRecipe'
import { CurrencyCode, Maybe } from 'types/graphqlTypes'
import { Collection, CustomProduct } from 'types/product'
import { getBrandLogoAssetsUrl } from './brand'
import {
  getEngravingFromOneConfAttributes,
  getEngravingFromOneConfAttributesForOO,
  getLocalizedConfigurationFromBlob,
  getThumbnailUrl,
} from './custom'
import formatPrice from './formatPrice'

const ooCustomizableBrands = ['oo', 'oj', 'ox', 'oy', 'oz']
const rbCustomizableBrands = ['rb', 'rx', 'rj', 'ry']

export const isCustomizableEnabledForBrand = (
  originalBrandCode: string,
  remixEnabled: boolean,
  ocpEnabled: boolean
): boolean => {
  const brandCode = originalBrandCode.toLocaleLowerCase()
  if (rbCustomizableBrands.includes(brandCode)) return remixEnabled
  if (ooCustomizableBrands.includes(brandCode)) return ocpEnabled
  return false
}

// TODO: align with ssr
export const getCustomizableBrand = (brand: string): 'oo' | 'rb' | null => {
  const formattedBrand = brand.toLowerCase()
  if (rbCustomizableBrands.includes(formattedBrand)) return 'rb'
  if (ooCustomizableBrands.includes(formattedBrand)) return 'oo'
  return null
}

export const getThumbnailUrlForCustom = (
  recipeId: Maybe<number> | string,
  brand: string,
  scale?: number,
  backgroundColor?: string
): string => {
  const composerService = 'https://cdn-prod.fluidconfigure.com/imagecomposer'
  const sideCode = brand === 'RB' ? RBThumbnailSide.FrontLeft : OOThumbnailSide.Left

  return getThumbnailUrl(
    Number(recipeId),
    scale,
    sideCode,
    composerService,
    backgroundColor || '#f6f6f6',
    'png'
  )
}

const getPriceFromCustom = (custom: Custom) => (custom.pricing ? custom.pricing.finalPrice : 0)

export const formatCustomOOProduct = (
  recipe: OOCustomRecipe,
  currency: string,
  url: string,
  defaultStoreLang: string
): CustomProduct => {
  const { custom, localized_configuration } = recipe
  const name: BOM['modelMetadata']['label'] = get(custom, 'bom.modelMetadata.label')
  const model: BOM['modelCode'] = get(custom, 'bom.modelCode')
  const recipeId: BOM['recipeId'] = get(custom, 'bom.recipeId')

  const customitemEngraving = getEngravingFromOneConfAttributesForOO(localized_configuration)
  const brand = {
    id: 'OCP',
    code: 'OO',
    name: 'Oakley',
    logo: {
      id: 'Oakley',
      src: getBrandLogoAssetsUrl('oo'),
      altText: 'Oakley',
    },
    url,
    currency,
  }

  const originalBrand = 'OO'
  const formattedPrice = {
    id: '',
    current: {
      value: getPriceFromCustom(custom),
      text: formatPrice({
        price: getPriceFromCustom(custom),
        currency,
        defaultLangCode: defaultStoreLang,
        fractionDigits: 2,
      }),
    },
    currency: currency as CurrencyCode,
  }
  const {
    lensColor,
    lensColor2,
    frameColor,
    size,
    jawColor,
    templeLongColor,
    stemsColor,
    iconColor,
    iconColor2,
    clipsColor,
    polarized = false,
  } = getLocalizedConfigurationFromBlob(recipe)

  return {
    recipeId,
    url,
    type: 'OCP',
    customizable: true,
    name,
    model,
    moco: model,
    size: Number(size),
    brand,
    originalBrand,
    price: formattedPrice,
    frameColor,
    lensColor,
    lensColor2,
    collection: Collection.Sun,
    image: getThumbnailUrl(Number(recipeId)),
    jawColorLabel: jawColor,
    templeLongColorLabel: templeLongColor,
    stemsColorLabel: stemsColor,
    iconColorLabel: iconColor,
    iconColor2,
    clipsColorLabel: clipsColor,
    customitemEngraving,
    customItem: recipe,
    availability: {
      id: `availability-${recipe.id}`,
      available: true,
      stock: 0,
      ecommStock: 1,
    },
    UPC: model,
    vto: false,
    image360: false,
    frameColorLabel: frameColor,
    lensColorLabel: lensColor,
    polarized,
    id: recipeId,
    displayMaterialCode: model,
    sku: size,
  }
}

export const getCaseThumbnailUrl = (
  recipeId: number,
  brand = 'RB',
  scale = 5,
  url = 'https://configure-imagecomposer.fluidretail.net',
  backgroundColor = '',
  format: 'jpg' | 'png' = 'jpg'
): string => {
  const bagName = brand === 'RB' ? 'CC' : 'Microbag'
  let thumbnailUrl = `${url}/recipe/${recipeId}/image/${bagName}.${format}?scale=.${scale}`
  if (backgroundColor) {
    const postCompose = `[{"background":"${backgroundColor}"}]`
    thumbnailUrl = `${thumbnailUrl}&postCompose=${encodeURIComponent(postCompose)}`
  }
  return thumbnailUrl
}

export const getCaseThumbnailUrlForCustom = (
  recipeId: Maybe<number> | string,
  brand = 'RB',
  scale?: number,
  backgroundColor?: string
): string => {
  const composerService = 'https://cdn-prod.fluidconfigure.com/imagecomposer'

  return getCaseThumbnailUrl(
    Number(recipeId),
    brand,
    scale,
    composerService,
    backgroundColor || '#f6f6f6',
    'png'
  )
}

export const formatCustomRBProduct = (
  recipe: RBCustomRecipe,
  currency: string,
  url: string,
  productCollection: string,
  defaultStoreLang: string
): CustomProduct => {
  const { custom } = recipe
  const name: BOM['modelMetadata']['label'] = get(custom, 'bom.modelMetadata.label')
  const model: BOM['modelCode'] = get(custom, 'bom.modelCode')
  const brand = {
    id: 'RB-REMIX',
    code: 'RB',
    name: 'Ray-Ban',
    logo: {
      id: 'Ray-Ban',
      src: getBrandLogoAssetsUrl('rb'),
      altText: 'Ray-Ban',
    },
    currency,
  }
  const formattedPrice = {
    id: `price-${model}`,
    current: {
      value: getPriceFromCustom(custom),
      // https://luxotticaretail.atlassian.net/wiki/spaces/ONECONF/pages/347209775/Technical+Specifications#TechnicalSpecifications-HowtogetBOMandPricingfromrecipe
      text: formatPrice({
        price: getPriceFromCustom(custom),
        currency,
        defaultLangCode: defaultStoreLang,
        fractionDigits: 2,
      }),
    },
    currency: currency as CurrencyCode,
  }
  const {
    lensColor,
    frameColor,
    size,
    templeTipsColor,
    frameFrontColor,
    polarized = false,
  } = getLocalizedConfigurationFromBlob(recipe)
  const recipeId = get(custom, 'bom.recipeId')
  const customitemEngraving = getEngravingFromOneConfAttributes({
    ...recipe.localized_configuration,
    case_category: recipe.localized_configuration.case_category || '',
  })
  return {
    recipeId,
    url,
    type: 'CUSTOMRB',
    customizable: true,
    name,
    size: Number(size),
    brand,
    originalBrand: 'RB',
    price: formattedPrice,
    lensColor,
    frameColor,
    frameFrontColorLabel: frameFrontColor,
    templeTipsColorLabel: templeTipsColor,
    customitemEngraving,
    collection: productCollection,
    image: getThumbnailUrl(recipeId, undefined, RBThumbnailSide.FrontLeft),
    UPC: model,
    vto: false,
    image360: false,
    frameColorLabel: frameColor,
    lensColorLabel: lensColor,
    polarized,
    id: recipeId,
    displayMaterialCode: model,
    sku: size,
    model,
    moco: model,
    customItem: recipe,
    availability: {
      id: `availability-${recipe.id}`,
      available: true,
      stock: 0,
      ecommStock: 1,
    },
  }
}
