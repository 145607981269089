import { AppActionTypes, RESET_APP_ERROR, SET_APP_ERROR, SET_INITIAL_APP_CONF } from 'actions/app'
import config from 'config'
import { setAuthToken } from 'libs/auth'

export interface APPState {
  token?: string | null
  url: string
  error?: {
    message?: string
    status?: string
    statusText?: string
  }
}

const initialState: APPState = {
  token: null,
  url: localStorage.getItem(config.urlKey),
  error: null,
}

export default (state = initialState, action: AppActionTypes): APPState => {
  switch (action.type) {
    case SET_INITIAL_APP_CONF: {
      setAuthToken(action.payload.token)
      localStorage.setItem(config.urlKey, `/${action.payload.url}`)
      return { ...state, token: action.payload.token, url: `/${action.payload.url}` }
    }
    case SET_APP_ERROR: {
      return { ...state, error: { message: action.payload } }
    }
    case RESET_APP_ERROR: {
      return {
        ...state,
        error: null,
      }
    }

    default:
      return state
  }
}
