import React from 'react'
import { IconProps } from 'types/icons'

export const HomeIconMenu: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = 'none',
  stroke = '#333',
}) => {
  return (
    <svg width={width} height={height} viewBox="-3 -8 32 50" xmlns="http://www.w3.org/2000/svg">
      <g fill={fill} fillRule="evenodd" stroke={stroke} strokeLinecap="round">
        <path d="M31.45 11.2165L16.7827 0.567167C16.5493 0.397833 16.274 0.3125 15.9993 0.3125C15.7247 0.3125 15.45 0.397167 15.2167 0.5665L0.550667 11.2025C0.204667 11.4532 0 11.8545 0 12.2818V30.6652C0 31.4018 0.596667 31.9985 1.33333 31.9985H12H20H30.6667C31.4033 31.9985 32 31.4018 32 30.6652V12.2952C32 11.8685 31.7953 11.4672 31.45 11.2165ZM12 30.6652V21.3318H20V30.6652H12ZM30.6667 30.6652H21.3333V21.3318C21.3333 20.5952 20.7367 19.9985 20 19.9985H12C11.2633 19.9985 10.6667 20.5952 10.6667 21.3318V30.6652H1.33333V12.2818L15.9993 1.64583H16L30.6667 12.2952V30.6652Z" />
      </g>
    </svg>
  )
}
