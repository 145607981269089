import { setAppError } from 'actions/app'
import config from 'config'
import { useStoreData } from 'data'
import { usePageChecker } from 'hooks/useNavigationUtils'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { isAuthenticated } from 'libs/auth'
import { redirectToFAAuthErrorPage, redirectToLoginPage } from 'libs/routing'
import { StoreProvider } from 'providers/storeProvider'
import { useGetI18n } from 'providers/translationProvider'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Route, RouteProps, useHistory } from 'react-router-dom'

interface PrivateRoute extends RouteProps {
  computedMatch?: { url: string }
}

export const PrivateRoute: React.FC<PrivateRoute> = ({
  component: Component,
  computedMatch,
  ...rest
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { isFrameAdvisorPage } = usePageChecker()
  const i18n = useGetI18n()

  const { data: storeData } = useStoreData()

  const { langCode } = useStoreIndentity()

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isAuthenticated()) {
      if (isFrameAdvisorPage) {
        return redirectToFAAuthErrorPage()
      }
      dispatch(setAppError(t('LoginErrors.112')))
      redirectToLoginPage()
    }
  }, [dispatch, t, isFrameAdvisorPage])

  useEffect(() => {
    if (
      storeData?.store &&
      computedMatch &&
      (!computedMatch.url.includes(`${storeData.store.storeType}/${storeData.store.deviceId}`) ||
        !langCode)
    ) {
      const deviceLangCode = storeData.store.defaultLangCode || langCode || config.fallbackLanguage
      const storeUrl = `/${storeData.store.storeType}/${storeData.store.deviceId}/${deviceLangCode}`
      localStorage.setItem(config.urlKey, storeUrl)
      history.push(storeUrl)
      if (deviceLangCode !== i18n.language) {
        i18n.changeLanguage(deviceLangCode)
      }
    }
  }, [computedMatch, history, i18n, langCode, storeData])

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated()) {
          const readonly = localStorage.getItem(config.appReadOnlyKey)
          if (storeData?.store.userStatus === 'ACTIVE_UNREGISTERED' && !readonly) {
            redirectToLoginPage()
          }
          return (
            <StoreProvider>
              <Component {...props} />
            </StoreProvider>
          )
        }
        return <></>
      }}
    />
  )
}
