import { toggleScanBarcodeDrawer } from 'actions/ui'
import { useMemo } from 'react'
import { useAction } from './useActions'

export const useBarcodeScanner = () => {
  const toggleBarcodeScanner = useAction(toggleScanBarcodeDrawer)
  return useMemo(
    () => ({
      toggleBarcodeScanner: toggleBarcodeScanner,
    }),
    [toggleBarcodeScanner]
  )
}
