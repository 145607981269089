import { IconProps } from 'types/icons'
import React from 'react'

export const VmIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 2C1.33333 1.63267 1.63267 1.33333 2 1.33333H6.66667V0H2C0.897333 0 0 0.897333 0 2V6.66667H1.33333V2Z"
        fill={fill}
      />
      <path
        d="M1.33333 30.0007V25.334H0V30.0007C0 31.1033 0.897333 32.0007 2 32.0007H6.66667V30.6673H2C1.63267 30.6673 1.33333 30.3687 1.33333 30.0007Z"
        fill={fill}
      />
      <path
        d="M30.6663 30.0007C30.6663 30.3687 30.367 30.6673 29.9997 30.6673H25.333V32.0007H29.9997C31.1023 32.0007 31.9997 31.1033 31.9997 30.0007V25.334H30.6663V30.0007Z"
        fill={fill}
      />
      <path
        d="M29.9997 0H25.333V1.33333H29.9997C30.367 1.33333 30.6663 1.63267 30.6663 2V6.66667H31.9997V2C31.9997 0.897333 31.1023 0 29.9997 0Z"
        fill={fill}
      />
      <path
        d="M29.5218 21.7327C31.5611 20.14 32.5058 16.68 31.7198 13.6833C31.1098 11.3587 29.5611 9.84398 27.4578 9.52665C26.5871 9.41198 25.6311 9.34332 24.6665 9.33932V9.33398H7.33381V9.33865C6.36515 9.34332 5.40581 9.41198 4.52981 9.52798C2.43915 9.84398 0.890481 11.3587 0.280481 13.6833C-0.505519 16.68 0.439814 20.1407 2.47848 21.732V21.7327C3.27115 22.3507 4.22915 22.664 5.27581 22.664C5.89981 22.664 6.55448 22.5527 7.22515 22.3273C10.2885 21.2987 13.2085 18.102 14.3845 14.56C15.3145 13.856 16.6838 13.8553 17.6145 14.5593C18.7825 18.092 21.7065 21.298 24.7751 22.328C25.4458 22.5533 26.1005 22.6646 26.7245 22.6646C27.7711 22.664 28.7291 22.35 29.5218 21.7327ZM13.2905 13.5787C12.3885 16.956 9.65981 20.104 6.80181 21.064C5.84181 21.386 4.45448 21.582 3.29915 20.6807C1.68181 19.418 0.923147 16.4933 1.57115 14.0213C1.84648 12.972 2.62248 11.1647 4.71781 10.848C5.61981 10.7293 6.50048 10.6707 7.33448 10.666V10.6673H7.62781C10.1085 10.692 12.1311 11.2093 12.9465 12.1107C13.3191 12.5233 13.4271 12.996 13.2905 13.5787ZM14.6645 12.9033C14.6178 12.276 14.3751 11.7033 13.9345 11.2167C13.7505 11.0133 13.5318 10.834 13.2931 10.668H18.7071C18.4691 10.834 18.2498 11.0133 18.0658 11.2167C17.6271 11.702 17.3845 12.2733 17.3378 12.904C16.9118 12.752 16.4605 12.6673 16.0005 12.6673C15.5405 12.6673 15.0905 12.752 14.6645 12.9033ZM18.7151 13.598C18.5738 12.996 18.6818 12.5233 19.0551 12.1107C19.8731 11.2067 21.9071 10.6893 24.3938 10.6673H24.6671V10.6667C25.4985 10.6713 26.3745 10.7273 27.2718 10.846C29.3791 11.1647 30.1551 12.972 30.4305 14.0213C31.0785 16.4933 30.3191 19.418 28.7025 20.6807C27.5465 21.5827 26.1598 21.3867 25.1998 21.064C22.3418 20.104 19.6125 16.9567 18.7151 13.598Z"
        fill={fill}
      />
    </svg>
  )
}
