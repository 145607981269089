import {
  VMMV_PICTURE_VIDEO_LOCALSTORAGE_KEY,
  VMMV_POLICY_COOKIE_KEY,
  VMMV_POLICY_COOKIE_KEY2,
  VMMV_SAVED_STATE_KEY,
  VM_STYLE,
} from 'components/VirtualMirror/constants'
import config from 'config'
import * as QRCodeBase from 'qrcode.react'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import Sniffr from 'sniffr'
import { Store } from 'types/store'

export const formatVMDataForCustom = (
  token: string,
  localizedName: string,
  urlThumbnail: string,
  store: Store,
  brandName: 'rb' | 'oo'
) => {
  const data = {
    store: {
      storeId: store.storeId,
      id: store.id,
      type: store.storeType,
      region: store.region,
      globalStoreId: store.globalStoreId,
    },
    glassEnv: config.vmEnv.toUpperCase(),
    env: config.vmEnv,
    style: VM_STYLE,
    products: [
      {
        upc: token,
        code: localizedName,
        name: localizedName,
        frameColorLabel: '',
        lensColorLabel: '',
        colorCode: '',
        thumbnailUrl: urlThumbnail,
        subtitle: getVMProductSubtitle(store.region, token),
        brand: {
          logoUrl: `${config.assets.URL}/brands/${brandName.toLowerCase()}.svg`,
          name: brandName,
        },
      },
    ],
    analytics: {
      adobeSessionId: window.utag_data?.Adobe_SessionId || undefined,
      pagePlatform: 'SSW',
      pageBrand: config.tealiumAnalytics.shopperSlug,
      storeId: store.storeId,
      pageEnvironment: window.utag_data?.Page_Environment || undefined,
      storeRegion: store.region,
      storeCompany: 'm4c',
      storeGlobalId: store.storeId,
      source: 'QRCODE',
      sourcePosition: 'custom',
    },
  }

  return data
}

export type VmDataForCustom = ReturnType<typeof formatVMDataForCustom>

export const getVMProductSubtitle = (region: string, upc: string) =>
  ['US', 'CA'].includes(region) ? upc : ''

export const generateBase64Qrcode = (value: string, size = 100) => {
  const svg = ReactDOMServer.renderToString(
    <QRCodeBase xmlns="http://www.w3.org/2000/svg" size={size} value={value} renderAs={'svg'} />
  )
  return btoa(svg)
}

export const caseInsensitiveMatch = (str1: string, str2: string) => {
  const caseInsensitive = new RegExp(str1, 'i')
  return str2.match(caseInsensitive)
}

export const getChromiumVersion = (): number => {
  const sniffr = new Sniffr()
  const sys = sniffr.sniff(navigator.userAgent)
  const isChromium = caseInsensitiveMatch(sys.browser.name, 'chrome')
  const version: string = sys.browser.versionString
  return isChromium ? parseInt(version.split('.')[0]) : 0
}

export const isIOS = (): boolean => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent)
}

export const isDesktop = (): boolean => {
  if (isIOS() || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return false
  }
  return !/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
    navigator.userAgent
  )
}

export type MicroserviceEnvironment = 'STAGING' | 'ACCEPTANCE' | 'PRODUCTION'
export const getVTOEnvironment = (): MicroserviceEnvironment => {
  return config.vtoEnv === 'test' ? 'STAGING' : 'PRODUCTION'
}

export const getIosVersion = (): number[] => {
  const sniffr = new Sniffr()
  const sys = sniffr.sniff(navigator.userAgent)
  const isIpad = sys.os.name === 'ios'
  const version: number[] = sys.os.version
  return isIpad ? version : [0]
}

const cleanupVMMVPictureVideo = () => {
  localStorage.removeItem(VMMV_PICTURE_VIDEO_LOCALSTORAGE_KEY)
}

const setCookieExpiredForAllPaths = (
  name: string,
  cookieString?: string,
  count = -1,
  paths?: string[]
): boolean => {
  if (paths && count > paths.length - 1) {
    return false
  } else if (paths) {
    cookieString = `${cookieString}/${paths[count]}`.replace('//', '/')
    document.cookie = cookieString
    document.cookie = `${cookieString}/`
  } else {
    cookieString = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;`
    document.cookie = cookieString
    cookieString = `${cookieString}path=`
  }

  if (document.cookie.indexOf(name) !== -1) {
    setCookieExpiredForAllPaths(
      name,
      cookieString,
      count + 1,
      paths || location.pathname.split('/')
    )
  } else {
    return true
  }
}

const cleanupVMMVPolicyAcceptance = () => {
  setCookieExpiredForAllPaths(VMMV_POLICY_COOKIE_KEY)
  setCookieExpiredForAllPaths(VMMV_POLICY_COOKIE_KEY2)
  localStorage.removeItem(VMMV_SAVED_STATE_KEY)
}

export const cleanupVMMVData = () => {
  cleanupVMMVPictureVideo()
  cleanupVMMVPolicyAcceptance()
}
