import { Text } from 'components/Typography'
import { pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: flex;
  margin-right: 1rem;
  position: relative;
`

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: flex;
  width: 1em;
  height: 1em;
  background-color: ${props => (props.checked ? props.theme.colors.blue : 'white')};
  border-radius: 6px;
  border: 1px solid gray;
  border: ${({ theme }) => `1px solid ${theme.colors.blue}`};

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`

export const CheckboxWrapper = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
`

export const CheckboxDescription = styled(Text)<{ disabled: boolean }>`
  padding-left: ${pxToRem(48)};
  text-transform: none;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
`
