import React from 'react'
import { ErrorMessageWrapper, ErrorMessageTitle, ErrorMessageText } from './styles'

export const ErrorMessage: React.FC<{ title?: string }> = ({ title, children }) => {
  return (
    <ErrorMessageWrapper>
      {title && <ErrorMessageTitle>{title}</ErrorMessageTitle>}
      <ErrorMessageText>{children}</ErrorMessageText>
    </ErrorMessageWrapper>
  )
}
