import React, { useContext } from 'react'
import { DefaultTheme, ThemeContext } from 'styled-components'
import { IconProps } from 'types/icons'

export const QuizProfileIcon = ({ width = '1rem', height = '1rem', className = '' }: IconProps) => {
  const theme: DefaultTheme = useContext(ThemeContext)
  const color = theme.colors.royalBlue
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 88 93"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      className={className}
    >
      <path
        fill={color}
        d="M27.672 16.98c0-3.358.957-6.64 2.75-9.433 1.791-2.793 4.339-4.97 7.319-6.254a15.713 15.713 0 0 1 9.424-.967c3.164.655 6.07 2.273 8.351 4.648 2.281 2.374 3.835 5.4 4.464 8.694a17.63 17.63 0 0 1-.928 9.811c-1.235 3.103-3.325 5.755-6.008 7.621a15.857 15.857 0 0 1-9.061 2.862c-4.326 0-8.475-1.79-11.533-4.974-3.06-3.184-4.778-7.503-4.778-12.007ZM.004 92.359h27.659v-.776H.004v.383c0 .13-.01.262 0 .393Zm28.926-6.98-26.014-9.85c-.184.523-.367 1.007-.542 1.56L28.38 86.95c.125-.544.348-1.067.55-1.571Zm-.968 3.021L.711 83.364c-.068.372-.136.755-.194 1.138l27.252 5.035c.097-.332.165-.715.242-1.097l-.049-.04Zm2.477-5.821L6.467 68.165a43.609 43.609 0 0 0-1.045 1.874L29.404 84.45c.3-.65.645-1.277 1.035-1.873Zm1.935-2.488L11.09 61.528a52.45 52.45 0 0 0-1.635 2.014l21.206 18.532a16.453 16.453 0 0 1 1.712-2.014v.03Zm27.958 12.298H88v-.393c.01-12.02-4.53-23.56-12.634-32.119l-.242-.261a44.785 44.785 0 0 0-5.543-4.885l-3.87 4.794 3.008-5.419a43.048 43.048 0 0 0-5.523-3.333l-4.392 7.916 3.008-8.581a42.532 42.532 0 0 0-5.553-2.105l-3.947 11.28 2.003-11.814a42.413 42.413 0 0 0-5.34-1.007l-2.477 14.634V46.249c-.822-.05-1.645-.08-2.476-.08-.832 0-1.655 0-2.477.08v17.716l-2.95-17.444c-1.48.188-2.949.453-4.402.796L37.627 67.6l-6.772-19.347a44.553 44.553 0 0 0-3.753 1.43L34.84 71.72 23.58 51.416a44.685 44.685 0 0 0-3.038 1.833l12.634 22.782-16.282-20.143a65.389 65.389 0 0 0-2.322 2.014l17.8 22.118a16.307 16.307 0 0 1 5.328-3.721A15.772 15.772 0 0 1 43.992 75c2.16.004 4.296.455 6.287 1.325 1.99.87 3.796 2.143 5.312 3.744l.068.07.241.272.058.061.262.302.26.332.281.363c2.36 3.09 3.61 6.94 3.531 10.888l.04.03Z"
      />
    </svg>
  )
}
