import React from 'react'
import { IconProps } from 'types/icons'

export const PictureModeIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#555',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2C1 1.7245 1.2245 1.5 1.5 1.5H5V0.5H1.5C0.673 0.5 0 1.173 0 2V5.5H1V2Z"
        fill={fill}
      />
      <path
        d="M1 23V19.5H0V23C0 23.827 0.673 24.5 1.5 24.5H5V23.5H1.5C1.2245 23.5 1 23.276 1 23Z"
        fill={fill}
      />
      <path
        d="M23 23C23 23.276 22.7755 23.5 22.5 23.5H19V24.5H22.5C23.327 24.5 24 23.827 24 23V19.5H23V23Z"
        fill={fill}
      />
      <path
        d="M22.5 0.5H19V1.5H22.5C22.7755 1.5 23 1.7245 23 2V5.5H24V2C24 1.173 23.327 0.5 22.5 0.5Z"
        fill={fill}
      />
      <path
        d="M22.1416 16.799C23.6711 15.6045 24.3796 13.0095 23.7901 10.762C23.3326 9.0185 22.1711 7.8825 20.5936 7.6445C19.9406 7.5585 19.2236 7.507 18.5001 7.504V7.5H5.5006V7.5035C4.7741 7.507 4.0546 7.5585 3.3976 7.6455C1.8296 7.8825 0.668105 9.0185 0.210605 10.762C-0.378895 13.0095 0.330105 15.605 1.8591 16.7985V16.799C2.4536 17.2625 3.1721 17.4975 3.9571 17.4975C4.4251 17.4975 4.9161 17.414 5.4191 17.245C7.7166 16.4735 9.9066 14.076 10.7886 11.4195C11.4861 10.8915 12.5131 10.891 13.2111 11.419C14.0871 14.0685 16.2801 16.473 18.5816 17.2455C19.0846 17.4145 19.5756 17.498 20.0436 17.498C20.8286 17.4975 21.5471 17.262 22.1416 16.799ZM9.9681 10.6835C9.2916 13.2165 7.2451 15.5775 5.1016 16.2975C4.3816 16.539 3.3411 16.686 2.4746 16.01C1.2616 15.063 0.692605 12.8695 1.1786 11.0155C1.3851 10.2285 1.9671 8.873 3.5386 8.6355C4.2151 8.5465 4.8756 8.5025 5.5011 8.499V8.5H5.7211C7.5816 8.5185 9.09861 8.9065 9.7101 9.5825C9.9896 9.892 10.0706 10.2465 9.9681 10.6835ZM10.9986 10.177C10.9636 9.7065 10.7816 9.277 10.4511 8.912C10.3131 8.7595 10.1491 8.625 9.9701 8.5005H14.0306C13.8521 8.625 13.6876 8.7595 13.5496 8.912C13.2206 9.276 13.0386 9.7045 13.0036 10.1775C12.6841 10.0635 12.3456 10 12.0006 10C11.6556 10 11.3181 10.0635 10.9986 10.177ZM14.0366 10.698C13.9306 10.2465 14.0116 9.892 14.2916 9.5825C14.9051 8.9045 16.4306 8.5165 18.2956 8.5H18.5006V8.4995C19.1241 8.503 19.7811 8.545 20.4541 8.634C22.0346 8.873 22.6166 10.2285 22.8231 11.0155C23.3091 12.8695 22.7396 15.063 21.5271 16.01C20.6601 16.6865 19.6201 16.5395 18.9001 16.2975C16.7566 15.5775 14.7096 13.217 14.0366 10.698Z"
        fill={fill}
      />
    </svg>
  )
}
