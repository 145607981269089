import React from 'react'
import { IconProps } from 'types/icons'
export const SkinUndertone2Icon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 78 78"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      stroke={stroke}
      className={className}
    >
      <mask
        id="mask0_3992_97265"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={4}
        y={4}
        width={70}
        height={70}
      >
        <circle cx={39} cy={39} r={34.8511} fill="#C4C4C4" />
        <circle cx={39} cy={39} r={34.8511} fill="url(#pattern0)" />
        <circle cx={39} cy={39} r={34.8511} fill="url(#pattern1)" />
      </mask>
      <g mask="url(#mask0_3992_97265)">
        <rect y={-19.0851} width={77.1702} height={116.17} fill="url(#pattern2)" />
        <rect
          x={38.585}
          y={-11.2885}
          width={14.1064}
          height={70.5319}
          transform="rotate(45 38.585 -11.2885)"
          fill="#F7E4DD"
        />
        <rect
          x={48.5596}
          y={-1.31396}
          width={14.1064}
          height={70.5319}
          transform="rotate(45 48.5596 -1.31396)"
          fill="#E0C8BE"
        />
        <rect
          x={58.5342}
          y={8.66089}
          width={14.1064}
          height={70.5319}
          transform="rotate(45 58.5342 8.66089)"
          fill="#C0A497"
        />
        <rect
          x={68.5088}
          y={18.6357}
          width={14.1064}
          height={70.5319}
          transform="rotate(45 68.5088 18.6357)"
          fill="#958076"
        />
        <rect
          x={78.4834}
          y={28.6106}
          width={14.1064}
          height={70.5319}
          transform="rotate(45 78.4834 28.6106)"
          fill="#735D53"
        />
      </g>
      <circle cx={39} cy={39} r={38.5} stroke="#D6D6D6" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width={1} height={1}>
          <use xlinkHref="#image0_3992_97265" transform="translate(-0.27193) scale(0.00438596)" />
        </pattern>
        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width={1} height={1}>
          <use xlinkHref="#image0_3992_97265" transform="translate(-0.27193) scale(0.00438596)" />
        </pattern>
        <pattern id="pattern2" patternContentUnits="objectBoundingBox" width={1} height={1}>
          <use
            xlinkHref="#image1_3992_97265"
            transform="translate(0 -0.00410334) scale(0.0035461 0.00235562)"
          />
        </pattern>
      </defs>
    </svg>
  )
}
