import flat from 'flat'
import { getConfigFromSessionStorage, getConfigOverrideSessionKey } from 'libs/configOverride'
import React from 'react'
import styled from 'styled-components'
import { ServiceApi } from './ServiceApi'
import config from 'config'
import { localStorageKeyPrefix } from '../../../config/config.base'

export const ServiceContent = styled.div`
  height: 100%;
  overflow: scroll;
  padding: 3rem;
  color: #000;

  h1 {
    font-size: 3rem;
  }
`

export const TableDataContent = styled.table`
  width: 100%;
  font-family: monospace;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid #ccc;

    &:hover {
      background-color: papayawhip;
    }
  }

  td {
    padding: 0.3rem;
  }
`

export const Notes = styled.p`
  padding: 0.3rem;
  font-size: 0.8rem;
  color: #999;
  text-align: right;
`
const Title = styled.h1`
  margin-bottom: 0;
  padding-bottom: 0;
`
const OverrideTitle = styled.span`
  color: #d94e34;
  font-weight: 700;
  font-size: 1.2rem;
`

interface FlatData {
  [k: string]: string
}

function copyTextToClipboard(text: string) {
  if (!window.navigator.clipboard) {
    return
  }
  navigator.clipboard.writeText(text)
}

const TableData = ({ data }: { data: unknown }) => {
  const flatData: FlatData = flat(data)

  return (
    <>
      <TableDataContent>
        <tbody>
          {Object.keys(flatData).map((r, i) => (
            <tr key={i}>
              <td>
                <strong>{r}</strong>
              </td>

              <td onDoubleClick={() => copyTextToClipboard(flatData[r])}>{String(flatData[r])}</td>
            </tr>
          ))}
        </tbody>
      </TableDataContent>
      <Notes>Double click on value to coNotesy data to clipboard</Notes>
    </>
  )
}

const RawJson = ({ json }: { json: unknown }) => (
  <details style={{ padding: '.5rem', marginBottom: '2rem' }}>
    <summary>JSON</summary>
    <pre>{JSON.stringify(json, undefined, 4)}</pre>
  </details>
)

function getBaseDomain(url: string) {
  return typeof url === 'string'
    ? url.split('/').reduce((url, seg, i) => (i < 3 ? (url += seg + '/') : url), '') + 'service'
    : ''
}

export const Service = () => {
  const configOverrideSessionKey = getConfigOverrideSessionKey(localStorageKeyPrefix)
  const configOverrideEnabled = getConfigFromSessionStorage(configOverrideSessionKey) !== null

  return (
    <ServiceContent>
      <Title>Service Info</Title>
      {configOverrideEnabled && (
        <OverrideTitle>
          Some local configurations override are enabled on your local environment
        </OverrideTitle>
      )}

      <h2>UI</h2>

      <TableData data={config} />
      <RawJson json={config} />

      <h2>API</h2>

      <ServiceApi apiEndpoint={getBaseDomain(config.middleware.URL)}>
        {({ loading, error, json }) => (
          <>
            {loading && <p>Loading...</p>}
            {json && (
              <>
                <TableData data={json} />
                <RawJson json={json} />
              </>
            )}

            {error && <pre>{JSON.stringify(error, undefined, 4)}</pre>}
          </>
        )}
      </ServiceApi>
    </ServiceContent>
  )
}
