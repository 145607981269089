import React from 'react'
import styled from 'styled-components'
import { ListContext } from './ListContext'

export interface ListProps {
  className?: string
  as?: keyof JSX.IntrinsicElements
}

const ListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const List: React.FC<ListProps> = ({ className, children, as = 'ul' }) => {
  return (
    <ListContext.Provider value={{ parentElement: as }}>
      <ListContainer as={as} className={className}>
        {children}
      </ListContainer>
    </ListContext.Provider>
  )
}
