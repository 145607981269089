import config from 'config'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export const COURTESY_ROUTE = '/courtesy'

const isCourtesyResponse = (response: Response): boolean => response.status === 418
const isNotCourtesy = (): boolean => false

const isCourtesyRouteEnabled = () => {
  // Matches:
  // /config-override
  // /service
  // courtesy will be disabled for these routes
  return !/^\/(config-override|service)$/.test(window.location.pathname)
}

export const CourtesyChecker: React.FC = ({ children }) => {
  const [isCourtesy, setIsCourtesy] = useState<boolean>()
  const history = useHistory()

  useEffect(() => {
    if (config.toggleFeature.courtesy && isCourtesyRouteEnabled()) {
      if (isCourtesy) {
        history.push(COURTESY_ROUTE)
      } else {
        history.location.pathname.includes(COURTESY_ROUTE) && history.push('/')
      }
    }
  }, [history, isCourtesy])

  useEffect(() => {
    if (config.toggleFeature.courtesy) {
      const checkCourtesy = () => {
        fetch(config.courtesyUrl).then(isCourtesyResponse).catch(isNotCourtesy).then(setIsCourtesy)
      }

      checkCourtesy()
      const courtesyCheckInterval = setInterval(checkCourtesy, config.courtesyInterval)

      return () => {
        clearInterval(courtesyCheckInterval)
      }
    }
  }, [])

  return <>{children}</>
}
