import classnames from 'classnames'
import { Text } from 'components/Typography'
import React from 'react'
import { Col } from '../Grid'
import { FormItemWrapper, HelpStyled, LabelStyled, LabelWrapper, ValidateWrapper } from './styles'

interface FormItemProps {
  help?: React.ReactNode
  extra?: React.ReactNode
  validateStatus?: 'success' | 'warning' | 'error' | 'validating'
  hasFeedback?: boolean
  required?: boolean
  style?: React.CSSProperties
  className?: string
  label?: string
  id?: string
}

export const FormItem: React.FC<FormItemProps> = ({
  className = '',
  children,
  label,
  id,
  required,
  validateStatus,
  hasFeedback,
  help,
  extra,
}) => {
  const renderHelp = () => {
    return (
      <HelpStyled className={`${className}-explain`} key="help">
        {help && help}
      </HelpStyled>
    )
  }

  const renderExtra = () => {
    return extra ? <div className={`${className}-extra`}>{extra}</div> : null
  }

  const renderValidateWrapper = (c1: React.ReactNode, c2: React.ReactNode, c3: React.ReactNode) => {
    const classes = !validateStatus
      ? `${className}-item-control`
      : classnames(`${className}-item-control`, {
          'has-feedback': hasFeedback || validateStatus === 'validating',
          'has-success': validateStatus === 'success',
          'has-warning': validateStatus === 'warning',
          'has-error': validateStatus === 'error',
          'is-validating': validateStatus === 'validating',
        })

    return (
      <ValidateWrapper className={classes}>
        <span className={`${className}-item-children`}>{c1}</span>
        {c2}
        {c3}
      </ValidateWrapper>
    )
  }

  return (
    <FormItemWrapper className={className}>
      {label && (
        <LabelWrapper className={`${className}-item-label`}>
          <LabelStyled
            htmlFor={id}
            title={label}
            required={required}
            className={required ? `${className}-item-required` : ''}
          >
            <Text>{label}</Text>
          </LabelStyled>
        </LabelWrapper>
      )}
      <Col>{renderValidateWrapper(children, renderHelp(), renderExtra())}</Col>
    </FormItemWrapper>
  )
}
