import { Modal as AntModal } from 'antd'
import ConditionalRender from 'components/ConditionalRender'
import { max } from 'lodash'
import React, { useCallback } from 'react'
import { CloseIcon } from '../Icons'
import { CloseIconContainer, ModalContent, ModalFooter, ModalHeader } from './styles'

export interface ModalProps {
  overlayVisible?: boolean
  visible: boolean
  onCancel?: React.MouseEventHandler
  dataAttr?: string
  dataDescription?: string
  dataCalls?: string
  footerContent?: React.ReactNode[]
  className?: string
  wrapClassName?: string
  width?: string
  renderHeader?: (() => React.ReactNode) | undefined
  renderCloseIcon?: (() => React.ReactNode) | undefined
  removeCloseBtn?: boolean
  centered?: boolean
  style?: React.CSSProperties
  aria?: {
    labelledby?: string
    describedby?: string
  }
}

export const Modal: React.FC<ModalProps> = ({
  overlayVisible = true,
  children,
  visible,
  onCancel,
  className,
  width,
  footerContent,
  dataAttr,
  dataDescription,
  dataCalls,
  renderHeader,
  renderCloseIcon,
  removeCloseBtn,
  centered = true,
  style,
  aria,
  wrapClassName,
}) => {
  const header = <ModalHeader centeredTitle={centered}>{renderHeader}</ModalHeader>

  const ref = useCallback(node => {
    if (node) {
      const { gap, width } = getComputedStyle(node)
      const buttons = [...(node.getElementsByTagName('button') as HTMLDivElement[])]
      const numberOfGaps = buttons.length - 1
      const maxButtonWidth =
        (Number(width.replace('px', '')) - numberOfGaps * Number(gap.replace('px', ''))) /
        buttons.length
      const maxWidth = max(buttons.map(el => Number(getComputedStyle(el).width.replace('px', ''))))
      buttons.forEach(button => {
        button.style.maxWidth = `${maxButtonWidth}px`
        button.style.width = `${maxWidth}px`
      })
    }
  }, [])

  return (
    <AntModal
      style={style}
      mask={overlayVisible}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={false}
      className={className}
      centered={centered}
      closable={false}
      width={width || 'auto'}
      aria-labelledby={aria?.labelledby}
      aria-modal={visible}
      aria-describedby={aria?.describedby}
      wrapClassName={wrapClassName}
    >
      {!removeCloseBtn ? (
        <CloseIconContainer
          data-element-id={dataAttr}
          data-description={dataDescription}
          data-analytics_available_call={dataCalls}
          onClick={onCancel}
        >
          {renderCloseIcon ? renderCloseIcon() : <CloseIcon />}
        </CloseIconContainer>
      ) : (
        <></>
      )}
      {renderHeader && header}
      <ModalContent>{children}</ModalContent>
      <ConditionalRender condition={!!footerContent?.length}>
        <ModalFooter ref={ref}>{footerContent}</ModalFooter>
      </ConditionalRender>
    </AntModal>
  )
}
