import React from 'react'
import { TooltipWrapper, ArrowLeft, LabelContainer, LabelStyled } from './styles'
import { Transition } from 'react-transition-group'
import { TooltipPosition, TooltipProps } from 'types/tooltip'

const SHOW_TOOLTIP_INTERVAL = 5000
const duration = 300

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

type TransitionTypes = Record<string, { [key: string]: number | string }>

const transitionStyles: TransitionTypes = {
  entering: { opacity: 1, visibility: 'visible' },
  entered: { opacity: 1, visibility: 'visible' },
  exiting: { opacity: 0, visibility: 'hidden' },
  exited: { opacity: 0, visibility: 'hidden' },
}

const getArrow = (tooltipPosition: TooltipPosition) => {
  switch (tooltipPosition) {
    case 'right': {
      return <ArrowLeft />
    }
    default: {
      return null
    }
  }
}

export const Tooltip: React.FC<TooltipProps> = ({ text, visible, position, children, timeout }) => (
  <TooltipWrapper>
    {children}
    <Transition timeout={timeout || SHOW_TOOLTIP_INTERVAL} in={visible}>
      {state => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <LabelContainer position={position}>
            {getArrow(position)}
            <LabelStyled aria-label={text} aria-description={text}>
              {text}
            </LabelStyled>
          </LabelContainer>
        </div>
      )}
    </Transition>
  </TooltipWrapper>
)
