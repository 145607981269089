import { hideAllDrawer as hideAllDrawerAction } from 'actions/ui'
import { useAction } from 'hooks/useActions'
import { useRTRVisibility } from 'hooks/useRTRVisibility'
import { useStoreContext } from 'hooks/useStoreContext'
import { landscape } from 'libs/media'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Product } from 'types/product'
import StandardProductCard from '../CardProduct/StandardProductCard'

export interface ProductListProps {
  items: Product[]
  previewQuality?: number
  genderValue?: string
  isSearchList?: boolean
}

const ProductListWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
`

const ProductItem = styled.div`
  padding: 1em;
  flex-basis: 50%;
  ${landscape(`
    flex-basis: 33%;
  `)}
`

export const ProductList: React.FC<ProductListProps> = ({ items, isSearchList }) => {
  const store = useStoreContext()
  const { isRTREnabled } = useRTRVisibility()
  const params = useParams<{ upc?: string }>()
  const hideAllDrawer = useAction(hideAllDrawerAction)

  const handleClick = (upc: string) => {
    // Hide search drawer while searching the same upc from pdp page
    if (upc !== params?.upc) return
    hideAllDrawer()
  }

  return (
    <ProductListWrapper>
      {items.map((item, index) => {
        return (
          <ProductItem key={item.UPC} onClick={() => handleClick(item.UPC)}>
            <StandardProductCard
              product={item}
              store={store}
              dataAttrs={{
                link: isSearchList ? `MainNav_SearchTiles_${index}-Img` : `Tiles_${index}-Img`,
                wishlist: `Tiles_${index}-FavAdd`,
              }}
              showRTRIcon={isRTREnabled}
            />
          </ProductItem>
        )
      })}
    </ProductListWrapper>
  )
}
