import React from 'react'
import { IconProps } from 'types/icons'

export const FiltersIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = 'none',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 33 32"
      aria-label="filtersIcon"
      role="button"
    >
      <g fill={fill} fillRule="evenodd" strokeWidth="1.5" transform="translate(1 1)">
        <path
          stroke="#333"
          strokeLinecap="round"
          d="M1.875 3.958h28.788M1.875 15h28.788M1.875 26.042h28.788"
        ></path>
        <circle cx="22.5" cy="3.75" r="3.75" fill="#FFF" stroke="#222"></circle>
        <circle cx="3.75" cy="15" r="3.75" fill="#FFF" stroke="#222"></circle>
        <circle cx="16.875" cy="26.25" r="3.75" fill="#FFF" stroke="#222"></circle>
      </g>
    </svg>
  )
}
