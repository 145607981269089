import { Text } from 'components/Typography'
import { BooleanResolver, useQueryParams } from 'hooks/useQueryParams'
import React from 'react'
import { useSelector } from 'react-redux'
import { BarCodeDebugMessageContainer } from './styles'

export const BarcodeDebugFlag: React.FC = () => {
  const { scenario } = useSelector(s => s.barcode)
  const queryParams = useQueryParams({
    barcodeDebug: BooleanResolver,
  })

  return queryParams.barcodeDebug && scenario ? (
    <BarCodeDebugMessageContainer>
      <Text weight="bold" color="white">
        {scenario}
      </Text>
    </BarCodeDebugMessageContainer>
  ) : null
}
