import React from 'react'
import { IconProps } from 'types/icons'
export const FaceLengthShortIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 52 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.0013 0.666992C18.238 0.666992 8.66797 10.237 8.66797 22.0003C8.66797 33.7637 18.238 43.3337 30.0013 43.3337C41.7646 43.3337 51.3346 33.7637 51.3346 22.0003C51.3346 10.237 41.7646 0.666992 30.0013 0.666992ZM30.0013 42.0003C18.9733 42.0003 10.0013 33.0283 10.0013 22.0003C10.0013 10.9723 18.9733 2.00033 30.0013 2.00033C41.0293 2.00033 50.0013 10.9723 50.0013 22.0003C50.0013 33.0283 41.0293 42.0003 30.0013 42.0003Z"
        fill={fill}
      />
      <path
        d="M0.667969 2.00033H2.0013V42.0003H0.667969V43.3337H4.66797V42.0003H3.33464V2.00033H4.66797V0.666992H0.667969V2.00033Z"
        fill={fill}
      />
      <path d="M32.6693 30H27.3359V31.3333H32.6693V30Z" fill={fill} />
      <path
        d="M31.3366 24.667H27.3359V26.0003H32.6686L32.6806 17.3343L31.3473 17.333L31.3366 24.667Z"
        fill={fill}
      />
    </svg>
  )
}
