import React from 'react'
import { IconProps } from 'types/icons'
export const UndertoneWarmIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0_7243_60284"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="62"
        height="62"
      >
        <circle cx="31.0002" cy="31.0002" r="30.383" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_7243_60284)">
        <rect
          x="31.127"
          y="-13.1274"
          width="12.2979"
          height="61.4894"
          transform="rotate(45 31.127 -13.1274)"
          fill="#FFE3CB"
        />
        <rect
          x="39.8223"
          y="-4.43164"
          width="12.2979"
          height="61.4894"
          transform="rotate(45 39.8223 -4.43164)"
          fill="#E5C6AB"
        />
        <rect
          x="48.5195"
          y="4.26416"
          width="12.2979"
          height="61.4894"
          transform="rotate(45 48.5195 4.26416)"
          fill="#BF9C7B"
        />
        <rect
          x="57.2148"
          y="12.9604"
          width="12.2979"
          height="61.4894"
          transform="rotate(45 57.2148 12.9604)"
          fill="#A27A56"
        />
        <rect
          x="65.9102"
          y="21.6562"
          width="12.2979"
          height="61.4894"
          transform="rotate(45 65.9102 21.6562)"
          fill="#805A39"
        />
      </g>
    </svg>
  )
}
