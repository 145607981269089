import React from 'react'
import { IconProps } from 'types/icons'
export const FaBridgePositionHighIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 59"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M76.4677 35.3146C76.7843 35.3146 77.1177 35.2146 77.401 35.0312C78.1677 34.5146 78.3677 33.4812 77.851 32.7146C77.351 31.9479 76.3177 31.7479 75.551 32.2479C74.7843 32.7662 74.5677 33.7996 75.0843 34.5662C75.401 35.0496 75.9343 35.3146 76.4677 35.3146Z"
        fill="black"
      />
      <path
        d="M48.717 50.732C48.0837 51.3837 48.0837 52.4337 48.7503 53.082C49.067 53.3987 49.5003 53.5653 49.917 53.5653C50.3503 53.5653 50.7837 53.3837 51.117 53.0653C51.7503 52.3987 51.7337 51.3487 51.0837 50.6987C50.417 50.0503 49.367 50.067 48.717 50.732Z"
        fill="black"
      />
      <path
        d="M58.1164 26.7997C57.183 26.8664 56.4997 27.6664 56.5831 28.5814C56.6497 29.4647 57.3831 30.1147 58.2497 30.1147C58.2831 30.1147 58.3331 30.1147 58.3664 30.1147C59.2831 30.0497 59.9664 29.233 59.8997 28.333C59.833 27.4164 59.033 26.7164 58.1164 26.7997Z"
        fill="black"
      />
      <path
        d="M51.2014 28.2318C50.3347 28.5651 49.918 29.5318 50.2514 30.3818C50.5014 31.0485 51.1347 31.4501 51.8014 31.4501C52.0014 31.4501 52.2014 31.4151 52.4014 31.3335C53.2514 31.0001 53.6847 30.0501 53.3514 29.1835C53.018 28.3335 52.0514 27.9001 51.2014 28.2318Z"
        fill="black"
      />
      <path
        d="M61.9004 55.0169C60.9837 55.0002 60.2171 55.7335 60.2171 56.6669C60.2004 57.5835 60.9337 58.3335 61.8504 58.3502C61.8671 58.3502 61.8671 58.3502 61.8837 58.3502C62.7837 58.3502 63.5337 57.6169 63.5337 56.7002C63.5504 55.7836 62.8171 55.0335 61.9004 55.0169Z"
        fill="black"
      />
      <path
        d="M45.6842 44.8833C44.8175 45.2333 44.4009 46.2 44.7509 47.05C45.0009 47.7016 45.6342 48.1 46.3009 48.1C46.5009 48.1 46.7175 48.0666 46.9175 47.985C47.7675 47.6516 48.1842 46.6683 47.8508 45.8183C47.5008 44.965 46.5342 44.5483 45.6842 44.8833Z"
        fill="black"
      />
      <path
        d="M45.0498 41.6665C45.9831 41.6315 46.6998 40.8665 46.6665 39.9498C46.6331 39.0331 45.8665 38.2998 44.9498 38.3331C44.0165 38.3665 43.2998 39.1315 43.3331 40.0665C43.3665 40.9665 44.0998 41.6665 44.9998 41.6665C45.0165 41.6665 45.0331 41.6665 45.0498 41.6665Z"
        fill="black"
      />
      <path
        d="M64.9679 26.7816C64.0513 26.7166 63.2513 27.3983 63.1846 28.3166C63.1013 29.2333 63.7846 30.0316 64.718 30.115C64.7513 30.115 64.8013 30.115 64.8346 30.115C65.7013 30.115 66.4346 29.45 66.5013 28.5816C66.568 27.6666 65.8846 26.8483 64.9679 26.7816Z"
        fill="black"
      />
      <path
        d="M56.0508 53.8834C55.1842 53.5501 54.2175 53.9667 53.8842 54.8167C53.5508 55.6851 53.9675 56.6517 54.8342 56.9834C55.0175 57.0684 55.2342 57.1001 55.4342 57.1001C56.1008 57.1001 56.7175 56.7001 56.9842 56.0334C57.3175 55.1817 56.9008 54.2167 56.0508 53.8834Z"
        fill="black"
      />
      <path
        d="M78.15 38.25C77.2333 38.25 76.5 38.9983 76.5 39.9167C76.5 39.95 76.5 39.9833 76.5 40C76.5 40.0167 76.5 40.0333 76.5 40.05C76.5333 40.9483 77.2667 41.6667 78.1667 41.6667C78.1833 41.6667 78.2 41.6667 78.2167 41.6667C79.1333 41.6317 79.8667 40.8667 79.8333 39.95C79.8333 39.9333 79.8333 39.9167 79.8333 39.9C79.8333 38.9833 79.0833 38.25 78.15 38.25Z"
        fill="black"
      />
      <path
        d="M67.7665 54.0335C66.8998 54.3485 66.4665 55.3002 66.7832 56.1668C67.0332 56.8318 67.6665 57.2485 68.3498 57.2485C68.5332 57.2485 68.7332 57.2152 68.9332 57.1485C69.7832 56.8152 70.2332 55.8635 69.8998 54.9985C69.5832 54.1318 68.6332 53.7002 67.7665 54.0335Z"
        fill="black"
      />
      <path
        d="M72.6847 50.8999C72.0347 51.5499 72.0347 52.5999 72.7013 53.2499C73.018 53.5649 73.4347 53.7332 73.868 53.7332C74.3013 53.7332 74.718 53.5649 75.0513 53.2499C75.7013 52.5832 75.7013 51.5332 75.0513 50.8832C74.3847 50.2332 73.3347 50.2482 72.6847 50.8999Z"
        fill="black"
      />
      <path
        d="M77.818 44.9162C76.9513 44.6145 76.0013 45.0979 75.718 45.9662C75.418 46.8328 75.8846 47.7828 76.7513 48.0828C76.9346 48.1328 77.118 48.1662 77.2846 48.1662C77.9846 48.1662 78.6346 47.7328 78.868 47.0328C79.168 46.1662 78.7013 45.2162 77.818 44.9162Z"
        fill="black"
      />
      <path
        d="M72.8335 30.3503C73.1668 29.4819 72.7335 28.5153 71.8668 28.2003C71.0168 27.8669 70.0502 28.3003 69.7335 29.1669C69.4001 30.0169 69.8335 30.9836 70.7001 31.3169C70.8835 31.3836 71.0835 31.4169 71.2835 31.4169C71.9502 31.4169 72.5835 31.0153 72.8335 30.3503Z"
        fill="black"
      />
      <path
        d="M4.28397 45.9663C4.00064 45.098 3.05064 44.633 2.1673 44.933C1.30064 45.2163 0.833971 46.1663 1.13397 47.0496C1.13397 47.033 1.13397 47.033 1.13397 47.033C1.3673 47.733 2.0173 48.1663 2.7173 48.183C2.88397 48.183 3.0673 48.148 3.25064 48.0813C4.1173 47.7813 4.58397 46.848 4.28397 45.9813V45.9663Z"
        fill="black"
      />
      <path
        d="M10.1841 29.1837C9.8508 28.3337 8.88413 27.9003 8.03413 28.2337C7.16747 28.567 6.73413 29.5337 7.06747 30.3837C7.33413 31.0503 7.9508 31.452 8.63413 31.452C8.83413 31.452 9.03413 31.417 9.23413 31.3503C10.0841 31.0003 10.5175 30.0503 10.1841 29.1837Z"
        fill="black"
      />
      <path
        d="M4.96695 50.8999C4.30028 51.5499 4.30028 52.5999 4.95028 53.2499C5.28361 53.5815 5.70028 53.7499 6.13361 53.7499C6.56695 53.7499 6.98361 53.5815 7.31695 53.2499C7.96695 52.6149 7.96695 51.5482 7.31695 50.8999C6.66695 50.2482 5.61695 50.2482 4.96695 50.8999Z"
        fill="black"
      />
      <path
        d="M1.83382 41.6668C2.71716 41.6668 3.45049 40.9835 3.50049 40.0835C3.53382 39.1668 2.83382 38.3835 1.91716 38.3501C0.983825 38.3001 0.200491 39.0168 0.167158 39.9335C0.133825 40.8501 0.833825 41.6335 1.75049 41.6668C1.78382 41.6668 1.80049 41.6668 1.83382 41.6668Z"
        fill="black"
      />
      <path
        d="M12.2329 54.0331C11.3829 53.6997 10.4162 54.1497 10.0996 55.0164C9.7829 55.8664 10.2162 56.8331 11.0829 57.1497C11.2662 57.2164 11.4662 57.2497 11.6496 57.2497C12.3329 57.2497 12.9662 56.8497 13.2162 56.1681C13.5329 55.3147 13.0996 54.3481 12.2329 54.0331Z"
        fill="black"
      />
      <path
        d="M21.8006 26.7816C20.8839 26.7166 20.0673 27.3983 20.0006 28.3166C19.9339 29.2333 20.6173 30.0316 21.5339 30.115C21.5839 30.115 21.6339 30.115 21.6673 30.115C22.5339 30.115 23.2673 29.45 23.3339 28.5816C23.4006 27.65 22.7173 26.8666 21.8006 26.7816Z"
        fill="black"
      />
      <path
        d="M2.56696 35.1146C2.85029 35.2979 3.16696 35.3812 3.48362 35.3812C4.01696 35.3812 4.55029 35.1146 4.88362 34.6329H4.86696C5.38362 33.8646 5.16696 32.8146 4.38363 32.3312C3.61696 31.8146 2.58362 32.0479 2.08362 32.8146C1.58362 33.5812 1.80029 34.6162 2.56696 35.1146Z"
        fill="black"
      />
      <path
        d="M18.1004 55.0169C17.1837 55.0335 16.4504 55.8002 16.4671 56.7185C16.4837 57.6352 17.2337 58.3669 18.1504 58.3502C19.0671 58.3502 19.8171 57.5835 19.8004 56.6669C19.7837 55.7502 19.0337 55.0002 18.1004 55.0169Z"
        fill="black"
      />
      <path
        d="M14.9343 26.7999C14.0176 26.8665 13.3343 27.6665 13.401 28.5815C13.4843 29.4649 14.201 30.1149 15.0676 30.1149C15.1176 30.1149 15.151 30.1149 15.201 30.1149C16.1176 30.0499 16.801 29.2482 16.7343 28.3165C16.6676 27.3982 15.851 26.7165 14.9343 26.7999Z"
        fill="black"
      />
      <path
        d="M23.967 53.8834C23.1003 54.2167 22.6837 55.1817 23.017 56.0501C23.2837 56.7001 23.9003 57.1001 24.567 57.1001C24.7837 57.1001 24.9837 57.0667 25.1837 56.9834C26.0337 56.6517 26.467 55.6851 26.117 54.8167C25.7837 53.9667 24.817 53.5501 23.967 53.8834Z"
        fill="black"
      />
      <path
        d="M29.6676 30.3328C30.0009 29.4811 29.5676 28.5144 28.7009 28.1994C28.7009 28.1828 28.7009 28.1828 28.7009 28.1828C27.8343 27.8661 26.8843 28.2978 26.5509 29.1661C26.2343 30.0161 26.6676 30.9828 27.5176 31.3161C27.7176 31.3828 27.9176 31.4161 28.1176 31.4161C28.7843 31.4161 29.4176 30.9994 29.6676 30.3328Z"
        fill="black"
      />
      <path
        d="M34.9831 38.2503C34.0665 38.2503 33.3331 38.9987 33.3331 39.917C33.3331 39.9337 33.3331 39.9337 33.3331 39.9503C33.2998 40.867 34.0165 41.6337 34.9498 41.667C34.9665 41.667 34.9831 41.667 34.9998 41.667C35.8998 41.667 36.6331 40.967 36.6665 40.0503C36.6665 40.0153 36.6665 39.9837 36.6665 39.9337C36.6665 39.917 36.6665 39.917 36.6665 39.8987C36.6665 38.9837 35.9165 38.232 34.9831 38.2503Z"
        fill="black"
      />
      <path
        d="M34.3167 44.8834C33.4667 44.5334 32.5 44.9668 32.15 45.8184C31.8167 46.6684 32.2333 47.6351 33.0833 47.9851C33.2833 48.0684 33.5 48.1001 33.7 48.1001C34.3667 48.1001 35 47.7018 35.25 47.0501C35.6 46.2001 35.1833 45.2168 34.3167 44.8834Z"
        fill="black"
      />
      <path
        d="M28.9178 50.6999C28.2678 51.3333 28.2511 52.3999 28.9011 53.0499C29.2178 53.3833 29.6511 53.5499 30.0845 53.5499C30.5178 53.5499 30.9345 53.3999 31.2511 53.0833C31.9178 52.4333 31.9178 51.3833 31.2845 50.7166C30.6345 50.0666 29.5845 50.0499 28.9178 50.6999Z"
        fill="black"
      />
      <path
        d="M38.3339 7.35656V23.3332H41.6673V7.35656L48.8223 14.5116L51.1789 12.1549L40.0006 0.976562L28.8223 12.1549L31.1789 14.5116L38.3339 7.35656Z"
        fill="black"
      />
      <path
        d="M33.8525 35.5333C37.3058 32.695 42.6958 32.695 46.1491 35.5333C46.4591 35.7883 46.8341 35.9133 47.2058 35.9133C47.6875 35.9133 48.1641 35.7067 48.4941 35.305C49.0791 34.5933 48.9758 33.5433 48.2641 32.96C45.9441 31.0517 43.0091 30 40.0008 30C36.9925 30 34.0575 31.0517 31.7375 32.9583C31.0258 33.5433 30.9241 34.5917 31.5075 35.3033C32.0925 36.0183 33.1425 36.1183 33.8525 35.5333Z"
        fill="black"
      />
    </svg>
  )
}
