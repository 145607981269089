import React, { Fragment } from 'react'
import styled from 'styled-components'
import config from 'config'

const CourtesyPageWrapper = styled.div``

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Logo = styled.div`
  padding: 2rem 0;

  img {
    max-width: 250px;
  }
`

const Separator = styled.div`
  width: 15.909rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.alto}`};
`

const Content = styled.div`
  margin: 3rem 0;
  text-align: center;
  padding: 0 1.5rem;
`

const TextStyled = styled.p`
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.8rem;
`

const COURTESY_PAGE_CONTENT = [
  {
    text: 'Sorry, we are down for a scheduled maintenance right now.',
    thanks: 'Thanks for your patience.',
  },
  {
    text: 'Lo sentimos, estamos fuera de servicio por un mantenimiento programado en este momento.',
    thanks: 'Gracias por su paciencia.',
  },
  {
    text: 'Désolé, nous sommes actuellement en panne pour une maintenance programmée.',
    thanks: 'Merci pour votre patience.',
  },
  {
    text: 'Siamo spiacenti, al momento siamo inattivi per una manutenzione programmata.',
    thanks: 'Grazie per la vostra pazienza.',
  },
]

const CourtesyPage = () => {
  return (
    <CourtesyPageWrapper id="root">
      <Main>
        <Logo>
          <img src={`${config.assets.URL}/luxottica-logo.png`} alt="luxottica-logo" />
        </Logo>
        <Separator />
        {COURTESY_PAGE_CONTENT.map((c, ind) => {
          return (
            <Fragment key={`courtesy-content-${ind}`}>
              <Content>
                <TextStyled>{c.text}</TextStyled>
                <TextStyled>{c.thanks}</TextStyled>
              </Content>
              <Separator />
            </Fragment>
          )
        })}
      </Main>
    </CourtesyPageWrapper>
  )
}

export default CourtesyPage
