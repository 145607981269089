import React from 'react'
import { IconProps } from 'types/icons'
export const FaFaceShapeOvalWomanIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M47.707 36.293C45.736 34.322 45.001 25.867 45 21C45 9.42 35.58 0 24 0C12.42 0 3 9.42 3 21C2.999 25.867 2.264 34.322 0.293 36.293L0 36.586V37C0 43.065 4.935 48 11 48C13.954 48 16.634 46.824 18.612 44.922C20.285 45.619 22.107 46 24 46C25.893 46 27.715 45.619 29.388 44.922C31.366 46.824 34.046 48 37 48C43.065 48 48 43.065 48 37V36.586L47.707 36.293ZM24 44C19.447 44 15.353 41.47 13.317 37.397C12.415 35.592 11.769 33.671 11.397 31.689L9.195 19.948C14.841 19.451 18.935 15.496 20.404 11.81C22.402 14.273 27.227 17.835 38.828 19.821L36.602 31.69C36.23 33.672 35.584 35.593 34.682 37.398C32.647 41.47 28.553 44 24 44ZM37 46C34.836 46 32.848 45.232 31.294 43.954C33.47 42.62 35.275 40.685 36.472 38.292C37.457 36.32 38.163 34.224 38.569 32.059L40.983 19.184L40.156 18.012C22.377 15.205 20.999 8.901 20.99 8.858L19 9C19 12.54 14.276 18 8 18L7.017 19.184L9.431 32.059C9.837 34.224 10.542 36.321 11.528 38.292C12.725 40.685 14.53 42.621 16.706 43.954C15.152 45.232 13.164 46 11 46C6.161 46 2.202 42.162 2.007 37.37C4.914 33.734 5 22.329 5 21C5 10.523 13.523 2 24 2C34.477 2 43 10.523 43 21C43 22.329 43.086 33.734 45.993 37.37C45.798 42.162 41.839 46 37 46Z"
        fill={fill}
      />
      <path d="M26.434 23.057H24.434V31H21.986V33H26.434V23.057Z" fill={fill} />
      <path
        d="M27.312 36.928L24 35.958L20.688 36.928L21.879 38.121C22.445 38.688 23.198 39 24 39C24.802 39 25.555 38.688 26.122 38.121L27.312 36.928Z"
        fill={fill}
      />
    </svg>
  )
}
