import { IconProps } from 'types/icons'
import React from 'react'

export const ArrowUpIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 8"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="button"
      aria-label="topIcon"
    >
      <path
        d="M12.451 6.97696L6.32364 0.849609L0.196289 6.97696L0.561754 7.34242L6.32364 1.58054L12.0855 7.34242L12.451 6.97696Z"
        fill={fill}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </svg>
  )
}
