import Maybe from 'graphql/tsutils/Maybe'
import { toArray } from 'libs/utils'
import { CustomModel } from 'types/custom'
import { Product } from 'types/graphqlTypes'

export const isCustomizedProduct = (type: string): boolean =>
  type === 'OCP' || type === 'REMIX' || type === 'CUSTOMRB'

export const checkIsElectronicsProduct = (product: Product): boolean =>
  product?.originalBrand?.toLowerCase() === 'rw'

export const isOakleyCustomModel = (model?: CustomModel): boolean =>
  model && model.pageUrl.includes('/oo/')
export const getQueryParamsOnlyDefined = (paramsObj: {
  [key: string]: string | undefined
}): string => {
  return paramsObj
    ? Object.keys(paramsObj)
        .filter(key => !!paramsObj[key])
        .map(key => key + '=' + paramsObj[key])
        .join('&')
    : ''
}

export const getProductColorTitle = (product: Product) => {
  const frameColorLabel = product.frameColorLabel || product.frameColor
  const lensColorLabel = product.lensColorLabel || product.lensColor
  return [frameColorLabel, lensColorLabel]
    .filter(Boolean)
    .map(v => v.toLowerCase())
    .join(' / ')
}

export const normalizeModelName = (model: string) => {
  return model.startsWith('0') ? model.substring(1) : model
}

export const isProductAfa = (product: Product) =>
  !['1', '2', 'E', 'I', 'G', 'K'].includes(product.collection)

export const getProductDescriptionValue = (
  filter: Maybe<Array<Maybe<string>> | string>
): string => {
  if (!filter) {
    return ''
  }
  return toArray(filter).filter(Boolean).join(', ')
}
