import { resetBarcodeModalInfo } from 'actions/barcode'
import { BarcodeScanModalOnlyCustom } from 'components/BarCodeScanModal/Modals/BarcodeScanModalOnlyCustom'
import { CustomModelCardProduct } from 'components/CardProduct/CustomModelCardProduct'
import Loading from 'components/Loading'
import {
  PrimaryButtonStyled,
  ProductCounter,
  ViewAllWrapper,
} from 'components/SearchDrawer/SearchProductContent'
import { useProductsByUpcData } from 'data'
import { useActions } from 'hooks/useActions'
import { usePreviousNonNullish } from 'hooks/usePreviousNonNullish'
import { useStoreContext } from 'hooks/useStoreContext'
import { useUrlParams } from 'hooks/useUrlParams'
import { OOMasterBrand, RBMasterBrand } from 'libs/custom'
import { isOakleyCustomModel } from 'libs/product'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { CustomModel } from 'types/custom'

const NUMBER_OF_PREVIEW_ITEMS = 4

const CustomModelsListWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
`

const ModelItem = styled.div`
  padding: 1em;
  flex-basis: 50%;
`

interface CatalogCustomModelsListProps {
  items: CustomModel[]
}

export const CatalogCustomModelsList: React.FC<CatalogCustomModelsListProps> = ({ items }) => {
  return (
    <CustomModelsListWrapper>
      {items.map((item, index) => (
        <ModelItem key={item.modelCode}>
          <CustomModelCardProduct model={item} index={index} />
        </ModelItem>
      ))}
    </CustomModelsListWrapper>
  )
}

interface CatalogCustomModelsProps {
  query?: string
}

export const CatalogCustomModels: React.FC<CatalogCustomModelsProps> = ({ query }) => {
  const { t } = useTranslation()
  const store = useStoreContext()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const history = useHistory()
  const { isOnlyCustom } = useUrlParams()
  const actions = useActions({ resetBarcodeModalInfo })

  const {
    data: catalogCustomModelsData,
    loading: catalogCustomModelsLoading,
  } = useProductsByUpcData(query)

  const prevSimilarStylesData = usePreviousNonNullish(catalogCustomModelsData)
  const existingCatalogCustomModelsData =
    !isEmpty(catalogCustomModelsData) && catalogCustomModelsData
      ? catalogCustomModelsData
      : prevSimilarStylesData

  useEffect(() => {
    if (isOnlyCustom) {
      setIsModalVisible(true)
    }
  }, [isOnlyCustom])
  if (catalogCustomModelsLoading) {
    return <Loading />
  }

  const catalogCustomModelsItems =
    existingCatalogCustomModelsData?.productsByUpc?.searchedCustomModelGroup?.customModels || []
  const slicedCatalogCustomModelsItems = catalogCustomModelsItems.slice(0, NUMBER_OF_PREVIEW_ITEMS)

  const searchedCustomModel =
    existingCatalogCustomModelsData?.productsByUpc.searchedCustomModelGroup.searchedCustomModel
  const isSearchedCustomModelOakley = isOakleyCustomModel(searchedCustomModel)

  const productCount = catalogCustomModelsItems.length ?? 0

  const productDataForModal = {
    originalBrand: isSearchedCustomModelOakley ? OOMasterBrand : RBMasterBrand,
    moco: searchedCustomModel.modelCode,
    model: searchedCustomModel.modelCode,
    UPC: '',
  }

  return (
    <>
      {productCount > 0 ? (
        <>
          <ProductCounter>
            {t(`ProductsListPage.${productCount > 1 ? 'results' : 'result'}`, {
              count: productCount,
            })}
          </ProductCounter>
          <CatalogCustomModelsList items={slicedCatalogCustomModelsItems} />
          <ViewAllWrapper>
            <PrimaryButtonStyled
              data-element-id="Tiles_ViewAll"
              onClick={() => {
                history.replace(history.location.pathname)
                history.push(`${store.url}/custom/${isSearchedCustomModelOakley ? 'oo' : 'rb'}`)
              }}
            >
              {t('Pages.viewAll')}
            </PrimaryButtonStyled>
          </ViewAllWrapper>
          {searchedCustomModel && (
            <BarcodeScanModalOnlyCustom
              visible={isModalVisible}
              onCancel={() => {
                setIsModalVisible(false)
              }}
              onRedirect={(url: string) => {
                history.replace(history.location.pathname)
                history.push(url)
                setIsModalVisible(false)
                actions.resetBarcodeModalInfo()
              }}
              productInfo={productDataForModal}
            />
          )}
        </>
      ) : null}
    </>
  )
}
