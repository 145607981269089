import { isIPadView } from 'libs/utils'
import { NavigationContext } from 'providers/navigationProvider'
import { useContext, useMemo } from 'react'

export const useDeviceType = () => {
  const isIpad = isIPadView()
  const { landscapeNavigation, isAlternativeNavigation } = useContext(NavigationContext)

  const isTowerPortrait = isAlternativeNavigation || (!landscapeNavigation && !isIpad)
  const isIpadlandscape = isIpad && landscapeNavigation
  const isTowerLandscape = !isIpad && (isAlternativeNavigation || landscapeNavigation)

  return useMemo(
    () => ({
      isTowerPortrait,
      isIpad,
      isIpadlandscape,
      isTowerLandscape,
    }),
    [isTowerPortrait, isIpad, isIpadlandscape, isTowerLandscape]
  )
}
