import { Dispatch } from 'react'

export const SET_INITIAL_APP_CONF = 'app/SET_INITIAL_APP_CONF'
export const SET_APP_ERROR = 'app/SET_APP_ERROR'
export const RESET_APP_ERROR = 'app/RESET_APP_ERROR'

export interface SetInitialAppConf {
  type: typeof SET_INITIAL_APP_CONF
  payload: {
    url: string
    token: string
  }
}

export interface SetAppError {
  type: typeof SET_APP_ERROR
  payload: string
}

export interface ResetAppError {
  type: typeof RESET_APP_ERROR
}

export const setInitialAppConf = payload => (dispatch: Dispatch<SetInitialAppConf>) => {
  dispatch({
    type: SET_INITIAL_APP_CONF,
    payload,
  })
}

export const setAppError = payload => (dispatch: Dispatch<SetAppError>) => {
  dispatch({
    type: SET_APP_ERROR,
    payload,
  })
}

export const resetAppError = () => (dispatch: Dispatch<ResetAppError>) => {
  dispatch({
    type: RESET_APP_ERROR,
  })
}

export type AppActionTypes = SetInitialAppConf | SetAppError | ResetAppError
