import { PrimaryButton } from 'components/core/Button'
import { Modal } from 'components/core/Modal'
import { ModalContent } from 'components/core/Modal/styles'
import Loading from 'components/Loading'
import { TermsAndConditionsDataQueryResult, useTermsAndConditionsPageData } from 'data'
import { pxToRem } from 'libs/styled'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ModalHeader = styled.div`
  margin-bottom: 3em;

  h1 {
    font-size: 1.5rem;
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.primaryText};
    font-family: ${({ theme }) => theme.fonts.primary};
    width: fit-content;
  }
`

const TermsAndConditionsContent = styled.div`
  min-height: ${pxToRem(400)};
  color: ${({ theme }) => theme.colors.primaryText};
`

const ModalStyled = styled(Modal)`
  ${ModalContent} {
    max-height: unset;
    overflow: unset;
  }
`

const Footer = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: ${pxToRem(32)} ${pxToRem(310)};
  > button {
    min-width: ${pxToRem(410)};
  }
`

interface TermsAndConditionsModalProps {
  className?: string
  visible: boolean
  onCancel: (event: React.MouseEvent<Element, MouseEvent>) => void
}

export const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = ({
  className,
  visible,
  onCancel,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [loadData, { data }] = useTermsAndConditionsPageData<TermsAndConditionsDataQueryResult>(
    'PageGeneric'
  )
  const { t } = useTranslation()
  const content = data?.page?.content[0]

  useEffect(() => {
    if (visible) {
      loadData()
    }
  }, [loadData, visible])

  useEffect(() => {
    if (data?.page?.content) {
      setIsLoading(false)
    }
  }, [data])

  return (
    <ModalStyled
      className="terms-and-conditions"
      width="100%"
      visible={visible}
      onCancel={onCancel}
      removeCloseBtn
      renderHeader={() => (
        <ModalHeader>
          <h1 className="link-to-modal__modal-header">{t('Core.termsAndConditionsLabel')}</h1>
        </ModalHeader>
      )}
    >
      {isLoading && <Loading />}
      <TermsAndConditionsContent
        className={className}
        dangerouslySetInnerHTML={{ __html: content?.html || '' }}
      />
      <Footer>
        <PrimaryButton onClick={onCancel}>{t('Core.close')}</PrimaryButton>
      </Footer>
    </ModalStyled>
  )
}
