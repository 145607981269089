import { MenuItem } from 'components/MainMenu/MenuContent'
import { convertToCamelCaseAndStripSpecials } from 'libs/utils'
import { Brand } from 'types/brand'
import * as graphqlTypes from 'types/graphqlTypes'
import { getSubBrandsStringValues } from './brand'

export type QueryParams = { [key: string]: string | undefined }

export function createMenuContent(
  menu: graphqlTypes.Menu,
  brands: Brand[],
  baseUrl: string
): MenuItem[] {
  const menuGroup = menu.items.map<MenuItem>(menuItem => {
    if (!menuItem?.submenu) {
      return {
        label: menuItem.text || '',
        href: menuItem.link && menuItem.link.url,
        analyticsData: menuItem.analyticsData || menuItem.link?.analyticsData,
        firstLevel: menuItem.link.firstLevel,
        secondLevel: menuItem.link.secondLevel,
      }
    }

    switch (menuItem.submenu.__typename) {
      case 'Menu': {
        return {
          label: menuItem.text || '',
          items: createMenuContent(menuItem.submenu, brands, baseUrl),
          analyticsData: menuItem.analyticsData,
        }
      }
      default:
        return {
          label: menuItem.text || '',
          items: brands.map(brand => ({
            label: brand.translatedLabel || brand.code,
            href: `${baseUrl}/products?brand=${getSubBrandsStringValues(brand)}${
              brand.code === 'aw' ? '&collection=P' : ''
            }`,
            analyticsData: {
              dataElementId: 'MainNav_Menu',
              dataDescription:
                (`${menuItem.analyticsData?.dataDescription}_` || '') +
                convertToCamelCaseAndStripSpecials(brand.code, true),
            },
          })),
          analyticsData: menuItem.analyticsData,
        }
    }
  })

  return menuGroup
}
