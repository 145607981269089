import React from 'react'
import { IconProps } from 'types/icons'

export const HingeToHingeIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      preserveAspectRatio="xMidYMid slice"
    >
      <g preserveAspectRatio="xMidYMid slice">
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M46.07 36.74a13.92 13.92 0 0 0 8.22 3.34c4.75.13 26.11-4.94 37.61-6.68 11.5-1.74 33.33-4.87 41.09-6 7.76-1.13 21.81-3.54 26.57-5.14 4.76-1.6 8.48-3.08 8.48-3.08"
        />
        <path fill={fill} d="M21.42 47.42a5.24 5.24 0 1 1 5.23 5.23h-6.33" />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M21.42 47.42a5.24 5.24 0 1 1 5.23 5.23h-6.33"
        />
        <path fill={fill} d="M19.73 45.75a5.24 5.24 0 1 1 5.23 5.24H19.1" />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M19.73 45.75a5.24 5.24 0 1 1 5.23 5.24H19.1"
        />
        <path fill={fill} d="M18.1 44.12a5.05 5.05 0 1 1 5 5.05h-5.79" />
        <path stroke={stroke} strokeMiterlimit="2" d="M18.1 44.12a5.05 5.05 0 1 1 5 5.05h-5.79" />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M23.14 46.59a2.61 2.61 0 1 0 0-5.22 2.61 2.61 0 0 0 0 5.22ZM30.17 43.7c1.04.048 2.069.25 3.05.6 3.48 1.41 2.66 1.89 6.8 2.38a45.34 45.34 0 0 0 11.34-.65M9.32 33.16S2.5 137.75 1.09 171.06c-1.41 33.31 0 78.64.7 89.06 1.37 21.57 3.51 40 8.77 62.41 5.26 22.41 19 52.48 26.3 65.21 6.21 10.87 20.08 30.76 21.93 33.35 1 1.36 5.6 3 7.61 2a5.798 5.798 0 0 0 3.55-5.63c-.21-3.14-13.06-29.56-17.74-36.11-4.33-6.06-21.44-27.63-30.43-57.38-10.31-34.15-9.25-80.12-5.54-153.26 3.24-63.85 4.11-106.42 4.26-114.1"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M18.12 43.82c-.58 6.18-.86 11-.93 13.2-.12 4.33-.18 6.38 1 7.09s2.14-.9 2.16-2c.05-4.76.24-9.58.24-9.58M22.05 39.14s10.15-1.6 12.37-1.92c3.8-.47 5.83 1.31 3.8 2.85-1.55 1.17-4.52.9-9.33 2"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M15.42 37.71S5.7 156.19 5.21 193.98c-.49 37.79.57 57.31 1.74 76.19 1.86 30.19 7.85 58.38 16.46 78.76a368.52 368.52 0 0 0 27.45 52.61c4.4 7 15.56 21.5 15.56 21.5M164.42 26.36s-.31 7.17 6.12 8.62c6.43 1.45 12.89-1.89 16.59-6.08 1.81-2.05 4.07-5.55 4.38-9"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M180.73 20.54a4.002 4.002 0 0 1 1.16 3.09 12.538 12.538 0 0 1-3.35 7.87c-.88 1-4.33 3.16-7.86 2.13a8.254 8.254 0 0 1-5.81-7.16M17.93 49.15l2.91 3.89"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M224.1 13.45c4.05 1.5 8.71 4.56 28.45 3.07a301.007 301.007 0 0 1 47.68-.12c13.49.92 38.39 6.08 47.92 8.48 18.37 4.62 58 13.07 58 13.07l6.28-4.87v-4.25l-26.33-6.71c-10.29-2.62-24.54-6.11-40.47-10-18.39-4.53-45.34-8.59-65.46-8.22-20.12.37-44.58 1-50.3.38-5.72-.62-9.79-3.41-19-3.23-9.21-.18-13.29 2.61-19 3.23-5.71.62-30.18 0-50.3-.38-20.12-.38-47.07 3.69-65.45 8.22-15.94 3.93-30.19 7.42-40.48 10L9.31 28.83v4.25l6.28 4.87s39.64-8.45 58-13.07c9.54-2.4 34.44-7.56 47.93-8.48a300.821 300.821 0 0 1 47.67.12c19.74 1.49 24.4-1.57 28.45-3.07a42.071 42.071 0 0 1 13.06-2.41c4.565.098 9.087.911 13.4 2.41Z"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M235.09 19.59s7.42.72 12.34 3c7.054 2.85 14.262 5.3 21.59 7.34 3.78.89 22.36 4 28.93 4.78 6.57.78 17 2 34.38 5.46 17.38 3.46 33.46 5.61 37.61 5.78 4.15.17 10.13-3.78 10.91-6 .78-2.22-4.51-3.16-9.24-5-4.73-1.84-31.51-8.87-46-11.8-20.74-4.2-36.48-4.21-46.15-4.56-9.12-.34-23.64.42-36.52.77-6.57.17-11.74.36-13.24.45-4.45.29-5-.65-9.24-2.67a24.61 24.61 0 0 0-9.64-2 24.927 24.927 0 0 0-9.81 2c-4.23 2-4.79 3-9.24 2.68-1.5-.1-6.67-.28-13.24-.46-12.88-.35-27.4-1.11-36.52-.77-9.67.35-25.41.36-46.15 4.56-14.45 2.93-41.23 9.95-46 11.8-4.77 1.85-10 2.78-9.23 5 .77 2.22 6.75 6.18 10.9 6 4.15-.18 20.23-2.36 37.61-5.78 17.38-3.42 27.82-4.68 34.38-5.45 6.56-.77 25.15-3.9 28.94-4.79a203.48 203.48 0 0 0 21.58-7.34c4.92-2.32 12.34-3 12.34-3"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M375.48 36.75a13.88 13.88 0 0 1-8.21 3.34c-4.76.13-26.12-4.94-37.62-6.68-11.5-1.74-33.33-4.87-41.08-6s-21.82-3.54-26.58-5.14c-4.76-1.6-8.47-3.08-8.47-3.08"
        />
        <path
          fill={fill}
          d="M400.18 47.43a5.236 5.236 0 0 0-3.228-4.849 5.239 5.239 0 0 0-4.924 9.195 5.24 5.24 0 0 0 2.912.884h6.29"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M400.18 47.43a5.236 5.236 0 0 0-3.228-4.849 5.239 5.239 0 0 0-4.924 9.195 5.24 5.24 0 0 0 2.912.884h6.29"
        />
        <path
          fill={fill}
          d="M401.83 45.76a5.239 5.239 0 0 0-10.379-1.023A5.238 5.238 0 0 0 396.59 51h5.83"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M401.83 45.76a5.239 5.239 0 0 0-10.379-1.023A5.238 5.238 0 0 0 396.59 51h5.83"
        />
        <path
          fill={fill}
          d="M403.42 44.13a4.996 4.996 0 0 0-3.121-4.584 5 5 0 1 0-1.879 9.634h5.79"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M403.42 44.13a4.996 4.996 0 0 0-3.121-4.584 5 5 0 1 0-1.879 9.634h5.79"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M398.41 46.6a2.61 2.61 0 1 0 0-5.22 2.61 2.61 0 0 0 0 5.22ZM391.42 43.71a10.447 10.447 0 0 0-3 .6c-3.48 1.4-2.67 1.88-6.81 2.38-3.792.26-7.602.041-11.34-.65M412.24 33.17s6.82 104.59 8.22 137.9c1.4 33.31 0 78.64-.7 89.06-1.37 21.57-3.51 40-8.77 62.41-5.26 22.41-19 52.48-26.29 65.21-6.22 10.87-20.09 30.76-21.94 33.35-1 1.35-5.6 3-7.61 2a5.842 5.842 0 0 1-3.55-5.64c.21-3.13 13.06-29.55 17.74-36.1 4.33-6.06 21.44-27.63 30.43-57.39 10.31-34.14 9.25-80.11 5.54-153.25-3.23-63.85-4.11-106.42-4.26-114.1"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M403.42 43.82c.58 6.19.86 11 .93 13.21.13 4.33.18 6.37-1 7.09-1.18.72-2.15-.9-2.16-2-.06-4.77-.25-9.58-.25-9.58M399.5 39.15s-10.15-1.65-12.34-1.92c-3.79-.47-5.83 1.31-3.8 2.85 1.55 1.17 4.52.9 9.33 2"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M406.09 37.72s9.72 118.48 10.22 156.27c.5 37.79-.58 57.31-1.75 76.19-1.86 30.19-7.85 58.38-16.46 78.76a368.114 368.114 0 0 1-27.41 52.6c-4.39 7.07-15.54 21.53-15.54 21.53M257.15 26.37s.31 7.16-6.12 8.62c-6.43 1.46-12.89-1.89-16.58-6.08-1.82-2.06-4.08-5.55-4.39-9"
        />
        <path
          stroke={stroke}
          strokeMiterlimit="2"
          d="M240.82 20.54a3.999 3.999 0 0 0-1.16 3.09 12.543 12.543 0 0 0 3.35 7.87c.88 1 4.33 3.16 7.87 2.13a8.258 8.258 0 0 0 5.8-7.16M403.62 49.16l-2.91 3.89"
        />
      </g>
    </svg>
  )
}
