import {
  CardBodyColumn,
  CardProductBody,
  CardProductContainerStyled,
  CardProductLinkStyled,
  ProductBrand,
  ProductModel,
} from 'components/CardProductBase/ProductCard.style'
import { StyledImg } from 'components/CardProductBase/ProductImage/ProductImage.style'
import { isOakleyCustomModel, normalizeModelName } from 'libs/product'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CustomModel } from 'types/custom'
import { ProductPreview } from '../CardProductBase/ProductPreview'

const StyledProductModel = styled(ProductModel)`
  text-transform: uppercase;
`

interface CatalogCustomModelCardProps {
  model: CustomModel
  index: number
}

export const CustomModelCardProduct: React.FC<CatalogCustomModelCardProps> = ({ model, index }) => {
  const { t } = useTranslation()
  const productCardId = `productCard_${model.standardModelCode}`
  const { label, standardModelCode, pageUrl, thumbnailUrl } = model
  const brandTitle = isOakleyCustomModel(model) ? t('Brand.OO') : t('Brand.RB')
  return (
    <>
      <CardProductContainerStyled>
        <CardProductLinkStyled
          to={pageUrl}
          aria-labelledby={productCardId}
          data-element-id={`MainNav_SearchTiles_${index}-Img`}
        />
        <ProductPreview>
          <StyledImg alt={label || ''} src={thumbnailUrl} />
        </ProductPreview>
      </CardProductContainerStyled>
      <CardProductBody>
        <CardBodyColumn>
          <ProductBrand>{brandTitle}</ProductBrand>
          <StyledProductModel highlight={false}>
            {normalizeModelName(standardModelCode)} {label}
          </StyledProductModel>
        </CardBodyColumn>
      </CardProductBody>
    </>
  )
}
