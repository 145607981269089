import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'
import { useEffect } from 'react'

export const useSendBarcodePolirizedAnalyticsEvent = (shouldSendEvent: boolean) => {
  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    Page_Section2: 'Barcode:Polarized',
  })

  const sendCloseModalEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: 'BarcodeResultPolarized_Close',
  })

  const sendDiscoverEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: 'BarcodeResultPolarized_Discover',
  })

  useEffect(() => {
    if (shouldSendEvent) {
      sendImpressionEvent()
    }
  }, [shouldSendEvent, sendImpressionEvent])

  return {
    sendCloseModalEvent,
    sendDiscoverEvent,
  }
}
