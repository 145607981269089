import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const CloseIconContainer = styled.button`
  position: absolute;
  top: 1.364rem;
  right: 1.364rem;
  display: block;
  width: 1.45rem;
  height: 1.45rem;
  z-index: 2;
`
export const ModalContent = styled.div`
  max-height: 85vw;
  overflow: auto;
`

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${pxToRem(104)} ${pxToRem(60)};
  width: ${pxToRem(900)};
  height: 100%;
  ${tablet(
    `
  padding: ${clampValues(78, 104)} ${clampValues(42, 60)};
  width: ${clampValues(660, 832)};
`
  )}
  ${landscape(
    Device.tablet,
    `
  padding: ${clampValuesLandscape(78, 104)} ${clampValuesLandscape(42, 60)};
  width: ${clampValuesLandscape(660, 832)};
`
  )}
`

export const ModalHeader = styled.div<{ centeredTitle?: boolean }>`
  display: flex;
  justify-content: ${({ centeredTitle }) => (centeredTitle ? 'center' : 'flex-start')};
  text-align: ${({ centeredTitle }) => (centeredTitle ? 'center' : 'left')};
  position: static;
  padding-bottom: ${pxToRem(40)};
  ${landscape(`
    padding-bottom: ${pxToRem(43)};
  `)}
  ${tablet(`
    padding-bottom: ${clampValues(30, 40)} ;
  `)}
  ${landscape(
    Device.tablet,
    `
    padding-bottom: ${clampValuesLandscape(30, 40)} ;
    `
  )}
`

export const ModalFooter = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  gap: ${pxToRem(48)};
  margin-top: ${pxToRem(48)};
  ${tablet(`
    margin-top: ${pxToRem(0)};
  `)}
`
