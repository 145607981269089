import React, { FC } from 'react'
import { IconProps } from 'types/icons'

const PenWithLine: FC<IconProps> = ({ width = '1rem', height = '1rem' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_42257_12780)">
      <path d="M16 15.3333H0V16H16V15.3333Z" fill="black" />
      <path
        d="M1.998 14C2.04167 14 2.08633 13.9957 2.13133 13.9867L4.469 13.373C4.598 13.3473 4.71667 13.2837 4.80967 13.1907L15.138 2.862C15.3983 2.60167 15.3983 2.17967 15.138 1.91933L13.414 0.195333C13.284 0.065 13.1133 0 12.9427 0C12.772 0 12.6013 0.065 12.4713 0.195333L2.14267 10.524C2.04967 10.617 1.986 10.7357 1.96033 10.8647L1.34667 13.2023C1.26233 13.6237 1.59 14 1.998 14ZM2.61033 11.015L2.61433 10.9957L12.9423 0.666667H12.943L14.6667 2.39067L4.338 12.7193L4.31867 12.7233L2.00233 13.3317L2.61033 11.015Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_42257_12780">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default PenWithLine
