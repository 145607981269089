import { updateBiometricData } from 'actions/frameAdvisor'
import ErrorWidget from 'components/ErrorWidget'
import Loading from 'components/Loading'
import RotateDevice from 'components/RotateDeviceMessage'
import { Heading1, Text } from 'components/Typography'
import BaseContainer from 'containers/BaseContainer'
import { useFrameAdvisorBipaConsents } from 'data'
import { parseGrapqhQLError } from 'libs/errors'
import { omit } from 'lodash'
import { NavigationContext } from 'providers/navigationProvider'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useStoreIndentity } from '../../../hooks/useStoreIdentity'
import { PrimaryButton, SecondaryButton } from '../../core/Button'
import { BiometricForm, BiometricFormData } from './BiometricForm'
import { BiometricDataConsentContainer, ButtonsContainer, Title } from './styles'

export const BiometricDataConsent: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { basePath } = useStoreIndentity()
  const dispatch = useDispatch()
  const { biometricData } = useSelector(state => state.frameAdvisor)
  const [isBiometricFormValid, setIsBiometricFormValid] = useState<boolean>(false)
  const [setBipaConsents] = useFrameAdvisorBipaConsents()
  const [isSendingDataLoading, setIsSendingDataLoading] = useState(false)
  const { landscapeNavigation } = useContext(NavigationContext)
  const [error, setError] = useState()

  const onConfirm = useCallback(async () => {
    try {
      setIsSendingDataLoading(true)
      await setBipaConsents({
        variables: {
          data: omit(biometricData, ['isIllinoisResident']) as BiometricFormData,
        },
      })
      history.push(`${basePath}/frame-advisor/fsa/scanning`, { fromBiometricDataConsent: true })
    } catch (err) {
      setIsSendingDataLoading(false)
      setError(err)
    }
  }, [basePath, biometricData, history, setBipaConsents])
  const onDecline = useCallback(() => {
    history.goBack()
  }, [history])

  if (landscapeNavigation) {
    return <RotateDevice showCloseBtn to="portrait" onCancel={() => history.go(-1)} />
  }

  if (isSendingDataLoading) {
    return <Loading />
  }

  if (error) {
    return <ErrorWidget {...parseGrapqhQLError(error)} withWrapper />
  }

  return (
    <BaseContainer
      className="biometric-data-consent-page"
      showPrivacyPolicyTooltip={false}
      showMenu={landscapeNavigation ? false : true}
      showHeader={false}
      withPadding={false}
      floatingHeader={false}
      content={
        <BiometricDataConsentContainer>
          <Title>
            <Heading1 id="biometricDataConsent">
              {t('FrameAdvisor.biometricDataConsent.title')}
            </Heading1>
            <Text>{t('FrameAdvisor.biometricDataConsent.subtitle')}</Text>
          </Title>
          <BiometricForm
            data={biometricData}
            onChange={data => {
              dispatch(updateBiometricData(data))
            }}
            onComplete={isValid => {
              setIsBiometricFormValid(isValid)
            }}
          />
          <ButtonsContainer>
            <SecondaryButton onClick={onDecline}>
              {t('FrameAdvisor.biometricDataConsent.decline')}
            </SecondaryButton>
            <PrimaryButton disabled={!isBiometricFormValid} onClick={onConfirm}>
              {t('FrameAdvisor.biometricDataConsent.confirm')}
            </PrimaryButton>
          </ButtonsContainer>
        </BiometricDataConsentContainer>
      }
    />
  )
}
