import { BooleanResolver } from 'hooks/useQueryParams'
import { OOThumbnailSide, ThumbnailSide } from 'types/custom'
import { OOLocalizedConfiguration, RBLocalizedConfiguration } from 'types/customRecipe'
import { ImageFormat } from 'types/image'
import { IBlobData, IProductAttribute } from 'types/oneConfigurator'
import { CustomitemEngraving, CustomSide } from 'types/product'
import { getSiteAbsolutePath } from './url'

const hybrisLanguageCountryMatrixForRB = [
  { region: 'at', defaultLanguage: 'de', languages: ['de'] },
  { region: 'be', defaultLanguage: 'fr', languages: ['fr, nl'] },
  { region: 'ch', defaultLanguage: 'de', languages: ['de', 'fr', 'en', 'it'] },
  { region: 'de', defaultLanguage: 'de', languages: ['de'] },
  { region: 'dk', defaultLanguage: 'en', languages: ['en'] },
  { region: 'es', defaultLanguage: 'es', languages: ['es'] },
  { region: 'fi', defaultLanguage: 'en', languages: ['en'] },
  { region: 'fr', defaultLanguage: 'fr', languages: ['fr'] },
  { region: 'gb', defaultLanguage: 'en', languages: ['en'] },
  { region: 'ie', defaultLanguage: 'en', languages: ['en'] },
  { region: 'it', defaultLanguage: 'it', languages: ['it'] },
  { region: 'nl', defaultLanguage: 'en', languages: ['en'] },
  { region: 'no', defaultLanguage: 'en', languages: ['en'] },
  { region: 'pl', defaultLanguage: 'pl', languages: ['pl'] },
  { region: 'pt', defaultLanguage: 'en', languages: ['pt'] },
  { region: 'se', defaultLanguage: 'en', languages: ['en'] },
  { region: 'tr', defaultLanguage: 'tr', languages: ['tr'] },
  { region: 'mx', defaultLanguage: 'es', languages: ['es'] },
  { region: 'us', defaultLanguage: 'en', languages: ['en'] },
  { region: 'ca', defaultLanguage: 'en', languages: ['en', 'fr'] },
  { region: 'br', defaultLanguage: 'pt', languages: ['pt'] },
  { region: 'au', defaultLanguage: 'en', languages: ['en'] },
  { region: 'kr', defaultLanguage: 'ko', languages: ['ko'] },
  { region: 'hu', defaultLanguage: 'hu', languages: ['hu'] },
  { region: 'cn', defaultLanguage: 'zh', languages: ['zh'] },
  { region: 'hk', defaultLanguage: 'en', languages: ['en', 'zh'] },
  { region: 'jp', defaultLanguage: 'ja', languages: ['ja'] },
]

export const RBMasterBrand = 'rb'
export const OOMasterBrand = 'oo'

const subBrandsRB = [RBMasterBrand, 'rx', 'ry', 'rj']
const subBrandsOO = [OOMasterBrand, 'ox', 'oy', 'oj']

export const isRBBrand = (brand: string) => subBrandsRB.includes(brand.toLowerCase())
export const isOOBrand = (brand: string) => subBrandsOO.includes(brand.toLowerCase())
export const isMultiOOBrand = (brands: string[]) =>
  brands.some(brand => subBrandsOO.includes(brand.toLowerCase()))
export const isMultiRBBrand = (brands: string[]) =>
  brands.some(brand => subBrandsRB.includes(brand.toLowerCase()))

export const setReturnUrl = (prevPage: string): void => {
  const storeBaseUrl = getSiteAbsolutePath()
  const partialUrl = prevPage.replace(storeBaseUrl.replace(/\/$/, ''), '') || '/'
  window.localStorage.setItem('custom-product-return-url', partialUrl)
}

export const getReturnUrl = (storeUrl: string): string => {
  const partialUrl = window.localStorage.getItem('custom-product-return-url') || '/'
  return storeUrl.replace(/\/$/, '') + partialUrl
}

export const getThumbnailUrl = (
  recipeId: number,
  scale = 5,
  side: ThumbnailSide = OOThumbnailSide.Left,
  url = 'https://configure-imagecomposer.fluidretail.net',
  backgroundColor = '',
  format: ImageFormat = 'jpg'
): string => {
  let thumbnailUrl = `${url}/recipe/${recipeId}/image/${side}.${format}?scale=.${scale}`
  if (backgroundColor) {
    const postCompose = `[{"background":"${backgroundColor}"}]`
    thumbnailUrl = `${thumbnailUrl}&postCompose=${encodeURIComponent(postCompose)}`
  }
  return thumbnailUrl
}

const getSupportedHybrisLanguageCountry = (language: string, country: string): string => {
  const targetCountry = country.toLowerCase()
  const selectedRegion = hybrisLanguageCountryMatrixForRB.find(
    region => region.region === targetCountry
  )
  if (!selectedRegion) return 'en_US'
  const targetLanguage = language.toLowerCase()
  const selectedLanguage =
    selectedRegion.languages.find(regionLanguage => regionLanguage === targetLanguage) ||
    selectedRegion.defaultLanguage
  return `${selectedLanguage}_${selectedRegion.region.toUpperCase()}`
}

export const getOCLangFromSelectedLang = (langCode: string): string => {
  const [locale, country] = langCode.split('-')
  return getSupportedHybrisLanguageCountry(locale, country)
}

export const cleanupOneConfiguratorRecipes = (): void => {
  // rayRecipe and oakRecipe
  Object.keys(localStorage)
    .filter(k => k.startsWith('rayRecipe') || k.startsWith('oakRecipe'))
    .map(k => {
      localStorage.removeItem(k)
    })
}

export const getEngravingFromOneConfAttributesForOO = (
  localizedConfiguration: OOLocalizedConfiguration
): CustomitemEngraving => {
  const {
    font,
    ocp_etching_text,
    text_etch_color,
    ocp_microbag,
    etch_or_logo,
  } = localizedConfiguration

  return {
    caseType: 'Engraving.CaseEngraving.standard',
    caseColor: ocp_microbag,
    sides: ocp_etching_text
      ? [{ title: 'Engraving.lens', value: ocp_etching_text.toString() }]
      : [],
    color: text_etch_color || '',
    font: font ? font.toString() : '',
    logo: etch_or_logo ? etch_or_logo.toString() : '',
  }
}

/**
 *
 * @param {IBlobData} data data come from one configurator addToCard callback
 * @return {IProductAttribute} partialProduct products built bases on data attributes.
 */
export const getLocalizedConfigurationFromBlob = (data: IBlobData): IProductAttribute => {
  const conf = data.localized_configuration
  const { size, polarized } = conf
  let lensColor,
    lensColor2,
    frameColor,
    jawColor,
    templeLongColor,
    stemsColor,
    iconColor,
    iconColor2,
    templeTipsColor,
    frameFrontColor,
    clipsColor

  if ('ocp_lens_ref' in conf) {
    lensColor = conf.ocp_lens_ref
    lensColor2 = conf.ocp_lens_ref_2
    frameColor = conf.ocp_frame_ref
    jawColor = conf.ocp_jaw
    templeLongColor = conf.ocp_temple_long_ref
    stemsColor = conf.ocp_stems
    iconColor = conf.ocp_icon
    iconColor2 = conf.ocp_icon_2
    clipsColor = conf.ocp_clip
  } else {
    lensColor = conf.lenses_sku
    frameColor = conf.frame_sku
    templeTipsColor = conf.temple_tips_sku
    frameFrontColor = conf.metal_details_render
  }

  const product: IProductAttribute = {
    lensColor,
    lensColor2,
    frameColor,
    jawColor,
    templeLongColor,
    stemsColor,
    iconColor,
    iconColor2,
    clipsColor,
    size,
    polarized: BooleanResolver(polarized),
    templeTipsColor,
    frameFrontColor,
  }

  return product
}

export const getFrameEngravingInfo = (
  localizedConfiguration: RBLocalizedConfiguration
): CustomSide[] => {
  const frameEngravingStandard = [
    { title: 'ir', value: 'internal_right' },
    { title: 'il', value: 'internal_left' },
    { title: 'el', value: 'external_left' },
    { title: 'er', value: 'external_right' },
  ]
  const frameEngravingPremium = [
    { title: 'ir', value: 'art_engraving_internal_right' },
    { title: 'il', value: 'art_engraving_internal_left' },
    { title: 'el', value: 'art_engraving_external_left' },
    { title: 'er', value: 'art_engraving_external_right' },
  ]

  const frameEngraving =
    localizedConfiguration.engraving_type === 'Premium'
      ? frameEngravingPremium
      : frameEngravingStandard

  const sides = frameEngraving.reduce((sidesMap: { title: string; value: string }[], sideObj) => {
    const key = sideObj.value as keyof RBLocalizedConfiguration
    const value = localizedConfiguration[key] as string
    if (value?.length) {
      sidesMap.push({ title: `Engraving.${sideObj.title}`, value })
    }
    return sidesMap
  }, [])

  return sides
}

export const getEngravingFromOneConfAttributes = (
  localizedConfiguration: RBLocalizedConfiguration
): CustomitemEngraving => {
  const {
    standard_engraving_font_render_color,
    choose_font_color_engraving,
    choose_your_font_engraving,
    choose_your_font_case_engraving,
    engraving_type,
    case_engraving_line_1,
    case_engraving_line_2,
    case_sku,
    case_category = 'standard',
    temple_tips_sku,
  } = localizedConfiguration

  const sides = getFrameEngravingInfo(localizedConfiguration)
  const caseEngraving = [case_engraving_line_1, case_engraving_line_2].filter(Boolean).join(', ')
  const caseType = `Engraving.CaseEngraving.${case_category.toLowerCase()}`
  const color =
    typeof choose_font_color_engraving === 'string'
      ? choose_font_color_engraving
      : standard_engraving_font_render_color

  return {
    color,
    font: choose_your_font_engraving,
    sides,
    frameEngravingType: engraving_type,
    templetipColor: temple_tips_sku,
    caseType,
    caseEngraving,
    caseColor: case_sku,
    caseFont: choose_your_font_case_engraving,
  }
}
