import styled from 'styled-components'
import { flexGapPolyfill, pxToRem } from '../../libs/styled'

export const RotateDeviceMessage = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 90vh;
  place-items: center;
`

export const RotateDeviceMessageInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: ${pxToRem(32)};
  gap: ${pxToRem(32)};
  ${flexGapPolyfill(pxToRem(16), 'column')}
`
