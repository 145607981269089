import { useApplyFilters } from 'hooks/useFilters'
import { pxToRem } from 'libs/styled'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ActiveFilters } from 'types/analytics'
import { ProductTypeValues } from 'types/filter'

export const Toggler = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.818rem;
  border-radius: 1.818rem;
  white-space: nowrap;
  margin-right: ${pxToRem(-20)};
`

export const ButtonStyled = styled.button<{ active?: boolean }>`
  z-index: ${({ active }) => (active ? 1 : 0)};
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1.818rem;
  outline: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 0.727rem;
  font-weight: 600;
  color: ${({ theme, active }) => (active ? theme.cta.textColor : theme.cta.secondaryTextColor)};
  line-height: 1;
  text-transform: uppercase;
  background-color: ${({ theme, active }) =>
    active ? theme.cta.backgroundColor : theme.cta.secondaryBackgroundColor};

  &:first-child {
    left: 0.9rem;
    padding: 0.7rem 2rem 0.7rem 1rem;
    border: 1px solid ${({ theme }) => theme.cta.backgroundColor};
  }

  &:last-child {
    right: 0.9rem;
    padding: 0.7rem 1rem 0.7rem 2rem;
    border: 1px solid ${({ theme }) => theme.cta.backgroundColor};
  }
`

interface ProductTypeToggler {
  className?: string
  filterValue: string | undefined
  preselectedFilters?: ActiveFilters[]
  dataElementId?: string
  onChange?: (value: ProductTypeValues) => void
  scrollToTop?: () => void
}

export const ProductTypeToggler: React.FC<ProductTypeToggler> = ({
  filterValue,
  dataElementId = 'Top_Toggle',
  preselectedFilters = [],
  scrollToTop,
  onChange,
}) => {
  const { t } = useTranslation()
  const toggleSunProduct = useApplyFilters([
    ...preselectedFilters,
    { kind: 'collection', value: ProductTypeValues.SUN },
  ])
  const toggleOpticalProduct = useApplyFilters([
    ...preselectedFilters,
    { kind: 'collection', value: ProductTypeValues.OPTICAL },
  ])

  // TODO: Why put the responsibility on the toggle to scroll the content of the page?
  // That task would be up to the page itself. I believe it was put in to solve the m4c-4580 ticket.
  useEffect(() => {
    scrollToTop && scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue])

  return (
    <Toggler>
      <ButtonStyled
        disabled={filterValue === ProductTypeValues.SUN}
        data-element-id={dataElementId}
        data-description="Sunglasses"
        onClick={onChange ? () => onChange(ProductTypeValues.SUN) : toggleSunProduct}
        active={filterValue === ProductTypeValues.SUN}
      >
        {t('PageTitle.sun')}
      </ButtonStyled>
      <ButtonStyled
        disabled={filterValue === ProductTypeValues.OPTICAL}
        data-element-id={dataElementId}
        data-description="Eyeglasses"
        onClick={onChange ? () => onChange(ProductTypeValues.OPTICAL) : toggleOpticalProduct}
        active={filterValue === ProductTypeValues.OPTICAL}
      >
        {t('PageTitle.optical')}
      </ButtonStyled>
    </Toggler>
  )
}
