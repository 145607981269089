import React from 'react'
import { IconProps } from 'types/icons'
export const FaFaceShapeRoundUngenderedIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 46"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M43.3129 15.9875C43.1209 7.99547 36.1949 2.55047 29.8409 0.873472C23.8829 -0.700528 18.5629 0.756472 15.6849 4.67047C12.5179 3.02647 9.13595 3.09947 6.27795 4.90647C1.88595 7.68347 -0.442052 13.8795 0.341948 20.6295C0.357948 20.9375 0.785949 28.1935 4.70295 31.5775C4.88795 31.7365 5.08695 31.8595 5.28095 31.9965C6.70195 39.9435 13.6489 46.0005 21.9999 46.0005C30.7119 46.0005 37.9049 39.4105 38.8789 30.9545C40.7279 29.3485 43.5399 25.3555 43.3129 15.9875ZM2.33395 20.4605C1.63295 14.4065 3.60095 8.96447 7.34695 6.59647C9.82295 5.03247 12.7059 5.12147 15.4699 6.84847L16.8569 6.51547C19.5309 2.05847 25.0529 1.67747 29.3299 2.80747C34.9839 4.30047 41.1459 9.07747 41.3139 16.0365C41.4619 22.1995 40.2329 25.7265 38.9999 27.7045V19.0005L38.1559 18.0125C20.3769 15.2055 18.9989 8.90147 18.9899 8.85847L16.9999 9.00047C16.9999 12.5405 12.2759 18.0005 5.99995 18.0005L4.99995 19.0005V28.9515C2.65895 25.7955 2.34195 20.5745 2.33395 20.4605ZM21.9999 44.0005C13.7289 44.0005 6.99995 37.2715 6.99995 29.0005V19.9635C12.7459 19.5425 16.9179 15.5385 18.4049 11.8095C20.4119 14.2845 25.2759 17.8695 36.9999 19.8505V29.0005C36.9999 37.2715 30.2709 44.0005 21.9999 44.0005Z"
        fill={fill}
      />
      <path d="M24.0129 36.0005H19.9859V38.0005H24.0129V36.0005Z" fill={fill} />
      <path d="M22.4339 31.0005H19.9859V33.0005H24.4339V23.0575H22.4339V31.0005Z" fill={fill} />
    </svg>
  )
}
