import {
  FrameAdvisorActionTypes,
  SAVE_FSA_INFO,
  SET_ON_CAPTURE_PROCESS,
  SET_SENTENCE_MAP,
  TOGGLE_WISHLIST_DRESSED_ON,
  UPDATE_FLAG_AGE_DECLARATION,
  UPDATE_VIEW_MODE,
} from 'actions/frameAdvisor'
import config from 'config'
import { omit } from 'lodash'
import {
  BiometricData,
  FrameAdvisorUserProfile,
  FrameAdvisorViewMode,
  LoveHatesData,
  UndoData,
} from 'types/frameAdvisor'
import { ContentV2PlaylistItem } from 'types/graphqlTypes'
import { Product } from 'types/product'
import {
  ADD_LOVE_HATE_MOCO,
  ADD_TO_MYSELECTION,
  CLEAR_FSA,
  REMOVE_FROM_MYSELECTION,
  REMOVE_LOVE_HATE_MOCO,
  REMOVE_MOCO_FROM_UNDO,
  REMOVE_SURVEY_CHOICE,
  RESET_FRAME_ADVISOR,
  RESET_LOVE_HATE,
  RESET_SURVEY,
  SAVE_FACE_SCAN_TOKENS,
  SAVE_FSA_USERID,
  SAVE_FSA_VIDEOID,
  SAVE_LAST_STEP_FOR_BACK,
  SAVE_MOCO_FOR_UNDO,
  SAVE_SURVEY_TOKENS,
  SELECT_FRAME_ADVISOR_PLAYLIST,
  SELECT_FRAME_ADVISOR_SHADES,
  SET_COMPLETE_SURVEY,
  SET_FRAME_ADVISOR_MYSELECTION_VISITED,
  SET_SELECTED_OPTION_ID,
  SET_SELECTED_OPTION_IDS,
  TOGGLE_COUVETTE_DRESSED_ON,
  UNDO_HATE_ITEM,
  UPDATE_BIOMETRIC_DATA,
  UPDATE_STYLES_PREFERENCES,
  UPDATE_SURVEY,
  UPDATE_SURVEY_STEPS,
  UPDATE_VISITED,
} from '../actions/frameAdvisor'

export interface FrameAdvisorState {
  isFrameAdvisorVisited: boolean
  isDressedOnEnabled: boolean
  survey: Record<string, string>
  surveySteps: Record<string, string>
  lastStep: string | null
  isSurveyComplete: boolean
  isScanning: boolean
  isStylesPreferencesSelected: boolean
  loveHate: LoveHatesData[]
  mySelection: Product[]
  fsaVideoId: string | null
  fsaInfo: FrameAdvisorUserProfile | null
  faceScanAccessToken: string | null
  faceScanRefreshToken: string | null
  surveyAccessToken: string | null
  surveyRefreshToken: string | null
  fsaUserId: string | null
  selectedPlaylist: ContentV2PlaylistItem | null
  shadesFor: string | null
  mySelectionVisited: boolean
  isWishlistDressedOnEnabled: boolean
  undoableList: UndoData[]
  selectedOptionIds: Record<string, string>
  sentenceMap: Record<string, number>
  biometricData?: Partial<BiometricData>
  flagAgeDeclaration: boolean
  viewMode: FrameAdvisorViewMode
}

export enum FaPlaylistType {
  OPTICAL = '1',
  SUN = '2',
}

export interface Survey {
  playlistType: FaPlaylistType
  gender?: string
  face_shape?: string
  face_length?: string
}

export interface Survey {
  playlistType: FaPlaylistType
  gender?: string
  face_shape?: string
  face_length?: string
}

const initialState: FrameAdvisorState = {
  isFrameAdvisorVisited: false,
  survey: {
    playlistType: config.defaultProductType,
    gender: undefined,
    face_shape: undefined,
    face_length: undefined,
  },
  surveySteps: {},
  lastStep: null,
  isSurveyComplete: false,
  isScanning: false,
  isStylesPreferencesSelected: false,
  loveHate: [],
  mySelection: [],
  fsaVideoId: null,
  fsaInfo: null,
  faceScanAccessToken: null,
  faceScanRefreshToken: null,
  surveyAccessToken: null,
  surveyRefreshToken: null,
  fsaUserId: null,
  isDressedOnEnabled: false,
  selectedPlaylist: null,
  shadesFor: null,
  mySelectionVisited: false,
  isWishlistDressedOnEnabled: false,
  undoableList: [],
  selectedOptionIds: {},
  sentenceMap: {},
  flagAgeDeclaration: false,
  viewMode: 'product',
}

export default (state = initialState, action: FrameAdvisorActionTypes): FrameAdvisorState => {
  switch (action.type) {
    case UPDATE_SURVEY: {
      return {
        ...state,
        survey: {
          ...state.survey,
          [action.payload.key]: action.payload.tag,
        },
      }
    }

    case UPDATE_SURVEY_STEPS: {
      return {
        ...state,
        surveySteps: {
          ...state.surveySteps,
          ...action.payload,
        },
      }
    }

    case SET_COMPLETE_SURVEY: {
      return {
        ...state,
        isSurveyComplete: action.payload,
      }
    }

    case SET_ON_CAPTURE_PROCESS: {
      return {
        ...state,
        isScanning: action.payload,
      }
    }

    case RESET_SURVEY: {
      return {
        ...state,
        survey: initialState.survey,
        isSurveyComplete: initialState.isSurveyComplete,
      }
    }

    case ADD_LOVE_HATE_MOCO: {
      const { playListId, moco } = action.payload
      const newLoveHate = state.loveHate.filter(
        lh => !(lh.moco === moco && (!playListId || lh.playListId === playListId))
      )
      newLoveHate.push(action.payload)
      return {
        ...state,
        loveHate: newLoveHate,
      }
    }

    case REMOVE_LOVE_HATE_MOCO: {
      const { playlistId, moco } = action.payload
      const newLoveHate = state.loveHate.filter(
        lh => !(lh.moco === moco && (!playlistId || lh.playListId === playlistId))
      )
      return {
        ...state,
        loveHate: newLoveHate,
      }
    }

    case SAVE_MOCO_FOR_UNDO: {
      const newUndoableList = state.undoableList.filter(
        (lh: UndoData) => lh.moco !== action.payload.moco
      )
      newUndoableList.push(action.payload)
      return {
        ...state,
        undoableList: newUndoableList,
      }
    }

    case REMOVE_MOCO_FROM_UNDO: {
      const newUndoableList = state.undoableList.filter(
        (lh: UndoData) => lh.moco !== action.payload.moco
      )
      return {
        ...state,
        undoableList: newUndoableList,
      }
    }

    case UNDO_HATE_ITEM: {
      const newUndoableList = state.undoableList.filter(
        (lh: UndoData) => lh.moco !== action.payload.moco
      )
      const newLoveHateList = state.loveHate.filter(lh => lh.moco !== action.payload.moco)
      return {
        ...state,
        undoableList: newUndoableList,
        loveHate: newLoveHateList,
      }
    }

    case RESET_LOVE_HATE: {
      return {
        ...state,
        loveHate: initialState.loveHate,
      }
    }

    case UPDATE_VISITED: {
      return {
        ...state,
        isFrameAdvisorVisited: action.payload.visited,
      }
    }

    case ADD_TO_MYSELECTION: {
      const newMySelection = state.mySelection.filter(i => i.moco !== action.payload.product.moco)
      newMySelection.push(action.payload.product)
      return {
        ...state,
        mySelection: newMySelection,
      }
    }

    case REMOVE_FROM_MYSELECTION: {
      const newMySelection = state.mySelection.filter(i => i.moco !== action.payload.moco)
      return {
        ...state,
        mySelection: newMySelection,
      }
    }

    case SAVE_FSA_VIDEOID: {
      return {
        ...state,
        fsaVideoId: action.payload.videoId,
      }
    }

    case RESET_FRAME_ADVISOR: {
      return {
        ...initialState,
        faceScanAccessToken: state.faceScanAccessToken,
        faceScanRefreshToken: state.faceScanRefreshToken,
      }
    }

    case CLEAR_FSA: {
      return {
        ...state,
        fsaInfo: null,
        fsaVideoId: null,
        fsaUserId: null,
        surveyAccessToken: null,
        surveyRefreshToken: null,
      }
    }

    case TOGGLE_COUVETTE_DRESSED_ON: {
      return {
        ...state,
        isDressedOnEnabled: !state.isDressedOnEnabled,
      }
    }

    case TOGGLE_WISHLIST_DRESSED_ON: {
      return {
        ...state,
        isWishlistDressedOnEnabled: !state.isWishlistDressedOnEnabled,
      }
    }

    case SELECT_FRAME_ADVISOR_PLAYLIST: {
      return {
        ...state,
        selectedPlaylist: action.payload.playlist,
      }
    }

    case SELECT_FRAME_ADVISOR_SHADES: {
      return {
        ...state,
        shadesFor: action.payload.shadesFor,
      }
    }
    case SET_FRAME_ADVISOR_MYSELECTION_VISITED: {
      return {
        ...state,
        mySelectionVisited: true,
      }
    }
    case SET_SELECTED_OPTION_ID: {
      const { key, id } = action.payload
      return {
        ...state,
        selectedOptionIds: {
          ...state.selectedOptionIds,
          [key]: id,
        },
      }
    }
    case SET_SELECTED_OPTION_IDS: {
      return {
        ...state,
        selectedOptionIds: action.payload,
      }
    }
    case REMOVE_SURVEY_CHOICE: {
      const updatedSurvey = omit(state.survey, action.payload)
      return {
        ...state,
        survey: {
          ...updatedSurvey,
        },
      }
    }
    case SAVE_LAST_STEP_FOR_BACK: {
      return {
        ...state,
        lastStep: action.payload,
      }
    }
    case SAVE_FACE_SCAN_TOKENS: {
      return {
        ...state,
        faceScanAccessToken: action.payload.accessToken,
        faceScanRefreshToken: action.payload.refreshToken,
      }
    }
    case SAVE_SURVEY_TOKENS: {
      return {
        ...state,
        surveyAccessToken: action.payload.accessToken,
        surveyRefreshToken: action.payload.refreshToken,
      }
    }
    case SAVE_FSA_USERID: {
      return {
        ...state,
        fsaUserId: action.payload.userId,
      }
    }
    case SAVE_FSA_INFO: {
      return {
        ...state,
        fsaInfo: { ...action.payload },
      }
    }
    case SET_SENTENCE_MAP: {
      return {
        ...state,
        sentenceMap: action.payload.sentenceMap,
      }
    }
    case UPDATE_STYLES_PREFERENCES: {
      return {
        ...state,
        isStylesPreferencesSelected: action.payload,
      }
    }
    case UPDATE_BIOMETRIC_DATA: {
      return {
        ...state,
        biometricData: state.biometricData
          ? { ...state.biometricData, ...action.payload }
          : action.payload,
      }
    }
    case UPDATE_FLAG_AGE_DECLARATION: {
      return {
        ...state,
        flagAgeDeclaration: action.payload,
      }
    }
    case UPDATE_VIEW_MODE: {
      return {
        ...state,
        viewMode: action.payload,
      }
    }
    default:
      return state
  }
}
