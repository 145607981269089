import React, { useEffect, useState } from 'react'

import { Keyboard } from './Keyboard'
import { KeyboardedInputInnerProps, KeyboardedInputProps } from '../../types/touch-keyboard'

const KeyboardedInputComponent = ({
  coords = {
    x: -2.4453125,
    y: -335.53125,
  },
  show,
  ...otherProps
}: KeyboardedInputInnerProps) => {
  const [position, setStorePosition] = useState(coords)
  const setStorePositionCb = (x: number, y: number) => setStorePosition({ x, y })

  useEffect(() => {
    setStorePosition({ x: coords.x, y: coords.y })
  }, [coords.x, coords.y])

  return show ? (
    <Keyboard coords={position} storePosition={setStorePositionCb} {...otherProps} />
  ) : null
}

export const KeyboardedInput = React.forwardRef<HTMLDivElement, KeyboardedInputProps>(
  (props, ref) => (
    <div className={props.containerClassName} ref={ref}>
      <KeyboardedInputComponent {...props} />
    </div>
  )
)

KeyboardedInput.displayName = 'KeyboardedInput'
