import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { useAction } from 'hooks/useActions'
import { hideAllDrawer } from 'actions/ui'

export const LinkWithCloseDrawer: React.FC<LinkProps> = ({ children, ...props }) => {
  const hideAll = useAction(hideAllDrawer)

  return (
    <Link
      {...props}
      onClick={e => {
        hideAll()
        props.onClick && props.onClick(e)
      }}
    >
      {children}
    </Link>
  )
}
