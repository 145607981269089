import styled from 'styled-components'

export const KioskAppClickCounterSection = styled.div`
  height: 50px;
  width: 50px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999999;
`
