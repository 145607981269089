import React from 'react'
import { IconProps } from 'types/icons'

export const FrameWidthIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = 'none',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M19.166 6C16.389 6 14.6565 6.7815 13.9855 8.324C13.162 7.9345 12.1695 7.9345 11.3465 8.324C10.6755 6.7815 8.94302 6 6.16602 6C2.51652 6 0.666016 7.3455 0.666016 10C0.666016 13.3925 2.77352 15.5 6.16602 15.5C9.19852 15.5 11.666 13.0325 11.666 10C11.666 9.757 11.645 9.5285 11.614 9.3075C12.2465 8.924 13.0855 8.924 13.718 9.3075C13.687 9.5285 13.666 9.757 13.666 10C13.666 13.0325 16.1335 15.5 19.166 15.5C22.5585 15.5 24.666 13.3925 24.666 10C24.666 7.3455 22.8155 6 19.166 6ZM6.16602 14.5C2.10452 14.5 1.66602 11.353 1.66602 10C1.66602 8.6035 2.17752 7 6.16602 7C10.1545 7 10.666 8.6035 10.666 10C10.666 12.481 8.64702 14.5 6.16602 14.5ZM19.166 14.5C16.685 14.5 14.666 12.481 14.666 10C14.666 8.6035 15.1775 7 19.166 7C23.1545 7 23.666 8.6035 23.666 10C23.666 11.353 23.2275 14.5 19.166 14.5Z"
        fill={fill}
      />
      <path d="M23.666 0V1H1.66602V0H0.666016V3H1.66602V2H23.666V3H24.666V0H23.666Z" fill={fill} />
    </svg>
  )
}
