//TODO: ship-to-consumer those are methods for checkoutV3
// checck for possibile merge with checkout libs
import * as emailValidator from 'email-validator'
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'
import * as postcodeValidator from 'postcode-validator'
import { TFunction } from 'react-i18next'
import { CartItem } from 'types/cart'
import {
  CheckoutFormData,
  CheckoutFormState,
  FormErrors,
  FormFieldValidator,
  FormFieldsValidators,
  OrderItemShipToConsumer,
  ShipToConsumerAddress,
  ShipToConsumerAdressData,
  State,
  StoreCountry,
  StoreData,
} from 'types/checkoutV3'
import { ShippingMethod, Store } from 'types/graphqlTypes'
import { CustomProduct } from 'types/product'
import { isCustomizedProduct } from './product'

export const US_WAREHOUSE = 'ZZSTOCK'
export const OVERSEAS_WAREHOUSE = 'ZZSTOCK2'

export const checkIsShippingMethod = (shippingMethods: ShippingMethod[], code: string) =>
  shippingMethods?.find(method => method.code === code)

export const filterShippingMethods = (shippingMethods: ShippingMethod[], shipping: string) =>
  shippingMethods?.filter(method => {
    if (shipping === 'home') {
      return method.destinationAvailability.home
    }
    return method.destinationAvailability.store
  })

/** Extract error message and translates it */
export const translateErrorMessage = (
  t: TFunction,
  name: keyof FormErrors,
  errors: FormErrors | undefined
) => {
  if (errors && errors[name]) {
    return t(errors[name])
  }
  return undefined
}

const convertToCountryCode = (countryId: string): CountryCode => {
  return countryId === 'UK' ? 'GB' : (countryId as CountryCode)
}

export const getCheckoutData = (
  store: Store,
  checkout: CheckoutFormState
): ShipToConsumerAdressData => {
  const {
    shipping,
    shippingType,
    firstName,
    lastName,
    email,
    phone,
    address,
    address1,
    zip,
    city,
    state,
  } = checkout.data

  let shipToConsumerAddress: ShipToConsumerAddress = undefined
  if (shipping !== 'store') {
    shipToConsumerAddress = {
      address,
      address1,
      zipCode: zip,
      city,
      country: store.countryId,
      state: state || '',
    }
  }

  const checkoutData: ShipToConsumerAdressData = {
    shipToConsumerAddress,
    shipToConsumerInfo: {
      firstName,
      lastName,
      email,
      phone,
    },
    shipToConsumerMethod: { shipMethodCode: shippingType },
  }

  return checkoutData
}

export const validateField = (
  validators: FormFieldValidator[],
  value: string | undefined,
  formData: CheckoutFormData,
  storeData: StoreData
) => {
  let error = ''
  validators.forEach(check => {
    const isValid = check.validate(value, formData, storeData)
    if (!isValid) {
      error = check.message
    }
  })
  return error
}

const isRequiredValidator: FormFieldValidator = {
  validate: v => !!v,
  message: 'CheckoutForm.requiredError',
}

const requiredIfShipping: FormFieldValidator = {
  validate: (v, data) => {
    if (data.shipping !== 'home') {
      return true
    }
    return !!v
  },
  message: 'CheckoutForm.requiredError',
}

const isValidEmail: FormFieldValidator = {
  validate: v => !v || (v.indexOf('@') !== -1 && emailValidator.validate(v)),
  message: 'CheckoutForm.emailError',
}

const isValidPhone: FormFieldValidator = {
  validate: (v, _data, store) => {
    if (v) {
      if (v.indexOf('+') !== -1) {
        return isValidPhoneNumber(v)
      }
      // use the country of the store

      const countryCode: CountryCode = convertToCountryCode(store.countryId)

      return isValidPhoneNumber(v, countryCode)
    }
    return false
  },
  message: 'CheckoutForm.phoneError',
}

const isMatchingEmail: FormFieldValidator = {
  validate: (v, data) => !v || v.toLowerCase() === data.email.toLowerCase(),
  message: 'CheckoutForm.emailVerificationError',
}

const isValidZipCode: FormFieldValidator = {
  validate: (v, data, store) => {
    if (data.shipping === 'home' && v) {
      // see https://abs-tech.atlassian.net/browse/WALL-462
      // ensure postal code contains a space.
      if (store.countryId === 'CA') {
        // get regexp from https://github.com/melwynfurtado/postcode-validator/blob/develop/src/postcode-regexes.ts
        const postalCodeReg = /^([ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ])\s*([0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/i
        return postalCodeReg.test(v)
      }

      // see https://abs-tech.atlassian.net/browse/WALL-539
      // validate also UK or GB postal code prefix only.
      if (store.countryId === 'UK' || store.countryId === 'GB') {
        const postalCodeReg = /^([A-Z]){1}([0-9][0-9]|[0-9]|[A-Z][0-9][A-Z]|[A-Z][0-9][0-9]|[A-Z][0-9]|[0-9][A-Z]){1}([ ])?([0-9][A-z][A-z]){0,1}$/i
        return postalCodeReg.test(v)
      }

      if (store.countryId === 'US' && store.stateId === 'PR') {
        const postalCodeReg = /^00[679]([0-9]{2})(-[0-9]{4})?$/ // Puerto Rico
        if (!postalCodeReg.test(v)) {
          return false
        }
      }
      if (store.countryId === 'US' && store.stateId === 'VI') {
        const postalCodeReg = /^008([0-9]{2})(-[0-9]{4})?$/ // Virgin Islands
        if (!postalCodeReg.test(v)) {
          return false
        }
      }

      if (store.countryId === 'PT') {
        const postalCodeReg = /[1-9][0-9]{3}-[0-9]{3}/ // Portugal
        if (!postalCodeReg.test(v)) {
          return false
        }
      }

      if (store.countryId === 'NL') {
        const postalCodeReg = /^\d{4}\s[A-Z]{2}$/ // Netherlands
        if (!postalCodeReg.test(v)) {
          return false
        }
      }

      return postcodeValidator.validate(v, store.countryId)
    }
    return true // skip validation
  },
  message: 'CheckoutForm.zipCodeError',
}

const isYesOrNo: FormFieldValidator = {
  validate: v => {
    return v === 'y' || v === 'n'
  },
  message: 'CheckoutForm.requiredError',
}

const isRequiredCheckoutCountryPostcode: FormFieldValidator = {
  validate: (v, _data, store) => {
    return requiredIfShipping.validate(v, _data, store)
  },
  message: 'CheckoutForm.requiredError',
}

export const fieldsValidator: FormFieldsValidators = {
  firstName: [isRequiredValidator],
  lastName: [isRequiredValidator],
  email: [isRequiredValidator, isValidEmail],
  emailVerification: [isRequiredValidator, isValidEmail, isMatchingEmail],
  phone: [isRequiredValidator, isValidPhone],
  address: [requiredIfShipping],
  zip: [isRequiredCheckoutCountryPostcode, isValidZipCode],
  city: [requiredIfShipping],
  state: [isRequiredCheckoutCountryPostcode],
  marketing: [isYesOrNo],
  profiling: [isYesOrNo],
  localName: [isRequiredValidator],
  localName2: [isRequiredValidator],
}

const isCalifornia = (countryId: string, stateCode: string): boolean =>
  countryId === 'US' && stateCode === 'CA'

export const checkIfCalifornia = (
  val: string | undefined,
  storeCountry: StoreCountry,
  states: State[]
) => {
  const { countryId = '', code = '' } = storeCountry || {}

  if (countryId && code && isCalifornia(countryId, code)) {
    return true
  }

  if (val) {
    const selectedState = states.find(state => state.id === val)

    if (selectedState) {
      const { countryId, code } = selectedState
      return isCalifornia(countryId, code)
    }
  }

  return false
}

export const findPanelsWithErrors = (
  panelsAndFields: Record<string, string[]>,
  errors: string[]
): string[] => {
  if (errors.length > 0) {
    const panels: string[] = []
    Object.keys(panelsAndFields).forEach((panel: string) => {
      if (panelsAndFields[panel].find((field: string) => errors.includes(field))) {
        panels.push(panel)
      }
    })
    return panels
  }
  return []
}

export const mergeOrderItemWithCartInfo = (
  orderList: OrderItemShipToConsumer[],
  cartItems: CartItem[]
): OrderItemShipToConsumer[] => {
  try {
    const mergedOrderItems = orderList?.map(orderItem => {
      const matchCartItem = cartItems?.find(item => {
        if (isCustomizedProduct(item.product?.type)) {
          return item.product?.recipeId === orderItem?.customDetails?.recipeId
        } else {
          return item.product.UPC === orderItem.details.upc
        }
      })

      if (!!matchCartItem) {
        const currentItemProduct: CustomProduct = matchCartItem.product || null
        return {
          ...orderItem,
          itemQuantity: matchCartItem.quantity,
          product: currentItemProduct,
        }
      } else {
        return orderItem
      }
    })
    return mergedOrderItems
  } catch (e) {
    return orderList
  }
}

const isCartItemFromUsWarehouse = (item: OrderItemShipToConsumer) =>
  item.warehouse.includes(US_WAREHOUSE)

export const isCartItemOnlyFromUsWarehouse = (item: OrderItemShipToConsumer) =>
  item.warehouse.length === 1 && isCartItemFromUsWarehouse(item)

export const isAnyCartItemFromUsWarehouse = (orderList: OrderItemShipToConsumer[]) => {
  const res = orderList.some((item: OrderItemShipToConsumer) => isCartItemFromUsWarehouse(item))
  return res
}
