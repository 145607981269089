import { clearPlpPreselectedFilters, hideAllDrawer } from 'actions/ui'
import { ListItemButton } from 'components/core/List'
import Maybe from 'graphql/tsutils/Maybe'
import { convertToCamelCaseAndStripSpecials } from 'libs/utils'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CmsAnalyticsData } from 'types/graphqlTypes'
import { MenuItem, MenuItemTitle, MenuList, MenuTitle } from '../styles'

export interface MenuItem {
  label: string
  href?: Maybe<string>
  items?: MenuItem[]
  analyticsData?: Maybe<CmsAnalyticsData>
  firstLevel?: Maybe<string>
  secondLevel?: Maybe<string>
}

interface MenuGroupProps {
  menu: MenuItem
  onSelectMenu: (item: MenuItem) => void
  isTopLevel: boolean
}

export const MenuGroup: React.FC<MenuGroupProps> = ({ menu, onSelectMenu, isTopLevel }) => {
  const dispatch = useDispatch()
  const onSelect = useCallback(() => {
    dispatch(hideAllDrawer)
    dispatch(clearPlpPreselectedFilters())
  }, [dispatch])

  return menu ? (
    <div>
      <MenuTitle isTopLevel={isTopLevel}>{menu.label}</MenuTitle>
      {menu.items && (
        <MenuList isTopLevel={isTopLevel}>
          {menu.items.map((item, index) => {
            const dataElementId = item.analyticsData
              ? `${item.analyticsData?.dataElementId}${item.analyticsData?.dataDescription}`
              : undefined
            const dataDescription = isTopLevel
              ? item.firstLevel || item.label
              : item.secondLevel || 'ViewAll'

            return (
              <MenuItem key={`${index}-${item.label}`}>
                <ListItemButton
                  onClick={() => {
                    if (item.items && item.items.length > 1) {
                      return onSelectMenu(item)
                    }
                    onSelect()
                  }}
                  dataAttrs={{
                    id: dataElementId,
                    description: convertToCamelCaseAndStripSpecials(dataDescription, true),
                  }}
                  ariaAttrs={{ label: `${menu.label} ${item.label}` }}
                  navigateTo={item.href ? item.href : undefined}
                >
                  <MenuItemTitle>{item.label}</MenuItemTitle>
                </ListItemButton>
              </MenuItem>
            )
          })}
        </MenuList>
      )}
    </div>
  ) : null
}

interface MenuContentProps {
  menu: MenuItem
  onSelect: (item: MenuItem) => void
  isTopLevel: boolean
}

export const MenuContent: React.FC<MenuContentProps> = ({ menu, onSelect, isTopLevel }) => {
  return (
    <MenuGroup
      menu={menu}
      onSelectMenu={(menu: MenuItem) => {
        onSelect(menu)
      }}
      isTopLevel={isTopLevel}
    />
  )
}
