import React from 'react'
interface IconProps {
  width?: string
  height?: string
  fill?: string
  stroke?: string
  className?: string
}
export const RotateDeviceToPortraitIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
    >
      <g clipPath="url(#clip0_22125_9258)">
        <path
          d="M58.3333 76.6667H8.33333C5.57667 76.6667 3.33333 74.4233 3.33333 71.6667V46.6667C3.33333 43.91 5.57667 41.6667 8.33333 41.6667H33.3333V38.3333H8.33333C3.73833 38.3333 0 42.0717 0 46.6667V71.6667C0 76.2617 3.73833 80 8.33333 80H58.3333C62.9283 80 66.6667 76.2617 66.6667 71.6667H63.3333C63.3333 74.4233 61.09 76.6667 58.3333 76.6667Z"
          fill={fill}
        />
        <path d="M13.3333 52.5H10V65.8333H13.3333V52.5Z" fill={fill} />
        <path
          d="M71.6667 0H46.6667C42.0717 0 38.3333 3.73833 38.3333 8.33333V58.3333C38.3333 62.9283 42.0717 66.6667 46.6667 66.6667H71.6667C76.2617 66.6667 80 62.9283 80 58.3333V8.33333C80 3.73833 76.2617 0 71.6667 0ZM76.6667 58.3333C76.6667 61.09 74.4233 63.3333 71.6667 63.3333H46.6667C43.91 63.3333 41.6667 61.09 41.6667 58.3333V8.33333C41.6667 5.57667 43.91 3.33333 46.6667 3.33333H71.6667C74.4233 3.33333 76.6667 5.57667 76.6667 8.33333V58.3333Z"
          fill={fill}
        />
        <path d="M65.8333 10H52.5V13.3333H65.8333V10Z" fill={fill} />
        <path
          d="M23.3333 16.6667H26.3817L22.155 20.8933L24.5117 23.25L32.7617 15L24.5117 6.75L22.155 9.10667L26.3817 13.3333H23.3333C14.1433 13.3333 6.66667 20.81 6.66667 30H10C10 22.6483 15.9817 16.6667 23.3333 16.6667Z"
          fill={fill}
        />
        <path
          d="M59.1667 56.6667C61.0076 56.6667 62.5 55.1743 62.5 53.3333C62.5 51.4924 61.0076 50 59.1667 50C57.3257 50 55.8333 51.4924 55.8333 53.3333C55.8333 55.1743 57.3257 56.6667 59.1667 56.6667Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_22125_9258">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
