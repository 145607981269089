import React, { ReactElement } from 'react'

import { PanelHeader } from 'components/MasterUI/PanelHeader'
import { PanelContent, PanelTitle, PDPPanelContainer, TitleCounter } from './styles'

export interface PDPPanelProps {
  title: string
  counter?: number
  onClose?: (event: React.MouseEvent<Element, MouseEvent>) => void
  showBackButton?: boolean
  showCloseButton?: boolean
  onBack?: React.EventHandler<React.MouseEvent>
  className?: string
  renderTitle?: () => ReactElement
}

export const PDPPanel = React.forwardRef<HTMLDivElement, React.PropsWithChildren<PDPPanelProps>>(
  (
    {
      title,
      counter,
      onClose,
      children,
      onBack,
      showBackButton = false,
      showCloseButton = true,
      className,
      renderTitle,
    },
    ref
  ) => {
    return (
      <PDPPanelContainer buttonsAboveTitle={showBackButton} className={className}>
        <PanelHeader
          onClose={onClose}
          showBackButton={showBackButton}
          showCloseButton={showCloseButton}
          onBack={onBack}
        >
          {renderTitle ? (
            renderTitle()
          ) : (
            <PanelTitle aria-label={title} aria-description={title} weight="bold">
              {title}
              {counter !== undefined && (
                <TitleCounter color="inactive" weight="bold">
                  ({counter})
                </TitleCounter>
              )}
            </PanelTitle>
          )}
        </PanelHeader>
        <PanelContent ref={ref}>{children}</PanelContent>
      </PDPPanelContainer>
    )
  }
)

PDPPanel.displayName = 'PDPPanel'
