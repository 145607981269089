import {
  ADD_ITEM_TO_WISHLIST,
  HIDE_TOOLTIP,
  REMOVE_ITEM_FROM_WISHLIST,
  TOGGLE_ITEM_TO_WISHLIST,
  WishlistActionTypes,
} from 'actions/wishlist'
import { Product } from 'types/product'

export interface WishlistState {
  items: Product[]
  tooltipIsVisible: boolean
}

const initialState: WishlistState = {
  items: [],
  tooltipIsVisible: true,
}

export default (state = initialState, action: WishlistActionTypes): WishlistState => {
  switch (action.type) {
    case TOGGLE_ITEM_TO_WISHLIST: {
      const { product } = action.payload

      // Remove product if present...
      const newItems = state.items.filter(item => item.UPC !== product.UPC)

      // If no item was removed, add the product
      if (newItems.length === state.items.length) {
        newItems.push({ ...product })
      }

      return {
        ...state,
        items: newItems,
      }
    }

    case ADD_ITEM_TO_WISHLIST: {
      const { product } = action.payload
      const isAlreadyWishlisted = state.items.some(item => item.UPC === product.UPC)

      if (isAlreadyWishlisted) {
        return state
      }

      return {
        ...state,
        items: [...state.items, product],
      }
    }

    case REMOVE_ITEM_FROM_WISHLIST: {
      const { product } = action.payload

      // Remove product if present...
      const newItems = state.items.filter(item => item.UPC !== product.UPC)

      return {
        ...state,
        items: newItems,
      }
    }

    case HIDE_TOOLTIP:
      return {
        ...state,
        tooltipIsVisible: false,
      }

    default:
      return state
  }
}
