import styled from 'styled-components'
import { pxToRem } from '../../libs/styled'

export const OfflinePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export const MessageBox = styled.div`
  background: white;
  border-radius: ${pxToRem(12)};
  box-shadow: 0 0 10px 1px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${pxToRem(50)};
`

export const MessageBoxHeader = styled.div`
  text-align: left;
  padding: ${pxToRem(30)} ${pxToRem(30)} 0;
  h3 {
    color: red;
  }
`
export const MessageBoxErrorDetails = styled.div`
  text-align: left;
  background: #ff000030;
  padding: ${pxToRem(40)};
  border-radius: 0 0 ${pxToRem(12)} ${pxToRem(12)};
  h5 {
    color: red;
    margin-bottom: ${pxToRem(15)};
  }
  span {
    display: block;
  }
`
