import { IconProps } from 'types/icons'
import React from 'react'

export const OvalIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 0.400391C32.757 0.400391 29.081 1.84439 27.247 3.95539C26.285 3.33539 25.165 3.00039 24 3.00039C22.827 3.00039 21.713 3.33739 20.755 3.95739C18.921 1.84539 15.245 0.400391 11 0.400391C4.925 0.400391 0 3.35539 0 7.00039C0 10.6454 4.925 13.6004 11 13.6004C17.075 13.6004 22 10.6454 22 7.00039C22 6.54739 21.923 6.10439 21.778 5.67739C22.43 5.23939 23.194 5.00039 24 5.00039C24.801 5.00039 25.567 5.24239 26.221 5.68139C26.077 6.10739 26 6.54839 26 7.00039C26 10.6454 30.925 13.6004 37 13.6004C43.075 13.6004 48 10.6454 48 7.00039C48 3.35539 43.075 0.400391 37 0.400391ZM11 11.6004C5.696 11.6004 2 9.17639 2 7.00039C2 4.82439 5.696 2.40039 11 2.40039C16.304 2.40039 20 4.82439 20 7.00039C20 9.17639 16.304 11.6004 11 11.6004ZM28 7.00039C28 9.17639 31.696 11.6004 37 11.6004C42.304 11.6004 46 9.17639 46 7.00039C46 4.82439 42.304 2.40039 37 2.40039C31.696 2.40039 28 4.82439 28 7.00039Z"
        fill={fill}
      />
    </svg>
  )
}
