import { Tooltip } from 'components/Tooltip'
import React, { useEffect, useState } from 'react'

const SHOW_TOOLTIP_INTERVAL = 5000

interface ToolTipTimeoutProps {
  text: string
  visible: boolean
}

export const ToolTipTimeout: React.FC<ToolTipTimeoutProps> = ({ text, visible, children }) => {
  const [isVisible, setIsVisible] = useState(visible)

  useEffect(() => {
    if (isVisible) {
      const timeout = window.setTimeout(() => {
        setIsVisible(false)
      }, SHOW_TOOLTIP_INTERVAL)

      return () => {
        timeout && clearTimeout(timeout)
      }
    }
  }, [isVisible])

  return (
    <Tooltip text={text} visible={isVisible} position="right" timeout={SHOW_TOOLTIP_INTERVAL}>
      {children}
    </Tooltip>
  )
}
