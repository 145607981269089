import { setBarcodeModalInitialQuery } from 'actions/barcode'
import { setBarcodeFlow } from 'actions/flows'
import { WHSSkuBase } from 'components/BarCodeScanModal/types'
import Loading from 'components/Loading'
import { useBarcodeProducts } from 'data'
import useBarcodeHandleScenario from 'hooks/useBarcodeHandleScenario'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { getSearchValueFromQs } from 'libs/utils'
import { intersection, isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const BarcodeActions: React.FC = () => {
  const history = useHistory()
  const initialQuery = getSearchValueFromQs(history.location)
  const { basePath } = useStoreIndentity()

  const { data: barcodeProducts } = useBarcodeProducts(initialQuery)
  const redirectToScenario = useBarcodeHandleScenario()

  const dispatch = useDispatch()

  useEffect(() => {
    // The NavigationActions fragment can't handle the barcode flow starting here
    dispatch(setBarcodeFlow(true))
    dispatch(setBarcodeModalInitialQuery(initialQuery))
  }, [dispatch, initialQuery])

  if (!barcodeProducts) {
    return <Loading />
  }
  const {
    model,
    catalogModelDTO,
    similarProducts,
    items,
    upcSameLensColor,
    upcSameFrameColor,
    upcSameSize,
    upcSameModel,
    upcPrizm,
    upcPolarized,
    upcPolarizedRelated,
    upcCustomizable,
  } = barcodeProducts.barcodeProducts

  const baseUpc = intersection(upcSameLensColor, upcSameSize, upcSameFrameColor, upcSameModel)
  const polarizedRelatedAndSameSize = intersection(upcPolarizedRelated, upcSameSize)
  const baseAndPolarizedUpc = intersection(baseUpc, upcPolarized)
  const differentCustomizableUpc = intersection(upcCustomizable, upcSameSize, upcSameModel)
  const baseAndPolarPrizmUpc = intersection(baseUpc, upcPrizm, upcPolarizedRelated)
  const baseAndPrizmUpc = intersection(baseUpc, upcPrizm)
  const prizmAndPolarUpc = intersection(upcPrizm, upcPolarized)

  const availableProduct = items?.find(p => p.upc === initialQuery)
  const isCustomAndAvailable = upcCustomizable?.includes(initialQuery)
  const isPrizm =
    items?.find(() => prizmAndPolarUpc.includes(initialQuery)) ??
    items?.find(() => upcPrizm.includes(initialQuery))

  const isCustomAndUnavailable =
    items.find(i => i.attributes?.find(a => a?.name === 'customizable' && a.value === 'true')) ||
    catalogModelDTO.find(e => e.standardModelCode === model)
  const isPolarizedList =
    items?.filter(() => baseAndPolarizedUpc?.includes(initialQuery)) || ([] as WHSSkuBase[])
  const isPolarized = !!isPolarizedList.find(x => x.upc === initialQuery)

  const availablePolarPrizmMoco = items?.find(p => baseAndPolarPrizmUpc.includes(p.upc))
  const availablePrizmMoco = items?.find(p => baseAndPrizmUpc.includes(p.upc))
  const availablePolarizedMoco = items?.find(p => polarizedRelatedAndSameSize.includes(p.upc))

  const availableSameSizeMoco = items?.find(p => upcSameSize?.includes(p.upc))
  const hasProductNeverExisted = isEmpty(similarProducts)
  const availableOtherMoco = items?.find(p => differentCustomizableUpc.includes(p.upc))

  if (availableProduct) {
    if (isCustomAndAvailable) {
      return redirectToScenario('scenario1', availableProduct)
    }
    if (isPrizm) {
      return redirectToScenario('scenario2A', availableProduct)
    }
    if (availablePolarPrizmMoco) {
      return redirectToScenario('scenario2B', availablePolarPrizmMoco, {
        pathname: availableProduct.url,
      })
    }
    if (availablePrizmMoco) {
      return redirectToScenario('scenario2C', availablePrizmMoco, {
        pathname: availableProduct.url,
      })
    }
    if (isPolarized) {
      return redirectToScenario('scenario3A', availableProduct)
    } else {
      if (!availablePolarizedMoco) {
        return redirectToScenario('scenario3C', availableProduct)
      } else {
        return redirectToScenario('scenario3B', availablePolarizedMoco, {
          pathname: availableProduct.url,
        })
      }
    }
  } else {
    if (!isEmpty(isCustomAndUnavailable)) {
      if (availableOtherMoco) {
        return redirectToScenario('scenario4A', availableOtherMoco)
      } else {
        return redirectToScenario(
          'scenario4B',
          catalogModelDTO.find(i => i.standardModelCode === model),
          {
            pathname: basePath,
            query: initialQuery,
          },
          similarProducts
        )
      }
    } else {
      if (availablePolarPrizmMoco) {
        return redirectToScenario('scenario5A', availablePolarPrizmMoco)
      }
      if (availablePrizmMoco) {
        return redirectToScenario('scenario5B', availablePrizmMoco)
      }
      if (availablePolarizedMoco) {
        return redirectToScenario('scenario6', availablePolarizedMoco)
      } else {
        if (availableSameSizeMoco) {
          return redirectToScenario('scenario7', availableSameSizeMoco)
        } else if (hasProductNeverExisted) {
          return redirectToScenario('scenario9', undefined, {
            pathname: basePath,
            query: initialQuery,
          })
        } else {
          return redirectToScenario(
            'scenario8',
            undefined,
            {
              pathname: basePath,
              query: initialQuery,
            },
            similarProducts
          )
        }
      }
    }
  }
}
