import { setVisibleModal as setVisibleModalAction } from 'actions/ui'
import { useDispatch, useSelector } from 'react-redux'
import { useAction } from './useActions'

export const useModalHandler = (modalKey?: string) => {
  const dispatch = useDispatch()
  const visibleModalKey = useSelector(s => s.ui.visibleModalKey)
  const setVisibleModal = (modalKey?: string) => dispatch(setVisibleModalAction(modalKey))
  const closeAnyModal = useAction(setVisibleModalAction(undefined))
  const isVisible = modalKey === visibleModalKey || false
  return { visibleModalKey, isVisible, closeAnyModal, setVisibleModal }
}
