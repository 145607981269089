import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ColorsStyled } from './ProductColors.style'

interface ProductColorsProps {
  availableColors: number
}

export const ProductColors = ({ availableColors = 0 }: ProductColorsProps) => {
  const { t } = useTranslation()
  return (
    <ColorsStyled>
      {availableColors > 1 && (
        <ColorsStyled
          data-test="text-colors"
          aria-label={t('ProductView.colorsAvailable', { count: availableColors })}
          aria-description={t('ProductView.colorsAvailable', { count: availableColors })}
        >
          {t('ProductView.colorsAvailable', { count: availableColors })}
        </ColorsStyled>
      )}
    </ColorsStyled>
  )
}
