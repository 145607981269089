import styled from 'styled-components'

export const StyledImg = styled.img`
  display: block;
  max-height: 100%;
  max-width: ${({ theme }) => theme.productCard.imageMaxWidth};
  margin: 0 auto ${({ theme }) => theme.productCard.imageMarginBottom};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`
