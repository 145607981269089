import styled, { DefaultColors, DefaultTheme, LegacyFonts } from 'styled-components'
import { DeepPartial } from 'types/utils'

interface ParagraphProps {
  size?: keyof DeepPartial<DefaultTheme['elementsNewUi']['typography']['sizes']>
  customSize?: string
  strong?: boolean
  removeMargin?: boolean
  color?: keyof DefaultColors
  font?: keyof LegacyFonts
}

export const Paragraph = styled.p<ParagraphProps>`
  font-family: ${({ theme, font }) => theme.fonts[font || 'primary']};
  font-size: ${({ size, theme }) => theme.elementsNewUi.typography.sizes[size || 'medium']};
  font-weight: ${({ strong }) => (strong ? 'bold' : '300')};
  color: ${({ color, theme }) => theme.colors[color || 'primaryText']};
  ${({ removeMargin }) => (removeMargin ? 'margin: 0;' : '')};
`
