import config from 'config'
import { AppConfig } from 'config/config.base'
import { Product, StoreLogo } from 'types/graphqlTypes'
import {
  CatalogAngles,
  ImageAngle,
  ImageFlag,
  ImageInfo,
  ImageShadow,
  ImageWidth,
  NewAngles,
  ProductImage,
} from 'types/image'
import { isProductAfa } from './product'

const baseURL = config.productImagesBaseUrl

/**
 * Takes in input a string and formats it in order that it can be used into a url.
 */
const formatUrlElement = (el: string): string => {
  const urlRegexp = /( |\/|%20)/g
  const subst = '_'
  return el.replace(urlRegexp, subst)
}
const imageTypePriority: Record<ImageFlag, number> = {
  colorhasPi21: 1,
  colorhasPi20: 2,
  colorhas360: 3,
}

const DEFAULT_MIN_PRIORITY = 100

const newAngles: Record<CatalogAngles, NewAngles> = {
  '000A': 'fr',
  '030A': 'qt',
  '090A': 'lt',
  '180A': 'bk',
}

type ImageFlagWithDefault = ImageFlag | 'catalog'

const getImageUrlByFlag = (
  flag: ImageFlagWithDefault,
  info: ImageInfo,
  imgWidth: ImageWidth,
  shadow: ImageShadow = 'noshad',
  angle?: ImageAngle
): string => {
  const { format, brand, originalBrand, materialCode, moco, isFirstLevel } = info
  const newAngle = newAngles[angle] || angle
  const angleQueryParam = isFirstLevel ? '' : `_${angle}`
  const newAngleQueryParam = `__${newAngle}`

  const partialUrl = `${baseURL}/${(originalBrand || brand).toUpperCase()}/${formatUrlElement(
    materialCode
  )}/${formatUrlElement(moco)}`

  switch (flag) {
    case 'colorhasPi21':
      return `${partialUrl}__P21__${shadow}${newAngleQueryParam}.png?imwidth=${imgWidth}`
    case 'colorhasPi20':
      return `${partialUrl}__STD__${shadow}${newAngleQueryParam}.png?imwidth=${imgWidth}`
    case 'colorhas360':
      return `${partialUrl}${angleQueryParam}.${format}?imwidth=${imgWidth}`
    case 'catalog':
      return `${partialUrl}${angleQueryParam}.${format}?imwidth=${imgWidth}`
    default:
      return ''
  }
}

const isCurrentPriorityHigher = (currentPiority: number, previousPriority: number): boolean =>
  currentPiority < previousPriority

const getImageFlagByPriority = (info: ImageInfo): ImageFlagWithDefault => {
  const imageFlag = Object.keys(imageTypePriority).reduce<ImageFlagWithDefault>(
    (flag, currentFlag) => {
      const currentPriority = info[currentFlag]
        ? imageTypePriority[currentFlag]
        : DEFAULT_MIN_PRIORITY
      const previousPriority = imageTypePriority[flag] || DEFAULT_MIN_PRIORITY

      if (isCurrentPriorityHigher(currentPriority, previousPriority)) {
        return currentFlag as ImageFlagWithDefault
      }
      return flag as ImageFlagWithDefault
    },
    'catalog'
  )
  return imageFlag
}

const getImageUrl = (
  info: ImageInfo,
  imgWidth: ImageWidth,
  angle: ImageAngle,
  shadow: ImageShadow
): string => {
  const imageFlag = getImageFlagByPriority(info)
  return getImageUrlByFlag(imageFlag, info, imgWidth, shadow, angle)
}

/**
 * Generates URL for product on remote server using assets url from .env.
 */
export const getProductImages = (
  product: Product,
  imgWidths: ImageWidth[] = [600],
  shadow: ImageShadow = 'noshad',
  angles: ImageAngle[] = ['030A']
): ProductImage[] => {
  const productInfo = getImageParamsFromProduct(product)

  if (!Array.isArray(imgWidths)) {
    imgWidths = Array.of(imgWidths)
  }

  return angles.map(angle => {
    let images = []

    imgWidths.forEach(imgWidth => {
      const file = getImageUrl(productInfo, imgWidth, angle, shadow)
      const image = {
        preview: file,
        file: file,
        angle: parseInt(angle) ?? angle,
      }
      images.push(image)
    })

    return images.length === 1 ? images[0] : images
  })
}

export enum CatalogImageAngles {
  FRONT = '000A',
  QUARTER = '030A',
}

export const getThumbnail = ({
  info,
  angle = CatalogImageAngles.FRONT,
  shadow = 'noshad',
  imgWidth = 600,
}: {
  info: ImageInfo
  angle?: ImageAngle
  shadow?: ImageShadow
  imgWidth?: ImageWidth
}): string => {
  return getImageUrl(info, imgWidth, angle, shadow)
}

export const getImageParamsFromProduct = (product: Product): ImageInfo => {
  const isFirstLevel = isProductAfa(product)
  const format = isFirstLevel ? 'jpg' : 'png'

  return {
    brand: product.brand.code,
    originalBrand: product.originalBrand,
    moco: product.moco,
    colorhas360: product.colorhas360,
    colorhasPi20: product.colorhasPi20,
    colorhasPi21: product.colorhasPi21,
    materialCode: product.model,
    format,
    isFirstLevel,
  }
}

export const noStoreLogo = (storeLogo: StoreLogo): boolean => {
  return Object.values(storeLogo).every(v => !v)
}

export const getDressedOnImage = (
  config: AppConfig,
  videoUUID: string,
  UPC: string,
  size = '480f'
) => {
  const url = `${config.dressedOnImageService}s/${size}/v/${videoUUID}/u/${UPC}/pi/7/np/0`

  return new Promise<string>(resolve => {
    const img = new Image()
    img.src = url
    img.onload = () => resolve(url)
    img.onerror = () => resolve('')
  })
}
