import { ProgressBar } from 'components/ProgressBar'
import { landscape, tablet } from 'libs/media'
import { clampValues, pxToRem } from 'libs/styled'
import styled, { css } from 'styled-components'

const sliderWrapperStyle = css`
  display: flex;
  width: 100%;
  padding: 0 ${pxToRem(108)};
  ${tablet(`padding: 0 ${clampValues(72, 108)};`)}
`

export const MultiSlidesWrapper = styled.div<{ isCentered?: boolean; gutter?: number }>`
  ${sliderWrapperStyle};
  ${({ isCentered }) =>
    landscape(`
      justify-content: ${isCentered ? 'center' : 'flex-start'}
      height: 100%;
  `)}
  overflow-x: auto;
  & > * {
    flex-shrink: 0;
  }
  & > * + * {
    margin-left: ${({ gutter = 40 }) => pxToRem(gutter)};
  }
`

export const SingleSlideWrapper = styled.div<{ isCentered?: boolean }>`
  ${sliderWrapperStyle};
  justify-content: ${({ isCentered = true }) => (isCentered ? 'center' : 'flex-start')};
`

export const SliderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const mediaElement = css`
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const VideoStyled = styled.video`
  ${mediaElement}
  object-position: 0 20%;
`

export const ContentImageStyled = styled.img`
  ${mediaElement}
`
export const ContentProgressbar = styled(ProgressBar)`
  width: 50vw;
  bottom: ${pxToRem(33)};
  ${landscape(`
    width: 37.73vw;
  `)}
`

export const SlideContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
