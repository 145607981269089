import { Product } from 'types/product'
import { usePriceFormatter } from './usePriceFormatter'

export const useProductPrices = () => {
  const priceFormatter = usePriceFormatter()

  const getPrice = (product: Product, operator: Math['max'] | Math['min']): string => {
    const { variants, price } = product
    const currency = price ? price.currency : null
    const variantPrices = variants
      ? variants.map(element => {
          const price = element ? element.price : null
          return price ? price.current.value : 0
        })
      : []
    const rangePrices = variantPrices.concat(price ? price.current.value : 0)

    return currency ? priceFormatter(operator(...rangePrices), currency) : '0'
  }

  const getHighestPrice = (product: Product): string => {
    return getPrice(product, Math.max)
  }

  const getLowestPrice = (product: Product): string => {
    return getPrice(product, Math.min)
  }

  const getRangePrices = (product: Product): string => {
    const min = getLowestPrice(product)
    const max = getHighestPrice(product)
    if (min !== max) {
      return `${min} - ${max}`
    } else {
      return min
    }
  }

  const getTotalPrice = (product: Product, quantity = 1) => product.price.current.value * quantity

  return {
    getPrice,
    getHighestPrice,
    getLowestPrice,
    getRangePrices,
    getTotalPrice,
  }
}
