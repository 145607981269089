import { ColorSwatch, ColorSwatchWrapper } from './ColorSwatch'
import { ColorImage } from './styles'
import {
  undertoneIcons,
  hairColorValues,
  eyeColorValues,
  getframeShapeIcon,
  getMetalsIcon,
  getPlasticsIcons,
  getColorIcon,
} from './consts'

export {
  ColorSwatch,
  ColorImage,
  undertoneIcons,
  hairColorValues,
  eyeColorValues,
  ColorSwatchWrapper,
  getframeShapeIcon,
  getMetalsIcon,
  getPlasticsIcons,
  getColorIcon,
}
