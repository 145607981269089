import { useTimer } from 'hooks/useIdle'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useRefValue } from 'hooks/useRefValue'
import { debounce } from 'lodash'
import React, { SyntheticEvent, useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'

export type ResetContextValue = {
  userInteractedAfterReset: boolean
  reset: () => void
}

export const defaultResetContext: ResetContextValue = {
  userInteractedAfterReset: false,
  reset: () => null,
}

export const ResetContext = React.createContext(defaultResetContext)

export const useReset = (): ResetContextValue => useContext(ResetContext)

export const ResetWrapper = styled.div`
  display: block;
  height: 100%;
`

export type ResetProviderProps = {
  onReset: () => void
  timeoutMs: number
  resetKeyPrefix?: string
  shouldWaitForUserInteractionBeforeReset?: boolean
}

const USER_INTERACTION_EVENT_DEBOUNCE_TIMEOUT_MS = 300

export const ResetProvider: React.FC<ResetProviderProps> = ({
  children,
  onReset,
  timeoutMs,
  resetKeyPrefix = '',
  shouldWaitForUserInteractionBeforeReset = false,
}) => {
  const [userInteractedAfterReset, setUserInteractedAfterReset] = useLocalStorage(
    [resetKeyPrefix, 'has-user-interacted'].join('-'),
    false
  )

  const shouldResetRef = useRefValue(
    !shouldWaitForUserInteractionBeforeReset || userInteractedAfterReset
  )

  const reset = useCallback(() => {
    if (shouldResetRef.current) {
      setUserInteractedAfterReset(false)
      onReset()
    }
  }, [onReset, setUserInteractedAfterReset, shouldResetRef])

  const resetTimer = useTimer(reset, timeoutMs)

  const userInteraction = useCallback(
    debounce(() => {
      setUserInteractedAfterReset(true)
      resetTimer()
    }, USER_INTERACTION_EVENT_DEBOUNCE_TIMEOUT_MS),
    [resetTimer, setUserInteractedAfterReset]
  )

  const handleUserInteraction = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      if ((e.target as HTMLDivElement)?.hasAttribute('data-reset')) return
      userInteraction()
    },
    [userInteraction]
  )

  const contextValue = useMemo(
    () => ({
      userInteractedAfterReset,
      reset,
    }),
    [reset, userInteractedAfterReset]
  )

  return (
    <ResetContext.Provider value={contextValue}>
      <ResetWrapper
        onClick={handleUserInteraction}
        onMouseMove={handleUserInteraction}
        onTouchMove={handleUserInteraction}
        onTouchEnd={handleUserInteraction}
        onKeyUp={handleUserInteraction}
      >
        {children}
      </ResetWrapper>
    </ResetContext.Provider>
  )
}
