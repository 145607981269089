import React from 'react'
import { Loading } from 'components/Loading'
import styled from 'styled-components'

const PageLoading = styled.div<{ fullscreen: boolean }>`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  ${({ fullscreen }) => fullscreen && 'min-height: 100vmax;'}
`

export const LoadingPage: React.FC<{ loading: boolean; fullscreen?: boolean }> = ({
  children,
  loading,
  fullscreen = true,
}) => {
  return (
    <>
      {children}
      {loading && (
        <PageLoading fullscreen={fullscreen}>
          <Loading />
        </PageLoading>
      )}
    </>
  )
}
