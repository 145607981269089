import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import { KeyboardedInput } from './index'
import {
  ClickHandler,
  VKContextInterface,
  VKeyboardProps,
  VKeyboardProviderProps,
  VKeyboardSettings,
} from 'types/touch-keyboard'

type VKeyboardEventProp = {
  stopFocusInPropagation: boolean
  stopFocusOutPropagation: boolean
}
type VKeyboardFocusEvent<K> = React.FocusEvent<K> & VKeyboardEventProp
type VKeyboardMouseEvent<K> = React.MouseEvent<K, MouseEvent> & VKeyboardEventProp

const isBlurOrMouseEvent = <T extends Element>(
  e: React.SyntheticEvent<T, unknown>
): e is VKeyboardFocusEvent<T> | VKeyboardMouseEvent<T> => 'blur' === e.type || 'click' === e.type

const AVAILABLE_TYPES = ['text', 'password', 'tel', 'number', 'search']

const VKContext = React.createContext<VKContextInterface | null>(null)
const defaultSettings = {
  hideKeyboardLabel: 'Close',
}

export const VKeyboardProvider: React.FC<VKeyboardProviderProps> = function (props) {
  const [show, setShow] = useState(false)
  const [srcElement, setSrcElement] = useState<HTMLInputElement | null>(null)
  const [submitHandlerRef, setSubmitHandlerRef] = useState<
    React.MutableRefObject<ClickHandler | undefined>
  >(useRef(props.submitClicked))
  const [settings, setSettings] = useState<VKeyboardSettings>({
    ...defaultSettings,
    ...props.defaultSettings,
  })

  const keyboardRef = useRef<HTMLDivElement>(null)

  const showKeyboard = useCallback(
    (showKeyboard: boolean, newSettings = {}) => {
      const keyboardSettings = { ...defaultSettings, ...props.defaultSettings, ...newSettings }
      if (showKeyboard !== show) {
        setShow(showKeyboard)
      }
      if (!isEqual(keyboardSettings, settings)) {
        setSettings(keyboardSettings)
      }
    },
    [setShow, show, settings, props.defaultSettings]
  )

  const setInput = (targetElement: HTMLInputElement) => {
    setSrcElement(targetElement)
  }

  useEffect(() => {
    if (props.forceKeybordHide) {
      showKeyboard(false)
    }
  }, [props.forceKeybordHide, showKeyboard])

  return (
    <VKContext.Provider
      value={{ ...settings, showKeyboard, keyboardRef, setInput, setSubmitHandlerRef }}
    >
      <VKeyboard
        settings={props.defaultSettings}
        submitClicked={props.submitClicked}
        onShowChanged={props.onShowChanged}
        disabled={props.disabled}
      >
        {props.children}
      </VKeyboard>
      <KeyboardedInput
        show={show}
        ref={keyboardRef}
        inputNode={srcElement}
        bounds={props.bounds}
        submitClicked={value => {
          const submitHandler = submitHandlerRef.current
          submitHandler && submitHandler(value)
          setShow(false)
        }}
        {...settings}
      />
    </VKContext.Provider>
  )
}

export const VKeyboard: React.FC<VKeyboardProps> = ({
  children,
  settings,
  submitClicked,
  disabled,
  onShowChanged,
}) => {
  const ref = useRef(null)

  const { showKeyboard, keyboardRef, setInput, setSubmitHandlerRef } = useContext(
    VKContext
  ) as VKContextInterface
  const submitCallbackRef = useRef(submitClicked)
  submitCallbackRef.current = submitClicked

  const isInputText = useCallback((el: HTMLElement | HTMLInputElement): el is HTMLInputElement => {
    if (el && el.tagName === 'INPUT' && 'type' in el) {
      return AVAILABLE_TYPES.includes(el.type.toLocaleLowerCase())
    }
    return false
  }, [])

  const isTextArea = useCallback(
    (el: HTMLElement): el is HTMLInputElement => !!el && el.tagName === 'TEXTAREA',
    []
  )

  const isKeyboardChild = useCallback(
    (el: HTMLElement): boolean =>
      keyboardRef && !!keyboardRef.current && keyboardRef.current.contains(el),
    [keyboardRef]
  )

  const handleShowKeyboard = useCallback(
    (event: VKeyboardFocusEvent<HTMLElement> | VKeyboardMouseEvent<HTMLElement>) => {
      const { target } = event
      const show =
        !disabled &&
        (isInputText(target as HTMLElement) ||
          isTextArea(target as HTMLElement) ||
          isKeyboardChild(target as HTMLElement))

      showKeyboard(show, settings)
      onShowChanged && onShowChanged(show)
    },
    [isInputText, isTextArea, isKeyboardChild, disabled, onShowChanged, showKeyboard, settings]
  )

  // we show keyboard if user press on input type text or on keyboard
  const onFocusHandler = useCallback(
    (event: VKeyboardFocusEvent<HTMLDivElement>) => {
      event.persist()
      if (event.stopFocusInPropagation) return
      const { target } = event

      if (isKeyboardChild(target)) return
      if (!(isInputText(target) || isTextArea(target))) return

      setSubmitHandlerRef(submitCallbackRef)
      setInput(target as HTMLInputElement)
      handleShowKeyboard(event)

      event.stopFocusInPropagation = true
    },
    [isKeyboardChild, handleShowKeyboard, setInput, setSubmitHandlerRef, isInputText, isTextArea]
  )

  // we hide keyboard if user click outside input type text or outside keyboard
  const onBlurOrClickHandler = useCallback(
    (event: React.SyntheticEvent<HTMLElement, MouseEvent | FocusEvent>) => {
      event.persist()
      if (!isBlurOrMouseEvent(event) || disabled) return
      if (event.stopFocusOutPropagation) return

      handleShowKeyboard(event)
      event.stopFocusOutPropagation = true
    },
    [disabled, handleShowKeyboard]
  )

  return (
    <div
      className="vk-panel"
      ref={ref}
      onFocus={onFocusHandler}
      onBlur={onBlurOrClickHandler}
      onClick={onBlurOrClickHandler}
    >
      {children}
    </div>
  )
}
