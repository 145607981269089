import { landscape, tablet } from 'libs/media'
import { clampValues, pxToRem } from 'libs/styled'
import styled from 'styled-components'
import { ListItemContainer } from 'components/core/List/ListItem'
import { Heading4 } from 'components/Typography'

export const PDPPanelContainer = styled.div<{ buttonsAboveTitle?: boolean }>`
  width: 100%;
  height: 100%;
  padding: ${pxToRem(411)} ${pxToRem(34)} 0 ${pxToRem(30)};
  ${landscape(`
    padding-top: ${pxToRem(164)}
  `)}
  ${tablet(`
    padding: ${pxToRem(144)}  ${clampValues(24, 32)} 0;
  `)}

  .panelHeaderCloseButton {
    margin: 0;
    z-index: 9999 ${tablet(`
      position: absolute;
      top: ${pxToRem(42)};
      right: ${pxToRem(35)};
    `)};
  }
  ${({ buttonsAboveTitle }) =>
    buttonsAboveTitle &&
    `
      ${ListItemContainer} {
        position: relative;
        ${tablet('position: static;')}
      }
      .panelHeaderBackButton {
        position: absolute;
        top: -${pxToRem(60)};
        left: 0;
        ${tablet(`
          top: ${pxToRem(42)};
          left: ${pxToRem(35)};
        `)}
      }
      .panelHeaderCloseButton {
        position: absolute;
        top: -${pxToRem(60)};
        right: 0;
        ${tablet(`
          top: ${pxToRem(42)};
          right: ${pxToRem(35)};
        `)}
      }
      `}
`
export const PanelTitle = styled(Heading4)``
export const TitleCounter = styled(Heading4)`
  display: inline;
  margin-left: ${pxToRem(8)};
`
export const PanelContent = styled.div`
  margin-top: ${pxToRem(32)};
  padding-bottom: ${pxToRem(40)};
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`
