import * as React from 'react'
import styled from 'styled-components'
import { CartItem } from 'types/cart'
import { MinicartProductCard } from '../CardProduct/MinicartProductCard'

const MinicartItemWrapper = styled.div`
  margin-bottom: 0.727rem;
  display: flex;
  flex-direction: column;
  .ant-row:nth-child(2) {
    order: -1;
  }
`

interface MinicartItemProps {
  cartItem: CartItem
  removeItemFromCart?: (productId: string) => void
  showPrice: boolean

  showRTRIcon: boolean
}

const MinicartItem = ({
  removeItemFromCart,
  cartItem,
  showPrice,

  showRTRIcon,
}: MinicartItemProps) => {
  const { product } = cartItem

  return (
    <MinicartItemWrapper
      data-test={`context-${product.moco}`}
      id={`minicart-item-${cartItem.product.UPC}`}
    >
      <MinicartProductCard
        cartItem={cartItem}
        handleRemoveButtonClick={() => removeItemFromCart(cartItem.id)}
        showPrice={showPrice}
        showRTRIcon={showRTRIcon}
      />
    </MinicartItemWrapper>
  )
}

export default MinicartItem
