import React from 'react'
import { IconProps } from 'types/icons'

export const PhantosIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 19"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M37 0C31.446 0 27.981 1.563 26.639 4.648C24.992 3.869 23.007 3.869 21.361 4.648C20.019 1.563 16.554 0 11 0C3.701 0 0 2.691 0 8C0 14.785 4.215 19 11 19C17.065 19 22 14.065 22 8C22 7.514 21.958 7.057 21.896 6.615C23.161 5.848 24.839 5.848 26.104 6.615C26.042 7.057 26 7.514 26 8C26 14.065 30.935 19 37 19C43.785 19 48 14.785 48 8C48 2.691 44.299 0 37 0ZM11 17C2.877 17 2 10.706 2 8C2 5.207 3.023 2 11 2C18.977 2 20 5.207 20 8C20 12.962 15.962 17 11 17ZM37 17C32.038 17 28 12.962 28 8C28 5.207 29.023 2 37 2C44.977 2 46 5.207 46 8C46 10.706 45.123 17 37 17Z"
        fill="#333333"
      />
    </svg>
  )
}
