import React from 'react'
import { IconProps } from 'types/icons'
interface VTOIconProps extends IconProps {
  src?: string
}

export const VTOIcon: React.FC<VTOIconProps> = ({
  width = '100%',
  height = '100%',
  src = '/assets/vto.png',
}) => {
  return <img src={src} alt="vto" width={width} height={height} />
}
