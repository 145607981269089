import {
  BlackMetalsIcon,
  BronzeMetalsIcon,
  ButterflyIcon,
  CatIcon,
  GeometricalIcon,
  GoldMetalsIcon,
  GunMetalsIcon,
  IrregularIcon,
  OvalIcon,
  PhantosIcon,
  PillowIcon,
  PilotIcon,
  RectangleIcon,
  RoseMetalsIcon,
  RoundIcon,
  SilverMetalsIcon,
  SquareIcon,
  UndertoneBlueIcon,
  UndertoneCoolIcon,
  UndertoneGreenIcon,
  UndertoneWarmIcon,
} from 'components/core/Icons'
import config from 'config'
import { pxToRem } from 'libs/styled'
import React from 'react'
import { theme } from 'theme'
import { EyeColor, HairColor } from 'types/frameAdvisor'
import { IconProps } from 'types/icons'
import { ColorImage } from './styles'

export const undertoneValues = {
  warm: 'warm',
  cool: 'cool',
  gold: 'gold',
  silver: 'silver',
  blue: 'blue',
  green: 'green',
}

export const undertoneIcons = {
  [undertoneValues.warm]: <UndertoneWarmIcon />,
  [undertoneValues.cool]: <UndertoneCoolIcon />,
  [undertoneValues.gold]: <ColorImage src={`${config.assets.URL}/frameAdvisor/gold.png`} />,
  [undertoneValues.silver]: <ColorImage src={`${config.assets.URL}/frameAdvisor/silver.png`} />,
  [undertoneValues.blue]: <UndertoneBlueIcon />,
  [undertoneValues.green]: <UndertoneGreenIcon />,
}

export const categoriesValues = {
  undertone: [undertoneValues.warm, undertoneValues.cool],
  veins_color: [undertoneValues.green, undertoneValues.blue],
  jewelry_preference: [undertoneValues.gold, undertoneValues.silver],
}

export const hairColorValues: HairColor[] = [
  HairColor.black,
  HairColor.brown,
  HairColor.blonde,
  HairColor.red,
  HairColor.grey,
  HairColor.othercolors,
  HairColor.bald,
]

const hairColorImageMap: { [key in HairColor]?: string } = {
  [HairColor.black]: `${config.assets.URL}/frameAdvisor/hair_color-black.png`,
  [HairColor.brown]: `${config.assets.URL}/frameAdvisor/hair_color-brown.png`,
  [HairColor.blonde]: `${config.assets.URL}/frameAdvisor/hair_color-blonde.png`,
  [HairColor.red]: `${config.assets.URL}/frameAdvisor/hair_color-red.png`,
  [HairColor.grey]: `${config.assets.URL}/frameAdvisor/hair_color-gray.png`,
  [HairColor.othercolors]: `${config.assets.URL}/frameAdvisor/hair_color-othercolors.png`,
  [HairColor.other]: `${config.assets.URL}/frameAdvisor/hair_color-other.png`,
}

export const eyeColorValues: EyeColor[] = [
  EyeColor.green,
  EyeColor.blue,
  EyeColor.brown,
  EyeColor.other,
]

const eyeColorImageMap: { [key in EyeColor]?: string } = {
  [EyeColor.blue]: `${config.assets.URL}/frameAdvisor/iris_color-blue.png`,
  [EyeColor.green]: `${config.assets.URL}/frameAdvisor/iris_color-green.png`,
  [EyeColor.brown]: `${config.assets.URL}/frameAdvisor/iris_color-brown.png`,
  [EyeColor.other]: `${config.assets.URL}/frameAdvisor/iris_color-other.png`,
}

const lensTreatmentColors = {
  gradient: `${config.assets.URL}/frameAdvisor/gradient.png`,
  mirror: `${config.assets.URL}/frameAdvisor/mirror.png`,
  solid: `${config.assets.URL}/frameAdvisor/solid.png`,
}

type ColorCategory = 'lense' | 'eye' | 'hair'

export const getColorIcon = (category: ColorCategory, name: string) => {
  let list = {}
  switch (category) {
    case 'eye':
      list = eyeColorImageMap
      break
    case 'hair':
      list = hairColorImageMap
      break
    case 'lense':
      list = lensTreatmentColors
      break
  }

  let src = list[name]

  // handle fallbacks, this is to avoid to map all the possible values from the CMS on the UI
  // for now since in all the cases we have to fallback on "other" for all the categoryes we only use the default case
  if (!src) {
    switch (category) {
      default:
        src = list['other']
        break
    }
  }

  return <ColorImage src={src} key={name} />
}

const metalsIconsMap = {
  black_metals: BlackMetalsIcon,
  bronze_metals: BronzeMetalsIcon,
  gold_metals: GoldMetalsIcon,
  gun_metals: GunMetalsIcon,
  rose_metals: RoseMetalsIcon,
  silver_metals: SilverMetalsIcon,
}

export const getMetalsIcon = (name: string) => {
  const Icon = metalsIconsMap[name]
  return <Icon width="100%" height="100%" />
}

const plasticIconsMap = {
  black_plastics: [
    `${config.assets.URL}/frameAdvisor/black-plastics_1.png`,
    `${config.assets.URL}/frameAdvisor/black-plastics_2.png`,
    `${config.assets.URL}/frameAdvisor/black-plastics_3.png`,
  ],
  tortoise_plastics: [
    `${config.assets.URL}/frameAdvisor/tortoise-plastics_1.png`,
    `${config.assets.URL}/frameAdvisor/tortoise-plastics_2.png`,
    `${config.assets.URL}/frameAdvisor/tortoise-plastics_3.png`,
  ],
}

export const getPlasticsIcons = (name: string) => {
  const srcList = plasticIconsMap[name]
  return srcList.map(src => <ColorImage src={src} key={src} />)
}

const frameShapeIconsMap: Record<string, React.FC<IconProps>> = {
  round: RoundIcon,
  oval: OvalIcon,
  cateye: CatIcon,
  phantos: PhantosIcon,
  pillow: PillowIcon,
  pilot: PilotIcon,
  butterfly: ButterflyIcon,
  rectangle: RectangleIcon,
  squared: SquareIcon,
  irregular: IrregularIcon,
  geometrical: GeometricalIcon,
}

export const getframeShapeIcon = (name: string) => {
  const Icon = frameShapeIconsMap[name]
  return <Icon width={pxToRem(48)} stroke="none" fill={theme.colors.primaryText} height="100%" />
}
