import { useCallback, useEffect, useRef } from 'react'
import { useRefValue } from './useRefValue'

const getFutureDateMilliseconds = (milliseconds: number) => {
  return Date.now() + milliseconds
}

export const useListener = (
  event: string,
  handler: () => void,
  deps = [],
  target: EventTarget = window
): void => {
  useEffect(() => {
    target.addEventListener(event, handler)

    return () => target.removeEventListener(event, handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, handler, ...deps])
}

export const useUserInteraction = (handler: () => void, deps = []): void => {
  useListener('click', handler, deps)
  useListener('mousemove', handler, deps)
  useListener('mouseup', handler, deps)
  useListener('touchend', handler, deps)
  useListener('touchmove', handler, deps)
  useListener('keypress', handler, deps)
  useListener('scroll', handler, deps)
}

export const useTimer = (cb: () => void, timeout: number): (() => void) => {
  const t = useRef<number>()
  const startIdleTimeMilliseconds = useRef<number>(getFutureDateMilliseconds(timeout))
  const cbRef = useRefValue(cb)

  const resetTimer = useCallback(() => {
    clearInterval(t.current)
    // if timeout is 0 do nothing
    if (timeout <= 0) return
    // reset target start idle time
    startIdleTimeMilliseconds.current = getFutureDateMilliseconds(timeout)

    t.current = window.setInterval(() => {
      if (Date.now() > startIdleTimeMilliseconds.current) {
        clearInterval(t.current)
        cbRef.current()
      }
    }, 100) //check 10 times a second if the timer is elapsed
  }, [cbRef, timeout])

  useEffect(() => {
    resetTimer()
    return () => {
      clearInterval(t.current)
    }
  }, [resetTimer])

  return resetTimer
}

export const useIdle = (cb: () => void, timeout: number): (() => void) => {
  const resetTimer = useTimer(cb, timeout)

  useUserInteraction(resetTimer)
  return resetTimer
}
