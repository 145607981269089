import React from 'react'
import { IconProps } from 'types/icons'
export const FaFaceShapeHeartManIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 43 48"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M42.435 22.989C41.927 22.207 40.878 21.3 38.75 21.062L38.992 19.123L39 17C39 12.794 37.456 8.947 34.916 5.976C36.201 4.706 37 2.945 37 1V0H19C16.57 0 14.544 1.744 14.096 4.046C9.00497 4.505 4.99997 8.791 4.99997 14V19L5.25297 21.036C2.88697 21.208 1.74497 22.166 1.20897 22.99C0.504968 24.073 0.459968 25.5 1.08597 26.907L1.31997 27.441C2.25497 29.577 3.31297 32 5.99997 32C6.21997 32 6.43497 31.979 6.64797 31.948C7.13897 34.483 8.30697 36.813 10.065 38.732L16.235 45.463C17.713 47.076 19.814 48 22 48C24.186 48 26.287 47.076 27.765 45.464L33.935 38.733C35.686 36.823 36.852 34.505 37.346 31.983C37.446 31.99 37.544 32 37.645 32C40.332 32 41.39 29.577 42.324 27.44L42.559 26.906C43.184 25.499 43.139 24.072 42.435 22.989ZM32.46 37.381L26.29 44.112C25.19 45.312 23.627 46 22 46C20.373 46 18.81 45.312 17.71 44.113L11.54 37.382C9.84797 35.536 8.78897 33.246 8.47797 30.762L6.99997 18.938V18C13.821 18 16.043 14.406 16.722 11.481C18.522 13.08 22.116 15 29 15C36.029 15 36.982 17.1 36.998 18.951L35.522 30.761C35.211 33.246 34.153 35.535 32.46 37.381ZM34.899 2C34.864 2.17 34.804 2.33 34.753 2.494C34.593 3.003 34.356 3.473 34.052 3.897C33.144 5.163 31.673 6 30 6V8C31.194 8 32.318 7.698 33.302 7.169C35.116 9.252 36.358 11.836 36.802 14.685C34.381 13.011 30.616 13 29 13C18.948 13 16.942 8.659 16.929 8.628L15 9C15 12.193 13.612 16 6.99997 16V14C6.99997 9.589 10.589 6 15 6H16V5C16 3.346 17.346 2 19 2H34.899ZM3.15297 26.639L2.91397 26.094C2.71097 25.638 2.43797 24.769 2.88597 24.079C3.28797 23.461 4.20797 23.091 5.49897 23.016L6.36197 29.957C6.24197 29.974 6.12397 30 5.99997 30C4.68897 30 4.06397 28.723 3.15297 26.639ZM40.731 26.094L40.491 26.639C39.581 28.723 38.956 30 37.645 30C37.641 30 37.637 29.999 37.633 29.999L38.502 23.048C39.604 23.167 40.395 23.52 40.759 24.079C41.207 24.769 40.934 25.638 40.731 26.094Z"
        fill={fill}
      />
      <path d="M24.013 36H19.986V38H24.013V36Z" fill={fill} />
      <path d="M22.434 31H19.986V33H24.434V23.057H22.434V31Z" fill={fill} />
    </svg>
  )
}
