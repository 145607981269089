import { FiltersActionTypes, SET_AVAILABLE_IN_STORE } from 'actions/filters'
import { ProductTypeValues } from 'types/filter'

export interface FiltersState {
  availableInStore: Record<string, boolean>
}

const initialState: FiltersState = {
  availableInStore: {
    [ProductTypeValues.SUN]: undefined,
    [ProductTypeValues.OPTICAL]: undefined,
  },
}

export default (state = initialState, action: FiltersActionTypes) => {
  switch (action.type) {
    case SET_AVAILABLE_IN_STORE: {
      const { productType, status } = action.payload
      return {
        ...state,
        availableInStore: {
          ...state.availableInStore,
          [productType]: status,
        },
      }
    }
    default:
      return state
  }
}
