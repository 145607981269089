import digitalCouvetteReferenceGlasses from 'assets/digitalCouvetteReferenceGlasses.svg'
import { Heading5, LinkTypography } from 'components/Typography'
import { clampValues, flexGapPolyfill, pxToRem } from 'libs/styled'
import styled from 'styled-components'
import { DigitalCouvetteSizeWidths } from '../types'

export const CouvetteScaleHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${pxToRem(48)} ${pxToRem(96)} ${pxToRem(40)};
`

export const CouvetteTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${pxToRem(24)};
  h2 {
    display: inline-block;
  }
  h5 {
    text-align: left;
  }
`

export const CouvetteScaleDescription = styled(Heading5)`
  text-align: left;
`

export const CouvetteRestart = styled(LinkTypography)`
  width: ${pxToRem(236)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${pxToRem(8)};
`

export const SizeList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${pxToRem(443)};
  margin: ${pxToRem(10)} 0 ${pxToRem(60)};
`

export const SizeCta = styled.div<{ selected: boolean }>`
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};
  text-transform: uppercase;
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.cta.textColor : theme.cta.secondaryTextColor)};
  background-color: ${({ theme, selected }) =>
    selected ? theme.cta.backgroundColor : 'transparent'};
  color: ${({ theme, selected }) =>
    selected ? theme.cta.textColor : theme.cta.secondaryTextColor};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'unset')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: ${pxToRem(4)};
  &:after {
    content: ${({ selected }) => (selected ? 'attr(data-measure)' : '')};
    color: ${({ theme }) => theme.cta.secondaryTextColor};
    position: absolute;
    bottom: ${pxToRem(-36)};
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  }
`

export const CouvetteScaleContainer = styled.div<{ sizes: DigitalCouvetteSizeWidths }>`
  display: grid;
  position: relative;
  height: ${pxToRem(295)};
  grid-template-columns: ${({ sizes }) => {
    const { XXS, XS, S, M, L, XL, XXL } = sizes
    return `${XXS.min - 1}px ${XXS.max - XXS.min}px ${XS.max - XS.min}px ${S.max - S.min}px ${
      M.max - M.min
    }px ${L.max - L.min}px ${XL.max - XL.min}px ${XXL.max - XXL.min}px;`
  }};
  width: ${({ sizes: { XXL } }) => `${XXL.max}px`};
  margin: 0 auto;
  border-top: ${({ theme }) => `1px solid ${theme.colors.mercury}`};
  padding-top: 16px;
`

export const CouvetteGuideLine = styled.div<{ size: string; width: string }>`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right, #00000060 40%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 10px 3px;
  background-repeat: repeat-x;
  &:before {
    height: 100%;
    position: absolute;
    inset: 0;
    width: ${({ width }) => width};
    background: ${({ theme }) => theme.cta.backgroundColor};
    content: '';
  }
  &:after {
    content: ${({ size }) => (size ? 'attr(data-measure)' : '')};
    position: absolute;
    width: ${({ width }) => width};
    height: 100%;
    inset: 0;
    color: ${({ theme }) => theme.cta.backgroundColor};
    margin-top: ${pxToRem(8)};
  }
  svg {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 0;
  }
`

export const CouvetteScaleColumn = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: flex-start;
  padding-top: ${pxToRem(18)};
  justify-content: center;
  &:nth-of-type(1) {
    border-radius: ${pxToRem(16)} 0 0 ${pxToRem(16)};
  }
  &:nth-of-type(8) {
    border-radius: 0 ${pxToRem(16)} ${pxToRem(16)} 0;
  }
  &:nth-child(odd) {
    background-color: ${({ theme, selected }) =>
      selected ? `${theme.cta.backgroundColor}14` : theme.colors.mercury};
  }
  &:nth-child(even) {
    background-color: ${({ theme, selected }) =>
      selected ? `${theme.cta.backgroundColor}14` : theme.colors.wildSand};
  }
  > span {
    transform: rotate(90deg);
    text-transform: uppercase;
  }
`

export const CouvetteScaleDetailsTitleContainer = styled.div<{ width: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ width }) => width};
  margin: 0 auto ${pxToRem(16)};
`
export const CouvetteScaleDetailsTitle = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  row-gap: ${pxToRem(16)};
  gap: ${pxToRem(16)};
  ${flexGapPolyfill(pxToRem(8))}
  justify-content: space-evenly;
  svg.open {
    rotate: ${({ isOpen }) => (isOpen ? '180deg' : 'unset')};
  }
`

export const CouvetteScaleDetailsContent = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  row-gap: ${pxToRem(24)};
  gap: ${pxToRem(24)};
  ${flexGapPolyfill(pxToRem(12), 'row')};
  margin: ${pxToRem(16)} 0;
`

export const SizeListDescriptionText = styled.div`
  display: block;
  margin: ${clampValues(32, 40)} auto 0;
`

export const ReferenceGlassContainer = styled.div<{ width: string }>`
  position: absolute;
  // 36 is the left position offset
  width: ${({ width }) => `calc(${width} + 36px)`};
  height: ${pxToRem(295)};
  background-image: ${`url(${digitalCouvetteReferenceGlasses})`};
  background-size: 100%;
  background-position: 0 36px;
  background-repeat: no-repeat;
  top: ${pxToRem(24)};
  left: -${pxToRem(36)};
`

export const CouvetteScaleDetailsReferenceContainer = styled.div`
  display: flex;
  align-items: center;
  row-gap: ${pxToRem(16)};
  gap: ${pxToRem(16)};
  ${flexGapPolyfill(pxToRem(8))}
`

export const CouvetteDetailCard = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.mercury}`};
  border-radius: ${pxToRem(8)};
  display: flex;
  flex-direction: column;
  max-width: ${pxToRem(248)};
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(18)};
  min-width: ${pxToRem(248)};
  min-height: ${pxToRem(100)};
`
