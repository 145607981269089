import React from 'react'
import { MenuItemButtonStyled, MenuItemIcon } from './styles'
import { LinkWithCloseDrawer } from 'components/core/LinkWithCloseDrawer'
import { Heading4 } from 'components/Typography'

interface MenuItemButtonProps {
  label: React.ReactNode
  icon?: React.ReactNode
  onClick?: (event: React.MouseEvent) => void
  dataAttr?: string
}

export const MenuItemButton: React.FC<MenuItemButtonProps> = ({
  label,
  icon,
  onClick,
  dataAttr,
}) => {
  return (
    <MenuItemButtonStyled data-element-id={dataAttr} type="button" onClick={onClick}>
      <MenuItemIcon>{icon && icon}</MenuItemIcon> <Heading4>{label}</Heading4>
    </MenuItemButtonStyled>
  )
}

interface MenuItemLinkProps {
  to: string
}

export const MenuItemLink: React.FC<MenuItemButtonProps & MenuItemLinkProps> = ({
  label,
  icon,
  to,
  dataAttr,
}) => {
  return (
    <MenuItemButtonStyled
      as={LinkWithCloseDrawer}
      to={to}
      data-element-id={dataAttr}
      data-description=" "
    >
      <MenuItemIcon>{icon}</MenuItemIcon>
      <Heading4>{label}</Heading4>
    </MenuItemButtonStyled>
  )
}
