import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Product } from 'types/product'
import { addItemToCart } from '../../../actions/cart'
import { removeItemFromWishlist } from '../../../actions/wishlist'
import { useSendAnalyticsAddToCartEvent } from '../../../containers/AnalyticsProvider'
import { useActions } from '../../../hooks/useActions'
import { useStoreIndentity } from '../../../hooks/useStoreIdentity'
import { useGetDataDescriptionForProduct } from '../../../libs/analytics'
import { WishlistAddToCart } from '../../Wishlist/styles'

interface ProductAddBuyButtonProps {
  product: Product
  index?: number
}

export const ProductAddBuyButton: React.FC<ProductAddBuyButtonProps> = ({ product, index }) => {
  const { t } = useTranslation()
  const getDataDescriptionForProduct = useGetDataDescriptionForProduct()
  const { storeId } = useStoreIndentity()
  const { addToCart, removeFromWishlist } = useActions({
    addToCart: addItemToCart,
    removeFromWishlist: removeItemFromWishlist,
  })

  const { sendAddToCartEvent } = useSendAnalyticsAddToCartEvent({})

  const handleClickToCart = useCallback(
    (product: Product) => {
      addToCart({ product, storeId: storeId || '' })
      sendAddToCartEvent(product)
      removeFromWishlist(product)
    },
    [addToCart, sendAddToCartEvent, storeId, removeFromWishlist]
  )
  const analyticsDataDescription = getDataDescriptionForProduct(product)
  return (
    <WishlistAddToCart
      data-element-id={`Tiles_${index}-AddCart`}
      data-description={analyticsDataDescription}
      onClick={() => handleClickToCart(product)}
    >
      {t('ProductView.add')}
    </WishlistAddToCart>
  )
}
