export type Size = 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL'

export const DIGITAL_COUVETTE_SIZES: Record<Size, string> = {
  XXS: '101-120',
  XS: '121-124',
  S: '125-128',
  M: '129-132',
  L: '133-136',
  XL: '137-140',
  XXL: '141-160',
}

export type DigitalCouvetteSizeWidths = {
  [key in Size]: {
    min: number
    max: number
  }
}

export interface Frame {
  model: string
  brand: string
  modelName: string
  hingeToHinge: number
  caliber: string
  bridge: string
}

export enum CalibrationMethods {
  frame = 'frame',
  ruler = 'ruler',
}

export enum MeasureUnits {
  Cm = 'Cm',
  Inch = 'Inch',
}

export type MeasureUnit = keyof typeof MeasureUnits
