/* eslint-disable @typescript-eslint/no-explicit-any */
import config from 'config'
import { importScript } from 'libs/importScript'
import { IInitCallbacks } from 'types/RealTimeRenderer'

type ActionName = 'openKioskAppSettings'
export type RTRConfig = {
  locale: string
  id: {
    type: string
    value: string
  }
}
export type RTRViewer = {
  isIdAvailable: (id: RTRConfig['id']) => Promise<boolean>
  dispose: () => Promise<unknown>
  init: (data: { data: RTRConfig; callbacks: IInitCallbacks }) => Promise<unknown>
}

declare global {
  interface Window {
    rtrViewerMV: RTRViewer
    api?: {
      invoke: (name: ActionName) => void
    } // DO NOT REMOVE! It is needed for the kiosk app
    ipc?: {
      send: (type: string, name: ActionName) => void
    } // DO NOT REMOVE! It is needed for the kiosk app
  }
}

export const isRTREnabled = () => {
  return config.toggleFeature.realTimeRenderer
}

export const importRTRScript = () => {
  return importScript('rtrViewerMV', config.rtrJS).then(() => {
    return window.rtrViewerMV
  })
}
