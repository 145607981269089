import React, { useCallback, useEffect, useRef } from 'react'
import { Canvas, ScanReader, Video } from './styles'

export interface BarcodeResult {
  value: string
}

const SCAN_PROID_MS = 500

const scanImageData = (imageData, scannerWorker): Promise<BarcodeResult[]> => {
  return new Promise(res => {
    scannerWorker.onmessage = e => {
      res(e.data.response)
    }
    scannerWorker.postMessage({
      imageData,
    })
  })
}

const init = async ({ video }) => {
  const mediaStream: MediaStream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      facingMode: 'environment',
      width: { max: 640 },
      height: { max: 640 },
    },
  })
  video.srcObject = mediaStream
  video.play()

  setTimeout(() => {
    video.style.objectFit = 'cover'
  }, 1000)
}

const processImage = async ({ video, closeCameraStream, canvas, scannerWorker, onResult }) => {
  const width = video.videoWidth
  const height = video.videoHeight
  if (!width || !height) {
    return
  }
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')
  ctx.drawImage(video, 0, 0, width, height)
  const imgData = ctx.getImageData(0, 0, width, height)
  const codes = await scanImageData(imgData, scannerWorker)
  if (codes.length) {
    onResult(codes[0])
    closeCameraStream(video)
  }
}

const sleep = ms =>
  new Promise(r => {
    setTimeout(r, ms)
  })

const main = async ({ video, closeCameraStream, canvas, scannerWorker, onResult, onError }) => {
  try {
    await init({ video })
    while (true) {
      await processImage({
        video,
        closeCameraStream,
        canvas,
        scannerWorker,
        onResult,
      })
      await sleep(SCAN_PROID_MS)
    }
  } catch (err) {
    closeCameraStream(video)
    onError(err.message)
  }
}

interface CodeScannerProps {
  onResult: (result: BarcodeResult) => void
  onError: (error: string) => void
  isScanned?: boolean
  onLoad?: () => void
}

export const CodeScanner: React.FC<CodeScannerProps> = ({
  onResult,
  onError,
  onLoad,
  isScanned,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef(null)
  const scannerWorkerRef = useRef(null)

  useEffect(() => {
    scannerWorkerRef.current = new Worker('./code-scanner.worker', { type: 'module' })

    return () => scannerWorkerRef.current.terminate()
  }, [])

  const closeCameraStream = useCallback((video: HTMLVideoElement) => {
    const stream = video?.srcObject as MediaStream
    const tracks = stream?.getTracks() || []

    tracks.forEach(o => {
      o?.stop()
    })
    videoRef.current = null
  }, [])

  useEffect(() => {
    const video = videoRef.current
    const canvas = canvasRef.current
    const scannerWorker = scannerWorkerRef.current
    if (!isScanned && video && canvas && scannerWorker) {
      main({
        video,
        closeCameraStream,
        canvas,
        scannerWorker,
        onResult,
        onError,
      })
    }

    return () => {
      closeCameraStream(video)
    }
  }, [onResult, onError, isScanned, closeCameraStream])

  return (
    <ScanReader>
      <Video ref={videoRef} onCanPlay={onLoad} />
      <Canvas ref={canvasRef} />
    </ScanReader>
  )
}
