import * as React from 'react'
import { KeyboardIcon } from '../../../types/touch-keyboard'

const CapsLockIcon = ({
  viewBox = '0 0 24 28',
  width = 19,
  height = 28,
  fill = 'currentColor',
}: KeyboardIcon) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...{ width, height, fill, viewBox }}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M6,6l-6,6H6.15v9.48H18V12.07h6.16l-6-6c-3.32-3.32-6-6-6.06-6S9.34,2.71,6,6Zm13,4.86H16.82v9.47H7.34V10.9H2.85L7.46,6.29l4.61-4.61,4.61,4.6,4.6,4.61Z" />
        <path d="M6.15,25.11v2.37H18V22.74H6.15Zm10.67,0v1.18H7.34V23.92h9.47Z" />
      </g>
    </g>
  </svg>
)

export default CapsLockIcon
