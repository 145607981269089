import React from 'react'

interface ConditionalRenderProps {
  condition: boolean
  // condition has necessarely to be a boolean, one of the most important purposes
  // of the component is raising a typescript error in case we're defining a condition
  // that is not a boolean. For instance showComponent && dataArray.length,
  // this is not returning a boolean and this is actually the case when we get to
  // display the 0 value in plain.
}

const ConditionalRender: React.FC<ConditionalRenderProps> = ({ condition, children }) => {
  return condition ? <>{children}</> : null
}

export default ConditionalRender
