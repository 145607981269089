import { SecondaryButton } from 'components/core/Button'
import { CheckboxWithLabel } from 'components/core/Checkbox'
import { InputTypography } from 'components/Typography'
import { pxToRem } from 'libs/styled'
import styled from 'styled-components'

export const ResetButton = styled(SecondaryButton)`
  padding-block: ${pxToRem(5)};
  padding-inline: ${pxToRem(30)};
`

export const DownloadButton = styled.a`
  padding-block: ${pxToRem(12)};
  padding-inline: ${pxToRem(20)};
  font-size: ${pxToRem(16)};
  border-radius: ${pxToRem(8)};
  margin-inline-end: ${pxToRem(16)};
`

export const Checkbox = styled(CheckboxWithLabel)`
  label {
    block-size: ${pxToRem(24)};
  }
`

export const FormWrapper = styled.div`
  padding: 2em;
`

export const ConfigOverrideHeader = styled.div`
  inline-size: 100vw;
  min-inline-size: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 0;
  padding-inline: 1rem;
`

export const OverrideForm = styled.form`
  height: 100vh;
  overflow: scroll;
`

export const ConfigOverrideInput = styled(InputTypography)`
  border: 1px solid #d9d9d9;
  width: 100%;
`
