import React, { FC } from 'react'
import { IconProps } from '../../../types/icons'

const CheckmarkRoundLight: FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#000000',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
    >
      <g>
        <path
          d="M40 0C17.945 0 0 17.945 0 40C0 62.055 17.945 80 40 80C62.055 80 80 62.055 80 40C80 17.945 62.055 0 40 0ZM40 76.6667C19.7817 76.6667 3.33333 60.2183 3.33333 40C3.33333 19.7817 19.7817 3.33333 40 3.33333C60.2183 3.33333 76.6667 19.7817 76.6667 40C76.6667 60.2183 60.2183 76.6667 40 76.6667Z"
          fill={fill}
        />
        <path
          d="M34.0667 50.9767L18.6667 35.5767L16.31 37.9333L34.0633 55.69L63.6383 26.18L61.2833 23.82L34.0667 50.9767Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_42257_11459">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CheckmarkRoundLight
