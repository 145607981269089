import { ProgressBar } from 'components/ProgressBar'
import { Progress } from 'components/ProgressBar/styles'
import { Label } from 'components/Typography'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem, withLineClamp } from 'libs/styled'
import styled, { css } from 'styled-components'

export const MultipleImagesContainer = styled.div`
  position: relative;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-size: contain;
    object-fit: contain;
  }
`

// TODO: commented rules from SingleImageContainer because they produced a very small image
// width: ${pxToRem(41)};
// ${landscape(`
//   height: 48.828vh;
// `)}
export const SingleImageContainer = styled.div`
  position: relative;

  ${landscape(`
    height: 48.828vh;
  `)}

  img {
    width: 100%;
    height: 100%;
  }
`

export const ImageWrapper = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
`

export const MainImagesCarousel = styled.div<{ isAfa: boolean }>`
  position: relative;
  height: 100%;
  min-height: 100%;
  width: 100%;
  margin: 0 auto;
  ${tablet(`
    width: ${clampValues(560, 748)};
  `)}
  ${({ isAfa }) =>
    landscape(`
      width: ${isAfa ? clampValues(550, 700) : '100%'};
    `)}
`

export const ProgressActionsContainer = styled.div<{ isSingleImage: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isSingleImage }) => (isSingleImage ? 'flex-end' : 'unset')};
  margin: auto;
  width: ${pxToRem(575)};
  top: 0;
  ${tablet(
    `
    width: ${clampValues(400, 575)};
  `
  )}
`

export const MultiImagesActions = styled.div<{ isSingleImage: boolean }>`
  margin-left: ${pxToRem(24)};

  button {
    position: static;
    margin-top: ${({ isSingleImage }) => (isSingleImage ? pxToRem(-10) : pxToRem(-5))};
  }
`

export const PDPProgressBar = styled(ProgressBar)`
  flex-grow: 1;

  ${Progress} {
    height: ${pxToRem(4)};
    background-color: ${({ theme }) => theme.colors.primaryText};
    opacity: 0.2;
    margin: 0;
    background-image: none;
    border-radius: 0;
    &.active {
      opacity: 1;
    }
  }
`

export const OverImageLayerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    display: block;
  }

  p {
    word-break: break-all;
  }
`

export const MessageLabel = styled(Label)`
  ${withLineClamp};
  line-clamp: 4;
  -webkit-line-clamp: 4;
`

export const MessageBox = styled.div`
  white-space: normal;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${pxToRem(20)};
  border-radius: ${pxToRem(12)};
`

const position1 = css`
  width: ${pxToRem(340)};
  bottom: 49%;
  right: 20%;

  ${landscape(`
    width: ${pxToRem(347)};
  `)}

  ${tablet(`
    width: ${clampValues(235, 340)};
  `)}

  ${landscape(
    Device.tablet,
    `
      width: ${clampValuesLandscape(235, 340)};
    `
  )}
`

const position2 = css`
  width: ${pxToRem(318)};
  top: 55%;
  left: 60%;
  ${landscape(`
      width: ${pxToRem(396)};
  `)}

  ${tablet(`
    width: ${clampValues(232, 318)};
  `)}
  ${tablet(
    Device.ipadMini,
    `
    flex-direction: row;
  `
  )}

  ${landscape(
    Device.tablet,
    `
      width: ${clampValuesLandscape(244, 298)};
    `
  )}
`

const position3 = css`
  width: ${pxToRem(320)};
  top: 45%;
  right: 65%;
  ${landscape(`
      width: ${pxToRem(378)};
  `)}

  ${tablet(`
    width: ${clampValues(252, 323)};
  `)}

  ${landscape(
    Device.tablet,
    `
      width: ${clampValuesLandscape(209, 280)};
    `
  )}
`

export const MessageTooltipBox = styled.div<{ position: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;

  ${({ position }) => {
    switch (position) {
      case 1:
        return position1
      case 2:
        return position2
      case 3:
        return position3
    }
  }}
`

const position1Button = css`
  order: 1;
  align-self: flex-end;
  margin-top: ${pxToRem(8)};
`

const position2Button = css`
  order: -1;
  align-self: flex-start;
  margin-bottom: ${pxToRem(8)};
  ${tablet(
    Device.ipadMini,
    `
    margin-bottom: 0;
    margin-right: ${pxToRem(8)};
  `
  )}
`

const position3Button = css`
  order: -1;
  align-self: flex-end;
  margin-bottom: ${pxToRem(8)};
`

export const MessageCloseButton = styled.button<{ position: number }>`
  background-color: #504bd6;
  border-radius: 50%;
  width: ${pxToRem(36)};
  height: ${pxToRem(36)};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;

  & > svg {
    vertical-align: unset;
    display: unset;
  }

  ${({ position }) => {
    switch (position) {
      case 1:
        return position1Button
      case 2:
        return position2Button
      case 3:
        return position3Button
    }
  }}
`

export const MessageOpenButton = styled.button<{ position: number }>`
  background-color: #504bd6;
  border-radius: 50%;
  width: ${pxToRem(26)};
  height: ${pxToRem(26)};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: ${pxToRem(9)} solid white;
  /* to count outline in element size */
  margin: ${pxToRem(6)};

  /* we simulate a outline to prevent Safari issues */
  ::before {
    content: '';
    position: absolute;
    top: ${pxToRem(-15)};
    right: ${pxToRem(-15)};
    bottom: ${pxToRem(-15)};
    left: ${pxToRem(-15)};
    border: ${pxToRem(1)} solid white;
    border-radius: 50%;
  }
  transform: scale(1);
  animation: pulse 2s infinite;
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  ${({ position }) => {
    switch (position) {
      case 1:
        return position1Button
      case 2:
        return position2Button
      case 3:
        return position3Button
    }
  }};
`
