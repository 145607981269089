import styled from 'styled-components'

export const MenuItemButtonStyled = styled.button`
  margin: 0;
  padding: 0 1.455rem;
  display: inline-flex;
  align-items: center;
  border: 0;
  outline: 0;
  /* font-family: ${({ theme }) => theme.fonts.primary}; */

  font-size: 1.364rem;
  font-weight: 300;
  color: ${({ theme }) => theme.menu.textColor};
  background: inherit;

  &:active {
    color: black;
  }
`

export const MenuItemIcon = styled.span`
  margin-right: 0.455rem;
  display: flex;
  width: 2.273rem;
  height: 2.273rem;
  justify-content: center;
  align-items: center;
`
