import React from 'react'
import { IconProps } from 'types/icons'

export const FaceShapeCircularIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 40C8.97 40 0 31.03 0 20C0 8.97 8.97 0 20 0C31.03 0 40 8.97 40 20C40 31.03 31.03 40 20 40ZM20 2C10.07 2 2 10.08 2 20C2 29.92 10.07 38 20 38C29.93 38 38 29.92 38 20C38 10.08 29.92 2 20 2Z"
        fill={fill}
      />
    </svg>
  )
}
