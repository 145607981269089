import { ListContext } from 'components/core/List/ListContext'
import React from 'react'
import styled from 'styled-components'

export interface ListItemProps {
  className?: string
  as?: keyof JSX.IntrinsicElements
}

export const ListItemContainer = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const ListItem: React.FC<ListItemProps> = ({ children, className, as }) => {
  const context = React.useContext(ListContext)
  const component = as || (context.parentElement === 'ul' ? 'li' : 'div')

  return (
    <ListContext.Provider value={{ parentElement: component }}>
      <ListItemContainer as={component} className={className}>
        {children}
      </ListItemContainer>
    </ListContext.Provider>
  )
}
