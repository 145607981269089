import { ProductInfoForModals, WHSSkuBase } from 'components/BarCodeScanModal/types'
import { CustomModel } from 'types/custom'
import { OOMasterBrand, RBMasterBrand } from './custom'

export const convertProductForBarcodeModal = (
  product?: WHSSkuBase
): ProductInfoForModals | undefined => {
  if (!product) {
    return undefined
  }
  const { materialCode, upc, url } = product
  const brand = product?.attributes?.find(p => p.name === 'brand').value
  const moco = `${product.materialCode}__${product.colorCode}`

  return {
    originalBrand: brand,
    model: materialCode,
    moco,
    UPC: upc,
    url,
  }
}

export const convertCustomModelIntoProductInfo = (
  product?: CustomModel
): ProductInfoForModals | undefined => {
  if (!product) {
    return undefined
  }
  const { standardModelCode, thumbnailUrl } = product
  const brand = standardModelCode.includes('OO') ? OOMasterBrand : RBMasterBrand

  return {
    originalBrand: brand,
    model: standardModelCode,
    thumbnailUrl,
  }
}
