export interface ICallbackError {
  code: TErrorCode
  context: TErrorContext
  error: Error
}

export type TErrorCode =
  | typeof ERROR_CODE_ASSET_LOADING
  | typeof ERROR_CODE_UPC_NOT_AVAILABLE
  | typeof ERROR_CODE_VALIDATION

export const ERROR_CODE_ASSET_LOADING = 'ASSET_LOADING_FAILED'
export const ERROR_CODE_UPC_NOT_AVAILABLE = 'UPC_NOT_AVAILABLE'
export const ERROR_CODE_VALIDATION = 'VALIDATION_FAILED'

type TErrorContext = typeof ERROR_CONTEXT_INIZIALIZATION
const ERROR_CONTEXT_INIZIALIZATION = 'initialization'

export interface IInitCallbacks {
  onClose?: () => void
  onError?: (detail: ICallbackError) => void
}

export interface RealTimeRendererProps {
  productUPC?: string
  type: IRTRTypes
  styleType: IRTRStyle
  showOnLoad?: boolean
  buttonText?: string
}

export enum IRTRTypes {
  embed = 'EMBED',
  overlay = 'OVERLAY',
}

export enum IRTRStyle {
  pdp = 'PDP',
  editorial = 'EDITORIAL',
}
