import React from 'react'
import { IconProps } from 'types/icons'
export const SkinUndertonesIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333333',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.856 32L46.642 26.927L46.142 26.061L38.642 13.07L5.61 32.142L21.805 4.09L32.197 10.09L28.393 16.679L31.857 14.679L34.929 9.358L34.063 8.858L21.072 1.358L11.303 18.279L2 34.393V2H14V9.608L16 6.144V0H0V40C0 44.411 3.589 48 8 48H48V32H37.856ZM46 46H8C4.691 46 2 43.309 2 40C2 36.691 4.691 34 8 34H46V46ZM37.91 15.803L43.91 26.195L33.855 32H9.855L37.91 15.803Z"
        fill={fill}
      />
    </svg>
  )
}
