import React, { useEffect } from 'react'

interface GesturesHandlerProps {
  children: React.ReactNode
  disablePinchZoom: boolean
}

export const useDisablePinchZoom = (enable: boolean) => {
  useEffect(() => {
    const preventDef = function (e: Event) {
      e.preventDefault()
    }

    if (enable) {
      document.addEventListener('gesturestart', preventDef)
    }

    return () => {
      document.removeEventListener('gesturestart', preventDef)
    }
  }, [enable])
}

export const GesturesHandler = ({ disablePinchZoom, children }: GesturesHandlerProps) => {
  useDisablePinchZoom(disablePinchZoom)
  return children
}
