import { BadgeBestMatch } from 'components/BadgeBestMatch'
import { LoveHateActionsBtns } from 'components/CardProductBase/LoveHateActionsBtns'
import { Heading4, LinkTypography } from 'components/Typography'
import { RoundCheckmarkIcon } from 'components/core/Icons/RoundCheckmark'
import { isRTREnabled } from 'components/core/RealTimeRenderer/utils'
import { pxToRem } from 'libs/styled'
import { getQueryParamsOnlyDefined } from 'libs/url'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProductPriceType } from 'types/price'
import { CustomProduct, DataAttrs } from 'types/product'
import { useGetDataDescriptionForProduct } from '../../libs/analytics'
import { BadgeChips } from './BadgeChips'
import { BestMatchInfo } from './BestMatchInfo'
import { ProductAddBuyButton } from './ProductAddBuyButton'
import {
  AddBuyWrapper,
  CardBodyColumn,
  CardProductBody,
  CardProductContainerStyled,
  CardProductLinkStyled,
  ChipsBottomLeft,
  ChipsBottomRight,
  ChipsTopLeft,
  DiscoverMoreLink,
  IconsBottomLeft,
  IconsStyled,
  IconsTop,
  ProductDescriptionStyled,
  ProductOverlay,
  ProductOverlayContent,
  ProductWishListActionButtonStyled,
  RemoveButton,
} from './ProductCard.style'
import CardProductInfo from './ProductCardInfo'
import { StyledCustomizeIcon, StyledFaCustomizeIcon, StyledRtrIcon } from './ProductIcons'
import { ProductImage } from './ProductImage'
import { ProductPreview } from './ProductPreview'

interface ProductCardImageSectionProps {
  product: CustomProduct
  showIcons?: boolean
  dressedOnImageUrl?: string
  showWishlistButton?: boolean
  customImage?: React.ReactElement
  displayWishlistIconAtBottom?: boolean
  showLoveHateBtns?: boolean
  showCheckedIcon?: boolean
  playlistId?: string
  isOppositeIconArrangement?: boolean
  isDressedOnView?: boolean
  scrollToTop?: () => void
  showRTRIcon?: boolean
  customizableEnabled?: boolean
  dataAttrs?: DataAttrs
  index?: number
  dynamicSentence?: string
  showBestMatch?: boolean
  showAddedToBagOverlay?: boolean
}

export interface ProductCardProps extends ProductCardImageSectionProps {
  product: CustomProduct
  showPrice?: boolean
  showProductBrandName?: boolean
  showProductModel?: boolean
  priceStyle?: ProductPriceType
  imageOptions?: Record<string, unknown>
  gender?: string
  productType?: string
  storeAvailable?: string
  className?: string
  highlightModelCode?: boolean
  additionalQueryStringAttributes?: { [key: string]: string | boolean | number | undefined }
  showColorsAvailable?: boolean
  asLink?: boolean
  showAddBuyBtn?: boolean
  showColor?: boolean
  handleRemoveButtonClick?: () => void
  showRemoveButton?: boolean
  customPrice?: React.ReactElement
  showBestMatch?: boolean
  dynamicSentence?: string
  showDiscoverMore?: boolean
  disableHateUndo?: boolean
}

const getProductCardUrl = (
  baseUrl: string,
  gender?: string,
  productType?: string,
  storeAvailable?: string,
  additionalQueryStringAttributes?: Record<string, unknown>
) => {
  const paramsString = getQueryParamsOnlyDefined({
    gender,
    productType,
    storeAvailable: storeAvailable ? '1' : undefined,
    ...additionalQueryStringAttributes,
  })

  const searchString = `${paramsString}`
  return { pathname: baseUrl, search: searchString }
}

export const ProductCardImageSection: React.FC<ProductCardImageSectionProps> = ({
  product,
  showIcons = true,
  dressedOnImageUrl,
  showWishlistButton = false,
  customImage,
  displayWishlistIconAtBottom = false,
  showLoveHateBtns = false,
  playlistId,
  isOppositeIconArrangement = false,
  isDressedOnView,
  scrollToTop,
  showRTRIcon = false,
  customizableEnabled = true,
  dataAttrs,
  index,
  dynamicSentence,
  showBestMatch,
  showAddedToBagOverlay,
}) => {
  const { t } = useTranslation()
  const { badges, customizable, rtr } = product
  const showRTR = isRTREnabled()
  const isRTRIconVisible = showRTRIcon && rtr && showRTR

  const renderIcons = (isOppositeArrangement?: boolean) => {
    if (isOppositeArrangement) {
      return (
        <IconsStyled>
          <IconsBottomLeft>
            {isRTRIconVisible && <StyledRtrIcon />}
            {customizable && customizableEnabled && <StyledFaCustomizeIcon />}
          </IconsBottomLeft>
          <ChipsTopLeft>
            <BadgeChips badges={badges?.tile} />
          </ChipsTopLeft>
        </IconsStyled>
      )
    }
    return (
      <IconsStyled>
        <IconsTop>
          {isRTRIconVisible && <StyledRtrIcon />}
          {customizable && customizableEnabled && <StyledCustomizeIcon />}
        </IconsTop>
        {}
        <ChipsBottomLeft>
          <BadgeChips badges={badges.tile} badgesToShow={['new', 'bestSeller']} />
        </ChipsBottomLeft>
        <ChipsBottomRight>
          <BadgeChips badges={badges.tile} badgesToShow={['polarized']} />
        </ChipsBottomRight>
      </IconsStyled>
    )
  }

  return (
    <ProductPreview isDressedOnView={isDressedOnView}>
      {showAddedToBagOverlay && (
        <ProductOverlay>
          <ProductOverlayContent>
            <RoundCheckmarkIcon width={pxToRem(40)} height={pxToRem(40)} />
            <Heading4 weight={'bold'} color="bodyBackground">
              {t('ProductView.added')}
            </Heading4>
          </ProductOverlayContent>
        </ProductOverlay>
      )}
      {showIcons && renderIcons(isOppositeIconArrangement)}
      {customImage || <ProductImage product={product} dressedOnImageUrl={dressedOnImageUrl} />}
      {showWishlistButton && (
        <ProductWishListActionButtonStyled
          product={product}
          dataAttrs={dataAttrs}
          displayWishlistIconAtBottom={displayWishlistIconAtBottom}
        />
      )}
      {showLoveHateBtns && !!playlistId && (
        <LoveHateActionsBtns
          product={product}
          playlistId={playlistId}
          dataAttrs={dataAttrs}
          index={index}
          scrollToTop={scrollToTop}
        />
      )}
      {showBestMatch && isDressedOnView && (
        <BestMatchInfo
          product={product}
          dataAttrs={dataAttrs}
          dynamicSentence={dynamicSentence}
          isDressedOnView={isDressedOnView}
        />
      )}
    </ProductPreview>
  )
}

export const ProductCard: React.FC<ProductCardProps> = ({
  gender,
  productType,
  storeAvailable,
  product,
  className,
  priceStyle = 'default',
  dynamicSentence,
  showProductBrandName = true,
  showProductModel = false,
  showPrice = true,
  highlightModelCode = false,
  dataAttrs,
  additionalQueryStringAttributes,
  asLink = true,
  showColorsAvailable = false,
  showAddBuyBtn = false,
  index = 0,
  showColor = false,
  handleRemoveButtonClick,
  showRemoveButton = false,
  customPrice,
  showBestMatch = false,
  showDiscoverMore = false,
  showIcons,
  dressedOnImageUrl,
  showWishlistButton,
  customImage,
  displayWishlistIconAtBottom,
  showLoveHateBtns,
  playlistId,
  isOppositeIconArrangement,
  isDressedOnView,
  scrollToTop,
  showRTRIcon,
  customizableEnabled,
  showAddedToBagOverlay = false,
}) => {
  const { UPC, moco, url, price } = product
  const productUrl = getProductCardUrl(
    url,
    gender,
    productType,
    storeAvailable,
    additionalQueryStringAttributes
  )
  const { t } = useTranslation()
  const getDataDescriptionForProduct = useGetDataDescriptionForProduct()

  const productCardId = `productCard_${UPC}`
  const shouldRenderPrice = showPrice && price.current.value !== 0

  dataAttrs = {
    description: getDataDescriptionForProduct(product),
    ...dataAttrs,
  }
  const showDynamicSentence = Boolean(dynamicSentence)

  const isExtendedCard =
    (showBestMatch || showDynamicSentence || showDiscoverMore) && !isDressedOnView

  return (
    <CardProductContainerStyled className={className} data-test={`context-${moco}`}>
      {asLink && (
        <CardProductLinkStyled
          to={productUrl}
          aria-labelledby={productCardId}
          data-element-id={dataAttrs && dataAttrs.link}
          data-description={dataAttrs && dataAttrs.description}
        />
      )}
      <ProductCardImageSection
        product={product}
        showIcons={showIcons}
        dressedOnImageUrl={dressedOnImageUrl}
        showWishlistButton={showWishlistButton}
        customImage={customImage}
        displayWishlistIconAtBottom={displayWishlistIconAtBottom}
        showLoveHateBtns={showLoveHateBtns}
        playlistId={playlistId}
        isOppositeIconArrangement={isOppositeIconArrangement}
        isDressedOnView={isDressedOnView}
        scrollToTop={scrollToTop}
        showRTRIcon={showRTRIcon}
        customizableEnabled={customizableEnabled}
        showBestMatch={showBestMatch}
        dynamicSentence={dynamicSentence}
        showAddedToBagOverlay={showAddedToBagOverlay}
      />

      <CardProductBody>
        <CardProductInfo
          product={product}
          showColor={showColor}
          showProductBrandName={showProductBrandName}
          showProductModel={showProductModel}
          shouldRenderPrice={shouldRenderPrice}
          showColorsAvailable={showColorsAvailable}
          customPrice={customPrice}
          priceStyle={priceStyle}
          highlightModelCode={highlightModelCode}
        />
        {isExtendedCard && (
          <CardBodyColumn isFullRow>
            {showBestMatch && <BadgeBestMatch />}
            {showDynamicSentence && (
              <ProductDescriptionStyled>{dynamicSentence}</ProductDescriptionStyled>
            )}
            {showDiscoverMore && (
              <DiscoverMoreLink
                to={`${productUrl}?fromFrameAdvisor=true`}
                aria-labelledby={productCardId}
                data-element-id={dataAttrs && dataAttrs.link}
                data-test={dataAttrs && dataAttrs.link}
                data-description={dataAttrs && dataAttrs.description}
              >
                <LinkTypography
                  textSize="body"
                  textSizeTablet="bodyTablet"
                  textSizeTabletMini="bodyTabletMini"
                >
                  {t('ProductView.discoverMore')}
                </LinkTypography>
              </DiscoverMoreLink>
            )}
          </CardBodyColumn>
        )}
      </CardProductBody>
      {showAddBuyBtn && (
        <AddBuyWrapper>
          <ProductAddBuyButton product={product} index={index} />
        </AddBuyWrapper>
      )}
      {showRemoveButton && (
        <RemoveButton
          data-element-id={dataAttrs.removeBtn}
          data-description={dataAttrs.description}
          data-test="button-remove"
          className="invisible-btn"
          onClick={handleRemoveButtonClick}
        >
          {t('Core.remove')}
        </RemoveButton>
      )}
    </CardProductContainerStyled>
  )
}
