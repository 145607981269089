import { pxToRem } from 'libs/styled'
import React from 'react'
import styled from 'styled-components'

export const ColorSwatchWrapper = styled.div<{ background?: string }>`
  background: ${({ background }) => background};
  flex-shrink: 0;
  margin: ${pxToRem(3)};
  width: ${pxToRem(60)};
  height: ${pxToRem(60)};
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 1px 2px white, 0px 0px 1px 3px ${({ theme }) => theme.colors.alto};
`

interface ColorSwatchProps {
  background?: string
}

export const ColorSwatch: React.FC<ColorSwatchProps> = ({ background, children }) => {
  return <ColorSwatchWrapper background={background}>{children}</ColorSwatchWrapper>
}
