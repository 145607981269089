import config from 'config'
import { useApplyFilters } from 'hooks/useFilters'
import { usePreselectedFilters, useUserFilters } from 'hooks/usePreselectedFilters'
import { getOppositeProductType } from 'libs/utils'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { FiltersState, ProductTypeValues } from 'types/filter'
import { ProductTypeToggler } from './ProductTypeToggler'

interface ProductTypeTogglerWrapper {
  filterValue: string | undefined
  hasItems?: boolean
  filters: FiltersState
  scrollToTop?: () => void
  onChange?: (value: ProductTypeValues) => void
}

export const ProductTypeTogglerWrapper: React.FC<ProductTypeTogglerWrapper> = ({
  filterValue,
  hasItems,
  filters,
  scrollToTop,
  onChange,
}) => {
  const preselectedFilters = usePreselectedFilters(filters)
  const userFilters = useUserFilters()
  const [stopAutotoggle, setStopAutoToggle] = useState(false)

  const hasUserFilters = useMemo(() => userFilters?.filters && userFilters?.filters?.length > 0, [
    userFilters,
  ])

  const oppositeProductType = getOppositeProductType(filterValue || config.defaultProductType)

  const hasOppositeItems = useMemo(
    () => Boolean(preselectedFilters?.productTypeCounts[oppositeProductType]),
    [preselectedFilters, oppositeProductType]
  )
  const notHasPreselectedFilters = useMemo(
    () => preselectedFilters !== undefined && isEmpty(preselectedFilters.filters),
    [preselectedFilters]
  )

  const isMultiProductTypeAvailable = useMemo(() => !!hasItems && !!hasOppositeItems, [
    hasOppositeItems,
    hasItems,
  ])
  const visible = useMemo(
    () => (notHasPreselectedFilters || isMultiProductTypeAvailable) && !!oppositeProductType,
    [isMultiProductTypeAvailable, notHasPreselectedFilters, oppositeProductType]
  )
  const autoSwitchProductType = useMemo(() => hasOppositeItems && !hasItems && !hasUserFilters, [
    hasOppositeItems,
    hasItems,
    hasUserFilters,
  ])

  const autoToggleProduct = useApplyFilters([
    ...(preselectedFilters?.filters ?? []),
    { kind: 'collection', value: oppositeProductType },
  ])

  useEffect(() => {
    if (!visible && autoSwitchProductType && !stopAutotoggle) {
      autoToggleProduct()
      setStopAutoToggle(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSwitchProductType, visible, stopAutotoggle])

  return visible ? (
    <ProductTypeToggler
      preselectedFilters={preselectedFilters?.filters}
      filterValue={filterValue}
      scrollToTop={scrollToTop}
      onChange={onChange}
    />
  ) : null
}
