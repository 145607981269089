import { CloseIconContainer } from 'components/core/Modal/styles'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { pxToRem } from '../../libs/styled'
import { Heading4 } from '../Typography'
import { CloseIcon, RotateDeviceToLandscapeIcon, RotateDeviceToPortraitIcon } from '../core/Icons'
import { RotateDeviceMessage, RotateDeviceMessageInner } from './styles'

interface RotateDeviceProps {
  onCancel?: () => void
  to?: 'portrait' | 'landscape'
  showCloseBtn?: boolean
}

const RotateDevice: React.FC<RotateDeviceProps> = ({
  onCancel,
  to = 'landscape',
  showCloseBtn = false,
}) => {
  const { t } = useTranslation()

  const Icon = useMemo(
    () => (to === 'portrait' ? RotateDeviceToPortraitIcon : RotateDeviceToLandscapeIcon),
    [to]
  )

  return (
    <RotateDeviceMessage>
      {showCloseBtn && (
        <CloseIconContainer onClick={onCancel}>
          <CloseIcon />
        </CloseIconContainer>
      )}
      <RotateDeviceMessageInner>
        <Icon width={pxToRem(110)} height={pxToRem(110)} />
        <Heading4>{t('FrameAdvisor.couvette.rotationError')}</Heading4>
      </RotateDeviceMessageInner>
    </RotateDeviceMessage>
  )
}

export default RotateDevice
