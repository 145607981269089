import React from 'react'
import { IconProps } from 'types/icons'
export const FaFaceShapeHeartWomanIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'black',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M47.707 36.293C45.736 34.322 45.001 25.867 45 21C45 9.42 35.58 0 24 0C12.42 0 3 9.42 3 21C2.999 25.867 2.264 34.322 0.293 36.293L0 36.586V37C0 43.065 4.935 48 11 48C14.218 48 17.109 46.602 19.122 44.392C20.529 45.43 22.219 46 24 46C25.781 46 27.471 45.43 28.878 44.392C30.891 46.602 33.782 48 37 48C43.065 48 48 43.065 48 37V36.586L47.707 36.293ZM14.551 37.137C12.834 35.42 11.69 33.25 11.242 30.864L9.195 19.948C14.841 19.451 18.935 15.496 20.404 11.81C22.402 14.273 27.227 17.835 38.828 19.821L36.758 30.863C36.31 33.25 35.166 35.42 33.449 37.137L28.414 42.172C27.235 43.351 25.667 44 24 44C22.333 44 20.765 43.351 19.586 42.172L14.551 37.137ZM37 46C34.37 46 32.007 44.859 30.36 43.054L34.863 38.551C36.866 36.548 38.201 34.017 38.724 31.232L40.983 19.185L40.156 18.013C22.377 15.205 20.999 8.901 20.99 8.858L19 9C19 12.54 14.276 18 8 18L7.017 19.184L9.276 31.232C9.798 34.017 11.133 36.547 13.137 38.551L17.64 43.054C15.993 44.859 13.63 46 11 46C6.161 46 2.202 42.162 2.007 37.37C4.914 33.734 5 22.329 5 21C5 10.523 13.523 2 24 2C34.477 2 43 10.523 43 21C43 22.329 43.086 33.734 45.993 37.37C45.798 42.162 41.839 46 37 46Z"
        fill={fill}
      />
      <path d="M26.434 23.057H24.434V31H21.986V33H26.434V23.057Z" fill={fill} />
      <path
        d="M21.878 38.121C22.445 38.688 23.198 39 24 39C24.802 39 25.555 38.688 26.122 38.121L27.313 36.929L24 35.958L20.688 36.928L21.878 38.121Z"
        fill={fill}
      />
    </svg>
  )
}
