import {
  ADD_ITEM,
  CartActions,
  CLEAR_CART_ITEM_OVERLAY,
  REMOVE_ITEM,
  SET_ITEM_QUANTITY,
} from 'actions/cart'
import { CartItem } from 'types/cart'

export type CartState = {
  items: CartItem[]
  cartItemOverlayId?: string
}

const initialState: CartState = {
  items: [],
}

export default (state = initialState, action: CartActions) => {
  switch (action.type) {
    case ADD_ITEM: {
      const { payload } = action
      const items = [...state.items]

      const item = items.find(v => v.id === payload.id)

      if (!item) {
        items.push(payload)
      } else {
        // increment quantity in cart
        item.quantity += 1
      }

      return {
        ...state,
        items,
        cartItemOverlayId: payload.id,
      }
    }

    case REMOVE_ITEM: {
      const { payload } = action

      return {
        ...state,
        items: state.items.filter(item => item.id !== payload.id),
      }
    }

    case CLEAR_CART_ITEM_OVERLAY: {
      return {
        ...state,
        cartItemOverlayId: undefined,
      }
    }

    case SET_ITEM_QUANTITY: {
      const { payload } = action
      const index = state.items.findIndex(e => e.id === payload.id)
      const items = [...state.items]

      items[index].quantity = payload.quantity

      return {
        ...state,
        items,
      }
    }

    default:
      return state
  }
}
