import { Spin } from 'components/Loading'
import React from 'react'
import styled from 'styled-components'
import { ContentV2Image, Maybe } from 'types/graphqlTypes'
import useImage from 'use-image'

type ContentImageProps = {
  imageSource?: Maybe<ContentV2Image> | string
  fallbackImage?: Maybe<React.ReactElement>
  showSkeleton?: boolean
  className?: string
  width?: string
  height?: string
  skipLazyLoading?: boolean
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

type ImageProps = {
  width?: string
  height?: string
}

export const Skeleton = styled.div<ImageProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
  isolation: isolate;
  inset: 0;
  overflow: hidden;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  min-height: ${({ height }) => height};
  min-width: ${({ width }) => width};

  &::before {
    content: ' ';
    position: absolute;
    inset: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, #f6f6f6 25%, #eeeeee 50%, #f6f6f6 100%);
  }
`

export const Image = styled(({ ...props }) => <img {...props} />)<ImageProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  object-fit: cover;
`

export const ContentImage: React.FC<ContentImageProps> = ({
  imageSource,
  fallbackImage,
  showSkeleton = true,
  className = '',
  width = '100%',
  height = '100%',
  skipLazyLoading = false,
  ref,
  ...props
}) => {
  const src = typeof imageSource === 'string' ? imageSource : null
  const [contentImage, status] = useImage(src)

  const renderSkeleton = (content?: React.ReactElement) => (
    <Skeleton width={width} height={height} className={className}>
      {content}
    </Skeleton>
  )

  const renderFallbackImage = (content?: React.ReactElement) => (
    <Skeleton className={className} width={width} height={height} {...props}>
      {content}
    </Skeleton>
  )

  if (!src) return skipLazyLoading ? null : renderSkeleton()

  if (skipLazyLoading) {
    return (
      <Image className={className} src={src} width={width} height={height} ref={ref} {...props} />
    )
  }

  switch (status) {
    case 'loaded':
      return (
        <Image
          className={className}
          src={contentImage?.src}
          width={width}
          height={height}
          {...props}
        />
      )
    case 'loading':
      return showSkeleton ? renderSkeleton(<Spin />) : null
    case 'failed':
      return fallbackImage ? renderFallbackImage(fallbackImage) : renderSkeleton()
    default:
      return null
  }
}
