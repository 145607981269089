import { default as React } from 'react'
import { IconProps } from 'types/icons'

export const FaceScanAppSwitchFace: React.FC<IconProps> = ({ width = '100%', height = '60' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 100 52">
      <g fill="none" fillRule="evenodd" transform="translate(0 1)">
        <rect width="98.864" height="50" fill="#1B1B1B" rx="25"></rect>
        <rect
          width="50"
          height="50"
          x="48.864"
          fill="#FFF"
          stroke="#979797"
          strokeWidth="1.136"
          rx="25"
        ></rect>
        <path
          fill="#FFF"
          d="M35.55 31.026l-.354.06-.113.34c-1.64 4.846-4.978 8.01-8.64 7.993-3.662.016-7.008-3.147-8.639-7.993l-.121-.34-.355-.06c.008-.037-3.12-.447-3.12-4.356.092-2.039.597-2.025 1.119-2.092.256-.01.55.081.778.165.226.088.385.186.393.18l.17.1c.504.941 1.251 1.301 1.63 1.427.157 1.795 1.623 3.22 3.475 3.22 1.865 0 3.346-1.447 3.482-3.26.067-.036.16-.076.272-.122.234-.098.559-.187.913-.178.355-.01.68.08.914.178.112.046.205.086.272.122.136 1.813 1.616 3.26 3.48 3.26 1.853 0 3.319-1.425 3.477-3.22.378-.126 1.126-.486 1.631-1.428l.167-.099c.008-.008.635-.36 1.17-.346.53.068 1.028.044 1.12 2.092-.001 3.91-3.12 4.32-3.12 4.357zm-10.992-4.868a2.782 2.782 0 01-2.785 2.761 2.77 2.77 0 01-2.778-2.76 2.772 2.772 0 012.778-2.766 2.785 2.785 0 012.785 2.765zm9.327 0a2.77 2.77 0 01-2.778 2.761 2.783 2.783 0 01-2.786-2.76 2.786 2.786 0 012.786-2.766 2.772 2.772 0 012.778 2.765zM17.41 24.426a24.2 24.2 0 01.36-3.333c1.141-3.179 2.544-3.62 3.131-3.62.59 0 2.616.35 4.03.35 3.489 0 4.385-.89 6.6-.89 2.105 0 3.587 4.656 3.73 5.119.104.77.182 1.561.212 2.374l.012.362c-.303.507-.68.74-.949.858-.258-1.684-1.656-3.002-3.43-3.002-1.76 0-3.15 1.3-3.422 2.97a3.376 3.376 0 00-1.245-.254c-.51.003-.938.13-1.245.255-.272-1.67-1.66-2.971-3.422-2.971-1.774 0-3.172 1.318-3.43 3.002a2.053 2.053 0 01-.946-.852l.014-.368zm21.788-.292a2.056 2.056 0 00-1.647-.756 3.07 3.07 0 00-.643.082v-.003c0-1.933.469-3.712.469-5.353 0-1.638-.981-4.46-4.003-4.757 0 0-1.983-5.59-13.675-4.092 0 0 1.131.704 1.414.982l.283.282s-2.876-.139-4.572 1.077c0 0 1.18.33 1.414.983 0 0-3.323.211-4.62 1.5 0 0 1.65.186 1.65 4.447 0 1.294.385 3.319.997 4.842-.004.054-.012.105-.015.157a3.38 3.38 0 00-.924-.147c-.537-.01-1.194.189-1.645.756-.454.564-.681 1.372-.681 2.536 0 3.916 2.755 5.13 3.761 5.44 1.762 4.957 5.352 8.49 9.681 8.51 4.322-.02 7.913-3.553 9.675-8.51 1.007-.31 3.76-1.524 3.76-5.44 0-1.164-.226-1.981-.678-2.536z"
        ></path>
      </g>
    </svg>
  )
}
