import React from 'react'
import { IconProps } from 'types/icons'
export const BackIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      strokeWidth={0}
    >
      <g clipPath="url(#clip0_1874_61700)">
        <path
          d="M2.20697 10.5L11.3535 1.35348L10.6465 0.646484L0.292969 11L10.6465 21.3535L11.3535 20.6465L2.20697 11.5H24V10.5H2.20697Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61700">
          <rect width={32} height={32} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
