import React from 'react'
import { ButtonExtraStyles, ButtonVariant } from 'types/components'
import { ButtonStyled } from './style'

export interface DataButtonAttrs {
  id?: string
  description?: string
  reset?: boolean
  test?: string
}

interface AriaButtonAttrs {
  label?: string
  description?: string
  disabled?: boolean
}

type BaseButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  extraStyles?: ButtonExtraStyles
  variant?: ButtonVariant
  navigateTo?: string
  dataAttrs?: DataButtonAttrs
  dataCalls?: string
  ariaAttrs?: AriaButtonAttrs
}

export type ButtonProps = Omit<BaseButtonProps, 'variant'>

export const Button: React.FC<BaseButtonProps> = ({
  extraStyles = {},
  variant = 'primary',
  ...props
}) => {
  return <ButtonStyled variant={variant} extraStyles={extraStyles} {...props} />
}

export const PrimaryButton: React.FC<ButtonProps> = props => <Button variant="primary" {...props} />
export const SecondaryButton: React.FC<ButtonProps> = props => (
  <Button variant="secondary" {...props} />
)
