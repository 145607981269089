import { CartItem } from 'types/cart'
import { Product } from 'types/product'
import { isCustomizedProduct } from './product'

interface CartSummaryValues {
  currency: string
  numberItems: number
  numberModels: number
  subtot: number
}

export const cartSummaryValues = (cartItems: CartItem[]): CartSummaryValues => {
  let currency
  if (cartItems.length > 0) {
    currency = cartItems[0].product.price.currency
  }

  return {
    currency,
    numberItems: cartItems.reduce((tot, el) => (tot += el.quantity), 0),
    numberModels: cartItems.length,
    subtot: cartItems.reduce(
      (tot, el) => (tot += el.quantity * el.product.price.current.value),
      0.0
    ),
  }
}

export const createCartItemId = (upc: string): string => {
  return `cart-${upc}`
}
interface Cart {
  items: CartItem[]
}

/**
 * Retrieve remaing items number.
 */
export const stock = (cart: Cart, product: Product): number => {
  let inCart = cart.items.filter(item => item.product.UPC === product.UPC)
  let quantity = inCart.reduce((tot, el) => (tot += el.quantity), 0)
  const stock = product.availability ? product.availability.ecommStock : 10000
  //TODO suggestions product models lack availability node
  return stock - quantity
}

export const shouldShowPrice = (
  cartItems: CartItem[],
  storeSrpCustom: boolean,
  storeSrpStandard: boolean
): boolean => {
  if (!cartItems.length) return false

  const onlyCustomProd = cartItems.every(item => isCustomizedProduct(item.product.type))
  const onlyStandardProd = cartItems.every(item => !isCustomizedProduct(item.product.type))

  if (onlyCustomProd) {
    return storeSrpCustom
  }

  if (onlyStandardProd) {
    return storeSrpStandard
  }

  return storeSrpStandard && storeSrpCustom
}
