export interface CustomModel {
  label: string
  modelCode: string
  pageUrl: string
  thumbnailUrl: string
  vendorId: string
  frameExclusive: boolean
  standardModelCode?: string
  collection: string
}

export enum OOThumbnailSide {
  Left = 'Left',
  Right = 'Right',
  Front = 'Front',
  Back = 'Back',
  Microbag = 'Microbag',
}

export enum RBThumbnailSide {
  FrontLeft = 'FFL',
  Frontal = 'FF',
  BackLeft = 'BLLL',
  BackRight = 'BRRR',
  Left = 'LL',
  Right = 'RR',
}

export type ThumbnailSide = OOThumbnailSide | RBThumbnailSide
