import { setBarcodeFlow } from 'actions/flows'
import { BooleanResolver, useQueryParams } from 'hooks/useQueryParams'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route } from 'react-router-dom'

const NavigationActions = () => {
  const dispatch = useDispatch()
  const queryParams = useQueryParams({
    comesFromBarcode: BooleanResolver,
  })

  // TODO: as soon as we initilize the redux flow state, we have to remove the query param form the url,
  // this way we don't need to exclude the query param from the url while composing the plp payloads in useSanitizedFiltersFromUrl
  useEffect(() => {
    dispatch(setBarcodeFlow(!!queryParams.comesFromBarcode))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])
  return <></>
}

export const AppRouteActions = () => {
  const { basePath } = useStoreIndentity()
  return (
    <>
      <Route path={`${basePath}`}>
        <NavigationActions />
      </Route>
    </>
  )
}
