import React from 'react'
import { IconProps } from 'types/icons'

export const FaTipsLitSpotIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 99 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="49.152" cy="49.152" r="48.128" stroke={stroke} strokeWidth="2.048" />
      <path
        d="M49.1507 33.6201C40.5866 33.6201 33.62 40.5867 33.62 49.1508C33.62 57.7148 40.5866 64.6814 49.1507 64.6814C57.7147 64.6814 64.6813 57.7148 64.6813 49.1508C64.6813 40.5867 57.7147 33.6201 49.1507 33.6201ZM49.1507 62.4628C41.8102 62.4628 35.8387 56.4912 35.8387 49.1508C35.8387 41.8103 41.8102 35.8388 49.1507 35.8388C56.4911 35.8388 62.4627 41.8103 62.4627 49.1508C62.4627 56.4912 56.4911 62.4628 49.1507 62.4628Z"
        fill={fill}
      />
      <path d="M50.2626 22.5283H48.0439V29.1843H50.2626V22.5283Z" fill={fill} />
      <path
        d="M67.1924 29.5416L62.486 34.248L64.0548 35.8169L68.7612 31.1104L67.1924 29.5416Z"
        fill={fill}
      />
      <path d="M75.776 48.0449H69.12V50.2636H75.776V48.0449Z" fill={fill} />
      <path
        d="M64.0548 62.4888L62.486 64.0576L67.1924 68.7641L68.7612 67.1953L64.0548 62.4888Z"
        fill={fill}
      />
      <path d="M50.2626 69.1201H48.0439V75.7761H50.2626V69.1201Z" fill={fill} />
      <path
        d="M34.2465 62.4859L29.54 67.1924L31.1089 68.7612L35.8153 64.0547L34.2465 62.4859Z"
        fill={fill}
      />
      <path d="M29.184 48.0449H22.528V50.2636H29.184V48.0449Z" fill={fill} />
      <path
        d="M31.1098 29.5425L29.541 31.1113L34.2475 35.8178L35.8163 34.249L31.1098 29.5425Z"
        fill={fill}
      />
    </svg>
  )
}
