import React, { useEffect, useMemo, useState } from 'react'

import { resetCouvette, setSelectedSize } from 'actions/couvette'
import { Heading2 } from 'components/Typography'
import {
  CaretDownIcon,
  FrameMeasureIcon,
  FrameWidthIcon,
  GeolocatorIcon,
  RestartIcon,
  RulerIcon,
} from 'components/core/Icons'
import { SwitcherWithLabel } from 'components/core/Switcher'
import { useActions } from 'hooks/useActions'
import { pxToRem } from 'libs/styled'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import RestartCalibrationPopup from '../RestartCalibrationPopup'
import { useDigitalCouvetteAnalytics } from '../analytics'
import { DIGITAL_COUVETTE_SIZES, Size } from '../types'
import { getSizeMeasure } from '../utils'
import {
  CouvetteDetailCard,
  CouvetteGuideLine,
  CouvetteRestart,
  CouvetteScaleColumn,
  CouvetteScaleContainer,
  CouvetteScaleDescription,
  CouvetteScaleDetailsContent,
  CouvetteScaleDetailsReferenceContainer,
  CouvetteScaleDetailsTitle,
  CouvetteScaleDetailsTitleContainer,
  CouvetteScaleHeader,
  CouvetteTitleWrapper,
  ReferenceGlassContainer,
  SizeCta,
  SizeList,
  SizeListDescriptionText,
} from './styles'

const CouvetteScale = () => {
  const { t } = useTranslation()
  const { calibrationSizes, selectedSize } = useSelector(s => s.couvette)
  const actions = useActions({ setSelectedSize, resetCouvette })
  const [digitalCouvetteDescriptionToggle, setDigitalCouvetteDescriptionToggle] = useState(false)
  const [showReferenceImage, setShowReferenceImage] = useState(true)
  const [showRestartPopup, setShowRestartPopup] = useState(false)

  const {
    onCouvetteExperiencePageView,
    onRestartCalibrationEvent,
    onClickSizeEvent,
    onClickHowToMeasureEvent,
    onClickReferenceGlassesEvent,
  } = useDigitalCouvetteAnalytics()

  const toggleReferenceImage = () => {
    onClickReferenceGlassesEvent()
    setShowReferenceImage(!showReferenceImage)
  }
  const toggleRestartPopup = () => {
    onRestartCalibrationEvent()
    setShowRestartPopup(!showRestartPopup)
  }

  useEffect(() => {
    onCouvetteExperiencePageView()
  }, [onCouvetteExperiencePageView])

  const selectedSizeWidth = selectedSize ? calibrationSizes[selectedSize].max : 0

  const referenceGlassesWith = useMemo(() => {
    let sizeWidths = {}
    for (const [size, width] of Object.entries(calibrationSizes)) {
      const isXXS = size === 'XXS',
        isXXL = size === 'XXL'
      const { min, max } = width
      if (isXXS || isXXL) {
        sizeWidths = {
          ...sizeWidths,
          [size]: `${max}px`,
        }
      } else {
        sizeWidths = {
          ...sizeWidths,
          [size]: `${max + (max - min)}px`,
        }
      }
    }
    return sizeWidths[selectedSize]
  }, [calibrationSizes, selectedSize])

  const onRestartCalibration = () => {
    //Todo add impression
    actions.resetCouvette()
  }

  return (
    <div>
      <CouvetteScaleHeader>
        <CouvetteTitleWrapper>
          <Heading2>{t('FrameAdvisor.couvette.title')}</Heading2>
          <CouvetteRestart onClick={toggleRestartPopup}>
            <RestartIcon width={pxToRem(32)} height={pxToRem(32)} />
            {t('DigitalCouvette.calibrationComplete.restart')}
          </CouvetteRestart>
        </CouvetteTitleWrapper>
        <CouvetteScaleDescription>
          {t('FrameAdvisor.couvette.description')}.
        </CouvetteScaleDescription>
      </CouvetteScaleHeader>
      <SizeListDescriptionText>{t('FrameAdvisor.couvette.selectSize')}</SizeListDescriptionText>
      <SizeList>
        {Object.keys(DIGITAL_COUVETTE_SIZES).map((size: Size, index) => {
          const onClickSize = () => {
            onClickSizeEvent(size)
            actions.setSelectedSize(size)
          }
          const dataMeasure = getSizeMeasure(size)
          return (
            <SizeCta
              selected={selectedSize === size}
              key={index}
              onClick={onClickSize}
              data-measure={dataMeasure}
            >
              {size}
            </SizeCta>
          )
        })}
      </SizeList>
      <div>
        <CouvetteScaleDetailsTitleContainer width={`${calibrationSizes.XXL.max}px`}>
          <CouvetteScaleDetailsTitle
            onClick={() => {
              onClickHowToMeasureEvent()
              setDigitalCouvetteDescriptionToggle(!digitalCouvetteDescriptionToggle)
            }}
            isOpen={digitalCouvetteDescriptionToggle}
          >
            <RulerIcon height={pxToRem(20)} width={pxToRem(24)} />
            {t('FrameAdvisor.couvette.howToMeasure')}
            <CaretDownIcon
              height={pxToRem(36)}
              width={pxToRem(36)}
              className={digitalCouvetteDescriptionToggle ? 'open' : ''}
            />
          </CouvetteScaleDetailsTitle>
          <CouvetteScaleDetailsReferenceContainer>
            <SwitcherWithLabel
              showOnOff={false}
              onChange={toggleReferenceImage}
              checked={showReferenceImage}
              label={t('FrameAdvisor.couvette.referenceGlasses')}
            />
          </CouvetteScaleDetailsReferenceContainer>
        </CouvetteScaleDetailsTitleContainer>
        {digitalCouvetteDescriptionToggle && (
          <CouvetteScaleDetailsContent>
            <CouvetteDetailCard>
              <GeolocatorIcon height={pxToRem(24)} width={pxToRem(24)} />
              {t('FrameAdvisor.couvette.howToMeasureStep1')}
            </CouvetteDetailCard>
            <CouvetteDetailCard>
              <FrameWidthIcon height={pxToRem(24)} width={pxToRem(24)} />
              {t('FrameAdvisor.couvette.howToMeasureStep2')}
            </CouvetteDetailCard>
            <CouvetteDetailCard>
              <FrameMeasureIcon height={pxToRem(24)} width={pxToRem(24)} />
              {t('FrameAdvisor.couvette.howToMeasureStep3')}
            </CouvetteDetailCard>
          </CouvetteScaleDetailsContent>
        )}
      </div>
      <CouvetteScaleContainer sizes={calibrationSizes}>
        <CouvetteScaleColumn className="columns" />
        {Object.keys(DIGITAL_COUVETTE_SIZES).map((size, index) => (
          <CouvetteScaleColumn className="columns" selected={selectedSize === size} key={index}>
            <span>{size}</span>
          </CouvetteScaleColumn>
        ))}
        <CouvetteGuideLine
          size={selectedSize}
          data-measure={`${getSizeMeasure(selectedSize)} mm`}
          width={`${selectedSizeWidth}px`}
        >
          {selectedSize && <GeolocatorIcon height={pxToRem(24)} width={pxToRem(24)} />}
        </CouvetteGuideLine>
        {showReferenceImage && selectedSize && (
          <ReferenceGlassContainer width={referenceGlassesWith} />
        )}
      </CouvetteScaleContainer>
      <RestartCalibrationPopup
        visible={showRestartPopup}
        onClose={toggleRestartPopup}
        onConfirm={onRestartCalibration}
      />
    </div>
  )
}

export default CouvetteScale
