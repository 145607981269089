import { UndoData } from 'types/frameAdvisor'

/**
 * Returns an object with the data required for the UNDO feature on Frame advisor
 * @returns {UndoData} UndoData object
 */
export const formatProductForUndo = (product: UndoData) => ({
  moco: product.moco,
  name: product.name,
  model: product.model,
  UPC: product.UPC,
  brand: product.brand,
})

export const notNull = <T>(x: T | null): x is T => x !== null

export const stringToNumber = (string: string, fractionDigits: number) => {
  const number = parseFloat(string)
  if (isNaN(number)) {
    return string
  }
  return Number.isInteger(number) ? number : parseFloat(number.toFixed(fractionDigits))
}
