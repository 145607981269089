import { FlowsActionTypes, SET_BARCODE_FLOW } from 'actions/flows'

export interface FlowsState {
  barcodeFlow: boolean
}

const initialState: FlowsState = {
  barcodeFlow: false,
}

export default (state = initialState, action: FlowsActionTypes) => {
  switch (action.type) {
    case SET_BARCODE_FLOW:
      return { ...state, barcodeFlow: action.payload.show }
    default:
      return state
  }
}
