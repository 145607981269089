import { TermsAndConditionsModal } from 'components/TermsAndConditionsModal'
import { pxToRem } from 'libs/styled'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PrivacyPolicyModal } from '../PrivacyPolicyModal'
const DisclaimerDiv = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 1rem;
  left: 0;
  margin-left: 2rem;
  padding: ${pxToRem(20)} ${pxToRem(24)};
  font-size: 0.875rem;
  /* color: ${({ theme }) => theme.colors.secondaryText}; */
  line-height: 1.5;
  text-align: left;
  background: #fff;
  a {
    color: ${({ theme }) => theme.colors.secondaryText};
    text-decoration: underline;
  }
`
interface DisclaimerProps {
  className?: string
}

export const Disclaimer: React.FC<DisclaimerProps> = ({ className }) => {
  const { t } = useTranslation()
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false)
  const [termsModalVisible, setTermsModalVisible] = useState(false)

  return (
    <DisclaimerDiv className={className}>
      <Trans i18nKey="Core.disclaimer">
        Product images and virtual model may distort or otherwise not provide an accurate
        representation of the product and its color(s), textures, size as well as your face and
        facial features and the fit of the glasses. Read our
      </Trans>{' '}
      <a data-element-id="Footer_Privacy" onClick={() => setPrivacyModalVisible(true)}>
        {t('Core.privacyPolicyLabel')}
      </a>{' '}
      {t('FrameAdvisor.frameSizeBox.conjunction')}{' '}
      <a data-element-id="Footer_Privacy" onClick={() => setTermsModalVisible(true)}>
        {t('Core.termsAndConditionsLabel')}
      </a>
      <PrivacyPolicyModal
        visible={privacyModalVisible}
        onCancel={() => setPrivacyModalVisible(false)}
      />
      <TermsAndConditionsModal
        visible={termsModalVisible}
        onCancel={() => setTermsModalVisible(false)}
      />
    </DisclaimerDiv>
  )
}
