import { DataAttrs } from './product'

export interface AccordionStateProps {
  isOpen: boolean
  onClick?: () => void
}

export interface AccordionSingleProps {
  title: React.ReactNode
  subtitle?: React.ReactNode
  className?: string
  isInitiallyOpen?: boolean
  renderAccordionIcon?: (() => React.ReactNode) | undefined
  closeByClickingOutside?: boolean
  dataAttr?: DataAttrs
  onClick?: (element: HTMLElement) => void
  onClose?: (element: HTMLElement) => void
  onTitleClick?: React.MouseEventHandler<HTMLElement>
  isOpened?: boolean
  caretIconColor?: string
  onClickAccordionIcon?: () => void
  accordionIconPosition?: keyof typeof AccordionIconPosition
}

export enum AccordionIconPosition {
  default = 'default',
  bottom = 'bottom',
}
