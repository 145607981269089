import { IconProps } from 'types/icons'
import React from 'react'
export const FilterIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      strokeWidth={0}
    >
      <g clipPath="url(#clip0_1874_61699)">
        <path
          d="M12 3.58333V1.58333C12 0.846667 11.4033 0.25 10.6667 0.25H5.33333C4.59667 0.25 4 0.846667 4 1.58333V3.58333H0V4.91667H4V6.91667C4 7.65333 4.59667 8.25 5.33333 8.25H10.6667C11.4033 8.25 12 7.65333 12 6.91667V4.91667H32V3.58333H12ZM10.6667 6.91667H5.33333V1.58333H10.6667V6.91667Z"
          fill={fill}
        />
        <path
          d="M20 13.5833C20 12.8467 19.4033 12.25 18.6667 12.25H13.3333C12.5967 12.25 12 12.8467 12 13.5833V15.5833H0V16.9167H12V18.9167C12 19.6533 12.5967 20.25 13.3333 20.25H18.6667C19.4033 20.25 20 19.6533 20 18.9167V16.9167H32V15.5833H20V13.5833ZM18.6667 18.9167H13.3333V13.5833H18.6667V18.9167Z"
          fill={fill}
        />
        <path
          d="M28 25.5833C28 24.8467 27.4033 24.25 26.6667 24.25H21.3333C20.5967 24.25 20 24.8467 20 25.5833V27.5833H0V28.9167H20V30.9167C20 31.6533 20.5967 32.25 21.3333 32.25H26.6667C27.4033 32.25 28 31.6533 28 30.9167V28.9167H32V27.5833H28V25.5833ZM26.6667 30.9167H21.3333V25.5833H26.6667V30.9167Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1874_61699">
          <rect width={32} height={32} fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}
