import styled from 'styled-components'

export const LanguageSelectorContainer = styled.div`
  display: block;
`

export const LanguageSelectorToggleButton = styled.span`
  display: inline-flex;
  width: 2.273rem;

  &.rotate {
    svg {
      height: 2.273rem;
      transform: rotate(180deg);
    }
  }
`

export const LanguageSelectorTitle = styled.h3`
  margin: 0;
  display: flex;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.364rem;
  font-weight: 300;
  color: ${({ theme }) => theme.menu.textColor};
`

export const LanguageSelectorList = styled.ul`
  position: absolute;
  left: 0;
  width: 100%;
  height: 80vh;
  overflow: auto;
`

export const LanguageSelectorListItem = styled.a`
  padding: 1.5rem 3.091rem 1.455rem;
  display: block;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.364rem;
  font-weight: 300;
  color: ${({ theme }) => theme.menu.textColor};

  &.active {
    font-weight: bold;
  }
`

export const CurrentLanguage = styled.b`
  margin-left: 0.364rem;
  font-weight: bold;
`
