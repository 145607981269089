import styled from 'styled-components'

export const ErrorMessageWrapper = styled.div`
  padding: 20px 56px;
  text-align: left;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fdd7dc;
`

export const ErrorMessageTitle = styled.h5`
  margin-bottom: 0.25rem;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.125rem;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.monza};
`

export const ErrorMessageText = styled.p`
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.125rem;
`
