import React from 'react'
import { IconProps } from 'types/icons'

export const FaTipsLookStraightIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 99 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="49.152" cy="49.4557" r="48.128" stroke={stroke} strokeWidth="2.048" />
      <path
        d="M73.7281 48.4319H68.5558C68.0367 38.5226 60.0853 30.5713 50.1761 30.0521V24.8799H48.1281V30.0521C38.2188 30.5713 30.2674 38.5226 29.7483 48.4319H24.576V50.4799H29.7483C30.2674 60.3891 38.2188 68.3405 48.1281 68.8597V74.0319H50.1761V68.8597C60.0853 68.3405 68.0367 60.3891 68.5558 50.4799H73.7281V48.4319ZM50.1761 66.8117V61.7439H48.1281V66.8117C39.3473 66.2976 32.3103 59.2607 31.7963 50.4799H36.864V48.4319H31.7963C32.3103 39.6511 39.3473 32.6142 48.1281 32.1001V37.1679H50.1761V32.1001C58.9569 32.6142 65.9938 39.6511 66.5078 48.4319H61.4401V50.4799H66.5078C65.9938 59.2607 58.9569 66.2976 50.1761 66.8117Z"
        fill={fill}
      />
    </svg>
  )
}
