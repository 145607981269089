import {
  CalibrationMethods,
  DigitalCouvetteSizeWidths,
  Frame,
  Size,
} from 'components/DigitalCouvette/types'

export const SET_SELECTED_FRAME = 'couvette/SET_SELECTED_FRAME'
export const SET_SHOW_CALIBRATION = 'couvette/SET_SHOW_CALIBRATION'
export const SET_CALIBRATION_METHOD = 'couvette/SET_CALIBRATION_METHOD'
export const RESET_COUVETTE = 'couvette/RESET'
export const SET_CALIBRATION_COMPLETED = 'couvette/SET_CALIBRATION_COMPLETED'
export const SET_SHOW_COUVETTE_SCALE = 'couvette/SET_SHOW_COUVETTE_SCALE'
export const SET_CALIBRATION_SIZES = 'couvette/SET_CALIBRATION_SIZES'
export const SET_SELECTED_SIZE = 'couvette/SET_SELECTED_SIZE'

export interface SetSelectedFrame {
  type: typeof SET_SELECTED_FRAME
  payload: Frame
}

export interface SetShowCalibration {
  type: typeof SET_SHOW_CALIBRATION
  payload: boolean
}

export interface SetCalibrationMethod {
  type: typeof SET_CALIBRATION_METHOD
  payload: keyof typeof CalibrationMethods
}

export interface ResetCouvette {
  type: typeof RESET_COUVETTE
}

export interface SetCalibrationCompleted {
  type: typeof SET_CALIBRATION_COMPLETED
  payload: boolean
}

export interface SetShowCouvetteScale {
  type: typeof SET_SHOW_COUVETTE_SCALE
  payload: boolean
}

export interface SetCalibrationSizes {
  type: typeof SET_CALIBRATION_SIZES
  payload: DigitalCouvetteSizeWidths
}

export interface SetSelectedSize {
  type: typeof SET_SELECTED_SIZE
  payload: Size
}

export const setSelectedFrame = (payload: Frame): SetSelectedFrame => ({
  type: SET_SELECTED_FRAME,
  payload,
})

export const setShowCalibration = (payload: boolean): SetShowCalibration => ({
  type: SET_SHOW_CALIBRATION,
  payload,
})

export const setCalibrationMethod = (
  payload: keyof typeof CalibrationMethods
): SetCalibrationMethod => ({
  type: SET_CALIBRATION_METHOD,
  payload,
})

export const resetCouvette = (): ResetCouvette => ({
  type: RESET_COUVETTE,
})

export const setCalibrationCompleted = (payload: boolean) => ({
  type: SET_CALIBRATION_COMPLETED,
  payload,
})

export const setShowCouvetteScale = (payload: boolean): SetShowCouvetteScale => ({
  type: SET_SHOW_COUVETTE_SCALE,
  payload,
})

export const setCalibrationSizes = (payload: DigitalCouvetteSizeWidths): SetCalibrationSizes => ({
  type: SET_CALIBRATION_SIZES,
  payload,
})

export const setSelectedSize = (payload: Size): SetSelectedSize => ({
  type: SET_SELECTED_SIZE,
  payload,
})

export type CouvetteActionTypes =
  | SetSelectedFrame
  | SetShowCalibration
  | SetCalibrationMethod
  | ResetCouvette
  | SetCalibrationCompleted
  | SetShowCouvetteScale
  | SetCalibrationSizes
  | SetSelectedSize
