import referenceGlasses from 'assets/lightReferenceGlasses.svg'
import { Heading5 } from 'components/Typography'
import { AccordionLayout } from 'components/core/Accordion/AccordionLayout'
import { PrimaryButton } from 'components/core/Button'
import { GeolocatorIcon } from 'components/core/Icons'
import { Toggler, TogglerButton } from 'components/core/Toggler'
import { Device, landscape } from 'libs/media'
import { clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'
import { DottedLine } from '../styles'

const DOTTED_LINES_WIDTH = 524
const Y_AXIS_BOTTOM_DISTANCE = 200
const CALIBRATION_START_POINT_SIZE = 50

export const CalibrationContainer = styled.div<{ openedAccordion: boolean }>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  .range-and-glasses-container {
    margin-top: ${({ openedAccordion }) => (openedAccordion ? '0' : pxToRem(80))};
  }
`

export const CalibrationAccordion = styled(AccordionLayout)`
  width: ${pxToRem(1032)};
  margin: 0 auto;
  padding: ${pxToRem(40)} ${pxToRem(16)};
  background-color: ${({ theme }) => theme.colors.wildSand};
  border: ${({ theme }) => `1px solid ${theme.colors.frenchGrey}`};
`

export const CalibrationFrameAccordionTitle = styled(Heading5)`
  width: ${pxToRem(510)};
  height: ${pxToRem(46)};
  border-radius: ${pxToRem(23)};
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${pxToRem(8)} ${pxToRem(16)};
  ${landscape(
    Device.tablet,
    `
    width: ${clampValuesLandscape(473, 620)}
  `
  )}
`

export const CalibrationRulerAccordionTitle = styled(Heading5)`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: ${pxToRem(24)};
  }
`

export const CalibrationAccordionContent = styled.div`
  text-align: left;
  width: 100%;
`

export const CalibrationInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${pxToRem(16)};
  gap: ${pxToRem(16)};
`

export const CalibrationFrameChanger = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${pxToRem(8)};
`

export const CalibrationTrack = styled.div`
  display: flex;
  align-items: center;
  height: 2px;
  width: ${pxToRem(1024)};
  background-color: ${({ theme }) => theme.cta.backgroundColor};
  position: relative;
  margin-top: ${pxToRem(265)};
  &::before {
    content: '';
    width: 100%;
    border-bottom: ${({ theme }) => `2px solid ${theme.cta.backgroundColor}`};
    margin-left: -50%;
  }
`

export const CalibrationThumb = styled.div`
  margin-top: ${pxToRem(CALIBRATION_START_POINT_SIZE / 2)};
  ${DottedLine} {
    position: absolute;
    height: ${pxToRem(DOTTED_LINES_WIDTH)};
    left: 49%;
    top: ${pxToRem(-Y_AXIS_BOTTOM_DISTANCE)};
  }
`

export const ThumbIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(${clampValuesLandscape(-110, -150)});
`

export const Thumb = styled.div`
  background-color: ${({ theme }) => theme.cta.backgroundColor};
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  border-radius: 50%;
`

export const CalibrationFooter = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  bottom: ${pxToRem(80)};
  z-index: 2;
  width: 100%;
  justify-content: center;
`

export const CalibrationConfirm = styled(PrimaryButton)``

export const RangeAndGlassContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const ReferenceObjectPlaceholder = styled(Heading5)`
  font-weight: bold;
`

export const ReferenceGlassContainer = styled.div`
  position: absolute;
  width: ${pxToRem(811)};
  height: ${pxToRem(650)};
  background-image: ${`url(${referenceGlasses})`};
  background-size: 100%;
  background-position: 0 36px;
  background-repeat: no-repeat;
  left: ${pxToRem(-45)};
  top: ${pxToRem(-130)};
  padding-top: ${pxToRem(75)};
`

export const ReferenceRulerContainer = styled.div`
  width: ${pxToRem(548)};
  height: ${pxToRem(79)};
  background-color: ${({ theme }) => theme.colors.frenchGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: ${pxToRem(-20)};
  bottom: ${pxToRem(30)};
`

export const RangeStyled = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  left: ${pxToRem(75)};
  z-index: 2;
  padding-left: ${pxToRem(100)};
`

export const CalibrationStartIcon = styled(GeolocatorIcon)`
  position: relative;
`

export const CalibrationStartPoint = styled.div`
  margin-left: ${pxToRem(-25)};
  width: ${pxToRem(CALIBRATION_START_POINT_SIZE)};
  height: ${pxToRem(CALIBRATION_START_POINT_SIZE)};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${DottedLine} {
    height: ${pxToRem(DOTTED_LINES_WIDTH)};
    position: absolute;
    left: 49%;
    top: ${pxToRem(-Y_AXIS_BOTTOM_DISTANCE)};
  }
  &::before {
    display: block;
    content: '';
    position: absolute;
    border-radius: 50%;
    width: ${pxToRem(15)};
    height: ${pxToRem(15)};
    background-color: ${({ theme }) => theme.cta.backgroundColor};
  }
`

export const MeasureIndicator = styled.div<{ visible: boolean }>`
  width: ${pxToRem(1024)};
  position: absolute;
  bottom: ${pxToRem(200)};
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: ${({ theme }) => theme.cta.backgroundColor};
  border-bottom: ${({ theme }) => `1px solid ${theme.cta.backgroundColor}`};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  svg {
    transform: translateY(5px);
  }
`

export const MeasureUnitToggle = styled(Toggler)`
  width: ${pxToRem(192)};
  height: ${pxToRem(42)};
  margin-top: ${pxToRem(16)};
`

export const MeasureUnitToggleButton = styled(TogglerButton)`
  width: 50%;
`

export const CalibrationCompletedStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    margin-bottom: ${pxToRem(24)};
  }
  h1 {
    margin-bottom: ${pxToRem(16)};
  }
`

export const CalibrationContinueBtn = styled(PrimaryButton)`
  margin-top: ${pxToRem(79)};
`
