import styled, { css } from 'styled-components'
import { pxToRem } from 'libs/styled'
import { CouvetteModal } from 'components/FrameAdvisor/DigitalCouvette/styles'
import { CloseIconContainer, ModalContent } from 'components/core/Modal/styles'

export const DigitalCouvetteLink = styled.button`
  width: 100%;
  background-color: ${({ theme }) => `${theme.colors.wildSand}`};
  border-radius: ${pxToRem(50)};
  text-align: center;
  text-decoration: underline;
  padding: ${pxToRem(15)} ${pxToRem(20)};
  margin: ${pxToRem(32)} 0;
`

export const DigitalCouvetteModal = styled(CouvetteModal)`
  .ant-modal-body {
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;

    ${CloseIconContainer} {
      width: ${pxToRem(64)};
      height: ${pxToRem(64)};
      padding: ${pxToRem(18)};
      top: 0;
      right: 0;
      border: ${({ theme }) => `1px solid ${theme.colors.frenchGrey}`};
    }
  }

  ${ModalContent} {
    height: 100%;
  }
`

export const Bold = styled.span`
  font-weight: bold;
`

export const UnderlineText = styled.span`
  text-decoration: underline;
`

export const DottedLine = styled.div<{ orientation: 'vertical' | 'horizontal' }>`
  ${({ orientation }) => {
    return orientation === 'vertical'
      ? css`
          border-left: ${({ theme }) => `2px dotted ${theme.cta.backgroundColor}`};
        `
      : css`
          border-top: ${({ theme }) => `2px dotted ${theme.cta.backgroundColor}`};
        `
  }}
`
