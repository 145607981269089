import React from 'react'
import { IconProps } from 'types/icons'

export const MinusIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = 'none',
  stroke = '#555',
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        d="M21 12.75H3.551"
      ></path>
    </svg>
  )
}
