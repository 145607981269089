import React from 'react'

export const BarcodeIcon: React.FC<{
  width?: string
  height?: string
  fill?: string
}> = ({ width = '100%', height = '100%', fill = 'black' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.68 7.18652H18.3467V24.4532H19.68V7.18652Z" fill={fill} />
    <path d="M13.6536 7.18652H12.3203V24.4532H13.6536V7.18652Z" fill={fill} />
    <path d="M22.6937 7.18652H21.3604V24.4532H22.6937V7.18652Z" fill={fill} />
    <path d="M28.7201 7.18652H27.3867V24.4532H28.7201V7.18652Z" fill={fill} />
    <path d="M10.64 7.18652H9.30664V24.4532H10.64V7.18652Z" fill={fill} />
    <path
      d="M26.0866 4.33333H30.6666V8.91333C30.6666 9.27999 30.9666 9.58 31.3333 9.58C31.7066 9.58 31.9999 9.27999 31.9999 8.91333V3.66667C31.9999 3.29334 31.7066 3 31.3333 3H26.0866C25.7199 3 25.4199 3.29334 25.4199 3.66667C25.4199 4.03333 25.7199 4.33333 26.0866 4.33333Z"
      fill={fill}
    />
    <path d="M7.63314 7.18652H6.2998V24.4532H7.63314V7.18652Z" fill={fill} />
    <path
      d="M0.666667 9.58C1.04 9.58 1.33333 9.27999 1.33333 8.91333V4.33333H5.92001C6.28666 4.33333 6.58667 4.03333 6.58667 3.66667C6.58667 3.29334 6.28666 3 5.92001 3H0.666667C0.300008 3 0 3.29334 0 3.66667V8.91333C0 9.27999 0.300008 9.58 0.666667 9.58Z"
      fill={fill}
    />
    <path d="M25.7064 7.18652H24.373V24.4532H25.7064V7.18652Z" fill={fill} />
    <path d="M16.6663 7.18652H15.333V24.4532H16.6663V7.18652Z" fill={fill} />
    <path
      d="M31.9999 23.0798C31.9999 22.7131 31.7066 22.4131 31.3333 22.4131C30.9666 22.4131 30.6666 22.7131 30.6666 23.0798V27.6664H26.0866C25.7199 27.6664 25.4199 27.9598 25.4199 28.3331C25.4199 28.6998 25.7199 28.9998 26.0866 28.9998H31.3333C31.7066 28.9998 31.9999 28.6998 31.9999 28.3331V23.0798Z"
      fill={fill}
    />
    <path d="M4.62044 7.18652H3.28711V24.4532H4.62044V7.18652Z" fill={fill} />
    <path
      d="M0.666667 28.9998H5.92001C6.28666 28.9998 6.58667 28.6998 6.58667 28.3331C6.58667 27.9598 6.28666 27.6664 5.92001 27.6664H1.33333V23.0798C1.33333 22.7131 1.04 22.4131 0.666667 22.4131C0.300008 22.4131 0 22.7131 0 23.0798V28.3331C0 28.6998 0.300008 28.9998 0.666667 28.9998Z"
      fill={fill}
    />
  </svg>
)
