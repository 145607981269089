import { PrimaryButton } from 'components/core/Button'
import { Select, SelectOption } from 'components/core/Select'
import ErrorWidget from 'components/ErrorWidget'
import Loading from 'components/Loading'
import { useFrameModelsDC } from 'data'
import { parseGrapqhQLError } from 'libs/errors'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContainerStartPageDigitalCouvette,
  DigitalCouvetteHeading1,
  DigitalCouvetteHeading4,
  DigitalCouvetteHeading5Styled,
  DigitalCouvetteLinkTypography,
  DigitalCouvetteSelectWrapper,
  DigitalCouvetteStyledCaption,
} from './styles'
import { formatSelectedFramePlaceholder } from '../utils'
import { Frame } from '../types'
import { useActions } from 'hooks/useActions'
import { setSelectedFrame } from 'actions/couvette'
import { useDigitalCouvetteAnalytics } from '../analytics'

interface Props {
  selectedFrame?: Frame
  startCalibration: () => void
  onClickRulerCalibration: () => void
}

const WelcomePageDigitalCouvette = ({
  selectedFrame,
  startCalibration,
  onClickRulerCalibration,
}: Props) => {
  const { t } = useTranslation()
  const actions = useActions({ setSelectedFrame })
  const { onLandingPageView } = useDigitalCouvetteAnalytics()

  useEffect(() => {
    onLandingPageView()
  }, [onLandingPageView])

  const {
    loading: framesDataLoading,
    error: framesDataError,
    data: framesData,
  } = useFrameModelsDC()

  if (framesDataLoading || !framesData) {
    return <Loading />
  }

  if (framesDataError) {
    return <ErrorWidget {...parseGrapqhQLError(framesDataError)} withWrapper />
  }

  const frames = framesData.digitalCouvetteFrames

  const handleSelectFrame = (model: string) => {
    const selectedFrame = frames.find(f => f.model === model)
    actions.setSelectedFrame(selectedFrame)
  }

  return (
    <ContainerStartPageDigitalCouvette>
      <DigitalCouvetteHeading1>{t('DigitalCouvette.startPage.welcome')}</DigitalCouvetteHeading1>
      <DigitalCouvetteHeading4 weight="bold">
        {t('DigitalCouvette.startPage.calibrate')}
      </DigitalCouvetteHeading4>
      <DigitalCouvetteHeading5Styled>
        {t('DigitalCouvette.startPage.selectModel')}
      </DigitalCouvetteHeading5Styled>
      <DigitalCouvetteSelectWrapper>
        <DigitalCouvetteStyledCaption>
          {t('DigitalCouvette.startPage.selectFrame')}
        </DigitalCouvetteStyledCaption>
        <Select
          placeholder={t('DigitalCouvette.startPage.placeHolderSelectModel')}
          onSelect={handleSelectFrame}
        >
          {frames.map((frame, idx) => (
            <SelectOption key={idx} value={frame.model}>
              {formatSelectedFramePlaceholder(frame)}
            </SelectOption>
          ))}
        </Select>
      </DigitalCouvetteSelectWrapper>
      <PrimaryButton disabled={!selectedFrame} onClick={startCalibration}>
        {t('DigitalCouvette.startPage.startCalibration')}
      </PrimaryButton>
      <DigitalCouvetteLinkTypography onClick={onClickRulerCalibration}>
        {t('DigitalCouvette.startPage.useRuler')}.
      </DigitalCouvetteLinkTypography>
    </ContainerStartPageDigitalCouvette>
  )
}

export default WelcomePageDigitalCouvette
