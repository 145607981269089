import { Disclaimer } from 'components/Disclaimer'
import { InfoIcon } from 'components/core/Icons'
import { useClickOutside } from 'hooks/useClickOutside'
import { clampValues, pxToRem } from 'libs/styled'
import { isIPadView } from 'libs/utils'
import React, { useContext, useRef, useState } from 'react'
import { DefaultTheme, ThemeContext } from 'styled-components'
import { PrivacyPolicyTooltipContainer, PrivacyPolicyTooltipWrapper } from './styles'

interface PrivacyPolicyTooltipProps {
  stroke?: string
  className?: string
  isFixed?: boolean
}

const PrivacyPolicyTooltip: React.FC<PrivacyPolicyTooltipProps> = ({
  stroke,
  className,
  isFixed = false,
}) => {
  const theme = useContext<DefaultTheme>(ThemeContext)
  stroke = stroke || theme.colors.primaryText

  const tooltipRef = useRef<HTMLDivElement>(null)
  const [tooltipVisible, setPrivacyTooltipVisible] = useState(false)
  useClickOutside(tooltipRef, () => setPrivacyTooltipVisible(false))

  return (
    <PrivacyPolicyTooltipContainer ref={tooltipRef} className={className} isFixed={isFixed}>
      <button onClick={() => setPrivacyTooltipVisible(s => !s)}>
        <InfoIcon
          stroke={stroke}
          width={isIPadView() ? clampValues(24, 32) : pxToRem(32)}
          height={isIPadView() ? clampValues(24, 32) : pxToRem(32)}
        />
      </button>
      <PrivacyPolicyTooltipWrapper visible={tooltipVisible}>
        <Disclaimer />
      </PrivacyPolicyTooltipWrapper>
    </PrivacyPolicyTooltipContainer>
  )
}

export default PrivacyPolicyTooltip
