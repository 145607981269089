import styled, { css } from 'styled-components'
import { ButtonExtraStyles, ButtonVariant } from 'types/components'

export const PrimaryButtonStyles = css`
  background-color: ${({ theme }) => theme.cta.backgroundColor};
  color: ${({ theme }) => theme.cta.textColor};
  text-align: ${({ theme }) => theme.cta.textAlignment};
  border-radius: ${({ theme }) => theme.cta.borderRadius};
`

export const SecondaryButtonStyles = css`
  background-color: ${({ theme }) => theme.cta.secondaryBackgroundColor};
  border: ${({ theme }) => `1px solid ${theme.cta.secondaryTextColor}`};
  color: ${({ theme }) => theme.cta.secondaryTextColor};
  border-radius: ${({ theme }) => theme.cta.secondaryBorderRadius};
  text-align: ${({ theme }) => theme.cta.secondaryTextAlignment};
`

const variantMap = {
  primary: PrimaryButtonStyles,
  secondary: SecondaryButtonStyles,
}

export const ExtraStyles = css<{ extraStyles: ButtonExtraStyles }>`
  ${({ extraStyles }) => extraStyles.textColor && `color: ${extraStyles.textColor};`}
  ${({ extraStyles }) =>
    extraStyles.backgroundColor && `background-color: ${extraStyles.backgroundColor};`}
  ${({ extraStyles }) =>
    extraStyles.borderRadius && `border-radius: ${extraStyles.borderRadius}px;`}
  ${({ extraStyles }) => extraStyles.textAlignment && `text-align: ${extraStyles.textAlignment};`}
`

export const ButtonStyled = styled.button<{
  extraStyles: ButtonExtraStyles
  variant: ButtonVariant
}>`
  padding: 0 1.37em;
  display: inline-block;
  height: ${({ theme }) => theme.elementsNewUi.button.height};
  font-size: 0.91rem;
  line-height: normal;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;
  letter-spacing: 0.2px;
  border-radius: ${({ theme }) => theme.cta.borderRadius};
  text-align: ${({ theme }) => theme.cta.textAlignment};
  white-space: nowrap;

  :disabled {
    opacity: 5%;
  }

  ${({ variant }) => variantMap[variant]};
  ${ExtraStyles};
`
