import { IconProps } from 'types/icons'
import React from 'react'

export const ButterflyIcon = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.01 0H44.97C42.06 0 30.05 1.41 26.81 6.73C25.95 6.27 25 6 24 6C23 6 22.03 6.25 21.17 6.71C17.92 1.41 5.94 0 3.03 0H2.99C0.76 0.01 0 1.88 0 3.68V12.9C0 17.45 1.98 19.19 7.99 19.19C14.01 19.19 22 17.07 22 9.55C22 9.23 21.96 8.91 21.91 8.61C22.53 8.22 23.25 8 24 8C24.75 8 25.47 8.21 26.09 8.6C26.04 8.91 26 9.22 26 9.55C26 17.07 33.99 19.19 40.01 19.19C46.02 19.19 48 17.45 48 12.9V3.68C48 1.88 47.24 0.01 45.01 0ZM16.31 15.43C13.44 16.96 9.87 17.19 7.99 17.19C2.28 17.19 2 15.64 2 12.9V3.68C2 2.03 2.682 2.001 2.988 2C4.536 2.004 9.057 2.445 13.15 3.8C16.28 4.84 20 6.67 20 9.55C20 12.18 18.79 14.1 16.31 15.43ZM40.01 17.19C45.72 17.19 46 15.64 46 12.9H46.001V3.68C46.001 2.027 45.316 2 45.011 2C43.465 2.003 38.946 2.445 34.85 3.8C31.72 4.84 28 6.67 28 9.55C28 12.18 29.21 14.1 31.69 15.43C34.56 16.96 38.13 17.19 40.01 17.19Z"
        fill={fill}
      />
    </svg>
  )
}
