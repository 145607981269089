import { NuanceTheme } from 'styled-components'

const nuanceFontFamily = 'TTHoves'

const colors: NuanceTheme['colors'] = {
  primaryText: '#202027',
  secondaryText: '#FFFFFF',
  bodyBackground: '#F6F6F6',
  secondary: '#3D50FA',
  inactive: '#C1C1C1',
}

const fonts: NuanceTheme['fonts'] = {
  heading0: {
    name: nuanceFontFamily,
    weight: 400,
    towerSize: 85,
    tabletSize: 68,
    tabletMiniSize: 48,
    towerLineHeight: 109,
    tabletLineHeight: 86,
    tabletMiniLineHeight: 68,
  },
  heading1: {
    name: nuanceFontFamily,
    weight: 400,
    towerSize: 45,
    tabletSize: 42,
    tabletMiniSize: 36,
    towerLineHeight: 50,
    tabletLineHeight: 44,
    tabletMiniLineHeight: 44,
  },
  heading2: {
    name: nuanceFontFamily,
    weight: 400,
    towerSize: 40,
    tabletSize: 36,
    tabletMiniSize: 32,
    towerLineHeight: 44,
    tabletLineHeight: 44,
    tabletMiniLineHeight: 38,
  },
  heading3: {
    name: nuanceFontFamily,
    weight: 400,
    bold: 600,
    towerSize: 35,
    tabletSize: 36,
    tabletMiniSize: 30,
    towerLineHeight: 48,
    tabletLineHeight: 44,
    tabletMiniLineHeight: 40,
  },
  heading4: {
    name: nuanceFontFamily,
    weight: 400,
    bold: 600,
    towerSize: 24,
    tabletSize: 28,
    tabletMiniSize: 30,
    towerLineHeight: 34,
    tabletLineHeight: 38,
    tabletMiniLineHeight: 40,
  },
  heading5: {
    name: nuanceFontFamily,
    weight: 400,
    bold: 600,
    towerSize: 22,
    tabletSize: 22,
    tabletMiniSize: 18,
    towerLineHeight: 33,
    tabletLineHeight: 28,
    tabletMiniLineHeight: 28,
  },
  body: {
    name: nuanceFontFamily,
    weight: 400,
    bold: 600,
    towerSize: 20,
    tabletSize: 16,
    tabletMiniSize: 16,
    towerLineHeight: 26,
    tabletLineHeight: 22,
    tabletMiniLineHeight: 22,
  },
  label: {
    name: nuanceFontFamily,
    weight: 400,
    towerSize: 16,
    tabletSize: 14,
    tabletMiniSize: 14,
    towerLineHeight: 22,
    tabletLineHeight: 20,
    tabletMiniLineHeight: 18,
  },
  cta: {
    name: nuanceFontFamily,
    weight: 400,
    bold: 500,
    towerSize: 18,
    tabletSize: 16,
    tabletMiniSize: 16,
    towerLineHeight: 22,
    tabletLineHeight: 20,
    tabletMiniLineHeight: 20,
  },
  caption: {
    name: nuanceFontFamily,
    weight: 600,
    towerSize: 14,
    tabletSize: 12,
    tabletMiniSize: 12,
    towerLineHeight: 16,
    tabletLineHeight: 14,
    tabletMiniLineHeight: 14,
  },
  link: {
    name: nuanceFontFamily,
    weight: 400,
    towerSize: 18,
    tabletSize: 16,
    tabletMiniSize: 16,
    towerLineHeight: 34,
    tabletLineHeight: 34,
    tabletMiniLineHeight: 34,
  },
}

export default {
  colors,
  fonts,
}
