import ConditionalRender from 'components/ConditionalRender'
import { NoDataSuggestionList } from 'components/Contents/NoDataContent'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Product } from 'types/product'
import { NotFoundMessage } from './NotFoundMessage'

interface SimilarStylesContentProps {
  similarStylesProducts: Product[]
}

export const SimilarStylesContent: React.FC<SimilarStylesContentProps> = ({
  similarStylesProducts,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <NotFoundMessage />
      <ConditionalRender condition={similarStylesProducts?.length > 0}>
        <NoDataSuggestionList
          items={similarStylesProducts}
          title={t('PlpFilters.youMayAlsoLike')}
        />
      </ConditionalRender>
    </>
  )
}
