import { AccordionIconPosition, AccordionSingleProps } from 'types/accordion'
import { CaretDownThinIcon } from 'components/core/Icons'
import { pxToRem } from 'libs/styled'
import { isIPadView } from 'libs/utils'
import { uniqueId } from 'lodash'
import React, { useCallback, useMemo, useRef } from 'react'
import {
  AccordionContent,
  AccordionFooter,
  AccordionIcon,
  AccordionLayoutWrapper,
  AccordionSubtitle,
  AccordionTitle,
} from './styles'
import ConditionalRender from 'components/ConditionalRender'

export const AccordionLayout: React.FC<AccordionSingleProps> = ({
  children,
  className,
  title,
  subtitle,
  renderAccordionIcon,
  dataAttr,
  isOpened = false,
  onClick,
  onTitleClick,
  onClose,
  onClickAccordionIcon,
  accordionIconPosition = AccordionIconPosition.default,
  ...props
}) => {
  const { id: dataId, title: dataTitle } = dataAttr || {}
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const accordionContentId = useMemo(uniqueId, [])

  const onElementClick = useCallback(() => {
    onClick && wrapperRef.current && onClick(wrapperRef.current)
    onClose && isOpened && onClose(wrapperRef.current)
  }, [onClick, onClose, isOpened])

  const handleTitleClick = useCallback<React.MouseEventHandler<HTMLElement>>(
    e => {
      onTitleClick && onTitleClick(e)
    },
    [onTitleClick]
  )

  const renderIconElement = useCallback(() => {
    return (
      <AccordionIcon
        isOpen={isOpened}
        {...(onClickAccordionIcon && { onClick: onClickAccordionIcon })}
      >
        {renderAccordionIcon ? (
          renderAccordionIcon()
        ) : (
          <CaretDownThinIcon
            height={pxToRem(isIPadView() ? 13 : 17)}
            width={pxToRem(isIPadView() ? 24 : 32)}
          />
        )}
      </AccordionIcon>
    )
  }, [isOpened, onClickAccordionIcon, renderAccordionIcon])

  return (
    <AccordionLayoutWrapper
      className={className}
      ref={wrapperRef}
      onClick={onElementClick}
      aria-expanded={isOpened}
      aria-controls={accordionContentId}
      {...(dataAttr && dataId && { 'data-element-id': dataId, 'data-test': dataId })}
      {...(isOpened && dataAttr && { 'data-analytics_available_call': '0' })}
      {...props}
    >
      <AccordionTitle onClick={handleTitleClick} data-test={dataTitle}>
        {title}
        {subtitle && <AccordionSubtitle>{subtitle}</AccordionSubtitle>}
        <ConditionalRender condition={accordionIconPosition === AccordionIconPosition.default}>
          {renderIconElement()}
        </ConditionalRender>
      </AccordionTitle>

      <AccordionContent id={accordionContentId} isOpen={isOpened}>
        {children}
      </AccordionContent>
      <ConditionalRender condition={accordionIconPosition === AccordionIconPosition.bottom}>
        <AccordionFooter>{renderIconElement()}</AccordionFooter>
      </ConditionalRender>
    </AccordionLayoutWrapper>
  )
}
