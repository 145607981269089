import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'
import { useEffect } from 'react'

export const useSendImpressionEvent = (shouldSendEvent: boolean, isOakley: boolean) => {
  const sendImpressionEvent = useSendAnalyticsEvent({
    id: 'Impression',
    Page_Section2: isOakley ? 'Barcode:CustomOO' : 'Barcode:CustomRB',
  })

  useEffect(() => {
    if (shouldSendEvent) {
      sendImpressionEvent()
    }
  }, [shouldSendEvent, sendImpressionEvent])
}

export const useSendCloseModalEvent = (isOakley: boolean) => {
  const sendCloseModalEvent = useSendAnalyticsEvent({
    id: 'Click',
    data_element_id: isOakley ? 'BarcodeResultOOCustom_Close' : 'BarcodeResultRBCustom_Close',
  })

  return sendCloseModalEvent
}
