import { useStoreContext } from 'hooks/useStoreContext'
import { isIPadView } from 'libs/utils'
import { useMemo } from 'react'

export const useVMVisibility = () => {
  const store = useStoreContext()

  return useMemo(() => {
    const isIpad = isIPadView()
    const vmEnabled = store.vmEnabled
    const ipadEnabled = isIpad && vmEnabled
    const monitorEnabled = !isIpad && vmEnabled

    return {
      isVMEnabled: vmEnabled && (ipadEnabled || monitorEnabled),
      isVMIpadEnabled: vmEnabled && ipadEnabled,
      isVMMonitorEnabled: vmEnabled && monitorEnabled,
    }
  }, [store.vmEnabled])
}
