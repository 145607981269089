import React from 'react'
import { IconProps } from 'types/icons'

export const PlaylistTipIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = 'none',
  stroke = 'none',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 239 91"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path
        d="M126.5 60H88C83.5817 60 80 56.4183 80 52V9C80 4.58172 83.5817 1 88 1H151C155.418 1 159 4.58172 159 9V52C159 56.4183 155.418 60 151 60H145.5"
        stroke="#333333"
        strokeWidth={2}
      />
      <rect x={1} y={7} width={63} height={47} rx={7} stroke="#333333" strokeWidth={2} />
      <rect x={175} y={7} width={63} height={47} rx={7} stroke="#333333" strokeWidth={2} />
      <path
        d="M148 59C147.268 59 146.591 59.212 146 59.556V59C146 56.794 144.206 55 142 55C140.8 55 139.734 55.542 139 56.382C138.266 55.542 137.2 55 136 55C135.268 55 134.591 55.212 134 55.556V47C134 44.794 132.206 43 130 43C127.794 43 126 44.794 126 47V68.464C122.653 68.673 120 71.465 120 74.888C120 78.444 124.384 87.149 125.895 90.045C126.203 90.636 126.811 91 127.477 91H146.565C147.275 91 147.919 90.581 148.205 89.931L151.846 81.681C151.948 81.452 152 81.204 152 80.953V65.157V63C152 60.794 150.206 59 148 59ZM150 80.858V80.912L146.43 89H127.605C124.198 82.455 122 76.925 122 74.889C122 72.598 123.772 70.691 126 70.483V75H128V70.128V68.076V47C128 45.897 128.897 45 130 45C131.103 45 132 45.897 132 47V59V69H134V59C134 57.897 134.897 57 136 57C137.103 57 138 57.897 138 59V69H140V59C140 57.897 140.897 57 142 57C143.103 57 144 57.897 144 59V69H146V63C146 61.897 146.897 61 148 61C149.103 61 150 61.897 150 63V63.024V65.049V65.158V80.858Z"
        fill="#333333"
      />
    </svg>
  )
}
