import React from 'react'
import { IconProps } from 'types/icons'

export const PlusIcon: React.FC<IconProps> = ({
  width = '100%',
  height = '100%',
  fill = '#555',
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M20.022 11.297h-7.319V3.978a.703.703 0 00-1.406 0v7.319H3.978a.703.703 0 000 1.406h7.319v7.319a.703.703 0 001.406 0v-7.319h7.319a.703.703 0 000-1.406z"
      ></path>
    </svg>
  )
}
