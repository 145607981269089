import { FormItemWrapper } from 'components/core/FormItem/styles'
import styled from 'styled-components'
import { pxToRem } from '../../../libs/styled'
import { Heading1, Text } from '../../Typography'

export const TipsHeading = styled(Heading1)`
  text-align: center;
`

export const BiometricDataConsentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding-inline: 96px;
  gap: 134px;
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${pxToRem(40)};

  ${Text} {
    padding: 0 80px;
  }
`

export const BiometricFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  ${FormItemWrapper} {
    margin: 0;
    width: 276px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${pxToRem(32)};

  button {
    width: 171px;
  }
`
