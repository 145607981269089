import React from 'react'
import { IconProps } from 'types/icons'
export const LSizeIcon = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
  className = '',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      strokeWidth={0}
    >
      <path
        d="M9.15527e-05 0.149902H3.12509V15.2999H12.2001V17.9999H9.15527e-05V0.149902Z"
        fill={fill}
      />
    </svg>
  )
}
