import styled from 'styled-components'

export const ColorsStyled = styled.p`
  color: ${({ theme }) => theme.productCard.colorsColor};
  order: ${({ theme }) => theme.productCard.colorsOrder};
  font-size: 0.7rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.secondaryText};
  text-align: right;
`
