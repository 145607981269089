import { IconProps } from 'types/icons'
import React from 'react'
export const ThumbDownIcon: React.FC<IconProps> = ({
  width = '1rem',
  height = '1rem',
  fill = '#333',
  stroke = '#333',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      strokeWidth={0}
    >
      <g clipPath="url(#clip0_2003_138051)">
        <path
          d="M32 12.5474C32 13.3914 31.6113 14.1394 31.0133 14.6474C31.6113 15.1554 32 15.9034 32 16.7474C32 18.2728 30.7587 19.5141 29.2333 19.5141H19.022C21.4413 25.5614 22.2527 29.0414 21.4313 29.8621C20.3527 30.9408 18.5973 30.9408 17.4873 29.8288L8.77267 19.8294C8.554 19.9854 8.28867 20.0801 8 20.0801H1.33333C0.596667 20.0801 0 19.4834 0 18.7468V2.69278C0 1.95611 0.596667 1.35944 1.33333 1.35944H8C8.73667 1.35944 9.33333 1.95611 9.33333 2.69278V3.23744L14.642 1.44944C14.7793 1.40344 14.9227 1.37944 15.0673 1.37944H26.4327C27.9587 1.37944 29.1993 2.62078 29.1993 4.14611C29.1993 4.67278 29.0433 5.16011 28.7873 5.57944H29.2327C30.7587 5.57944 31.9993 6.82078 31.9993 8.34611C31.9993 9.19078 31.6113 9.93811 31.0127 10.4461C31.612 10.9548 32 11.7028 32 12.5474ZM8 2.69278H1.33333V18.7468H8V18.6928V10.7814V4.02611V3.68678V2.69278ZM29.2333 9.78011C30.024 9.78011 30.6667 9.13678 30.6667 8.34678C30.6667 7.55678 30.0233 6.91344 29.2333 6.91344H25.0333V5.58011H26.4333C27.224 5.58011 27.8667 4.93678 27.8667 4.14678C27.8667 3.35678 27.2233 2.71344 26.4333 2.71344H15.3013H15.068L9.33333 4.64478V7.71678V9.44811V10.7814V18.4428L18.4613 28.9194C19.0047 29.4621 19.8753 29.4728 20.4367 28.9614C20.6887 28.0434 19.08 23.1661 17.4153 19.1001C17.3313 18.8941 17.3547 18.6601 17.4787 18.4761C17.6027 18.2914 17.81 18.1808 18.032 18.1808H29.2333C30.024 18.1808 30.6667 17.5381 30.6667 16.7474C30.6667 15.9568 30.0233 15.3141 29.2333 15.3141H25.0333V13.9808H29.2333C30.024 13.9808 30.6667 13.3381 30.6667 12.5474C30.6667 11.7568 30.0233 11.1141 29.2333 11.1141H25.0333V9.78078H29.2333V9.78011Z"
          fill={fill}
          stroke={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_2003_138051">
          <rect width={32} height={32} fill={fill} stroke={stroke} />
        </clipPath>
      </defs>
    </svg>
  )
}
