import styled, { css } from 'styled-components'
import { TooltipPosition } from 'types/tooltip'

export const TooltipWrapper = styled.div`
  position: relative;
`

const getTooltipCoordinates = (position: TooltipPosition) => {
  switch (position) {
    case 'right': {
      return css`
        top: 50%;
        left: 101%;
        transform: translate(0, -50%);
      `
    }
    default: {
      return null
    }
  }
}

export const LabelContainer = styled('div')<{ position: TooltipPosition }>`
  z-index: 4;
  position: absolute;
  display: flex;
  align-items: center;
  ${props => getTooltipCoordinates(props.position)}
`
export const LabelStyled = styled.div`
  padding: 0.95rem 0.77rem;
  height: 3rem;
  min-width: 9.09rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  font-size: 0.909rem;
  color: white;
  white-space: nowrap;
  background: black;
  background-color: rgba(0, 0, 0, 0.9);
`

export const ArrowLeft = styled.div`
  margin-right: -1px;
  width: 0;
  height: 0;
  border-top: 0.454rem solid transparent;
  border-bottom: 0.454rem solid transparent;
  border-right: 0.545rem solid rgba(0, 0, 0, 0.9);
`
